import { Stack, Typography } from '@mui/material'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'

const RolePlayWelcome = () => {
  const { t } = useTranslation()
  const name = getLocalUser().firstName || t('rolePlay.upcomingLeader')

  return (
    <Stack spacing={6} padding={0}>
      <Typography variant='h4'>{ t('rolePlay.hi') }, {name}</Typography>
      <Typography variant='h2'>
        { t('rolePlay.welcomeToPractice') }!
      </Typography>
      <Typography variant='body1'>
        { t('rolePlay.chooseAScenario') }
      </Typography>
    </Stack>
  )
}

export default RolePlayWelcome
