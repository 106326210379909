import { Box } from '@mui/material'
import ReactPlayer from 'react-player'
import {
  logScreenAction,
  trackingSubScreenKey
} from 'services/trackingScreenActions'

const VideoPlayer = ({
  url,
  contentActionTracking,
  controls = true,
  ...otherProps
}) => {
  return (
    <Box borderRadius='20px'>
      <ReactPlayer
        url={url}
        controls={controls}
        width='100%'
        height='100%'
        className='react-player'
        onPlay={() => {
          logScreenAction('play_video', null, trackingSubScreenKey)
          contentActionTracking &&
            contentActionTracking('Media Play', { mediaType: 'video' })
        }}
        onPause={() =>
          logScreenAction('pause_video', null, trackingSubScreenKey)
        }
        {...otherProps}
      />
    </Box>
  )
}

export default VideoPlayer
