import {
  alpha,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { CircleNumber, PageHeader } from 'components/atoms/Text'
import StarRateIcon from '@mui/icons-material/StarRate'
import InCardStack from 'components/atoms/InCardStack'
import ShadedScoreDisplayBar from 'components/atoms/ShadedScoreDisplayBar'
import colors from 'styles/colors'
import ScaleBar from 'components/atoms/ScaleBar'
import { useTranslation } from 'react-i18next'

const AssessmentBehaviourCard = ({
  behaviourInfoData,
  behaviour,
  showOthersScore
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const data = behaviourInfoData.find(i => i.name.trim() === behaviour)
  const scaleStartPosition = 0.2
  const getScoreBarWidth = score => {
    return (score * (1 - scaleStartPosition)) / 5 + scaleStartPosition
  }
  const moduleMinWidth = '450px'
  return (
    <>
      <Stack
        spacing={10}
        padding={0}
        width='60%'
        minWidth={moduleMinWidth}
        data-testid='behaviour-summary'>
        <PageHeader>{data.name}</PageHeader>
        <ShadedScoreDisplayBar
          width='100%'
          height='60px'
          backgroundColor={theme.palette.background.default}
          labelComponent={
            <InCardStack direction='row' spacing={2}>
              <StarRateIcon color='secondary' />
              <Typography> {t('assessmentBehaviour.avgRating')} </Typography>
            </InCardStack>
          }
          scoreComponent={
            <CircleNumber
              value={data.avgRating.toFixed(1)}
              color={theme.palette.background.paper}
            />
          }
        />
        <Typography variant='body2'>{data.description}</Typography>
      </Stack>
      <Divider />
      <Box data-testid='behaviour-questions'>
        <Typography variant='h3'>{t('assessmentBehaviour.questions')}</Typography>
        <Grid container spacing={40} paddingTop={10} rowSpacing={20}>
          {data.questions.map((value, index) => {
            return (
              <Grid
                item
                key={`question-${index}`}
                xs={5.5}
                minWidth={moduleMinWidth}>
                <Grid container height='100%' display='flex' direction='column'>
                  <Typography>
                    {index + 1}. {value.selfQuestion}
                  </Typography>
                  <Grid
                    item
                    flex={1}
                    display='flex'
                    justifyContent='flex-end'
                    flexDirection='column'>
                    <Stack padding={0} paddingY={5}>
                      <ShadedScoreDisplayBar
                        width={getScoreBarWidth(value.selfScore)}
                        height='40px'
                        backgroundColor={alpha(
                          theme.palette.primary.main,
                          0.16
                        )}
                        labelComponent={
                          <Typography variant='string'>{t('goalsArea.self')}</Typography>
                        }
                        scoreComponent={
                          <Typography variant='string'>
                            {value.selfScore?.toFixed(1)}
                          </Typography>
                        }
                      />
                      {showOthersScore ? (
                        <ShadedScoreDisplayBar
                          width={getScoreBarWidth(value.othersScore)}
                          height='40px'
                          backgroundColor={alpha(colors.Aluminium, 0.15)}
                          labelComponent={<Typography>{t('goalsArea.others')}</Typography>}
                          scoreComponent={
                            <Typography>
                              {value.othersScore?.toFixed(1)}
                            </Typography>
                          }
                        />
                      ) : null}
                    </Stack>
                    <ScaleBar
                      startPosition={scaleStartPosition}
                      startNumber={0}
                      endNumber={5}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </>
  )
}

export default AssessmentBehaviourCard
