import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import ShortButton from 'components/atoms/button/ShortButton'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import InternalPage from 'components/templates/navigationPageTemplates/InternalPage'
import { hostUrl } from 'config/config'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CONTENT_EDITOR, DEEP_LINK_ACTION, GOALS, JOURNEY_DEMO } from 'routes/constants'
import { getGoals } from 'utils/getSupportingInfo'

const DemoGoals = () => {
  const navigate = useNavigate()
  const [goals, setGoals] = useState([])

  useEffect(() => {
    const fetchGoals = async _goalId => {
      const res = await getGoals()
      setGoals(res.filter(g => g.available))
    }

    fetchGoals()
  }, [])

  return (
    <InternalPage activePage={GOALS} pageTitle='Demo Paths'>
      <Stack>
        <Typography variant='h3'>Available Paths</Typography>
        <PresetTableContainer
          maxHeight='100%'
          headers={
            <>
              <TableCell>Path Name</TableCell>
              <TableCell>Demo Link</TableCell>
              <TableCell>Preview</TableCell>
              <TableCell>Content Editor</TableCell>
            </>
          }>
          {goals.map(g => (
            <TableRow key={`demo-goal-${g.id}`}>
              <TableCell>{g.name}</TableCell>
              <TableCell>{`${hostUrl}${DEEP_LINK_ACTION}?goalId=${g.id}&action=demoGoal`}</TableCell>
              <TableCell>
                <ShortButton
                  onClick={() =>
                    navigate(JOURNEY_DEMO, { state: { goalId: g.id } })
                  }>
                  Go
                </ShortButton>
              </TableCell>
              <TableCell>
                <ShortButton
                  onClick={() =>
                    navigate(`${CONTENT_EDITOR}/${g.id}`)
                  }>
                  Edit
                </ShortButton>
              </TableCell>
            </TableRow>
          ))}
        </PresetTableContainer>
      </Stack>
    </InternalPage>
  )
}

export default DemoGoals
