import {
  Box,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import { ActionText, SeeAllLinkText, Subtitle4 } from 'components/atoms/Text'
import { useEffect, useState } from 'react'
import CalendarEventsList from './CalendarEventsList'
import { HOME, USER_CALENDAR_EVENTS, USER_SETTINGS } from 'routes/constants'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LinkCalendarButton from 'components/molecules/oAuth/LinkCalendarButton'
import {
  getLocalUser,
  getPopupConfig,
  setPopupConfig
} from 'utils/localStorage'
import ConfirmCancelDialog from 'components/molecules/notificationOverlay/ConfirmCancelDialog'
import LowFocusButton from 'components/atoms/button/LowFocusButton'
import moment from 'moment'
import { getCalendarEvents } from 'services/userServices'
import { useTranslation } from 'react-i18next'

const popupType = 'calendarConnect'

const CalendarEvents = ({ home = true, userData }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [linked, setLinked] = useState(true)
  const [events, setEvents] = useState([])
  const [tokenExpired, setTokenExpired] = useState(false)
  const [tokenUnauthorized, setUnauthorized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openAskToConnect, setOpenAskToConnect] = useState(false)

  const checkOpenPopup = () => {
    const opened = getPopupConfig(popupType)
    const userCreated = getLocalUser()?.createdAt
    if (
      !opened &&
      home &&
      moment().diff(moment(userCreated), 'day') > 1 &&
      !userData.startingGoalId // Avoid popup conflict with switched path popup
    ) {
      setOpenAskToConnect(true)
      setPopupConfig(popupType, true)
    }
  }

  const fetchEvents = async () => {
    setLoading(true)
    const code = params.get('code')
    const res = await getCalendarEvents(code)
    setLinked(res?.linked ?? false)
    setUnauthorized(res?.unauthorized ?? false)
    setTokenExpired(res?.tokenExpired ?? false)

    if (res?.status === 200) {
      setEvents(res.data.data.calendarEvents || [])
    } else if (res?.status === 204) {
      checkOpenPopup()
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    fetchEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const signinCallback = async success => {
    if (success) {
      await fetchEvents()
      setLinked(true)
    }
    setOpenAskToConnect(false)
  }
  return (
    <>
      {home ? (
        <LeftJustifyCardContent
          sx={{ alignItems: 'baseline', paddingBottom: 0 }}>
          <Typography variant='h3'>
            {t('calenderEvents.upcomingReminders')}
          </Typography>
          {linked ? (
            <SeeAllLinkText to={USER_CALENDAR_EVENTS} from={HOME} text={t('common.seeAll')}/>
          ) : null}
        </LeftJustifyCardContent>
      ) : null}
      {linked ? (
        !loading ? (
          home ? (
            <CardContent>
              <Grid container spacing={10} width='100%'>
                <CalendarEventsList events={events} home={home} />
              </Grid>
            </CardContent>
          ) : (
            <Box width='100%'>
              <Stack spacing={10} padding={0}>
                <CalendarEventsList events={events} home={home} />
              </Stack>
            </Box>
          )
        ) : null
      ) : (
        <CardContent>
          <InCardStack>
            <Subtitle4>
              {tokenExpired ? (
                tokenUnauthorized ? (
                  <>
                    {t('calenderEvents.unableToConnect.part1')}
                    <ActionText onClick={() => navigate(USER_SETTINGS)}>
                      {t('headers.settings')}
                    </ActionText>
                    {t('calenderEvents.unableToConnect.part2')}
                  </>
                ) : (
                  t('calenderEvents.connectionExpired')
                )
              ) : (
                t('calenderEvents.noUpcomingReminders')
              )}
            </Subtitle4>
            <LinkCalendarButton signinCallback={signinCallback} label={t('calenderEvents.linkMyCalendar')}/>
          </InCardStack>
        </CardContent>
      )}
      {home ? <Divider /> : null}
      {loading && !home ? <OverlayLoading open={loading} /> : null}
      <ConfirmCancelDialog
        open={openAskToConnect}
        titleText={t('calenderEvents.linkYourCalendar')}
        onClose={() => setOpenAskToConnect(false)}
        maxWidth='sm'>
        <Typography
          variant='subtitle2'
          maxWidth={420}
          marginBottom={10}
          textAlign='center'
          fontSize={18}>
          {t('calenderEvents.instantlyConnectCalendar')}
        </Typography>
        <Stack spacing={10} direction='row'>
          <LinkCalendarButton
            signinCallback={signinCallback}
            label={t('calenderEvents.giveItTry')}
          />
          <LowFocusButton onClick={() => setOpenAskToConnect(false)}>
            {t('common.noThanks')}
          </LowFocusButton>
        </Stack>
      </ConfirmCancelDialog>
    </>
  )
}

export default CalendarEvents
