import { Circle, CircleOutlined } from '@mui/icons-material'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { getSizeString } from 'utils/formatText'

const RoundButton = props => {
  const theme = useTheme()
  const primaryColor = theme.palette.primary
  const {
    number,
    size = 40,
    fontSize = 14,
    disabled = false,
    onClick,
    filled,
    ...otherProps
  } = props
  const cursorSX = { '&:hover': { cursor: disabled ? 'default' : 'pointer' } }
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      component='span'
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      sx={cursorSX}
      {...otherProps}>
      <IconButton sx={cursorSX} disabled={disabled}>
        {filled ? (
          <Circle
            sx={{
              width: getSizeString(size),
              height: getSizeString(size),
              opacity: disabled ? 0.2 : 1
            }}
          />
        ) : (
          <CircleOutlined sx={{
            width: getSizeString(size+1),
            height: getSizeString(size+1),
            strokeWidth: 1.4,
            stroke: theme.palette.background.paper
          }} />
        )}
        <Typography
          position='absolute'
          color={filled ? theme.palette.background.default : primaryColor.dark}
          variant='h3'
          fontSize={getSizeString(fontSize)}
          alignItems='center'
          display='flex'>
          {number}
        </Typography>
      </IconButton>
    </Box>
  )
}

export default RoundButton
