import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CheckIcon from '@mui/icons-material/Check'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { JOURNEY, ONBOARDING } from 'routes/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import WideEndIconButton from 'components/atoms/button/WideEndIconButton'
import { useState } from 'react'
import { updateStates } from 'services/userServices'
import ConfirmCancelDialog from './notificationOverlay/ConfirmCancelDialog'
import { Subtitle4 } from 'components/atoms/Text'
import { JourneyIcon, LearningIcon } from 'components/atoms/Icons'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import { useTranslation } from 'react-i18next'
import { oneRangeCoursePage } from 'config/config'

const pathIcons = [
  <PersonOutlineOutlinedIcon />,
  <PeopleAltOutlinedIcon />,
  <GroupsOutlinedIcon />
]

const PathItem = ({
  goalData,
  onGoingGoalId,
  onGoingPathName,
  onAssessmentClick
}) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [oneRangeDialogOpen, setOneRangeDialogOpen] = useState(false)
  const [requestError, setRequestError] = useState(undefined)

  const updateCurrentPath = async () => {
    const res = await updateStates(
      {
        currentGoalStepId: '',
        currentGoalId: goalData.id
      },
      true
    )
    if (res.status === 200) {
      if (location.pathname === ONBOARDING) {
        const selectedGoal = await getGoalInfoById(goalData.id)
        navigate(ONBOARDING, { state: { selectedGoal } })
      } else {
        navigate(JOURNEY)
      }
    } else {
      setRequestError(true)
    }
  }
  const onBeginPathClick = () => {
    if (onGoingGoalId === null) {
      updateCurrentPath()
    } else {
      setDialogOpen(true)
    }
  }
  const onDialogClose = confirmed => {
    if (confirmed) {
      updateCurrentPath()
    }
    setDialogOpen(false)
  }

  const handleViewClick = () => {
    if (goalData.completed) {
      navigate(`${JOURNEY}/${goalData.id}`)
    } else {
      navigate(JOURNEY)
    }
  }

  const handleSurveyClick = () =>
    navigate(`${JOURNEY}/${goalData.id}`, {
      state: { goalStepId: goalData.completedQuizStepId }
    })

  const iconLevel = parseInt(goalData?.level) || 1
  const pathIcon = pathIcons[iconLevel - 1]
  const buttonWidth = goalData.completedQuizStepId ? 4 : 6
  return (
    <Box
      width='100%'
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: 4,
        borderColor: theme.palette.grey[500] + ' !important'
      }}>
      <Stack spacing={5}>
        <Subtitle4 component='div' alignItems='center' display='flex'>
          {pathIcon}&nbsp;{goalData.name}
          {goalData.completed ? (
            <Box
              paddingX='10px'
              height='18px'
              marginLeft='10px'
              sx={{
                backgroundColor: theme.palette.grey[700],
                borderRadius: '10px'
              }}>
              <Typography
                fontSize='12px'
                fontWeight='bold'
                color={theme.palette.background.paper}>
                {t('common.done')}
              </Typography>
            </Box>
          ) : null}
        </Subtitle4>
        <Box paddingX={14}>
          <Typography variant='body2'>{goalData.summary}</Typography>
        </Box>
        {goalData.completed || goalData.id === onGoingGoalId ? (
          <Box paddingX={14}>
            <Grid container spacing={5}>
              <Grid item xs={buttonWidth}>
                <WideEndIconButton
                  label={t('pathsAndModels.viewPath')}
                  onClick={() => handleViewClick()}
                  endIcon={<JourneyIcon />}
                />
              </Grid>
              <Grid item xs={buttonWidth}>
                <WideEndIconButton
                  label={t('pathsAndModels.assessments')}
                  onClick={onAssessmentClick}
                  endIcon={<LearningIcon />}
                />
              </Grid>
              {goalData.completedQuizStepId ? (
                <Grid item xs={buttonWidth}>
                  <WideEndIconButton
                    label={t('pathsAndModels.surveys')}
                    onClick={() => handleSurveyClick()}
                    endIcon={<CheckIcon />}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        ) : (
          <Box width='100%' paddingX={14}>
            <Grid container spacing={5}>
              {goalData.available &&
              goalData.supportLanguages.includes(i18n.resolvedLanguage) ? (
                <>
                  <Grid item xs={goalData.hasAssessment ? 6 : 12}>
                    {goalData.licensed ? (
                      <WideEndIconButton
                        label={t('pathsAndModels.beginPath')}
                        onClick={() => onBeginPathClick(goalData.id)}
                        endIcon={<AddOutlinedIcon />}
                      />
                    ) : (
                      <WideEndIconButton
                        label={t('pathsAndModels.unlock')}
                        onClick={() => setOneRangeDialogOpen(true)}
                        endIcon={<LockOpenIcon />}
                      />
                    )}
                  </Grid>
                  {goalData.hasAssessment ? (
                    <Grid item xs={6}>
                      <WideEndIconButton
                        label={t('pathsAndModels.assessments')}
                        onClick={onAssessmentClick}
                        endIcon={<LearningIcon />}
                      />
                    </Grid>
                  ) : null}
                </>
              ) : (
                <Grid item xs={12}>
                  <WideEndIconButton
                    label={t('pathsAndModels.locked')}
                    disabled={true}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        {requestError === true ? (
          <Box paddingX={14}>
            <RequestRetryAlert />
          </Box>
        ) : null}
      </Stack>
      <ConfirmCancelDialog
        open={dialogOpen}
        onClose={onDialogClose}
        titleText={t('pathsAndModels.pathInProgress')}
        notificationText={
          <>
            {t('pathsAndModels.startNewPathNotificationText.part1')}
            <strong> {onGoingPathName}</strong>
            {t('pathsAndModels.startNewPathNotificationText.part2')}
          </>
        }
        confirmText={t('pathsAndModels.confirmStartPath')}
        cancelText={t('common.cancel')}
      />
      <ConfirmCancelDialog
        open={oneRangeDialogOpen}
        maxWidth='sm'
        onClose={() => setOneRangeDialogOpen(false)}
        titleText={t('pathsAndModels.oneRangePurchaseText.title')}
        notificationText={
          <>
            {t('pathsAndModels.oneRangePurchaseText.part1')}
            <strong>{t('pathsAndModels.oneRangePurchaseText.part2')}</strong>
          </>
        }
        cancelText={t('common.cancel')}>
        <Button
          sx={{ marginTop: 10 }}
          href={oneRangeCoursePage}
          target='_blank'>
          {t('pathsAndModels.oneRangePurchaseText.visit')}
        </Button>
      </ConfirmCancelDialog>
    </Box>
  )
}

export default PathItem
