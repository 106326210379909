import JourneyContents from 'components/organisms/JouneyContents'
import SinglePageMessageCard from 'components/templates/SinglePageMessageCard'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ASSESSMENT_SELF,
  JOURNEY_NEW,
  SEND_EMAIL_VERIFICATION
} from 'routes/constants'
import { GOAL_STEP_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { getLocalUser } from 'utils/localStorage'
import JourneyPage from 'components/templates/navigationPageTemplates/JourneyPage'
import { CircularProgress } from '@mui/material'
import { getStates, updateStates } from 'services/userServices'
import { useTranslation } from 'react-i18next'

const Journey = () => {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [userStates, setUserStates] = useState(null)
  const [goalId, setGoalId] = useState(
    params.goalId ? parseInt(params.goalId) : undefined
  )
  const [goalStepsData, setGoalStepsData] = useState(undefined)
  const [requestError, setRequestError] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setGoalId(params.goalId ? parseInt(params.goalId) : undefined)
  }, [params])

  useEffect(() => {
    const fetchGoalSteps = async _goalId => {
      // Check if user paid and open notification dialogue if subscription required
      const user = getLocalUser()
      if (!user.is_verified) {
        navigate(SEND_EMAIL_VERIFICATION)
        return
      }
      if (!userStates) {
        const statesData = await getStates()
        setUserStates(statesData)
      }
      const res = await getRequest(GOAL_STEP_URL, { goalId: _goalId })
      if (res.status === 200) {
        if (res.data.data.length === 0) {
          setRequestError(true)
          setErrorMessage(
            t('pathsAndModels.doNotHaveResource')
          )
        } else {
          setGoalStepsData(res.data.data)
        }
      } else {
        setRequestError(true)
        setErrorMessage(
          t('pathsAndModels.notAbleLoadResource')
        )
      }
      setLoading(false)
    }
    if (goalId && (!goalStepsData || goalStepsData[0].goal_id !== goalId)) {
      setLoading(true)
      fetchGoalSteps(goalId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId, goalStepsData])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const stateData = await getStates()
      setUserStates(stateData)
      if (!goalId || stateData.currentGoalId === goalId) {
        if (!stateData.currentGoalId && !goalId) {
          navigate(JOURNEY_NEW)
        } else {
          // Update user state if from deep link
          if (
            stateData.startingGoalId &&
            location.state?.startingGoalId === stateData.startingGoalId
          ) {
            updateStates({ startingGoalId: '' })
          }
          if (!stateData.currentGoalSelfAssessmentComplete) {
            navigate(ASSESSMENT_SELF, {
              state: {
                goalId: stateData.currentGoalId
              }
            })
          } else {
            setGoalId(stateData.currentGoalId)
          }
        }
      }
      setLoading(false)
    }
    if (!goalId) {
      fetchData()
    }
  }, [goalId, navigate, location.state])

  return (
    <JourneyPage>
      {requestError ? (
        <SinglePageMessageCard titleText={t('common.notFound')} message={errorMessage} />
      ) : loading ? (
        <CircularProgress />
      ) : (
        <JourneyContents
          goalId={goalId}
          steps={goalStepsData}
          stepId={location.state?.goalStepId}
          userStates={userStates}
        />
      )}
    </JourneyPage>
  )
}

export default Journey
