import {
  Box,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LinkButton from 'components/atoms/button/LinkButton'
import InCardStack from 'components/atoms/InCardStack'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import { useEffect, useState } from 'react'
import { NOTEBOOK } from 'routes/constants'
import { USER_NOTES_TYPES, USER_NOTES_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import RoundedFilledIconButton from 'components/atoms/button/RoundedFilledIconButton'
import { useNavigate } from 'react-router-dom'
import { ActionText } from 'components/atoms/Text'
import { formatDate } from 'utils/formatDateTime'
import moment from 'moment'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

const GoalsArea = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [userGoals, setUserGoals] = useState([])

  useEffect(() => {
    const fetchNotes = async () => {
      const res = await getRequest(USER_NOTES_URL, {
        type: USER_NOTES_TYPES.GOAL
      })
      if (res.status === 200) {
        const filtered = res.data.data.filter(g => g.completed === false)
        setUserGoals(filtered)
      }
    }

    fetchNotes()
  }, [])

  return (
    <>
      {userGoals.length > 0 ? (
        <>
          <LeftJustifyCardContent>
            <Typography variant='h3'>{t('goalsArea.goalsToComplete')}</Typography>
          </LeftJustifyCardContent>
          <CardContent sx={{ paddingTop: 0 }}>
            <InCardStack width='100%'>
              {userGoals.map(goal => (
                <Box width='100%' key={goal.id}>
                  <Box
                    width='100%'
                    display='flex'
                    alignItems='center'
                    marginBottom={2}>
                    <ActionText
                      width='inherit'
                      noWrap
                      fontWeight='bold'
                      onClick={() =>
                        navigate(NOTEBOOK, { state: { noteData: goal } })
                      }>
                      {goal.notes}
                    </ActionText>

                    <Grid
                      item
                      flex={1}
                      display='inline-flex'
                      justifyContent='right'>
                      <RoundedFilledIconButton
                        color={theme.palette.primary.main}
                        StartIcon={ArrowForwardIcon}
                        onClick={() =>
                          navigate(NOTEBOOK, { state: { noteData: goal } })
                        }
                      />
                    </Grid>
                  </Box>
                  <Typography fontWeight='bold' color={colors.Aluminium}>
                    {t('goalsArea.dueDate')}:{' '}
                    <Typography
                      component='span'
                      fontWeight='bold'
                      color={
                        moment(goal.dueDate) < moment() ? colors.Cinnabar : colors.Aluminium
                      }>
                      {goal.dueDate ? formatDate(goal.dueDate, i18n.resolvedLanguage) : t('goalsArea.notSet')}
                    </Typography>
                  </Typography>
                </Box>
              ))}
            </InCardStack>
          </CardContent>
          <CardContent>
            <LinkButton to={NOTEBOOK}>{t('goalsArea.gotoMyGoals')}</LinkButton>
          </CardContent>
          <Divider />
        </>
      ) : null}
    </>
  )
}

export default GoalsArea
