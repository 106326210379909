import {
  Alert,
  Button,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import FormCard from 'components/templates/FormCard'
import { useState } from 'react'
import { UNSUBSCRIBE_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import InCardStack from 'components/atoms/InCardStack'
import { useTranslation } from 'react-i18next'

const UnsubscribeEmailCard = ({ data }) => {
  const { email, actionName, actionLabel } = data
  const [selected, setSelected] = useState('individual')
  const [resSuccess, setResSuccess] = useState(undefined)
  const { t } = useTranslation()

  const unsubscribe = async () => {
    const res = await postRequest(`${UNSUBSCRIBE_URL}/${email}/${actionName}`, {
      unsubscribeType: selected
    })
    if (res.status === 204) {
      setResSuccess(true)
    } else {
      setResSuccess(false)
    }
  }

  return (
    <FormCard>
      <CardContent>
        <Typography variant='h2_alu'>{t('setings.unsubscribe')}</Typography>
      </CardContent>
      <CardContent data-testid='unsubscribe-form'>
        {resSuccess ? (
          <Alert severity='success'>{t('setings.preferenceSaved')}!</Alert>
        ) : (
          <InCardStack>
            <Typography>
              {t('setings.aboutToUnsubscribe')}
              <br /><br />
              {t('setings.unsubscribeFrom')}:
            </Typography>
            <FormControl>
              <RadioGroup
                name='controlled-radio-buttons-group'
                value={selected}
                onChange={event => setSelected(event.target.value)}>
                <FormControlLabel
                  value={'individual'}
                  control={<Radio size='small' />}
                  label={
                    <Typography variant='body1'>
                      {actionLabel}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value='all'
                  control={<Radio size='small' />}
                  label={
                    <Typography variant='body1'>
                      {t('setings.all')}
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
            {resSuccess === false ? (
              <Alert severity='error'>
                {t('setings.submitError')}
              </Alert>
            ) : null}
            <Button onClick={() => unsubscribe()}>
              {t('setings.confirm')}
            </Button>
          </InCardStack>
        )}
      </CardContent>
    </FormCard>
  )
}

export default UnsubscribeEmailCard
