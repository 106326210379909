import { alpha, Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import { TaskSuccessIcon } from 'components/atoms/Icons'
import InCardStack from 'components/atoms/InCardStack'
import { AttributeLabelValue } from 'components/atoms/Text'
import { useEffect, useState } from 'react'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

const StepQuizMatching = ({ questionData }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [quizAnswers, setQuizAnswers] = useState([])
  const [displayQuestions, setDisplayQustions] = useState([])
  const [displayAnswers, setDisplayAnswers] = useState([])
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(undefined)
  const [errorAnswerIndex, setErrorAnswerIndex] = useState(undefined)
  const [correctAnswers, setCorrectAnswers] = useState([])

  const shuffleDeck = array => {
    let i = array.length - 1
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  useEffect(() => {
    if (questionData) {
      setQuizAnswers(questionData)
      const questions = questionData.map(q => q[0])
      const answers = questionData.map(q => q[1])
      setDisplayQustions(shuffleDeck(questions))
      setDisplayAnswers(shuffleDeck(answers))
      setCorrectAnswers([])
    }
  }, [questionData])

  useEffect(() => {
    const errorTimeout = setTimeout(() => {
      setErrorAnswerIndex(undefined)
    }, 750)
    return () => clearTimeout(errorTimeout)
  }, [errorAnswerIndex])

  const onQuestionSelect = index => {
    if (index === selectedQuestionIndex) {
      setSelectedQuestionIndex(undefined)
    } else {
      setSelectedQuestionIndex(index)
    }
  }

  const onAnswerSelect = index => {
    if (selectedQuestionIndex === undefined) {
      return
    }
    const answer = quizAnswers.find(
      q => q[0] === displayQuestions[selectedQuestionIndex]
    )
    if (displayAnswers[index] === answer[1]) {
      displayQuestions.splice(selectedQuestionIndex, 1)
      displayAnswers.splice(index, 1)
      setSelectedQuestionIndex(undefined)
      correctAnswers.push(answer)
    } else {
      setErrorAnswerIndex(index)
    }
  }
  const getQustionPalletStyle = index => {
    if (index === selectedQuestionIndex) {
      return {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        fontWeight: 'bold'
      }
    }
    return {}
  }

  const palletProps = {
    padding: 5,
    sx: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 5,
      ':hover': {
        border: '2px solid',
        borderColor: theme.palette.primary.light,
        cursor: 'pointer'
      }
    }
  }

  const getAnswerPalletStyle = index => {
    const transition = 'background-color 1s'
    if (index === errorAnswerIndex) {
      return {
        backgroundColor: alpha(colors.Cinnabar, 0.5),
        transition
      }
    }
    return { transition }
  }
  return (
    <>
      {displayQuestions.length === 0 ? (
        <InCardStack>
          <TaskSuccessIcon />
          <Typography>
            {t('stepQuiz.matchedCorrectly.part1')} <br /> {t('stepQuiz.matchedCorrectly.part2')}
          </Typography>
        </InCardStack>
      ) : (
        <Grid container>
          <Grid item xs={4} sx={{ paddingRight: 15 }}>
            <Stack
              spacing={10}
              padding={5}
              height='100%'
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: 5
              }}>
              {displayQuestions.map((q, i) => (
                <Grid
                  item
                  key={i}
                  {...palletProps}
                  style={getQustionPalletStyle(i)}
                  onClick={() => onQuestionSelect(i)}>
                  {q}
                </Grid>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={8}
            flex={1}
            display='inline-flex'
            justifyContent='right'
            sx={{ paddingLeft: 15 }}>
            <Stack
              spacing={5}
              padding={5}
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: 5
              }}>
              {displayAnswers.map((a, i) => (
                <Box
                  key={i}
                  {...palletProps}
                  style={getAnswerPalletStyle(i)}
                  onClick={() => onAnswerSelect(i)}>
                  <Typography>{a}</Typography>
                </Box>
              ))}
            </Stack>
          </Grid>
        </Grid>
      )}

      {correctAnswers.length > 0 ? (
        <>
          <Typography variant='subtitle1'>{t('stepQuiz.correctAnswers')}</Typography>
          <InCardStack sx={{ alignItems: 'left' }} spacing={2}>
            {correctAnswers.map(([q, a]) => (
              <AttributeLabelValue label={q} value={a} key={q} />
            ))}
          </InCardStack>
        </>
      ) : null}
    </>
  )
}

export default StepQuizMatching
