import { alpha, Button, useTheme } from '@mui/material'

const LowFocusButton = props => {
  const theme = useTheme()
  const { children, sx, ...otherProps } = props
  return (
    <Button
      {...otherProps}
      sx={[
        sx,
        {
          backgroundColor: theme.palette.action.disabledBackground,
          color: theme.palette.action.disabled,
          '&:hover': {
            backgroundColor: alpha(theme.palette.action.disabledBackground, 0.2)
          }
        }
      ]}>
      {children}
    </Button>
  )
}

export default LowFocusButton
