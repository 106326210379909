import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Close } from '@mui/icons-material'
import InCardStack from 'components/atoms/InCardStack'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { useState } from 'react'
import { USER_URL } from 'services/constants'
import { deleteRequest } from 'utils/api'
import LowFocusButton from 'components/atoms/button/LowFocusButton'
import { useNavigate } from 'react-router-dom'
import { clearPendoSession } from 'services/pendo'
import { SIGNUP_DIRECT } from 'routes/constants'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useTranslation } from 'react-i18next'

const DeleteAccountButton = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [requestSentSuccessful, setRequestSentSuccessful] = useState(undefined)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteConfirm = async () => {
    setLoading(true)
    const res = await deleteRequest(USER_URL)
    if (res.status === 204) {
      setRequestSentSuccessful(true)
      setDialogOpen(false)
      setSuccessDialogOpen(true)
    } else {
      setRequestSentSuccessful(false)
    }
    setLoading(false)
  }

  const signout = () => {
    localStorage.clear()
    clearPendoSession()
    navigate(SIGNUP_DIRECT)
  }
  return (
    <CardContent sx={{ paddingTop: 0 }}>
      <LowFocusButton
        startIcon={<ErrorOutlineIcon />}
        onClick={() => setDialogOpen(true)}>
        {t('profile.deleteMyAccount')}
      </LowFocusButton>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth='xs'>
        <Box width='100%'>
          <IconButton
            sx={{ float: 'right', paddingTop: '10px' }}
            onClick={() => setDialogOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <InCardStack
          spacing={5}
          sx={{ paddingBottom: 8, paddingTop: 0, paddingX: 20 }}>
          <Typography variant='h3'>{t('profile.areYouSure')}</Typography>
          <Typography variant='body2' textAlign='center'>
            {t('profile.sorryToSeeYouGo')}
          </Typography>
          <Stack pading={0} spacing={5}>
            <Button onClick={() => setDialogOpen(false)}>
              {t('profile.keepMyAccount')}
            </Button>
            <Button onClick={() => deleteConfirm()}>
              {t('profile.closeMyAccount')}
            </Button>
          </Stack>
          {requestSentSuccessful === false ? <RequestRetryAlert /> : null}
        </InCardStack>
      </Dialog>
      <Dialog open={successDialogOpen} onClose={() => signout()}>
        <InCardStack sx={{padding: 10}}>
          <DialogTitle>{t('profile.accountClosed')}</DialogTitle>
          <Button onClick={() => signout()}>{t('common.done')}</Button>
        </InCardStack>
      </Dialog>
      <OverlayLoading open={loading} />
    </CardContent>
  )
}

export default DeleteAccountButton
