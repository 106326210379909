import { Button, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import InCardStack from 'components/atoms/InCardStack'
import { useEffect, useState } from 'react'
import { ActionText } from 'components/atoms/Text'
import ImageContainer from 'components/atoms/container/ImageContainer'
import { useTranslation } from 'react-i18next'

const StepOne = ({ completeCallback }) => {
  const { t } = useTranslation()
  const [activePage, setActivePage] = useState(0)
  useEffect(() => {
    if (activePage === 5) {
      completeCallback()
    }
  }, [activePage, completeCallback])

  const TitleText = ({ children }) => (
    <Typography color='primary' variant='h1' fontWeight={800}>
      {children}
    </Typography>
  )
  const BodyText = ({ children }) => (
    <Typography variant='h3' textAlign='center'>
      {children}
    </Typography>
  )
  return (
      <InCardStack width='650px' spacing={10} marginTop={5}>
        {activePage === 0 ? (
          <>
            <TitleText>{t('welcomeArea.welcome')} {t('common.to')} Monark!</TitleText>
            <BodyText>
              {t('onboarding.embarkInteractiveJourney')}
            </BodyText>
            <BodyText>{t('onboarding.heresWhatExpect')}</BodyText>
            <ImageContainer
              src='https://monark-public-assets.s3.ca-central-1.amazonaws.com/img/onboardingHome.png'
              alt='home'
            />
          </>
        ) : activePage === 1 ? (
          <>
            <TitleText>{t('onboarding.getYourBaseline')}</TitleText>
            <BodyText>
              {t('onboarding.assessmentOfCurrentBehaviors')}
            </BodyText>
            <ImageContainer
              src='https://monark-public-assets.s3.ca-central-1.amazonaws.com/img/onboardingBaseline.png'
              alt='baseline'
            />
          </>
        ) : activePage === 2 ? (
          <>
            <TitleText>{t('onboarding.learnAtYourPace')}</TitleText>
            <BodyText>
              {t('onboarding.easilyAccessContent')}
            </BodyText>
            <ImageContainer
              src='https://monark-public-assets.s3.ca-central-1.amazonaws.com/img/onboardingLearn.png'
              alt='learn'
            />
          </>
        ) : activePage === 3 ? (
          <>
            <TitleText>{t('onboarding.setGoalsPractice')}</TitleText>
            <BodyText>
              {t('onboarding.trackYourProgress')}
            </BodyText>
            <ImageContainer
              src='https://monark-public-assets.s3.ca-central-1.amazonaws.com/img/onboardingGoals.png'
              alt='goal'
            />
          </>
        ) : activePage === 4 ? (
          <>
            <TitleText>{t('onboarding.getFeedbackImprove')}</TitleText>
            <BodyText>
              {t('onboarding.useSurveysFeedbackTools')}
            </BodyText>
            <ImageContainer
              src='https://monark-public-assets.s3.ca-central-1.amazonaws.com/img/onboardingFeedback.png'
              alt='feedback'
            />
          </>
        ) : null}
        <Button
          endIcon={<ArrowForward />}
          onClick={() => setActivePage(activePage + 1)}>
          {t('common.continue')}
        </Button>
        <ActionText onClick={() => completeCallback()}>{t('common.skip')}</ActionText>
      </InCardStack>
  )
}

export default StepOne
