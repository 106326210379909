import Header from 'components/molecules/header/Header'
import { Box, Container, useTheme } from '@mui/material'
import { PAGE_TITLES } from 'utils/constants'
import { initPendo } from 'services/pendo'

const SimplePage = ({ children, maxWidth = 'sm' }) => {
  document.title = PAGE_TITLES.DEFAULT
  initPendo(false)
  const theme = useTheme()
  return (
    <Box
      width='100%'
      height='100%'
      position='fixed'
      sx={{
        backgroundColor: theme.palette.background.paper
      }}>
      <Header loggedIn={false}></Header>
      <Box width='100%' height='inherit' overflow='auto'>
        <Container
          sx={{ paddingTop: '65px' }}
          maxWidth={maxWidth}
          data-testid='all-page-content'>
          {children}
        </Container>
      </Box>
    </Box>
  )
}

export default SimplePage
