import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import { PageHeader } from 'components/atoms/Text'
import CalendarEvents from 'components/organisms/home/CalenderEvents'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { useTranslation } from 'react-i18next'

const UserCalendarEvents = () => {
  const { t } = useTranslation()
  return (
    <HomePage>
      <BackIconTextButton />
      <PageHeader width='fit-content' paddingBottom='12px'>
        {t('calenderEvents.upcomingReminders')}
      </PageHeader>
      <CalendarEvents home={false}/>
    </HomePage>
  )
}

export default UserCalendarEvents
