import { alpha, Box, Typography, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const LeadershipModelItem = ({
  active,
  name,
  size,
  tagLabel,
  progress = 1
}) => {
  const theme = useTheme()
  const activeColor = theme.palette.primary.main
  const inactiveColor = theme.palette.grey[400]
  const activeTextColor = theme.palette.primary.main
  const textStyle = {
    color: active ? activeTextColor : undefined,
    fontWeight: active ? 'bold' : 'normal',
    sx: { transitionDuration: '2s', transitionProperty: 'color' }
  }
  const borderColor = active ? activeColor : inactiveColor
  const boxStyle = {
    backgroundColor: alpha(active ? activeColor : inactiveColor, 0.15),
    borderColor,
    transitionDuration: '2s',
    transitionProperty: 'background-color, border-color, box-shadow',
    '&:hover': { cursor: 'pointer' }
  }
  const progressStyle = {
    color: borderColor,
    position: 'absolute',
    transitionDuration: '2s',
    transitionProperty: 'color'
  }
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height={size}
      width={size}
      sx={{
        borderRadius: size,
        boxShadow: active
          ? `0 0 10px 2px ${alpha(activeColor, 0.3)}`
          : undefined,
        ...boxStyle
      }}
      alignContent='center'>
      <CircularProgress
        variant='determinate'
        value={100}
        thickness={0.5}
        sx={[progressStyle, {opacity: 0.5}]}
        size={size}
      />
      <CircularProgress
        variant='determinate'
        value={progress * 100}
        thickness={1.5}
        sx={progressStyle}
        size={size+2}
      />
      <Typography
        {...textStyle}
        alignSelf='center'
        textAlign='center'
        fontSize='13px'
        width={size - 20}>
        {name}
      </Typography>
      <Box
        position='fixed'
        sx={{
          bottom: '-8px',
          backgroundColor: theme.palette.background.paper,
          borderRadius: '15px'
        }}>
        <Box
          sx={{
            padding: '0 15px',
            border: 'solid 1px',
            borderRadius: '15px',
            ...boxStyle
          }}
          alignContent='center'>
          <Typography
            {...textStyle}
            variant='caption'
            lineHeight={1}
            textAlign='center'>
            {tagLabel}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default LeadershipModelItem
