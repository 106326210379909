import {
  Autocomplete,
  createFilterOptions,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import { useFormik } from 'formik'
import UserConnectionMenuItem from './UserConnectionMenuItem'
import { useEffect, useState } from 'react'
import FormSelectInput from './FormSelectInput'
import { USER_CONNECTION_RELATIONSHIP } from 'services/constants'
import { validationsRules } from 'utils/formValidations'
import { getFullName } from 'utils/formatText'
import { useTranslation } from 'react-i18next'

const UserConnectionForm = ({
  index,
  connections,
  selected,
  submitHandler,
  existingRaters
}) => {
  const { t } = useTranslation()
  const filter = createFilterOptions()
  const [options, setOptions] = useState(connections)
  const initialValue = selected[index]
  const [disableRelationship, setDisableRelationship] = useState(
    Boolean(initialValue?.relationship)
  )

  const formik = useFormik({
    initialValues: {
      recipient: initialValue,
      relationship: initialValue?.relationship || ''
    },
    validateOnBlur: false,
    onSubmit: values => {
      let error = false
      let errorMessage = t('addRaters.validConnError')
      if (formik.errors.recipient) {
        error = true
      } else if (values.relationship === '') {
        formik.setFieldError('relationship', t('addRaters.required'))
        error = true
        errorMessage = t('addRaters.relationshipTypeValidation')
      } else {
        formik.setErrors({})
      }
      if (!error) {
        const rater = values.recipient || {}
        rater.relationship = values.relationship
        submitHandler(index, rater)
      } else {
        submitHandler(index, { error, errorMessage })
      }
    }
  })

  const handleOpen = () => {
    const filtered = connections.filter(
      c =>
        !selected.find(s => s?.email === c.email) ||
        c.email === selected[index]?.email
    )
    if (selected[index]?.new) {
      filtered.push(selected[index])
    }
    setOptions(filtered)
  }

  const handleSelectRater = async (e, value) => {
    let newValue = value?.email ? value : null
    if (value?.new) {
      try {
        await Promise.resolve(validationsRules.email.validate(value.email))
        formik.setErrors({})
      } catch {
        formik.setFieldError('recipient', t('shareMyResults.recpEmailValidation'))
        formik.setTouched({ recipient: true }, false)
        return
      }
      const existingInOthers = selected.find(s => s?.email === value.email)
      const existingRater = existingRaters.find(s => s?.email === value.email)
      if (existingInOthers || existingRater) {
        formik.setFieldError(
          'recipient',
          existingInOthers
            ? t('addRaters.emailAlreadyInInvite')
            : t('addRaters.emailAlreadyInRaters')
        )
        formik.setTouched({ recipient: true }, false)
        return
      }
      newValue = {
        email: value.email,
        relationship: formik.values.relationship,
        first_name: t('addRaters.new'),
        last_name: t('addRaters.connection'),
        new: true
      }
      options.push(newValue)
      setDisableRelationship(false)
    } else {
      formik.setFieldValue(
        'relationship',
        value?.relationship ? value?.relationship : ''
      )
      // Not allow user to change relationship if there is one existing
      if (value?.relationship) {
        setDisableRelationship(true)
      } else {
        setDisableRelationship(false)
      }
    }
    if (newValue === null) {
      formik.resetForm()
      if (initialValue?.relationship) {
        formik.setFieldValue('relationship', '')
      }
      setDisableRelationship(false)
      submitHandler(index, null)
    } else {
      formik.setFieldValue('recipient', newValue)
      formik.submitForm()
    }
  }

  const handleSelectRelationship = e => {
    formik.setFieldValue('relationship', e.target.value)
    formik.submitForm()
  }

  useEffect(() => {
    if (initialValue && !initialValue.relationship) {
      formik.setFieldError('relationship', t('addRaters.required'))
      formik.setTouched({ relationship: true }, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container data-testid={`rater-form-${index}`}>
      <Grid item xs={8}>
        <Autocomplete
          autoSelect
          options={options}
          onOpen={() => handleOpen()}
          autoHighlight={true}
          defaultValue={initialValue}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)

            if (params.inputValue !== '') {
              filtered.push({
                email: params.inputValue,
                first_name: `${t('addRaters.new')} ${t('addRaters.connection')}`,
                last_name: `"${params.inputValue}"`,
                new: true
              })
            }

            return filtered
          }}
          renderOption={(props, option) => (
            <UserConnectionMenuItem
              itemProps={props}
              option={option}
              key={option.email}
            />
          )}
          renderInput={params => (
            <TextField
              {...params}
              error={
                formik.touched.recipient && Boolean(formik.errors.recipient)
              }
              helperText={formik.touched.recipient && formik.errors.recipient}
              value={formik['recipient']}
              placeholder={t('addRaters.nameOrWorkEmail')}
            />
          )}
          getOptionLabel={option =>
            option.new
              ? `${t('addRaters.new')} ${t('addRaters.connection')} (${option.email})`
              : `${getFullName(option)} (${option.email})`
          }
          isOptionEqualToValue={(option, value) => option.email === value.email}
          onChange={handleSelectRater}
        />
      </Grid>
      <Grid item xs={0.2}></Grid>
      <Grid item xs={3.8}>
        <FormSelectInput
          fullWidth
          type='text'
          name='relationship'
          formik={formik}
          disabled={disableRelationship}
          onChange={e => handleSelectRelationship(e)}
          placeholder={t('addRaters.select')}
          attributeName='relationship'
          InputProps={{ sx: { minWidth: 0 } }}>
          {USER_CONNECTION_RELATIONSHIP.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {t(`constants.userConnRelationship.${item.value}`)}
            </MenuItem>
          ))}
        </FormSelectInput>
      </Grid>
    </Grid>
  )
}

export default UserConnectionForm
