import { Star } from '@mui/icons-material'
import { Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import ShadedScoreDisplayBar from 'components/atoms/ShadedScoreDisplayBar'
import { CircleNumber } from 'components/atoms/Text'
import colors from 'styles/colors'
import { formatDate } from 'utils/formatDateTime'
import { useTranslation } from 'react-i18next'

const ScoreTextStack = ({ score, text, color }) => (
    <InCardStack spacing={0}>
      <CircleNumber value={score} size='60px' textColor={color} />
      <Typography color={color}>{text}</Typography>
    </InCardStack>
  )

const ScoreChangeDisplayBar = ({displayData, hasOlderData, displayEmpty = false}) => {
  const { t, i18n } = useTranslation()
  return (
    <ShadedScoreDisplayBar
      width='100%'
      paddingY={5}
      paddingX={15}
      borderRadius={5}
      labelComponent={
        <InCardStack direction='row' spacing={3}>
          <Star color='secondary' />
          <Typography textAlign='center'>{t('assessmentTimeline.overallAverageRating')}</Typography>
        </InCardStack>
      }
      scoreComponent={
        <InCardStack direction='row' minWidth='380px' justifyContent='flex-end'>
          {displayData.oldScore > 0 || displayEmpty ? (
            <ScoreTextStack
              score={displayData.oldScore || '-'}
              text={formatDate(displayData.oldTimeStamp, i18n.resolvedLanguage)}
              color='primary'
            />
          ) : (
            <Typography
              variant='caption'
              color='primary'
              maxWidth='140px'
              textAlign='center'>
              {t('assessmentTimeline.completeMoreAssessments')}
            </Typography>
          )}
          {displayData.newScore > 0 || displayEmpty ? (
            <ScoreTextStack
              score={displayData.newScore || '-'}
              text={formatDate(displayData.newTimeStamp, i18n.resolvedLanguage)}
              color={colors.LightSalmon}
            />
          ) : null}
          {hasOlderData && displayData.oldScore > 0 && displayData.changePercent? (
            <ScoreTextStack
              score={displayData.changeScore}
              text={`${t('assessmentTimeline.change')} ${displayData.changePercent > 0 ? '+' : ''}${
                displayData.changePercent
              }%`}
              color={colors.Orangish}
            />
          ) : null}
        </InCardStack>
      }
    />
  )
}

export default ScoreChangeDisplayBar
