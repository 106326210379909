import { Avatar, Box, Icon } from '@mui/material'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import colors from 'styles/colors'

const CharacterAvatar = ({
  rolePlay,
  name,
  sx,
  showCompleteIcon,
  showAudioIcon = false,
  size = 28
}) => {
  const initials = name
    ?.split(' ')
    .map(x => x[0]?.toUpperCase())
    .join('')
  return (
    <Box display='flex' position='relative'>
      <Avatar
        sx={[{ width: size, height: size }, sx]}
        src={
          rolePlay?.context?.avatarUrl ?? require('assets/images/alexRole.png')
        }>
        {initials}
      </Avatar>
      {(showAudioIcon || showCompleteIcon) && (
        <Box
          sx={{
            backgroundColor: 'Background',
            position: 'absolute',
            right: 0,
            bottom: 0,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center'
          }}>
          {showAudioIcon && (
            <Box sx={{ padding: 3, height: 36 }}>
              <Icon>
                {showAudioIcon && <VolumeUpIcon sx={{ color: colors.Black }} />}
              </Icon>
            </Box>
          )}
          {showCompleteIcon && (
            <Icon
              fontSize='large'
              sx={{
                height: 30,
                width: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <CheckCircleIcon color='success' fontSize='large' />
            </Icon>
          )}
        </Box>
      )}
    </Box>
  )
}

export default CharacterAvatar
