import { Stack, TableCell, useTheme } from '@mui/material'
import RoundedFilledIconButton from '../button/RoundedFilledIconButton'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'


const AcceptRejectCell = props => {
  const { acceptClick, rejectClick, ...otherProps } = props
  const theme = useTheme()
  return (
    <TableCell {...otherProps}>
      <Stack padding={0} direction='row' spacing={5}>
        <RoundedFilledIconButton
          StartIcon={CheckIcon}
          color={theme.palette.primary.main}
          onClick={acceptClick}
        />
        <RoundedFilledIconButton
          StartIcon={CloseIcon}
          color={theme.palette.error.main}
          onClick={rejectClick}
        />
      </Stack>
    </TableCell>
  )
}

export default AcceptRejectCell
