import { Box, useTheme } from '@mui/material'
const BoxWithBorder = props => {
  const theme = useTheme()
  const {
    children,
    borderColor = theme.palette.primary.light,
    borderWidth = '3px',
    borderRadius = 5,
    sx,
    ...otherProps
  } = props

  return (
    <Box
      {...otherProps}
      borderRadius={borderRadius}
      sx={[{ borderStyle: 'solid', borderColor, borderWidth }, sx]}>
      {children}
    </Box>
  )
}

export default BoxWithBorder
