import moment from 'moment'
import { refreshProfile } from 'services/userServices'

let updatingUser = false

export const getUpdateLocalUser = async (
  current,
  reloadOnUnAuthorized = true
) => {
  const updated = await refreshProfile(reloadOnUnAuthorized)
  if (updated) {
    if (current) {
      updated.token = current.token
    }
    setLocalUser(updated)
    updatingUser = false
  }
  return updated
}

export const getLocalUser = () => {
  const user = localStorage.getItem('user')
  const parsed = user ? JSON.parse(user) : null
  if (
    updatingUser === false &&
    parsed &&
    (!parsed.setAt || moment().diff(parsed.setAt, 'minute') > 5)
  ) {
    updatingUser = true
    getUpdateLocalUser(parsed)
  }
  return parsed
}

export const setLocalUser = userObj => {
  if (!userObj.token){
    userObj.token = JSON.parse(localStorage.getItem('user')).token
  }
  userObj.setAt = moment()
  return localStorage.setItem('user', JSON.stringify(userObj))
}

export const getUserOrganizationId = () => {
  const user = getLocalUser()
  return user.organizationId
}

// key stays even if user loggout
const getUserBasedStorageKey = baseKey => {
  const user = getLocalUser()
  return `${baseKey}-${user.id}`
}

export const getUserBasedStorageItem = (baseKey, itemName) => {
  const storageKey = getUserBasedStorageKey(baseKey)
  const storageItem = localStorage.getItem(storageKey)
  if (storageItem) {
    const parsed = JSON.parse(storageItem)
    return parsed[itemName]
  }
  return null
}

export const setUserBasedStorageItem = (baseKey, itemName, value) => {
  const storageKey = getUserBasedStorageKey(baseKey)
  const stored = localStorage.getItem(storageKey)
  let storageItem = {}
  if (stored) {
    storageItem = JSON.parse(stored)
  }
  storageItem[itemName] = value
  localStorage.setItem(storageKey, JSON.stringify(storageItem))
}

export const deleteUserBasedStorageItem = (baseKey, itemName) => {
  const storageKey = getUserBasedStorageKey(baseKey)
  const storageItem = localStorage.getItem(storageKey)
  if (storageItem) {
    const parsed = JSON.parse(storageItem)
    delete parsed[itemName]
    localStorage.setItem(storageKey, JSON.stringify(parsed))
  }
  return null
}

// Local storage for popup windows that previously show/disabled by user
const popupConfigBaseKey = 'popupConfig'
export const getPopupConfig = popupType => {
  return getUserBasedStorageItem(popupConfigBaseKey, popupType)
}
export const setPopupConfig = (popupType, value) => {
  setUserBasedStorageItem(popupConfigBaseKey, popupType, value)
}

// The same for all users on this device
const getGlobalStorageKey = key => `GLOBAL-KEY-${key}`

export const getGlobalStorageItem = key => {
  return localStorage.getItem(getGlobalStorageKey(key))
}

export const setGlobalStorageItem = (key, value) => {
  localStorage.setItem(getGlobalStorageKey(key), value)
}

export const delGlobalStorageItem = key => {
  localStorage.removeItem(getGlobalStorageKey(key))
}
