import { Box, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ASSESSMENT_BEHAVIOUR } from 'routes/constants'
import colors from 'styles/colors'
import { getSizeString } from 'utils/formatText'
import {
  getMaxima,
  prepareRadarGraphData,
  processData
} from 'utils/radarGraphHelper'
import { updatePageState } from 'utils/windowHistoryHelper'
import {
  VictoryPolarAxis,
  VictoryArea,
  VictoryTheme,
  VictoryScatter,
  VictoryGroup,
  VictoryChart,
  VictoryLabel
} from 'victory'

export const radarGraphLocationString = 'RadarGraph'

const RadarGraph = ({
  assessmentData,
  size = 500,
  disableBehaviorClick = false,
  showScoreLabel = false,
  labelColor = colors.BondiBlue,
  disableScroll = false
}) => {
  const ref = useRef()
  const [rawRatings, setRawRatings] = useState([])
  const [graphData, setGraphData] = useState([])
  const [maximaData, setMaximaData] = useState([])
  const [cursor, setCursor] = useState(null)
  const navigate = useNavigate()

  const theme = useTheme()

  const scrollToGraph =
    window.history.state?.graphData?.id === assessmentData.id && !disableScroll

  const handleMouseChange = newCursor => {
    if (!disableBehaviorClick) setCursor(newCursor)
  }

  const handleBehaviorNavitation = behavior => {
    if (!disableBehaviorClick) {
      updatePageState({
        location: radarGraphLocationString,
        graphData: assessmentData
      })
      navigate(
        `${ASSESSMENT_BEHAVIOUR}/${assessmentData.goalData.id}/${assessmentData.id}/${behavior}`,
        { state: { assessmentData } }
      )
    }
  }

  const calculateLabelDx = angle => Math.cos(angle) * 15

  const calculateLabelX = (x, angle) => {
    const isVertical = Math.abs(Math.cos(angle)) < Number.EPSILON
    const isHorizontal = Math.abs(Math.sin(angle)) < Number.EPSILON
    const isRight = Math.cos(angle) > 0

    return isVertical
      ? x
      : isHorizontal && isRight
        ? x + 15
        : isHorizontal
          ? x - 15
          : x + 20 * Math.cos(angle)
  }

  const calculateLabelY = (y, angle, text, verticalAnchor) => {
    const isTopHalf = Math.sin(angle) > 0
    const isHorizontal = Math.abs(Math.sin(angle)) < Number.EPSILON

    const gap = 5.5 + text.length * 2.5
    const wideGap = gap + text.length * 2

    return !isTopHalf && verticalAnchor === 'start'
      ? y + wideGap
      : isHorizontal || !isTopHalf
        ? y + gap
        : isTopHalf && verticalAnchor === 'end'
          ? y - wideGap
          : y - gap
  }

  const calculateBoxY = (y, angle, text, verticalAnchor, scoreFontSize) => {
    const label = calculateLabelY(y, angle, text, verticalAnchor)

    return verticalAnchor === 'start'
      ? label + scoreFontSize / 2
      : verticalAnchor === 'end'
        ? label - scoreFontSize / 2
        : label
  }

  const ScoreLabel = ({ x, y, angle, score, attrKey, ...props }) => {
    const rectW = 30
    const rectH = 7
    const scoreFontSize = 4 // Oddly small number here

    return (
      <g>
        <VictoryLabel
          x={x}
          y={y}
          dx={calculateLabelDx(angle)}
          labelPlacement='vertical'
          {...props}
        />
        <rect
          x={calculateLabelX(x, angle) - rectW / 2}
          y={
            calculateBoxY(
              y,
              angle,
              props.text,
              props.verticalAnchor,
              scoreFontSize
            ) -
            rectH / 2
          }
          width={rectW}
          height={rectH}
          fill={colors.White}
          filter='drop-shadow(0px 0px 1px rgba(0,0,0,0.15))'
          rx={1}
        />
        <VictoryLabel
          text={score.toString()}
          style={{
            fill: colors.Teal400,
            fontSize: scoreFontSize,
            fontFamily: theme.typography.fontFamily
          }}
          x={calculateLabelX(x, angle)}
          y={calculateLabelY(y, angle, props.text, props.verticalAnchor)}
          labelPlacement='vertical'
          textAnchor='middle'
          verticalAnchor={props.verticalAnchor}
        />
      </g>
    )
  }

  useEffect(() => {
    if (assessmentData?.radarChartData) {
      const characterData = prepareRadarGraphData(assessmentData.radarChartData)
      setRawRatings(characterData)
      setGraphData(processData(characterData))
      const maxima = getMaxima(characterData)
      setMaximaData(maxima)
      if (scrollToGraph && ref.current) {
        ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  }, [assessmentData, scrollToGraph])

  return (
    <Box
      width={getSizeString(size + 200)}
      height={getSizeString(size + 220)}
      display='flex'
      justifyContent='center'
      alignContent='center'
      overflow='hidden'
      position='relative'>
      <Box
        width='200%'
        height='200%'
        position='absolute'
        top='-50%'
        sx={{
          cursor: cursor
        }}
        ref={ref}>
        {Object.keys(maximaData).length > 0 ? (
          <VictoryChart
            polar
            theme={VictoryTheme.material}
            domain={{ y: [0, 1] }}
            height={195}>
            {/* Outer dark grey circle */}
            <VictoryPolarAxis
              tickFormat={() => ''}
              style={{
                axis: { strokeWidth: 1, opacity: 1 }
              }}
            />

            {/* All the labels */}
            {Object.entries(maximaData).map(([key, value], index) => {
              const score = rawRatings[0][key].toFixed(2)
              const angle =
                (index * 2 * Math.PI) / Object.keys(maximaData).length

              return (
                <VictoryPolarAxis
                  key={`vPA ${index}`}
                  dependentAxis
                  style={{
                    axisLabel: {
                      fontSize: 5,
                      fontWeight: '600',
                      fill: labelColor
                    },
                    axis: { stroke: colors.Aluminium, opacity: 0.2 },
                    grid: {
                      stroke: colors.Aluminium,
                      opacity: 0.5,
                      strokeWidth: 0.2,
                      strokeDasharray: [5, 4]
                    }
                  }}
                  label={key.split(/(?<=[ -])/)}
                  labelPlacement='vertical'
                  axisValue={index + 1}
                  tickFormat={t => t * 5}
                  tickValues={[0.2, 0.4, 0.6, 0.8]}
                  tickLabelComponent={
                    <VictoryLabel
                      style={{ fill: colors.BondiBlue, fontSize: 5 }}
                      labelPlacement='vertical'
                    />
                  }
                  axisLabelComponent={
                    showScoreLabel ? (
                      <ScoreLabel attrKey={key} score={score} angle={angle} />
                    ) : (
                      <VictoryLabel dx={calculateLabelDx(angle)} />
                    )
                  }
                  events={[
                    {
                      target: 'axisLabel',
                      eventHandlers: {
                        onClick: () => handleBehaviorNavitation(key),
                        onMouseEnter: () => {
                          handleMouseChange('pointer')
                        },
                        onMouseLeave: () => {
                          handleMouseChange(null)
                        }
                      }
                    }
                  ]}
                />
              )
            })}

            {/* Polygon-fill area */}
            <VictoryGroup
              colorScale={[colors.BondiBlue, colors.Aluminium, 'transparent']}
              style={{ data: { fillOpacity: 0.15, strokeWidth: 0.1 } }}>
              {graphData.map((data, i) => {
                return <VictoryArea key={`vPA ${i}`} data={data}></VictoryArea>
              })}
            </VictoryGroup>

            {/* Filled dots on vertices */}
            {graphData[0].map((value, index) => {
              return (
                <VictoryScatter
                  key={`vPA ${index}`}
                  size={2}
                  data={[{ x: value.x, y: value.y }]}
                  style={{
                    data: { fill: colors.BondiBlue, opacity: 1 }
                  }}
                />
              )
            })}
          </VictoryChart>
        ) : null}
      </Box>
    </Box>
  )
}

export default RadarGraph
