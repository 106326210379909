import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import StyledButton from 'components/atoms/button/StyledButton'
import RowStack from 'components/atoms/container/RowStack'
import EventRowCard from 'components/molecules/myTeam/EventRowCard'
import InCardStack from 'components/atoms/InCardStack'
import { getUpcomingEvents } from 'services/userConnectionsHelper'
import colors from 'styles/colors'
import { getRequest } from 'utils/api'
import {
  USER_CALENDAR_EVENTS_URL,
  USER_CONNECTION_NOTE_URL
} from 'services/constants'
import BookOneOnOne from 'components/organisms/myTeam/BookOneOnOne'
import { CreateNoteButton } from './ContactNotes'

const EVENTS_PER_GROUP = 3
const headerButtonProps = {
  inverseTheme: true,
  size: 'small',
  sx: {
    backgroundColor: colors.MetalGrey,
    textWrap: 'nowrap',
    borderWidth: 0
  }
}
export const EventList = ({
  title,
  events,
  name,
  nameRel,
  headingButton,
  noEventsMsg,
  totalEventCount,
  paginateEvents,
  refreshCallback,
  isDecendingSort = false,
  isNotes = false,
  isPast = false,
  isFixedHeight = false
}) => {
  const { t, i18n } = useTranslation()
  moment.locale(i18n.resolvedLanguage)
  const [nbVisibleEvents, setNbVisibleEvents] = useState(0)

  const increaseVisibleEvents = async () => {
    const newNum = nbVisibleEvents + EVENTS_PER_GROUP
    if (paginateEvents) {
      await paginateEvents()
    }
    setNbVisibleEvents(Math.min(totalEventCount, newNum))
  }

  useEffect(() => {
    increaseVisibleEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalEventCount])

  return (
    <BoxWithBorder
      borderColor={colors.LightGrey200}
      sx={{
        height: isFixedHeight ? '100%' : null,
        flex: 1,
        padding: 10,
        width: '100%'
      }}
      borderRadius={3}
      borderWidth={1}>
      <InCardStack
        alignItems='flex-start'
        height={isFixedHeight ? '100%' : null}>
        <RowStack justifyContent='space-between' height={32}>
          <RowStack spacing={3}>
            <Typography variant='h4'>{title}</Typography>
            {totalEventCount !== 0 && (
              <Typography
                variant='body1Small'
                sx={{
                  textWrap: 'nowrap',
                  color: colors.SpunPearl
                }}>
                (
                {t('myTeam.numeratorOfDenomiator', {
                  numerator: nbVisibleEvents,
                  denomiator: totalEventCount
                })}
                )
              </Typography>
            )}
          </RowStack>
          {headingButton}
        </RowStack>
        <InCardStack
          sx={{
            height: isFixedHeight ? '100%' : null,
            overflow: 'auto',
            width: '100%'
          }}
          spacing={4}>
          {totalEventCount === 0 ? (
            <>
              <Typography variant='body1' alignSelf='stretch' textAlign='left'>
                {noEventsMsg}
              </Typography>
              <EventRowCard isBlank />
            </>
          ) : (
            events
              .map(x => ({
                ...x,
                startTime: isNotes ? x.updatedAt : x.startTime
              }))
              .sort((a, b) => {
                const diff = moment(a.startTime).diff(moment(b.startTime))
                return isDecendingSort ? diff : -diff
              })
              .slice(0, nbVisibleEvents)
              .map((x, _) => (
                <EventRowCard
                  name={name}
                  nameRel={nameRel}
                  isNotes={isNotes}
                  isPast={isPast}
                  key={uuidv4()}
                  event={x}
                  noteRefreshCallback={refreshCallback}
                />
              ))
          )}
        </InCardStack>

        {totalEventCount !== 0 && (
          <StyledButton
            onClick={increaseVisibleEvents}
            disabled={totalEventCount === nbVisibleEvents}
            variant='outlined'
            size='small'
            sx={{
              backgroundColor: colors.Transparent,
              borderColor: colors.Grey300,
              color: colors.Grey400,
              '&:disabled': {
                backgroundColor: colors.Grey50,
                borderWidth: 0,
                color: colors.Grey300
              }
            }}>
            {t('common.loadMore')}
          </StyledButton>
        )}
      </InCardStack>
    </BoxWithBorder>
  )
}

// Wrapper around EventList to fetch/display past events
export const PastEventList = ({
  name,
  nameRel,
  userConnectionId,
  ...props
}) => {
  const { t } = useTranslation()
  const [pastEvents, setPastEvents] = useState([])
  const [pagination, setPagination] = useState({ next: 1, total: 0 })
  const loadTime = moment.utc().toISOString()

  const paginateEvents = async () => {
    if (pagination.next) {
      const res = await getRequest(USER_CALENDAR_EVENTS_URL, {
        userConnectionId,
        limit: EVENTS_PER_GROUP,
        page: pagination.next,
        historicalEvents: true,
        internalEventOnly: true,
        maxDateTime: loadTime
      })
      if (res.status === 200) {
        setPastEvents([...pastEvents, ...res.data.data.data])
        setPagination(res.data.data.pagination)
      }
    }
  }
  return (
    <EventList
      isPast
      title={t('myTeam.pastOneOnOne')}
      name={name}
      nameRel={nameRel}
      events={pastEvents}
      paginateEvents={paginateEvents}
      totalEventCount={pagination.total}
      userConnectionId={userConnectionId}
      noEventsMsg={t('myTeam.noPastMeetings')}
      {...props}
    />
  )
}

// Wrapper around EventList to fetch/display upcoming events
export const UpcomingEventList = ({
  name,
  nameRel,
  userConnectionId,
  userConnectionEmail,
  showBookOneOnOne
}) => {
  const { t } = useTranslation()
  const [upcomingEvents, setUpcomingEvents] = useState([])

  useEffect(() => {
    const fetchUpcomingEvents = async () => {
      const upcoming = await getUpcomingEvents(userConnectionId)
      setUpcomingEvents(upcoming)
    }
    if (userConnectionId) {
      fetchUpcomingEvents()
    }
  }, [userConnectionId])

  return (
    <EventList
      isDecendingSort
      title={t('contact.upcomingMeetings')}
      events={upcomingEvents}
      userConnectionId={userConnectionId}
      totalEventCount={upcomingEvents.length}
      name={name}
      nameRel={nameRel}
      noEventsMsg={t('myTeam.noUpcomingMeetings')}
      headingButton={
        showBookOneOnOne && (
          <BookOneOnOne
            userConnectionId={userConnectionId}
            connectionName={nameRel}
            userConnectionEmail={userConnectionEmail}
            buttonProps={headerButtonProps}
            buttonLabel={t('myTeam.bookOneOnOne')}
          />
        )
      }
    />
  )
}

// Wrapper around EventList to fetch/display notes
export const ConnectionNotesList = ({ userConnectionId }) => {
  const { t } = useTranslation()
  const [notes, setNotes] = useState([])

  const fetchNotes = async () => {
    const res = await getRequest(USER_CONNECTION_NOTE_URL, { userConnectionId })
    if (res.status === 200) {
      setNotes(res.data.data)
    }
  }

  useEffect(() => {
    fetchNotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConnectionId])

  return (
    <EventList
      isNotes
      title={t('common.notes')}
      userConnectionId={userConnectionId}
      totalEventCount={notes.length}
      noEventsMsg={t('myTeam.noNotes')}
      events={notes}
      refreshCallback={fetchNotes}
      headingButton={
        <CreateNoteButton
          buttonProps={headerButtonProps}
          userConnectionId={userConnectionId}
          refreshCallback={fetchNotes}
        />
      }
    />
  )
}
