import { Button, IconButton, useTheme } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import colors from 'styles/colors'

const StyledButton = ({
  children,
  sx,
  inverseTheme,
  inverseColor,
  size,
  disabled,
  ...otherProps
}) => {
  const theme = useTheme()

  return (
    <Button
      sx={[
        {
          fontWeight: 600,
          borderRadius: 20,
          boxShadow: 'none',
          '&:disabled': {
            borderColor: colors.LightGrey200,
            color: colors.LightGrey200
          },
          minWidth: 'unset'
        },
        size === 'small'
          ? { paddingX: 8, paddingY: 4, fontSize: 13 }
          : { paddingX: 10, paddingY: 6, fontSize: 16 },
        inverseTheme
          ? {
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${inverseColor ?? theme.palette.primary.main}`,
              color: inverseColor ?? theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.light
              }
            }
          : undefined,
        sx
      ]}
      variant={disabled ? 'outlined' : 'contained'}
      disabled={disabled}
      {...otherProps}>
      {children}
    </Button>
  )
}

export const InverseIconButton = ({ icon, onClick }) => {
  const theme = useTheme()
  return (
    <IconButton
      onClick={onClick}
      sx={{
        color: theme.palette.primary.main,
        background: theme.palette.primary.contrastText,
        '&:hover': {
          background: theme.palette.grey.A200,
          color: theme.palette.primary.dark
        }
      }}>
      {icon}
    </IconButton>
  )
}

export const LowConstrastButton = props => {
  return (
    <StyledButton
      sx={{
        textWrap: 'nowrap',
        backgroundColor: colors.LightBlue,
        color: colors.BondiBlue,
        '&:hover': { backgroundColor: colors.LightBlue }
      }} 
      {...props}
      />
  )
}

export const NoBorderButton = props => {
  return (
    <StyledButton
      sx={{
        border: 'none',
        backgroundColor: colors.Transparent,
        color: colors.Black,
        '&:hover': { border: 'none', color: colors.Black }
      }}
      inverseTheme={true}
      {...props}
    />
  )
}

export const RoundIconButton = ({ icon, ...otherProps }) => {
  return (
    <StyledButton sx={{ height: 48, width: 48, minWidth: 48 }} {...otherProps}>
      {icon}
    </StyledButton>
  )
}
export const BackIconButton = props => {
  return <RoundIconButton icon={<ArrowBack />} {...props} />
}

export const NextIconButton = props => {
  return <RoundIconButton icon={<ArrowForward />} {...props} />
}

export default StyledButton
