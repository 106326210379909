import { Button } from '@mui/material'
import SuccessfulNotificationDialog from 'components/molecules/notificationOverlay/SuccessfulNotificationDialog'
import { useState } from 'react'
import { FORGOT_PASSWORD_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'

const ChangePasswordButton = () => {
  const { t } = useTranslation()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const handleChangePasswordClick = async () => {
    const user = getLocalUser()
    const res = await postRequest(FORGOT_PASSWORD_URL, {
      email: user.email
    })
    if (res.status === 200) {
      setSuccessDialogOpen(true)
    }
  }
  return (
    <>
      <Button onClick={() => handleChangePasswordClick()}>
      {t('profile.changePassword')}
      </Button>
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        buttonAction={() => setSuccessDialogOpen(false)}
        titleText={t('profile.requestSubmitted')}
        notificationText={t('profile.checkYourEmail')}
        confirmButtonText={t('common.ok')}
      />
    </>
  )
}

export default ChangePasswordButton
