// A wrapper for <Route> that redirects to the login
// Reference from https://www.robinwieruch.de/react-router-private-routes/

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { LOGIN } from './constants'
import { permitted } from 'utils/permissionCheck'

const PrivateRoute = ({ children, permission, checkVerified=false, redirectPath = LOGIN }) => {
  if (!permitted(permission, checkVerified)) {
    return <Navigate to={redirectPath} />
  }

  return children ? children : <Outlet />
}

export default PrivateRoute
