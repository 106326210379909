import { Box } from '@mui/material'

const FixHeightScrollBox = ({ children }) => (
  <Box
    height='inherit'
    width='100%'
    sx={{
      overflowY: 'auto'
    }}>
    {children}
  </Box>
)

export default FixHeightScrollBox
