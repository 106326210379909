import JourneyContents from 'components/organisms/JouneyContents'
import InternalPage from 'components/templates/navigationPageTemplates/InternalPage'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GOALS } from 'routes/constants'
import { GOAL_STEP_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { getLocalUser } from 'utils/localStorage'

const ContentEditor = () => {
  const params = useParams()
  const [goalId, setGoalId] = useState(params.goalId)
  const [goalStepsData, setGoalStepsData] = useState(undefined)

  useEffect(() => {
    const fetchGoalSteps = async _goalId => {
      const res = await getRequest(GOAL_STEP_URL, {goalId: parseInt(_goalId, 10)})
      if (res.status === 200) {
        setGoalStepsData(res.data.data)
      }
    }

    let goalToFetch = goalId
    if (!goalId) {
      const user = getLocalUser()
      const userGoal = user.userStates.currentGoalId
      if (!userGoal) {
        goalToFetch = 2
      } else {
        goalToFetch = userGoal
      }
      setGoalId(goalToFetch)
    }
    if (!goalStepsData) {
      fetchGoalSteps(goalToFetch)
    }
  }, [goalId, goalStepsData])

  return (
    <InternalPage activePage={GOALS} pageTitle='Content Editor'>
      <JourneyContents
        goalId={goalId}
        steps={goalStepsData}
        editorMode={true}
        userStates={{}}
      />
    </InternalPage>
  )
}

export default ContentEditor
