import React, { useEffect, useState } from 'react'
import MyTeamPage from 'components/templates/navigationPageTemplates/MyTeamPage'
import SearchInputBox from 'components/atoms/input/SearchInputBox'
import { Stack, Typography, Box, Divider } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import { getUserConnections } from 'services/userServices'
import StyledButton from 'components/atoms/button/StyledButton'
import { USER_CONNECTION_RELATIONSHIP } from 'services/constants'
import TeamList from 'components/molecules/myTeam/TeamList'
import OverlayLoading from 'components/atoms/OverlayLoading'
import ConfirmCancelDialog from 'components/molecules/notificationOverlay/ConfirmCancelDialog'
import ConnectionUserItem from 'components/molecules/myTeam/ConnectionUserItem'
import AddConnectionButton from 'components/molecules/myTeam/AddConnectionButton'
import InCardStack from 'components/atoms/InCardStack'
import BookOneOnOne from 'components/organisms/myTeam/BookOneOnOne'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'

const nonOtherRelationships = [
  USER_CONNECTION_RELATIONSHIP[0].value,
  USER_CONNECTION_RELATIONSHIP[1].value,
  USER_CONNECTION_RELATIONSHIP[2].value,
  USER_CONNECTION_RELATIONSHIP[3].value
]
const seniorRelationships = [
  USER_CONNECTION_RELATIONSHIP[1].value,
  USER_CONNECTION_RELATIONSHIP[2].value
]
const otherRelationships = [USER_CONNECTION_RELATIONSHIP[4].value]
const colleagueRelationship = [USER_CONNECTION_RELATIONSHIP[3].value]
const reportRelationship = [USER_CONNECTION_RELATIONSHIP[0].value]

const UserConnections = () => {
  const { t } = useTranslation()
  const [userConnections, setUserConnections] = useState([])
  const [otherUserConnections, setOtherUserConnections] = useState([])
  const [filterText, setFilterText] = useState('')
  const [otherDialogOpen, setOtherDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const res = await getUserConnections()
    if (res.length) {
      setUserConnections(res.filter(u => u.relationship))
      setOtherUserConnections(res.filter(u => !u.relationship))
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const getConnectionsByType = types =>
    userConnections.filter(u => types.indexOf(u.relationship) >= 0)
  const getConnectionCountByType = types => {
    const list = getConnectionsByType(types)
    return list.length
  }

  return (
    <MyTeamPage>
      <Stack width='100%' spacing={10} alignItems='center'>
        <RowStack
          width='100%'
          sx={{
            justifyContent: 'space-between'
          }}>
          <Typography variant='h1' width={400}>
            {t('headers.myTeam')}
          </Typography>

          {getConnectionCountByType(nonOtherRelationships) ? (
            <SearchInputBox
              updatedStyle
              onChangeHandler={e => setFilterText(e.target.value.toLowerCase())}
              placeholder={t('myTeam.searchConnection')}
              sx={{ paddingBottom: '0!important' }}
            />
          ) : null}

          <RowStack sx={{ justifyContent: 'flex-end' }} spacing={4} width={400}>
            <AddConnectionButton
              users={otherUserConnections}
              sucessCallback={() => fetchData()}
            />
            <BookOneOnOne fetchedUserConnections={userConnections} />
          </RowStack>
        </RowStack>
        <RowStack
          width='100%'
          sx={{
            justifyContent: 'space-between'
          }}>
          <Box width={250} />
          <Typography variant='h2'>
            {t('myTeam.myManagersSeniorLeaders')}
          </Typography>
          <RowStack width={250} sx={{ justifyContent: 'flex-end' }}>
            {Boolean(getConnectionCountByType(otherRelationships)) &&
              <StyledButton
                inverseTheme
                onClick={() => setOtherDialogOpen(true)}
              >
                {t('myTeam.otherConnections')}
              </StyledButton>
            }
          </RowStack>
        </RowStack>
        {getConnectionCountByType(seniorRelationships) ? (
          <TeamList
            connections={userConnections}
            filterText={filterText}
            roleList={seniorRelationships}
          />
        ) : (
          <Typography variant='h4' color={colors.Grey300}>
            {t('myTeam.nobodyHereYet')}
          </Typography>
        )}
        <Divider sx={{ width: '100%' }} />
        <Typography variant='h2'>{t('myTeam.myColleaguesMe')}</Typography>
        <TeamList
          connections={userConnections}
          filterText={filterText}
          roleList={colleagueRelationship}
          meList={true}
        />
        <Divider sx={{ width: '100%' }} />
        <Typography variant='h2'>{t('myTeam.myReports')}</Typography>
        {getConnectionCountByType(reportRelationship) ? (
          <TeamList
            connections={userConnections}
            filterText={filterText}
            roleList={reportRelationship}
          />
        ) : (
          <Typography variant='h4' color={colors.Grey300}>
            {t('myTeam.nobodyHereYet')}
          </Typography>
        )}
      </Stack>
      <OverlayLoading open={loading} />
      <ConfirmCancelDialog
        open={otherDialogOpen}
        onClose={() => setOtherDialogOpen(false)}
        titleText={t('myTeam.otherConnections')}>
        <InCardStack spacing={5}>
          {getConnectionsByType(otherRelationships).map(c => (
            <ConnectionUserItem key={`connection-${c.id}`} c={c} />
          ))}
        </InCardStack>
      </ConfirmCancelDialog>
    </MyTeamPage>
  )
}

export default UserConnections
