import { LOGIN_TYPES } from 'services/constants'
import { signin } from 'services/userServices'
import { Box, IconButton, Typography } from '@mui/material'
import { ReactComponent as GoogleLogo } from 'assets/images/google.svg'
import {
  GoogleOAuthProvider,
  GoogleLogin as ReactGoogleLogin,
  useGoogleOneTapLogin
} from '@react-oauth/google'
import { googleClientId } from 'config/config'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { jwtDecode } from 'jwt-decode'
import ErrorNotificationDialog from 'components/molecules/notificationOverlay/ErrorNotificationDialog'
import colors from 'styles/colors'

const LoginButton = ({ signinCallback, email, label }) => {
  const { t } = useTranslation()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)

  const handleSuccessLogin = async response => {
    const idToken = response.credential || response.code
    const tokenInfo = jwtDecode(idToken)

    // Check if signing up with invited email
    if (email && email !== tokenInfo.email) {
      setSuccessDialogOpen(true)
      return
    }

    const requestBody = {
      loginToken: idToken,
      login_type: LOGIN_TYPES.GOOGLE
    }
    const res = await signin(requestBody)
    signinCallback(res)
  }
  useGoogleOneTapLogin({
    auto_select: true,
    onSuccess: response => handleSuccessLogin(response)
  })
  return (
    <>
      <IconButton
        disableRipple
        sx={{
          display: 'flex',
          borderRadius: 2,
          width: '100%',
          border: '1px solid',
          borderColor: colors.LightGrey200,
          gap: 4,
          paddingY: 6
        }}
      >
        <GoogleLogo />
        <Typography>{label}</Typography>
        <Box sx={{ opacity: 0.01, zIndex: 1 }} position='absolute'>
          <ReactGoogleLogin
            shape='rectangular'
            onSuccess={response => handleSuccessLogin(response)}
            size='large'
            width='380'
          />
        </Box>
      </IconButton>

      <ErrorNotificationDialog
        open={successDialogOpen}
        titleText={t('authPages.invitationEmailMissmatchTitle')}
        notificationText={`${t('authPages.invitationEmailMissmatch.part1')}'${email}'${t('authPages.invitationEmailMissmatch.part2')}`}
        confirmButtonText={t('common.ok')}
        buttonAction={() => setSuccessDialogOpen(false)}
        success={false}
      />
    </>
  )
}
const GoogleLogin = ({ signinCallback, email, label = null }) => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <LoginButton
        label={label}
        signinCallback={signinCallback}
        email={email}
      />
    </GoogleOAuthProvider>
  )
}

export default GoogleLogin
