import { Box } from '@mui/material'
import PracticePage from 'components/templates/navigationPageTemplates/PracticePage'
import { useEffect, useState } from 'react'
import SelectVirtue from 'components/molecules/rolePlay/SelectVirtue'
import RolePlayWelcome from 'components/molecules/rolePlay/RolePlayWelcome'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import { getRequest } from 'utils/api'
import { ROLE_PLAYS_URL } from 'services/constants'
import FixHeightScrollBox from 'components/atoms/container/FixHeightScrollBox'
import RowStack from 'components/atoms/container/RowStack'
import InCardStack from 'components/atoms/InCardStack'
import RolePlayGrids from 'components/molecules/rolePlay/RolePlayGrids'

const AllScenarios = () => {
  const [rolePlays, setRolePlays] = useState([])
  const [selectedVirtueId, setSelectedVirtueId] = useState('')

  useEffect(() => {
    const getRolePlays = async () => {
      const res = await getRequest(ROLE_PLAYS_URL)
      if (res.status === 200) {
        setRolePlays(res.data.data)
      }
    }
    getRolePlays()
  }, [])

  return (
    <PracticePage>
      <Box height='inherit' overflow='hidden' paddingTop={5}>
        <BoxWithBackground
          alignItems='center'
          width='100%'
          sx={{ paddingX: 10, height: '100%',paddingTop: 30, }}>
          <InCardStack width='100%' spacing={20} height='inherit'>
            <RowStack
              sx={{
                paddingX: 20,
                justifyContent: 'space-between',
                alignItems: 'end'
              }}>
              <RolePlayWelcome />
              <SelectVirtue
                virtueId={selectedVirtueId}
                setVirtueId={setSelectedVirtueId}
                rolePlays={rolePlays}
              />
            </RowStack>
            <FixHeightScrollBox>
              <RolePlayGrids rolePlays={rolePlays} selectedVirtueId={selectedVirtueId} />
            </FixHeightScrollBox>
          </InCardStack>
        </BoxWithBackground>
      </Box>
    </PracticePage>
  )
}

export default AllScenarios
