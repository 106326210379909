import { TableCell, TableRow } from '@mui/material'
import LinkRoute from 'components/atoms/LinkRoute'
import DateCell from 'components/atoms/table/DateCell'
import { PULSE_SURVEY_DETAIL } from 'routes/constants'
import { PULSE_SURVEY_STATUSES } from 'services/constants'
import UsersIconPopupList from './UsersIconPopupList'
import { useTranslation } from 'react-i18next'

const PulseSurveyRow = props => {
  const { t } = useTranslation()
  const { surveyData } = props
  const statusText =
    surveyData.status === PULSE_SURVEY_STATUSES.CLOSED
      ? t('pulseSurvey.closed')
      : surveyData.status === PULSE_SURVEY_STATUSES.OPEN
      ? t('pulseSurvey.open')
      : t('pulseSurvey.closedInsufficientRaters')

  return (
    <TableRow data-testid={`pulse-survey-row-${surveyData.id}`}>
      <TableCell>
        {surveyData.pulseSurveyQuestionText ||
          surveyData.pulseSurveyQuestion?.statementText ||
          surveyData.pulseSurveyQuestion?.questionText}
      </TableCell>
      <TableCell>{statusText}</TableCell>
      <DateCell value={surveyData.sendAt} />
      <DateCell
        value={
          surveyData.status === PULSE_SURVEY_STATUSES.CLOSED
            ? surveyData.updatedAt
            : null
        }
      />
      <TableCell>{surveyData.avgRating?.toFixed(1)}</TableCell>
      <TableCell>
        <UsersIconPopupList
          users={surveyData.recipients}
          parentKey={surveyData.id}
          popupTitle={t('pulseSurvey.allRecipients')}
        />
      </TableCell>
      <TableCell>
        <LinkRoute
          to={`${PULSE_SURVEY_DETAIL}/${surveyData.id}`}
          state={{ surveyData }}>
          {t('pulseSurvey.viewResults')}
        </LinkRoute>
      </TableCell>
    </TableRow>
  )
}

export default PulseSurveyRow
