import CircularProgressBar from 'components/atoms/CircularProgressBar'
import StyledAccordion from 'components/atoms/container/Accordion'
import { Box, Stack, Typography, Button, Grid } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'
import { CircleNumber } from 'components/atoms/Text'
import { lightGreen, yellow, red } from '@mui/material/colors'
import { DownRed, UpGreen } from 'components/atoms/Icons'
import ToolTipCustomized from 'components/atoms/container/ToolTipCustomized'
import InCardStack from 'components/atoms/InCardStack'

const SuperLeaderScore = ({ SCORE_TABS, setActiveScoreTab, scoreData }) => {
  const { t } = useTranslation()
  const textProps = {
    variant: 'body2',
    fontSize: '14px',
    lineHeight: 1.75,
    width: '80%'
  }

  const ScoreButton = ({ children, tab }) => {
    return (
      <Grid item xs={3} display='flex'>
        <Button
          variant='text'
          onClick={() => setActiveScoreTab(tab)}
          sx={{
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}>
          {children}
        </Button>
      </Grid>
    )
  }

  const getGrowthValue = value => {
    return (
      <Box display='flex' alignItems='center'>
        {value > 0 ? (
          <>
            <UpGreen sx={{ fontSize: 14, marginRight: 4 }} />{' '}
            {`${value.toFixed()}%`}
          </>
        ) : value < 0 ? (
          <>
            <DownRed sx={{ fontSize: 14, marginRight: 4 }} />{' '}
            {`${Math.abs(value.toFixed())}%`}
          </>
        ) : (
          <>-</>
        )}
      </Box>
    )
  }

  const getGrowthColor = value => {
    if (value > 0) return lightGreen[100]
    else if (value < 0) return red[100]
    else return yellow[100]
  }

  const MatrixText = ({ name, titleComponent, list }) => (
    <Grid item xs={3}>
      <Typography fontWeight='bold'>{titleComponent}</Typography>
      {list.map((item, index) => (
        <Typography
          {...textProps}
          marginLeft='30px'
          display='list-item'
          key={`${name}-${index}`}>
          {t(item)}
        </Typography>
      ))}
    </Grid>
  )
  return (
    <Stack spacing={10}>
      <InCardStack>
        <Typography variant='h2'>{t('insights.superLeaderScores')}</Typography>
      </InCardStack>

      <Grid container>
        <ScoreButton tab={SCORE_TABS.DEVELOPMENT}>
          <CircularProgressBar
            value={scoreData.development}
            zeroValueLabel={t('insights.noData')}
          />
          <Typography variant='subtitle1'>
            {t('insights.development')}
          </Typography>
        </ScoreButton>

        <ScoreButton tab={SCORE_TABS.COMMITMENT}>
          <CircularProgressBar
            value={scoreData.commitment}
            zeroValueLabel={t('insights.noData')}
          />
          <Typography variant='subtitle1'>
            {t('insights.commitment')}
          </Typography>
        </ScoreButton>

        <ScoreButton tab={SCORE_TABS.GROWTH}>
          <CircleNumber
            value={getGrowthValue(scoreData.growth)}
            color={getGrowthColor(scoreData.growth)}
            textColor='default'
            variant='body1'
            size='90px'
          />
          <Typography variant='subtitle1'>{t('insights.growth')}</Typography>

          {!scoreData.growth && (
            <ToolTipCustomized
              toolTip={
                <Stack padding={5} spacing={2}>
                  <Typography fontWeight='bolder' variant='body1SmallWhite'>
                    {t('insights.whyNoScore')}
                  </Typography>
                  <Typography variant='body1SmallWhite'>
                    {t('insights.orderToShowGrowthScore')}
                  </Typography>
                </Stack>
              }
            />
          )}
        </ScoreButton>

        <ScoreButton tab={SCORE_TABS.TEAM}>
          <CircularProgressBar
            value={scoreData.teamStrength}
            zeroValueLabel={t('insights.noData')}
          />
          <Typography variant='subtitle1'>
            {t('insights.teamStength')}
          </Typography>
        </ScoreButton>
      </Grid>

      <StyledAccordion
        initialExpanded={false}
        sx={{
          width: '100%',
          boxShadow: 'none',
          '&.MuiAccordion-root::before': {
            display: 'none'
          }
        }}
        summaryComponent={
          <Typography
            variant='body1'
            sx={{ color: colors.CaribbeanBlue, flexGrow: 0 }}>
            <span className='showText'>{t('insights.showDescriptions')}</span>
            <span className='hideText'>{t('insights.hideDescriptions')}</span>
          </Typography>
        }
        summaryProps={{
          sx: {
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              flexGrow: 0
            },
            '&.Mui-expanded': {
              '& .showText': {
                display: 'none'
              },
              '& .hideText': {
                display: 'block'
              }
            },
            '& .showText': {
              display: 'block'
            },
            '& .hideText': {
              display: 'none'
            }
          }
        }}
        detailProps={{ sx: { paddingX: 0 } }}
        style={{
          marginTop: '0px'
        }}>
        <RowStack sx={{ alignItems: 'flexStart' }}>
          <Typography {...textProps}>
            {t('insights.focusesOnLeadersProactiveEfforts')}
          </Typography>

          <Typography {...textProps}>
            {t('insights.assessesLeadersDedication')}
          </Typography>

          <Typography {...textProps}>
            {t('insights.measuresLeadersDevelopment')}
          </Typography>

          <Typography {...textProps}>
            {t('insights.evaluatesLeadersTeam')}
          </Typography>
        </RowStack>
      </StyledAccordion>

      <Grid container>
        <MatrixText
          titleComponent={t('insights.includedMetrics')}
          name='development'
          list={[
            'insights.includedMetrics',
            'insights.learningCheckScores',
            'insights.facilitatedSessionAttendance',
            'insights.feedbackSeeking'
          ]}
        />

        <MatrixText
          titleComponent={t('insights.includedMetrics')}
          name='engagement'
          list={[
            'insights.intentionToStay',
            'insights.engagement',
            'insights.workSatisfaction',
            'insights.burnout'
          ]}
        />

        <MatrixText
          titleComponent={t('insights.includedMetrics')}
          name='growth'
          list={['insights.changesInAssessmentScores']}
        />

        <MatrixText
          titleComponent={
            <>
              {t('insights.includedMetrics')}
              <br />
              {t('insights.directReports')}
            </>
          }
          name='directReports'
          list={[
            'insights.intentionToStay',
            'insights.engagement',
            'insights.workSatisfaction',
            'insights.burnout'
          ]}
        />
      </Grid>
    </Stack>
  )
}

export default SuperLeaderScore
