import {
  Alert,
  Box,
  FormControl,
  RadioGroup,
  Typography,
  useTheme,
  Stack
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const QuestionOptionsTemplate = ({
  question,
  options,
  correctAnswer,
  correctMessage,
  wrongMessage,
  handleOnValueChange,
  initialValue = null,
  optionMarginX = 10,
  showSummary = true,
  submitError = false,
  ...otherProps
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [currentValue, setCurrentValue] = useState(initialValue)
  const onChange = event => {
    setCurrentValue(event.target.value)
    if (handleOnValueChange !== undefined) {
      handleOnValueChange(event)
    }
  }
  return (
    <Stack spacing={5} sx={{ paddingBottom: 0 }} {...otherProps}>
      <Typography alignSelf='flex-start'>{question}</Typography>
      <FormControl
        name={'question-option-form'}
        // fullWidth
        sx={{
          paddingX: optionMarginX,
          backgroundColor: 'none'
        }}>
        <Box
          borderRadius={5}
          padding={5}
          paddingLeft={8}
          sx={{ backgroundColor: theme.palette.background.default, border: submitError && !currentValue ? '1px solid ' +  theme.palette.error.light : 'none' }}>
          <RadioGroup
            name='question-options'
            sx={{ display: 'flex', gap: 5 }}
            value={currentValue}
            onChange={e => onChange(e)}>
            {options}
          </RadioGroup>
        </Box>
      </FormControl>
      <Box width='100%' paddingX='20px'>
        {showSummary && correctMessage ? (
          currentValue === null ? null : currentValue === correctAnswer ? (
            <Alert severity='success'>{correctMessage}</Alert>
          ) : (
            <Alert severity='error'>
              {wrongMessage || t('stepQuiz.pleaseTryAgain')}
            </Alert>
          )
        ) : null}
      </Box>
    </Stack>
  )
}

export default QuestionOptionsTemplate
