import { useState, useEffect } from 'react'
import {
  alpha,
  Box,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemText,
  Stack,
  Grid,
  Snackbar
} from '@mui/material'
import { Tag } from 'components/atoms/Text'
import RowStack from 'components/atoms/container/RowStack'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'
import { ActionText } from 'components/atoms/Text'
import InCardStack from 'components/atoms/InCardStack'
import { ROLE_PLAY_TYPES } from 'services/constants'
import { convertSecsToMins } from 'utils/formatDateTime'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import RolePlayDialog from 'components/molecules/rolePlay/RolePlayDialog'
import RolePlayCarousel from 'components/molecules/rolePlay/RolePlayCarousel'
import ConvoBoxNew from 'components/molecules/rolePlay/ConvoBoxNew'
import StyledButton from 'components/atoms/button/StyledButton'
import { useNavigate } from 'react-router-dom'
import { ALL_SCENARIOS } from 'routes/constants'
import MuiMarkdown from 'mui-markdown'

const RolePlayResults = ({
  selectedRolePlay,
  selectedUserRolePlay,
  rolePlays,
  onLearningPath = false,
  setNewRolePlay = () => {}
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogParams, setDialogParams] = useState({})
  const [hasImprove, setHasImprove] = useState(false)
  const [hasWell, setHasWell] = useState(false)

  const ReviewBox = ({ children, bg, md = 6 }) => (
    <Grid item md={md}>
      <Box
        padding={12}
        sx={{
          borderRadius: 5,
          backgroundColor: alpha(bg, 0.05),
          height: '100%'
        }}>
        {children}
      </Box>
    </Grid>
  )

  const ReviewList = ({ items, type }) => (
    <List sx={{ listStyle: 'decimal', pl: 8 }}>
      {items.map((summary, index) => (
        <ListItem key={`review-${type}-${index}`} sx={{ display: 'list-item' }}>
          <ListItemText primary={summary} />
        </ListItem>
      ))}
    </List>
  )

  const PopupButton = ({ icon, label, type }) => (
    <ActionText
      style={{ color: colors.Aluminium }}
      onClick={() => openRolePlayDialog(type)}>
      <RowStack spacing={3}>
        {icon}
        <Typography>{t(label)}</Typography>
      </RowStack>
    </ActionText>
  )

  const openRolePlayDialog = paramsFor => {
    const newDialogParams = {}
    if (paramsFor === 'notes') {
      newDialogParams.titleText = t('rolePlay.yourNotes')
      newDialogParams.width = '30%'
      newDialogParams.children = selectedUserRolePlay.notes
    } else if (paramsFor === 'scenario') {
      newDialogParams.titleText = t('rolePlay.scenario')
      newDialogParams.children = (
        <Box
          dangerouslySetInnerHTML={{
            __html: selectedRolePlay.context?.html
          }}
        />
      )
    } else if (paramsFor === 'transcript') {
      newDialogParams.titleText = t('rolePlay.transcript')
      newDialogParams.width = '42%'
      newDialogParams.forTranscript = true
      newDialogParams.children = (
        <ConvoBoxNew
          height={'80vh'}
          messages={selectedUserRolePlay.messages}
          rolePlay={selectedRolePlay}
          forPopup={true}
          hidePopup={() => setDialogOpen(false)}
        />
      )
    }

    setDialogParams(newDialogParams)
    setDialogOpen(true)
  }

  const tryRolePlayAgain = () => {
    setNewRolePlay(selectedRolePlay.id, selectedUserRolePlay.type)
  }

  useEffect(() => {
    setHasWell(selectedUserRolePlay?.parsedReview?.WHAT_YOU_DID_WELL?.length > 0)
    setHasImprove(selectedUserRolePlay?.parsedReview?.WHAT_YOU_COULD_IMPROVE_ON?.length > 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRolePlay])

  return (
    <>
      <Stack padding={0} gap={5}>
        <Typography
          textTransform='uppercase'
          sx={{ marginBottom: '-10px', color: colors.Aluminium }}>
          {t('rolePlay.scenario')}
        </Typography>

        <RowStack justifyContent={'space-between'}>
          <Typography variant='h1' maxWidth={'60%'}>
            {selectedRolePlay.title} {t('rolePlay.results')}
          </Typography>

          <StyledButton onClick={tryRolePlayAgain}>
            {t('rolePlay.tryAgain')}
          </StyledButton>
        </RowStack>

        <RowStack justifyContent={'space-between'}>
          <RowStack spacing={3} width={'auto'}>
            <Tag>{selectedRolePlay.virtue.name}</Tag>

            {selectedUserRolePlay?.type === ROLE_PLAY_TYPES.LIVE_CALL ? (
              <Tag>{t('rolePlay.liveCall')}</Tag>
            ) : (
              <Tag>{t('rolePlay.textChat')}</Tag>
            )}
            <Tag>{`${t('rolePlay.length')}: ${convertSecsToMins(selectedUserRolePlay?.conversationSeconds ?? 0)}`}</Tag>
          </RowStack>
          <RowStack spacing={10} width='unset'>
            <PopupButton
              type='transcript'
              icon={<TextSnippetOutlinedIcon />}
              label='rolePlay.transcript'
            />

            {selectedUserRolePlay?.notes && (
              <PopupButton
                type='notes'
                icon={<DescriptionOutlinedIcon />}
                label='rolePlay.yourNotes'
              />
            )}
            <PopupButton
              type='scenario'
              icon={<ArticleOutlinedIcon />}
              label='rolePlay.scenario'
            />
          </RowStack>
        </RowStack>
      </Stack>

      {selectedUserRolePlay?.parsedReview?.OVERALL_SUMMARY?.length > 0 && (
        <InCardStack spacing={2} alignItems={'flex-start'}>
          <Typography variant='h3'>{t('rolePlay.overallSummary')}</Typography>

          {selectedUserRolePlay.parsedReview.OVERALL_SUMMARY.map(
            (summary, index) => (
              <Typography key={`summary-${index}`} color={colors.Aluminium}>
                {summary}
              </Typography>
            )
          )}
        </InCardStack>
      )}
      <Box width='100%' left={-12} top={-12} paddingBottom={12}>
        <Grid container spacing={12} justify='center'>
          {!selectedUserRolePlay?.parsedReview?.OVERALL_SUMMARY?.length ? (
            <ReviewBox bg={theme.palette.grey[800]} md={12}>
              <MuiMarkdown>{selectedUserRolePlay?.review}</MuiMarkdown>
            </ReviewBox>
          ) : (
            <>
              {hasWell && (
                <ReviewBox md={hasImprove ? 6 : 12} bg={colors.BrightGreen}>
                  <Typography
                    variant='h3'
                    style={{ color: colors.BrightGreen }}>
                    {t('rolePlay.whatYouDidWell')}
                  </Typography>
                  <ReviewList
                    items={selectedUserRolePlay.parsedReview.WHAT_YOU_DID_WELL}
                  />
                </ReviewBox>
              )}

              {hasImprove && (
                <ReviewBox md={hasWell ? 6 : 12} bg={colors.Cinnabar}>
                  <Typography variant='h3' style={{ color: colors.Cinnabar }}>
                    {t('rolePlay.whatYouCouldImproveOn')}
                  </Typography>
                  <ReviewList
                    items={
                      selectedUserRolePlay.parsedReview
                        .WHAT_YOU_COULD_IMPROVE_ON
                    }
                  />
                </ReviewBox>
              )}
            </>
          )}

          <ReviewBox bg={theme.palette.grey[800]} md={12}>
            <RowStack justifyContent={'space-between'}>
              <InCardStack spacing={2} alignItems={'flex-start'} width={'60%'}>
                <Typography variant='h2'>
                  {t('rolePlay.morePracticeScenarios')}
                </Typography>

                <Typography variant='body1'>
                  {t('rolePlay.chooseAScenario')}
                </Typography>
              </InCardStack>

              <StyledButton onClick={() => navigate(ALL_SCENARIOS)}>
                {t('rolePlay.seeAllScenarios')}
              </StyledButton>
            </RowStack>

            { (rolePlays?.length && selectedRolePlay?.virtueId) ? (
              // DO NOT remove the display flex. That bugs out the carousel
              <Box height={440} display='flex' marginTop={12}>
                <RolePlayCarousel
                  rolePlays={rolePlays}
                  virtueId={selectedRolePlay?.virtueId}
                  setNewRolePlay={setNewRolePlay}
                />
              </Box>
            ) : null}
          </ReviewBox>
        </Grid>
      </Box>
      <RolePlayDialog
        open={dialogOpen}
        titleText={dialogParams.titleText}
        children={dialogParams.children}
        width={dialogParams.width}
        forTranscript={dialogParams.forTranscript}
        confirmButtonText={t('common.done')}
        handleCancel={() => setDialogOpen(false)}
      />
      <Snackbar
        open={onLearningPath}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={t('rolePlay.wouldYouTryExerciseAgain')}
        action={
          <StyledButton onClick={tryRolePlayAgain} size='small'>
            {t('rolePlay.yesTryAgain')}
          </StyledButton>
        }
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: colors.WhiteLilac,
            color: colors.Black,
            borderRadius: 12,
          }
        }}
      />
    </>
  )
}

export default RolePlayResults
