import { useRef, useEffect } from 'react'
import {
  Box,
  Grid,
  Stack,
  LinearProgress,
  Typography,
  DialogTitle,
  IconButton
} from '@mui/material'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import BoxWithBackground from '../../atoms/container/BoxWithBackground'
import { getLocalUser } from 'utils/localStorage'
import ProfileAvatar from '../../atoms/ProfileAvatar'
import colors from 'styles/colors'
import FormInputBox from 'components/atoms/input/FormInputBox'
import { useTranslation } from 'react-i18next'
import { Close } from '@mui/icons-material'
import CharacterAvatar from 'components/molecules/rolePlay/CharacterAvatar'
import StyledButton from '../../atoms/button/StyledButton'
import RowStack from 'components/atoms/container/RowStack'

// Similar to ConvoBox but with the new styles!
const ConvoBoxNew = ({
  messages,
  isLoading,
  rolePlay,
  handleCancel = () => {},
  setIsPopupFinish = () => {},
  formik = {},
  forPopup = false,
  hidePopup = () => {},
  ...styles
}) => {
  const { t } = useTranslation()
  const boxPadding = { paddingX: 10, paddingY: 7 }
  const scrollRef = useRef(null)
  const user = getLocalUser()

  const AiAvatar = () => <CharacterAvatar rolePlay={rolePlay} />

  const messagesHtml = messages?.map((item, i) => (
    <Grid container paddingY={2} key={`convo-text-${i}`}>
      <Grid item>
        <Box padding='7px 0' alignSelf='flex-start'>
          {item.role !== 'user' ? (
            <AiAvatar />
          ) : (
            <ProfileAvatar size='small' user={user} />
          )}
        </Box>
      </Grid>

      <Grid item display='flex' alignItems='center'>
        <Typography paddingX={6} variant='h6'>
          {item.role === 'user'
            ? user.fullName
            : rolePlay?.context?.characterName || 'Alex Smith'}
        </Typography>
      </Grid>

      <Grid item xs={12} paddingLeft={6}>
        <BoxWithBackground borderRadius='0 20px 20px 20px' {...boxPadding}>
          <Typography variant='body1Small' color={colors.Black}>
            {item.content}
          </Typography>
        </BoxWithBackground>
      </Grid>
    </Grid>
  ))

  useEffect(() => {
    const scrollToSecondLastMessage = () => {
      if (scrollRef.current) {
        const scroller = scrollRef.current
        const messages = Array.from(scroller.children)

        if (messages.length < 3) {
          return
        }

        // Height of the last two messages
        const messageHeight = messages
          .slice(-2)
          .reduce((acc, c) => acc + c.clientHeight, 0)
        const extraPadding = Math.max(scroller.clientHeight - messageHeight, 0)
        const scrollTo = scroller.scrollHeight - messageHeight

        if (isLoading) {
          scroller.style.paddingBottom = `${extraPadding}px`
          scroller.scrollTo({ left: 0, top: scrollTo, behavior: 'smooth' })
        } else {
          // Leave minimum 20 at the bottom
          if (!forPopup) {
            scroller.style.paddingBottom = `${Math.max(20, extraPadding)}px`
          } else {
            scroller.style.padding = `${20}px`
          }
        }
      }
    }

    scrollToSecondLastMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <Box data-testid='convo-box'>
      <Box borderRadius={4} boxShadow={2}>
        <Box
          borderRadius='16px 16px 0 0'
          backgroundColor={colors.DarkBlueishGreenish}
          height={50}
          display='flex'
          alignItems='center'
          padding={3}
          paddingRight={6}>
          {!forPopup ? (
            <RowStack justifyContent='flex-end'>
              <StyledButton
                size='small'
                onClick={() => handleCancel(true)}
                sx={{
                  backgroundColor: colors.Transparent,
                  '&:hover': {
                    backgroundColor: colors.Transparent
                  }
                }}>
                {t('rolePlay.cancelChat')}
              </StyledButton>
              <StyledButton
                size='small'
                onClick={() => setIsPopupFinish(true)}
                disabled={!messages || messages.length < 3}>
                {t('rolePlay.endChatGetFeedback')}
              </StyledButton>
            </RowStack>
          ) : (
            <RowStack justifyContent='space-between'>
              <DialogTitle sx={{ color: colors.White }}>
                {t('rolePlay.transcript')}
              </DialogTitle>
              <IconButton
                sx={{ color: colors.White }}
                onClick={() => hidePopup()}>
                <Close />
              </IconButton>
            </RowStack>
          )}
        </Box>
        <Stack
          {...styles}
          ref={scrollRef}
          padding={0}
          sx={{
            overflowY: 'scroll',
            paddingX: 6,
            overscrollBehavior: 'contain'
          }}
          spacing={0}>
          {messagesHtml}
          {isLoading ? (
            <RowStack paddingY={boxPadding.paddingY}>
              <AiAvatar />
              <LinearProgress sx={{ width: '90%' }} />
            </RowStack>
          ) : null}
        </Stack>

        {!forPopup && (
          <Box
            backgroundColor={colors.WhiteLilac}
            height={76}
            borderRadius='0 0 16px 16px'
            padding={4}
            paddingBottom={0}>
            <FormInputBox
              position='relative'
              placeholder={t('rolePlay.sendMessageToContinue')}
              variant='outlined'
              sx={{
                width: '100%'
              }}
              InputProps={{
                sx: {
                  borderRadius: 40,
                  height: 60,
                  backgroundColor: colors.White,
                  paddingLeft: 12,
                  fontSize: 14
                }
              }}
              endAdornment={
                <StyledButton
                  endIcon={<SendRoundedIcon />}
                  size='small'
                  onClick={() => formik.handleSubmit()}>
                  {t('common.send')}
                </StyledButton>
              }
              type='text'
              formik={formik}
              multiline
              rows={2}
              attributeName='newReply'
              onKeyDown={e => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  e.preventDefault()
                  formik.handleSubmit()
                }
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ConvoBoxNew
