import { Dialog, Typography, Stack, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import { ReactComponent as MonarkLogoSvg } from 'assets/images/monark_logo.svg'
import colors from 'styles/colors'

const RotationNotificationDialog = props => {
  const { t } = useTranslation()
  const { open } = props

  return (
    <Dialog open={open}>
      <Stack alignItems='center' spacing={20}>
        <MonarkLogoSvg
          style={{ '--dot-color': colors.GoldenYellow400 }}
          width={150}
        />
        <ScreenRotationIcon sx={{ fontSize: 200 }} />
        <Box>
          <Typography align='center' textAlign='center'>
            {t('authPages.monarkWorksBestInLandscape')}
          </Typography>
        </Box>
      </Stack>
    </Dialog>
  )
}

export default RotationNotificationDialog
