import CompanyUsersCard from 'components/organisms/adminDashboard/CompanyUsersCard'
import CompanySettingsTemplate, {
  COMPANY_TABS
} from 'components/templates/CompanySettingsTemplate'

const CompanyUsers = () => {
  return (
    <CompanySettingsTemplate activeTab={COMPANY_TABS.USERS}>
      <CompanyUsersCard />
    </CompanySettingsTemplate>
  )
}

export default CompanyUsers
