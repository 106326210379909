import * as yup from 'yup'
import InputDialog from 'components/molecules/notificationOverlay/InputDialog'
import { useFormik } from 'formik'
import { validationsRules } from 'utils/formValidations'
import { postRequest } from 'utils/api'
import { ORGANIZATIONS_URL, CONSTANTS_OPTIONS } from 'services/constants'
import { useEffect, useState } from 'react'
import FormInputBox from 'components/atoms/input/FormInputBox'
import { Alert, MenuItem } from '@mui/material'
import FormSelectInput from 'components/atoms/input/FormSelectInput'
import { getConstant } from 'services/constantServices'

const AddOrganizationDialog = ({ open, createdCallback, onCancel }) => {
  const [orgCreatedSuccessful, setOrgCreatedSuccessful] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [industryOptions, setIndustryOptions] = useState([])

  const formik = useFormik({
    initialValues: {
      name: '',
      domain: '',
      industry: ''
    },
    validationSchema: yup.object({
        name: validationsRules.textRequired,
        domain: validationsRules.textRequired,
        industry: validationsRules.textRequired
    }),
    onSubmit: async values => {
      const requestBody = {
        name: values.name,
        domain: values.domain,
        industry: values.industry
      }
      const res = await postRequest(ORGANIZATIONS_URL, requestBody)
      if (res.status === 201) {
        setOrgCreatedSuccessful(true)
        createdCallback(res.data?.data)
      } else {
        setOrgCreatedSuccessful(false)
        setErrorMessage(res.data?.message)
      }
    }
  })

  useEffect(() => {
    formik.resetForm()
    setOrgCreatedSuccessful(undefined)

    const fetchData = async () => {
      const industry = await getConstant(CONSTANTS_OPTIONS.INDUSTRY_OPTIONS)
      setIndustryOptions(industry || [])
    }
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  
  return (
    <InputDialog
      open={open}
      formik={formik}
      confirmText='Create'
      handleCancel={onCancel}
      titleText='Create New Company'>
      <FormInputBox
        type='text'
        formik={formik}
        attributeName='name'
        placeholder='Company Name'
      />
      <FormInputBox
        type='text'
        formik={formik}
        attributeName='domain'
        placeholder='Domain (e.g: leadwithmonark.com)'
      />
      <FormSelectInput
        name='industry'
        type='text'
        formik={formik}
        placeholder='Company Industry'
        attributeName='industry'>
        {industryOptions.map(({value, label}) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </FormSelectInput>
      {orgCreatedSuccessful === false ? (
        <Alert severity='error'>{errorMessage}</Alert>
      ) : null}
    </InputDialog>
  )
}

export default AddOrganizationDialog
