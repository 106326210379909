import { CardContent } from '@mui/material'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { NotificationBodyText } from 'components/atoms/Text'
import JourneyContents from 'components/organisms/JouneyContents'
import JourneyPage from 'components/templates/navigationPageTemplates/JourneyPage'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { GOAL_STEP_URL } from 'services/constants'
import { getRequest } from 'utils/api'

const JourneyDemo = () => {
  const location = useLocation()
  const goalId = location.state?.goalId
  const [loading, setLoading] = useState(true)
  const [goalStepsData, setGoalStepsData] = useState(undefined)

  useEffect(() => {
    const fetchGoalSteps = async _goalId => {
      const res = await getRequest(GOAL_STEP_URL, {
        goalId: parseInt(_goalId, 10)
      })
      if (res.status === 200) {
        setGoalStepsData(res.data.data)
      }
      setLoading(false)
    }
    if (!goalStepsData && goalId) {
      fetchGoalSteps(goalId)
    }
    if (!goalId) {
      setLoading(false)
    }
  }, [goalId, goalStepsData])

  return (
    <JourneyPage>
      {loading ? (
        <OverlayLoading open={loading} />
      ) : !goalId ? (
        <CardContent>
          <NotificationBodyText>No demo found.</NotificationBodyText>
        </CardContent>
      ) : (
        <JourneyContents
          goalId={goalId}
          steps={goalStepsData}
          demoMode={true}
          userStates={{}}
        />
      )}
    </JourneyPage>
  )
}

export default JourneyDemo
