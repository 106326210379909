import { Button, Container, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import { useState } from 'react'
import { GoalIcon } from 'components/atoms/Icons'
import LeadershipPathList from 'components/organisms/LeadershipPathList'
import { useTranslation } from 'react-i18next'

const StepThree = ({ completeCallback, selectedGoal }) => {
  const { t } = useTranslation()
  const [activePage, setActivePage] = useState(0)

  const handleStartClick = () => {
    if (selectedGoal.id) {
      completeCallback()
    } else {
      setActivePage(1)
    }
  }
  return (
    <>
      {activePage === 0 ? (
        <InCardStack width='500px' spacing={10} marginTop={10}>
          <GoalIcon sx={{ width: '150px', height: '180px' }} />
          <Typography variant='h3'>{t('addRaters.startYourJourney')}</Typography>
          <Typography variant='subtitle2' textAlign='center'>
            {t('onboarding.getStartedOnLeadershipJourney')}
          </Typography>
          <Typography variant='subtitle2' textAlign='center'>
            {selectedGoal?.id
              ? `${t('onboarding.hasBeenPreSelected.part1')} ${selectedGoal?.leadershipPathName} ${t('onboarding.hasBeenPreSelected.part2')}`
              : t('onboarding.seeDetailsLearningPaths')
              }
          </Typography>
          <Button onClick={() => handleStartClick()}>{t('addRaters.getStarted')}</Button>
        </InCardStack>
      ) : activePage === 1 ? (
        <Container maxWidth='xl'>
          <LeadershipPathList />
        </Container>
      ) : null}
    </>
  )
}

export default StepThree
