import ContentHeaderTabs from 'components/organisms/ContentHeaderTabs'
import AuthorizedPage from 'components/templates/AuthorizedPage'
import { ROLE_PLAY_PRACTISES } from 'routes/constants'
import { PAGE_TITLES } from 'utils/constants'

const PracticePage = ({ children, ...otherProps }) => {
  return (
    <AuthorizedPage
      pageTitle={PAGE_TITLES.PRACTISE}
      {...otherProps}
      headerTabs={<ContentHeaderTabs activePage={ROLE_PLAY_PRACTISES} />}>
      {children}
    </AuthorizedPage>
  )
}

export default PracticePage
