import { EMAIL_CLICK_TRACKING_URL, USER_NOTES_TYPES } from 'services/constants'
import { postRequest } from './api'
import {
  ADD_ASSESSMENT_RATERS,
  ASSESSMENT_OTHERS,
  ASSESSMENT_SELF,
  CHECK_IN_SURVEY,
  HOME,
  JOURNEY,
  JOURNEY_DEMO,
  LEADERSHIP_MODEL,
  ONBOARDING,
  PULSE_SURVEY_DETAIL,
  PULSE_SURVEY_RATE,
  RESET_PASSWORD,
  SIGNUP_DIRECT,
  USER_FEEBACK,
  MY_MEETING
} from '../routes/constants'

export const getDeepLinkDestination = link => {
  const getParameterByName = name => {
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(link)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  const token = getParameterByName('token')
  const action = getParameterByName('action')
  const email = getParameterByName('email')?.replace(' ', '+')
  const firstName = getParameterByName('firstName') ?? ''
  const lastName = getParameterByName('lastName') ?? ''
  const assessmentRequestId = getParameterByName('assessmentRequestId')
  const userCalendarEventId = parseInt(
    getParameterByName('userCalendarEventId'),
    10
  )
  const userConnectionEventId = parseInt(
    getParameterByName('userConnectionEventId'),
    10
  )
  const associatedActionId = getParameterByName('associatedActionId')
  const clickId = getParameterByName('clickId')
  if (clickId) {
    postRequest(EMAIL_CLICK_TRACKING_URL, {
      clickId
    })
  }

  const goalStepRedirect = (scrollToGoal = true) => {
    const goalStepId = getParameterByName('goalStepId')
    const goalId = getParameterByName('goalId')
    const params = { goalId, goalStepId }
    if (scrollToGoal) {
      params.to = USER_NOTES_TYPES.GOAL
    }
    return { url: `${JOURNEY}/${goalId}`, params }
  }

  const leadershipModelRedirect = () => {
    const goalId = parseInt(getParameterByName('goalId'), 10)
    return { url: LEADERSHIP_MODEL, params: { showAssessment: true, goalId } }
  }

  const myTeamEventRedirect = pageUrl => {
    return {
      url: pageUrl,
      params: { userCalendarEventId, userConnectionEventId, deepLink: true }
    }
  }

  let dest = {}
  let matchLocalAccount = true
  switch (action) {
    case 'signup':
      dest.url = `${SIGNUP_DIRECT}/${email}`
      matchLocalAccount = false
      dest.params = { firstName, lastName, link }
      break
    case 'signupAndRate':
      dest.url = `${SIGNUP_DIRECT}/${email}/${assessmentRequestId}`
      matchLocalAccount = false
      dest.params = { firstName, lastName, link }
      break
    case 'rate':
      dest.url = `${ASSESSMENT_OTHERS}/${assessmentRequestId}`
      break
    case 'demoGoal':
      dest.url = JOURNEY_DEMO
      dest.params = { goalId: getParameterByName('goalId') }
      matchLocalAccount = false
      break
    case 'selfAssessmentReport':
      dest = leadershipModelRedirect()
      break
    case 'pulseSurvey':
      dest.url = `${PULSE_SURVEY_RATE}/${associatedActionId}`
      break
    case 'pulseSurveyClose':
      dest.url = `${PULSE_SURVEY_DETAIL}/${associatedActionId}`
      break
    case 'userFeedback':
      dest.url = USER_FEEBACK
      break
    case 'available360Rating':
      dest = leadershipModelRedirect()
      break
    case 'notifyIdleUser':
      dest.url = JOURNEY
      break
    case 'learningPathReminder':
      dest.url = JOURNEY
      break
    case 'switchPath':
      dest.params = {
        startingGoalId: parseInt(getParameterByName('goalId'), 10)
      }
      dest.url = JOURNEY
      break
    case 'completeProfile':
      dest.url = ONBOARDING
      dest.params = { completeProfile: true }
      break
    case 'goalNoteReminder':
      dest = goalStepRedirect()
      break
    case 'goalNotePastDue':
      dest = goalStepRedirect()
      break
    case 'goalStepContent':
      dest = goalStepRedirect(false)
      break
    case 'reAssessment':
      dest = {
        url: ASSESSMENT_SELF,
        params: {
          goalId: parseInt(getParameterByName('goalId'), 10)
        }
      }
      break
    case 'send360':
      dest.url = `${ADD_ASSESSMENT_RATERS}/${getParameterByName('goalId')}`
      break
    case 'signupAndConnectionEvent':
      dest.url = `${SIGNUP_DIRECT}/${email}`
      matchLocalAccount = false
      dest.params = {
        firstName,
        lastName,
        userConnectionEventId,
        from: MY_MEETING,
        deepLink: true
      }
      break
    case 'signUpAndOneOnOneSurvey':
      dest.url = `${SIGNUP_DIRECT}/${email}`
      matchLocalAccount = false
      dest.params = {
        firstName,
        lastName,
        from: CHECK_IN_SURVEY,
        userCalendarEventId
      }
      break
    case 'userConnectionEvent':
      dest = myTeamEventRedirect(MY_MEETING)
      break
    case 'oneOnOneSurvey':
      dest = myTeamEventRedirect(CHECK_IN_SURVEY)
      break
    // Not in use:
    // case 'customerService':
    //   window.location.replace(`monarkapp://CustomerService/${email}`)
    //   break
    // case 'userReminder':
    //   window.location.replace(`monarkapp://UserReminder/${email}`)
    //   break
    default:
      // If there is token, redirect to app reset paasowrdScreen
      if (token) {
        dest.url = `${RESET_PASSWORD}/${token}`
        matchLocalAccount = false
      } else {
        dest.url = HOME
      }
  }
  if (!dest.params) {
    dest.params = null
  }
  dest.matchLocalAccount = matchLocalAccount
  dest.email = email
  return dest
}
