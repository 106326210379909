import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StepperPageTemplate from 'components/templates/StepperPageTemplate'
import ImageContainer from 'components/atoms/container/ImageContainer'
import InCardStack from 'components/atoms/InCardStack'
import StyledButton from 'components/atoms/button/StyledButton'
import colors from 'styles/colors'
import FullAssessmentCard from './FullAssessmentCard'
import StepTwo from 'components/organisms/user/onboardingSteps/StepTwoNew'
import { Container } from '@mui/system'
import { permitted } from 'utils/permissionCheck'
import { HOME, PERMISSION_LEVEL } from 'routes/constants'
import { useNavigate } from 'react-router-dom'

const TitleText = ({ children }) => (
  <Typography variant='h1' color='primary'>
    {children}
  </Typography>
)
const BodyText = ({ children }) => (
  <Typography textAlign='center' color={colors.Grey400}>
    {children}
  </Typography>
)
const FullAssessmentOnboarding = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(1)

  const licensed = permitted(PERMISSION_LEVEL.LICENSED)
  const onboardingSteps = [
    'welcomeArea.welcome',
    'onboarding.createProfile'
  ]
  if (licensed) {
    onboardingSteps.push('onboarding.selfAssessment')
  }
  const handleCompleteProfile = () => {
    if (licensed) {
      setActiveStep(3)
    } else {
      navigate(HOME)
    }
  }
  return (
    <StepperPageTemplate
      activeStep={activeStep}
      steps={onboardingSteps}
      stepperMaxWidth='sm'>
      <Container maxWidth={activeStep === 3 ? 'xl' : 'sm'}>
        <InCardStack spacing={8} sx={{ paddingY: 16 }}>
          {activeStep === 1 && (
            <>
              <TitleText>
                {t('welcomeArea.welcome')} {t('common.to')} Monark!
              </TitleText>
              <BodyText>{t('onboarding.fullAssesmentWelcome')}</BodyText>
              <Box width={300} paddingY={12}>
                <ImageContainer
                  src='https://monark-public-assets.s3.ca-central-1.amazonaws.com/img/Whatwelookfor01.png'
                  alt='home'
                />
              </Box>
              <StyledButton onClick={() => setActiveStep(2)}>
                {t('onboarding.createYourProfile')}
              </StyledButton>
            </>
          )}
          {activeStep === 2 && (
            <StepTwo completeCallback={handleCompleteProfile} />
          )}
          {activeStep === 3 && <FullAssessmentCard />}
        </InCardStack>
      </Container>
    </StepperPageTemplate>
  )
}

export default FullAssessmentOnboarding
