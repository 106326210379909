import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import FormInputBox from 'components/atoms/input/FormInputBox'
import { useFormik } from 'formik'
import InCardStack from 'components/atoms/InCardStack'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import { postRequest } from 'utils/api'
import { useOnPageLeave } from 'utils/domEventHelper'
import {
  FEATURE_KEYS,
  AI_ROLE_PLAY_V2_URL,
  AI_STT_URL,
  AI_TTS_URL
} from 'services/constants'
import { formatDateTime } from 'utils/formatDateTime'
import ShortButton from 'components/atoms/button/ShortButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import SendIcon from '@mui/icons-material/Send'
import Tooltip from '@mui/material/Tooltip'
import SmallButton from 'components/atoms/button/SmallButton'
import CancelIcon from '@mui/icons-material/Cancel'
import moment from 'moment'
import ConvoBox from 'components/atoms/container/ConvoBox'
import { PopupAlert } from 'components/organisms/JourneyPopups'
import { useLocation } from 'react-router-dom'
import { JOURNEY_DEMO } from 'routes/constants'
import { useTranslation } from 'react-i18next'
import MuiMarkdown from 'mui-markdown'
import RecordRTC from 'recordrtc'
import { isAllowedFeature } from 'utils/permissionCheck'

const SttState = Object.freeze({
    STT_RECORDING: 0,
    STT_TRANSCRIBING: 10, // On GCP
    CANCELLED: 30,        // Either user or system cancelled
    FAILED: 40,           // Unexpected error
    COMPLETE: 50          // Finished successfully for this back-and-forth
});

const sttStates = []

const StepQuizRolePlay = ({ quizData, settings }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const location = useLocation()
  const isDemo = location.pathname === JOURNEY_DEMO
  const [hasHistory, setHasHistory] = useState(
    Boolean(quizData?.userQuizResults.length)
  )
  const [loading, setLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [isTranscribing, setIsTranscribing] = useState(false)
  const [isDictTimeout, setIsDictTimeout] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const isMutedRef = useRef(false)  // For async callbacks
  const [displayMessages, setDisplayMessages] = useState([])
  const [started, setStarted] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [finalFeedback, setFinalFeedback] = useState('')
  const [finalFeedbackLoading, setFinalFeedbackLoading] = useState(false)
  const [showHistory, setShowHistory] = useState(hasHistory)
  const [reviewingData, setReviewingData] = useState({})
  const [reviewing, setReviewing] = useState(false)
  const finalFeedbackScrollRef = useRef([])
  const scrollRef = useRef(null)
  const topRef = useRef(null)
  const convoTopRef = useRef(null)
  const audioPlayer = useRef(null)
  const audioRecorder = useRef(null)

  const hasAudioFeature = isAllowedFeature(FEATURE_KEYS.AUDIO_ROLEPLAY)

  const handleInputSubmission = async userInput => {
    stopPlayingAudio()

    if (loading) {
      return
    } else if (!userInput || userInput.trim().length === 0) {
      sttStates[sttStates.length - 1] = SttState.COMPLETE
      return
    }
    setLoading(true)
    const newDisplayMessage = [
      ...displayMessages,
      { role: 'user', content: userInput }
    ]
    setDisplayMessages(newDisplayMessage)

    formik.resetForm()
    sttStates[sttStates.length - 1] = SttState.COMPLETE

    await handleGetResponse(newDisplayMessage)
  }

  const formik = useFormik({
    initialValues: {
      newReply: ''
    },
    onSubmit: values => handleInputSubmission(values.newReply),
  })

  useEffect(() => {
    setHasHistory(Boolean(quizData?.userQuizResults.length))
    setShowHistory(Boolean(quizData?.userQuizResults.length))
    topRef.current?.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizData.id])
  useEffect(() => {
    if (started) {
      const scroller = scrollRef.current

      const children = scroller.children
      const messageBlock = children[children.length - 1]
      const messages = Array.from(messageBlock.children)

      if (messages.length === 0) {  // Silly to scroll with no messages
        return
      }

      // Height of the last two messages
      const messageHeight = messages.slice(-2)
        .reduce((acc, c) => acc + c.clientHeight, 0)

      const extraPadding = Math.max(scroller.clientHeight - messageHeight, 0)
      const scrollTo = scroller.scrollHeight - messageHeight

      scroller.style.paddingBottom = `${extraPadding}px`

      if (loading || displayMessages.length <= 1) {
        scroller.scroll(0, scrollTo)
      }
    }
  }, [loading, displayMessages, started])
  useEffect(() => {
    if (reviewing) {
      convoTopRef.current?.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      })
    }
  }, [reviewing])

  useEffect(() => {
    if (finalFeedbackScrollRef.current.length === 2) {
      const scroller = scrollRef.current
      const scrollerA = finalFeedbackScrollRef.current[0]
      const scrollerB = finalFeedbackScrollRef.current[1]

      let scrollTo = 0
      scrollTo += scrollerA ? scrollerA.clientHeight : 0
      scrollTo += scrollerB ? scrollerB.clientHeight : 0
      scrollTo += 20

      scroller.style.paddingBottom = '0'
      scroller.scroll(0, scroller.scrollHeight - scrollTo)
    }
  }, [finalFeedbackLoading])

  const dictationTimeout = sttIndex => {
    if (sttStates[sttIndex] === SttState.STT_RECORDING) {
      cancelDictation()
      setIsDictTimeout(true)
    }
  }

  const startRecordingAudio = async () => {
    stopPlayingAudio()

    sttStates.push(SttState.STT_RECORDING)
    const sttIndex = sttStates.length - 1

    setTimeout(() => dictationTimeout(sttIndex), 58000)

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioRecorder.current = new RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm; codecs=opus',
        disableLogs: true,
        checkForInactiveTracks: false, // auto stop recording if camera stops

        // Both for audio and video tracks. Make these the same just in case
        bitsPerSecond: 32000,
        audioBitsPerSecond: 32000,

        sampleRate: 48000, // Values from 22050 to 96000
        desiredSampRate: 48000, // Like sample rate, but goes lower?
        bufferSize: 16384, // Legal values are 2**{8,9,10,11,12,13,14}
        numberOfAudioChannels: 1, // Must be 1 for google's STT!
      })

      audioRecorder.current.startRecording()
      setIsRecording(true)
    } catch (e) {
      console.error(`Failed to start audio recorder: ${e}`)
      setIsRecording(false)
      sttStates[sttIndex] = SttState.FAILED
    }
  }

  const cancelDictation = () => {
    sttStates[sttStates.length - 1] = SttState.CANCELLED
    setIsTranscribing(false)
    setIsRecording(false)
  }

  // Copied from https://stackoverflow.com/a/61226119
  const blobToBase64 = blob => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result)
      }
    })
  }

  const stopRecordingAudio = async () => {
    const sttIndex = sttStates.length - 1
    setIsRecording(false)

    if (sttStates[sttIndex] !== SttState.STT_RECORDING) {
      // Some error came up between hitting record and now
      return
    }

    sttStates[sttIndex] = SttState.STT_TRANSCRIBING
    setIsTranscribing(true)

    audioRecorder.current.stopRecording(async () => {
      let audio = await blobToBase64(audioRecorder.current.getBlob())
      // Removes MIME heading (leaving it will break GCP).
      // Different browsers create slightly different MIME headings for their
      // recordings, so here we search for the end instead.
      audio = audio.slice(audio.indexOf('base64,') + 7)

      const res = await postRequest(AI_STT_URL, {
        audio,
        encoding: 'WEBM_OPUS',
        sampleRateHertz: 48000
      })

      if (sttStates[sttIndex] === SttState.STT_TRANSCRIBING) { // Not canceled
        setIsTranscribing(false)
        handleInputSubmission(res.data.data)
      }
    })
  }

  const getTTSResponse = async text => {
    const res = await postRequest(AI_TTS_URL, { text })

    return res.status === 200 ? res.data.data.tts: null
  }

  const getAIResponse = async (newMessages, complete) => {
    const res = await postRequest(
      AI_ROLE_PLAY_V2_URL,
      {
        goalStepQuizId: quizData.id,
        rolePlayMessages: newMessages,
        isWithAudio: hasAudioFeature,
        complete
      }
    )
    if (res.status === 200) {
      return res.data.data
    } else if (res.status === 429) {
      return { role: 'assistant', content: res.data.message }  // Rate limit
    }
    return null
  }

  const toggleAudioPlayerMute = () => {
    if (!isMuted) {  // This is when the user just pressed to mute it
      stopPlayingAudio()
    }

    isMutedRef.current = !isMuted  // Required for async callbacks
    setIsMuted(!isMuted)  // Required to rerender button
  }

  const playBase64Audio = async base64 => {
    if (hasAudioFeature && !isMutedRef.current) {
      audioPlayer.current = new Audio("data:audio/wav;base64," + base64)
      audioPlayer.current.play()
    }
  }

  const stopPlayingAudio = () => {
    audioPlayer.current?.pause()
    delete audioPlayer.current  // Don't start playing it again later

    // Instantly complete latest typing animation
    if (displayMessages.length > 0) {
      const latest_message = displayMessages.slice(-1)[0]
      latest_message.sequence = null
      setDisplayMessages([...displayMessages.slice(0, -1), latest_message])
    }
  }

  const timingsToSequence = textTimings => {
    // Takes something like:
    //    [
    //      { time: 1, text: 'one' },
    //      { time: 4, text: 'one two' },
    //      { time: 10, text: 'one two three' }
    //    ]
    // And turns it into: ['one', 1000, 'one two', 3000, 'one two three', 6000]
    const calcTime = (x, i) => {
      const thisTime = x.time
      const lastTime = i > 0 ? textTimings[i-1].time : 0
      const thisTextLength = x.text.length
      const lastTextLength = i > 0 ? textTimings[i-1].text.length : 0
      const charsPerSecond = 100
      const charsSecond = (thisTextLength - lastTextLength) / charsPerSecond

      return (thisTime - lastTime - charsSecond) * 1000
    }

    return [].concat(
      ...textTimings
        .map((x, i) => {
          return {
            time: calcTime(x, i),
            text: x.text
          }
        })
        .map(x => [x.text, x.time])
    )
  }

  const handleGetResponse = async newDisplayMessage => {
    const response = await getAIResponse(newDisplayMessage, false)

    if (response) {
      const message = {
        role: response.role,
        content: response.content,
        sequence: response.tts?.sequence && !isMutedRef.current ?
          timingsToSequence(response.tts.sequence) : null
      }

      response.tts?.audio && playBase64Audio(response.tts.audio)

      setDisplayMessages([...newDisplayMessage, message])
    }
    setLoading(false)
  }

  const handleStart = async () => {
    setStarted(true)
    const newDisplayMessage = []

    if (settings.firstMessage) {
      setLoading(true)
      const tts = await getTTSResponse(settings.firstMessage.content)

      tts?.audio && playBase64Audio(tts.audio)

      newDisplayMessage.push({
        role: settings.firstMessage.role,
        content: settings.firstMessage.content,
        sequence: tts?.audio && !isMutedRef.current ?
          timingsToSequence(tts.sequence) : null
      })

      setLoading(false)
    }

    setDisplayMessages(newDisplayMessage)
    setReviewing(false)
    setCompleted(false)
    setShowHistory(false)
    setFinalFeedback('')
  }
  const handleComplete = async () => {
    setFinalFeedbackLoading(true)
    setCompleted(true)
    stopPlayingAudio(true)
    const updatedMessages = [...displayMessages]
    const feedbackMessage = await getAIResponse(updatedMessages, true)
    if (feedbackMessage) {
      setFinalFeedback(feedbackMessage.content)
      quizData.userQuizResults.push({
        resultData: {
          rolePlayMessages: displayMessages,
          review: feedbackMessage.content
        },
        createdAt: moment()
      })
    }
    setStarted(false)
    setFinalFeedbackLoading(false)
  }

  const handleReviewRolePlay = result => {
    setStarted(false)
    setDisplayMessages(result.resultData.rolePlayMessages)
    setFinalFeedback(result.resultData.review)
    setReviewingData(result)
    setCompleted(true)
    setReviewing(true)
    setShowHistory(false)
  }

  useOnPageLeave(stopPlayingAudio)

  const boxPadding = { paddingX: 10, paddingY: 5 }
  const buttonStyles = { sx: { maxWidth: '30px', margin: '0 5px' } }

  return (
    <>
      <PopupAlert
        open={isDictTimeout}
        confirmText={t('rolePlay.okay')}
        title={t('rolePlay.dictCancelled')}
        message={t('rolePlay.dictCannotExceed')}
        onClose={() => setIsDictTimeout(false)}
      />
      <Box
        height='inherit'
        overflow='clip'
        paddingBottom={showHistory ? 0 : started ? 70 : 30}
      >
        <Stack
          ref={scrollRef}
          height='inherit'
          overflow='auto'
          width='100%'
          padding={0}
          sx={{ scrollBehavior: 'smooth', wordBreak: 'break-word' }}
          spacing={6}>
          <InCardStack direction='row'>
            <Box height='1px' ref={topRef} />
            {hasHistory && !showHistory ? (
              <Typography
                alignItems='center'
                display='flex'
                sx={{ '&:hover': { cursor: 'pointer' } }}
                onClick={() => {
                  stopPlayingAudio()
                  if (sttStates.length) {
                    sttStates[sttStates.length - 1] = SttState.CANCELLED
                    stopRecordingAudio()
                  }
                  setShowHistory(true)
                  setReviewing(false)
                }}>
                <KeyboardBackspaceIcon />
                {'  '}
                Back
              </Typography>
            ) : null}
            <Typography
              variant='subtitle1'
              width={hasHistory && !showHistory ? '75.3%' : '100%'}
              textAlign='center'
            >
              {quizData.title}
            </Typography>
          </InCardStack>
          {showHistory ? (
            <>
              <Typography
                variant='subtitle1'
                width='100%'
                display='flex'
                alignItems='center'>
                Previous Role Play Results
                <SmallButton
                  sx={{ marginLeft: 5, fontSize: '14px', paddingX: 6 }}
                  onClick={() => handleStart()}>
                  Start Again
                </SmallButton>
              </Typography>
              <InCardStack width='100%' spacing={5}>
                {quizData.userQuizResults.sort(
                  (x, y) => x.createdAt > y.createdAt ? -1 : 1
                ).map(r => (
                  <BoxWithBackground
                    key={`role-play-result-${r.id}`}
                    width='90%'
                    {...boxPadding}>
                    <InCardStack
                      direction='row'
                      sx={{ justifyContent: 'space-between' }}>
                      <Typography>{formatDateTime(r.createdAt)}</Typography>
                      <ShortButton onClick={() => handleReviewRolePlay(r)}>
                        Review
                      </ShortButton>
                    </InCardStack>
                  </BoxWithBackground>
                ))}
              </InCardStack>
            </>
          ) : null}

          <Typography variant='subtitle1' marginX={10}>
            Scenario Details
          </Typography>

          {/* Senario/context box */}
          <BoxWithBorder
            paddingX={8}
            borderColor={theme.palette.secondary.light}
            sx={{ backgroundColor: theme.palette.background.default }}>
            <Box dangerouslySetInnerHTML={{ __html: settings?.contextHtml }} />
          </BoxWithBorder>

          {!showHistory ? (
            <>
              {reviewing ? (
                <Typography fontWeight='bold' textAlign='center'>
                  Reviewing Role Play From:{' '}
                  {formatDateTime(reviewingData.createdAt)}
                </Typography>
              ) : null}
              <Box height='1px' ref={convoTopRef} />
              <ConvoBox
                messages={displayMessages}
                loading={loading}
                messageBubbles={false}
              />
              {completed && !finalFeedbackLoading ? (
                <>
                  {!reviewing && isDemo && settings.feedbackLink ? (
                    <CardContent
                      ref={e => e && (finalFeedbackScrollRef.current[0] = e)}
                      sx={{ padding: 0 }}
                    >
                      <Button
                        component='a'
                        color='secondary'
                        href={settings.feedbackLink}
                        target='_blank'
                        sx={{
                          fontWeight: 'bold',
                          color: theme.palette.background.paper
                        }}>
                        Tell Us What You Think
                      </Button>
                    </CardContent>
                  ) : null}
                  <Box ref={e => e && (finalFeedbackScrollRef.current[1] = e)}>
                    <BoxWithBorder padding={8} margin={5} >
                      <Typography textAlign='center' variant='h3'>
                        Your Results!
                      </Typography>
                      <MuiMarkdown>{finalFeedback}</MuiMarkdown>
                    </BoxWithBorder>
                  </Box>
                </>
              ) : null}
            </>
          ) : null}
        </Stack>
        {!showHistory ? (
          <Box
            width={1}
            height={started ? 140 : 60}
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: theme.palette.background.paper
            }}>
            <InCardStack
              spacing={5}
              sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center'
              }}>
              {started ? (
                <>
                  <FormInputBox
                    position='relative'
                    placeholder='Send a message to continue the conversation.'
                    sx={{ width: '100%' }}
                    endAdornment={
                      <>
                        { hasAudioFeature &&
                          <>
                            <Tooltip
                              title={
                                isRecording ? 'Submit' :
                                isTranscribing ? 'Cancel' :
                                'Dictate'
                              }
                            >
                              <span>
                                <Button
                                  {...buttonStyles}
                                  onClick={() => {
                                    isRecording ?
                                      stopRecordingAudio() :
                                    isTranscribing ?
                                      cancelDictation() :
                                    startRecordingAudio()
                                  }}
                                  disabled={loading || isTranscribing}>
                                  { isRecording ? <StopCircleIcon />
                                    : isTranscribing ?
                                      <Box height={24}>
                                        <CircularProgress
                                          color="background"
                                          sx={{
                                              position: 'absolute',
                                              top: 6,
                                              left: 16
                                          }}
                                          size={32}
                                        />
                                      </Box>
                                    : <KeyboardVoiceIcon /> }
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip title='Mute speech'>
                              <div>
                                <Button
                                  {...buttonStyles}
                                  onClick={() => toggleAudioPlayerMute()}>
                                  { isMuted ? <VolumeOffIcon /> :
                                    <VolumeUpIcon /> }
                                </Button>
                              </div>
                            </Tooltip>
                          </>
                        }
                        { isRecording || isTranscribing ?
                        <Tooltip title='Cancel'>
                          <div>
                            <Button
                              {...buttonStyles}
                              onClick={() => cancelDictation()}
                            >
                              <Box height={24}>
                                <CancelIcon
                                  size={24}
                                  sx={{
                                      position: 'absolute',
                                      top: 10,
                                      left: 20
                                  }}
                                />
                              </Box>
                            </Button>
                          </div>
                        </Tooltip>
                        :
                        <Tooltip title='Send'>
                          <div>
                            <Button
                              {...buttonStyles}
                              onClick={() => formik.handleSubmit()}
                              disabled={
                                loading || isRecording || isTranscribing
                                || formik.values.newReply.trim().length === 0
                              }>
                              <SendIcon />
                            </Button>
                          </div>
                        </Tooltip>
                        }
                      </>
                    }
                    type='text'
                    formik={formik}
                    multiline
                    rows={2}
                    attributeName='newReply'
                    disabled={completed || isRecording || isTranscribing}
                    onKeyDown={e => {
                      if (e.keyCode === 13 && !e.shiftKey) {
                        e.preventDefault()
                        formik.handleSubmit()
                      }
                    }}
                  />
                  <Button
                    onClick={() => handleComplete()}
                    disabled={
                      displayMessages.length < 3 || isRecording
                        || isTranscribing || loading
                    }>
                    Complete and Get Feedback
                  </Button>
                </>
              ) : (
                <Button onClick={() => handleStart()}>
                  {completed ? 'Start Again' : `Let's Start!`}
                </Button>
              )}
            </InCardStack>
          </Box>
        ) : null}
        {finalFeedbackLoading ? (
          <Dialog open={finalFeedbackLoading} maxWidth='xs'>
            <InCardStack sx={{ padding: 20 }}>
              <CircularProgress />
              <Typography variant='h5' textAlign='center'>
                Hold tight, we are gathering your results!
              </Typography>
            </InCardStack>
          </Dialog>
        ) : null}
      </Box>
    </>
  )
}

export default StepQuizRolePlay
