import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { getUserConnections } from 'services/userServices'
import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import AvatarChip from 'components/atoms/AvatarChip'
import { getFullName } from 'utils/formatText'
import UserConnectionMenuItem from 'components/atoms/input/UserConnectionMenuItem'
import { validationsRules } from 'utils/formValidations'

const EmailsInputBox = ({
  disabled,
  formik,
  placeholder = 'Enter Emails',
  ...otherProps
}) => {
  const { t } = useTranslation()
  const filter = createFilterOptions()
  const [recipients, setRecipients] = useState([])
  const [recipientRequestSuccess, setRecipientRequestSuccess] =
    useState(undefined)
  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuOpen = () => {
    if (recipients.length === 0) {
      fetchData()
    }
    setMenuOpen(true)
  }

  const fetchData = async () => {
    const res = await getUserConnections()
    if (res.length) {
      setRecipients(res)
      setRecipientRequestSuccess(true)
    } else {
      setRecipientRequestSuccess(false)
    }
  }

  const handleSelectRater = async (e, value) => {
    let newValues = value.length ? value : []
    const selected = formik.values.recipients
    const newSelect = newValues.find(v => selected.indexOf(v) < 0)
    if (newSelect?.user.new) {
      const inList = recipients.find(
        r => r.user?.email.trim() === newSelect.user.email.trim()
      )
      if (!inList) {
        try {
          await Promise.resolve(
            validationsRules.email.validate(newSelect.user.email)
          )
          recipients.push(newSelect)
        } catch {
          formik.setFieldError(
            'recipients',
            t('shareMyResults.recpEmailValidation')
          )
          formik.setTouched({ recipients: true }, false)
          setMenuOpen(false)
          return
        }
      }
    }
    formik.setFieldValue('recipients', newValues)
  }

  return (
    <Autocomplete
      {...otherProps}
      multiple
      freeSolo
      sx={{ flex: 1 }}
      disableCloseOnSelect
      data-testid='share-result-select'
      autoHighlight={true}
      open={menuOpen}
      clearIcon={false}
      onOpen={() => handleMenuOpen()}
      onClose={() => setMenuOpen(false)}
      options={recipients}
      value={formik.values.recipients}
      noOptionsText={
        recipientRequestSuccess === true
          ? t('feedbackArea.noMatchUserFound')
          : recipientRequestSuccess === false
            ? t('feedbackArea.failedLoadUserConnections')
            : t('feedbackArea.loadingConnections')
      }
      renderOption={(props, option) => (
        <UserConnectionMenuItem
          itemProps={props}
          option={option}
          key={option.user?.email}
        />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        const existing = options.find(
          o => o.user.email === params.inputValue
        )
        if (params.inputValue !== '' && !existing) {
          filtered.push({
            user: {
              email: params.inputValue,
              first_name: '',
              last_name: '',
              new: true
            }
          })
        }
        return filtered
      }}
      renderInput={params => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'white',
              borderRadius: 6,
              paddingY: 2,
              fontSize: 12,
              height: 'auto',
            },
          }}
          error={
            formik.touched.recipients &&
            Boolean(formik.errors.recipients)
          }
          placeholder={t('shareMyResults.recpPlaceholder')}
          helperText={
            formik.touched.recipients && formik.errors.recipients
          }
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <AvatarChip
            key={option.user?.id}
            user={option.user}
            {...getTagProps({ index })}
          />
        ))
      }
      isOptionEqualToValue={(option, value) =>
        option.user?.email === value.user.email
      }
      getOptionLabel={option =>
        option.new
          ? option.email
          : `${getFullName(option.user)} (${option.user?.email})`
      }
      onChange={handleSelectRater}
      ListboxProps={{ sx: { maxHeight: '300px' } }}
    />
  )
}

export default EmailsInputBox
