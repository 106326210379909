import { Button } from '@mui/material'

const SmallButton = ({ children, sx, ...otherProps }) => {
  return (
    <Button
      sx={[{ paddingY: 0, paddingX: 4, fontSize: 'small' }, sx]}
      {...otherProps}>
      {children}
    </Button>
  )
}

export default SmallButton
