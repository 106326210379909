import {
  MenuItem,
  TableCell,
  TextField,
  Typography
} from '@mui/material'
import { patchRequest } from 'utils/api'
import { USERS_URL, USER_ROLE_TYPES } from 'services/constants'
import { useEffect, useState } from 'react'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { userRoleMenuItems } from 'utils/getSupportingInfo'
import NotificationSnackBar from '../notificationOverlay/NotificationSnackBar'
import { useTranslation } from 'react-i18next'

const UserRoleSelectCell = ({ user, disabled }) => {
  const { t } = useTranslation()
  const [requestError, setRequestError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [roleSelected, setRoleSelected] = useState(user.role)
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  useEffect(() => {
    const updateRole = async () => {
      setLoading(true)
      const res = await patchRequest(`${USERS_URL}/${user.id}`, {
        role: roleSelected
      })
      if (res.status === 200) {
        setRequestError(false)
        user.role = roleSelected
      } else {
        setRequestError(true)
      }
      setLoading(false)
      setSnackBarOpen(true)
    }
    if (user.role && roleSelected !== user.role) {
      updateRole()
    }
  }, [roleSelected, user])

  return (
    <TableCell width='300px'>
      {roleSelected ? (
        <TextField
          data-testid={`select-user-role-${user.id}`}
          select
          value={roleSelected}
          disabled={roleSelected === USER_ROLE_TYPES.SUPER_ADMIN || disabled}
          onChange={e => setRoleSelected(e.target.value)}
          sx={{ padding: 0 }}
          InputProps={{
            sx: {
              minWidth: '250px',
              '& .MuiInputBase-input': { padding: '8px 12px' }
            }
          }}>
          {userRoleMenuItems(roleSelected === USER_ROLE_TYPES.SUPER_ADMIN).map(
            r => (
              <MenuItem key={r.key} value={r.value}>
                {t(`company.${r.label}`)}
              </MenuItem>
            )
          )}
        </TextField>
      ) : (
        <Typography variant='body2'>{t('company.outOfOrganizationUser')}</Typography>
      )}

      <NotificationSnackBar
        open={snackBarOpen}
        setOpen={setSnackBarOpen}
        success={requestError === false}
        error={requestError}
      />

      <OverlayLoading open={loading} />
    </TableCell>
  )
}

export default UserRoleSelectCell
