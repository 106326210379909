import {
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Box,
  IconButton
} from '@mui/material'
import { Close } from '@mui/icons-material'

const RolePlayDialog = props => {
  const {
    open,
    titleText,
    handleCancel,
    confirmButtonText,
    forTranscript = false,
    children,
    width = '50%'
  } = props

  return (
    <Dialog
      onClose={handleCancel}
      open={open}
      sx={{ '& .MuiDialog-paper': { maxWidth: width } }}>
      {!forTranscript ? (
        <>
          <DialogTitle width={'100%'}>
            {titleText}
            <IconButton
              sx={{ float: 'right', paddingTop: '2px' }}
              onClick={() => handleCancel()}>
              <Close />
            </IconButton>
          </DialogTitle>
          <Stack alignItems='center' spacing={10} padding={10}>
            <Box>{children}</Box>
            <Button onClick={() => handleCancel()}>{confirmButtonText}</Button>
          </Stack>
        </>
      ) : (
        <>{children}</>
      )}
    </Dialog>
  )
}

export default RolePlayDialog
