import * as yup from 'yup'
import AddIcon from '@mui/icons-material/Add'
import {
  Alert,
  Autocomplete,
  MenuItem,
  TextField,
  useTheme
} from '@mui/material'
import { useState } from 'react'
import {
  SimpleInputDialog
} from 'components/molecules/notificationOverlay/InputDialog'
import { useFormik } from 'formik'
import { validationsRules } from 'utils/formValidations'
import { postRequest } from 'utils/api'
import {
  USER_CONNECTION_RELATIONSHIP,
  USER_CONNECTION_URL
} from 'services/constants'
import OverlayLoading from 'components/atoms/OverlayLoading'
import UserConnectionMenuItem from 'components/atoms/input/UserConnectionMenuItem'
import FormSelectInput from 'components/atoms/input/FormSelectInput'
import NotificationSnackBar from '../notificationOverlay/NotificationSnackBar'
import { useTranslation } from 'react-i18next'
import StyledButton from 'components/atoms/button/StyledButton'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import { TextInputBox } from 'components/atoms/input/FormInputBox'
import colors from 'styles/colors'

const AddConnectionButton = ({ users, sucessCallback }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [createError, setCreateError] = useState(false)
  const [disableNameField, setDisableNameField] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      relationship: ''
    },
    validationSchema: yup.object({
      email: validationsRules.email,
      lastName: validationsRules.textRequired,
      firstName: validationsRules.textRequired,
      relationship: validationsRules.textRequired
    }),
    onSubmit: async values => {
      setLoading(true)
      const requestBody = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        relationship: values.relationship
      }
      const res = await postRequest(USER_CONNECTION_URL, requestBody)
      if (res.status === 201 || res.status === 200) {
        setCreateError(false)
        setDialogOpen(false)
        setShowNotification(true)
        setLoading(false)
        sucessCallback()
      } else {
        setCreateError(true)
        setLoading(false)
      }
    }
  })

  const handleDialogOpen = () => {
    formik.resetForm()
    setDisableNameField(false)
    setDialogOpen(true)
  }
  return (
    <>
      <StyledButton
        inverseTheme
        onClick={handleDialogOpen}
        startIcon={<AddIcon />}
      >
        {t('myTeam.addConnection')}
      </StyledButton>

      <SimpleInputDialog
        open={dialogOpen}
        handleCancel={() => setDialogOpen(false)}
        handleConfirm={formik.handleSubmit}
        confirmText={t('common.add')}
        cancelText={t('common.cancel')}
      >
        <Autocomplete
          freeSolo
          autoSelect
          fullWidth
          options={users}
          noOptionsText={
            users.length ? t('myTeam.noMatchedUserFound') : t('myTeam.noRelatedUsers')
          }
          data-testid='connection-user-input'
          autoHighlight={true}
          getOptionLabel={option => (option?.user ? option.user.email : option)}
          value={formik.values.email}
          isOptionEqualToValue={(option, value) => option.user.email === value}
          sx={{
            '& .MuiAutocomplete-popupIndicator': {
              transform: 'none',
              color: theme.palette.primary.main
            }
          }}
          onChange={(_, value) => {
            formik.setFieldValue('email', value?.user?.email || value)
            if (value?.user) {
              formik.setFieldValue('firstName', value.user.firstName || ' ')
              formik.setFieldValue('lastName', value.user.lastName || ' ')
              setDisableNameField(true)
            } else {
              setDisableNameField(false)
            }
          }}
          forcePopupIcon
          popupIcon={
            <MailOutlineOutlinedIcon
              sx={{ color: colors.LightGrey400, marginRight: 2 }} />
          }
          renderInput={params => (
            <TextField
              {...params}
              error={formik.touched.email && Boolean(formik.errors.email)}
              placeholder={t('myTeam.selectUserOrInputRmail')}
              helperText={formik.touched.email && formik.errors.email}
              variant='standard'
              InputProps={{
                style: {
                  paddingLeft: 4,
                  paddingRight: 4,
                  paddingTop: 6,
                  paddingBottom: 6
                },
                ...params.InputProps
              }}
            />
          )}
          renderOption={(props, option) => (
            <UserConnectionMenuItem
              itemProps={props}
              option={option}
              key={option.user.email}
            />
          )}
        />
        <TextInputBox
          formik={formik}
          attributeName='firstName'
          placeholder={t('company.firstName')}
          disabled={disableNameField}
          endAdornment={
            <PersonOutlineOutlinedIcon
              sx={{ color: colors.LightGrey400, marginRight: 2 }}
            />
          }
        />
        <TextInputBox
          formik={formik}
          attributeName='lastName'
          placeholder={t('company.lastName')}
          disabled={disableNameField}
          endAdornment={
            <PersonOutlineOutlinedIcon
              sx={{ color: colors.LightGrey400, marginRight: 2 }}
            />
          }
        />
        <FormSelectInput
          fullWidth
          type='text'
          name='relationship'
          formik={formik}
          placeholder={t('myTeam.selectRelationship')}
          variant='standard'
          updatedStyle
          attributeName='relationship'>
          {USER_CONNECTION_RELATIONSHIP.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {t(`constants.userConnRelationship.${item.value}`)}
            </MenuItem>
          ))}
        </FormSelectInput>
        {createError ? (
          <Alert severity='error'>
            {t('myTeam.failedToCreateContact')}
          </Alert>
        ) : null}
      </SimpleInputDialog>

      <OverlayLoading open={loading} />
      <NotificationSnackBar
        open={showNotification}
        setOpen={setShowNotification}
        success={true}
        successText={t('myTeam.connectionAddedSuccessfully')}
      />
    </>
  )
}

export default AddConnectionButton
