import { Button, alpha } from '@mui/material'
const RoundedFilledIconButton = props => {
  const { StartIcon, color, onClick } = props
  return (
    <Button
      sx={{
        padding: '2px 2px',
        borderRadius: '8px',
        backgroundColor: alpha(color, 0.1),
        width: '25px',
        height: '25px',
        minWidth: '10px',
        '& .MuiButton-startIcon': { margin: 0 },
        '&:hover':{backgroundColor: alpha(color, 0.5)}
      }}
      disableElevation={true}
      onClick={onClick}
      startIcon={
        <StartIcon
          sx={{
            color: color,
            borderRadius: 5
          }}
        />
      }
    />
  )
}

export default RoundedFilledIconButton
