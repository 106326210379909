import { Avatar, Chip } from '@mui/material'
import { getNameIntials, getFullName } from 'utils/formatText'

const AvatarChip = props => {
  const {
    user,
    closeIcon,
    closeAction,
    variant = 'outlined',
    sx,
    onDelete,
    chipNameWithEmail = false,
    ...otherProps
  } = props
  const { profile_image: initialProfileImage } = user
  const profileImage = initialProfileImage || user.profileImage
  const nameInitials = getNameIntials(user)
  const fullName = getFullName(user, chipNameWithEmail)
  return (
    <Chip
      {...otherProps}
      avatar={
        profileImage ? (
          <Avatar alt={nameInitials} src={profileImage} />
        ) : (
          <Avatar>{nameInitials}</Avatar>
        )
      }
      size='medium'
      variant={variant}
      label={fullName}
      title={fullName}
      onDelete={closeIcon ? closeAction : onDelete}
      sx={[
        {
          background: 'transparent',
          fontSize: 14
        },
        sx
      ]}
    />
  )
}

export default AvatarChip
