import { Icon, Typography } from '@mui/material'
import colors from 'styles/colors'
import InCardStack from './InCardStack'
import { useTranslation } from 'react-i18next'

const RadarGraphLegend = ({ displayOthersRating = true }) => {
  const { t } = useTranslation()

  return (
    <InCardStack>
      <Typography color='primary' fontStyle='italic'>
        {t('goalsArea.clickBehaviours')}
      </Typography>
      <InCardStack
        direction='row'
        sx={{ marginTop: '5px !important' }}
        spacing={15}>
        <Typography fontWeight='bold'>
          <Icon
            color='primary'
            style={{ paddingRight: '20px', fontSize: '10px' }}>
            circle
          </Icon>
          {t('goalsArea.selfRating')}
        </Typography>
        {displayOthersRating ? (
          <Typography fontWeight='bold'>
            <Icon
              style={{
                paddingRight: '20px',
                fontSize: '10px',
                color: colors.Aluminium
              }}>
              circle
            </Icon>
            {t('goalsArea.othersRating')}
          </Typography>
        ) : null}
      </InCardStack>
    </InCardStack>
  )
}

export default RadarGraphLegend
