import React, { useState } from 'react'
import { useFormik } from 'formik'
import {
  Alert,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import { LOGIN } from 'routes/constants'
import { RESET_PASSWORD_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import { passwordValidationSchema } from 'utils/formValidations'
import { useTranslation } from 'react-i18next'
import FloatingCard from 'components/atoms/container/FloatingCard'
import LanguageIcon from '@mui/icons-material/Language'
import { LANGUAGE_OPTIONS } from 'utils/constants'
import { multilingualSwitch } from 'config/config'
import InCardStack from 'components/atoms/InCardStack'
import StyledButton from 'components/atoms/button/StyledButton'
import { useNavigate } from 'react-router-dom'
import { PasswordInputBox } from 'components/atoms/input/FormInputBox'

const ResetPasswordCard = ({ token }) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [requestSuccess, setRequestSuccess] = useState(undefined)

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: passwordValidationSchema,
    onSubmit: async values => {
      const requestBody = {
        password: values.password,
        token: token
      }
      const res = await postRequest(RESET_PASSWORD_URL, requestBody)
      if (res.data.success) {
        setRequestSuccess(true)
      } else {
        setRequestSuccess(false)
      }
    }
  })

  return (
    <>
      {requestSuccess === true ? (
        <>
          <CardContent>
            <Typography variant='body1'>
              {t('authPages.reestPasswordSucessfully')}
            </Typography>
          </CardContent>

          <CardContent>
            <LinkButton
              buttonProps={{ sx: { fontWeight: 600, borderRadius: 10 } }}
              to={LOGIN}>
              {t('authPages.backToLogin')}
            </LinkButton>
          </CardContent>

          {multilingualSwitch ? (
            <CardContent sx={{ marginTop: 15, alignItems: 'center' }}>
              <LanguageIcon />
              <FormControl>
                <Select
                  sx={{
                    marginLeft: 5,
                    paddingLeft: 2,
                    minWidth: 120,
                    height: 30
                  }}
                  value={i18n.resolvedLanguage}
                  onChange={e => {
                    i18n.changeLanguage(e.target.value)
                    sessionStorage.clear()
                  }}>
                  {LANGUAGE_OPTIONS.map(l => (
                    <MenuItem
                      key={`language-option-${l.value}`}
                      value={l.value}>
                      {t(l.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          ) : null}
        </>
      ) : (
        <FloatingCard>
          <form onSubmit={formik.handleSubmit}>
            <InCardStack width='100%'>
              <PasswordInputBox
                isValidate
                formik={formik}
                attributeName='password'
                placeholder={t('authPages.newPassword')}
              />
              <PasswordInputBox
                formik={formik}
                attributeName='confirmPassword'
                placeholder={t('authPages.confirmPassword')}
              />
              {requestSuccess === false ? (
                <Alert severity='error'>
                  {t('authPages.somethingWentWrong')}
                </Alert>
              ) : null}

              <StyledButton type='submit' sx={{ width: '100%' }}>
                {t('authPages.reset')}
              </StyledButton>

              <StyledButton
                sx={{ width: '100%' }}
                onClick={() => navigate(LOGIN)}>
                {t('authPages.backToLogin')}
              </StyledButton>
            </InCardStack>
          </form>
        </FloatingCard>
      )}
    </>
  )
}
export default ResetPasswordCard
