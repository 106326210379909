import { Typography } from '@mui/material'
import UserFeedbackCard from 'components/organisms/UserFeedbackCard'
import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import SinglePageStack from 'components/templates/SinglePageStack'
import UserFeedbackNew from '../../molecules/FeedbackNew'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import { useLocation } from 'react-router-dom'
import { permitted } from 'utils/permissionCheck'
import { PERMISSION_LEVEL } from 'routes/constants'
import { useTranslation } from 'react-i18next'

const UserFeedback = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const hasLicense = permitted(PERMISSION_LEVEL.LICENSED)
  return (
    <FeedbackPage>
      <SinglePageStack spacing={10}>
        {location.state?.from ? <BackIconTextButton /> : null}
        <Typography variant='h3'>{t('feedbackArea.recentFeedback')}</Typography>
        <UserFeedbackCard hasLicense={hasLicense} />
        {hasLicense ? <UserFeedbackNew /> : null}
      </SinglePageStack>
    </FeedbackPage>
  )
}

export default UserFeedback
