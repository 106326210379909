import { PageHeader } from 'components/atoms/Text'
import BookmarksCard from 'components/organisms/BookmarksCard'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { useTranslation } from 'react-i18next'

const Bookmarks = () => {
  const { t } = useTranslation()
  return (
    <HomePage>
      <PageHeader>{t('bookmarks.myBookmarks')}</PageHeader>
      <BookmarksCard />
    </HomePage>
  )
}

export default Bookmarks
