import { Autocomplete, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { USERS_URL, USER_ROLE_TYPES } from 'services/constants'
import { getUserConnections } from 'services/userServices'
import { getRequest } from 'utils/api'
import { getFullName } from 'utils/formatText'
import UserConnectionMenuItem from './UserConnectionMenuItem'
import { useTranslation } from 'react-i18next'

const UserConnectionSelect = ({
  formik,
  fieldName,
  useAdminData = false,
  placeholder,
  organizationId = undefined,
  coaches = undefined
}) => {
  const { t } = useTranslation()
  const [recipients, setRecipients] = useState([])
  const [selected, setSelected] = useState(null)
  const [recipientRequestSuccess, setRecipientRequestSuccess] =
    useState(undefined)

  const fetchData = async (init = false) => {
    let res
    if (useAdminData) {
      const queryParams = {
        noStats: true,
        coaches
      }
      const userRes = await getRequest(USERS_URL, queryParams)
      res = userRes.data.data
        .filter(u => u.role !== USER_ROLE_TYPES.RESTRICTED)
        .map(u => ({ user: u }))
    } else {
      res = await getUserConnections()
    }
    if (res.length) {
      if (init) {
        const preSelected = res.find(
          u => u.user.id === formik.values[fieldName]
        )
        if (preSelected) {
          setSelected(preSelected)
        }
      }
      setRecipients(res)
      setRecipientRequestSuccess(true)
    } else {
      setRecipientRequestSuccess(false)
    }
  }

  useEffect(() => {
    if (formik.values[fieldName] && recipients.length === 0) {
      fetchData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values[fieldName]])

  const handleOpen = () => {
    if (recipients.length === 0) {
      fetchData()
    }
  }

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      options={recipients}
      value={selected}
      onOpen={() => handleOpen()}
      noOptionsText={
        recipientRequestSuccess === true
          ? t('feedbackArea.noMatchUserFound')
          : recipientRequestSuccess === false
            ? t('feedbackArea.failedLoadUserConnections')
            : t('feedbackArea.loadingConnections')
      }
      autoHighlight={true}
      renderOption={(props, option) => (
        <UserConnectionMenuItem
          itemProps={props}
          option={option}
          key={option.user.email}
          showEmail={true}
          showRelationship={false}
          showRole={true}
        />
      )}
      renderInput={params => (
        <TextField
          {...params}
          error={Boolean(formik.errors[fieldName])}
          helperText={formik.errors[fieldName]}
          placeholder={
            placeholder ? placeholder : t('feedbackArea.selectRecipient')
          }
        />
      )}
      getOptionLabel={option =>
        `${getFullName(option.user)} (${option.user.email})`
      }
      isOptionEqualToValue={(option, value) =>
        option.user.email === value.user.email
      }
      onChange={(e, value) => {
        setSelected(value)
        formik.setFieldValue(fieldName, value?.user.id)
      }}
    />
  )
}

export default UserConnectionSelect
