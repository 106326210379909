import {
  MenuItem,
  MenuList,
  Typography,
  CardContent,
  Grid,
  Box
} from '@mui/material'
import { NotificationBodyText } from 'components/atoms/Text'
import CohortUserProgress from 'components/organisms/adminDashboard/CohortUserProgress'
import InternalReportPage from 'components/templates/navigationPageTemplates/InternalReportPage'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { COHORTS_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { getGoalInfoById } from 'utils/getSupportingInfo'

const UserProgressReport = () => {
  const [companyList, setCompanyList] = useState([])
  const [selectedCohortSession, setSelectedCohortSession] = useState(null)
  const containerRef = useRef()
  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(COHORTS_URL)
      if (res.status === 200) {
        const resData = res.data.data
        const companies = resData
          .filter(c => c.organizationId)
          .map(c => ({
            id: c.organizationId,
            name: c.organization?.name
          }))
        const uniqueComanies = [
          ...new Map(companies.map(i => [i.id, i])).values()
        ]
        const companyWithCohorts = uniqueComanies.map(org => {
          const cohorts = resData.filter(c => c.organizationId === org.id)
          return {
            ...org,
            cohorts
          }
        })
        setCompanyList(companyWithCohorts)
      }
    }
    fetchData()
  }, [])


  const handleOrgExpand = async org => {
    const newCompanyList = [...companyList]
    const o = newCompanyList.find(n => n.id === org.id)
    if (!org.cohorts[0]?.cohortSessions) {
      const res = await getRequest(COHORTS_URL, { organizationId: org.id })
      if (res.status === 200) {
        o.cohorts = await Promise.all(
          res.data.data.map(async c => {
            c.cohortSessions = await Promise.all(
              c.cohortSessions
                .filter(s => moment.utc(s.startDate) < moment.utc())
                .map(async s => {
                  const goal = await getGoalInfoById(s.goalId)
                  return {
                    ...s,
                    goalName: goal?.name
                  }
                })
            )
            return c
          })
        )
      }
    }
    o.expanded = !o.expanded
    setCompanyList(newCompanyList)
  }
  const handleCohortExpand = (org, cohort) => {
    const newCompanyList = [...companyList]
    const o = newCompanyList.find(n => n.id === org.id)
    const c = o.cohorts.find(n => n.id === cohort.id)
    c.expanded = !c.expanded
    setCompanyList(newCompanyList)
  }
  return (
    <InternalReportPage>
      <Grid container width='100%' ref={containerRef}>
        <Grid item width={300} height='inherit'>
          <Box overflow='auto'>
            <MenuList>
              {companyList.map(o => [
                <MenuItem
                  key={`organization-${o.id}`}
                  onClick={() => handleOrgExpand(o)}>
                  {o.name}
                </MenuItem>,
                o.expanded
                  ? o.cohorts.map(c => [
                      <MenuItem
                        key={`cohort-${c.id}`}
                        onClick={() => handleCohortExpand(o, c)}>
                        - {c.name}
                      </MenuItem>,
                      c.expanded && c.cohortSessions
                        ? c.cohortSessions.map(s => (
                            <MenuItem
                              key={`cohort-session-${s.id}`}
                              onClick={() => {
                                setSelectedCohortSession(s)
                              }}>
                              <Typography paddingLeft={8}>
                                - {s.goalName}
                              </Typography>
                            </MenuItem>
                          ))
                        : null
                    ])
                  : null
              ])}
            </MenuList>
          </Box>
        </Grid>
        <Grid item width={containerRef.current?.offsetWidth-300}>
          {selectedCohortSession ? (
            <CohortUserProgress cohortSession={selectedCohortSession} />
          ) : (
            <CardContent>
              <NotificationBodyText>
                Expand a Organization and Cohort to See Available Sessions and
                Select to View Data.
              </NotificationBodyText>
            </CardContent>
          )}
        </Grid>
      </Grid>
    </InternalReportPage>
  )
}

export default UserProgressReport
