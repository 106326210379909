import { Typography, darken } from '@mui/material'
import ReportPage from 'components/templates/ReportPage'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import RadarGraph from 'components/molecules/assessments/RadarGraph'
import { useTranslation } from 'react-i18next'

const CategorySummaryPage = ({
  downloadingFile,
  goalData,
  categoryData,
  pageNum
}) => {
  const { t } = useTranslation()
  const categoryColor = categoryData?.color || colors.Black
  const darkCategoryColor = darken(categoryColor, 0.2) || colors.Black

  return (
    <>
      <ReportPage
        assessmentName={goalData?.name}
        downloadingFile={downloadingFile}
        pageNum={pageNum}
        childrenWrapperSx={{ paddingLeft: 26, paddingRight: 26 }}>
        <InCardStack>
          <Typography variant='h6' color={categoryColor}>
            {categoryData?.modelName}
          </Typography>

          <Typography variant='reportH1' sx={{ color: darkCategoryColor }}>
            {categoryData?.name} {t('fullReport.summary')}
          </Typography>

          <Typography
            variant='formText'
            style={{
              color: colors.MidnightTeal400,
              textAlign: 'center',
              width: '80%',
              marginTop: 60
            }}>
            {categoryData?.description}
          </Typography>

          <RadarGraph
            assessmentData={{
              goalData,
              ...categoryData.data
            }}
            disableBehaviorClick={true}
            size={350}
            labelColor={darkCategoryColor}
            disableScroll={true}
          />
        </InCardStack>
      </ReportPage>
    </>
  )
}

export default CategorySummaryPage
