import { Grid, MenuItem, Stack, Typography } from '@mui/material'
import FormDatePicker from 'components/atoms/input/FormDatePicker'
import moment from 'moment'
import FormSelectInput from 'components/atoms/input/FormSelectInput'
import InCardStack from 'components/atoms/InCardStack'
import SettingSwitch from 'components/atoms/input/SettingSwitch'
import { useState } from 'react'
import { useEffect } from 'react'
import { getGoals, getGoalSteps } from 'utils/getSupportingInfo'
import FormInputBox from 'components/atoms/input/FormInputBox'
import { getRequest } from 'utils/api'
import { GOAL_TIMELINES_URL } from 'services/constants'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import UserConnectionSelect from 'components/atoms/input/UserConnectionSelect'
import FacilitationRow from './FacilitationRow'
import { useTranslation } from 'react-i18next'

const reAssessmentTimingOptions = ['0', '30', '60', '90', '120']

const CohortSessionFields = ({
  formik,
  sessionFieldOnly,
  autoAssignPathOnStart,
  setAutoAssignPathOnStart,
  postGoalActivityEmails,
  setPostGoalActivityEmails,
  preFacilitationEmails,
  setPreFacilitationEmails
}) => {
  const { t } = useTranslation()
  const [goalData, setGoalData] = useState([])
  const [goalTimelines, setGoalTimelines] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      // permission filtering required if component use outside of internal dashboard
      const goals = await getGoals()
      setGoalData(goals.filter(g => g.available))
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchTimeline = async () => {
      const steps = await getGoalSteps(formik.values.path)
      const res = await getRequest(GOAL_TIMELINES_URL, {
        goalId: formik.values.path
      })
      if (res.status === 200 && steps) {
        const timelines = res.data.data
        timelines.forEach(t => {
          const milestoneStep = steps.find(s => s.id === t.endGoalStepId)
          t.stepName = milestoneStep?.name
        })
        setGoalTimelines(timelines)
      }
    }

    if (formik.values.path !== '') {
      fetchTimeline()
    }
  }, [formik.values.path])

  const checkPathDisabled = () => {
    if (!formik.values.facilitations.length) {
      return false
    }
    const someNotDeleting = formik.values.facilitations.find(
      f => f.facilitationDate
    )
    return Boolean(someNotDeleting)
  }
  return (
    <>
      {sessionFieldOnly ? null : (
        <>
          <Typography variant='subtitle2'>{t('company.cohortName')}</Typography>
          <FormInputBox
            formik={formik}
            attributeName='cohortName'
            placeholder={t('company.enterNameOfCohort')}
          />
          <Typography variant='subtitle1'>
            {t('company.firstLearningPathDetails')}
          </Typography>
        </>
      )}
      <Grid container gap={5}>
        <Grid item xs={5}>
          <Stack padding={0} spacing={4}>
            <Typography variant='subtitle2'>
              {t('company.startDate')}
            </Typography>
            <FormDatePicker
              formik={formik}
              attributeName='startDate'
              minDate={moment()}
            />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack padding={0} spacing={4}>
            <Typography variant='subtitle2'>{t('company.endDate')}</Typography>
            <FormDatePicker
              formik={formik}
              attributeName='endDate'
              minDate={moment()}
            />
          </Stack>
        </Grid>
      </Grid>
      <Typography variant='subtitle2'>{t('overview.learningPath')}</Typography>
      <FormSelectInput
        formik={formik}
        attributeName='path'
        placeholder={t('company.selectLearningPath')}
        disabled={checkPathDisabled()}>
        {goalData.map(option => (
          <MenuItem key={`timing-option-${option.id}`} value={option.id}>
            {option.leadershipPathName}
          </MenuItem>
        ))}
      </FormSelectInput>
      {formik.values.path ? (
        <>
          <PresetTableContainer shorterRow={true}>
            {goalTimelines.map(t => (
              <FacilitationRow
                key={`timeline-row-${t.id}`}
                formik={formik}
                timeline={t}
                minDate={formik.values.startDate}
                maxDate={formik.values.endDate}
              />
            ))}
          </PresetTableContainer>
          <Typography variant='subtitle2'>
            {t('company.facilitator')}
          </Typography>
          <UserConnectionSelect
            formik={formik}
            fieldName='facilitatorId'
            placeholder={t('company.selectFacilitator')}
            useAdminData={true}
            coaches={true}
          />
        </>
      ) : (
        <Typography variant='body2' textAlign='center'>
          {t('company.selectPathLoadPresetPathTimelines')}
        </Typography>
      )}
      <Typography variant='subtitle2'>
        {t('company._360ReassessmentTiming')}
      </Typography>
      <FormSelectInput
        formik={formik}
        attributeName='reAssessmentTiming'
        placeholder={t('company.selectTiming')}>
        {reAssessmentTimingOptions.map(option => (
          <MenuItem key={`timing-option-${option}`} value={option}>
            {option === '0'
              ? t('company.disable')
              : `${option} ${t('company.days')}`}
          </MenuItem>
        ))}
      </FormSelectInput>
      <InCardStack direction='row'>
        <Typography variant='subtitle2'>
          {t('company.autoAssignPathOnSessionStart')}
        </Typography>
        <SettingSwitch
          testId='auto-assign-path-switch'
          checked={autoAssignPathOnStart}
          onChange={e => setAutoAssignPathOnStart(e.target.checked)}
        />
      </InCardStack>
      <InCardStack direction='row'>
        <Typography variant='subtitle2'>
          {t('company.postGoalActivityEmail')}
        </Typography>
        <SettingSwitch
          testId='post-goal-email-switch'
          checked={postGoalActivityEmails}
          onChange={e => setPostGoalActivityEmails(e.target.checked)}
        />
      </InCardStack>
      <InCardStack direction='row'>
        <Typography variant='subtitle2'>
          {t('company.preFacilitationReminderEmails')}
        </Typography>
        <SettingSwitch
          testId='post-goal-email-switch'
          checked={preFacilitationEmails}
          onChange={e => setPreFacilitationEmails(e.target.checked)}
        />
      </InCardStack>
    </>
  )
}

export default CohortSessionFields
