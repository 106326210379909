import { useEffect, useState } from 'react'
import { FormControlLabel, Checkbox, Stack, Box } from '@mui/material'
import { CALENDAR_OAUTH_TOKEN_PROVIDERS } from 'services/constants'
import { getThirdPartyOauth } from 'services/userServices'
import colors from 'styles/colors'
import RadioTags from 'components/atoms/RadioTags'
import LinkCalendarButton from 'components/molecules/oAuth/LinkCalendarButton'
import { useTranslation } from 'react-i18next'

const CalendarSetup = ({ formik }) => {
  const { t } = useTranslation()
  const [linked, setLinked] = useState(false)
  const [calendarProvider, setCalendarProvider] = useState(null)

  const checkCalenderLinked = async () => {
    const res = await getThirdPartyOauth()
    setLinked(res.status === 200)
    setCalendarProvider(res?.data?.data?.provider)
  }

  const signinCallback = async success => {
    if (success) {
      checkCalenderLinked()
    }
  }

  useEffect(() => {
    checkCalenderLinked()
  }, [])

  const linkCalendarBtnStyle = {
    background: colors.LightBlue,
    color: colors.BondiBlue,
    boxShadow: 'none',
    borderRadius: '50px',
    padding: '8px 12px',
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '16px'
  }

  return (
    <>
      {linked ? (
        <>
          {calendarProvider === CALENDAR_OAUTH_TOKEN_PROVIDERS.GOOGLE && (
            <Stack padding={0} spacing={6}>
              <FormControlLabel
                label={t('contact.addVideoConferencing')}
                labelPlacement='start'
                sx={{
                  color: colors.DarkGrey
                }}
                control={
                  <Checkbox
                    onChange={e =>
                      formik.setFieldValue('videoConference', e.target.checked)
                    }
                    color='primary'
                  />
                }
              />

              <RadioTags
                tags={[
                  {
                    key: 'googleMeet',
                    value: 'googleMeet',
                    label: t('contact.googleMeet')
                  }
                ]}
                selectedTag={
                  formik.values.videoConference ? 'googleMeet' : null
                }
                setSelectedTag={() => {}}
              />
            </Stack>
          )}
        </>
      ) : (
        <Box padding={0} paddingY={12} spacing={6}>
          <LinkCalendarButton
            signinCallback={signinCallback}
            label={t('contact.whyNotLinkCalendar')}
            btnStyle={linkCalendarBtnStyle}
          />
        </Box>
      )}
    </>
  )
}

export default CalendarSetup
