import { CardContent, Divider, Grid, Stack, alpha, Button } from '@mui/material'
import { Subtitle4 } from 'components/atoms/Text'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import colors from 'styles/colors'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import DateTimeStack from 'components/molecules/calendarEvents/DateTimeStack'
import EventContent from 'components/molecules/calendarEvents/EventContent'

const eventColorOptions = [
  [colors.LightSalmon, 0.14],
  [colors.MonarkYellow, 0.05]
]

const CalendarEventsList = ({ events, home }) => {
  const displayEvents = home ? events.slice(0, 2) : events

  const getColorByIndex = (i, background = false) => {
    const r = i % 2
    return alpha(
      eventColorOptions[r][0],
      background ? eventColorOptions[r][1] : 1
    )
  }

  return (
    <>
      {events.length > 0 ? (
        displayEvents.map((e, i) => {
          const fontColor = home ? getColorByIndex(i) : undefined
          if (home) {
            return (
              <Grid item xs={6} key={e.id} minHeight='100px'>
                <BoxWithBackground
                  width='100%'
                  height='100%'
                  backgroundColor={getColorByIndex(i, true)}>
                  <Stack padding={8} direction='row' spacing={10} height='100%'>
                    <DateTimeStack event={e} fontColor={fontColor} />
                    <Divider
                      orientation='vertical'
                      sx={{ height: 'inherit' }}
                    />
                    <EventContent event={e} fontColor={fontColor} home={home} />
                  </Stack>
                </BoxWithBackground>
              </Grid>
            )
          } else {
            return (
              <LeftJustifyCardContent key={e.id} sx={{ paddingTop: 0 }}>
                <Stack padding={0} width='100%'>
                  <Stack padding={0} spacing={10} direction='row'>
                    <BoxWithBorder
                      padding={4}
                      height='fit-content'
                      marginBottom={5}
                      minWidth='105px'>
                      <DateTimeStack event={e} fontColor={fontColor} />
                    </BoxWithBorder>
                    <EventContent event={e} fontColor={fontColor} home={home} />
                  </Stack>

                  <Divider />
                </Stack>
              </LeftJustifyCardContent>
            )
          }
        })
      ) : (
        <CardContent sx={{ width: '100%' }}>
          <Subtitle4>No upcoming events.</Subtitle4>
        </CardContent>
      )}
      {home ? null : (
        <CardContent>
          <Button href={'mailto:?subject=Schedule a discussion with me'}>
            Request a Meeting
          </Button>
        </CardContent>
      )}
    </>
  )
}

export default CalendarEventsList
