import {
  CardContent,
  Divider,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { ActionText, NotificationBodyText } from 'components/atoms/Text'
import AvatarHeaderCell from 'components/atoms/table/AvartarHeaderCell'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import UserManagementContainer from 'components/molecules/adminDashboard/UserManagementContainer'
import { useEffect, useState } from 'react'
import {
  INVITED_LIST_URL,
  ORGANIZATIONS_URL,
  USERS_URL
} from 'services/constants'
import { getRequest } from 'utils/api'
import SearchInputBox from 'components/atoms/input/SearchInputBox'
import DeactivateUserButton from 'components/molecules/adminDashboard/DeactivateUserButton'
import UpgradeUserButton from 'components/molecules/adminDashboard/UpgradeUserButton'
import DowngradeUserButton from 'components/molecules/adminDashboard/DowngradeUserButton'
import CompanyInvitedUserList from './CompanyInvitedUserList'
import {
  getGoalNameById,
  getOrgLicenseStatus,
  userRoleMenuItems
} from 'utils/getSupportingInfo'
import InviteMoreButton from 'components/molecules/adminDashboard/InviteMoreButton'
import UserRoleSelectCell from 'components/molecules/adminDashboard/UserRoleSelectCell'
import { MONARK_CONTACT_EMAIL } from 'utils/constants'
import { getUserOrganizationId } from 'utils/localStorage'
import { permitted } from 'utils/permissionCheck'
import { isMatchingUser } from 'utils/userSearchHelper'
import { PERMISSION_LEVEL } from 'routes/constants'
import { useTranslation } from 'react-i18next'

const UserLicensesList = ({
  refreshDectivated,
  organizationId,
  refreshDataRef
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [users, setUsers] = useState({})
  const [organization, setOrganization] = useState(null)
  const [loading, setLoading] = useState(true)
  const [requestError, setRequestError] = useState(false)
  const [invitedUsers, setInvitedUsers] = useState([])
  const [inviteRequestError, setInviteRequestError] = useState(false)
  const [userFilterText, setUserFilterText] = useState('')
  const [raterFilterText, setRaterFilterText] = useState('')
  const [licenseActive, setLicenseActive] = useState(null)
  const isSuperAdminView =
    organizationId && permitted(PERMISSION_LEVEL.SUPER_ADMIN)

  const fetchInviteData = async () => {
    const res = await getRequest(INVITED_LIST_URL, {
      organizationId: isSuperAdminView
        ? organizationId
        : getUserOrganizationId()
    })
    let invited = []
    if (res.status === 200) {
      if (res.data?.data?.length > 0) {
        const licenseInvite = res.data.data
        const roleList = userRoleMenuItems()
        invited = await Promise.all(
          licenseInvite.map(async u => {
            const user = u
            if (u.goalId) {
              user.goalName = await getGoalNameById(u.goalId)
            }
            if (u.userRole) {
              const roleItem = roleList.find(r => r.value === u.userRole)
              user.roleName = t(`company.${roleItem?.label}`)
            }
            return user
          })
        )
      }
      setInviteRequestError(false)
    } else {
      setInviteRequestError(true)
    }
    setInvitedUsers(invited)
  }

  const fetchLicense = async orgUsers => {
    const newOrgUsers = { ...orgUsers }
    const resOrg = await getRequest(
      `${ORGANIZATIONS_URL}/${organizationId || getUserOrganizationId()}`,
      {
        licenseStats: true
      }
    )
    let licenseUsers = []
    if (resOrg.status === 200) {
      const orgData = resOrg.data.data
      licenseUsers = orgData.users
      const orgActive = getOrgLicenseStatus(orgData)
      if (orgActive === false) {
        orgData.licenseStats.licensesAvailable = 0
        orgData.licenseStats.licensesUsed = 0
        orgData.licenseCount = 0
      }
      setLicenseActive(orgActive)
      setOrganization(orgData)
    } else {
      setRequestError(true)
    }
    licenseUsers.forEach(u => {
      if (!newOrgUsers[u.id]) {
        newOrgUsers[u.id] = u
      }
      newOrgUsers[u.id].userLicense = u.userLicense
    })

    setUsers(newOrgUsers)
  }
  useEffect(() => {
    const fetchData = async () => {
      const queryParams = {
        noStats: true
      }
      if (isSuperAdminView) {
        queryParams.organizationId = organizationId
      }
      const resUser = await getRequest(USERS_URL, queryParams)
      const orgUsers = {}
      if (resUser.status === 200) {
        const resUsers = resUser.data?.data
        resUsers?.forEach(u => {
          orgUsers[u.id] = u
        })
      } else {
        setRequestError(true)
      }
      await fetchLicense(orgUsers)
      await fetchInviteData()
      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, refreshDataRef])

  const deleteUserCallback = async userId => {
    delete users[userId]
    await refreshDectivated()
  }

  const downgradeUserCallback = userId => {
    const orgUsers = { ...users }
    delete orgUsers[userId].userLicense
    setUsers(orgUsers)
    organization.licenseStats.licensesUsed--
    organization.licenseStats.licensesAvailable++
  }

  const inviteUserCallback = async () => {
    await fetchLicense(users)
    await fetchInviteData()
  }

  const removeInvitedUser = id => {
    setInvitedUsers(invitedUsers.filter(i => i.id !== id))
    organization.licenseStats.licensesUsed--
    organization.licenseStats.licensesAvailable++
  }

  const contentContainerProps = {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: 5,
    spacing: 3
  }
  return (
    <>
      <UserManagementContainer
        titleText={t('company.licensedUsers')}
        requestError={requestError}
        leftPanelComponent={
          <>
            {organization?.licenseCount ? (
              <>
                <Typography variant='body1Small'>
                  {t('company.inviteMoreUsersMonark')}
                </Typography>
                <InviteMoreButton
                  organizationId={organization.id}
                  licensesAvailable={
                    organization.licenseStats.licensesAvailable
                  }
                  successCallback={inviteUserCallback}
                  isSuperAdminView={isSuperAdminView}
                />
              </>
            ) : null}
            <Typography variant='body1Small'>
              {`${organization?.licenseStats.licensesUsed || 0}/${
                organization?.licenseCount || 0
              } ${t('company.licensesInUse')}`}
            </Typography>
            {licenseActive === false ? (
              <Typography variant='body1Small'>
                {t('company.licensesHaveExpired')}
              </Typography>
            ) : null}
            <Typography variant='body1Small'>
              {licenseActive === false
                ? t('company.wantToReactivateSubscription')
                : t('company.needMoreLicenses')}
              {t('company.contactUs')}
              <ActionText
                href={`mailto:${MONARK_CONTACT_EMAIL}?subject=${t('company.getMoreMonarkLicenses')}`}>
                {MONARK_CONTACT_EMAIL}
              </ActionText>
            </Typography>
          </>
        }>
        {!organization?.licenseType ? (
          <NotificationBodyText>
            {t('company.youDoNotHaveActiveSubscription')}
          </NotificationBodyText>
        ) : organization?.users?.length > 0 ? (
          <Stack {...contentContainerProps}>
            <SearchInputBox
              onChangeHandler={e => setUserFilterText(e.target.value)}
              placeholder={t('overview.searchForUser')}
            />
            <PresetTableContainer
              shorterRow={true}
              maxHeight='500px'
              headers={
                <>
                  <TableCell>{t('company.userDetails')}</TableCell>
                  <TableCell />
                  <TableCell>{t('company.userRole')}</TableCell>
                  <TableCell width='100px' />
                </>
              }>
              {Object.entries(users)
                .filter(([, u]) => u.userLicense)
                .filter(([, u]) => isMatchingUser(u, userFilterText))
                .map(([id, u]) => (
                  <TableRow key={id}>
                    <AvatarHeaderCell user={u} />
                    <TableCell />
                    <UserRoleSelectCell user={u} disabled={!licenseActive} />
                    <TableCell width='100px'>
                      {licenseActive ? (
                        <InCardStack spacing={0} direction='row'>
                          <DowngradeUserButton
                            successCallback={downgradeUserCallback}
                            user={u}
                          />
                          {u.role ? (
                            <DeactivateUserButton
                              successCallback={userId => {
                                downgradeUserCallback(userId)
                                deleteUserCallback(userId)
                              }}
                              user={u}
                              fromLicensed={true}
                            />
                          ) : null}
                        </InCardStack>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
            </PresetTableContainer>
          </Stack>
        ) : (
          <CardContent>
            <NotificationBodyText>
              {t('company.doNotHaveLicensedUsers')}
            </NotificationBodyText>
          </CardContent>
        )}
      </UserManagementContainer>
      <Divider />
      <UserManagementContainer
        requestError={requestError}
        titleText={t('company.unlicensedUsers')}
        description={t('company.usersHaveAdministrator')}
        leftPanelComponent={
          <>
            <InviteMoreButton
              organizationId={organization?.id}
              successCallback={inviteUserCallback}
              licenseInvite={false}
            />
            <Typography variant='body2'>
              {t('company.adminDoNotRequireUserLicense')}
            </Typography>
          </>
        }>
        {Object.entries(users).filter(([, u]) => !u.userLicense).length > 0 ? (
          <Stack {...contentContainerProps}>
            <SearchInputBox
              onChangeHandler={e => setRaterFilterText(e.target.value)}
              placeholder={t('overview.searchForUser')}
            />
            <PresetTableContainer
              maxHeight='500px'
              shorterRow={true}
              headers={
                <>
                  <TableCell>{t('company.userDetails')}</TableCell>
                  <TableCell>{t('company.userRole')}</TableCell>
                  <TableCell width='100px' />
                </>
              }>
              {Object.entries(users)
                .filter(([, u]) => !u.userLicense)
                .filter(([, u]) => isMatchingUser(u, raterFilterText))
                .map(([id, u]) => (
                  <TableRow key={id}>
                    <AvatarHeaderCell user={u} />
                    <UserRoleSelectCell user={u} disabled={!licenseActive} />
                    <TableCell width='100px'>
                      {licenseActive ? (
                        <InCardStack spacing={0} direction='row'>
                          <UpgradeUserButton
                            successCallback={async () =>
                              await fetchLicense(users)
                            }
                            user={u}
                            organizationId={organization.id}
                            disabled={
                              organization.licenseStats.licensesAvailable <= 0
                            }
                          />
                          <DeactivateUserButton
                            successCallback={deleteUserCallback}
                            user={u}
                          />
                        </InCardStack>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
            </PresetTableContainer>
          </Stack>
        ) : (
          <CardContent>
            <NotificationBodyText>
              {t('company.allUsersAccessToMonark')}
            </NotificationBodyText>
          </CardContent>
        )}
      </UserManagementContainer>
      <Divider />
      <CompanyInvitedUserList
        users={invitedUsers}
        requestError={inviteRequestError}
        removeCallback={removeInvitedUser}
        licenseActive={licenseActive}
      />
      <OverlayLoading open={loading} />
    </>
  )
}

export default UserLicensesList
