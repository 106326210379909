import { Circle } from '@mui/icons-material'
import { alpha, Box, IconButton, Typography, useTheme } from '@mui/material'
import { getSizeString } from 'utils/formatText'

const NumberIconButton = props => {
  const theme = useTheme()
  const {
    number,
    size = 40,
    color = theme.palette.primary,
    fontSize = 14,
    textColor = theme.palette.background.default,
    hoverColor = theme.palette.primary.dark,
    disabled = false,
    onClick,
    active,
    ...otherProps
  } = props
  const cursorSX = { '&:hover': { cursor: disabled ? 'default' : 'pointer' } }
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      component='span'
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      sx={cursorSX}
      {...otherProps}>
      <IconButton sx={[cursorSX, {padding: active ? 0 : getSizeString(7)}]} disabled={disabled}>
        <Circle
          sx={{
            width: getSizeString(size + (active ? 10 : 0)),
            height: getSizeString(size + (active ? 10 : 0)),
            color: disabled ? alpha(theme.palette.action.disabled, 0.8) : color,
            '&:hover': {
              color: disabled ? undefined : hoverColor
            },
            borderRadius: 'inherit',
            borderColor:
              alpha(theme.palette.primary.light, 0.3) + ' !important',
            border: active ? '7px solid' : undefined
          }}
        />
        <Typography
          position='absolute'
          color={textColor}
          variant='h3'
          fontSize={getSizeString(fontSize)}
          alignItems='center'
          display='flex'>
          {number}
        </Typography>
      </IconButton>
    </Box>
  )
}

export default NumberIconButton
