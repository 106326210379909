import {Box, CardContent, Typography, useTheme} from '@mui/material'
import {useTranslation} from 'react-i18next'
import InCardStack from 'components/atoms/InCardStack'
import RowStack from 'components/atoms/container/RowStack'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import { InputDialog, WideInputDialog} from 'components/molecules/notificationOverlay/InputDialog'
import colors from 'styles/colors'

const ScoreRow = ({title, score}) => {
  const scoreColor = score >= 4 ? colors.SuccessGreen : colors.Watermelon400

  return (
    <BoxWithBackground
      sx={{
        width: '100%',
        paddingY: 6,
        paddingX: 8,
        borderRadius: 2,
      }}
    >
      <RowStack sx={{ justifyContent: 'space-between' }}>
        <Typography variant='h3' sx={{ flex: 1 }}>{title}</Typography>

        <BoxWithBackground
          backgroundColor={scoreColor}
          sx={{ paddingY: 4, paddingX: 6 }}
        >
          <Typography
            variant='button'
            sx={{ color: colors.White, whiteSpace: 'nowrap' }}
          >
            {parseFloat(score).toFixed(2)} / 5
          </Typography>
        </BoxWithBackground>
      </RowStack>
    </BoxWithBackground>
  )
}

export const MilestoneEncouragementPopup = ({
  open,
  onClose,
  message,
  virtueScores,
  nextMilestoneName,
}) => {
  const { t } = useTranslation()
  const isSelfScore = virtueScores.some(x => x.othersScore === null)

  return (
    <WideInputDialog
      open={open}
      handleCancel={onClose}
      confirmText={
        t('goalStepPaths.encouragementPopupButton', { x: nextMilestoneName})
      }
      titleText={t('goalStepPaths.encouragementPopupTitle')}
      cancelText={null}
      alignItems='left'>
      <InCardStack spacing={6}>
        <Typography variant='body1' sx={{ paddingBottom: 4 }}>
          {message}
        </Typography>

        <Typography
          variant='h4'
          sx={{ paddingBottom: 4, textAlign: 'start', width: '100%' }}
        >
          { isSelfScore
              ? t('goalStepPaths.selfAssessmentScores')
              : t('goalStepPaths.howOthersHaveRatedYou')
          }
        </Typography>
        {virtueScores.map(v => (
          <ScoreRow
            key={v.virtueName}
            title={v.virtueName}
            score={isSelfScore ? v.selfScore : v.othersScore}
          />
        ))}
      </InCardStack>
    </WideInputDialog>
  )
}

export const PopupAlert = ({ open, confirmText, onClose, title, message }) => {
  const theme = useTheme()
  return (
    <InputDialog
      open={open}
      confirmText={confirmText}
      titleText={title}
      handleCancel={onClose}
      cancelText={null}
      alignItems='left'>
      <CardContent sx={{ paddingY: 0 }}>
        <Box
          borderRadius={5}
          display='flex'
          flexDirection='column'
          gap={2}
          sx={{
            width: '100%',
            paddingY: 5,
            paddingX: 10,
            backgroundColor: theme.palette.background.default
          }}>
          <Typography variant='subtitle2'>{message}</Typography>
        </Box>
      </CardContent>
    </InputDialog>
  )
}
