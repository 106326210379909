import { CardContent, Grid, Stack, Typography } from '@mui/material'
import { PageHeader } from 'components/atoms/Text'

import LeadershipPathList from 'components/organisms/LeadershipPathList'
import JourneyPage from 'components/templates/navigationPageTemplates/JourneyPage'
import { useTranslation } from 'react-i18next'

const JourneyNew = () => {
  const { t } = useTranslation()
  return (
    <JourneyPage>
      <CardContent>
        {/* Replicating carousel setup to lineup to the right panel */}
        <Grid container width='100%' gap={15}>
          <Grid item xs={4} minWidth={330}></Grid>
          <Grid item xs={7}>
            <Stack spacing={5} padding={0}>
              <PageHeader>
                {t('pathsAndModels.startYourLearningJourney')}
              </PageHeader>
              <Typography variant='subtitle2'>
                {t('pathsAndModels.chooseAnItem')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <LeadershipPathList />
    </JourneyPage>
  )
}

export default JourneyNew
