import { Grid } from '@mui/material'
import RolePlayCard from 'components/molecules/rolePlay/RolePlayCard'

const RolePlayGrids = ({
  rolePlays,
  selectedVirtueId,
  setNewRolePlay = () => {}
}) => (
  <Grid container rowGap={6} paddingX={6} columnGap={12}>
    {rolePlays
      .filter(d => selectedVirtueId === '' || d.virtue.id === selectedVirtueId)
      .map(d => (
        <Grid key={`role-play-${d.id}`} item>
          <RolePlayCard
            data={d}
            setNewRolePlay={setNewRolePlay}
          />
        </Grid>
      ))}
  </Grid>
)

export default RolePlayGrids
