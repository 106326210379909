import { Buffer } from 'buffer'
import UnsubscribeEmailCard from 'components/organisms/user/UnsubscribeEmailCard'
import SimplePage from 'components/templates/SimplePage'
import { useParams } from 'react-router-dom'

const Unsubscribe = () => {
  const params = useParams()
  const decodedData = JSON.parse(Buffer.from(params.data, 'base64').toString('ascii'))
  return (
    <SimplePage>
      <UnsubscribeEmailCard data={decodedData}></UnsubscribeEmailCard>
    </SimplePage>
  )
}

export default Unsubscribe
