import { ExpandMore } from '@mui/icons-material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { TableCell, TableSortLabel } from '@mui/material'

const SortableHeaderCell = ({
  children,
  currentSort,
  attributeName,
  sortOrder,
  requestSort
}) => {
  return (
    <TableCell>
      <TableSortLabel
        active={currentSort === attributeName}
        direction={sortOrder}
        onClick={() => requestSort(attributeName)}
        hideSortIcon
        IconComponent={ExpandMore}
        >
        {children}
        <SwapVertIcon
          fontSize='small'
          sx={{
            opacity: 0.5,
            display: currentSort === attributeName ? 'none' : undefined
          }}
        />
      </TableSortLabel>
    </TableCell>
  )
}
export default SortableHeaderCell
