import { Button, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import { useState } from 'react'
import { ActionText } from 'components/atoms/Text'
import ProfileCard from '../ProfileCard'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { updateProfile } from 'services/userServices'
import OnboardingQuestiionnaire from '../OnboardingQuestionnaire'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'

const StepTwo = ({ completeCallback }) => {
  const { t } = useTranslation()
  const userInfo = getLocalUser()
  const [activePage, setActivePage] = useState(0)
  const [answers, setAnswers] = useState(userInfo.onboardingAnswers || {})
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    if (Object.keys(answers).length !== 0) {
      setLoading(true)
      const data = new FormData()
      data.append('onboardingAnswers', JSON.stringify(answers))
      await updateProfile(data)
    }
    completeCallback()
  }

  return (
    <>
      <InCardStack width='550px' spacing={10} marginTop={5}>
        {activePage === 0 ? (
          <>
            <Typography variant='h3'>{t('onboarding.createYourProfile')}</Typography>
            <Typography variant='subtitle2' textAlign='center'>
              {t('onboarding.thanksForSigningUp')} <br />
              {t('onboarding.helpUsOptimize')}
            </Typography>
            <ProfileCard
              submitButtonText='Continue'
              continueCallback={() => setActivePage(1)}
            />
          </>
        ) : activePage === 1 ? (
          <>
            <OnboardingQuestiionnaire
              updateAnswers={newAnswers => setAnswers(newAnswers)}
              userAnswers={answers}
            />
            <Button onClick={() => handleSubmit()}>{t('common.continue')}</Button>
            <ActionText onClick={() => completeCallback()}>{t('common.skip')}</ActionText>
          </>
        ) : null}
      </InCardStack>
      <OverlayLoading open={loading} />
    </>
  )
}

export default StepTwo
