import React from 'react'
import AuthorizedPage from 'components/templates/AuthorizedPage'
import LinkRoute from 'components/atoms/LinkRoute'
import { ROOT } from 'routes/constants'
import { Alert } from '@mui/material'

const Unauthorized = () => {
  return (
    <AuthorizedPage>
      {/* Unauthorized! */}
      <LinkRoute to={ROOT}>Return to Home Page</LinkRoute>
      <Alert severity='error'>Unauthorized: You are not authorized for the page</Alert>
    </AuthorizedPage>
  )
}

export default Unauthorized
