import { Box, Divider, Stack, Typography, alpha, useTheme } from '@mui/material'
import {
  Level1IconButton,
  Level2IconButton
} from 'components/atoms/button/JourneyPathIconButton'
import { getSizeString } from 'utils/formatText'
import { useEffect, useState } from 'react'
import { getRequest } from 'utils/api'
import { GOAL_TIMELINES_URL } from 'services/constants'
import colors from 'styles/colors'

const sessionColorOptions = [
  colors.BondiBlue,
  colors.Cinnabar,
  colors.MonarkYellow
]

const getLetterFromNumber = i => String.fromCharCode(i + 64)

const JourneyPath = ({
  organizedSteps,
  clickHandler,
  containerWidth,
  currentStepNumber,
  journeyCompleted = false,
  activeStepNumber,
  goalId
}) => {
  const theme = useTheme()

  const rowHeight = 56 // Row height for each step, both l1 and l2
  const buttonSize = 72 // l1 button size after padding
  const smallButtonSize = 44 // l2 button size after padding
  const paddingWidth = 16 * 2 // Container has padding passing down
  const sessionBarWidth = 20 // Session bar width
  const rightBufferWidth = 100 // Buffer space for text display
  const scrollBarWidth = 15 // In panel vertical scroll bar size
  // Canvas width for drowing buttons and connection lines
  const canvasWidth =
    containerWidth -
    buttonSize -
    paddingWidth -
    sessionBarWidth -
    rightBufferWidth -
    scrollBarWidth

  const [goalSessions, setGoalSessions] = useState([])
  useEffect(() => {
    const fetchGoalSessions = async () => {
      const res = await getRequest(GOAL_TIMELINES_URL, { goalId })
      if (res.status === 200) {
        const sessionData = res.data?.data
        if (
          sessionData?.length > 0 &&
          sessionData[sessionData.length - 1].endGoalStepId ===
            organizedSteps[organizedSteps.length - 1].id
        ) {
          const countedSessions = sessionData.map((s, i) => {
            const endStep = organizedSteps.find(o => o.id === s.endGoalStepId)
            let filteredSteps
            if (i === 0) {
              filteredSteps = organizedSteps.filter(
                o => o.number < endStep.number
              )
            } else {
              const startStep = organizedSteps.find(
                o => o.id === sessionData[i - 1].endGoalStepId
              )
              filteredSteps = organizedSteps.filter(
                o => o.number < endStep.number && o.number >= startStep.number
              )
            }
            let stepsCount = i === 0 || i === sessionData.length - 1 ? 0.5 : 0
            filteredSteps.forEach(
              f => (stepsCount += f.childL2Steps?.length + 1 || 0)
            )
            s.stepsCount = stepsCount

            return s
          })
          setGoalSessions(countedSessions)
        }
      }
    }
    if (goalId && organizedSteps.length > 0) {
      fetchGoalSessions()
    }
  }, [goalId, organizedSteps])

  useEffect(() => {
    if (activeStepNumber) {
      document
        .getElementById(`step-${activeStepNumber}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [activeStepNumber])
  return (
    <Box paddingTop='64px'>
      <Stack padding={0} direction='row' spacing={5} >
        <Stack padding={0} paddingLeft={1} spacing={1}>
          {goalSessions?.map((s, i) => (
            <Box
              height={s.stepsCount * rowHeight - 1}
              key={`goal-session-${s.id}`}
              sx={{ backgroundColor: alpha(sessionColorOptions[i % 3], 0.2) }}>
              <Typography
                textAlign='center'
                height='100%'
                fontSize='12px'
                sx={{
                  textOrientation: 'sideways',
                  writingMode: 'vertical-rl'
                }}>
                {s.label}
              </Typography>
            </Box>
          ))}
        </Stack>
        <Box style={{ marginTop: 5 }}>
          {organizedSteps.map((l1, i) => {
            const spaceCount = l1.childL2Steps?.length + 1
            const placementFactor = canvasWidth / spaceCount
            const originLeft = i % 2 === 0 ? true : false
            const totalHeight = spaceCount * rowHeight
            const connectorAngle = originLeft
              ? (Math.atan2(totalHeight, canvasWidth) * 180) / Math.PI
              : 180 - (Math.atan2(totalHeight, canvasWidth) * 180) / Math.PI
            const connectorLength = Math.sqrt(
              Math.pow(canvasWidth, 2) + Math.pow(totalHeight, 2)
            )
            const lastL1Step = i === organizedSteps.length - 1

            const activeL1Step =
              !journeyCompleted &&
              currentStepNumber > l1.number &&
              !lastL1Step &&
              organizedSteps[i + 1].number > currentStepNumber
            const completedL1Step =
              l1.number <= currentStepNumber && !activeL1Step

            const Level1Stack = ({ left }) => {
              const BigIcon = () => (
                <Level1IconButton
                  completed={completedL1Step}
                  number={i + 1}
                  active={activeL1Step}
                />
              )
              return (
                <Stack direction='row' padding={0} alignItems='center'>
                  {left ? <BigIcon /> : null}
                  <Typography component='span'>{l1.name}</Typography>
                  {left ? null : <BigIcon />}
                </Stack>
              )
            }

            return (
              <div key={l1.number}>
                {!lastL1Step ? (
                  // draw conncetion line to next l1 button if not last step
                  <Box
                    top={getSizeString(rowHeight / 2)}
                    left={
                      originLeft
                        ? getSizeString(buttonSize / 2)
                        : getSizeString(canvasWidth + buttonSize / 2)
                    }
                    position='relative'
                    justifyContent='center'
                    alignContent='flex-start'
                    display='flex'
                    width='fit-content'
                    zIndex={0}>
                    <Box
                      width={getSizeString(connectorLength)}
                      sx={{
                        transform: `rotate(${connectorAngle}deg)`,
                        transformOrigin: '0 50%'
                      }}>
                      <Divider
                        sx={{
                          borderColor: completedL1Step
                            ? theme.palette.primary.main
                            : undefined,
                          opacity: 0.6
                        }}
                      />
                    </Box>
                  </Box>
                ) : null}
                <Stack
                  key={l1.number}
                  padding={0}
                  width={getSizeString(
                    containerWidth -
                      paddingWidth -
                      scrollBarWidth -
                      sessionBarWidth
                  )}
                  zIndex={1}>
                  {originLeft ? (
                    <Box height={getSizeString(rowHeight)} display='flex'>
                      <Level1Stack left={true} />
                    </Box>
                  ) : (
                    <Box
                      display='flex'
                      justifyContent='flex-end'
                      marginRight={getSizeString(rightBufferWidth)}
                      height={getSizeString(rowHeight)}>
                      <Level1Stack left={false} />
                    </Box>
                  )}
                  {l1.childL2Steps.map((l2, j) => {
                    const margin =
                      placementFactor *
                        (originLeft ? j + 1 : spaceCount - j - 1) +
                      (buttonSize - smallButtonSize) / 2
                    const stepCompleted = currentStepNumber >= l2.number
                    return (
                      <Box
                        key={l2.number}
                        marginLeft={getSizeString(margin)}
                        height={getSizeString(rowHeight)}
                        display='flex'>
                        <Box
                          position='relative'
                          left={getSizeString(-margin)}
                          id={`step-${l2.number}`}
                        />
                        <Stack
                          direction='row'
                          padding={0}
                          alignItems='center'
                          onClick={() => stepCompleted && clickHandler(i, j)}
                          sx={{
                            '&:hover': {
                              cursor: stepCompleted ? 'pointer' : 'default'
                            }
                          }}>
                          <Level2IconButton
                            number={getLetterFromNumber(j + 1)}
                            stepIndex={j}
                            completed={stepCompleted}
                            originLeft={originLeft}
                            hasThoughtLeader={l2.hasThoughtLeader}
                            active={activeStepNumber === l2.number}
                            currentUserCount={l2.currentUserCount}
                          />
                          <Typography component='span' variant='body2'>
                            {l2.name}
                          </Typography>
                        </Stack>
                      </Box>
                    )
                  })}
                </Stack>
              </div>
            )
          })}
        </Box>
      </Stack>
    </Box>
  )
}

export default JourneyPath
