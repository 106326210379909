import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Grid,
  Box
} from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import AppUsageProgressBar from 'components/molecules/adminDashboard/AppUsageProgressBar'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

export const BehaviourTable = ({ children }) => {
  const { t } = useTranslation()
  const tableHeaderStyle = {
    marginRight: 10,
    lineHeight: '140%',
    color: colors.Grey300
  }

  return (
    <>
      <TableContainer>
        <RowStack justifyContent='space-between' marginBottom={3}>
          <Typography variant='body1Micro' style={tableHeaderStyle}>
            {t('fullReport.areaAssessed')}
          </Typography>

          <Typography variant='caption' style={tableHeaderStyle}>
            {t('pulseSurvey.averageRating')}
          </Typography>
        </RowStack>

        <Table>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export const BehaviourTableRow = ({
  name,
  score,
  index,
  fontColor,
  backColor
}) => {
  const tableBodyStyle = (
    color,
    backgroundColor,
    bold,
    cellSize,
    textAlign = 'center'
  ) => {
    return {
      border: `0.5px solid ${colors.Grey100}`,
      verticalAlign: 'middle',
      color: color || colors.Black,
      textAlign,
      fontWeight: bold ? '500' : 'normal',
      backgroundColor: backgroundColor ? backgroundColor : colors.White,
      width: cellSize || 'auto',
      padding: 8
    }
  }
  return (
    <TableRow key={name}>
      <TableCell style={tableBodyStyle(fontColor, backColor, true, 50)}>
        {index + 1}
      </TableCell>
      <TableCell
        style={{
          ...tableBodyStyle(undefined, undefined, false, undefined, 'left'),
          padding: 16
        }}>
        <Typography variant='formText'>{name}</Typography>
      </TableCell>
      <TableCell style={tableBodyStyle(fontColor, undefined, true, 105)}>
        {score || 0}
      </TableCell>
    </TableRow>
  )
}

const border = `1px solid ${colors.Grey100}`

export const BehaviourQuestionTable = ({ children }) => {
  const { t } = useTranslation()
  const tableHeaderStyle = {
    fontSize: 9,
    fontWeight: 500,
    lineHeight: '140%',
    color: colors.Grey400,
    textTransform: 'uppercase'
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ border: border }}>
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderStyle}>
                {t('assessmentBehaviour.questions')}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'center',
                  borderLeft: border,
                  ...tableHeaderStyle
                }}>
                {t('fullReport.ratings')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const colorScale = {
  1: colors.Salomie,
  2: colors.Deco,
  3: colors.PineGlade,
  4: colors.DeYork,
  5: colors.Fern
}

export const BehaviourQuestionTableRow = ({
  index,
  question,
  selfScore,
  othersScore
}) => {
  const { t } = useTranslation()

  const cellStyle = {
    padding: 8,
    border: border,
    height: '100%',
    textAlign: 'center'
  }

  const questionCellStyle = {
    width: '200px',
    height: '100%',
    verticalAlign: 'top',
    backgroundColor: colors.LightGrey100,
    textAlign: 'left'
  }

  const questonTypoSx = {
    fontSize: 10,
    fontStyle: 'italic',
    height: '100%'
  }

  const ScoreCell = ({ label, score }) => {
    const scorePercentage = (score / 5) * 100
    const barColor = colorScale[Math.ceil(score)]
    return (
      <Grid container spacing={0}>
        <Grid
          item
          xs={2}
          style={{ ...cellStyle, textAlign: 'right', paddingTop: 12 }}>
          <Typography variant='formText' fontSize={11}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={8} style={cellStyle}>
          {score > 0 && (
            <AppUsageProgressBar
              progress={scorePercentage}
              primary={barColor}
              secondary={colors.Transparent}
              height={'100%'}
              borderRadius={0}
            />
          )}
        </Grid>
        <Grid item xs={2} style={{ ...cellStyle, paddingTop: 12 }}>
          <Typography variant='formText' fontSize={11}>
            {score?.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <TableRow key={index}>
      <TableCell style={{ ...cellStyle, ...questionCellStyle }}>
        <Typography variant='formText' sx={questonTypoSx} component={'p'}>
          {question}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          display: 'grid',
          padding: 0,
          minHeight: '100px'
        }}>
        <ScoreCell label={t('fullReport.selfView')} score={selfScore} />
        <ScoreCell label={t('fullReport.others')} score={othersScore} />
      </TableCell>
    </TableRow>
  )
}

export const ColorScaleBox = () => {
  const { t } = useTranslation()
  const colorBoxSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32
  }
  return (
    <RowStack justifyContent={'flex-end'} spacing={0}>
      <Box marginRight={4.5} width={42}>
        <Typography variant='body1Nano' textAlign={'right'} component={'p'}>
          {t('stepFeedback.stronglyDisagree')}
        </Typography>
      </Box>
      {Object.keys(colorScale).map(key => (
        <Box key={key} sx={colorBoxSx} backgroundColor={colorScale[key]}>
          <Typography variant='body1Nano' fontSize={11}>
            {key}
          </Typography>
        </Box>
      ))}
      <Box marginLeft={4.5} width={42}>
        <Typography variant='body1Nano' component={'p'}>
          {t('stepFeedback.stronglyAgree')}
        </Typography>
      </Box>
    </RowStack>
  )
}
