import { CardContent, TableCell } from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import { useEffect, useState } from 'react'
import { PULSE_SURVEY_NEW } from 'routes/constants'
import { PULSE_SURVEYS_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText, PageHeader } from 'components/atoms/Text'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import PulseSurveyRow from 'components/molecules/PulseSurveyRow'
import InCardStack from 'components/atoms/InCardStack'
import { useTranslation } from 'react-i18next'

const PulseSurveyList = () => {
  const { t } = useTranslation()
  const [requestError, setRequestError] = useState(undefined)
  const [surveys, setSurveys] = useState([])

  const fetchData = async () => {
    const res = await getRequest(PULSE_SURVEYS_URL)
    if (res.status === 200) {
      setSurveys(res.data.data)
      setRequestError(false)
    } else {
      setRequestError(true)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <InCardStack direction='row'>
        <PageHeader>{t('pulseSurvey.myPulseSurveys')}</PageHeader>
        <LinkButton buttonProps={{sx: {paddingY: 1, paddingX: 10}}} to={PULSE_SURVEY_NEW}>{t('pulseSurvey.sendOutPS')}</LinkButton>
      </InCardStack>
      {surveys.length > 0 ? (
        <PresetTableContainer
          headers={
            <>
              <TableCell>{t('pulseSurvey.question')}</TableCell>
              <TableCell sx={{minWidth: 180}}>{t('pulseSurvey.status')}</TableCell>
              <TableCell width={130}>{t('pulseSurvey.sentSendingOn')}</TableCell>
              <TableCell width={130}>{t('pulseSurvey.closed')}</TableCell>
              <TableCell>{t('pulseSurvey.averageRating')}</TableCell>
              <TableCell width={130}>{t('pulseSurvey.raters')}</TableCell>
              <TableCell />
            </>
          }>
          {surveys.map(survey => (
            <PulseSurveyRow key={survey.id} surveyData={survey} />
          ))}
        </PresetTableContainer>
      ) : (
        <CardContent>
          <NotificationBodyText>
            {t('pulseSurvey.sendSingleQuestion')}
          </NotificationBodyText>
        </CardContent>
      )}
      {requestError === true ? (
        <CardContent>
          <RequestRetryAlert />
        </CardContent>
      ) : null}
    </>
  )
}

export default PulseSurveyList
