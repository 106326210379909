import { PageHeader } from 'components/atoms/Text'
import NotebookCard from 'components/organisms/NotebookCard'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { useTranslation } from 'react-i18next'

const Notebook = () => {
  const { t } = useTranslation()
  return (
    <HomePage>
      <PageHeader>{t('notebook.myNotebook')}</PageHeader>
      <NotebookCard />
    </HomePage>
  )
}

export default Notebook
