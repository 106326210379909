import {
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import BookmarkButton from 'components/atoms/button/BookmarkButton'

const ContentListItem = ({
  selected,
  contentData,
  selectCallback,
  bookmarked
}) => {
  const theme = useTheme()
  const regex = /(<([^>]+)>)/gi
  const cleanText =
    contentData.contentWeb?.replace(regex, '') || contentData.summary
  const contentWithoutHtml =
    cleanText?.length > 80 ? cleanText.substring(0, 80) + '...' : cleanText
  const containerProps = {
    container: true,
    width: '100%',
    borderRadius: 5,
    padding: 8,
    border: 'solid'
  }
  const TextContainer = ({ children }) => (
    <Grid item xs={10.8} display='flex' alignItems='center'>
      <Stack padding={0}>{children}</Stack>
    </Grid>
  )
  return (
    <>
      <Grid
        {...containerProps}
        sx={{
          backgroundColor: theme.palette.background.default,
          borderColor: selected
            ? theme.palette.primary.main
            : theme.palette.grey[400],
          borderWidth: selected ? '3px' : '2px'
        }}
        onClick={selectCallback}
        data-testid={`content-list-item-${contentData.id}`}>
        <TextContainer>
          <Typography variant='subtitle1'>{contentData.title}</Typography>
          <Typography
            paddingRight='50px'
            maxHeight='120px'
            overflow='hidden'
            dangerouslySetInnerHTML={{ __html: contentWithoutHtml }}
          />
        </TextContainer>
        <Grid item xs={1.2}>
          <BookmarkButton
            bookmarked={bookmarked}
            stepContentId={contentData.id}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ContentListItem
