import { PageHeader } from 'components/atoms/Text'
import SearchResultCard from 'components/organisms/SearchResultCard'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SearchResults = () => {
  const { t } = useTranslation()
  const params = useParams()
  const [resultCount, setResultCount] = useState(0)
  const resultCallback = count => {
    setResultCount(count)
  }
  return (
    <HomePage>
      <PageHeader>{t('searchArea.searchResults')} ({resultCount})</PageHeader>
      <SearchResultCard
        searchText={params.searchText}
        resultCallback={resultCallback}
      />
    </HomePage>
  )
}

export default SearchResults
