import { Alert, Box, Container } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import { PageHeader } from 'components/atoms/Text'
import { useTranslation } from 'react-i18next'

const RequestErrorPageCard = ({
  title,
  text,
  children
}) => {
  const { t } = useTranslation()
  return (
    <Box width='100%'>
      <Container maxWidth='sm'>
        <InCardStack>
          <PageHeader>{title ? title : t('requestErrorPageCard.title')}</PageHeader>
          <Alert severity='error'>{text ? text : t('requestErrorPageCard.text')}</Alert>
          {children}
        </InCardStack>
      </Container>
    </Box>
  )
}

export default RequestErrorPageCard
