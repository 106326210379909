import {
  TableBody,
  Table,
  TableRow,
  TableCell,
  Button,
  Typography
} from '@mui/material'
import DateCell from 'components/atoms/table/DateCell'
import SearchInputBox from 'components/atoms/input/SearchInputBox'
import { useEffect, useState } from 'react'
import PopoverCell from 'components/atoms/table/PopoverCell'
import SortableHeaderCell from 'components/atoms/table/SortableHeader'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import AvatarHeaderCell from 'components/atoms/table/AvartarHeaderCell'
import InCardStack from 'components/atoms/InCardStack'
import { isMatchingUser } from 'utils/userSearchHelper'
import CsvDownloader from 'react-csv-downloader'
import { useTranslation } from 'react-i18next'
import { requestSort } from 'utils/collectionHelper'
import colors from 'styles/colors'

const exportColumns = [
  'id',
  'email',
  'firstName',
  'lastName',
  'completedGoalsCount',
  'goalName',
  'currentStepName',
  'currentGoalComplete',
  'currentGoal360Sent',
  'currentGoal360Completed',
  'lastSeen'
  // 'activityScoreTotal'
]

const UserStatsTable = ({
  data,
  showLicensedBy,
  showUsersAtRisk,
  openUserDetailsTab,
  title = ''
}) => {
  const { t } = useTranslation()
  const [filterText, setFilterText] = useState('')
  const [currentSort, setCurrentSort] = useState(undefined)
  const [sortOrder, setSortOrder] = useState('asc')
  const [displayData, setDisplayData] = useState(data)
  const sortableColumns = [
    { label: t('overview.currentPath'), columnName: 'goalName' },
    { label: t('overview.currentStep'), columnName: 'currentStepName' },
    {
      label: t('overview.percentCompleted'),
      columnName: 'percentageCompleteCurrentGoal'
    },
    {
      label: t('overview.detailList._360Sent'),
      columnName: 'currentGoal360Sent'
    },
    {
      label: t('overview._360Responses'),
      columnName: 'currentGoal360Completed'
    },
    { label: t('overview.lastSeen'), columnName: 'lastSeen' }
    // DEPRECATED for now due to client misunderstanding about make up of field
    // { label: t('overview.leaderHealthScore'), columnName: 'activityScoreTotal' }
  ]

  const handleRequestSort = (pSortBy, clickAction = true) => {
    requestSort(
      data,
      currentSort,
      sortOrder,
      pSortBy,
      setCurrentSort,
      setSortOrder,
      setDisplayData,
      clickAction
    )
  }

  useEffect(() => {
    if (currentSort) {
      handleRequestSort(currentSort, false)
    } else {
      setDisplayData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const getAtRiskStyle = atRisk =>
    showUsersAtRisk && atRisk ? { color: colors.Cinnabar } : {}

  const denotesAtRiskStyle = {
    color: colors.Cinnabar,
    padding: '5px',
    borderBottom: `1px solid ${colors.Gainsboro}`
  }

  const handleOnClickUserAvatar = user => {
    if (openUserDetailsTab) {
      openUserDetailsTab(user)
    }
  }
  return (
    <>
      <InCardStack
        direction='row'
        sx={{ width: '100%', justifyContent: 'space-between' }}>
        <InCardStack direction='row'>
          {title && (
            <Typography variant='h3' width='fit-content' paddingLeft='12px'>
              {title}
            </Typography>
          )}
          <SearchInputBox
            onChangeHandler={e => setFilterText(e.target.value)}
            placeholder={t('overview.searchForUser')}
            style={{
              paddingBottom: '0'
            }}
          />
        </InCardStack>

        <InCardStack direction='row'>
          {showUsersAtRisk && (
            <Typography variant='body1' sx={denotesAtRiskStyle}>
              {`*${t('overview.denotesAtRiskUsers')}`}
            </Typography>
          )}

          <CsvDownloader
            columns={exportColumns.map(c => ({ id: c, displayName: c }))}
            datas={data}
            separator=';'
            extension='.csv'
            filename='user_stats'>
            <Button>{t('overview.export')}</Button>
          </CsvDownloader>
        </InCardStack>
      </InCardStack>
      <PresetTableContainer
        headers={
          <>
            <TableCell>{t('overview.user')}</TableCell>
            <TableCell>{t('overview.completedPaths')}</TableCell>
            {sortableColumns.map(({ label, columnName }) => {
              return (
                <SortableHeaderCell
                  key={columnName}
                  currentSort={currentSort}
                  sortOrder={sortOrder}
                  requestSort={handleRequestSort}
                  attributeName={columnName}>
                  {label}
                </SortableHeaderCell>
              )
            })}
            {showLicensedBy ? (
              <TableCell>{t('overview.licensedBy')}</TableCell>
            ) : null}
          </>
        }>
        {displayData
          .filter(user => isMatchingUser(user, filterText))
          .map(user => {
            const atRiskStyle = getAtRiskStyle(user.atRisk)
            return (
              <TableRow key={user.id}>
                <AvatarHeaderCell
                  user={user}
                  onClick={() => handleOnClickUserAvatar(user)}
                  clickable={openUserDetailsTab}
                />
                {user.completedGoals?.length ? (
                  <PopoverCell
                    id={`${user.email}-completed-goals`}
                    cellValue={user.completedGoals.length}
                    PopoverContent={() => {
                      return (
                        <Table>
                          <TableBody>
                            {user.completedGoalsNames.map(d => {
                              return (
                                <TableRow key={d}>
                                  <TableCell>{d}</TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      )
                    }}
                  />
                ) : (
                  <TableCell />
                )}
                <TableCell style={atRiskStyle}>{user.goalName}</TableCell>
                <TableCell style={atRiskStyle}>
                  {user.currentStepName}
                </TableCell>
                <TableCell style={atRiskStyle}>
                  {user.currentGoalComplete}
                </TableCell>
                <TableCell style={atRiskStyle}>
                  {user.currentGoal360Sent}
                </TableCell>
                <TableCell style={atRiskStyle}>
                  {user.currentGoal360Completed}
                </TableCell>
                <DateCell value={user.lastSeen} style={atRiskStyle} />
                {/* <TableCell>{user.activityScoreTotal}</TableCell> */}
                {/* {user.activityScoreTotal ? (
                  <PopoverCell
                    id={`${user.email}-activity-score`}
                    cellValue={user.activityScoreTotal}
                    PopoverContent={() => {
                      return (
                        <Table>
                          <TableBody>
                            {user.engagementDetail.map(d => {
                              return (
                                <TableRow key={d.name}>
                                  <TableCell>{d.name}</TableCell>
                                  <TableCell>{d.score}</TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      )
                    }}
                  />
                ) : (
                  <TableCell />
                )} */}
                {showLicensedBy ? (
                  <TableCell>{user.licensedOrganizations[0]}</TableCell>
                ) : null}
              </TableRow>
            )
          })}
      </PresetTableContainer>
    </>
  )
}

export default UserStatsTable
