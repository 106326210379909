import {
  Alert,
  Button,
  CardContent,
  Container,
  Stack,
  Typography
} from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import OverlayLoading from 'components/atoms/OverlayLoading'
import SurveyQuestionPageContainer from 'components/molecules/ohepSurvey/SurveyQuestionPage'
import moment from 'moment'
import { useState } from 'react'
import { OHEP_RESPONSES_URL } from 'services/constants'
import { postRequest } from 'utils/api'

const SurveyRespondingCard = ({ surveyConfig, completeCallback, token }) => {
  const { title, summary, demographicQuestions, EVALUATION_OPTIONS } =
    surveyConfig
  const otherQuestionPages = surveyConfig.questions?.pages
  const totalPages = otherQuestionPages?.length + 1
  const [started, setStarted] = useState(false)
  const [activePage, setActivePage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState(undefined)
  const [response, setResponse] = useState({
    surveyQuestionAnswers: Array(otherQuestionPages?.length).fill({}),
    demographicAnswers: {}
  })

  const updateResponse = (pageIndex, data, navigateIndex) => {
    const res = { ...response }
    if (pageIndex === 0) {
      res.demographicAnswers = data
    } else {
      res.surveyQuestionAnswers[pageIndex - 1] = data
    }
    setResponse(res)
    if (navigateIndex === totalPages) {
      submitData(res)
    } else {
      setActivePage(navigateIndex)
    }
  }
  const submitData = async responseData => {
    setLoading(true)
    let surveyQuestionAnswers = {}
    responseData.surveyQuestionAnswers.forEach(o =>
      Object.assign(surveyQuestionAnswers, o)
    )
    const requestData = {
      demographicAnswers: responseData.demographicAnswers,
      completedAt: moment.utc(),
      startedAt: responseData.startedAt,
      surveyQuestionAnswers,
      token
    }
    const res = await postRequest(OHEP_RESPONSES_URL, requestData)
    if (res.status === 201) {
      completeCallback(true)
    } else if (res.status === 422) {
      setRequestError(true)
    }

    setLoading(false)
  }

  const handleStartSurvey = () => {
    setResponse({ ...response, startedAt: moment.utc() })
    setStarted(true)
  }
  return (
    <Container maxWidth='md'>
      <OverlayLoading open={loading} />
      {started ? (
        activePage === 0 ? (
          <SurveyQuestionPageContainer
            activePage={activePage}
            totalPages={totalPages}
            pageContent={demographicQuestions}
            isDemographic={true}
            initialAnswers={response.demographicAnswers}
            submitAnswers={(data, i) => updateResponse(0, data, i)}
          />
        ) : (
          <SurveyQuestionPageContainer
            activePage={activePage}
            totalPages={totalPages}
            scoreOptions={EVALUATION_OPTIONS}
            pageContent={otherQuestionPages[activePage - 1]}
            initialAnswers={response.surveyQuestionAnswers[activePage - 1]}
            submitAnswers={(data, navigateIndex) =>
              updateResponse(activePage, data, navigateIndex)
            }
          />
        )
      ) : (
        <Stack marginTop={30}>
          <CardContent>
            <Typography variant='h3'>{title}</Typography>
          </CardContent>
          <CardContent>
            <InCardStack>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {summary}
              </Typography>
              <Typography fontWeight='bold'>
                Lastly, unlike past surveys, your progress will NOT be saved
                along the way. You will need to complete this survey in one
                sitting and get to the end page to have your answers submitted.
              </Typography>
            </InCardStack>
          </CardContent>
          <CardContent>
            <Button onClick={() => handleStartSurvey()}>Begin Survey</Button>
          </CardContent>
        </Stack>
      )}
      {requestError ? (
        <Alert severity='error'>
          Failed to submit response. If this issue persist, please contant
          Monark support.
        </Alert>
      ) : null}
    </Container>
  )
}

export default SurveyRespondingCard
