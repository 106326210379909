import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import PulseSurveyDetailCard from 'components/organisms/pulsSurveys/PulsSurveyDetailCard'
import { PULSE_SURVEYS_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import SinglePageCard from 'components/templates/SinglePageCard'
import { CardContent, Typography } from '@mui/material'
import { PULSE_SURVEYS } from 'routes/constants'
import LinkButton from 'components/atoms/button/LinkButton'
import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import { pulseSurveyName } from 'config/config'

const PulseSurveyDetail = () => {
  const location = useLocation()
  const params = useParams()
  const [surveyData, setSurveyData] = useState(
    location.state?.surveyData || undefined
  )
  const [requestError, setRequestError] = useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(`${PULSE_SURVEYS_URL}/${params.id}`)
      if (res.status === 200) {
        const surveys = res.data.data

        if (surveys.length === 0) {
          setRequestError(true)
        } else {
          setSurveyData(surveys[0])
        }
      } else {
        setRequestError(true)
      }
    }
    if (!surveyData) {
      fetchData()
    }
  })

  return (
    <FeedbackPage>
      {requestError ? (
        <SinglePageCard title='Not Found'>
          <Typography variant='body1'>
            Sorry, we cannot find the {pulseSurveyName.lowerCase} at the moment.
            Are you looking for a different survey?
          </Typography>
          <CardContent>
            <LinkButton to={PULSE_SURVEYS}>Back to {pulseSurveyName.camelCase}s</LinkButton>
          </CardContent>
        </SinglePageCard>
      ) : surveyData ? (
        <PulseSurveyDetailCard surveyData={surveyData} />
      ) : null}
    </FeedbackPage>
  )
}

export default PulseSurveyDetail
