export const capitalizeFirstLetter = input => {
  const arr = input.split(' ')
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  return arr.join(' ')
}

export const getNameIntials = user => {
  const [firstName, lastName, email] = desctructUser(user)
  return (
    firstName
      ? firstName[0] + (lastName ? lastName[0] : '')
      : email
        ? email[0]
        : ''
  ).toUpperCase()
}

export const getFullName = (user, nameWithEmail = false) => {
  const [firstName, lastName, email] = desctructUser(user)
  return firstName
    ? `${firstName}` +
        (lastName ? ` ${lastName}` : '') +
        (nameWithEmail ? ` (${email})` : '')
    : email
}

export const desctructUser = user => {
  // APIs are not fully consistent, sometimes we get "first_name", other times we get "firstName"
  // We can remove the additional logic here once the APIs are consistent
  const firstName = user?.first_name || user?.firstName
  const lastName = user?.last_name || user?.lastName
  return [firstName, lastName, user?.email]
}

export const getSizeString = n => `${n}px`

export const getDisplayScore = (oldScore, newScore) => {
  const displayData = {
    oldScore: oldScore?.toFixed(1) || null,
    newScore: newScore?.toFixed(1) || null
  }
  if (oldScore && newScore) {
    const changeScore = displayData.newScore - displayData.oldScore
    const changePercent = (changeScore / oldScore) * 100
    displayData.changePercent = changePercent.toFixed(0)
    displayData.changeScore = changeScore.toFixed(1)
  }

  return displayData
}

export const getPercentageDisplay = (value, fractionDigits) =>
  value || value === 0
    ? Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
      }).format(value)
    : null

export const padNumber = (number, size = 2) => {
  const s = String(number)
  return s.padStart(size, '0')
}

export const getStringAbbreviation = value => {
  if (!value) return ''
  const arr = value.split(' ')
  return arr.reduce((acc, val) => acc + val.charAt(0), '')
}
