import React, { useEffect, useState } from 'react'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import {
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { getUpdateLocalUser } from 'utils/localStorage'
import LinkButton from 'components/atoms/button/LinkButton'
import { EDIT_PROFILE, PERMISSION_LEVEL } from 'routes/constants'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import {
  getGoalNameById,
  getLabelFromConstatnt,
  getLabelFromOnboardingAnswer
} from 'utils/getSupportingInfo'
import { DepartmentIcon, IndustryIcon, JobIcon } from 'components/atoms/Icons'
import UserLearningPaths from 'components/organisms/home/UserLearningPaths'
import { getFullName } from 'utils/formatText'
import { isAllowedFeature, permitted } from 'utils/permissionCheck'
import { useNavigate } from 'react-router-dom'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useTranslation } from 'react-i18next'
import { CONSTANTS_OPTIONS, FEATURE_KEYS } from 'services/constants'

const UserProfile = () => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [user, setUser] = useState({})
  const [goalName, setGoalName] = useState('')
  const [hasLicense, setHasLicense] = useState(false)
  const [displaySeniority, setDisplaySeniority] = useState(null)
  const [displayExpeirence, setDisplayExpeirence] = useState(null)
  const [displayRole, setDisplayRole] = useState(null)
  const [displayDepartment, setDisplayDepartment] = useState(null)
  const [displayIndustry, setDisplayIndustry] = useState(null)
  const [loading, setLoading] = useState(false)

  const showPath = hasLicense && !isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const updatedUser = await getUpdateLocalUser()
      const { userStates } = updatedUser
      setUser(updatedUser)
      if (userStates.currentGoalId) {
        const currentGoalName = await getGoalNameById(userStates.currentGoalId)
        setGoalName(currentGoalName)
      }
      if (updatedUser.onboardingAnswers) {
        const { onboardingAnswers: answers } = updatedUser
        const seniorityLabel = await getLabelFromOnboardingAnswer(0, answers)
        setDisplaySeniority(seniorityLabel)
        const experienceLabel = await getLabelFromOnboardingAnswer(1, answers)
        setDisplayExpeirence(experienceLabel)
      }

      await Promise.all([
        (async () => {
          const role = await getLabelFromConstatnt(
            CONSTANTS_OPTIONS.JOB_OPTIONS,
            updatedUser.job
          )
          setDisplayRole(role)
        })(),
        (async () => {
          const dept = await getLabelFromConstatnt(
            CONSTANTS_OPTIONS.DEPARTMENT_OPTIONS,
            updatedUser.department
          )
          setDisplayDepartment(dept)
        })(),
        (async () => {
          const industry = await getLabelFromConstatnt(
            CONSTANTS_OPTIONS.INDUSTRY_OPTIONS,
            updatedUser.organization?.industry
          )
          setDisplayIndustry(industry)
        })()
      ])

      setHasLicense(permitted(PERMISSION_LEVEL.LICENSED))
      setLoading(false)
    }
    fetchData()
  }, [])

  const InfoCard = ({ titleText, icon, value }) => (
    <Grid item xs={4}>
      <CardHeader
        avatar={icon}
        title={<Typography variant='h5'>{titleText}</Typography>}
        subheader={<Typography>{value}</Typography>}
      />
    </Grid>
  )
  const SeniorityTitle = ({ children, hasValue }) => (
    <Typography variant='h5'>
      {children}
      {hasValue ? null : (
        <IconButton
          color='primary'
          title='edit'
          onClick={() => navigation(EDIT_PROFILE)}>
          <EditIcon fontSize='small' />
        </IconButton>
      )}
    </Typography>
  )

  return (
    <HomePage>
      <Container maxWidth='md'>
        <Stack width='100%'>
          <Grid container width='100%'>
            <Grid item xs={9}>
              <CardHeader
                avatar={<ProfileAvatar user={user} size='large' />}
                subheader={user.email}
                title={
                  <Typography variant='h3'>
                    {getFullName(user)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={3} paddingRight='16px'>
              <LinkButton to={EDIT_PROFILE}>
                {!displayExpeirence && !displayExpeirence
                  ? t('profile.finishProfile')
                  : t('profile.editProfile')}
              </LinkButton>
            </Grid>
          </Grid>
          {user?.userStates?.currentGoalId && showPath ? (
            <CardContent>
              <BoxWithBackground width='100%' paddingX={10} paddingY={5}>
                <Typography>{t('pathsAndModels.currentPath')}</Typography>
                <Typography color='primary' fontWeight='bold'>
                  {goalName}
                </Typography>
              </BoxWithBackground>
            </CardContent>
          ) : null}
          <Grid paddingLeft={5} container width='100%'>
            <InfoCard
              icon={<JobIcon style={{ fontSize: 'xxx-large' }} />}
              titleText={t('profile.role')}
              value={displayRole}
            />
            <InfoCard
              icon={<IndustryIcon style={{ fontSize: 'xxx-large' }} />}
              titleText={t('profile.industry')}
              value={displayIndustry}
            />
            <InfoCard
              icon={<DepartmentIcon style={{ fontSize: 'xxx-large' }} />}
              titleText={t('profile.department')}
              value={displayDepartment}
            />
          </Grid>
          <Grid container width='100%'>
            <Grid item xs={6} minWidth='300px'>
              <Stack>
                <SeniorityTitle hasValue={displaySeniority}>
                  {t('profile.currentLevelOfSeniority')}
                </SeniorityTitle>
                <Typography>{displaySeniority}</Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              display='flex'
              justifyContent='right'
              minWidth='300px'>
              <Stack minWidth='300px'>
                <SeniorityTitle hasValue={displayExpeirence}>
                  {t('profile.workExperience')}
                </SeniorityTitle>
                <Typography>{displayExpeirence}</Typography>
              </Stack>
            </Grid>
          </Grid>
          {showPath ? (
            <UserLearningPaths userStates={user.userStates} />
          ) : null}
        </Stack>
      </Container>
      <OverlayLoading open={loading} />
    </HomePage>
  )
}

export default UserProfile
