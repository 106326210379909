import {
  Select,
  useTheme,
  Typography,
  alpha,
  MenuItem,
  Checkbox,
  TextField
} from '@mui/material'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

export const StyledSelect = ({ sx, children, name, ...otherProps }) => {
  const theme = useTheme()
  return (
    <Select
      {...otherProps}
      sx={[
        {
          minWidth: 0,
          borderRadius: 20,
          backgroundColor: theme.palette.background.paper,
          '& .MuiSvgIcon-root': { marginRight: 5 }
        },
        sx
      ]}
      inputProps={{ sx: { paddingY: 5, paddingX: 10 } }}
      fullWidth={false}
      data-testid={`${name}-select`}>
      {children}
    </Select>
  )
}

export const MultiCheckBoxSelect = ({
  options,
  selectedOptions,
  onSelection
}) => {
  const { t } = useTranslation()

  const FilterMenuProps = {
    PaperProps: {
      style: {
        padding: 12,
        borderRadius: 20,
        marginTop: 5
      },
      sx: {
        '& .MuiList-root': {
          padding: 0
        }
      }
    }
  }

  const FilterMenuItemSx = {
    padding: 0,
    paddingRight: 5,
    overflow: 'hidden',
    '&.Mui-selected': {
      backgroundColor: colors.White
    },
    '&:hover': {
      backgroundColor: alpha(colors.Teal400, 0.1)
    }
  }

  return (
    <StyledSelect
      sx={{
        minWidth: 200,
        paddingY: '3.5px'
      }}
      displayEmpty
      placeholder={t('fullReport.filter')}
      renderValue={selected => {
        return (
          <Typography
            variant='body2'
            sx={{
              color: colors.DarkGrey,
              fontWeight: 500,
              lineHeight: '22px'
            }}>
            {t('fullReport.filter')}{' '}
            {selected.length > 0 && `(${selected.length})`}
          </Typography>
        )
      }}
      value={selectedOptions}
      multiple
      onChange={e => onSelection(e.target.value)}
      MenuProps={FilterMenuProps}>
      {options.map((filter, index) => (
        <MenuItem
          value={filter.value}
          key={`filter-${index}`}
          sx={FilterMenuItemSx}>
          <Checkbox checked={selectedOptions.indexOf(filter.value) > -1} />
          {filter.label}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

export const TextBox = ({ sx, removeBorder = true, ...otherProps }) => {
  return (
    <TextField
      multiline
      variant='outlined'
      sx={[
        {
          '& .MuiInputBase-input.Mui-disabled': {
            color: colors.DarkGrey, // Webkit
            WebkitTextFillColor: colors.DarkGrey // Blink
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: colors.Transparent,
            color: colors.DarkGrey,
            // Class for the border around the input field
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: removeBorder ? 0 : undefined
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: removeBorder ? 0 : undefined
              }
            },
            '&.Mui-disabled': {
              color: 'red',
              WebkitTextFillColor: 'red'
            }
          },
          width: '100%'
        },
        sx
      ]}
      {...otherProps}
    />
  )
}
