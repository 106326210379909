import SinglePageCard from './SinglePageCard'

const SingleWhitePageCard = ({ children, title, mainBodyWidth = '80%' }) => {
  return (
    <SinglePageCard noBorder={true} mainBodyWidth={mainBodyWidth} title={title}>
      {children}
    </SinglePageCard>
  )
}

export default SingleWhitePageCard
