import React, { useEffect, useState } from 'react'
import { Button, CardContent, Icon, Typography } from '@mui/material'
import { deleteRequest } from 'utils/api'
import {
  CALENDAR_OAUTH_TOKEN_PROVIDERS,
  CALENDER_OAUTH_TOKEN_URL
} from 'services/constants'
import LinkCalendarButton from 'components/molecules/oAuth/LinkCalendarButton'
import OverlayLoading from 'components/atoms/OverlayLoading'
import SuccessfulNotificationDialog from 'components/molecules/notificationOverlay/SuccessfulNotificationDialog'
import ImageContainer from 'components/atoms/container/ImageContainer'
import { getThirdPartyOauth } from 'services/userServices'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'services/amplitudeTracking'

const CalendarSettings = () => {
  const { t } = useTranslation()
  const [linked, setLinked] = useState(null)
  const [tokenExpired, setTokenExpired] = useState(false)
  const [tokenUnauthorized, setTokenUnauthorized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [provider, setProvider] = useState(null)
  const [email, setEmail] = useState('')

  const fetchData = async () => {
    const res = await getThirdPartyOauth()
    const resData = res?.data?.data

    setLinked(res.status === 200)
    setTokenUnauthorized(res.status === 422)
    setTokenExpired(resData?.status === 'invalid')
    setProvider(resData?.provider ?? null)
    setEmail(resData?.email ?? '')
    setLoading(false)
  }

  const signinCallback = sucess => {
    setLinked(sucess)
    if (sucess) {
      setLoading(true)
      fetchData()
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [])

  const handleDisconnect = async () => {
    setLoading(true)
    const res = await deleteRequest(CALENDER_OAUTH_TOKEN_URL)
    if (res.status === 204) {
      setLinked(false)
      setEmail(null)
      setSuccessDialogOpen(true)
      trackEvent('Disconnect Calendar')
    }
    setLoading(false)
  }
  return (
    <>
      <Typography variant='h3'>{t('calenderEvents.calendar')}</Typography>
      {email ? (
        <>
          <Typography>{t('calenderEvents.connectedCalendar')}:</Typography>
          <Typography display='flex' alignItems='center' component='div'>
            <Icon sx={{ marginRight: '10px' }}>
              {provider === CALENDAR_OAUTH_TOKEN_PROVIDERS.MICROSOFT ? (
                <ImageContainer
                  src='https://cdn-icons-png.flaticon.com/64/5969/5969144.png'
                  alt='microsoft'
                />
              ) : (
                <ImageContainer
                  src='https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA'
                  alt='google'
                />
              )}
            </Icon>
            {email}
          </Typography>
        </>
      ) : null}
      {linked === false ? (
        <>
          <Typography variant='body2'>
            {tokenExpired
              ? tokenUnauthorized
                ? t('calenderEvents.unableToConnectCalendarTryAgain')
                : t('calenderEvents.connectionExpired')
              : t('calenderEvents.linkingYourCalendar')}
          </Typography>
          <CardContent>
            <LinkCalendarButton signinCallback={signinCallback} />
          </CardContent>
        </>
      ) : linked === true ? (
        <>
          <Typography variant='body2'>
            {t('calenderEvents.IiDisconnectCalendar')}
          </Typography>
          <CardContent>
            <Button onClick={() => handleDisconnect()}>
              {t('calenderEvents.disconnectCalendar')}
            </Button>
          </CardContent>
        </>
      ) : null}
      <OverlayLoading open={loading} />
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={t('calenderEvents.requestDone')}
        notificationText={t('calenderEvents.youDisconnectedCalendar')}
        buttonAction={() => setSuccessDialogOpen(false)}
        confirmButtonText={t('common.done')}
      />
    </>
  )
}

export default CalendarSettings
