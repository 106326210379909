import { Grid, Stack } from '@mui/material'
import FixHeightScrollBox from 'components/atoms/container/FixHeightScrollBox'

export const ColumnItem = ({ children, md }) => (
  <Grid item md={md} height='inherit' paddingTop={6}>
    <FixHeightScrollBox>
      <Stack padding={0} spacing={12} paddingRight={2}>
        {children}
      </Stack>
    </FixHeightScrollBox>
  </Grid>
)
