const { Box, useTheme } = require('@mui/material')

const RoundContainer = ({ backgroundColor, size = 28, children }) => {
  const theme = useTheme()
  return (
    <Box
      borderRadius='50%'
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{
        backgroundColor: backgroundColor || theme.palette.primary.main,
        width: size,
        height: size
      }}>
      {children}
    </Box>
  )
}

export default RoundContainer
