import { Grid, Stack, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'

const UserManagementContainer = ({
  titleText,
  description,
  leftPanelComponent,
  children,
  requestError
}) => {
  return (
    <Grid container spacing={5} marginLeft={0}>
      <Grid item xs={3}>
        <Stack padding={0} spacing={10}>
          <Typography variant='h5' fontWeight={800}>{titleText}</Typography>
          {description ? (
            <Typography variant='body2'>{description}</Typography>
          ) : null}
          {leftPanelComponent}
        </Stack>
      </Grid>
      <Grid item xs={9} minWidth='300px'>
        <InCardStack>
          {requestError ? <RequestRetryAlert /> : children}
        </InCardStack>
      </Grid>
    </Grid>
  )
}

export default UserManagementContainer
