import { Container, Stack, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import OverlayLoading from 'components/atoms/OverlayLoading'
import ConfirmCancelDialog from 'components/molecules/notificationOverlay/ConfirmCancelDialog'
// import CalendarEvents from 'components/organisms/home/CalenderEvents'
import CurrentGoalRating from 'components/organisms/home/CurrentGoalRating'
import FeedbackArea from 'components/organisms/home/FeedbackArea'
import GoalsArea from 'components/organisms/home/GoalsArea'
import WelcomeArea from 'components/organisms/home/WelcomeArea'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { JOURNEY, ONBOARDING, PERMISSION_LEVEL } from 'routes/constants'
import { updateStates } from 'services/userServices'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import { getUpdateLocalUser } from 'utils/localStorage'
import { isAllowedFeature, permitted } from 'utils/permissionCheck'
import { useTranslation } from 'react-i18next'
import { FEATURE_KEYS } from 'services/constants'
import FullAssessmentHome from 'components/organisms/assessments/fullAssessment/FullAssessmentHome'

const Home = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [user, setUser] = useState(undefined)
  const [hasLicense, setHasLicense] = useState(false)
  const [fullAssessmentUser, setFullAssessmentUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const [startingGoal, setStartingGoal] = useState({})
  const [startingGoalDialogOpen, setStartingGoalDialogOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const updatedUser = await getUpdateLocalUser()
      const fullAssessmentPermitted = isAllowedFeature(
        FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT
      )
      setFullAssessmentUser(fullAssessmentPermitted)
      if (!fullAssessmentPermitted) {
        if (
          updatedUser.hasLicense &&
          !updatedUser.onboardingSelfAssessmentComplete
        ) {
          navigate(ONBOARDING)
        } else if (
          updatedUser.hasLicense &&
          updatedUser.userStates.startingGoalId
        ) {
          const goalInfo = await getGoalInfoById(
            updatedUser.userStates.startingGoalId
          )
          setStartingGoal(goalInfo)
          setStartingGoalDialogOpen(true)
          updateStates({ startingGoalId: '' })
        }
      }
      setUser(updatedUser)
      setHasLicense(permitted(PERMISSION_LEVEL.LICENSED))
      setLoading(false)
    }
    setLoading(true)
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HomePage>
      {fullAssessmentUser ? (
        <FullAssessmentHome user={user}/>
      ) : (
        <Container>
          {user ? (
            hasLicense ? (
              <Stack
                width='100%'
                maxWidth='1200px'
                alignSelf='center'
                data-testid='home-page'>
                <WelcomeArea userData={user} />
                <GoalsArea />
                {/*<CalendarEvents userData={user} />*/}
                <FeedbackArea userData={user} />
                <CurrentGoalRating goalId={user.userStates.currentGoalId} />
              </Stack>
            ) : (
              <Stack
                width='100%'
                maxWidth='1200px'
                alignSelf='center'
                data-testid='home-page'>
                <WelcomeArea userData={user} hasLicense={hasLicense} />
                <FeedbackArea userData={user} hasLicense={hasLicense} />
              </Stack>
            )
          ) : null}
          <ConfirmCancelDialog
            titleText={t('home.newLearningPathAssignment')}
            open={startingGoalDialogOpen}
            confirmText={t('home.startMyPath')}
            onClose={confirmed =>
              confirmed ? navigate(JOURNEY) : setStartingGoalDialogOpen(false)
            }>
            <InCardStack>
              <Typography component='div'>
                <Typography>
                  {t('home.companyAssignedYouNewLearningPath')}
                </Typography>
                <Typography fontWeight='bold' textAlign='center'>
                  {startingGoal.name}
                </Typography>
              </Typography>
              <Typography textAlign='center'>
                {t('home.automaticallyTakenToSelfAssessment')}
              </Typography>
              <Typography>{t('home.clickStartToBegin')}</Typography>
            </InCardStack>
          </ConfirmCancelDialog>
          <OverlayLoading open={loading} />
        </Container>
      )}
    </HomePage>
  )
}

export default Home
