import { Checkbox, FormControlLabel } from '@mui/material'

const CheckButton = props => {
  const {
    label,
    isChecked,
    setIsChecked,
    formProps={},
    checkProps={}
  } = props

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={setIsChecked}
          {...checkProps}
        />
      }
      label={label}
      {...formProps}
    />
  )
}

export default CheckButton
