import { Typography, darken, lighten } from '@mui/material'
import ReportPage from 'components/templates/ReportPage'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import {
  BehaviourTable,
  BehaviourTableRow,
  BehaviourQuestionTable,
  BehaviourQuestionTableRow,
  ColorScaleBox
} from './ReportTable'
import { useTranslation } from 'react-i18next'

export const CardStack = props => {
  const { children, ...otherProps } = props
  return (
    <InCardStack width={'100%'} {...otherProps}>
      {children}
    </InCardStack>
  )
}

const BehaviourPage = ({
  downloadingFile,
  categoryData,
  behaviorData,
  behaviorIndex,
  assessmentName,
  pageNum
}) => {
  const { t } = useTranslation()
  const categoryColor = categoryData?.color || colors.Black
  const darkCategoryColor = darken(categoryColor, 0.2) || colors.Black
  const lightCategoryColor = lighten(categoryColor, 0.85) || colors.Black

  return (
    <>
      <ReportPage
        assessmentName={assessmentName}
        downloadingFile={downloadingFile}
        pageNum={pageNum}
        childrenWrapperSx={{ justifyContent: 'space-between' }}>
        <CardStack spacing={12}>
          <Typography variant='h6' color={categoryColor}>
            {categoryData?.modelName}
          </Typography>

          <CardStack spacing={38}>
            <CardStack spacing={8}>
              <BehaviourTable>
                <BehaviourTableRow
                  name={behaviorData?.name}
                  index={behaviorIndex}
                  score={behaviorData?.avgSelfRating?.toFixed(2)}
                  fontColor={darkCategoryColor}
                  backColor={lightCategoryColor}
                />
              </BehaviourTable>

              <Typography
                variant='formText'
                width={480}
                color={colors.MidnightTeal400}>
                {behaviorData?.description}
              </Typography>
            </CardStack>

            <CardStack spacing={4}>
              <Typography variant='formTagText' color={colors.Grey300}>
                {t('fullReport.ratingsByQuestions')}
              </Typography>

              <BehaviourQuestionTable>
                {behaviorData?.questions.map((value, index) => (
                  <BehaviourQuestionTableRow
                    key={`behaviour-${index}`}
                    question={value.selfQuestion}
                    selfScore={value.selfScore}
                    othersScore={value.othersScore}
                  />
                ))}
              </BehaviourQuestionTable>
            </CardStack>
          </CardStack>
        </CardStack>

        <ColorScaleBox />
      </ReportPage>
    </>
  )
}

export default BehaviourPage
