import { io } from 'socket.io-client'
import { getLocalUser } from './localStorage'
import { hostUrl, serverEndpoint } from 'config/config'
import { LIVE_ROLE_PLAY_URL } from 'services/constants'

export const rolePlaysocket = io(`${serverEndpoint}${LIVE_ROLE_PLAY_URL}`, {
  autoConnect: false,
  cors: { origin: hostUrl, methods: ['GET', 'POST'] },
  transports: ['websocket', 'polling'],
  auth: {
    token: getLocalUser()?.token
  },
  path: '/ws'
})
