import { LOGIN_TYPES } from 'services/constants'
import { signin } from 'services/userServices'

import { microsoftAuthConfig } from 'utils/thirdParty'
import { IconButton, Typography } from '@mui/material'
import { ReactComponent as MSLogo } from 'assets/images/outlook.svg'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from 'utils/thirdParty'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorNotificationDialog from 'components/molecules/notificationOverlay/ErrorNotificationDialog'
import colors from 'styles/colors'


let msalInstance
let MicrosoftLogin = () => <></>
if (process.env.NODE_ENV !== 'test') {
  msalInstance = new PublicClientApplication(msalConfig)

  MicrosoftLogin = ({ signinCallback, email, label = null }) => {
    const { t } = useTranslation()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false)

    const handleLogin = loginType => {
      if (loginType === 'popup') {
        msalInstance
          .loginPopup(microsoftAuthConfig.loginScopes)
          .then(async r => {
            const idToken = r.idToken

            // Check if signing up with invited email
            if (email && email !== r.account?.username) {
              setSuccessDialogOpen(true)
              return
            }

            const requestBody = {
              loginToken: idToken,
              login_type: LOGIN_TYPES.MICROSOFT
            }
            const res = await signin(requestBody)
            signinCallback(res)
          })
          .catch(e => {
            console.log(e)
            signinCallback(false)
          })
      }
    }
    return (
      <>
        <MsalProvider instance={msalInstance}>
          <IconButton
            onClick={() => handleLogin('popup')}
            disableRipple
            sx={{
              borderRadius: 2,
              width: '100%',
              border: '1px solid',
              borderColor: colors.LightGrey200,
              gap: 4,
              paddingY: 6
            }}
          >
            <MSLogo />
            <Typography variant='body1'>
              {label}
            </Typography>
          </IconButton>
        </MsalProvider>

        <ErrorNotificationDialog
          open={successDialogOpen}
          titleText={t('authPages.invitationEmailMissmatchTitle')}
          notificationText={`${t('authPages.invitationEmailMissmatch.part1')}'${email}'${t('authPages.invitationEmailMissmatch.part2')}`}
          confirmButtonText={t('common.ok')}
          buttonAction={() => setSuccessDialogOpen(false)}
          success={false}
        />
      </>
    )
  }
}

export default MicrosoftLogin
