import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from 'styles/theme'
import { BrowserRouter } from 'react-router-dom'
import { RouterConfig } from 'routes/RouterConfig'
import { isiPad } from 'utils/deviceCheck'
import RotationNotificationDialog from 'components/molecules/notificationOverlay/RotationNotificationDialog'
import MobileNotification from 'components/organisms/user/MobileNotification'

const App = () => {
  const [notificationOpen, setNotificationOpen] = useState(false)

  const forceLandscapeOrientation = () => {
    // Function to handle orientation change
    const handleOrientationChange = () => {
      setTimeout(() => {
        // Check if the device is in portrait orientation
        const isPortrait = window.matchMedia('(orientation: portrait)').matches

        // If it's an iPad and in portrait mode, apply CSS to force landscape orientation
        if (isiPad() && isPortrait) {
          setNotificationOpen(true)
        } else {
          setNotificationOpen(false)
        }
      }, 100)
    }

    // Add event listener for orientation change
    window.addEventListener('orientationchange', handleOrientationChange)

    // Initial check for orientation
    handleOrientationChange()

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }

  useEffect(() => {
    forceLandscapeOrientation()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <BrowserRouter>
          <RouterConfig />
          <RotationNotificationDialog open={notificationOpen} />
          <MobileNotification />
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  )
}

export default App
