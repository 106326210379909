import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import { PageHeader } from 'components/atoms/Text'
import TeamShoutoutsCard from 'components/organisms/TeamShoutOutsCard'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { useTranslation } from 'react-i18next'

const LeadershipShoutOuts = () => {
  const { t } = useTranslation()
  return (
    <HomePage>
      <BackIconTextButton />
      <PageHeader width='fit-content' paddingBottom='12px'>
        {t('teamShoutOut.heading')}
      </PageHeader>
      <TeamShoutoutsCard />
    </HomePage>
  )
}

export default LeadershipShoutOuts
