import {
  Alert,
  Box,
  Button,
  CardHeader,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import AudioPlayer from 'components/atoms/AudioPlayer'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import VideoPlayer from 'components/atoms/VideoPlayer'
import StepNote from './StepNote'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InCardStack from 'components/atoms/InCardStack'
import ImageContainer from 'components/atoms/container/ImageContainer'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import EditorModule from './EditorModule'
import { USER_NOTES_TYPES } from 'services/constants'
import StyledAccordion from 'components/atoms/container/Accordion'
import { useEffect, useState } from 'react'
import { getFullName } from 'utils/formatText'
import {
  logScreenAction,
  trackingSubScreenKey
} from 'services/trackingScreenActions'
import { useTranslation } from 'react-i18next'

const tickGreen = require('assets/images/tickGreen.png')

const StepContent = ({
  stepData,
  editorMode = false,
  contentEditorCallback,
  contentActionTracking
}) => {
  const { t } = useTranslation()
  const [noteExpanded, setNoteExpanded] = useState(false)
  useEffect(() => {
    const existingNote = stepData.userNotes?.find(
      n => n.type === USER_NOTES_TYPES.NOTE
    )
    if (existingNote) {
      setNoteExpanded(true)
    } else {
      setNoteExpanded(false)
    }
  }, [stepData])

  const handleSourceClick = e => {
    const aTag = e.target.closest('a')
    if (aTag) {
      const linkClicked = aTag.getAttribute('href')
      if (linkClicked) {
        logScreenAction('source_click', linkClicked, trackingSubScreenKey)
      }
    }
  }
  return (
    <>
      {stepData ? (
        <Stack
          spacing={5}
          alignItems='center'
          width='100%'
          data-testid={'step-content-stack'}
          className={editorMode ? null : 'disableTextSelection'}>
          {stepData.thoughtLeaderImage_url ? (
            <ImageContainer
              src={stepData.thoughtLeaderImage_url}
              alt='Thought Leader'
            />
          ) : null}
          {stepData.thoughtLeader ? (
            <InCardStack direction='row'>
              <CardHeader
                avatar={<ProfileAvatar user={stepData.thoughtLeader} />}
                title={getFullName(stepData.thoughtLeader)}
                subheader={stepData.thoughtLeader.thoughtLeaderSpecialty}
              />
              <img width='30px' src={tickGreen} alt='Thought Leader' />
              <Tooltip title={stepData.thoughtLeader.thoughtLeaderBio}>
                <InfoOutlinedIcon />
              </Tooltip>
            </InCardStack>
          ) : null}
          <Typography variant='h5' fontWeight={800}>{stepData.title}</Typography>
          {stepData.thoughtLeaderSummary ? (
            <BoxWithBorder
              padding={5}
              minWidth='200px'
              width='60%'
              marginBottom='20px !important'>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {stepData.thoughtLeaderSummary}
              </Typography>
            </BoxWithBorder>
          ) : null}
          <Typography style={{ whiteSpace: 'pre-line' }}>{stepData.summary}</Typography>
          {stepData.image_url ? (
            <ImageContainer src={stepData.image_url} alt='Content' />
          ) : null}
          {stepData.video_url ? <VideoPlayer url={stepData.video_url} contentActionTracking={contentActionTracking} /> : null}
          {stepData.audio_url ? <AudioPlayer url={stepData.audio_url} contentActionTracking={contentActionTracking} /> : null}
          {editorMode ? (
            <EditorModule
              data={stepData}
              saveCallback={contentEditorCallback}
            />
          ) : stepData.contentWeb ? (
            <Box
              dangerouslySetInnerHTML={{ __html: stepData.contentWeb }}
              className='journeyStepContentInnerHTML'
            />
          ) : null}
          {stepData.meetingRequestSubject ? (
            <Button
              href={`mailto:?subject=${stepData.meetingRequestSubject}
            ${
              stepData.meetingRequestBody
                ? `&body=${stepData.meetingRequestBody}`
                : ''
            }`}>
              {t('pathsAndModels.requestMeeting')}
            </Button>
          ) : null}
          {stepData.activityQuestion ? (
            <StepNote
              stepId={stepData.goalStepId}
              question={stepData.activityQuestion}
              type={USER_NOTES_TYPES.ACTIVITY}
              noteData={stepData.userNotes}
              title={t('stepNote.activitiesAndReflection')}
              placeholder={t('stepNote.recordResponse')}
              contentActionTracking={contentActionTracking}
            />
          ) : null}
          {stepData.goalQuestion ? (
            <StepNote
              stepId={stepData.goalStepId}
              question={stepData.goalQuestion}
              type={USER_NOTES_TYPES.GOAL}
              noteData={stepData.userNotes}
              title={t('stepNote.setGoal')}
              placeholder={t('stepNote.setGoal')}
              contentActionTracking={contentActionTracking}
            />
          ) : null}
          <StyledAccordion
            summaryComponent={
              <Typography variant='subtitle1'>{t('stepNote.takeNotes')}</Typography>
            }
            trackingEvent='note'
            initialExpanded={noteExpanded}
            detailProps={{ sx: { paddingX: 0 } }}
            style={{ marginTop: '10px' }}>
            <StepNote
              stepId={stepData.goalStepId}
              type={USER_NOTES_TYPES.NOTE}
              noteData={stepData.userNotes}
              placeholder={t('stepNote.takeSomeNotes')}
              contentActionTracking={contentActionTracking}
            />
          </StyledAccordion>
          {stepData.sources ? (
            <StyledAccordion
              trackingEvent='sources'
              summaryComponent={
                <Typography variant='subtitle1'>{t('stepNote.sources')}</Typography>
              }>
              <Box
                onClick={handleSourceClick}
                dangerouslySetInnerHTML={{ __html: stepData.sources }}
                className='journeyStepContentInnerHTML'
              />
            </StyledAccordion>
          ) : null}
        </Stack>
      ) : (
        <Alert severity='error'>
          {t('stepNote.errorRetrievingContent')}
        </Alert>
      )}
    </>
  )
}

export default StepContent
