import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import ConvoBoxNew from 'components/molecules/rolePlay/ConvoBoxNew'
import { postRequest } from 'utils/api'
import { AI_ROLE_PLAY_V2_URL, ROLE_PLAY_TYPES } from 'services/constants'
import { OverlayWithText } from 'components/atoms/OverlayLoading'
import UnavoidableConfirmCancel from 'components/molecules/notificationOverlay/UnavoidableConfirmCancel'
import { useTranslation } from 'react-i18next'
import RolePlayInteractionCard from 'components/molecules/rolePlay/RolePlayInteractionCard'
import moment from 'moment'
import RolePlayCompletedPopup from 'components/molecules/rolePlay/RolePlayCompletedPopup'

const RolePlayChat = props => {
  const {
    rolePlay,
    isStarted,
    setIsStarted,
    isCompleted,
    setIsCompleted,
    setActiveUserRolePlayId,
    notes
  } = props
  const { t } = useTranslation()
  const [chatMessages, setChatMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isGatheringResults, setIsGatheringResults] = useState(false)
  const [isPopupCancel, setIsPopupCancel] = useState(false)
  const [isPopupFinish, setIsPopupFinish] = useState(false)
  const [completedRolePlay, setCompletedRolePlay] = useState(null)
  const [completeDialogOpen, setCompleteDialogOpen] = useState(false)
  const [startTime, setStartTime] = useState(null)

  // Formik for submitting text
  const formik = useFormik({
    initialValues: {
      newReply: ''
    },
    onSubmit: async values => {
      const userInput = values.newReply
      if (isLoading || !userInput || userInput.trim().length === 0) {
        return
      }

      setIsLoading(true)

      const newChatMessages = [
        ...chatMessages,
        { role: 'user', content: userInput }
      ]

      setChatMessages(newChatMessages)
      formik.resetForm()

      await handleGetResponse(newChatMessages)
    }
  })

  // API call section
  const handleGetResponse = async newChatMessages => {
    const response = await getAIResponse(newChatMessages, false)

    if (response) {
      const message = {
        role: response.role,
        content: response.content
      }
      setChatMessages([...newChatMessages, message])
    }

    setIsLoading(false)
  }

  const getAIResponse = async (newChatMessages, isCompleted) => {
    // Notes are only relevant when the role play is completed.
    const res = await postRequest(AI_ROLE_PLAY_V2_URL, {
      rolePlayId: rolePlay.id,
      rolePlayMessages: newChatMessages,
      isWithAudio: false,
      notes: notes.length ? notes : null,
      complete: isCompleted,
      conversationSeconds: isCompleted
        ? moment().diff(startTime, 'seconds')
        : null,
      type: ROLE_PLAY_TYPES.TEXT_CHAT
    })

    if (res.status === 200) {
      return res.data.data
    } else if (res.status === 429) {
      // Rate limit
      return {
        role: 'assistant',
        content: res.data.message
      }
    }
    return null
  }

  // Chat page
  useEffect(() => {
    if (isStarted && rolePlay) {
      const firstMessage = rolePlay?.aiData?.firstMessage
      if (firstMessage) {
        setChatMessages([firstMessage])
      } else {
        setChatMessages([])
      }
      setStartTime(moment())
    }
  }, [isStarted, rolePlay])

  const endChat = async isCompleting => {
    if (!isCompleting) {
      setIsStarted(false)
    } else {
      setIsGatheringResults(true)
      const result = await getAIResponse(chatMessages, true, notes)
      setCompletedRolePlay(result?.userRolePlay)
      setIsCompleted(true)
      setIsStarted(false)
      setCompleteDialogOpen(true)
      setIsGatheringResults(false)
    }

    setChatMessages([])
  }

  const handleCancel = () => {
    if (!chatMessages.length) {
      endChat(false)
    } else {
      setIsPopupCancel(true)
    }
  }
  return (
    <>
      <OverlayWithText
        isOpen={isGatheringResults}
        message={t('rolePlay.holdTight')}
      />
      <UnavoidableConfirmCancel
        open={isPopupCancel}
        title={t('rolePlay.cancelChat')}
        subtitle={t('rolePlay.thisSessionNotSaved')}
        lowFocusText={t('rolePlay.dontCancel')}
        lowFocusAction={() => setIsPopupCancel(false)}
        highFocusText={t('rolePlay.cancelChat')}
        highFocusAction={() => {
          setIsPopupCancel(false)
          endChat(false)
        }}
      />
      <UnavoidableConfirmCancel
        open={isPopupFinish}
        title={t('rolePlay.finishSession')}
        lowFocusText={t('common.cancel')}
        lowFocusAction={() => setIsPopupFinish(false)}
        highFocusText={t('common.finish')}
        highFocusAction={() => {
          setIsPopupFinish(false)
          endChat(true)
        }}
      />
      {isCompleted ? (
        <RolePlayInteractionCard
          rolePlay={rolePlay}
          onButtonClick={() => setActiveUserRolePlayId(completedRolePlay?.id)}
          type={ROLE_PLAY_TYPES.TEXT_CHAT}
          completeTime={completedRolePlay?.conversationSeconds}
          buttonText={t('rolePlay.viewYourResults')}
        />
      ) : !isStarted ? (
        <RolePlayInteractionCard
          rolePlay={rolePlay}
          onButtonClick={() => {
            setIsStarted(true)
          }}
          buttonText={t('rolePlay.startChat')}
        />
      ) : (
        <ConvoBoxNew
          height={400}
          messages={chatMessages}
          isLoading={isLoading}
          rolePlay={rolePlay}
          handleCancel={handleCancel}
          setIsPopupFinish={setIsPopupFinish}
          formik={formik}
        />
      )}
      <RolePlayCompletedPopup
        open={completeDialogOpen}
        handleClose={() => setCompleteDialogOpen(false)}
        handleReview={() => setActiveUserRolePlayId(completedRolePlay?.id)}
        rolePlay={rolePlay}
      />
    </>
  )
}

export default RolePlayChat
