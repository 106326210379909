import { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  TextField,
  MenuItem,
  CardContent
} from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { COHORTS_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import CohortUserProgress from 'components/organisms/adminDashboard/CohortUserProgress'
import { NotificationBodyText } from 'components/atoms/Text'

const CompletionPace = ({ companyFilter, cohortFilter }) => {
  const { t } = useTranslation()
  const [cohortSessions, setCohortSessions] = useState([])
  const [selectedCohort, setSelectedCohort] = useState(null)
  const [selectedCohortSess, setSelectedCohortSess] = useState('')

  const selectProps = (attr, lable) => ({
    displayEmpty: true,
    renderValue:
      attr === ''
        ? () => <div style={{ opacity: 0.4 }}>{lable}</div>
        : undefined
  })

  useEffect(() => {
    const fetchCohortsWithSessions = async () => {
      const res = await getRequest(COHORTS_URL, {
        organizationId: companyFilter
      })
      if (res?.status === 200) {
        const cohorts = await Promise.all(
          res.data.data.map(async c => {
            c.cohortSessions = await Promise.all(
              c.cohortSessions
                .filter(s => moment.utc(s.startDate) < moment.utc())
                .map(async s => {
                  const goal = await getGoalInfoById(s.goalId)
                  return {
                    ...s,
                    goalName: goal?.name
                  }
                })
            )
            return c
          })
        )
        setCohortSessions(cohorts)
      }
    }
    fetchCohortsWithSessions()
  }, [companyFilter])

  useEffect(() => {
    setSelectedCohortSess('')
    if (cohortFilter && cohortSessions.length > 0) {
      setSelectedCohort(cohortSessions.find(c => c.id === cohortFilter))
    }
  }, [cohortFilter, cohortSessions])

  return (
    <Stack spacing={10}>
      <RowStack sx={{ justifyContent: 'space-between' }}>
        <Typography variant='h2'>{t('insights.completionPace')}</Typography>
        {selectedCohort && (
          <TextField
            select
            id='goal-select'
            value={selectedCohortSess}
            onChange={e => setSelectedCohortSess(e.target.value)}
            SelectProps={selectProps(
              selectedCohortSess,
              t('company.selectLearningPath')
            )}
            inputProps={{sx: {paddingY: 6}}}>
            {selectedCohort?.cohortSessions.map(g => (
              <MenuItem value={g} key={g.id}>
                {g.goalName}
              </MenuItem>
            ))}
          </TextField>
        )}
      </RowStack>

      {cohortFilter && selectedCohortSess ? (
        <CohortUserProgress
          cohortSession={selectedCohortSess}
          showExport={false}
        />
      ) : (
        <Box>
          <CardContent>
            <NotificationBodyText>
              {t('insights.selectCohortAndLearningPath')}
            </NotificationBodyText>
          </CardContent>
        </Box>
      )}
    </Stack>
  )
}

export default CompletionPace
