import { Box, Typography, alpha } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import RowStack from 'components/atoms/container/RowStack'
import CheckInAnswersButton from 'components/molecules/myTeam/CheckInAnswersPopup'
import CompactRadarGraph from 'components/molecules/myTeam/CompactRadarGraph'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CHECK_IN_SURVEY } from 'routes/constants'
import colors from 'styles/colors'
import radarGraph from 'assets/images/radarGraph.png'
import moment from 'moment'
import { formatDateTime, formatDifference } from 'utils/formatDateTime'
import StyledButton from 'components/atoms/button/StyledButton'

const MessageBox = ({ children }) => (
  <BoxWithBackground
    backgroundColor={colors.MutedTeal100}
    updatedStyle={true}
    sx={{ padding: 10, width: '100%' }}>
    <InCardStack spacing={3}>{children}</InCardStack>
  </BoxWithBackground>
)
const BackgroundBox = ({ children }) => (
  <Box
    sx={{
      backgroundImage: `url(${radarGraph})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: 'inherit',
      width: 340
    }}>
    <Box
      display='flex'
      height='100%'
      width='100%'
      justifyContent='center'
      alignItems='center'
      sx={{
        backgroundColor: alpha(colors.White, 0.85),
        backdropFilter: 'blur(2.5px)'
      }}>
      <MessageBox>{children}</MessageBox>
    </Box>
  </Box>
)

const CheckInSurveyResult = ({ userCalendarEvent, isOwner, surveyUser }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const hasCurrentData = userCalendarEvent?.surveyDisplayData?.currentAverage
  const hasGraphData =
    userCalendarEvent?.surveyDisplayData?.currentAverage ||
    userCalendarEvent?.surveyDisplayData?.previousAverage
  const showGraph =
    hasGraphData && (userCalendarEvent?.isClosed || isOwner || hasCurrentData)
  const surveyOpenTime = moment
    .utc(userCalendarEvent?.startTime)
    .subtract(1, 'day')
  return (
    <BoxWithBorder
      borderColor={colors.LightGrey200}
      borderRadius={3}
      borderWidth={1}
      height='100%'>
      <InCardStack padding={10} spacing={8} height='100%'>
        <RowStack sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Typography variant='h5'>
            {t('checkInSurvey.surveyResult')}
          </Typography>
          {hasCurrentData && (
            <CheckInAnswersButton
              userCalendarEvent={userCalendarEvent}
              surveyUser={surveyUser}
            />
          )}
        </RowStack>
        {showGraph ? (
          <>
            <CompactRadarGraph
              assessmentData={userCalendarEvent.surveyDisplayData}
            />
            <BoxWithBackground updatedStyle={true}>
              <RowStack>
                <Typography variant='button' color='primary'>
                  {t('checkInSurvey.currentLabel', {
                    score: hasCurrentData
                      ? userCalendarEvent.surveyDisplayData.currentAverage
                      : 'N/A'
                  })}
                </Typography>
                <Typography variant='button' color={colors.Grey400}>
                  {t('checkInSurvey.previousLabel', {
                    score: userCalendarEvent.surveyDisplayData.previousAverage
                      ? userCalendarEvent.surveyDisplayData.previousAverage
                      : 'N/A',
                    count: userCalendarEvent.surveyDisplayData.previousCount
                  })}
                </Typography>
              </RowStack>
            </BoxWithBackground>
            {(isOwner || userCalendarEvent?.isClosed) && !hasCurrentData && (
              <Typography textAlign='center'>
                {t('checkInSurvey.thisWeekNotComplete')}
              </Typography>
            )}
          </>
        ) : (
          <BackgroundBox>
            {!isOwner && !userCalendarEvent?.isClosed ? (
              <>
                {surveyOpenTime.isSameOrBefore(moment.utc()) ? (
                  <>
                    <Typography>{t('checkInSurvey.surveyDueIn')}</Typography>
                    <Typography variant='h4'>
                      {formatDifference(
                        moment.utc(),
                        moment.utc(userCalendarEvent?.endTime)
                      )}
                    </Typography>
                    <StyledButton
                      onClick={() =>
                        navigate(CHECK_IN_SURVEY, {
                          state: {
                            userCalendarEventId: userCalendarEvent?.id
                          }
                        })
                      }>
                      {t('checkInSurvey.completeSurveyNow')}
                    </StyledButton>
                  </>
                ) : surveyOpenTime.isAfter(moment.utc()) ? (
                  <>
                    <Typography>
                      {t('checkInSurvey.nextSurveyOpens')}
                    </Typography>
                    <Typography variant='h4'>
                      {formatDateTime(surveyOpenTime)}
                    </Typography>
                  </>
                ) : (
                  <Typography>
                    {t('checkInSurvey.surveyNotAvailable')}
                  </Typography>
                )}
              </>
            ) : (
              <Typography>{t('checkInSurvey.noSurveyData')}</Typography>
            )}
          </BackgroundBox>
        )}
      </InCardStack>
    </BoxWithBorder>
  )
}

export default CheckInSurveyResult
