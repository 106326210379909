import {
  Button,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import { UpwardIcon } from 'components/atoms/Icons'
import { useEffect, useState } from 'react'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import { getRequest } from 'utils/api'
import { ASSESSMENT_RATINGS_TIMELINE_URL } from 'services/constants'
import AssessmentTimelineItem from 'components/molecules/assessments/AssessmentTimelineItem'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import AssessmentChangeTable from 'components/molecules/assessments/AssessmentChangeTable'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ADD_ASSESSMENT_RATERS, ASSESSMENT_SELF } from 'routes/constants'
import ConfirmCancelDialog from 'components/molecules/notificationOverlay/ConfirmCancelDialog'
import NoOpenAssessmentDialog from 'components/molecules/notificationOverlay/NoOpenAssessmentDialog'
import { NotificationBodyText } from 'components/atoms/Text'
import { useTranslation } from 'react-i18next'

const AssessmentTimelineCard = ({ goal, returnCallback }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [goalData, setGoalData] = useState(goal)
  const [timelineData, setTimelineData] = useState([])
  const [requestError, setRequestError] = useState(undefined)
  const [openAssessmentNote, setOpenAssessmentNote] = useState(false)
  const [openNoOpenAssessmentNote, setOpenNoOpenAssessmentNote] =
    useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const goalInfo = await getGoalInfoById(goal.id)
      setGoalData(goalInfo)
      const res = await getRequest(ASSESSMENT_RATINGS_TIMELINE_URL, {
        goal_id: goal.id
      })
      if (res.status === 200) {
        setTimelineData(res.data.data)
        setRequestError(false)
      } else {
        setRequestError(true)
      }
    }
    fetchData()
  }, [goal])

  const gotoSelfAssessment = () => {
    navigate(ASSESSMENT_SELF, {
      state: {
        goalId: goalData.id,
        existingRaterCount: timelineData[0]?.raters.length
      }
    })
  }

  const handleNewSelfAssessmentClick = () => {
    const lastAssessmentTime = moment.utc(timelineData[0]?.ratingTimeStamp)
    const currentTime = moment.utc()
    if (
      timelineData[0] &&
      (timelineData[0].isOpen ||
        currentTime.diff(lastAssessmentTime, 'days') < 14)
    ) {
      setOpenAssessmentNote(true)
    } else {
      gotoSelfAssessment()
    }
  }
  const handleAddRatersClick = () => {
    if (!timelineData[0]?.isOpen) {
      setOpenNoOpenAssessmentNote(true)
    } else {
      navigate(`${ADD_ASSESSMENT_RATERS}/${goalData.id}`, {
        state: {
          assessmentData: timelineData[0]
        }
      })
    }
  }

  const handleNoOpenAssesmentNoteClose = () => {
    setOpenNoOpenAssessmentNote(false)
  }
  return (
    <Stack spacing={3} padding={0}>
      <BackIconTextButton onClick={returnCallback} />
      <Stack
        padding={0}
        alignItems='center'
        display='flex'
        spacing={5}
        direction='row'>
        <UpwardIcon />
        <Stack padding={0}>
          <Typography variant='h3'>{goalData.leadershipModelName}</Typography>
          <Typography variant='subtitle1'>{goalData.name}</Typography>
        </Stack>
      </Stack>
      <CardContent>
        <Grid container gap={5}>
          <Grid item xs={5} minWidth='340px'>
            <Button
              onClick={() => handleNewSelfAssessmentClick()}
              fullWidth
              startIcon={<PersonOutlineOutlinedIcon />}
              endIcon={<ArrowForwardOutlinedIcon />}>
              {t('assessmentTimeline.takeNewSelfassessment')}
            </Button>
          </Grid>
          {goalData.enableOthersAssessment ? (
            <Grid
              item
              xs={5}
              display='flex'
              justifyContent='flex-end'
              minWidth='340px'>
              <Button
                onClick={() => handleAddRatersClick()}
                fullWidth
                startIcon={<GroupsOutlinedIcon />}
                endIcon={<ArrowForwardOutlinedIcon />}>
                {t('assessmentTimeline.inviteOthersAssess')}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      {requestError ? <RequestRetryAlert /> : null}
      {timelineData.length > 0 ? (
        timelineData.map((data, index) => (
          <Stack key={data.id} width='100%' padding={0} spacing={5}>
            {index === 1 ? (
              <Typography variant='subtitle1'>{t('assessmentTimeline.historicalRatings')}</Typography>
            ) : null}
            {index > 1 ? <Divider /> : null}
            <LeftJustifyCardContent sx={{ paddingY: 0 }}>
              <AssessmentTimelineItem data={data} index={index} />
            </LeftJustifyCardContent>
            {index === 0 ? <AssessmentChangeTable data={timelineData} /> : null}
          </Stack>
        ))
      ) : (
        <NotificationBodyText textAlign='center'>
          {t('assessmentTimeline.noAssessmentData')}
        </NotificationBodyText>
      )}
      <ConfirmCancelDialog
        open={openAssessmentNote}
        onClose={() => setOpenAssessmentNote(false)}
        notificationText={t('assessmentTimeline.alreadyCompletedSelfassessment')}
        confirmText={t('common.ok')}
      />
      <NoOpenAssessmentDialog
        open={openNoOpenAssessmentNote}
        handleDialogClose={handleNoOpenAssesmentNoteClose}
        goalId={goalData.id}
        goalName={goalData.name}
        existingRaterCount={timelineData[0]?.raters.length}
      />
    </Stack>
  )
}
export default AssessmentTimelineCard
