import { IconButton, Typography, TextField } from '@mui/material'
import { useState } from 'react'
import { postRequest } from 'utils/api'
import { FEATURES_FEEDBACK_URL } from 'services/constants'
import ConfirmCancelDialog from './ConfirmCancelDialog'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined'
import SuccessfulNotificationDialog from '../notificationOverlay/SuccessfulNotificationDialog'
import { useTranslation } from 'react-i18next'

const FeedbackDialog = ({
  title,
  dialogOpen,
  setDialogOpen,
  featureKey,
  featureContent = {}
}) => {
  const { t } = useTranslation()
  const [isThumbsUp, setIsThumbsUp] = useState(undefined)
  const [feedback, setFeedback] = useState('')
  const [requestError, setRequestError] = useState(null)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)

  const onDialogClose = async confirmed => {
    if (confirmed === 1 || Object.keys(featureContent).length > 0) {
      const res = await postRequest(
        `${FEATURES_FEEDBACK_URL}`,
        {
          featureKey,
          isThumbsUp,
          feedback,
          content: featureContent
        }
      )
      if (res.status === 201) {
        if (confirmed === 1) setSuccessDialogOpen(true)
        setDialogOpen(false)
        setFeedback('')
        setIsThumbsUp(undefined)
      } else {
        setRequestError(true)
        setTimeout(() => {
          setRequestError(false)
        }, 3000)
      }
    } else {
      setIsThumbsUp(undefined)
      setFeedback('')
      setDialogOpen(false)
    }
  }

  return (
    <>
      <ConfirmCancelDialog
        open={dialogOpen}
        onClose={onDialogClose}
        titleText={title}
        notificationText={
          <>
            <IconButton
              aria-label='thumbUp'
              size='large'
              onClick={() => setIsThumbsUp(true)}>
              {isThumbsUp ? (
                <ThumbUpIcon fontSize='large' />
              ) : (
                <ThumbUpOutlinedIcon fontSize='large' />
              )}
            </IconButton>

            <IconButton
              aria-label='thumbDown'
              size='large'
              onClick={() => setIsThumbsUp(false)}>
              {isThumbsUp === false ? (
                <ThumbDownIcon fontSize='large' />
              ) : (
                <ThumbDownOutlinedIcon fontSize='large' />
              )}
            </IconButton>
            <Typography variant='subtitle1' textAlign={'start'}>
              {t('headers.feedback')}
            </Typography>
            <TextField
              multiline
              placeholder={t('chatBot.feedbackAboutExperience')}
              rows={3}
              value={feedback}
              onChange={e => setFeedback(e.target.value)}
              sx={{ width: '500px', marginBottom: 5 }}
            />
            {requestError ? <RequestRetryAlert /> : null}
          </>
        }
        confirmText={t('welcomeArea.sendFeedback')}
        cancelText={t('common.noThanks')}
        buttonsDirection='row'
      />

      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={t('stepFeedback.thankYouForFeedback')}
        confirmButtonText={t('common.done')}
        buttonAction={() => setSuccessDialogOpen(false)}
      />
    </>
  )
}

export default FeedbackDialog
