import React from 'react'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import colors from 'styles/colors'

const BlankRect = ({ w, h, sx }) => (
  <BoxWithBackground
    sx={{ width: w, height: h, ...sx }}
    backgroundColor={colors.LightGrey200}
    borderRadius={1.5}
  />
)

export default BlankRect
