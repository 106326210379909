import { Autocomplete, TextField } from '@mui/material'
import AvatarChip from 'components/atoms/AvatarChip'
import UserConnectionMenuItem from 'components/atoms/input/UserConnectionMenuItem'
import { useState } from 'react'
import { getUserConnections } from 'services/userServices'
import { getFullName } from 'utils/formatText'
import { useTranslation } from 'react-i18next'

const UserConnectionSelectMultiple = props => {
  const { t } = useTranslation()
  const { formik } = props
  const [recipients, setRecipients] = useState([])
  const [recipientRequestSuccess, setRecipientRequestSuccess] =
    useState(undefined)

  const fetchData = async () => {
    const res = await getUserConnections(true)
    if (res.length) {
      setRecipients(res)
      setRecipientRequestSuccess(true)
    } else {
      setRecipientRequestSuccess(false)
    }
  }

  const handleOpen = () => {
    if (recipients.length === 0) {
      fetchData()
    }
  }
  return (
    <Autocomplete
      multiple
      id='recipients-select'
      options={recipients}
      disableCloseOnSelect
      onOpen={() => handleOpen()}
      noOptionsText={
        recipientRequestSuccess === true
          ? t('feedbackArea.noMatchUserFound')
          : recipientRequestSuccess === false
          ? t('feedbackArea.failedLoadUserConnections')
          : t('feedbackArea.loadingConnections')
      }
      renderOption={(props, option) => (
        <UserConnectionMenuItem itemProps={props} option={option} key={option.user?.id}/>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <AvatarChip
            key={option.user?.id}
            user={option.user}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          placeholder={t('userConnectionSelect.recipientName')}
          error={formik.touched.recipients && Boolean(formik.errors.recipients)}
          helperText={formik.touched.recipients && formik.errors.recipients}
        />
      )}
      getOptionLabel={option => `${getFullName(option.user)} (${option.user.email})`}
      isOptionEqualToValue={(option, value) => option.user.id === value.user.id}
      fullWidth
      value={formik.values.recipients}
      onChange={(e, value) => formik.setFieldValue('recipients', value || [])}
      data-testid='user-connection-select'
    />
  )
}

export default UserConnectionSelectMultiple
