import { Stack } from '@mui/material'
import DeactivatedUserList from 'components/molecules/adminDashboard/DeactivatedUserList'
import UserLicensesList from 'components/organisms/adminDashboard/UserLicensesList'
import { useEffect, useState } from 'react'
import { USERS_URL } from 'services/constants'
import { getRequest } from 'utils/api'

const CompanyUsersCard = ({ organizationId, refreshDataRef }) => {
  const [deactivatedUsers, setDeactivatedUsers] = useState([])
  const [deactivatedRequestError, setDeactivatedRequestError] = useState(false)

  const fetchDeactivatedData = async () => {
    const res = await getRequest(USERS_URL, {
      noStats: true,
      deleted: true,
      organizationId
    })
    let resData = []
    if (res.status === 200) {
      if (res.data?.data?.length > 0) resData = res.data.data
      setDeactivatedRequestError(false)
    } else {
      setDeactivatedRequestError(true)
    }
    setDeactivatedUsers(resData)
  }

  useEffect(() => {
    fetchDeactivatedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  return (
    <Stack spacing={5} padding={0} sx={{ paddingBottom: 20 }}>
      <UserLicensesList
        refreshDectivated={fetchDeactivatedData}
        organizationId={organizationId}
        refreshDataRef={refreshDataRef}
      />
      <DeactivatedUserList
        users={deactivatedUsers}
        requestError={deactivatedRequestError}
      />
    </Stack>
  )
}
export default CompanyUsersCard
