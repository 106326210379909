// Desc: This component is a time picker that allows the user to select a start and end time for a meeting.
import { useState, useRef, useEffect } from 'react'
import {
  Box,
  Menu,
  MenuItem,
  useTheme,
  Typography,
  alpha,
  Stack
} from '@mui/material'
import colors from 'styles/colors'
import { Tag } from 'components/atoms/Text'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const generateTimeSlots = () => {
  let times = []
  const interval = 15

  let current = moment('12:00am', 'hh:mma')
  let endingTime = moment('11:45pm', 'hh:mma')

  while (current <= endingTime) {
    times.push(current.format('hh:mma'))
    current.add(interval, 'minutes')
  }
  return times
}

const StartEndTimePicker = ({ timeChange, error }) => {
  const { t, i18n } = useTranslation()
  const [startTime, setStartTime] = useState(
    moment().startOf('hour').add(30, 'minutes').format('hh:mma')
  )
  const [endTime, setEndTime] = useState(
    moment(startTime, 'hh:mma').add(30, 'minutes').format('hh:mma')
  )
  const [currentSelection, setCurrentSelection] = useState(null) // 'start' or 'end'
  const [openPicker, setOpenPicker] = useState(false)
  const wrapperRef = useRef(null)
  const theme = useTheme()
  const inActiveColor = error
    ? theme.palette.error.main
    : alpha(colors.DarkGrey, 0.5)
  const activeColor = theme.palette.primary.main
  moment.locale(i18n.resolvedLanguage)
  const timeOptions = generateTimeSlots()

  useEffect(() => {
    timeChange(startTime, endTime)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStartClick = () => {
    setCurrentSelection('start')
    setOpenPicker(true)
  }

  const handleEndClick = () => {
    setCurrentSelection('end')
    setOpenPicker(true)
  }

  const handleMenuClose = time => {
    if (time) {
      if (currentSelection === 'start') {
        setStartTime(time)
        let newEndTime = endTime
        if (
          moment(time, 'hh:mma').isAfter(moment(newEndTime, 'hh:mma')) ||
          moment(time, 'hh:mma').isSame(moment(newEndTime, 'hh:mma'))
        ) {
          newEndTime = moment(time, 'hh:mma')
            .add(30, 'minutes')
            .format('hh:mma')
          setEndTime(newEndTime)
        }
        timeChange(time, newEndTime)
      } else if (currentSelection === 'end') {
        setEndTime(time)
        let newStartTime = startTime
        if (
          moment(time, 'hh:mma').isBefore(moment(newStartTime, 'hh:mma')) ||
          moment(time, 'hh:mma').isSame(moment(newStartTime, 'hh:mma'))
        ) {
          newStartTime = moment(time, 'hh:mma')
            .subtract(30, 'minutes')
            .format('hh:mma')
          setStartTime(newStartTime)
        }
        timeChange(newStartTime, time)
      }
    }

    setOpenPicker(false)
    setCurrentSelection(null)
  }

  const TimeTag = ({ onClick, active, valueSelected, children }) => (
    <Tag
      sx={{
        color: valueSelected ? colors.Black : inActiveColor,
        background: active ? alpha(activeColor, 0.1) : colors.White,
        borderRadius: 1,
        cursor: 'pointer',
        padding: '4px 1px'
      }}
      onClick={onClick}>
      {children}
    </Tag>
  )

  const boxStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <>
      <Stack spacing={2} padding={0} width={'100%'}>
        <Box
          sx={{
            ...boxStyle,
            position: 'relative',
            borderBottom:
              openPicker && !error
                ? `2px solid ${activeColor}`
                : `1px solid ${inActiveColor}`,
            paddingTop: '21px',
            paddingBottom: '20px'
          }}
          ref={wrapperRef}>
          <Box
            position='absolute'
            left={0}
            top={5}
            justifyContent={'space-between'}
            sx={boxStyle}>
            <Box sx={boxStyle} gap={1}>
              <TimeTag
                onClick={handleStartClick}
                active={currentSelection === 'start'}
                valueSelected={startTime}>
                {startTime || t('contact.start')}
              </TimeTag>
              <TimeTag>{' - '}</TimeTag>
              <TimeTag
                onClick={handleEndClick}
                active={currentSelection === 'end'}
                valueSelected={endTime}>
                {endTime || t('contact.end')}
              </TimeTag>
            </Box>

            <AccessTimeIcon
              sx={{ color: openPicker && !error ? activeColor : inActiveColor }}
            />
          </Box>
        </Box>
        {error && (
          <Typography
            color={'error'}
            sx={{
              fontSize: 12,
              fontWeight: 500
            }}>
            {error}
          </Typography>
        )}
      </Stack>
      <Menu
        anchorEl={wrapperRef.current}
        open={openPicker}
        onClose={() => handleMenuClose('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        slotProps={{
          paper: {
            sx: {
              width: wrapperRef.current
                ? wrapperRef.current.clientWidth
                : undefined,
              height: 199,
              marginTop: 4,
              borderRadius: 3,
              boxShadow: `0px 6px 12px 0px ${alpha(colors.DarkGrey, 0.5)}`
            }
          }
        }}>
        {timeOptions.map(time => (
          <MenuItem
            key={time}
            selected={
              currentSelection === 'start'
                ? time === startTime
                : time === endTime
            }
            onClick={() => handleMenuClose(time)}>
            <Typography variant='body2' color={colors.DarkGrey}>
              {time}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default StartEndTimePicker
