import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import { useLocation, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { getRequest } from 'utils/api'
import { ASSESSMENT_RATINGS_TIMELINE_URL } from 'services/constants'
import AssessmentBehaviourComparisonCard from 'components/organisms/assessments/AssessmentBehaviourComparisonCard'
import RequestErrorPageCard from 'components/molecules/RequestErrorPageCard'

const AssessmentBehaviourComparison = () => {
  const location = useLocation()
  const params = useParams()
  const [behaviourData, setBehaviourData] = useState(
    location.state?.behaviourData || undefined)
  const [requestError, setRequestError] = useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(
        ASSESSMENT_RATINGS_TIMELINE_URL, {goal_id: params.goalId}
      )
      if (res.status === 200) {
        setBehaviourData(res.data.data)
        setRequestError(false)
      } else {
        setRequestError(true)
      }
    }
    if (!behaviourData) {
      fetchData()
    } 
  })

  return (
    <FeedbackPage>
      <BackIconTextButton />
      {requestError ? (
        <RequestErrorPageCard />
      ) : behaviourData ? (
        <AssessmentBehaviourComparisonCard
          behaviour={params.behaviour}
          data={behaviourData}
        />
      ) : null}
    </FeedbackPage>
  )
}

export default AssessmentBehaviourComparison
