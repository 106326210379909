import {
  CardContent,
  CircularProgress,
  MenuItem,
  MenuList,
  Stack,
  Typography
} from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import { NotificationBodyText } from 'components/atoms/Text'
import PanelsWithScrollBars from 'components/atoms/container/PanelsWithScrollBars'
import SearchInputBox from 'components/atoms/input/SearchInputBox'
import OHEPSurveyResult from 'components/molecules/internal/OHEPSurveyResult'
import { useEffect, useState } from 'react'
import { OHEP_SURVEYS_URL } from 'services/constants'
import { getRequest } from 'utils/api'

const OHEPSurveysExportCard = () => {
  const [loading, setLoading] = useState(true)
  const [surveyList, setSurveyList] = useState([])
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [filterText, setFilterText] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const oRes = await getRequest(OHEP_SURVEYS_URL)
      if (oRes.status === 200) {
        setSurveyList(oRes.data.data)
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <PanelsWithScrollBars
      leftList={
        <InCardStack>
          <SearchInputBox
            onChangeHandler={e => setFilterText(e.target.value)}
            placeholder='Search for survey'
          />
          <MenuList sx={{ width: '100%', top: '-20px' }}>
            {surveyList
              .filter(c => c.title?.indexOf(filterText) > -1)
              .map(c => (
                <MenuItem
                  onClick={() => setSelectedSurvey(c)}
                  key={c.id}
                  style={{ justifyContent: 'center', whiteSpace: 'normal' }}>
                  <Typography
                    color={c.id === selectedSurvey?.id ? 'primary' : 'default'}
                    paddingBottom='10px'>
                    {c.title}
                  </Typography>
                </MenuItem>
              ))}
          </MenuList>
        </InCardStack>
      }>
      {loading ? (
        <CardContent>
          <CircularProgress />
        </CardContent>
      ) : selectedSurvey ? (
        <InCardStack>
          <CardContent>
            <Typography variant='h5'>{selectedSurvey.title}</Typography>
          </CardContent>
          <OHEPSurveyResult surveyId={selectedSurvey.id} />
        </InCardStack>
      ) : (
        <CardContent>
          <Stack maxWidth='600px'>
            <NotificationBodyText>
              Select a survey to view results
            </NotificationBodyText>
          </Stack>
        </CardContent>
      )}
    </PanelsWithScrollBars>
  )
}

export default OHEPSurveysExportCard
