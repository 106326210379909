import { alpha } from '@mui/material'
import colors from 'styles/colors'
import { Tag } from 'components/atoms/Text'
import RowStack from 'components/atoms/container/RowStack'

const RadioTags = ({ tags, selectedTag, setSelectedTag }) => {
  const tagStyle = {
    color: colors.DarkGrey,
    background: colors.White,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '16px',
    cursor: 'pointer'
  }

  const activeTagStyle = {
    color: colors.BondiBlue,
    background: alpha(colors.BondiBlue, 0.1)
  }

  return (
    <RowStack spacing={2} width={'auto'}>
      {tags.map(tag => (
        <Tag
          key={tag.key}
          style={{
            ...tagStyle,
            ...(selectedTag === tag.value ? activeTagStyle : {})
          }}
          onClick={() => setSelectedTag(tag.value)}>
          {tag.label}
        </Tag>
      ))}
    </RowStack>
  )
}

export default RadioTags
