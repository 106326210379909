import { Stack, Box, Typography, useTheme } from '@mui/material'
import ReportPage from 'components/templates/ReportPage'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import colors from 'styles/colors'
import { CardStack } from 'components/atoms/container/BoxCard'
import { getFullName } from 'utils/formatText'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MonarkLogoSvg } from 'assets/images/monark_logo.svg'
import { BloomingFlowerIcon } from 'components/atoms/Icons'

const InCardStack = ({ children, ...props }) => (
  <Stack padding={0} {...props}>
    {children}
  </Stack>
)

const TitlePage = ({ userData, assessmentName, downloadingFile }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const fullName = getFullName(userData)
  moment.locale(i18n.resolvedLanguage)

  const footerSx = {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    color: colors.White,
    fontFamily: theme.typography.fontFamily
  }

  return (
    <>
      <ReportPage
        downloadingFile={downloadingFile}
        containerStyle={{ padding: '64px 80px' }}>
        <InCardStack spacing={28}>
          <MonarkLogoSvg
            style={{ '--dot-color': colors.GoldenYellow400 }}
            width={200}
          />

          <InCardStack spacing={4.5}>
            <Typography variant='body1' color={colors.MidnightTeal400}>
              {`${assessmentName} ${t('rolePlay.results')}`}
            </Typography>

            <Typography variant='h1' color={colors.MidnightTeal400}>
              {t('fullReport.selfAssessment')}
            </Typography>
          </InCardStack>
        </InCardStack>

        <BoxWithBackground
          backgroundColor={colors.MidnightTeal400}
          borderRadius={0}
          sx={{
            width: '100%',
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: '45%'
          }}>
          <CardStack spacing={4} sx={{ padding: '64px' }}>
            <Typography sx={{ ...footerSx, color: colors.MutedTeal300 }}>
              {t('fullReport.preparedFor')}
            </Typography>
            <Typography sx={footerSx} style={{ fontSize: 24 }}>
              {fullName || userData.email}
            </Typography>
            <Typography sx={footerSx}>
              {moment().format('MMMM DD, YYYY')}
            </Typography>
          </CardStack>
        </BoxWithBackground>

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-10%, -44%)'
          }}>
          <BloomingFlowerIcon sx={{ width: 998, height: 974 }} />
        </Box>
      </ReportPage>
    </>
  )
}

export default TitlePage
