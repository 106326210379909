import { Button, CardContent, Stack, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import OverlayLoading from 'components/atoms/OverlayLoading'
import RadioOption from 'components/atoms/input/RadioOption'
import QuestionOptionsTemplate from 'components/templates/QuestionOptionsTemplate'
import SimplePage from 'components/templates/SimplePage'
import SinglePageMessageCard from 'components/templates/SinglePageMessageCard'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  POST_GOAL_ACTIVITY_URL,
  POST_GOAL_QUIZ_LOGGING_URL
} from 'services/constants'
import { getRequest, postRequest } from 'utils/api'

const PostGoalQuiz = () => {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [requestError, setRequestError] = useState(undefined)
  const [quiz, setQuiz] = useState({})
  const [contentDeepLink, setContentDeepLink] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(`${POST_GOAL_ACTIVITY_URL}/${params.quizId}`)
      if (res.status === 200) {
        // This is for mobile to catch deep link url and open content page on mobile
        setContentDeepLink(
          `${window.location.origin}/assets/deepLinkAction.html?action=goalStepContent&goalId=${res.data.data.goalId}&goalStepId=${res.data.data.goalStepId}&email=${params.userEmail}`
        )
        setQuiz(res.data.data)
        setRequestError(false)
      } else {
        setRequestError(true)
      }
      if (params.userEmail) {
        await postRequest(POST_GOAL_QUIZ_LOGGING_URL, {
          postGoalWeeklyEmailId: params.quizId,
          email: params.userEmail,
          postGoalQuizAnswer: params.optionIndex
        })
      }
      setLoading(false)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SimplePage>
      <OverlayLoading open={loading} />
      {requestError ? (
        <SinglePageMessageCard
          noBorder={true}
          titleText='Failed to retrieve information'
          message='Sorry, we are not able to find matching record at the moment. Please try again later.'
        />
      ) : (
        <Stack marginTop={10}>
          <Typography variant='h3'>{quiz.title}</Typography>

          {quiz.quizQuestion ? (
            <CardContent>
              <QuestionOptionsTemplate
                initialValue={params.optionIndex}
                question={quiz.quizQuestion.questionText}
                correctMessage={quiz.quizQuestion.correctSummary}
                wrongMessage={quiz.quizQuestion.wrongSummary}
                correctAnswer={quiz.quizQuestion.correctIndex.toString()}
                options={quiz.quizQuestion.options.map((a, i) => (
                  <RadioOption value={i} label={a} key={`option-${i}`} />
                ))}
              />
            </CardContent>
          ) : null}
          <CardContent>
            <InCardStack>
              <Typography>
                Click below to review the content and learn more.
              </Typography>
              <Button href={contentDeepLink}>Tell Me More</Button>
            </InCardStack>
          </CardContent>
        </Stack>
      )}
    </SimplePage>
  )
}
export default PostGoalQuiz
