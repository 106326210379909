import { Box, Grid, Typography, Divider, alpha, MenuList } from '@mui/material'
import AvatarChip from 'components/atoms/AvatarChip'
import InCardStack from 'components/atoms/InCardStack'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { getColorByIndex } from 'components/atoms/button/JourneyPathIconButton'
import NumberIconButton from 'components/atoms/button/NumberIconButton'
import ToolTipCustomized from 'components/atoms/container/ToolTipCustomized'
import moment from 'moment'
import { useEffect, useState } from 'react'
import colors from 'styles/colors'
import { formatDateShort } from 'utils/formatDateTime'

const CohortUserProgressChart = ({ goalSteps, selectedCallback }) => {
  const [selectedMilestoneNumber, setSelectedMilestoneNumber] = useState(0)
  const [currentMilestoneNumber, setCurrentMilestoneNumber] = useState(0)
  useEffect(() => {
    const futureStone = goalSteps.find(
      s => s.facilitationDate && moment.utc(s.facilitationDate) >= moment.utc()
    )
    let foundNumber = futureStone?.number
    if (!futureStone) {
      foundNumber = goalSteps[goalSteps.length - 1].number
    }
    setCurrentMilestoneNumber(foundNumber)
    setSelectedMilestoneNumber(foundNumber)
  }, [goalSteps])

  useEffect(() => {
    selectedCallback(selectedMilestoneNumber)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMilestoneNumber])
  return (
    <Box
      width='100%'
      paddingBottom={10}
      paddingX={30}
      paddingTop={20}
      overflow='auto'>
      <Grid container width='fit-content' wrap='nowrap'>
        {goalSteps.map(s => (
          <Grid
            item
            width={30}
            display='flex'
            key={`date-${s.id}`}
            overflow='visible'>
            {s.facilitationDate ? (
              <Box>
                <Typography variant='caption' width='fit-content' noWrap>
                  {formatDateShort(s.facilitationDate)}
                </Typography>
              </Box>
            ) : null}
          </Grid>
        ))}
      </Grid>
      <Grid container width='fit-content' height={120} wrap='nowrap'>
        {goalSteps.map(s => {
          const stepUsers = s.completedUsers?.filter(
            c => c.milestoneStep.number === selectedMilestoneNumber
          )
          const extraUserLength = stepUsers.length - 2
          const plusLabel = extraUserLength > 0 ? true : false
          return (
            <Grid
              item
              width={30}
              alignItems='flex-end'
              display='flex'
              key={`fDate-${s.id}`}>
              {s.facilitationDate ? (
                <Divider
                  orientation='vertical'
                  sx={{
                    position: 'relative',
                    height: 120,
                    left: 14,
                    borderColor: alpha(colors.BondiBlue, 0.5)
                  }}
                />
              ) : null}
              <InCardStack spacing={0} width='100%'>
                {stepUsers.slice(0, 2).map(u => (
                  <ToolTipCustomized
                    key={`user-step-${u.id}-${s.id}`}
                    whiteBackground={true}
                    toolTip={<AvatarChip user={u.user} variant='filled' />}>
                    <Box
                      border={
                        u.status === 'Behind'
                          ? `${colors.LightSalmon} 1px solid`
                          : 'unset'
                      }
                      borderRadius={10}>
                      <ProfileAvatar user={u.user} size='tiny' />
                    </Box>
                  </ToolTipCustomized>
                ))}
                {plusLabel ? (
                  <ToolTipCustomized
                    whiteBackground={true}
                    toolTip={
                      <MenuList>
                        {stepUsers.map(u => (
                          <AvatarChip
                            key={`list-popup-${u.user.id}`}
                            user={u.user}
                          />
                        ))}
                      </MenuList>
                    }>
                    <Typography variant='caption' color='primary'>
                      + {extraUserLength}
                    </Typography>
                  </ToolTipCustomized>
                ) : null}
              </InCardStack>
            </Grid>
          )
        })}
      </Grid>
      <Divider
        sx={{
          top: 22,
          left: 10,
          position: 'relative',
          width: goalSteps?.length * 30 - 20
        }}
      />
      <Grid container width='fit-content' wrap='nowrap'>
        {goalSteps.map(s => (
          <Grid
            item
            width={30}
            paddingTop={s.milestone_level === 1 ? 0 : 1}
            key={`step-${s.id}`}>
            <NumberIconButton
              number={s.percentage?.toFixed(0)}
              size={s.milestone_level === 1 ? 30 : 25}
              fontSize={12}
              disabled={
                s.milestone_level === 1 && s.number > currentMilestoneNumber
              }
              active={
                s.milestone_level === 1 && s.number === selectedMilestoneNumber
              }
              onClick={
                s.milestone_level === 1 && s.facilitationDate
                  ? () => setSelectedMilestoneNumber(s.number)
                  : null
              }
              color={
                s.milestone_level === 1
                  ? undefined
                  : getColorByIndex(s.stepIndex)
              }
            />
            <Box display='flex' justifyContent='center'>
              <Typography
                variant='caption'
                textAlign='center'
                sx={{ transform: 'rotate(-45deg)', top: 15 }}>
                {s.milestone_level === 1 ? s.name : ''}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default CohortUserProgressChart
