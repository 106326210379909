import { useParams } from 'react-router-dom'
import ResetPasswordCard from 'components/organisms/user/ResetPasswordCard'
import SplashPage from 'components/templates/SplashPage'
import { useTranslation } from 'react-i18next'

const ResetPassword = () => {
  const { t } = useTranslation()
  const params = useParams()

  return (
    <SplashPage title={t('authPages.resetPassword')} showPrivacyPolicy={false}>
      <ResetPasswordCard token={params.token} />
    </SplashPage>
  )
}

export default ResetPassword
