import AddRatersCard from 'components/organisms/assessments/AddRatersCard'
import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HOME } from 'routes/constants'
import { getGoalInfoById } from 'utils/getSupportingInfo'

const AssessmentAddRaters = () => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const fromSelfAssessment = location.state?.fromSelfAssessment
  const assessmentData = location.state?.assessmentData

  useEffect(() => {
    const fetchAndRedirect = async () => {
      const goalData = await getGoalInfoById(parseInt(params.goalId, 10))
      if (!goalData.enableOthersAssessment) {
        navigate(HOME)
      }
    }
    fetchAndRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <FeedbackPage>
      <AddRatersCard
        fromSelfAssessment={fromSelfAssessment}
        goalId={params.goalId}
        data={assessmentData}
      />
    </FeedbackPage>
  )
}

export default AssessmentAddRaters
