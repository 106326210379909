import { TaskAlt } from '@mui/icons-material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Avatar, Icon } from '@mui/material'
import ImageContainer from './container/ImageContainer'
import survey from 'assets/images/survey.svg'
import growingPlant from 'assets/images/growing_plant.svg'
import wavingHand from 'assets/images/wavingHand.svg'
import bloomingFlower from 'assets/images/blooming_flower.svg'
import successAccent from 'assets/images/success.svg'

const journey = require('assets/images/journey.png')
const lock = require('assets/images/lock.png')
const learning = require('assets/images/learningPreference.png')
const learningInvert = require('assets/images/learningInvert.png')
const feedback = require('assets/images/feedback.png')
const feedbackBlue = require('assets/images/feedbackBlue.png')
const feedbackBlueLarge = require('assets/images/feedbackBlueLarge.png')
const upward = require('assets/images/uicons_upward.png')
const industry = require('assets/images/industry.png')
const job = require('assets/images/job.png')
const upGreen = require('assets/images/upGreen.png')
const downRed = require('assets/images/downRed.png')
const goal = require('assets/images/goal.png')
const goalInvert = require('assets/images/goalInvert.png')
const department = require('assets/images/department.png')
const report = require('assets/images/reportPreview.png')

export const InfoCardIcon = ({ src, sx, ...props }) => (
  <Icon sx={[{ width: 80, height: 80 }, sx]} {...props}>
    <ImageContainer src={src} alt={src} />
  </Icon>
)
export const TaskSuccessIcon = ({ size = 50 }) => (
  <TaskAlt sx={{ width: size, height: size }} />
)
export const TaskErrorIcon = ({ size = 50, color }) => (
  <ErrorOutlineIcon sx={{ width: size, height: size }} color={color} />
)
export const JourneyIcon = () => <ImageContainer src={journey} alt='journey' />
export const LockIcon = () => <ImageContainer src={lock} alt='lock' />
export const LearningIcon = () => (
  <ImageContainer src={learning} alt='learning' />
)
export const LearningInvertIcon = () => (
  <ImageContainer src={learningInvert} alt='learning' />
)
export const FeedbackIcon = props => (
  <Icon {...props}>
    <ImageContainer src={feedback} alt='feedback' />
  </Icon>
)
export const FeedbackBlueIcon = ({ iconVariant, ...otherProps }) => (
  <Icon {...otherProps}>
    <ImageContainer
      src={iconVariant === 'large' ? feedbackBlueLarge : feedbackBlue}
      alt='feedbackBlue'
    />
  </Icon>
)
export const UpwardIcon = props => (
  <Icon {...props}>
    <ImageContainer src={upward} alt='up' />
  </Icon>
)
export const IndustryIcon = props => (
  <Icon {...props}>
    <ImageContainer src={industry} alt='industry' />
  </Icon>
)

export const JobIcon = props => (
  <Icon {...props}>
    <ImageContainer src={job} alt='job' />
  </Icon>
)

export const DepartmentIcon = props => (
  <Icon {...props}>
    <ImageContainer src={department} alt='department' />
  </Icon>
)

export const UpGreen = props => (
  <Icon {...props}>
    <ImageContainer src={upGreen} alt='upGreen' />
  </Icon>
)

export const DownRed = props => (
  <Icon {...props}>
    <ImageContainer src={downRed} alt='downRed' />
  </Icon>
)

export const GoalIcon = props => (
  <Icon {...props}>
    <ImageContainer src={goal} alt='goal' />
  </Icon>
)

export const GoalInvertIcon = () => (
  <ImageContainer src={goalInvert} alt='goal' />
)

export const SurveyIcon = props => (
  <Icon {...props}>
    <ImageContainer src={survey} alt='survey' />
  </Icon>
)

export const GrowingPlantIcon = props => (
  <Icon {...props}>
    <ImageContainer src={growingPlant} alt='growing plant' />
  </Icon>
)

export const BloomingFlowerIcon = props => (
  <Icon {...props}>
    <ImageContainer
      maxWidth={998}
      maxHeight={974}
      src={bloomingFlower}
      alt='blooming flower'
    />
  </Icon>
)

export const SuccessAccentIcon = props => <InfoCardIcon src={successAccent} />

export const LogoAvatar = () => (
  <Avatar src={require('assets/images/logoAvatar.png')}>M</Avatar>
)

export const WavingHandIcon = props => (
  <Icon {...props}>
    <ImageContainer src={wavingHand} alt='waving hand' />
  </Icon>
)

export const ReportImg = ({ sx, ...otherProps }) => (
  <ImageContainer
    sx={{
      position: 'absolute',
      top: '8%',
      right: 0,
      ...sx
    }}
    {...otherProps}
    src={report}
    alt='report'
  />
)
