import {
  Button,
  Grid,
  Icon,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
  useTheme
} from '@mui/material'
import { DownRed, UpGreen } from 'components/atoms/Icons'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import colors from 'styles/colors'
import { formatDate, formatDateShort } from 'utils/formatDateTime'
import { useTranslation } from 'react-i18next'
import ToolTipCustomized from 'components/atoms/container/ToolTipCustomized'

const ChangeIcon = ({ hasOldScore, oldScore, newScore }) =>
  hasOldScore ? (
    oldScore !== newScore ? (
      <Icon sx={{ marginLeft: '10px', marginBottom: '-8px' }}>
        {oldScore > newScore ? <DownRed /> : <UpGreen />}
      </Icon>
    ) : (
      <Icon sx={{ marginLeft: '10px', height: '18px' }}>
        <HorizontalRuleIcon />
      </Icon>
    )
  ) : null

export const ChangeTable = ({
  data,
  hasOlderScore,
  timestampField,
  children,
  oldDataKey = 1,
  newDataKey = 0,
  longFormDate = false,
  showBehaviors = true,
  timeLabel = 'assessmentTimeline.rating',
  showOlderColumn = false
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const tableHeaderStyle = {
    paddingBottom: 0,
    fontStyle: 'italic',
    fontWeight: 400,
    verticalAlign: 'middle',
    textAlign: 'center',
    borderBottom: 0
  }
  const formatDateText = date => {
    if (!date) return ''
    if (longFormDate) {
      return formatDate(date, i18n.resolvedLanguage)
    }
    return formatDateShort(date, i18n.resolvedLanguage)
  }

  return (
    <TableContainer>
      <Table>
        {showBehaviors && (
          <TableHead>
            <TableRow>
              <TableCell style={tableHeaderStyle} />
              <TableCell
                style={tableHeaderStyle}
                sx={{ color: colors.Aluminium }}>
                {t('assessmentTimeline.behaviours')}
              </TableCell>
              {showOlderColumn && (
                <TableCell
                  style={tableHeaderStyle}
                  sx={{ color: theme.palette.primary.main, maxWidth: 120 }}>
                  {hasOlderScore
                    ? `${formatDateText(data[oldDataKey][timestampField])} ${t(timeLabel)}`
                    : t('assessmentTimeline.noComparisonData')}
                </TableCell>
              )}
              <TableCell
                style={tableHeaderStyle}
                sx={{
                  color: showOlderColumn
                    ? colors.LightSalmon
                    : theme.palette.primary.main,
                  maxWidth: 120
                }}>
                {`${formatDateText(data[newDataKey][timestampField])} ${t(timeLabel)}`}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}

export const ChangeTableRow = ({
  name,
  oldScore,
  newScore,
  index,
  handleRowClick,
  tooltip,
  boldIndex = false,
  showOlderColumn = false
}) => {
  const theme = useTheme()
  const tableBodyStyle = (color, bold, textAlign = 'center') => {
    return {
      border: 'solid 1px ' + theme.palette.background.paper,
      verticalAlign: 'middle',
      color: color || 'black',
      textAlign,
      fontWeight: bold ? 'bold' : 'normal',
      fontSize: bold ? '18px' : 'inherit',
      backgroundColor: color
        ? alpha(color, 0.1)
        : theme.palette.background.default
    }
  }
  return (
    <TableRow
      key={name}
      data-testid={`change-table-${name}`}
      onClick={handleRowClick ? handleRowClick : null}
      sx={handleRowClick ? { '&:hover': { cursor: 'pointer' } } : null}>
      <TableCell style={tableBodyStyle(undefined, boldIndex)}>
        {index + 1}
      </TableCell>
      <TableCell
        style={tableBodyStyle(undefined, false, 'left')}
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: tooltip ? 10 : undefined
        }}>
        {name}
        {tooltip && (
          <ToolTipCustomized
            toolTip={
              <Stack padding={5} spacing={2}>
                <Typography variant='body1SmallWhite'>
                  {tooltip}
                </Typography>
              </Stack>
            }
          />
        )}
      </TableCell>
      {showOlderColumn && (
        <TableCell style={tableBodyStyle(theme.palette.primary.main, true)}>
          {oldScore}
        </TableCell>
      )}
      <TableCell
        style={tableBodyStyle(
          showOlderColumn ? colors.LightSalmon : theme.palette.primary.main,
          true
        )}>
        {newScore}
        <ChangeIcon
          hasOldScore={showOlderColumn && oldScore !== '-'}
          oldScore={oldScore}
          newScore={newScore}
        />
      </TableCell>
    </TableRow>
  )
}

export const SelfOtherToggle = ({ selfState, setSelfState }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const getButtonStyle = active => {
    return {
      backgroundColor: active
        ? alpha(theme.palette.primary.main, 0.15)
        : theme.palette.grey[100],
      border: active ? 'solid 1px ' + theme.palette.primary.light : 'none',
      boxShadow: 'none',
      color: active ? 'black' : colors.Aluminium,
      padding: 0
    }
  }
  return (
    <Grid container borderRadius='100%'>
      <Grid item xs={6}>
        <Button
          fullWidth
          style={getButtonStyle(selfState)}
          sx={{
            borderRadius: '16px 0 0 16px'
          }}
          onClick={() => setSelfState(true)}>
          {t('goalsArea.self')}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          style={getButtonStyle(!selfState)}
          sx={{
            borderRadius: '0 16px 16px 0'
          }}
          onClick={() => setSelfState(false)}>
          {t('goalsArea.others')}
        </Button>
      </Grid>
    </Grid>
  )
}
