import { Box, Card, Stack, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'

const SinglePageCard = ({
  children,
  title,
  mainBodyWidth = '450px',
  noBorder = false
}) => {
  return (
    <Container maxWidth='lg'>
      <Box display='flex' justifyContent='center'>
        <Card
          sx={{
            paddingTop: '50px',
            minWidth: '600px',
            minHeight: '800px',
            boxShadow: noBorder ? 'none' : undefined
          }}>
          <Stack spacing={0}>
            <Typography variant='h2_alu' align='center'>
              {title}
            </Typography>
            <Stack width={mainBodyWidth} alignSelf='center'>
              {children}
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Container>
  )
}

export default SinglePageCard
