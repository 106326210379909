import * as yup from 'yup'
import {
  Alert,
  Button,
  CardContent,
  Typography
} from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { validationsRules } from 'utils/formValidations'
import InputDialog from './notificationOverlay/InputDialog'
import { USER_FEEDBACK_TYPES, USER_FEEDBACK_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import FormInputBox from 'components/atoms/input/FormInputBox'
import SuccessfulNotificationDialog from './notificationOverlay/SuccessfulNotificationDialog'
import { useLocation, useNavigate } from 'react-router-dom'
import { USER_FEEBACK } from 'routes/constants'
import { getFullName } from 'utils/formatText'
import UserConnectionSelect from 'components/atoms/input/UserConnectionSelect'
import { useTranslation } from 'react-i18next'

const UserFeedbackNew = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [selected, setSelected] = useState(undefined)
  const [sendDialogOpen, setSendDialogOpen] = useState(selected ? true : false)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [feedbackCreatedSuccessful, setFeedbackCreatedSuccessful] =
    useState(undefined)

  const formik = useFormik({
    initialValues: {
      feedbackText: '',
      recipientId: null
    },
    validationSchema: yup.object({
      feedbackText: validationsRules.textRequired,
      recipientId: yup.number().required(t('feedbackArea.selectRecipient'))
    }),
    onSubmit: async values => {
      const requestBody = {
        createdForUserId: values.recipientId,
        feedbackText: values.feedbackText,
        feedbackType: USER_FEEDBACK_TYPES.COMMENT
      }
      const res = await postRequest(USER_FEEDBACK_URL, requestBody)
      if (res.data?.success) {
        setFeedbackCreatedSuccessful(true)
        handleSendDialogClose()
        setSuccessDialogOpen(true)
      } else {
        setFeedbackCreatedSuccessful(false)
      }
    }
  })

  useEffect(() => {
    if (location.search) {
      setSelected(location.state?.selected)
      setSendDialogOpen(true)
      formik.setFieldValue('feedbackText', '')
      formik.setFieldValue('recipientId', location.state?.selected.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const handleCreateNew = () => {
    formik.resetForm()
    setFeedbackCreatedSuccessful(undefined)
    setSendDialogOpen(true)
  }


  const handleSendDialogClose = () => {
    setSendDialogOpen(false)
    if (location.search) {
      navigate(USER_FEEBACK)
    }
  }
  return (
    <CardContent>
      <Button onClick={() => handleCreateNew()}>{t('welcomeArea.sendFeedback')}</Button>
      <InputDialog
        open={sendDialogOpen}
        titleText={t('welcomeArea.sendFeedback')}
        handleCancel={() => handleSendDialogClose()}
        formik={formik}
        confirmText={t('common.send')}
        cancelText={t('common.cancel')}>
        {location.search && selected ? (
          <Typography variant='subtitle2' width='100%'>
            {t('common.to')} {getFullName(selected)}
          </Typography>
        ) : (
          <UserConnectionSelect formik={formik} fieldName='recipientId' />
        )}
        <FormInputBox
          sx={{ width: '100%' }}
          type='text'
          formik={formik}
          multiline
          rows={3}
          attributeName='feedbackText'
          placeholder={t('feedbackArea.yourMessage')}
        />
        {feedbackCreatedSuccessful === false ? (
          <Alert severity='error'>
            {t('feedbackArea.feedbackError')}
          </Alert>
        ) : null}
      </InputDialog>
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={t('feedbackArea.feedbackSuccess')}
        confirmButtonText={t('common.done')}
        buttonAction={() => setSuccessDialogOpen(false)}
      />
    </CardContent>
  )
}

export default UserFeedbackNew
