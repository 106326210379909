// Code from
// https://medium.com/geekculture/run-code-on-component-unmount-with-the-custom-hook-useonunmount-5355bd99e11e
import { useEffect, useRef } from 'react'

export const useOnPageLeave = callback => {
  const isLeavingPage = useRef(false)

  useEffect(() => () => (isLeavingPage.current = true), [])

  useEffect(() => () => isLeavingPage.current && callback(), [callback])
}
