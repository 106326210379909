import { useNavigate } from 'react-router-dom'
import { ASSESSMENT_SELF } from 'routes/constants'
import { getLocalUser } from 'utils/localStorage'
import ConfirmCancelDialog from './ConfirmCancelDialog'
import { useTranslation } from 'react-i18next'

const NoOpenAssessmentDialog = ({ open, goalId, goalName, handleDialogClose, existingRaterCount }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleClose = confirmed => {
    if (confirmed) {
      navigate(ASSESSMENT_SELF, {
        state: {
          goalId: goalId,
          goalName: goalName,
          userId: getLocalUser().id,
          existingRaterCount
        }
      })
    }
    handleDialogClose()
  }
  return (
    <ConfirmCancelDialog
      open={open}
      onClose={handleClose}
      notificationText={t('assessmentTimeline.notCompletedSelfassessment')}
      confirmText={t('assessmentTimeline.doSelfAssessmentNow')}
      cancelText={t('assessmentTimeline.notRightNow')}
    />
  )
}

export default NoOpenAssessmentDialog
