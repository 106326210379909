export const MONARK_WEBSITE = 'www.leadwithmonark.com'
export const MONARK_CONTACT_EMAIL = 'hello@leadwithmonark.com'

export const MONARK_SUPPORT_EMAIL = 'support@leadwithmonark.com'

export const PAGE_TITLE_PREFIX = 'Monark - '

export const PAGE_TITLES = {
  DEFAULT: 'Monark',
  FEEDBACK: PAGE_TITLE_PREFIX + 'Feedback',
  JOURNEY: PAGE_TITLE_PREFIX + 'Journey',
  ADMIN: PAGE_TITLE_PREFIX + 'Admin Dashboard',
  INTERNAL: PAGE_TITLE_PREFIX + 'Internal Dashboard',
  ABOUT: PAGE_TITLE_PREFIX + 'About',
  PRACTISE: PAGE_TITLE_PREFIX + 'Practice'
}

export const ISO_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const LANGUAGE_OPTIONS = [
  { value: 'en', label: 'english' },
  { value: 'fr', label: 'french' }
]

export const LANGUAGES = { EN: 'en', FR: 'fr' }

export const NARROW_BROWSER_WIDTH = 1380
