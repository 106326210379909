import moment from 'moment'
import { getConstant } from 'services/constantServices'
import {
  CONSTANTS_OPTIONS,
  GOALS_URL,
  GOAL_STEP_URL,
  USER_CONNECTION_RELATIONSHIP,
  USER_ROLE_TYPES,
  VIRTUES_URL,
  VIRTUE_TYPES
} from 'services/constants'
import { getRequest } from 'utils/api'

export const getGoals = async () => {
  let goals = sessionStorage.getItem('goals')
  if (!goals) {
    const goalRes = await getRequest(GOALS_URL)
    if (goalRes.data.success) {
      const goalParsed = goalRes.data.data.map(g => {
        g.leadershipPathName = `${g.leadershipModelName}: ${g.name}`
        return g
      })
      sessionStorage.setItem('goals', JSON.stringify(goalParsed))
      goals = goalRes.data.data
    }
  } else {
    goals = JSON.parse(goals)
  }
  return goals
}
export const getGoalInfoById = async goalId => {
  const goals = await getGoals()
  return goals.find(g => g.id === goalId)
}

export const getGoalNameById = async goalId => {
  if (goalId) {
    const goal = await getGoalInfoById(goalId)
    if (goal) {
      return `${goal.leadershipModelName}: ${goal.name}`
    }
  }
  return null
}

export const getGoalSteps = async goalId => {
  const key = `goal-steps-${goalId}`
  let goalSteps = sessionStorage.getItem(key)
  if (!goalSteps) {
    const res = await getRequest(GOAL_STEP_URL, { goalId })
    if (res.status === 200) {
      const { data } = res.data
      sessionStorage.setItem(key, JSON.stringify(data))
      goalSteps = data
    }
  } else {
    goalSteps = JSON.parse(goalSteps)
  }
  return goalSteps
}

export const getVirtues = async type => {
  const storageName = `virtues-${type}`
  let virtues = () => JSON.parse(sessionStorage.getItem(storageName))
  if (virtues.length === 0) {
    const res = await getRequest(VIRTUES_URL, { type })
    if (res.data.success) {
      sessionStorage.setItem(storageName, JSON.stringify(res.data.data))
      virtues = res.data.data
    }
  }
  return virtues
}
export const getVirtueById = async (
  virtueId,
  type = VIRTUE_TYPES.WORK_VALUE
) => {
  const virtues = await getVirtues(type)
  return virtues.find(v => v.id === virtueId)
}

export const getUserConnectionLabel = (value, translation) => {
  const labelItem = USER_CONNECTION_RELATIONSHIP.find(
    item => item.value === value
  )
  return translation(`constants.userConnRelationship.${labelItem.value}`)
}

export const getLabelFromOnboardingAnswer = async (questionId, answers) => {
  if (answers[questionId] >= 0) {
    const questions = await getConstant(
      CONSTANTS_OPTIONS.ONBOARDING_QUESTIONNARIE
    )
    const question = questions.find(q => q.questionId === questionId)
    const option = question?.options.find(
      o => o.value === parseInt(answers[questionId])
    )
    return option?.label
  }
  return null
}

export const getLabelFromConstatnt = async (key, value) => {
  if (key && value?.length) {
    const constant = await getConstant(key)
    const item = constant.find(c => c.value === value)
    if (item) {
      return item.label
    }
  }
  return ''
}

export const getOrgLicenseStatus = organization => {
  let status = null
  if (organization.licenseType && organization.licenseExpireAt) {
    if (moment.utc(organization.licenseExpireAt) > moment.utc()) {
      status = true
    } else {
      status = false
    }
  }
  return status
}

export const userRoleMenuItems = (showSuperAdmin = false) => {
  const roleList = [
    {
      key: USER_ROLE_TYPES.RESTRICTED,
      value: USER_ROLE_TYPES.RESTRICTED,
      label: 'member' // labels are mapped to the translation file
    },
    {
      key: USER_ROLE_TYPES.ADMINISTRATOR,
      value: USER_ROLE_TYPES.ADMINISTRATOR,
      label: 'administrator'
    }
  ]
  if (showSuperAdmin) {
    roleList.push({
      key: USER_ROLE_TYPES.SUPER_ADMIN,
      value: USER_ROLE_TYPES.SUPER_ADMIN,
      label: 'systemAdministrator'
    })
  }
  return roleList
}

