import {
  alpha,
  Stack,
  Typography,
  Divider,
  useTheme,
  Avatar
} from '@mui/material'
import { ActionText } from 'components/atoms/Text'
import DateTimeStack from 'components/molecules/calendarEvents/DateTimeStack'
import RowStack from 'components/atoms/container/RowStack'
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import { ROLE_PLAY_TYPES } from 'services/constants'
import { useNavigate } from 'react-router-dom'
import { ROLE_PLAY, ROLE_PLAY_PRACTISES } from 'routes/constants'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import colors from 'styles/colors'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'

const RolePlayResultCard = ({
  userRolePlay,
  setActiveUserRolePlayId,
  active = false
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const onRolePlayPracticePath = location.pathname === ROLE_PLAY_PRACTISES
  const navigateToRolePlay = (showResults = false) => {
    if (onRolePlayPracticePath) {
      navigate(ROLE_PLAY, {
        state: {
          rolePlayId: userRolePlay.rolePlayId,
          activeUserRolePlayId: showResults ? userRolePlay.id : null,
          practiceType: userRolePlay.type,
        }
      })
    } else {
      setActiveUserRolePlayId(userRolePlay.id)
    }
  }

  return (
    <BoxWithBorder
      borderColor={
        active
          ? alpha(theme.palette.primary.main, 0.4)
          : theme.palette.grey[200]
      }
      sx={{
        backgroundColor: active
          ? alpha(theme.palette.primary.main, 0.05)
          : theme.palette.primary.contrastText,
        borderWidth: '2px',
        paddingX: 5,
        '&:hover': { cursor: onRolePlayPracticePath ? 'pointer' : 'unset' }
      }}
      onClick={() => onRolePlayPracticePath && navigateToRolePlay(true)}>
      <RowStack padding={0} justifyContent={'space-between'} spacing={0}>
        <RowStack padding={3} maxWidth={'86%'}>
          <DateTimeStack
            event={{ startTime: userRolePlay.createdAt }}
            dayMonth={true}
          />

          <Divider orientation='vertical' sx={{ height: 80 }} />

          <Stack padding={0} maxWidth={'60%'}>
            <ActionText
              variant='h3'
              onClick={() => navigateToRolePlay(false)}
              maxWidth={'100%'}
              noWrap
              style={{ color: colors.Black }}>
              {userRolePlay.rolePlay.title}
            </ActionText>

            <Typography variant='body1Small' maxWidth={'100%'} noWrap>
              {userRolePlay.rolePlay.summary}
            </Typography>

            <ActionText
              onClick={() => navigateToRolePlay(true)}
              variant='h6'
              sx={{ fontSize: 12, paddingTop: 3 }}
            >
              {t('pulseSurvey.viewResults')}
            </ActionText>
          </Stack>
        </RowStack>

        <Stack padding={'5px'}>
          <Avatar
            style={{
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              color: theme.palette.info.main
            }}
            variant='soft'>
            {userRolePlay.type === ROLE_PLAY_TYPES.TEXT_CHAT ? (
              <ChatOutlinedIcon color='primary' />
            ) : (
              <PhoneInTalkOutlinedIcon color='primary' />
            )}
          </Avatar>
        </Stack>
      </RowStack>
    </BoxWithBorder>
  )
}

export default RolePlayResultCard
