import { patchRequest, postRequest } from 'utils/api'
import { SCREEN_CLICK_TRACKING_URL, SCREEN_TRACKING_URL } from './constants'
import moment from 'moment'

export const trackingScreenKey = 'trackingScreenId'
export const trackingSubScreenKey = 'trackingSubScreenId'
export const TRACKING_SCREEN_MODELS = {
  GOAL_STEPS: 'goal_steps',
  LEADERSHIP_MODELS: 'leadership_models'
}
export const logScreenAction = async (
  eventType,
  eventDetail,
  sessionScreenKey = trackingScreenKey
) => {
  const trackingScreen = sessionStorage.getItem(sessionScreenKey)
  if (trackingScreen) {
    const trackingScreenId = JSON.parse(trackingScreen).id
    await postRequest(SCREEN_CLICK_TRACKING_URL, {
      trackingScreenId,
      eventType,
      eventDetail
    })
  }
}

const updateViewTimeRequest = async (id, startTime, sessionKey) => {
  await patchRequest(`${SCREEN_TRACKING_URL}/${id}`, {
    viewTime: moment.utc().diff(moment.utc(startTime), 'milliseconds')
  })
  sessionStorage.removeItem(sessionKey)
}
export const updateViewTime = async (screenLogRef, sessionKey) => {
  if (screenLogRef?.current?.id) {
    await updateViewTimeRequest(
      screenLogRef.current.id,
      screenLogRef.current.startTime,
      sessionKey
    )
    screenLogRef.current = {}
  }
}
export const cleanupTrackingScreen = async () => {
  await Promise.all(
    [trackingScreenKey, trackingSubScreenKey].map(async k => {
      const trackingScreen = sessionStorage.getItem(k)
      if (trackingScreen) {
        const screenObj = JSON.parse(trackingScreen)
        await updateViewTimeRequest(screenObj.id, screenObj.startTime, k)
      }
    })
  )
}

export const logScreen = async (
  screenLogRef,
  sessionKey,
  location,
  relatedModelName,
  relatedModelId
) => {
  if (screenLogRef?.current.id) {
    await updateViewTime(screenLogRef, sessionKey)
  }
  if (process.env.NODE_ENV === 'test') {
    return
  }
  const startTime = moment.utc()
  const res = await postRequest(SCREEN_TRACKING_URL, {
    name: location.pathname,
    isWeb: true,
    relatedModelName,
    relatedModelId
  })
  if (res?.status === 201) {
    const created = res.data.data
    created.startTime = startTime
    sessionStorage.setItem(sessionKey, JSON.stringify(created))
    screenLogRef.current = created
  }
}
