import { useState } from 'react'
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  useTheme
} from '@mui/material'
import PasswordValidation from 'components/atoms/input/PasswordValidation'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'

export const FormInputBox = props => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    attributeName,
    formik,
    showHelperText = true,
    endAdornment = undefined,
    adormentPosition = 'end',
    InputProps = {},
    isStandard = false,
    ...otherProps
  } = props
  const [focused, setFocused] = useState(false)

  return (
    <TextField
      {...otherProps}
      id={attributeName}
      onChange={formik.handleChange}
      onBlur={e => {
        formik.handleBlur(e)
        setFocused(false)
      }}
      onFocus={() => setFocused(true)}
      value={formik.values[attributeName]}
      error={
        formik.touched[attributeName] && Boolean(formik.errors[attributeName])
      }
      helperText={
        showHelperText
          ? formik.touched[attributeName] &&
            (formik.errors[attributeName]
              ? t(`${formik.errors[attributeName]}`)
              : false)
          : ''
      }
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position={adormentPosition}
            sx={{
              color: focused
                ? theme.palette.primary.main
                : colors.LightGrey200
            }}>
            {endAdornment}
          </InputAdornment>
        ),
        ...InputProps
      }}
      {...(isStandard && { variant: 'standard' })}
    />
  )
}

export const TextInputBox = ({ formik, sx, attributeName, ...props }) => {
  return (
    <FormInputBox
      required
      isStandard
      type='text'
      formik={formik}
      inputProps={{
        style: {
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 4,
          paddingRight: 4,
        }
      }}
      sx={[{ minWidth: '100%' }, sx]}
      attributeName={attributeName}
      {...props}
    />
  )
}

export const EmailInputBox = ({ isDisabled, formik, sx, ...props }) => {
  const { t } = useTranslation()

  return (
    <FormInputBox
      required
      isStandard
      type='text'
      id='email'
      placeholder={t('authPages.workEmail')}
      formik={formik}
      inputProps={{
        style: {
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 4,
          paddingRight: 4,
          textTransform:
            formik.values.email.length > 0
              ? 'lowercase'
              : undefined
        }
      }}
      endAdornment={
        <MailOutlineOutlinedIcon
          sx={{ color: colors.LightGrey400, marginRight: 2 }}
        />
      }
      attributeName='email'
      disabled={isDisabled}
      sx={[
        {
          minWidth: '100%',
          backgroundColor: isDisabled ? colors.WhiteLilac : null,
        },
        sx,
      ]}
      {...props}
    />
  )
}

export const PasswordInputBox = ({
  formik,
  onKeyDown,
  sx,
  isValidate=false,
  ...props
}) => {
  const [isShow, setIsShow] = useState(false)
  const { t } = useTranslation()

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <FormInputBox
        required
        isStandard
        type={isShow ? 'text' : 'password'}
        placeholder={t('authPages.password')}
        formik={formik}
        inputProps={{
          style: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 4,
            paddingRight: 4,
          }
        }}
        endAdornment={
          <IconButton
            aria-label='toggle password visibility'
            onClick={() => setIsShow(show => !show)}
            sx={{ paddingRight: 0, marginRight: 2 }}
          >
            {isShow ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
          </IconButton>
        }
        attributeName='password'
        onKeyDown={onKeyDown ?? (e => {
          if (e.keyCode === 13) {
            e.preventDefault()
            formik.handleSubmit()
          }
        })}
        sx={[{ minWidth: '100%' }, sx]}
        {...props}
      />
      { isValidate && <PasswordValidation formik={formik} /> }
    </Box>
  )
}

export default FormInputBox
