import { useState } from 'react'
import { Typography, Box } from '@mui/material'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import SearchInputBox from 'components/atoms/input/SearchInputBox'
import colors from 'styles/colors'
import RowStack from 'components/atoms/container/RowStack'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import { MultiCheckBoxSelect } from 'components/atoms/input/StyledInputs'
import { isMatchingUser } from 'utils/userSearchHelper'
import { useTranslation } from 'react-i18next'

const TableContainer = ({
  headers,
  children,
  data,
  displayData,
  setDisplayData,
  filters = null,
  maxHeight = '100%',
  minHeight = '200px',
  otherSX,
  shorterRow = false,
  tableProps = {},
  title = '',
  placeholder = ''
}) => {
  const { t } = useTranslation()
  const [selectedFilters, setSelectedFilters] = useState([])

  const contentContainerProps = {
    width: '100%',
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.LightGrey200
  }
  const sectionSx = {
    padding: '20px 16px',
    borderBottom: `1px solid ${colors.Grey}`
  }

  const searchInputSx = {
    width: '320px',
    backgroundColor: colors.White,
    padding: '8px 16px',
    borderRadius: 25,
    '& .MuiInputBase-input': {
      paddingY: 2.5
    },
    '& .MuiInputAdornment-root': {
      color: colors.Grey300
    }
  }

  const tableSx = {
    sx: {
      '& .MuiTableHead-root .MuiTableCell-root': {
        backgroundColor: colors.LightGrey100,
        color: colors.DarkGrey,
        fontSize: 13,
        lineHeight: '150%',
        textTransform: 'uppercase'
      },
      '& .MuiTableHead-root .MuiTableSortLabel-root': {
        gap: 4
      },
      '& .MuiTableCell-root': {
        padding: 8,
        borderBottom: `1px solid ${colors.Grey}`
      }
    }
  }

  const StyledTypo = ({ children, variant = 'body2', sx }) => {
    return (
      <Typography variant={variant} sx={{ color: colors.DarkGrey, ...sx }}>
        {children}
      </Typography>
    )
  }

  const onSearch = value => {
    const newData = data.filter(user => isMatchingUser(user, value))
    setDisplayData(newData)
  }

  const filterData = filterSelection => {
    setSelectedFilters(filterSelection)
    if (filterSelection.length === 0) return setDisplayData(data)

    // Group filters by type
    const groupedFilters = filterSelection.reduce((acc, filter) => {
      const filterType = filters.find(f => f.value === filter)?.type
      if (filterType) {
        if (!acc[filterType]) {
          acc[filterType] = []
        }
        acc[filterType].push(filter)
      } else {
        if (!acc['other']) {
          acc['other'] = []
        }
        acc['other'].push(filter)
      }
      return acc
    }, {})

    // Filter by grouped filters, same type of filter should be OR condition and different type should be AND condition
    const newData = data.filter(user => {
      return Object.entries(groupedFilters).every(([key, value]) => {
        return value.some(filter => Object.values(user).includes(filter))
      })
    })
    setDisplayData(newData)
  }

  return (
    <BoxWithBorder {...contentContainerProps}>
      {title && (
        <StyledTypo variant='h3' sx={sectionSx}>
          {title}
        </StyledTypo>
      )}

      <RowStack justifyContent={'space-between'} sx={sectionSx}>
        <SearchInputBox
          onChangeHandler={e => onSearch(e.target.value)}
          placeholder={placeholder || t('searchArea.search')}
          style={{ padding: 0 }}
          inputSx={searchInputSx}
        />

        {filters && (
          <MultiCheckBoxSelect
            options={filters}
            selectedOptions={selectedFilters}
            onSelection={filterData}
          />
        )}
      </RowStack>

      <PresetTableContainer
        maxHeight={maxHeight}
        minHeight={minHeight}
        otherSX={otherSX}
        tableProps={{ ...tableSx, ...tableProps }}
        shorterRow={shorterRow}
        headers={headers}>
        {children}
      </PresetTableContainer>

      <Box sx={{ backgroundColor: colors.LightGrey100, ...sectionSx }}>
        <StyledTypo>
          {t('fullReport.showing')} {displayData.length > 0 ? 1 : 0}{' '}
          {t('fullReport.to')} {displayData.length} {t('fullReport.of')}{' '}
          {data.length} {t('fullReport.leaders')}
        </StyledTypo>
      </Box>
    </BoxWithBorder>
  )
}

export default TableContainer
