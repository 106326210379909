import { SaveOutlined } from '@mui/icons-material'
import { Box, Button, Divider, Typography } from '@mui/material'
import LowFocusButton from 'components/atoms/button/LowFocusButton'
import InCardStack from 'components/atoms/InCardStack'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { PageHeader } from 'components/atoms/Text'
import { useEffect, useState } from 'react'
import { GOAL_STEP_CONTENT_URL } from 'services/constants'
import { patchRequest } from 'utils/api'
import RichTextContentEditor from './RichTextEditor'

const EditorModule = ({ data, saveCallback }) => {
  const [webContent, setWebContent] = useState(undefined)
  const [mobileContent, setMobileContent] = useState(undefined)
  const [sources, setSources] = useState(undefined)
  const [initialContent, setInitialContnet] = useState({})
  const [saved, setSaved] = useState(true)
  const [requestError, setRequestError] = useState(undefined)

  useEffect(() => {
    setWebContent(data?.contentWeb)
    setMobileContent(data?.content)
    setSources(data?.sources)
    setInitialContnet(data)
    setSaved(true)
  }, [data])

  useEffect(() => {
    sessionStorage.setItem('editSaved', saved ? 'true' : 'false')
  }, [saved])

  const webEditorCallback = updated => {
    setWebContent(updated)
    setSaved(false)
  }
  const mobileEditorCallback = updated => {
    setMobileContent(updated)
    setSaved(false)
  }
  const sourcesEditorCallback = updated => {
    setSources(updated)
    setSaved(false)
  }

  const handleSave = async (clear = false, clearSource = false) => {
    const requestBody = {
      contentWeb: webContent,
      content: mobileContent,
      sources: sources
    }
    if (clear) {
      requestBody.contentWeb = null
      requestBody.content = null
    }
    if (clearSource) {
      requestBody.sources = null
    }
    const res = await patchRequest(
      `${GOAL_STEP_CONTENT_URL}/${data.id}`,
      requestBody
    )
    if (res.status === 200) {
      setSaved(true)
      setRequestError(false)
      if (clear) {
        setWebContent(null)
        setMobileContent(null)
      }
      if (clearSource) {
        setSources(null)
      }
      initialContent.content = mobileContent
      initialContent.contentWeb = webContent
      initialContent.sources = sources
      saveCallback(initialContent)
    } else {
      setRequestError(true)
    }
  }

  const handleClear = () => {
    handleSave(true)
  }

  const SaveButton = props => (
    <Button
      {...props}
      onClick={() => handleSave()}
      disabled={saved}
      sx={{ paddingY: 2 }}
      startIcon={<SaveOutlined />}>
      Save
    </Button>
  )

  const handleClearSource = () => {
    handleSave(false, true)
  }
  return (
    <>
      <Divider width='100%' />
      <InCardStack direction='row'>
        <PageHeader>Content Editor </PageHeader>
        {webContent ? <SaveButton /> : null}
      </InCardStack>
      {webContent ? (
        <>
          {requestError ? <RequestRetryAlert /> : null}
          <RichTextContentEditor
            data={initialContent.contentWeb}
            changeCallback={webEditorCallback}
          />
          <Typography variant='subtitle1'>Preview</Typography>
          <Box
            dangerouslySetInnerHTML={{ __html: webContent }}
            className='journeyStepContentInnerHTML'
          />
        </>
      ) : (
        <Button onClick={() => setWebContent('<p></p>')}>Add Content</Button>
      )}
      {mobileContent ? (
        <>
          <Divider width='100%' />
          <InCardStack direction='row'>
            <PageHeader>Content Editor - Mobile</PageHeader>
            <SaveButton />
          </InCardStack>
          {requestError ? <RequestRetryAlert /> : null}
          <RichTextContentEditor
            data={initialContent.content}
            changeCallback={mobileEditorCallback}
          />
          <Typography variant='subtitle1'>Mobile Preview</Typography>
          <Box
            dangerouslySetInnerHTML={{ __html: mobileContent }}
            width='410px'
            className='journeyStepContentInnerHTML'
            border='solid 2px grey'
            borderRadius={8}
            padding='10px'
            minHeight='800px'
          />
        </>
      ) : (
        <Button onClick={() => setMobileContent('<p></p>')}>
          Add Mobile Content
        </Button>
      )}
      {webContent || mobileContent ? (
        <>
          <InCardStack direction='row'>
            <SaveButton />
            <LowFocusButton sx={{ paddingY: 2 }} onClick={() => handleClear()}>
              Remove All Content
            </LowFocusButton>
          </InCardStack>
          {requestError ? <RequestRetryAlert /> : null}
        </>
      ) : null}

      {sources ? (
        <>
          <Divider width='100%' />
          <InCardStack direction='row'>
            <PageHeader>Sources Editor</PageHeader>
            <SaveButton />
          </InCardStack>
          {requestError ? <RequestRetryAlert /> : null}
          <RichTextContentEditor
            data={initialContent.sources}
            changeCallback={sourcesEditorCallback}
          />
          <Typography variant='subtitle1'>Sources Preview</Typography>
          <Box
            dangerouslySetInnerHTML={{ __html: sources }}
            className='journeyStepContentInnerHTML'
          />
          <InCardStack direction='row'>
            <SaveButton />
            <LowFocusButton
              sx={{ paddingY: 2 }}
              onClick={() => handleClearSource()}>
              Remove Sources
            </LowFocusButton>
          </InCardStack>
        </>
      ) : (
        <Button onClick={() => setSources('<p></p>')}>Add Sources</Button>
      )}
      <Typography variant='caption'>End of editor area</Typography>
      <Divider width='100%' />
    </>
  )
}

export default EditorModule
