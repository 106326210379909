// Import the functions you need from the SDKs you need
import { firebaseConfig, firebaseVapidKey } from 'config/config'
import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import { getToken } from 'firebase/messaging'
import { postRequest } from 'utils/api'
import { ADD_DEVICE_TOKEN_URL } from './constants'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)

export const loggedTokenKey = 'loggedToken'
export const getFirebaseToken = async () => {
  const messaging = getMessaging(firebaseApp)
  const permission = window.Notification.permission
  const loggedToken = localStorage.getItem(loggedTokenKey)
  if (permission !== 'denied') {
    try {
      // Wait for service worker to get ready to avoid issue on first load
      // Reference from https://github.com/firebase/firebase-js-sdk/issues/6013
      // window.navigator.serviceWorker.ready
      
      const currentToken = await getToken(messaging, {
        vapidKey: firebaseVapidKey
      })

      if (currentToken && loggedToken !== currentToken) {
        const res = await postRequest(ADD_DEVICE_TOKEN_URL, {
          token: currentToken
        })
        if (res.status === 200) {
          localStorage.setItem(loggedTokenKey, currentToken)
        }
      }
    } catch (error) {
      console.log('notification permission not granted', error.message)
    }
  }
}
