import { alpha, ListItem, useTheme } from '@mui/material'

const ThemeListItemButton = props => {
  const theme = useTheme()
  const { children } = props
  return (
    <ListItem
      {...props}
      sx={{
        marginY: '8px',
        border: 'solid 1px',
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
        paddingY: '12px',
        paddingX: '40px',
        borderRadius: '16px',
        fontSize: '14px',
        '&:hover': {
          cursor: 'pointer',
          background: alpha(theme.palette.primary.main, 0.2)
        }
      }}>
      {children}
    </ListItem>
  )
}

export default ThemeListItemButton
