import { CardContent } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import LinkButton from 'components/atoms/button/LinkButton'
import PulseSurveyRatingCard from 'components/organisms/pulsSurveys/PulseSurveyRatingCard'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { PULSE_SURVEYS, ROOT } from 'routes/constants'
import { PULSE_SURVEY_RECIPIENTS_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import SinglePageMessageCard from 'components/templates/SinglePageMessageCard'
import { pulseSurveyName } from 'config/config'

const PulseSurveyRate = () => {
  const location = useLocation()
  const params = useParams()
  const [surveyData, setSurveyData] = useState(
    location.state?.pulseSurvey || undefined
  )
  const [requestError, setRequestError] = useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(
        `${PULSE_SURVEY_RECIPIENTS_URL}?pulseSurveyId=${params.id}`
      )
      if (res.status === 200) {
        const invites = res.data.data

        const targetInvite = invites.find(
          i => i.pulseSurveyId === parseInt(params.id)
        )
        if (!targetInvite) {
          setRequestError(true)
        } else {
          setSurveyData(targetInvite)
        }
      } else {
        setRequestError(true)
      }
    }
    if (!surveyData) {
      fetchData()
    }
  })

  return (
    <FeedbackPage>
      {requestError ? (
        <SinglePageMessageCard
          titleText='Not Found'
          message={`Sorry, we cannot find the ${pulseSurveyName.lowerCase} at the moment. You might have completed the rating.`}
          noBorder={true}>
          <CardContent>
            <InCardStack>
              <LinkButton to={ROOT}>Home Page</LinkButton>
              <LinkButton to={PULSE_SURVEYS}>{pulseSurveyName.camelCase}s</LinkButton>
            </InCardStack>
          </CardContent>
        </SinglePageMessageCard>
      ) : surveyData ? (
        <PulseSurveyRatingCard surveyData={surveyData} />
      ) : null}
    </FeedbackPage>
  )
}

export default PulseSurveyRate
