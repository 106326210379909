import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import colors from 'styles/colors'

const defaulOptions = [...Array(5).keys()].map(k => ({
  value: k + 1,
  labelKey: `constants.likertScaleSimple.${k}`
}))

export const LikertScale = ({
  id,
  initialValue,
  onChange,
  options = defaulOptions
}) => {
  const [value, setValue] = useState(
    initialValue !== undefined ? initialValue : null
  )
  const { t } = useTranslation()

  return (
    <BoxWithBackground
      backgroundColor={colors.White}
      sx={{
        overflow: 'hidden',
        flex: 1,
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}>
      {/* Box anchors the relative positions of the divider */}
      <Box
        sx={{
          height: 90,
          width: '100%',
          paddingX: 12,
          paddingTop: 5,
          paddingBottom: 6,
          overflow: 'hidden'
        }}>
        <Divider
          sx={{
            position: 'relative',
            top: 18,
            borderBottomWidth: 2
          }}
        />
        <RadioGroup
          value={value}
          onChange={e => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          {options.map(o => {
            const label = o.labelKey ? t(o.labelKey) : o.label
            return (
              <FormControlLabel
                key={`radio-${o.value}-on-${id}`}
                value={o.value}
                control={
                  <Radio
                    size='medium'
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 35
                      },
                      '&:not(.Mui-checked)': {
                        color: colors.LightGrey400
                      },
                      padding: 0,
                      backgroundColor: colors.White,
                      '&:hover': {
                        backgroundColor: colors.White
                      }
                    }}
                  />
                }
                label={
                  <Typography
                    variant='body1Micro'
                    sx={{
                      userSelect: 'none',
                      textAlign: 'center',
                      whiteSpace: 'pre-line'
                    }}>
                    {label.split(' ').join('\n')}
                  </Typography>
                }
                labelPlacement='bottom'
              />
            )
          })}
        </RadioGroup>
      </Box>
    </BoxWithBackground>
  )
}

export default LikertScale
