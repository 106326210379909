import { Alert, Fade, Snackbar } from '@mui/material'
import { useTranslation } from 'react-i18next'

const NotificationSnackBar = ({
  error,
  success,
  open,
  setOpen,
  autoHideDuration = 1500,
  successText,
  errorText
}) => {
  const { t } = useTranslation()
  return (
    <Snackbar
      data-testid='notification-snack'
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Fade}
      transitionDuration={1000}
      autoHideDuration={autoHideDuration}
      onClose={() => setOpen(false)}>
      {error ? (
        <Alert severity='error'>{errorText ? errorText :  t('company.failedToSave')}</Alert>
      ) : success ? (
        <Alert severity='success'>{successText ? successText : t('company.changeSaved')}</Alert>
      ) : null}
    </Snackbar>
  )
}
export default NotificationSnackBar
