import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import { ORGANIZATIONS_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import SubscriptionRow from './SubscriptionRow'
import InCardStack from 'components/atoms/InCardStack'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import CompanyUsersCard from 'components/organisms/adminDashboard/CompanyUsersCard'
import { getOrgLicenseStatus } from 'utils/getSupportingInfo'

const SubscriptionManagement = ({ organizationId }) => {
  const [subscription, setSubscription] = useState(null)
  const [requestError, setRequestError] = useState(null)
  const [activeLicense, setActiveLicense] = useState(null)
  const [refreshOrg, setRefreshOrg] = useState(0)

  const fetchSubscription = async () => {
    const sRes = await getRequest(`${ORGANIZATIONS_URL}/${organizationId}`, {
      licenseStats: true
    })
    if (sRes.status === 200) {
      const licenseData = sRes.data.data
      const licenseStatus = getOrgLicenseStatus(licenseData)
      setActiveLicense(licenseStatus)
      setSubscription(licenseData)
      setRequestError(false)
    } else {
      setSubscription(null)
      setRequestError(true)
    }
  }

  useEffect(() => {
    fetchSubscription()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  const saveRefresh = () => {
    fetchSubscription()
    setRefreshOrg(refreshOrg + 1)
  }
  return (
    <Stack spacing={5} width='100%' sx={{ paddingTop: 0 }}>
      <InCardStack>
        {requestError ? <RequestRetryAlert /> : null}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Number of Licenses</TableCell>
              <TableCell>Expire Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <SubscriptionRow
                organization={subscription}
                saveCallback={saveRefresh}
                organizationId={subscription?.id}
              />
            </TableRow>
          </TableBody>
        </Table>
        {activeLicense === null ? null : (
          <Typography width='100%'>
            License Status:{' '}
            <Typography
              component='span'
              fontWeight='bold'
              color={activeLicense === false ? 'error' : 'green'}>
              {activeLicense === false ? 'Expired' : 'Active'}
            </Typography>
          </Typography>
        )}
      </InCardStack>
      <Divider />
      <CompanyUsersCard
        organizationId={organizationId}
        refreshDataRef={refreshOrg}
      />
    </Stack>
  )
}

export default SubscriptionManagement
