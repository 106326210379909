import RowStack from 'components/atoms/container/RowStack'
import { ActionText } from 'components/atoms/Text'
import { useTranslation } from 'react-i18next'

const PolicyLinks = ({ sx, fontSize = 14, gap = 0 }) => {
  const { t } = useTranslation()
  return (
    <RowStack
      direction='row'
      width={188 + gap}
      justifyContent='space-between'
      spacing={0}
      sx={sx}>
      <ActionText
        fontSize={fontSize}
        href='https://www.iubenda.com/privacy-policy/55240335'>
        {t('authPages.privacyPolicy')}
      </ActionText>

      <ActionText
        fontSize={fontSize}
        href='https://www.iubenda.com/terms-and-conditions/55240335'>
        {t('authPages.termsOfUse')}
      </ActionText>
    </RowStack>
  )
}

export default PolicyLinks
