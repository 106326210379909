import { Stack, Typography } from '@mui/material'
import ReportPage from 'components/templates/ReportPage'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import { ActionText } from 'components/atoms/Text'
import { MONARK_WEBSITE, MONARK_CONTACT_EMAIL } from 'utils/constants'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MonarkLogoSvg } from 'assets/images/monark_logo.svg'

const InCardStack = ({ children, ...props }) => (
  <Stack padding={0} {...props}>
    {children}
  </Stack>
)

const AboutPage = ({ assessmentName, downloadingFile, pageNum }) => {
  const { t } = useTranslation()
  const typoSx = {
    color: colors.MidnightTeal400,
    lineHeight: '140%'
  }
  const actionSx = {
    ...typoSx,
    fontSize: 8,
    fontWeight: 500
  }
  return (
    <>
      <ReportPage
        downloadingFile={downloadingFile}
        assessmentName={assessmentName}
        pageNum={pageNum}
        containerStyle={{ marginBottom: downloadingFile ? 0 : 15 }}>
        <BoxWithBackground
          backgroundColor={colors.Grey}
          borderRadius={0}
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: '12%',
            left: 0,
            padding: '30px 52px'
          }}>
          <InCardStack spacing={19}>
            <InCardStack spacing={7}>
              <MonarkLogoSvg
                style={{
                  '--dot-color': colors.GoldenYellow400,
                  '--letter-color': colors.MidnightTeal400
                }}
                width={100}
              />

              <Typography variant='body1Nano' sx={typoSx}>
                {t('fullReport.aboutMonark')}
              </Typography>
            </InCardStack>

            <InCardStack spacing={3}>
              <ActionText
                variant='body1Nano'
                sx={actionSx}
                href={`mailto:${MONARK_CONTACT_EMAIL}`}>
                {MONARK_CONTACT_EMAIL}
              </ActionText>

              <ActionText
                variant='body1Nano'
                sx={actionSx}
                href={`https://${MONARK_WEBSITE}/`}
                target='_blank'>
                {MONARK_WEBSITE}
              </ActionText>
            </InCardStack>
          </InCardStack>
        </BoxWithBackground>
      </ReportPage>
    </>
  )
}

export default AboutPage
