import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem
} from '@mui/material'
import AvatarChip from 'components/atoms/AvatarChip'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { ActionText } from 'components/atoms/Text'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const UsersIconPopupList = ({ users, parentKey, popupTitle }) => {
  const [raterDialogOpen, setRaterDialogOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <Grid container gap={2}>
        {users.slice(0, 3).map(rater => (
          <ProfileAvatar key={`user-avatar-${parentKey}-${rater.id}`} size='small' user={rater} />
        ))}
        {users.length > 3 ? (
          <ActionText variant='body2' onClick={() => setRaterDialogOpen(true)}>
            {' '}
            + {users.length - 3} {t('common.more')}
          </ActionText>
        ) : null}
      </Grid>
      <Dialog open={raterDialogOpen} onClose={() => setRaterDialogOpen(false)}>
        <DialogTitle>
          {popupTitle}
          <IconButton
            sx={{ float: 'right', paddingTop: '2px' }}
            onClick={() => setRaterDialogOpen(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: '300px' }}>
          {users.map(rater => (
            <ListItem key={`popup-list-${parentKey}-${rater.id}`} sx={{ paddingY: '2px', paddingX: 0 }}>
              <AvatarChip user={rater} variant='filled' />
            </ListItem>
          ))}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UsersIconPopupList
