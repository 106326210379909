import { Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'

const HeaderTabs = ({
  activePage,
  tabs,
  handleChange,
  handleMouseEnter,
  handleMouseLeave
}) => {
  const {t} = useTranslation()
  return (
    <Tabs
      value={activePage}
      onChange={handleChange}
      sx={{
        '& .MuiTabs-indicator': {
          display: 'none'
        },
        '& .Mui-selected': {
          color: 'primary'
        }
      }}>
      {tabs.map(tab => {
        let label = t(`headers.${tab.label}`)
        if (tab.value === activePage) {
          label = <strong>{label}</strong>
        }
        return (
          <Tab
            sx={{
              paddingLeft: '8px',
              fontWeight: 400,
            }}
            value={tab.value}
            label={label}
            key={tab.value}
            onMouseEnter={e =>
              handleMouseEnter ? handleMouseEnter(e, tab.value) : undefined
            }
            onMouseLeave={e =>
              handleMouseLeave ? handleMouseLeave(e) : undefined
            }
            iconPosition='start'
            onClick={e =>
              tab.value === activePage ? handleChange(e, tab.value) : null
            }
          />
        )
      })}
    </Tabs>
  )
}

export default HeaderTabs
