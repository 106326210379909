import { Box } from '@mui/material'

const FloatingCard = ({children, sx, ...props}) => {
  return (
    <Box
      sx={[
        {
          padding: 12,
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '0px 1px 30px 0px rgba(0, 0, 0, 0.15)',
          width: 456,
          paddingBottom: 16
        },
        sx,
      ]}
      {...props}
    >
      {children}
    </Box>
  )
}

export default FloatingCard
