import LoginCard from 'components/organisms/user/LoginCard'
import { useParams } from 'react-router-dom'
import SplashPage from 'components/templates/SplashPage'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const { t } = useTranslation()
  const params = useParams()

  return (
    <SplashPage title={t('authPages.login')}>
      <LoginCard email={params.email} />
    </SplashPage>
  )
}

export default Login
