import { Box, MenuItem, Popover } from '@mui/material'
import HeaderTabs from 'components/molecules/header/HeaderTabs'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { HOME, PERMISSION_LEVEL } from 'routes/constants'
import {
  contentTabs,
  practiceTab,
  myTeamOneOnOneTab,
  raterTabs
} from 'utils/headerTabs'
import { isAllowedFeature, permitted } from 'utils/permissionCheck'
import { useTranslation } from 'react-i18next'
import { FEATURE_KEYS } from 'services/constants'

const ContentHeaderTabs = ({ activePage, tabs = contentTabs }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [menuItems, setMenuItems] = useState([])
  const [visiting, setVisiting] = useState(false)
  const [mouseOnTab, setMouseOnTab] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  let processedTabs = tabs
  let hasLicense = true
  if (tabs === contentTabs) {
    hasLicense = permitted(PERMISSION_LEVEL.LICENSED)
    if (!hasLicense) {
      processedTabs = raterTabs
    } else {
      if (isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT)) {
        processedTabs = raterTabs
      } else {
        if (isAllowedFeature(FEATURE_KEYS.ROLE_PLAY_STANDALONE)) {
          processedTabs = [...processedTabs, practiceTab]
        }
      }
    }
    if (
      !isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT) &&
      isAllowedFeature(FEATURE_KEYS.MY_TEAM_ONE_ON_ONE)
    ) {
      processedTabs = [...processedTabs, myTeamOneOnOneTab]
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setVisiting(false)
  }

  const handleHover = (event, value) => {
    const selectedTab = processedTabs.find(tab => tab.value === value)
    if (selectedTab.pages) {
      setMenuItems(selectedTab.pages)
      setAnchorEl(event.currentTarget)
      setMouseOnTab(true)
      setVisiting(false)
    } else {
      handleClose()
    }
  }

  const handleMouseLeave = event => {
    if (event.currentTarget === anchorEl) {
      setMouseOnTab(false)
    }
  }
  useEffect(() => {
    const menuTimeout = setTimeout(() => {
      if (anchorEl && !visiting && !mouseOnTab) {
        handleClose()
      }
    }, 500)
    return () => clearTimeout(menuTimeout)
  }, [anchorEl, visiting, mouseOnTab])

  const handleNavigate = url => {
    handleClose()
    navigate(url)
  }

  // handle no sub menu item
  const handleChange = (event, newValue) => {
    const selectedTab = processedTabs.find(tab => tab.value === newValue)
    if (!selectedTab.pages) {
      if (newValue !== location.pathname) {
        navigate(newValue)
      }
    }
  }

  const processActivePage = () => {
    if (!hasLicense && [HOME, 'about'].indexOf(activePage) < 0) {
      return HOME
    }
    return activePage
  }
  return (
    <>
      <HeaderTabs
        tabs={processedTabs}
        activePage={processActivePage()}
        handleMouseEnter={handleHover}
        handleMouseLeave={handleMouseLeave}
        handleChange={handleChange}
      />
      <Popover
        id='mouse-over-popover'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disableRestoreFocus
        sx={{ pointerEvents: 'none' }}>
        <Box
          onMouseEnter={() => setVisiting(true)}
          onMouseLeave={() => setVisiting(false)}
          sx={{ pointerEvents: 'all' }}>
          {menuItems.map(menuItem => (
            <MenuItem
              onClick={() => handleNavigate(menuItem.value)}
              key={menuItem.value}>
              {t(`headers.${menuItem.label}`)}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  )
}

export default ContentHeaderTabs
