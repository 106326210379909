import AuthorizedPage from 'components/templates/AuthorizedPage'
import ContentHeaderTabs from 'components/organisms/ContentHeaderTabs'
import { PAGE_TITLES } from 'utils/constants'

const JourneyPage = ({ children, ...otherProps }) => {
  return (
    <AuthorizedPage
      pageTitle={PAGE_TITLES.JOURNEY}
      {...otherProps}
      headerTabs={<ContentHeaderTabs activePage='journey' />}>
      {children}
    </AuthorizedPage>
  )
}

export default JourneyPage
