import { Icon, Tooltip, useTheme } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const ToolTipCustomized = props => {
  const theme = useTheme()
  const {
    toolTip,
    children,
    whiteBackground = false,
    maxWidth = '360px',
    backgroundColor = theme.palette.primary.main,
    ...otherProps
  } = props
  const toolTipStyle = {
    '& .MuiTooltip-tooltip': {
      backgroundColor: whiteBackground
        ? theme.palette.background.paper
        : backgroundColor,
      maxWidth,
      borderRadius: 5,
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: theme.palette.primary.main
    },
    '& .MuiTooltip-arrow': {
      color: backgroundColor
    }
  }
  return (
    <Tooltip
      {...otherProps}
      arrow
      componentsProps={{
        popper: { sx: toolTipStyle }
      }}
      title={toolTip}>
      {children ? (
        children
      ) : (
        <Icon sx={{ marginLeft: '8px' }}>
          <InfoOutlinedIcon color='primary' />
        </Icon>
      )}
    </Tooltip>
  )
}

export default ToolTipCustomized
