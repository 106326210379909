import React from 'react'
import ProfileCard from 'components/organisms/user/ProfileCard'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { Container, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import DeleteAccountButton from 'components/organisms/user/DeleteAccountButton'
import ChangePasswordButton from 'components/organisms/user/ChangePasswordButton'
import { useTranslation } from 'react-i18next'

const EditProfile = () => {
  const { t } = useTranslation()
  return (
    <HomePage>
      <Container maxWidth='sm'>
        <InCardStack>
          <Typography variant='h3'>{t('profile.editProfile')}</Typography>
          <ProfileCard submitButtonText={t('company.saveChanges')} fromSignup={false}/>
          <ChangePasswordButton />
          <DeleteAccountButton />
        </InCardStack>
      </Container>
    </HomePage>
  )
}

export default EditProfile
