import {
  Box,
  Button,
  CardContent,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import RadarGraphLegend from 'components/atoms/RadarGraphLegend'
import RadarGraph, {
  radarGraphLocationString
} from 'components/molecules/assessments/RadarGraph'
import RadarGraphAddMoreRaters from 'components/molecules/assessments/RadarGraphAddMoreRaters'
import ShareResultPopupButton from 'components/molecules/assessments/ShareResultPopupButton'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ABOUT_360, HOME } from 'routes/constants'
import { ASSESSMENT_BY_GOAL_URL } from 'services/constants'
import colors from 'styles/colors'
import { getRequest } from 'utils/api'
import { updatePageState } from 'utils/windowHistoryHelper'
import { useTranslation } from 'react-i18next'

const CurrentGoalRating = ({ goalId }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [assessmentData, setAssessmentData] = useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(
        ASSESSMENT_BY_GOAL_URL, {goal_id: goalId}
      )
      if (res.status === 200) {
        setAssessmentData(res.data.data)
      }
    }
    if (goalId) {
      fetchData()
    }
  }, [goalId])

  const onAbout360Click = () => {
    updatePageState({ location: radarGraphLocationString })
    navigate(ABOUT_360, { state: { from: HOME } })
  }

  const buttonSx = { padding: '0 5px', width: '220px', marginLeft: '20px' }
  return assessmentData?.selfAllBehaviourAvg ? (
    <InCardStack width='100%'>
      <LeftJustifyCardContent sx={{ width: '100%' }}>
        <Stack padding={0}>
          <Typography variant='h3'>{t('goalsArea.currentGoalRatings')}</Typography>
          <Typography>
            {assessmentData.goalData.leadershipModel.name}:{' '}
            {assessmentData.goalData.name}
          </Typography>
        </Stack>
        {assessmentData?.goalData?.enableOthersAssessment ? (
          <Button sx={buttonSx} onClick={() => onAbout360Click()}>
            {t('goalsArea.learnMoreAbout360Results')}
          </Button>
        ) : null}
        <ShareResultPopupButton
          sx={buttonSx}
          assessmentId={assessmentData.id}
          title={t('shareMyResults.selectRecipients')}
          buttonLabel={t('shareMyResults.heading')}
        />
      </LeftJustifyCardContent>
      <Box paddingX='16px' width='100%'>
        <CardContent
          sx={{
            width: 'inherit',
            backgroundColor: theme.palette.background.default,
            borderRadius: 5,
            paddingY: '14px !important'
          }}>
          <InCardStack direction='row' spacing={15} justifyContent='center'>
            <Typography variant='h5'>{t('goalsArea.overallScore')}</Typography>
            <Typography variant='subtitle1' color='primary'>
              {t('goalsArea.self')}: {assessmentData.selfAllBehaviourAvg.toFixed(2)}
            </Typography>
            {assessmentData.othersAllBehaviourAvg ? (
              <Typography
                variant='subtitle1'
                sx={{ color: colors.Aluminium }}>
                {t('goalsArea.others')}: {assessmentData.othersAllBehaviourAvg.toFixed(2)}
              </Typography>
            ) : null}
          </InCardStack>
        </CardContent>
      </Box>

      <RadarGraph assessmentData={assessmentData} />
      <RadarGraphLegend
        displayOthersRating={assessmentData.goalData.enableOthersAssessment}
      />
      <RadarGraphAddMoreRaters assessmentData={assessmentData} />
    </InCardStack>
    ) : null
}

export default CurrentGoalRating
