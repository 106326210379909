import { Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'
import RoundButton from 'components/atoms/button/RoundButton'
import InCardStack from 'components/atoms/InCardStack'
import SettingSwitch from 'components/atoms/input/SettingSwitch'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { updateStates } from 'services/userServices'
import { useTranslation } from 'react-i18next'

const LearningPathReminderSettings = ({ userStates }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dayLabels = [
    { value: 0, label: 'Su' },
    { value: 1, label: 'Mo' },
    { value: 2, label: 'Tu' },
    { value: 3, label: 'We' },
    { value: 4, label: 'Th' },
    { value: 5, label: 'Fr' },
    { value: 6, label: 'Sa' }
  ]
  const [days, setDays] = useState([1, 2, 3, 4, 5])
  const [reminder, setReminder] = useState(true)
  const [timezone, setTimezone] = useState(moment.tz.guess()) //start with local time
  const [time, setTime] = useState(moment.utc().tz(timezone)) // setup to allow test mock
  const [requestError, setRequestError] = useState(false)

  const updateSettigns = async (
    pathReminder,
    pathReminderDays,
    pathReminderTime
  ) => {
    // If local days are different from utc days, needs to adjust updating data
    const updatingTime = moment(pathReminderTime).utc().format('HH:mm:ss')
    const updatingDays = pathReminderDays.map(d =>
      moment(pathReminderTime).set('day', d).utc().day()
    )
    const res = await updateStates({
      pathReminder,
      pathReminderDays: updatingDays,
      pathReminderTime: updatingTime
    })
    if (res.status !== 200) {
      setRequestError(true)
    } else {
      setRequestError(false)
    }
  }

  useEffect(() => {
    if (userStates) {
      if (location.state?.fromJourney === true && !userStates.pathReminder) {
        updateSettigns(reminder, days, time)
      } else {
        if (userStates.pathReminder) {
          // If local days are different from utc days, needs to adjust display data
          const today = moment.utc()
          const currentUtcTime = moment.utc(
            `${today.format('YYYY-MM-DD')} ${userStates.pathReminderTime}`
          )
          const localDays = userStates.pathReminderDays?.map(d =>
            moment(currentUtcTime).set('day', d).tz(timezone).day()
          )
          setDays(localDays)
          setTime(currentUtcTime.tz(timezone))
        }
        setReminder(userStates.pathReminder)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStates])

  const handleDayChange = async day => {
    const newDays = [...days]
    const currentIndex = days.indexOf(day)
    if (currentIndex > -1) {
      newDays.splice(currentIndex, 1)
    } else {
      newDays.push(day)
    }
    setDays(newDays)
    await updateSettigns(reminder, newDays, time)
  }

  const handleTimeChange = async value => {
    setTime(value)
    await updateSettigns(reminder, days, value)
  }
  const handleTimezoneChange = e => {
    setTimezone(e.target.value)
    setTime(time.tz(e.target.value))
  }
  const CardContentContainer = ({ children, title }) => (
    <LeftJustifyCardContent sx={{ padding: 0 }}>
      <Stack padding={0}>
        <Typography variant='subtitle1'>{title}:</Typography>
        {children}
      </Stack>
    </LeftJustifyCardContent>
  )
  return (
    <>
      <Grid container justifyContent='space-between' width='100%'>
        <Grid item>
          <Typography variant='h3'>{t('setings.learningReminders')}</Typography>
        </Grid>
        <Grid item>
          <SettingSwitch
            checked={reminder}
            onChange={e => {
              setReminder(e.target.checked)
              updateSettigns(e.target.checked, days, time)
            }}
            testId='switch-path-reminder'
          />
        </Grid>
      </Grid>
      <CardContentContainer title={t('setings.repeat')}>
        <Stack direction='row' padding={0}>
          {dayLabels.map(({ value, label }) => (
            <RoundButton
              key={`day-button-${value}`}
              number={label}
              size={60}
              fontSize={16}
              disabled={!reminder}
              filled={days.indexOf(value) > -1}
              onClick={() => handleDayChange(value)}
            />
          ))}
        </Stack>
      </CardContentContainer>
      <CardContentContainer title={t('setings.time')}>
        <InCardStack direction='row'>
          <TimePicker
            value={time}
            disabled={!reminder}
            sx={{
              '& .MuiInputBase-root': {
                minWidth: 0
              }
            }}
            onChange={handleTimeChange}
          />
          <TextField
            select
            value={timezone}
            onChange={handleTimezoneChange}
            disabled={!reminder}>
            {moment.tz.names().map((n, i) => (
              <MenuItem key={i} value={n}>
                {`(GMT ${moment.tz(n).format('Z')}) ${n}`}
              </MenuItem>
            ))}
          </TextField>
        </InCardStack>
      </CardContentContainer>
      {requestError ? (
        <RequestRetryAlert message={t('setings.failedToSave')} />
      ) : null}
    </>
  )
}

export default LearningPathReminderSettings
