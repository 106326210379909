import { CardHeader, TableCell } from '@mui/material'
import ProfileAvatar from '../ProfileAvatar'
import { getFullName } from 'utils/formatText'

const AvatarHeaderCell = ({ user, onClick, clickable }) => {
  const fullName = getFullName(user)
  const email = fullName === user.email ? null : user.email
  return (
    <TableCell sx={{ padding: 3 }}>
      <CardHeader
        avatar={<ProfileAvatar user={user} />}
        title={fullName}
        subheader={email}
        sx={{ padding: 0, cursor: clickable ? 'pointer' : 'default' }}
        onClick={onClick}
      />
    </TableCell>
  )
}

export default AvatarHeaderCell
