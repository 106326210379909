import { Container, Stack, Typography } from '@mui/material'
import colors from 'styles/colors'
import RowStack from 'components/atoms/container/RowStack'
import { useTranslation } from 'react-i18next'

const ReportPage = ({
  children,
  pageNum = null,
  assessmentName = null,
  containerStyle = {},
  childrenWrapperSx = {},
  downloadingFile
}) => {
  const { t } = useTranslation()
  const typoSx = {
    textAlign: 'center',
    color: colors.MidnightTeal400
  }
  return (
    <Container
      style={{
        width: '780px',
        height: '1054px',
        padding: '27px 64px',
        border: downloadingFile ? 'none' : `1px solid ${colors.Grey}`,
        position: 'relative',
        overflow: 'hidden',
        ...containerStyle
      }}>
      <>
        {(assessmentName || pageNum) && (
          <RowStack
            sx={{
              marginBottom: 19,
              justifyContent: assessmentName ? 'space-between' : 'flex-end'
            }}>
            {assessmentName && (
              <Typography variant='body1Micro' sx={typoSx}>
                {`${assessmentName} ${t('rolePlay.results')}`}
              </Typography>
            )}
            {pageNum && (
              <Typography variant='body1Micro' sx={typoSx}>
                {pageNum}
              </Typography>
            )}
          </RowStack>
        )}

        <Stack padding={0} height={'calc(100% - 55px)'} sx={childrenWrapperSx}>
          {children}
        </Stack>
      </>
    </Container>
  )
}

export default ReportPage
