import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const BackIconTextButton = ({ onClick }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Typography
      alignItems='center'
      display='flex'
      variant='h3'
      onClick={onClick ? onClick : () => navigate(-1)}
      sx={{ '&:hover': { cursor: 'pointer' }, zIndex: 99 }}>
      <KeyboardBackspaceIcon sx={{ marginRight: '5px' }} />  {t('common.back')}
    </Typography>
  )
}

export default BackIconTextButton
