import {
    Dialog,
    DialogTitle,
    IconButton,
    Stack
  } from '@mui/material'
  import { Close } from '@mui/icons-material'
  import MicrosoftCalendar from './MicrosoftCalendar'
  import GoogleCalendarLogin from './GoogleCalendar'
  import { useTranslation } from 'react-i18next'
  
  const LinkCalendarDialog = ({ handleSigninResult, dialogOpen, setDialogOpen }) => {
    const { t } = useTranslation()
    return (
        <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
            <DialogTitle>
                {t('calenderEvents.linkMyCalendar')}
                <IconButton
                    sx={{ float: 'right', paddingTop: '2px' }}
                    onClick={() => setDialogOpen(false)}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Stack spacing={5}>
                <MicrosoftCalendar signinCallback={handleSigninResult} />
                <GoogleCalendarLogin signinCallback={handleSigninResult} />
            </Stack>
        </Dialog>
    )
  }
  
  export default LinkCalendarDialog
  