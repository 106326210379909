import {
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow
} from '@mui/material'

const PresetTableContainer = ({
  headers,
  children,
  maxHeight = '600px',
  minHeight = '200px',
  otherSX,
  shorterRow = false,
  tableProps = {}
}) => {
  return (
    <TableContainer
      sx={{
        maxHeight,
        minHeight,
        ...otherSX,
        '& .MuiTableCell-root': { paddingY: shorterRow ? 4 : undefined }
      }}>
      <Table {...tableProps}>
        {headers ? (
          <TableHead>
            <TableRow sx={{ '& .MuiTableCell-root': { lineHeight: '1rem' } }}>
              {headers}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default PresetTableContainer
