import { useEffect, useState } from 'react'
import {
  Typography,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Icon
} from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import RowStack from 'components/atoms/container/RowStack'
import colors from 'styles/colors'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import { Tag } from 'components/atoms/Text'
import moment from 'moment'
import { StyledSelect } from 'components/atoms/input/StyledInputs'
import RadarGraph from 'components/molecules/assessments/RadarGraph'
import { useTranslation } from 'react-i18next'

const AssessmentResultSummary = ({ data, closingTimeStamp }) => {
  const { t } = useTranslation()
  const [models, setModels] = useState([])
  const [selectedModel, setSelectedModel] = useState(
    data?.assessmentCategories?.[0]?.modelName
  )
  const [filteredCategories, setFilteredCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState(
    data?.assessmentCategories?.[0]
  )

  useEffect(() => {
    if (data) {
      const newModals = [
        ...new Set(data?.assessmentCategories?.map(c => c?.modelName))
      ].sort((a, b) => a - b)
      setModels(newModals)
    }
  }, [data])

  useEffect(() => {
    if (selectedModel) {
      const newFilteredCategories = data?.assessmentCategories?.filter(
        c => c?.modelName === selectedModel
      )
      setFilteredCategories(newFilteredCategories)
      setActiveCategory(newFilteredCategories?.[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModel])

  return (
    <>
      {data && (
        <>
          <BoxWithBorder
            width={'100%'}
            borderRadius={4}
            borderWidth={1}
            borderColor={colors.LightGrey200}
            style={{ marginBottom: 20 }}>
            <RowStack
              padding={8}
              justifyContent={'space-between'}
              borderBottom={`1px solid ${colors.LightGrey200}`}>
              <InCardStack spacing={4} alignItems={'flex-start'}>
                <Typography variant='h2'>
                  {t('fullReport.assessmentResultsSummary')}
                </Typography>
                <Tag style={{ padding: 4 }}>
                  {t('common.completed')}:{' '}
                  {moment(closingTimeStamp).format('MMMM DD, YYYY')}
                </Tag>
              </InCardStack>

              <StyledSelect
                sx={{ minWidth: 250 }}
                displayEmpty
                value={selectedModel}
                onChange={e => setSelectedModel(e.target.value)}>
                {models.map((model, index) => (
                  <MenuItem value={model} key={`model-${index}`}>
                    {model}
                  </MenuItem>
                ))}
              </StyledSelect>
            </RowStack>

            <RowStack alignItems={'flex-start'}>
              <List
                sx={{
                  minWidth: '264px',
                  padding: 0
                }}>
                {filteredCategories.map((category, index) => (
                  <ListItem disablePadding key={`category-${index}`}>
                    <ListItemButton
                      selected={activeCategory?.id === category?.id}
                      onClick={event => setActiveCategory(category)}
                      sx={{
                        padding: 8,
                        '&.Mui-selected .MuiTypography-root': {
                          color: colors.Teal400
                        }
                      }}>
                      <ListItemText
                        sx={{ '& .MuiTypography-root': { fontWeight: 500 } }}>
                        <RowStack justifyContent={'space-between'}>
                          {category.name}

                          {category.id === activeCategory?.id && (
                            <Icon>chevron_right</Icon>
                          )}
                        </RowStack>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

              <InCardStack
                width={'100%'}
                spacing={0}
                style={{
                  borderLeft: `1px solid ${colors.LightGrey200}`,
                  padding: 32,
                  marginLeft: 0
                }}>
                <RowStack justifyContent={'space-between'}>
                  <Typography variant='h2'>{activeCategory?.name}</Typography>
                  <Tag
                    sx={{
                      padding: 6,
                      background: colors.LightGrey100,
                      borderRadius: 3,
                      fontSize: 16,
                      fontWeight: 600,
                      color: colors.Teal400
                    }}>
                    {t('fullReport.overall')}:{' '}
                    {activeCategory?.data.selfAllBehaviourAvg?.toFixed(2) || 0}
                  </Tag>
                </RowStack>

                <RadarGraph
                  assessmentData={{
                    goalData: data.goalData,
                    ...activeCategory?.data
                  }}
                  disableBehaviorClick={true}
                  showScoreLabel={true}
                />

                <Typography
                  variant='body1'
                  sx={{ color: colors.Grey400, textAlign: 'center' }}>
                  {activeCategory?.description}
                </Typography>
              </InCardStack>
            </RowStack>
          </BoxWithBorder>
        </>
      )}
    </>
  )
}
export default AssessmentResultSummary
