// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./InterVariable.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./InterVariable-Italic.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Inter';
  src: local(Inter), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local(Inter-Italic), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./src/styles/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,0EAA+D;EAC/D,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,iFAA6E;EAC7E,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: 'Inter';\n  src: local(Inter), url('./InterVariable.woff2') format('woff2');\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Inter';\n  src: local(Inter-Italic), url('./InterVariable-Italic.woff2') format('woff2');\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
