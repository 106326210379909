import { Button } from '@mui/material'

const ShortButton = ({ sx, children, ...otherProps }) => {
  return (
    <Button sx={[{ paddingY: 2 }, sx]} {...otherProps}>
      {children}
    </Button>
  )
}

export default ShortButton
