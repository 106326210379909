export const translationFr = {
  english: 'Anglais',
  french: 'Français',
  constants: {
    userConnRelationship: {
      direct_report: 'Subordonné direct',
      direct_supervisor: 'Superviseur direct',
      senior_leader: 'Responsable principal',
      colleague: 'Collègue',
      other: 'Autres'
    },
    userRoleTypes: {
      admin: 'Administrateur',
      restricted: 'Limité',
      manager: 'Directeur',
      coach: 'Entraîneur'
    }
  },
  headers: {
    home: 'Accueil',
    journey: 'Mon parcours',
    feedback: 'Retour d’information',
    about: 'À propos',
    overview: 'Vue d’ensemble',
    company: 'Entreprise',
    bookmarks: 'Signets',
    notebook: 'Carnet',
    adminDashboard: 'Tableau de bord de l’administrateur',
    internalDashboard: 'Tableau de bord interne',
    monarkHome: 'Accueil Monark',
    verifyEmail: 'Vérifier le courriel',
    profile: 'Profil',
    myTeam: 'Mon équipe',
    myOrganization: 'Mon organisation',
    settings: 'Réglages',
    languages: 'Langues',
    helpSupport: 'Aide et assistance',
    logout: 'Déconnexion',

    // sub headers
    currentPath: 'Parcours actuel',
    leadershipModel: 'Modèle de leadership',
    assessments: 'Évaluations',
    pulseSurvey: 'Sondages instantanés',
    userFeedback: 'Retour d’information de l’utilisateur',
    about360: 'En savoir plus sur les résultats des évaluations à 360°',
    practice: 'Pratiques',
    aboutConfidentiality: 'Confidentialité',
    ohepSurveys: "Sondages de l’OHEP",
    learningCheckQuizzesResults: "Résultats des questionnaires de contrôle de l’apprentissage",
    userProgressReport: "Rapport d’activité de l’utilisateur",

    // Internal pages, not translation needed
    contentEditor: 'Rédacteur de contenu',
    subscriptions: 'Abonnements',
    ohep: 'Sondages de l’OHEP',
    demoGoals: 'Objectifs de démonstration',
    paths: 'Parcours',
    reportExport: 'Rapport et exportation',
    features: 'Caractéristiques',
    demoAccounts: 'Comptes de démonstration',
  },
  common: {
    send: 'Envoyer',
    to: 'À',
    back: 'Retour',
    seeAll: 'Voir tout',
    learnMore: 'En savoir plus',
    noThanks: 'Non merci',
    sendMessage: 'Envoyer un message',
    save: 'Sauvegarder',
    cancel: 'Annuler',
    done: 'Fait',
    notFound: 'Introuvable',
    ok: 'OK',
    saved: 'Sauvegardé',
    submit: 'ENVOYER',
    continue: 'Continuer',
    skip: 'Sauter',
    date: 'Date',
    from: 'De',
    more: 'plus',
    add: 'Ajouter'
  },
  welcomeArea: {
    welcome: 'Bienvenue',
    heading: "Qu’est-ce que vous voulez faire aujourd’hui?",
    startNewPath: 'Commencer un nouveau parcours',
    continueLearning: "Poursuivre l’apprentissage",
    viewCompletedPath: 'Voir le parcours complété',
    startLearning: 'Commencer à apprendre',
    sendFeedback: 'Envoyer un retour d’information',
    raterOnlyAccount: "Vous avez un compte d’évaluateur uniquement, ce qui signifie que vous ne pouvez évaluer que les autres utilisateurs qui vous invitent à répondre à leurs demandes d’évaluation à 360° et",
    learnMoreAboutMonark: "Si vous souhaitez en savoir plus sur l’ensemble du contenu et des fonctionnalités de Monark, et notamment sur la manière dont vous pouvez envoyer des demandes de commentaires à vos pairs, contactez-nous.",
    raterOnlySubject: "Je suis un utilisateur « Évaluateur uniquement » et j’aimerais en savoir plus."
  },
  home: {
    newLearningPathAssignment: "Nouvelle affectation de parcours d’apprentissage.",
    startMyPath: 'Commencer mon parcours',
    companyAssignedYouNewLearningPath: 'Votre entreprise vous a assigné un nouveau parcours d’apprentissage.',
    automaticallyTakenToSelfAssessment: 'Vous serez automatiquement dirigé vers l’auto-évaluation pour commencer votre nouveau parcours. Tous les progrès que vous avez réalisés sur votre parcours actuel seront sauvegardés, et vous pourrez revenir à tout moment.',
    clickStartToBegin: 'Cliquez sur Démarrer mon parcours pour commencer.'
  },
  goalsArea: {
    goalsToComplete: 'Objectifs à atteindre',
    dueDate: "Date d’échéance",
    notSet: 'Non défini',
    gotoMyGoals: 'Aller à mes objectifs',
    currentGoalRatings: 'Évaluations des objectifs actuels',
    learnMoreAbout360Results: 'En savoir plus sur les résultats 360°',
    overallScore: 'NOTE GLOBALE',
    self: 'Soi',
    others: 'Autres',
    clickBehaviours: "Cliquez sur les comportements ci-dessus pour obtenir une analyse plus détaillée.",
    selfRating: 'Auto-évaluation',
    othersRating: 'Évaluation par les autres'
  },
  goalStepPaths: {
    encouragementPopupTitle: 'Opportunité d\'apprentissage'
  },
  calenderEvents: {
    calendar: 'Calendrier',
    connectedCalendar: 'Calendrier connecté',
    upcomingReminders: 'Rappels à venir',
    unableToConnect: {
      part1: "Nous n’avons pas pu connecter votre calendrier. Veuillez vous rendre sur la",
      part2:
        'page et réessayer. Si le problème persiste, veuillez contacter votre administrateur informatique afin qu’il autorise Monark à se connecter à votre calendrier.'
    },
    connectionExpired: "Votre connexion au calendrier a expiré. Veuillez vous reconnecter à votre fournisseur de calendrier.",
    noUpcomingReminders: "Il n’y a pas de rappels à afficher car votre calendrier n’est pas lié à Monark. En liant votre calendrier, vous verrez les événements de leadership à venir mis en évidence ici.",
    linkMyCalendar: 'Lier mon calendrier',
    linkYourCalendar: 'Liez votre calendrier',
    instantlyConnectCalendar: "Vous savez que vous pouvez instantanément connecter votre calendrier à Monark afin de visualiser et de gérer les événements de leadership à venir sans quitter l’application?",
    giveItTry: 'Essayez-le',
    connectMicrosoft: 'Se connecter avec Microsoft',
    connectGoogle: 'Se connecter avec Google Agenda (Google Calendar)',
    editNotes: 'Modifier les notes',
    addNotes: 'Ajouter des notes',
    eventNotes: "Notes sur l’événement",
    yourNotePlaceholder: 'Votre note pour cet événement',
    unableToConnectCalendarTryAgain: "Nous n’avons pas pu connecter votre calendrier. Veuillez réessayer. Si le problème persiste, veuillez contacter votre administrateur informatique afin qu’il autorise Monark à se connecter à votre calendrier.",
    linkingYourCalendar: "Lier votre calendrier vous permet de visualiser les événements importants liés au leadership directement sur votre écran d’accueil.",
    IiDisconnectCalendar: "Si vous déconnectez votre calendrier, vous ne verrez plus les événements à venir liés au leadership s’afficher dans l’application.",
    disconnectCalendar: 'Déconnecter le calendrier',
    requestDone: 'Demande effectuée',
    youDisconnectedCalendar: 'Vous avez déconnecté votre calendrier.'
  },
  requestRetryAlert: {
    message: "La demande a échoué! Veuillez réessayer."
  },
  feedbackArea: {
    openFeedbackRequests: 'Demandes de retour d’information ouvertes',
    _360Requests: 'Demandes de 360°',
    recentFeedback: 'Retour d’information récent',
    noFeedbackFromTeam: "Vous n’avez actuellement aucun commentaire de la part de vos coéquipiers.",
    noFeedbackFromColleagues: "Vous n’avez pas encore de retour d’information de la part de vos collègues. Demandez-leur de vous tenir au courant de vos progrès!",
    gotoFeedback: 'Aller au retour d’information',
    reply: 'Réponse',
    yourReply: 'Votre réponse',
    feedbackError: "Échec de l’envoi du retour d’information. Veuillez réessayer plus tard.",
    feedbackSuccess: 'Retour d’information envoyé!',
    selectRecipient: 'Sélectionnez un destinataire',
    noMatchUserFound: 'Aucun utilisateur correspondant trouvé',
    failedLoadUserConnections: 'Échec du chargement des connexions utilisateur',
    loadingConnections: 'Chargement des connexions',
    yourMessage: 'Votre message'
  },
  teamShoutOut: {
    heading: "Mentions spéciales d’équipe",
    commentValidation: 'Le retour d’information doit être inférieur à 1024 caractères.',
    postedBy: 'Posté par',
    shoutOutToTeam: 'Faites une mention spéciale à vos coéquipiers pour leur excellent travail! Les messages postés ici seront visibles par l’ensemble de votre équipe.',
    noTeamShouts: "Aucune mention spéciale d’équipe n’a encore été publiée.",
    post: 'Post',
    postToWall: 'Poster sur le mur',
    comments: 'Commentaires'
  },
  shareMyResults: {
    heading: 'Partager mes résultats',
    recpMinValidation: 'Veuillez sélectionner ou ajouter au moins un destinataire.',
    recpEmailValidation: "Veuillez saisir une adresse électronique valide",
    resultsSentSuccess: 'Vos résultats ont été envoyés!',
    selectRecipients: 'Sélectionnez les destinataires',
    recpPlaceholder: 'Sélectionnez une connexion ou un courriel d’entrée',
    sendNow: 'Envoyer maintenant'
  },
  raters: {
    addMore360Raters: "Ajouter des évaluateurs 360°"
  },
  requestErrorPageCard: {
    title: "Oups, une erreur s’est produite!",
    text: 'Désolé, nous ne pouvons pas récupérer les informations demandées pour le moment. Veuillez réessayer plus tard!'
  },
  assessmentBehaviour: {
    avgRating: 'Moyenne note',
    questions: 'Questions'
  },
  profile: {
    editProfile: 'Modifier le profil',
    finishProfile: 'Terminer le profil',
    role: 'Rôle',
    industry: 'Secteur d’activité',
    department: 'Service',
    currentLevelOfSeniority: 'NIVEAU ACTUEL D’ANCIENNETÉ',
    workExperience: "EXPÉRIENCE PROFESSIONNELLE",
    pathKeepTrack: 'Gardez la trace de tout ce que vous avez fait, et regardez ce que vous avez appris.',
    noPathYet: 'Vous n’avez encore accompli aucun parcours d’apprentissage. Consultez nos modèles de leadership et choisissez un parcours d’apprentissage pour commencer.',
    startNewPath: 'Commencer un nouveau parcours d’apprentissage.',
    uploadPhoto: 'télécharger la photo',
    enterYourFirstName: 'Entrez votre prénom',
    enterYourLastName: 'Entrez votre nom de famille',
    yourDepartment: 'Votre service… ',
    yourRole: 'Votre poste… ',
    changesSaved: 'Changements sauvegardés',
    changePassword: 'Modifier le mot de passe',
    requestSubmitted: 'Demande envoyée!',
    checkYourEmail: 'Veuillez vérifier votre boîte de réception et suivre les instructions pour mettre à jour votre mot de passe.',
    deleteMyAccount: 'Supprimer mon compte',
    areYouSure: 'Êtes-vous sûr(e)?',
    sorryToSeeYouGo: 'Nous sommes désolés de vous voir partir, revenez à tout moment et reprenez là où vous vous êtes arrêté.',
    keepMyAccount: 'Non, laissez mon compte ouvert',
    closeMyAccount: 'Oui, fermez mon compte',
    accountClosed: 'Compte fermé'
  },
  pathsAndModels: {
    currentPath: 'Parcours actuel',
    path: 'Parcours',
    milestone: 'Jalon',
    learningPaths: 'Parcours d’apprentissage',
    viewPath: 'Voir le parcours',
    beginPath: 'Commencer le parcours',
    locked: 'Verrouillé',
    unlock: 'Déverrouiller',
    assessments: 'Évaluations',
    surveys: 'Sondages',
    pathInProgress: 'Parcours d’apprentissage en cours',
    startNewPathNotificationText: {
      part1: 'Vous travaillez actuellement sur le parcours d’apprentissage ',
      part2:
        "et vous ne l’avez pas encore achevé. Si vous changez de parcours, votre progression sera sauvegardée et vous pourrez reprendre là où vous vous étiez arrêté lorsque vous reviendrez. Voulez-vous commencer un nouveau parcours maintenant?"
    },
    oneRangePurchaseText: {
        title: 'Achat OneRange',
        part1: 'Vous avez acheté votre licence d’apprentissage avec Monark via OneRange. Il semble que vous n’ayez pas acheté ce parcours d’apprentissage.',
        part2:
          'Visitez OneRange pour acheter l’accès à ce parcours d’apprentissage.',
          visit: 'Visiter OneRange'
      },
    confirmStartPath: 'Oui, commencez un nouveau parcours',
    doNotHaveResource: "Il semble que nous n’ayons pas de ressources pour cet objectif",
    notAbleLoadResource: "Désolé, nous n’avons pas pu charger la ressource. Veuillez réessayer plus tard",
    congratulations: 'Félicitations',
    youJustCompleted: 'Vous venez de terminer',
    senseOfNewSkills: {
      part1: 'Pour avoir une idée de la manière dont ces nouvelles compétences vous aideront en tant que dirigeant dans les semaines et les mois à venir, nous vous recommandons de procéder à une nouvelle auto-évaluation ',
      part2: ' jours ',
      part3: "après avoir suivi ce parcours de formation."
    },
    makeItEasy: 'Pour vous faciliter la tâche, nous avons programmé un rappel. Vous recevrez une notification vous invitant à passer l’évaluation à cette date.',
    reassessmentReminder: 'Un rappel de réévaluation sera envoyé le ',
    completedDemoPath: 'Vous avez terminé le parcours de démonstration!',
    alreadyCompleted: 'Vous avez déjà terminé',
    reflectOnWhatYouLearned: 'Alors que vous réfléchissez à ce que vous avez appris et que vous continuez à mettre en pratique vos nouvelles compétences, c’est le moment idéal pour obtenir un retour d’information sur vos progrès en envoyant à vos pairs un sondage rapide comportant une seule question.',
    sendOutSurvey: 'Envoyez le sondage maintenant',
    requestMeeting: 'Demander une réunion',
    clickBookmarkContent: 'Cliquez pour ajouter un signet au contenu',
    clickRemoveBookmark: 'Cliquez pour supprimer le signet',
    getInstantFeedback: 'Obtenez un retour d’information instantané!',
    sendPulseSurvey: 'Envoyer un sondage instantané',
    partWayThroughLearning: {
      part1: 'Maintenant que vous êtes à mi-chemin de votre parcours d’apprentissage, c’est le moment idéal pour envoyer un ',
      part2: 'Sondage instantané',
      part3: 'pour obtenir un retour d’information sur vos progrès!'
    },
    useThisQuestion: 'Utilisez cette question telle quelle ou modifiez-la pour la faire vôtre. Ensuite, sélectionnez vos destinataires, cliquez sur Envoyer et regardez les réponses arriver!',
    scheduleTimeLearn: 'Prévoyez du temps pour apprendre',
    customize: 'Personnalisez',
    spendingTimeLearning: 'En consacrant chaque jour un peu de temps à l’apprentissage et à la mise en pratique de comportements, vous avez plus de chances de retenir l’information et d’atteindre vos objectifs.',
    useTheSettings: 'Utilisez les paramètres pour définir des rappels d’apprentissage personnalisés. Vous pouvez modifier ces paramètres à tout moment.',
    coveredLotContent: 'Vous avez abordé un grand nombre de sujets!',
    movingThroughContentQickly: 'Nous avons remarqué que vous parcourez le contenu très rapidement. Bien que nous soyons ravis de voir votre enthousiasme, pour tirer le meilleur parti de Monark, nous vous recommandons de prendre le temps de réviser et d’appliquer les concepts que vous avez appris avant de passer au sujet suivant.',
    startYourLearningJourney: 'Commencer votre parcours d’apprentissage',
    chooseAnItem: 'Choisissez un élément pour voir votre progression et les autres parcours d’apprentissage disponibles.'
  },
  assessmentTimeline: {
    takeNewSelfassessment: 'Faire une nouvelle auto-évaluation',
    inviteOthersAssess: 'Inviter les autres à vous évaluer',
    historicalRatings: 'Historique des évaluations',
    closingOn: 'Ferme le ',
    closedOn: 'Fermé le ',
    assessingYourChange: 'Évaluer votre changement',
    overallAverageRating: 'Note moyenne globale',
    completeMoreAssessments: 'Complétez davantage d’évaluations pour construire des données de comparaison',
    change: 'Changer',
    behaviours: 'Comportements',
    rating: 'Note',
    noComparisonData: 'Aucune donnée de comparaison n’est disponible',
    clickVariousBehaviours: 'Cliquez sur les différents comportements pour obtenir une analyse détaillée de l’évolution de vos notes d’évaluation.',
    noAssessmentData: 'Vous n’avez pas encore de données d’évaluation, faites des évaluations pour obtenir des données sur vous-même!',
    alreadyCompletedSelfassessment: 'Vous avez déjà réalisé une auto-évaluation pour ce parcours d’apprentissage au cours des deux dernières semaines. Veuillez attendre au moins deux semaines avant de procéder à une nouvelle auto-évaluation.',
    notCompletedSelfassessment: 'Vous n’avez pas effectué d’auto-évaluation pour ce parcours d’apprentissage au cours des deux dernières semaines. Afin d’inviter d’autres personnes à vous évaluer, veuillez d’abord procéder à une auto-évaluation, puis inviter d’autres personnes à vous évaluer.',
    doSelfAssessmentNow: 'Faites votre auto-évaluation maintenant',
    notRightNow: 'Pas maintenant',
    addMore360Raters: 'Vous pouvez maintenant ajouter plus d’évaluateurs à 360° à votre évaluation ouverte.',
    addRaters: 'Ajouter des évaluateurs',
    returnHome: 'Retour à l’accueil',
    completingSelfAssessment: {
      part1: 'Bravo pour avoir terminé',
      part2: 'votre auto-évaluation!'
    },
    selfAssessmentReport: 'Rapport d’auto-évaluation',
    currentlySelfAssessment: 'Vous êtes en train de remplir une auto-évaluation',
    howYouScored: 'Vérifiez votre note pour chacun des comportements mesurés dans votre auto-évaluation ci-dessous (pour rappel, vous vous êtes évalué sur une échelle de 1 à 5, où 1 = pas du tout d’accord, et 5 = tout à fait d’accord).',
    nextStep: 'Prochaine étape',
    capturingBaseline: 'Capturer votre ligne de base',
    captureBaselineMeasurement: 'Nous allons prendre une mesure de référence pour savoir où vous en êtes aujourd’hui.',
    fewMinutesToAnswer: 'Prenez quelques minutes pour répondre à quelques questions sur votre comportement habituel. Ne vous inquiétez pas, personne d’autre que vous ne verra ces évaluations. Votre organisation ne peut voir les progrès que vous faites qu’une fois que vous êtes dans le parcours d’apprentissage!',
    beginSelfAssessment: 'Commencer l’auto-évaluation',
    selfAssessment: 'Auto-évaluation',
    assessmentOnly: {
      part1: 'Cette évaluation comporte seulement',
      part2: 'questions.'
    },
    submitError: 'Un problème s’est produit, veuillez réessayer.'
  },
  leadership: {
    monarkLeadershipModel: 'Modèle de leadership Monark',
    chooseAnItem: 'Choisissez un élément pour voir votre progression et les autres parcours d’apprentissage disponibles.'
  },
  addRaters: {
    inviteOthersToRate: 'Inviter les autres à vous évaluer',
    details: {
      item1: {
        title: 'Pourquoi dois-je envoyer des demandes d’évaluation à 360°?',
        description: 'Obtenez une vision équilibrée de vos forces et de vos faiblesses potentielles en matière de leadership.'
      },
      item2: {
        title: 'À qui dois-je envoyer les demandes?',
        description: 'Choisissez des collègues, des superviseurs, des subordonnés directs ou d’autres personnes connaissant votre comportement au travail.'
      },
      item3: {
        title: 'Comment ajouter des évaluateurs?',
        description: 'Il suffit de saisir leur courriel et leur relation; il n’est pas nécessaire qu’ils soient des utilisateurs de l’application et ils peuvent être extérieurs à votre organisation.'
      },
      item4: {
        title: 'Quand verrai-je les résultats?',
        description: 'Pour protéger l’anonymat, vous ne verrez les résultats que lorsque vous aurez reçu des évaluations d’au moins trois personnes.'
      }
    },
    tellMeMore: 'Expliquez-moi comment réaliser des évaluations à 360°',
    minimum3ConnValidation: 'Veuillez sélectionner ou fournir un minimum de 3 contacts valides.',
    validConnError: 'Veuillez sélectionner ou fournir des contacts valides.',
    required: 'Obligatoire',
    relationshipTypeValidation: 'Veuillez sélectionner un type de relation pour chaque évaluateur.',
    emailAlreadyInInvite: 'Ce courriel a déjà été ajouté à votre liste d’invités',
    emailAlreadyInRaters: 'Ce courriel figure déjà dans votre liste d’évaluateurs',
    new: 'Nouveau',
    connection: 'Contact',
    nameOrWorkEmail: 'Nom ou courriel professionnel',
    select: 'Sélectionner',
    emailsSent: 'Courriels envoyés',
    emailSuccessfullySent: 'Vos courriels ont été envoyés. Si, à tout moment, vous souhaitez revenir en arrière et ajouter d’autres évaluateurs, visitez votre page de profil.',
    notWaitForResults: 'N’oubliez pas : vous n’avez pas besoin d’attendre vos résultats pour commencer! Vous pouvez commencer votre périple dès maintenant et vous serez informé dès que vos résultats d’évaluations à 360° seront disponibles.',
    startYourJourney: 'Commencez votre périple',
    ratersNotSelected: 'Vous n’avez pas encore sélectionné d’évaluateurs à 360° et vous n’avez donc pas encore de données à afficher.',
    invitedRaters: {
      part1: 'Vous avez invité',
      part2: 'évaluateurs pour cette évaluation.'
    },
    relationship: 'Relation',
    addMoreRaters: 'Ajouter d’autres évaluateurs',
    skipForNow: 'Sauter pour l’instant',
    getMostFromResults: 'Tirez le meilleur parti de vos résultats',
    getStarted: 'Commencer',
    nextStepToGetOthersRate: 'L’étape suivante consiste à demander aux autres de vous évaluer dans le cadre d’une évaluation à 360°. Cela vous permettra de mieux comprendre comment vous vous présentez dans votre rôle au travail.',
    importantToNote: 'Il est important de noter qu’une évaluation à 360° est conçue pour mesurer vos forces et vos faiblesses, et non vos performances.',
    rateAndCompare: 'Lorsque vous vous évaluez et que vous comparez ces évaluations à celles fournies par d’autres, vous avez une meilleure connaissance de vous-même, ce qui est un ingrédient clé pour changer de comportement!'
  },
  stepNote: {
    activitiesAndReflection: 'Activités et réflexion',
    recordResponse: 'Enregistrer une réponse',
    setGoal: 'Fixer un objectif',
    setReminder: 'Définir un rappel',
    addDateToCalendar: 'Ajouter la date d’échéance à mon calendrier',
    greatJobCompletedGoal: 'EXCELLENT TRAVAIL! Vous avez atteint cet objectif!',
    IDidIt: 'J’AI RÉUSSI!',
    takeNotes: 'Prenez des notes',
    takeSomeNotes: 'Prenez des notes',
    sources: 'Sources',
    errorRetrievingContent: 'Erreur de récupération du contenu, veuillez réessayer plus tard'
  },
  stepQuiz: {
    answerAllQuestionsValidation: 'Veuillez répondre à toutes les questions avant d’envoyer',
    workValuesSurvey: 'Sondage sur les valeurs professionnelles',
    result: 'Résultat',
    score: 'Score',
    youScored: 'Vous avez obtenu la note ',
    on: 'activé',
    correct: 'Bonne réponse!',
    incorrectAnswer: 'Mauvaise réponse. La bonne réponse est',
    true: 'Vrai',
    or: 'Ou',
    false: 'Faux',
    multipleChoice: 'Choix multiple',
    pleaseTryAgain: 'Veuillez réessayer!',
    matchedCorrectly: {
      part1: 'Félicitations! Vous avez correctement fait correspondre les éléments.',
      part2: 'Veuillez examiner toutes les réponses ci-dessous.'
    },
    correctAnswers: 'Réponses correctes',
    reviewMyResults: 'Examiner mes résultats'
  },
  stepFeedback: {
    checkIn: 'Enregistrement',
    EnjoyedLearningAbout: 'J’ai aimé en apprendre plus sur',
    thankYouForFeedback: 'Merci pour vos commentaires!',
    completeMilestone: 'Compléter le jalon',
    beforeYouMoveOn: 'Avant de passer à autre chose… ',
    takeMomentToRate: 'Voulez-vous prendre un moment pour évaluer cette étape? Vos commentaires nous aideront à améliorer l’expérience de l’utilisateur.',
    rateNow: 'Évaluer maintenant',
    stronglyDisagree: 'Pas du tout d’accord',
    neutral: 'Neutre',
    stronglyAgree: 'Tout à fait d’accord',
    dontAskMeAgain: 'Ne me le redemandez pas'
  },
  about360: {
    heading: 'Interpréter vos résultats à 360°',
    why360: 'Pourquoi un 360°?',
    why360Text: {
      part1: 'Les évaluations à 360° sont souvent utilisées dans le cadre de programmes coûteux de développement du leadership, mais quel en est l’intérêt? Une évaluation à 360° vous permet de comprendre comment les autres perçoivent votre comportement dans un certain nombre de domaines clés. En intégrant de multiples perspectives (donc 360°), vous obtenez une description plus précise de la façon dont vous vous présentez au travail.',
      part2: 'De plus, lorsque vous vous évaluez vous-même et que vous comparez votre auto-évaluation à celle des autres, vous avez une meilleure connaissance de vous-même. La conscience de soi (en particulier la conscience de soi externe), c’est-à-dire la mesure dans laquelle vous comprenez comment les autres vous voient, est le point de départ d’un changement de comportement.'
    },
    interpretResults: 'Interprétation des résultats',
    interpretResultsText: {
      para: 'Cette évaluation sert de point de départ à votre parcours de développement du leadership. Elle est conçue pour vous donner une idée de votre point de départ dans un certain nombre de domaines clés en rapport avec votre objectif. Il y a quelques points à garder à l’esprit lorsque vous examinez vos résultats',
      itmes: {
        item1: {
          part1: 'Cette évaluation se concentre sur les comportements qui peuvent être ',
          part2: 'appris ',
          part3: 'et ',
          part4: 'développés.'
        },
        item2: {
          part1: 'Vos résultats ',
          part2: 'ne ',
          part3: 'reflètent pas ce que vous êtes en tant que personne; ils décrivent simplement votre engagement dans certains comportements jusqu’à ce stade.'
        },
        item3: {
          part1: 'Il ne faut pas voir cela comme une ',
          part2: 'évaluation de la performance',
          part3: ', cela est destiné à être utilisé uniquement comme un outil de développement.'
        }
      }
    },
    whatYouSee: 'Ce que vous verrez dans vos résultats à 360°',
    whatYouSeeItems: {
      item1: {
        part1: 'Auto-évaluation : ',
        part2: 'Il s’agit de la note que vous vous êtes attribuée en remplissant l’auto-évaluation.'
      },
      item2: {
        part1: 'Évaluation des autres : ',
        part2: 'Il s’agit d’une moyenne des notes attribuées par toutes les personnes qui ont été invitées à vous évaluer.'
      }
    },
    understandingBlindspots: 'Comprendre les angles morts et les forces cachées',
    understandingBlindspotsItems: {
      item1: {
        part1: 'Les angles morts ',
        part2: 'sont des domaines dans lesquels vous vous êtes mieux évalué que les autres, et donc vos croyances concernant vos performances dans un domaine particulier dépassent celles des personnes qui vous entourent. Il en résulte parfois un décalage entre vos intentions et vos comportements tels qu’ils sont perçus par les autres.'
      },
      item2: {
        part1: 'Les forces cachées ',
        part2: 'sont des domaines dans lesquels vous vous êtes moins bien évalué pour un comportement donné par rapport aux évaluations fournies par d’autres, ce qui entraîne une sous-estimation de vos propres performances. Il s’agit de compétences potentielles dont vous n’avez peut-être pas pleinement conscience. Elles sont déjà en vous, elles ne demandent qu’à être renforcées!'
      }
    }
  },
  confidentiality: {
    heading: 'Confidentialité',
    part1: 'Nous prenons un certain nombre de mesures pour préserver l’anonymat et la confidentialité des personnes qui fournissent des évaluations et des commentaires sur Monark.',
    part2: 'Pour l’évaluation à 360°, nous ne divulguons pas les noms des personnes qui fournissent les évaluations. En outre, pour chaque comportement/question, nous n’indiquons qu’une note moyenne pour l’ensemble des évaluateurs et nous ne le faisons que lorsqu’au moins trois évaluateurs ont fourni des évaluations.',
    part3: 'Il s’agit de protéger l’anonymat de la personne qui attribue les notes et de garantir un retour d’information le plus honnête et le plus précis possible à la personne qui reçoit les évaluations. Le même processus est appliqué aux sondages instantanés.',
    part4: 'Toutefois, veuillez noter que pour l’option de retour d’information qualitatif (commentaires ouverts envoyés et reçus par les utilisateurs), tous les commentaires fournis sont attachés au nom de l’utilisateur. Une manière simple de différencier ces différentes méthodes est que les données quantitatives seront toujours fournies sous forme de moyenne et de manière anonyme, alors que le retour d’information qualitatif n’est pas anonyme.',
    gotIt: 'Compris'
  },
  pulseSurvey: {
    sendPulseSurvey: 'Envoyer un sondage instantané',
    surveyRequest: 'Demande de sondage instantané',
    backtoPulseSurvey: 'Retour aux sondages instantanés',
    yourResponseRecorded: 'Votre réponse a été enregistrée',
    pleaseSelectQuestion: 'Veuillez sélectionner ou taper une question.',
    pleaseSelectMinRecipients: 'Veuillez sélectionner un minimum de trois destinataires.',
    recipientsWillAnswer: {
      part1: 'Les destinataires répondront à votre question sur une échelle de 1 (pas du tout d’accord) à 5 (tout à fait d’accord).',
      part2: 'Votre sondage restera ouvert pendant une semaine, ou jusqu’à ce que tous les évaluateurs aient répondu.'
    },
    pulseSurveyQuestion: 'Question de sondage instantané',
    fetchingQuestions: 'Récupération des questions',
    notAbleToRetrieve: 'Désolé, nous ne sommes pas en mesure de récupérer les questions pour le moment. Veuillez réessayer plus tard.',
    question: 'Question',
    selectQuestion: 'Sélectionnez une question',
    questionPlaceholder: 'Saisissez une question ou sélectionnez-la dans une liste',
    recipients: 'Destinataires',
    selectTime: 'Sélectionnez une heure d’envoi',
    now: 'Maintenant',
    after1Day: 'Après 1 jour',
    after3Days: 'Après 3 jours',
    after1Week: 'Après une semaine',
    createFailed: 'Échec de la création du sondage instantané. Veuillez réessayer plus tard.',
    createSuccess: 'Sondage instantané envoyé!',
    allowAtLeast3Response: 'Veuillez attendre au moins trois réponses avant de commencer à voir vos résultats.',
    incomingPulseSurvey: 'Demandes de sondages instantanés entrantes',
    receivedOn: 'Reçu le ',
    openUntil: 'Ouvert jusqu’au ',
    noOutstandingRequests: 'Vous n’avez aucune demande de sondage instantané en cours de la part de votre équipe.',
    rejectPulseSurvey: 'Rejeter la demande de sondage instantané',
    removeRequest: 'Cela supprimera la demande de votre liste et vous ne pourrez pas récupérer le sondage instantané ultérieurement.',
    myPulseSurveys: 'Mes sondages instantanés',
    sendOutPS: 'Envoyer un sondage instantané',
    status: 'Statut',
    sentSendingOn: 'Envoyé/en cours d’envoi',
    closed: 'Fermé',
    open: 'Ouvert',
    closedInsufficientRaters: 'Fermé — Pas assez d’évaluateurs',
    averageRating: 'Note moyenne',
    raters: 'Évaluateurs',
    sendSingleQuestion: 'Vous pouvez envoyer des questionnaires instantanés à vos coéquipiers pour obtenir un retour d’information rapide.',
    allRecipients: 'Tous les destinataires',
    viewResults: 'Voir les résultats',
    psDetails: 'Détails du sondage instantané',
    issuedOn: 'Publié le',
    teamMembersSentTo: 'Membres de l’équipe à qui vous avez envoyé',
    responsesReceived: 'réponses reçues',
    makingGreatProgress: 'Félicitations, vous faites de grands progrès pour devenir un maître de la reconnaissance! Il s’agit d’une compétence essentielle pour tout dirigeant, car elle permet d’inspirer la motivation et de créer un lieu de travail psychologiquement sûr pour vos employés.',
    whyStopAtGood: 'Mais pourquoi se contenter d’être bon quand on peut être grand? Afin d’améliorer vos compétences en matière de reconnaissance, rafraîchissez vos connaissances sur les meilleures pratiques en matière de reconnaissance sur le lieu de travail, qui ont fait l’objet de recherches. Grâce à ces conseils, vous serez en mesure de créer un environnement de travail encore plus positif et stimulant pour tous les membres de votre équipe!',
    learnAboutRecognition: 'En savoir plus sur la reconnaissance',
    noRating: 'Pas d’évaluation',
    atLeast3People: 'Au moins trois personnes doivent répondre au sondage pour que vous puissiez voir vos résultats. Essayez d’envoyer à nouveau le sondage pour recevoir plus de réponses.',
    goBack: 'Retourner'
  },
  userConnectionSelect: {
    recipientName: 'Nom du destinataire'
  },
  assessments: {
    incomingRatingRequests: 'Demandes d’évaluation à 360° entrantes',
    invitedToProvideRating: {
      part1: 'Vous avez été invité à fournir une évaluation à 360° pour les utilisateurs ci-dessous.',
      part2: 'Il ne vous faudra que quelques minutes pour répondre à ce questionnaire, et vos commentaires fourniront des informations précieuses qui l’aideront dans son parcours de leadership.'
    },
    noOutstandingRating: 'Vous n’avez actuellement aucune demande d’évaluation 360° en cours.',
    rejectRating: 'Rejeter la demande d’évaluation à 360°',
    willRemoveRequest: 'Cela supprimera la demande de votre liste et vous ne pourrez pas récupérer la demande d’évaluation ultérieurement.',
    keepIt: 'Le garder',
    reject: 'Rejeter'
  },
  onboarding: {
    companyHasActiveTrial: 'Votre entreprise a un essai actif avec Monark',
    companyTrialExpired: 'La période d’essai de votre entreprise a expiré',
    companyAlreadyHasLicense: 'Votre entreprise a déjà une licence avec Monark',
    companyLicenseExpired: 'La licence de votre entreprise a expiré',
    companyCompletingTrial: 'Votre entreprise est en train de terminer un essai avec Monark. Vous pourriez être ajouté à l’essai existant, et vous aurez un accès complet au contenu et aux fonctionnalités de Monark.',
    trialExpireOn: 'L’essai prendra fin le ',
    companyPreviouslyHadLicense: 'Votre entreprise disposait auparavant d’une licence avec Monark, mais celle-ci a expiré. Vous pouvez toujours utiliser Monark en tant qu’évaluateur pour répondre aux demandes d’évaluation à 360° et de retour d’information de vos pairs.',
    contactToLearn: 'Contactez-nous pour en savoir plus sur l’accès à l’ensemble du contenu et des fonctionnalités de Monark.',
    createProfile: 'Créer votre profil',
    selectPath: 'Choisissez votre parcours',
    completeSelfAssessment: 'Compléter l’auto-évaluation',
    inviteOthersToRate: 'Inviter les autres à vous évaluer',
    continueRater: 'Continuer comme évaluateur',
    requestSent: 'Demande envoyée',
    messageSent: 'Un message a été envoyé à Monark. Quelqu’un prendra contact avec vous dans les plus brefs délais.',
    embarkInteractiveJourney: 'Vous êtes sur le point de vous embarquer dans un périple de leadership interactif qui vous permet de contrôler où et quand vous apprenez.',
    heresWhatExpect: 'Voici ce à quoi vous pouvez vous attendre… ',
    getYourBaseline: 'Obtenez votre base de référence',
    assessmentOfCurrentBehaviors: 'Faites une évaluation de vos comportements actuels et invitez d’autres personnes à vous évaluer afin de savoir exactement où vous en êtes.',
    learnAtYourPace: 'Apprenez à votre rythme',
    easilyAccessContent: 'Accédez facilement au contenu quand et comment il s’intègre dans votre emploi du temps grâce à des micro-leçons de 5 minutes.',
    setGoalsPractice: 'Fixez-vous des objectifs et entraînez-vous',
    trackYourProgress: 'Gardez une trace de vos progrès et fixez-vous des objectifs et des rappels pour mettre en pratique de nouvelles compétences.',
    getFeedbackImprove: 'Obtenez un retour d’information et améliorez-vous',
    useSurveysFeedbackTools: 'Utilisez des sondages et des outils de retour d’information pour obtenir des informations en temps réel de la part de vos pairs sur la manière dont vous vous améliorez.',
    createYourProfile: 'Créer votre profil',
    thanksForSigningUp: 'Merci de vous être inscrit!',
    helpUsOptimize: 'Pour nous aider à optimiser votre parcours vers la réussite, veuillez répondre à quelques questions sur vous-même et votre expérience.',
    getStartedOnLeadershipJourney: 'Il est temps d’entamer votre parcours de leader!',
    hasBeenPreSelected: {
      part1: 'Le',
      part2: 'parcours d’apprentissage a été présélectionné pour vous. Ensuite, vous commencerez par remplir une auto-évaluation.'
    },
    seeDetailsLearningPaths: 'Ensuite, vous verrez les détails de tous les parcours de formation disponibles dans le cadre du modèle de leadership Monark. Sélectionnez celui qui vous intéresse pour commencer.'
  },
  assessmentForOthers: {
    notFoundAssessmentError: 'Désolé, nous ne pouvons pas trouver l’évaluation pour le moment. Veuillez réessayer plus tard.',
    youCompletedAssessmentError: 'Il semble que vous ayez déjà répondu à cette demande d’évaluation. Veuillez retourner à votre liste de demandes pour vérifier s’il y a d’autres demandes en suspens.',
    previouslyRejectedThisRequestError: 'Il semble que vous ayez déjà rejeté cette demande, vous ne pourrez donc plus la compléter. Veuillez retourner à votre liste de demandes pour vérifier s’il y a d’autres demandes en suspens.',
    assessmentExpiredError: 'L’évaluation à laquelle vous essayez d’accéder a expiré et n’est plus disponible. Veuillez retourner à votre liste de demandes pour vérifier s’il y a d’autres demandes en suspens.',
    assessmentNotAvailable: 'Évaluation non disponible.',
    backToAssessments: 'Retour aux évaluations',
    thankYouForRatings: 'Merci pour votre évaluation!',
    informationProvidedGreatlyHelp: {
      part1: 'Les informations que vous avez fournies seront d’une grande utilité ',
      part2: ' dans son parcours d’apprentissage!'
    },
    _360RatingFor: 'Évaluation à 360° pour ',
    askYouSomeQuestionsAbout: {
      part1: 'Nous allons vous demander de répondre à quelques questions sur ',
      part2: ', afin de l’aider dans son parcours d’apprentissage. ',
      part3: 'Prenez quelques minutes pour répondre à quelques questions sur la façon don’t ',
      part4: ' se comporte généralement au travail.'
    },
    userReceiveAverageRating: {
      part1: 'ne recevra qu’une note moyenne calculée sur l’ensemble des évaluateurs, ',
      part2: 'et n’aura accès à son évaluation que s’il/elle reçoit au moins trois réponses distinctes, ',
      part3: 'pour préserver la confidentialité des évaluateurs.'
    },
    begin360Assessment: 'Commencer l’évaluation à 360°',
    youAreAssessing: 'Vous êtes en train d’évaluer '
  },
  overview: {
    filters: 'FILTRES',
    cohort: 'Cohorte',
    resetFilters: 'Réinitialiser les filtres',
    users: 'Utilisateurs',
    insights: 'Connaissances',
    active: 'ACTIF',
    invited: 'INVITÉ',
    atRisk: 'À RISQUE',
    detailList: {
      pulseSurveySent: 'Sondage instantané envoyé',
      pulseSurveyCompleted: 'Sondage instantané terminé',
      qualitativeFeedback: 'Retour d’information qualitatif',
      communityPostsLeadershipShouts: 'Messages de la communauté et mentions de leadership',
      completedSelfAssessments: 'Auto-évaluations complétées',
      completed360Assessments: 'Évaluations à 360° complétées',
      _360Sent: '360° envoyée',
      milestonesCompleted: 'Jalons franchis',
      learningJourneysCompleted: 'Parcours d’apprentissage terminés'
    },
    atRiskUsers: 'Utilisateurs à risque',
    denotesAtRiskUsers: 'Désigne Les Utilisateurs à Risque',
    usersNotLogged30days: 'Utilisateurs qui ne se sont pas connectés depuis plus de 30 jours',
    activeUsers: 'Utilisateurs actifs',
    usersWhoStartedLearningPath: 'Utilisateurs ayant entamé un parcours d’apprentissage en effectuant une auto-évaluation',
    raterOnlyUsers: 'Utilisateurs « Évaluateur uniquement »',
    raterOnlyUsersCanRateOthers: 'Les utilisateurs évaluateurs seulement peuvent évaluer d’autres personnes sur des sondages instantanés et des évaluations à 360°, mais ne peuvent pas consulter le contenu de Monark ni procéder à des auto-évaluations. Ces utilisateurs peuvent être mis à niveau avec une licence',
    searchForUser: 'Rechercher l’utilisateur… ',
    export: 'Exporter',
    user: 'UTILISATEUR',
    completedPaths: 'PARCOURS COMPLÉTÉS',
    currentPath: 'PARCOURS ACTUEL',
    currentStep: 'ÉTAPE ACTUELLE',
    percentCompleted: '% COMPLÉTÉ',
    _360Responses: 'RÉPONSES 360°',
    lastSeen: 'VU LA DERNIÈRE FOIS',
    leaderHealthScore: 'NOTE DE SANTÉ DU LEADER',
    licensedBy: 'AUTORISÉ PAR',
    invitedUsers: 'Utilisateurs invités',
    usersWhoBeenInvitedButNotLoggedIn: 'Utilisateurs qui ont été invités à utiliser l’application, mais qui ne se sont pas encore connectés',
    email: 'Courriel',
    learningPath: 'Parcours d’apprentissage',
    firstInvitedAt: 'Invité(e) la première fois à ',
    noUserInvited: 'Aucun utilisateur n’est invité'
  },
  insights: {
    superLeaderScores: 'Scores Des Super Leaders',
    development: 'Développement',
    commitment: 'Engagement',
    growth: 'Croissance',
    teamStength: 'Force De L\'Équipe',
    showDescriptions: 'Afficher Les Descriptions',
    hideDescriptions: 'Masquer Les Descriptions',
    focusesOnLeadersProactiveEfforts: "Se concentre sur les efforts proactifs du leader pour améliorer ses compétences et ses connaissances. Il repose sur le principe selon lequel un leadership efficace est un processus d'apprentissage continu. Dans ce contexte, cela est largement démontré par leur engagement envers le processus d’apprentissage et de développement de Monark.",
    assessesLeadersDedication: "Évalue le dévouement du leader envers son organisation, en évaluant son niveau d'engagement, sa loyauté et sa volonté de contribuer au succès de l'organisation. Ainsi que leur volonté de recommander ou de parler positivement de l’organisation aux autres.",
    measuresLeadersDevelopment: "Mesure le développement personnel et professionnel du leader depuis son engagement avec l'application Monark, en mettant l'accent sur les progrès et l'avancement réels.",
    evaluatesLeadersTeam: "Évalue la santé et la performance de l’équipe dirigée par l’individu. Elle repose sur la conviction qu'un aspect clé du leadership est la capacité de créer et de maintenir une équipe performante et satisfaite.",
    includedMetrics: 'Métriques Incluses',
    directReports: '(Rapports Directs Uniquement)',
    learningPathCompletion: 'Achèvement Du Parcours D\'Apprentissage',
    learningCheckScores: 'Scores De Contrôle D\'Apprentissage',
    facilitatedSessionAttendance: 'Participation À Une Séance Facilitée',
    feedbackSeeking: 'Recherche De Commentaires (360s envoyées)',
    intentionToStay: 'Intention De Rester',
    engagement: 'Fiançailles',
    workSatisfaction: 'Satisfaction Au Travail',
    burnout: 'Burnout',
    changesInAssessmentScores: 'Changements dans les scores d\'auto-évaluation et d\'autrui à 360',
    appUsage: 'Utilisation De L\'Application',
    last: 'Derniers',
    avgOnlineTime: 'Moy. Temps En Ligne',
    mins: 'mins',
    avgDaysLoggedIn: 'Moy. jours de connexion',
    monthlyUsage: 'Utilisation Mensuelle',
    topUsers: 'Principaux Utilisateurs',
    topActivities: 'Principales Activités',
    learning: 'Apprentissage',
    _360sSent: '360s Envoyées',
    goalsAndActivities: 'Objectifs et Activités',
    whyNoScore: 'Pourquoi n\'y a-t-il pas de score ?',
    orderToShowGrowthScore: 'Afin d\'afficher un score de croissance, les membres de votre organisation doivent effectuer à la fois une évaluation initiale à 360° ainsi qu\'une évaluation de suivi à 360° pour montrer l\'évolution des scores.',
    commitmentDetails: 'Détails De L\'Engagement',
    teamStrengthDetails: 'Détails De La Force De L\'Équipe',
    developmentScoreDetails: 'Détails Du Score De Développement',
    survey: 'Enquête',
    growthScoreDetails: 'Détails Du Score De Croissance',
    seeDetailsOfGrowth: 'Découvrez les détails de la croissance du développement des comportements dans l’ensemble de l’organisation.',
    assessingTeamChange: 'Évaluation Du Changement D\'Équipe',
    combined: 'Combinée',
    completionPace: 'Rythme D\'Achèvement',
    selectCohortAndLearningPath: 'Veuillez sélectionner une cohorte et un parcours d\'apprentissage pour afficher la carte de progression',
    noGrowDetail: 'Aucun détail de croissance disponible pour le parcours sélectionné.'
  },
  superLeaderUserDrillDown: {
    learningPath: 'Parcours d\'apprentissage',
    complete: 'Complet',
    _360sResponse: 'Réponses à 360°',
    minutesOnPath: 'Minutes sur le chemin'
  },
  company: {
    companySettings: 'Paramètres de l’entreprise',
    general: 'Général',
    users: 'Utilisateurs',
    companyInformation: 'Informations sur l’entreprise',
    companyName: 'Nom de l’entreprise',
    enterYourCompanyName: 'Entrez le nom de votre entreprise',
    industry: 'Secteur d’activité',
    yourIndustry: 'Votre secteur d’activité… ',
    scheduleAutomatic360RemindersAfter: 'Programmer des rappels automatiques de 360° après ',
    whatDoesThisMean: 'Qu’est-ce que cela signifie?',
    membersOrganizationToSee: 'Afin que les membres de votre organisation puissent constater les progrès qu’ils ont accomplis, Monark encourage vivement les évaluations à 360° après une certaine période afin d’évaluer les changements.',
    recommend60DaysDefault: 'Nous recommandons un délai de 60 jours par défaut, mais vous pouvez choisir de faire réévaluer votre utilisateur plus tôt ou après une période d’attente plus longue.',
    _30Days: '30 jours',
    _60Days: '60 jours',
    _90Days: '90 jours',
    _120Days: '120 jours',
    saveChanges: 'Sauvegarder les modifications',
    updatedSucessfully: 'Mise à jour réussie',
    cohorts: 'Cohortes',
    name: 'NOM',
    members: 'MEMBRES',
    startDate: 'DATE DE DÉBUT',
    endDate: 'DATE DE FIN',
    path: 'PARCOURS',
    autoAssihnPath: 'ASSIGNER UN PARCOURS AUTOMATIQUEMENT',
    _360Reassessment: 'RÉÉVALUATION À 360°',
    postGoalEmail: 'COURRIEL APRÈS L’OBJECTIF',
    preFacilitationEmail: 'COURRIEL PRÉ-FACILITATION',
    allMembers: 'Tous les membres',
    on: 'Activé',
    off: 'Désactivé',
    days: 'Jours',
    addCohort: 'Ajouter une cohorte',
    addSessionToCohort: 'Ajouter une session à la cohorte',
    editCohortSession: 'Modifier la session de la cohorte',
    addSession: 'Ajouter une session',
    editSession: 'Modifier la session',
    cohortName: 'Nom de la cohorte',
    enterNameOfCohort: 'Entrez le nom de la cohorte',
    firstLearningPathDetails: 'Détails du premier parcours d’apprentissage',
    selectLearningPath: 'Sélectionnez un parcours d’apprentissage',
    facilitator: 'Facilitateur',
    selectFacilitator: 'Sélectionnez le facilitateur',
    selectPathLoadPresetPathTimelines: 'Veuillez sélectionner un parcours pour charger les chronologies de parcours prédéfinies.',
    _360ReassessmentTiming: 'Calendrier de la réévaluation à 360°',
    selectTiming: 'Sélectionner le moment',
    disable: 'Désactiver',
    autoAssignPathOnSessionStart: 'Attribuer automatiquement un parcours au début de la session.',
    postGoalActivityEmail: 'Courriel d’activité post-objectif',
    preFacilitationReminderEmails: 'Courriel de rappel avant la facilitation',
    licensedUsers: 'Utilisateurs sous licence',
    inviteMoreUsersMonark: 'Inviter plus d’utilisateurs à Monark',
    licensesInUse: 'licences en cours d’utilisation',
    licensesHaveExpired: 'Vos licences ont expiré. Tous les utilisateurs ont maintenant un accès Évaluateur uniquement.',
    wantToReactivateSubscription: 'Vous voulez réactiver votre abonnement? ',
    needMoreLicenses: 'Besoin de plus de licences? ',
    contactUs: 'Contactez-nous : ',
    getMoreMonarkLicenses: 'Obtenez plus de licences Monark',
    youDoNotHaveActiveSubscription: 'Vous n’avez pas d’abonnement actif pour le moment',
    userDetails: 'Détails de l’utilisateur',
    userRole: 'Rôle de l’utilisateur',
    enterValidEmailValidation: 'Veuillez entrer un courriel valide pour envoyer des invitations',
    inviteLicensedUsers: 'Inviter des utilisateurs licenciés',
    inviteAdministrativeUsers: 'Inviter les utilisateurs administratifs',
    licensesRemaining: 'Licences restantes',
    administrativeUsersAccessAdminDashboard: 'Les utilisateurs administratifs ont accès au tableau de bord administratif et un accès « Évaluateur uniquement » à l’application. Si vous souhaitez inviter ces utilisateurs à avoir un accès complet à l’application, vous devez les ajouter en utilisant le bouton « Inviter des utilisateurs licenciés » ci-dessus.',
    optional: 'Facultatif',
    ifYouIncludeLearningPath: 'Si vous incluez un parcours d’apprentissage, l’utilisateur sera automatiquement assigné à ce parcours, au lieu d’en choisir un lui-même.',
    byDefaultAssignedTheRoleOfMember: 'Par défaut, tout utilisateur disposant d’une licence se verra attribuer le rôle de membre. Vous pouvez également ajouter un accès administrateur pour que l’utilisateur puisse voir le tableau de bord de l’administrateur.',
    cohortGroupOfUsersWhoParticipateInApp: 'Une cohorte est un groupe d’utilisateurs qui participeront ensemble à l’application et seront assignés au même parcours d’apprentissage.',
    selectAcohort: 'Sélectionnez une cohorte',
    emailSubject: 'Objet du courriel',
    customizedSubject: 'Objet personnalisé',
    defaultSujectIs: 'L’objet par défaut est « Monark — Vous êtes invité à vous inscrire à l’application Monark ». Ne pas classer si l’on n’a pas l’intention de modifier',
    enterCustomizedEmailSubject: 'Entrez l’objet du courriel personnalisé',
    emailBody: 'Corps du courriel',
    customizedEmail: 'Courriel personnalisé',
    defaultEmailBody: 'Le corps du courriel par défaut a été défini en fonction des objectifs et des rôles invités. Ne pas utiliser avant confirmation avec la Production/Développement',
    default: 'Par défaut',
    customized: 'Personnalisé',
    messageAccompanyInvitation: 'Ce message accompagnera l’invitation',
    inviteSuccessfullySent: 'Invitation envoyée!',
    emailAddressAlreadyAdded: 'L’adresse électronique a déjà été ajoutée',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    addRecipient: 'Ajouter un destinataire',
    outOfOrganizationUser: 'Utilisateur hors de l’organisation',
    member: 'Membre',
    administrator: 'Administrateur',
    systemAdministrator: 'Administrateur système',
    changeSaved: 'Changement sauvegardé!',
    failedToSave: 'Échec de l’enregistrement de la modification. Veuillez réessayer plus tard',
    downgradeUserRaterOnly: 'Rétrograder l’utilisateur en Évaluateur uniquement',
    downgradeUser: 'Utilisateur rétrogradé',
    yesDowngradeUser: 'Oui, rétrograder cet utilisateur',
    accountTypeToRaterOnly: 'Le type de compte de l’utilisateur passera alors à Évaluateur uniquement, ce qui signifie qu’il ne pourra pas envoyer de demandes d’évaluation ni consommer le contenu d’apprentissage de Monark. Cela libérera une licence supplémentaire pour votre abonnement',
    deactivateUser: 'Désactiver l’utilisateur',
    yesDeactivateUser: 'Oui, désactiver cet utilisateur',
    willDisableUsersAccount: 'Cela désactivera le compte de l’utilisateur et l’empêchera de se connecter.',
    free1AdditionalLicense: 'Cela libérera une (1) licence supplémentaire pour votre abonnement',
    actionCannotUndone: 'Cette action ne peut être annulée, êtes-vous sûr?',
    doNotHaveLicensedUsers: 'Vous n’avez pas encore d’utilisateurs licenciés. Ajoutez des utilisateurs à votre abonnement à partir de la liste ci-dessous!',
    unlicensedUsers: 'Utilisateurs non licenciés',
    usersHaveAdministrator: 'Utilisateurs ayant un accès administrateur et/ou Évaluateur uniquement',
    adminDoNotRequireUserLicense: 'Les utilisateurs administratifs n’ont pas besoin de licence d’utilisateur',
    noLicenseAvailable: 'Aucune licence disponible',
    upgradeToFullAccess: 'Mettre à niveau l’utilisateur pour un accès complet',
    upgradeUser: 'Mettre à niveau l’utilisateur',
    yesUpgradeUser: 'Oui, mettre à niveau cet utilisateur',
    willUse1License: 'Ceci utilisera une licence supplémentaire et donnera à cet utilisateur un accès complet à la plateforme Monark.',
    allUsersAccessToMonark: 'Tous les utilisateurs ont un accès complet à Monark.',
    pendingInvites: 'Invitations en attente',
    listOfUsersYouInvited: 'Liste des utilisateurs que vous avez invités à rejoindre Monark.',
    invitedOn: 'Invité(s) le',
    license: 'Licence',
    noPendingInvites: 'Aucune invitation en attente',
    resend: 'Renvoyer',
    resendInvitation: 'Renvoyer l’invitation',
    revoke: 'Révoquer',
    revokeUserInvite: 'Révoquer l’invitation de l’utilisateur',
    yesRevokeInvite: 'Oui, révoquer cette invitation',
    actionRevokeUserInvitation: 'Cette action annulera l’invitation de l’utilisateur. Si cette invitation est destinée à un utilisateur licencié, elle libérera une licence supplémentaire pour votre abonnement. Voulez-vous continuer?',
    deactivatedUsers: 'Utilisateurs désactivés',
    usersHaveBeenDeactivated: 'Utilisateurs qui étaient auparavant actifs au sein de votre organisation mais qui ont été désactivés. Vous pouvez les réactiver à tout moment.',
    deactivatedOn: 'Désactivé le',
    noDeactivatedUsers: 'Pas d’utilisateurs désactivés.'
  },
  chatBot: {
    chat: 'Clavardage',
    askMeAnythingLeadership: 'Le leadership « Demandez-moi n’importe quoi »',
    notSureWhatToAsk: 'Vous ne savez pas quoi demander? Voici quelques suggestions',
    howToProvideFeedback: 'Comment fournir un retour d’information?',
    whatIs360: 'Qu’est-ce que le 360°?',
    howWasYourExperience: `Comment s’est déroulée votre expérience avec la séance « Demandez-moi n’importe quoi »?'`,
    feedbackAboutExperience: 'Donnez votre avis sur votre expérience'
  },
  rolePlay: {
    okay: 'D’accord',
    dictCancelled: 'Dictée annulée',
    dictCannotExceed: 'La dictée ne peut pas dépasser 1 minute.'
  },
  searchArea: {
    search: 'Recherche',
    noPreviousSearch: 'Pas de recherche préalable',
    contentSearch: 'Recherche de contenu',
    searchResults: 'Résultats de la recherche',
    searchWithNoResults: 'Votre recherche n’a donné aucun résultat, veuillez réviser votre requête et réessayer',
    selectSearchResult: 'Sélectionnez un résultat de recherche pour voir le contenu détaillé ici'
  },
  bookmarks: {
    myBookmarks: 'Mes signets',
    noCurrentlyContentBookmarked: 'Vous n’avez actuellement aucun contenu mis en signet.',
    addContentToList: 'Pour ajouter du contenu à votre liste, visitez votre parcours d’apprentissage et cliquez sur l’icône Signet dans l’en-tête de n’importe quelle étape.',
    goToCurrentPath: 'Aller à mon parcours actuel',
    selectBookmark: 'Sélectionnez un signet pour voir le contenu détaillé ici.'
  },
  notebook: {
    myNotebook: 'Mon carnet de notes',
    goals: 'OBJECTIFS',
    notesActivities: 'NOTES ET ACTIVITÉS',
    doNotCurrentlyHaveAnyGoals: 'Vous n’avez actuellement aucun objectif enregistré. Consultez le parcours d’apprentissage pour obtenir des suggestions d’objectifs associés à chaque étape',
    doNotCurrentlyHaveAnyNotes: 'Vous n’avez actuellement aucune note enregistrée. Visitez le parcours d’apprentissage pour ajouter des notes à n’importe quelle étape',
    visitLearningPath: 'Visiter le parcours d’apprentissage',
    notWorkedOnLearningPaths: 'Vous n’avez pas encore travaillé sur des parcours d’apprentissage, commencez votre périple d’apprentissage!',
    startMyLearningJourney: 'Commencer mon périple d’apprentissage',
    createdDate: 'DATE DE CRÉATION : ',
    dueDate: "DATE D'ÉCHÉANCE: ",
    completedDate: 'DATE D’ACHÈVEMENT : '
  },
  organization: {
    searchOrganizationMembers: 'Recherche des membres de l’organisation',
    enterNameOrEmail: 'Entrez votre nom ou votre adresse électronique',
    noConnectionFound: 'Aucun contact n’a été trouvé'
  },
  myTeam: {
    searchConnection: 'Recherche d’un contact',
    myManagersSeniorLeaders: 'Mes cadres et dirigeants',
    addConnection: 'Ajouter un contact',
    addNewConnection: 'Ajouter un nouveau contact',
    noMatchedUserFound: 'Aucun utilisateur correspondant trouvé',
    noRelatedUsers: 'Pas d’utilisateurs apparentés',
    selectUserOrInputRmail: 'Sélectionnez un utilisateur ou saisissez un courriel',
    selectRelationship: 'Sélectionnez la relation',
    failedToCreateContact: 'Échec de la création d’un contact. Veuillez réessayer plus tard.',
    connectionAddedSuccessfully: 'Contact ajouté',
    otherConnections: 'Autres contacts',
    lastUpdate: 'Dernière mise à jour',
    me: 'Moi',
    showMore: 'Afficher plus',
    nobodyHereYet: 'Personne ici',
    myColleaguesMe: 'Mes collègues et moi',
    myReports: 'Mes subordonnés',
    deleteConnection: 'Supprimer La Connexion',
    areYouSureDeleteConnection: 'Êtes-vous sûr de vouloir supprimer cette connexion ?'
  },
  contact: {
    contactUpdates: 'Mises à jour des contacts',
    addNote: 'Ajouter une note',
    doNotHaveNotes: 'Vous n’avez pas encore de notes. Cliquez sur le bouton Ajouter une note pour ajouter des notes pour référence ultérieure.',
    editNote: 'Modifier la note',
    noteIsPrivate: 'Cette note est privée et n’est visible que par vous.',
    manualNotesEntry: 'Saisie manuelle de notes',
    deleteNote: 'Supprimer la note',
    yesDeleteIt: 'Oui, supprimez-la.',
    aboutToDeleteNotes: {
      part1: 'Vous êtes sur le point de supprimer cette entrée de notes manuelles.',
      part2: 'Cette action ne peut être annulée, êtes-vous sûr(e)?'
    },
    doNotHaveUpcomingMeetings: 'Vous n’avez pas de réunion prévue avec',
    bookMeetingWillList: 'Une fois que vous aurez réservé une réunion, vous la verrez apparaître dans cette liste.',
    upcomingMeetings: 'Réunions à venir',
    pastMeetings: 'Réunions antérieures',
    noteTitle: 'Titre De La Note'
  },
  setings: {
    learningReminders: 'Rappels d’apprentissage',
    repeat: 'Répéter',
    time: 'Temps',
    failedToSave: 'Échec de la sauvegarde. Veuillez réessayer plus tard.',
    notifications: 'Notifications',
    type: 'Type',
    inApp: 'Dans l’appli',
    disableAll: 'Désactiver tout'
  },
  authPages: {
    logIn: "S'Identifier",
    workEmail: 'Email de travail',
    password: 'Mot de passe',
    invalidLoginCredentials: 'Authentification invalide',
    forgotYourPassword: 'Mot de passe oublié?',
    loginWith: 'Connectez-vous avec:',
    dontHaveAccount: "Vous n'avez pas de compte ? ",
    contactUs: 'Contactez-nous',
    privacyPolicy: 'Politique De Confidentialité',
    termsOfUse: "Conditions D'Utilisation",
    leastOneAlphabetCharacter: 'Au moins un caractère alphabétique',
    leastOneDigit: 'Au moins un chiffre',
    leastOneSpecialCharacter: 'Au moins un caractère spécial',
    least8Characters: 'Au moins 8 caractères',
    signUp: "S'Inscrire",
    passwordMustHave: 'Le mot de passe doit avoir',
    confirmPassword: 'Confirmez Le Mot De Passe',
    orSignUpWith: 'Ou Inscrivez-Vous Avec :',
    bySigningYouAgree: 'En vous inscrivant, vous acceptez le ',
    alreadyHaveAccount: 'Vous avez déjà un compte? Se connecter ',
    here: 'ici',
    and: 'et',
    verifyEmailAddress: "Vérifier L'Adresse E-mail",
    mustVerifyEmailAddress: "Vous devez vérifier votre adresse e-mail avant de pouvoir commencer le parcours d'apprentissage.",
    signedUpForMonark: "Vous vous êtes inscrit à Monark en utilisant l'adresse",
    verificationEmailWillBeSent: 'Votre e-mail de vérification sera envoyé à cette adresse.',
    verificationEmailSent: "L'Email De Vérification A Été Envoyé!",
    checkYourInbox: 'Veuillez vérifier votre boîte de réception et suivre les instructions pour vérifier votre adresse e-mail',
    sendVerificationEmail: 'Envoyer email de vérification',
    sendPasswordReset: 'Envoyer La Réinitialisation Du Mot De Passe',
    requestSubmittedSuccessfully: 'Demande soumise avec succès ! Veuillez vérifier votre boîte de réception et suivre les instructions pour réinitialiser le mot de passe !',
    cannotFindAccount: 'Nous ne trouvons pas votre compte. Veuillez réessayer.',
    sendResetLink: 'Envoyer le lien de réinitialisation',
    backToLogin: 'Retour Connexion',
    resetPassword: 'Rˇéinitialiser Le Mot De Passe',
    reestPasswordSucessfully: 'Réinitialisez le mot de passe avec succès ! Vous pouvez maintenant vous connecter avec le nouveau mot de passe.',
    newPassword: 'nouveau mot de passe',
    somethingWentWrong: "Quelque chose s'est mal passé.",
    reset: 'Réinitialiser',
    invitationEmailMissmatchTitle: "L'E-mail D'Invitation Ne Correspond Pas",
    invitationEmailMissmatch: {
      part1: "L'invitation que vous avez reçue était pour ",
      part2: ", vous devez utiliser cette adresse e-mail pour vous inscrire."
    },
    appNotAvailable: 'Application Non Disponible sur Le Navigateur Mobile',
    installTheApp: 'Veuillez installer l\'application ou l\'ouvrir sur votre appareil mobile.',
    goToStore: 'Aller au Magasin',
    monarkWorksBestInLandscape: 'Monark fonctionne mieux en mode Paysage, veuillez faire pivoter votre appareil pour une meilleure expérience'
  },
  validationErrors: {
    enterYourEmail: 'Entrez votre email',
    enterValidEmail: 'Entrer un email valide',
    emailRequired: "L'e-mail est requis",
    enterYourPassword: 'Tapez votre mot de passe',
    passwordRequired: 'Mot de passe requis',
    passwordLeast8Characters: 'Le mot de passe doit comporter au moins 8 caractères',
    passwordMustContains: 'Le mot de passe doit contenir au moins un caractère, un chiffre et un caractère spécial',
    confirmYourPassword: 'Confirmer votre mot de passe',
    passwordsMustMatch: 'Les mots de passe doivent correspondre',
    required: 'Requis',
    pleaseSelectDate: 'Veuillez sélectionner une date',
    pleaseSelectEndDate: 'Veuillez sélectionner la date de fin',
    endDateAfterStartDate: 'La date de fin doit être postérieure à la date de début',
    pleaseSelectReassessmentTiming: 'Veuillez sélectionner le moment de la réévaluation',
    pleaseSelectPath: 'Veuillez sélectionner le chemin',
    pastDue: 'En retard!'
  },
  support: {
    frequentlyAskedQuestions: 'Foire aux questions',
    haveQuestion: 'Vous avez une question, un problème ou un retour d’information à nous communiquer?',
    supportRequest: 'Demande d’assistance',
    howCanWeHelpYou: 'Comment pouvons-nous vous aider?',
    yourRequestReceived: 'Votre demande a été reçue',
    FAQ_LIST: [
      {
        id: 0,
        question: 'Qui voit les résultats de mon évaluation à 360°, les résultats de mon sondage instantané ou le retour d’information qualitatif que je reçois?',
        answer: 'Toutes les évaluations et tous les commentaires que vous recevez sont privés! Lorsqu’un autre utilisateur vous fournit un retour d’information quantitatif ou qualitatif, cette information n’est disponible que pour vous.'
      },

      {
        id: 1,
        question: 'Qu’advient-il des informations que je fournis aux autres?',
        answer: 'Lorsque vous donnez une note numérique (par exemple, dans l’évaluation à 360° ou dans un sondage instantané), votre note est intégrée à la moyenne générale de l’utilisateur, ce qui signifie que votre note reste anonyme. Les notes quantitatives ne sont communiquées à un utilisateur qu’après qu’il a reçu au moins trois notes. Toutefois, les commentaires qualitatifs que vous fournissez à d’autres utilisateurs (c’est-à-dire les commentaires ouverts) seront associés à votre nom d’utilisateur et seront visibles par l’utilisateur pour lequel vous avez fourni le commentaire.'
      },

      {
        id: 2,
        question: 'Quand pourrai-je voir les évaluations à 360° des autres?',
        answer: 'Vos évaluations à 360° seront disponibles dès que trois évaluateurs ou plus auront fourni des réponses. Nous fournissons une moyenne générale des notes afin de garantir une évaluation aussi honnête et précise que possible, tout en préservant l’anonymat des utilisateurs. Si vous ne voyez pas encore de notes attribuées par d’autres personnes, nous vous suggérons d’inviter d’autres collègues ou des personnes de votre organisation qui peuvent évaluer votre comportement au travail. Vous pouvez y accéder dans votre profil.'
      },
      {
        id: 3,
        question: 'Comment puis-je demander un retour d’information à d’autres utilisateurs?',
        answer: `Il y a plusieurs façons de recevoir un retour d’information de la part d’autres utilisateurs. La première occasion d’obtenir un retour d’information de la part des autres est la mesure initiale de référence à 360° (présentée immédiatement après la sélection de votre objectif). Si vous avez sauté cette étape, ou si vous souhaitez simplement ajouter d’autres évaluateurs à cette évaluation, vous pouvez le faire en allant sur votre page de profil et en sélectionnant Ajouter d’autres évaluateurs 360°. Les personnes dont vous avez saisi l’adresse électronique recevront alors une invitation à vous évaluer à l’aide de cette évaluation.

 Vous avez également la possibilité d’envoyer un sondage instantané qui vous permet de sélectionner une question spécifique qui sera évaluée par d’autres personnes.Nous vous recommandons d’utiliser cette fonction lorsque vous avez travaillé sur un domaine particulier lié à votre objectif(ou simplement à votre objectif en général) et que vous souhaitez obtenir un retour d’information rapide sur votre progression.Pour envoyer un sondage instantané, sélectionnez « Retour d’information » dans le menu principal.Puis, sous « Demande de retour d’information », sélectionnez « Envoyer un sondage instantané ».Sur cet écran, vous pouvez sélectionner votre question, les utilisateurs que vous souhaitez évaluer et le moment où vous souhaitez que l’invitation soit envoyée.

 Une dernière option consiste à poser directement la question à certains de vos collègues, en personne ou par courriel.N’oubliez pas que le retour d’information se fait dans les deux sens et que, souvent, les autres ne vous donneront pas directement leur avis à moins que vous ne le demandiez!`
      },

      {
        id: 4,
        question: 'Qui dois-je choisir pour m’évaluer?',
        answer: 'Afin d’obtenir les évaluations les plus précises, il est préférable de sélectionner des personnes qui ont une bonne compréhension de votre comportement sur le lieu de travail. En choisissant des personnes qui ont été suffisamment exposées à vos comportements professionnels, vous aurez la meilleure occasion de mettre le doigt sur vos forces et vos faiblesses d’un point de vue extérieur.'
      }
    ]
  }
}
