import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import StyledButton from 'components/atoms/button/StyledButton'
import { HOME, MY_MEETING } from 'routes/constants'
import { useNavigate } from 'react-router-dom'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'
import { patchUserCalendarEvent } from 'services/userConnectionsHelper'
import QuestionRow from '../QuestionRow'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import OverlayLoading from 'components/atoms/OverlayLoading'
import UnavoidableConfirmCancel from 'components/molecules/notificationOverlay/UnavoidableConfirmCancel'
import { getConstant } from 'services/constantServices'

const CheckInSurvey = ({ userCalendarEventId, questions }) => {
  const [choices, setChoices] = useState([])
  const [isAllAnswered, setIsAllAnswered] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const name = getLocalUser().firstName || t('rolePlay.upcomingLeader')

  const handleSubmit = async () => {
    setLoading(true)
    await patchUserCalendarEvent(userCalendarEventId, {
      // Backend choices are 1-indexed
      surveyResults: choices.map(x => parseInt(x) + 1)
    })
    setLoading(false)
    setIsPopupOpen(true)
  }

  const updateAnswer = (idx, choice) => {
    setChoices(prev => {
      const newChoices = [...prev]
      newChoices[idx] = choice
      return newChoices
    })
  }

  useEffect(() => {
    if (!userCalendarEventId && questions.length) {
      setChoices(Array(questions.length).fill(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCalendarEventId, questions])

  useEffect(() => {
    setIsAllAnswered(choices.reduce((acc, x) => acc + (x !== null), 0) === 5)
  }, [choices])

  return (
    <>
      <OverlayLoading open={loading} />
      <UnavoidableConfirmCancel
        open={isPopupOpen}
        title={t('checkInSurvey.answersSubmitted')}
        subtitle={t('checkInSurvey.doYouWantToPrepareSomeNotes')}
        lowFocusText={t('common.done')}
        highFocusText={t('checkInSurvey.viewMeeting')}
        lowFocusAction={() => navigate(HOME)}
        highFocusAction={() =>
          navigate(MY_MEETING, {
            state: {
              userCalendarEventId
            }
          })
        }
        dialogWidth={400}
        buttonAlignment='flex-end'
      />
      <BoxWithBackground padding={20}>
        <Box paddingBottom={8}>
          <Typography variant='h2'>
            {t('checkInSurvey.checkInSurvey')}
          </Typography>
          <Typography variant='body1Large' fontSize={20}>
            {t('checkInSurvey.nameHowHaveYouFelt', { name })}
          </Typography>
        </Box>
        <Grid container rowSpacing={10} sx={{ paddingTop: 8, paddingRight: 5 }}>
          {questions
            .sort((a, b) => a.order - b.order)
            .map(d => (
              <QuestionRow
                key={`question-row-${d.order}`}
                questionText={
                  <>
                    <strong>{d.virtue}:</strong> {d.description}
                  </>
                }
                order={d.order}
                onChange={v => updateAnswer(d.order - 1, v)}
              />
            ))}
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <StyledButton
              disabled={!isAllAnswered}
              sx={{ width: 170, textWrap: 'nowrap' }}
              onClick={handleSubmit}>
              {t('checkInSurvey.submitAnswers')}
            </StyledButton>
          </Grid>
        </Grid>
      </BoxWithBackground>
    </>
  )
}

export default CheckInSurvey
