import { getRequest } from 'utils/api'
import { CONSTANTS_URL } from './constants'
import { getLocalConstant, setLocalConstant } from 'utils/sessionStorage'

export const getConstant = async KEY => {
  let requestUrl = CONSTANTS_URL
  if (KEY) {
    const localItem = getLocalConstant(KEY)
    if (localItem) {
      return localItem
    }
    requestUrl = `${CONSTANTS_URL}/${KEY}`
  }
  const res = await getRequest(requestUrl)
  if (res.status === 200) {
    setLocalConstant(KEY, res.data.data)
    return res.data.data
  } else {
    return null
  }
}
