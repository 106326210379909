import { Stack, Typography, useTheme, Box } from '@mui/material'
import ShadedScoreDisplayBar from 'components/atoms/ShadedScoreDisplayBar'
import { CircleNumber } from 'components/atoms/Text'
import InCardStack from 'components/atoms/InCardStack'
import { useTranslation } from 'react-i18next'

const StepQuizSurveyResult = ({ resultData }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <InCardStack sx={{ alignItems: 'left' }}>
      <Typography variant='subtitle1'>Scores</Typography>
      {resultData.map(({ name, score, description, highLowMsg }) => (
        <ShadedScoreDisplayBar
          key={name}
          width='100%'
          height='fit-content'
          backgroundColor={theme.palette.background.default}
          labelComponent={
            <Stack padding={2} alignItems='left'>
              <Typography>{name}</Typography>
              <Typography variant='body2'>{description}</Typography>
              {highLowMsg ?
                <Box sx={{
                  marginLeft: 6,
                  marginTop: 4,
                  paddingLeft: 3,
                  borderLeft: 2,
                  borderColor: theme.palette.primary.main
                }}>
                  <Typography sx={{
                    fontSize: 14
                  }}>
                    {t('stepQuiz.youScored')} {highLowMsg.type} {t('stepQuiz.on')} {name}
                  </Typography>
                  <Typography variant='body2'>{highLowMsg.message}</Typography>
                </Box> : null
              }
            </Stack>
          }
          scoreComponent={
            <CircleNumber
              value={score.toFixed(1)}
              color={theme.palette.background.paper}
            />
          }
        />
      ))}
    </InCardStack>
  )
}
export default StepQuizSurveyResult
