import { useRef } from 'react'
import { ActionText } from 'components/atoms/Text'
import { Add } from '@mui/icons-material'
import ExcelJS from 'exceljs';
import { validationsRules } from 'utils/formValidations'

const EmailsImportInput = ({
  emails,
  setEmails
}) => {
  const fileInputRef = useRef(null)

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (!file)
        return
    if (file.type === 'text/csv') {
        handleCSV(file)
    } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.name.endsWith('.xlsx')) {
        handleExcel(file)
    } else {
        return
    }
     // Reset the input value after reading the file
     event.target.value = null;
  }

  const handleCSV = (file) => {
    const reader = new FileReader()
    reader.onload = (e) => {
        const content = e.target.result;
        let rows = content.split('\n')
        const headerRow = rows[0].split(',').map(header => header.trim())
        rows = rows.slice(1).map(row => row.split(',').map(ele => ele.trim()))
        setInviteData(headerRow, rows)
    }
    reader.readAsText(file)
  }

  const handleExcel = (file) => {
    const reader = new FileReader()
    const workbook = new ExcelJS.Workbook()

    reader.onload = async (e) => {
      const content = e.target.result
      try {
        await workbook.xlsx.load(content)
        workbook.eachSheet((sheet) => {
          const headerRow = []
          const rows = []
          sheet.eachRow((row, rowNumber) => {
            const rowData = []
            row.eachCell({ includeEmpty: false }, (cell) => {
              if(rowNumber === 1)
                headerRow.push(cell.value)
              else
                rowData.push(cell.value)
            })
            if (rowData.length > 0)
              rows.push(rowData)
          })
          setInviteData(headerRow, rows)

          // Break the loop after processing the first sheet
          return
        })
      } catch (error) {
        return
      }
    }
    reader.readAsArrayBuffer(file)
  }

  const setInviteData = async (headerRow, rows) => {
    const data = []
    for (let i = 0; i < rows.length; i++) {
        const values = rows[i]
        const csvInvites = {}
        let validEmail = false
        for (let j = 0; j < headerRow.length; j++) {
            const header = headerRow[j]
            const value = values[j] ? values[j].toString().trim() : ''
            if (header && value) {
                // Map headers to desired keys
                if (header === 'Email') {
                    validEmail = await validateEmail(value)
                    csvInvites.email = value
                } else if (header === 'First Name') {
                    csvInvites.firstName = value
                } else if (header === 'Last Name') {
                    csvInvites.lastName = value
                }
            }
        }
        if (validEmail)
          data.push(csvInvites)
    }
    let newEmails = [...emails, ...data];
    setEmails(newEmails)
  }

  const validateEmail = async (email) => {
    try {
      await Promise.resolve(validationsRules.email.validate(email))
      const existing = emails.find(e => e.email.toLowerCase() === email.toLowerCase())
      if (existing)
        throw new Error('Email already exists')
      return true 
    } catch (error) {
        return false
    }
  }

  const handleImportClick = () => {
    fileInputRef.current.click()
  }

  return (
    <>
        <ActionText
            display='flex'
            justifyContent='center'
            width='fit-content'
            onClick={() => handleImportClick()}
            >
            <Add color='primary' /> Import
        </ActionText>

        <input
            type="file"
            ref={fileInputRef}
            accept=".csv, .xlsx"
            style={{ display: 'none' }}
            onChange={handleFileChange}
        />
    </>
  )
}

export default EmailsImportInput
