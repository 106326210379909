import { IconButton, TableCell, TableRow } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { useEffect, useState } from 'react'

const FacilitationRow = ({ formik, timeline, minDate, maxDate }) => {
  const [facilitationDate, setFacilitationDate] = useState(null)
  const [existingId, setExistingId] = useState(null)
  useEffect(() => {
    const existingFacilitation = formik.values.facilitations?.find(
      f => f.goalTimelineId === timeline.id
    )
    if (existingFacilitation) {
      setExistingId(existingFacilitation.id)
    }
    if (!existingFacilitation) {
      setExistingId(null)
      setFacilitationDate(null)
    } else {
      setFacilitationDate(moment.utc(existingFacilitation.facilitationDate).local())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.facilitations])

  const handleDateSelect = value => {
    const updatingValue = value ? moment(value).utc() : value
    const updatingFacilitations = [...formik.values.facilitations]
    const currentFacilitation = updatingFacilitations?.find(
      f => f.goalTimelineId === timeline.id
    )
    if (currentFacilitation && currentFacilitation.id) {
      currentFacilitation.updating = true
    }
    if (currentFacilitation) {
      currentFacilitation.facilitationDate = updatingValue
    } else {
      updatingFacilitations.push({
        goalTimelineId: timeline.id,
        facilitationDate: updatingValue
      })
    }
    formik.setFieldValue('facilitations', updatingFacilitations, false)
  }

  return (
    <TableRow key={`timeline-row-${timeline.id}`}>
      <TableCell>{timeline.number}</TableCell>
      <TableCell>{timeline.stepName}</TableCell>
      <TableCell>
        <DatePicker
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleDateSelect}
          value={facilitationDate}
          disablePast
          disabled={
            existingId && facilitationDate && facilitationDate < moment()
          }
          sx={[
            {
              '& .MuiInputBase-root': {
                minWidth: 0
              }
            }
          ]}
        />
      </TableCell>
      <TableCell sx={{ minWidth: 36 }}>
        {facilitationDate && facilitationDate > moment() && (
          <IconButton onClick={() => handleDateSelect(null)}>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

export default FacilitationRow
