import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import { useLocation, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { getRequest } from 'utils/api'
import { ASSESSMENT_RATINGS_TIMELINE_URL } from 'services/constants'
import AssessmentBehaviourCard from 'components/organisms/assessments/AssessmentBehaviourCard'
import RequestErrorPageCard from 'components/molecules/RequestErrorPageCard'

const AssessmentBehaviourDetail = () => {
  const location = useLocation()
  const params = useParams()
  const [assessmentData, setAssessmentData] = useState(
    location.state?.assessmentData || undefined
  )
  const [requestError, setRequestError] = useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(ASSESSMENT_RATINGS_TIMELINE_URL, {
        goal_id: params.goalId
      })
      if (res.status === 200) {
        const assessments = res.data.data
        const targetAssessment = assessments.find(
          assessment => assessment.id === parseInt(params.id)
        )
        if (!targetAssessment) {
          setRequestError(true)
        } else {
          setAssessmentData(targetAssessment)
          setRequestError(false)
        }
      } else {
        setRequestError(true)
      }
    }
    if (!assessmentData) {
      fetchData()
    }
  })

  return (
    <FeedbackPage>
      <BackIconTextButton />
      {requestError ? (
        <RequestErrorPageCard />
      ) : assessmentData ? (
        <AssessmentBehaviourCard
          behaviour={params.behaviour}
          behaviourInfoData={assessmentData.behaviourInfoData}
          showOthersScore={assessmentData.goalData?.enableOthersAssessment}
        />
      ) : null}
    </FeedbackPage>
  )
}

export default AssessmentBehaviourDetail
