import { useEffect, useState } from 'react'
import { ChangeTable, ChangeTableRow } from '../../ChangeTable'
import { getPercentageDisplay } from 'utils/formatText'

const DrillDownChangeTable = ({
  data,
  valueField,
  showBehaviors = true,
  isPercentage = false
}) => {
  const [hasOlderData, setHasOlderData] = useState(false)

  useEffect(() => {
    if (data.old?.timestamp) {
      setHasOlderData(true)
    } else {
      setHasOlderData(false)
    }
  }, [data])

  const getValue = value => {
    let nValue = valueField ? value[valueField] : value
    if (isPercentage){
      nValue = getPercentageDisplay(nValue) 
    } else {
      nValue = nValue?.toFixed(1)
    }
    return nValue || '-'
  }
  return (
    <ChangeTable
      data={data}
      hasOlderScore={hasOlderData}
      timestampField='timestamp'
      oldDataKey='old'
      newDataKey='current'
      showBehaviors={showBehaviors}
      timeLabel='insights.survey'
      showOlderColumn={hasOlderData}
      longFormDate={true}>
      {Object.entries(data.current.virtues).map(([behavior, value], index) => {
        const hasOldScore =
          hasOlderData && data.old.virtues?.[behavior]
        const oldScore = hasOldScore
          ? getValue(data.old.virtues[behavior]) || '-'
          : '-'
        return (
          <ChangeTableRow
            key={behavior}
            name={behavior}
            index={index}
            tooltip={value.tooltip}
            showOlderColumn={hasOlderData}
            oldScore={oldScore}
            newScore={getValue(value)}
            boldIndex={true}
          />
        )
      })}
    </ChangeTable>
  )
}

export default DrillDownChangeTable
