import InCardStack from 'components/atoms/InCardStack'
import MicrosoftLogin from './MicrosoftLogin'
import GoogleLogin from './GoogleLogin'
import { Divider, Stack, Typography } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

const ThirdPartyLoginStack = ({ signinCallback, email, labelPrefix }) => {
  const { t } = useTranslation()
  return (
    <Stack padding={0} spacing={12} width='100%'>
      <RowStack justifyContent='center' spacing={4}>
        <Divider sx={{ color: colors.LightGrey200 }} width={170} />
        <Typography
          variant='body1Small'
          color={colors.LightGrey400}
          sx={{ textTransform: 'uppercase' }}>
          {t('common.or')}
        </Typography>
        <Divider sx={{ color: colors.LightGrey200 }} width={170} />
      </RowStack>
      <InCardStack spacing={6}>
        <GoogleLogin
          label={labelPrefix && labelPrefix + ' Google'}
          signinCallback={signinCallback}
          email={email}
        />
        <MicrosoftLogin
          label={labelPrefix && labelPrefix + ' Microsoft'}
          signinCallback={signinCallback}
          email={email}
        />
      </InCardStack>
    </Stack>
  )
}

export default ThirdPartyLoginStack
