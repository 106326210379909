import { Box, Card, Stack, Tooltip, Typography } from '@mui/material'

const PageComponent = props => {
  const { children, titleText, tooltip = '', ...otherProps } = props
  return (
    <Box {...otherProps}>
      {titleText ? (
        <Tooltip title={tooltip}>
          <Typography variant='h3' width='fit-content' paddingBottom='12px'>
            {titleText}
          </Typography>
        </Tooltip>
      ) : null}
      <Box paddingBottom='12px'>
        <Card>
          <Stack sx={{paddingX: 5}}>{children}</Stack>
        </Card>
      </Box>
    </Box>
  )
}

export default PageComponent
