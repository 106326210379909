import AuthorizedPage from 'components/templates/AuthorizedPage'
import { PAGE_TITLES } from 'utils/constants'
import ContentHeaderTabs from 'components/organisms/ContentHeaderTabs'
import { testingTabs } from 'utils/headerTabs'

const InternalPage = ({ children, activePage, ...otherProps }) => {
  return (
    <AuthorizedPage
      pageTitle={PAGE_TITLES.INTERNAL}
      {...otherProps}
      headerTabs={<ContentHeaderTabs activePage={activePage} tabs={testingTabs}/>}>
      {children}
    </AuthorizedPage>
  )
}

export default InternalPage
