import {
  Backdrop,
  CircularProgress,
  Dialog,
  Stack,
  Typography
} from '@mui/material'
import colors from 'styles/colors'
import CircularProgressBar from 'components/atoms/CircularProgressBar'

const OverlayLoading = ({ open }) => {
  return (
    <>
      {open ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          data-testid='overlayer-spinner'
          open={open}>
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : null}
    </>
  )
}

export const OverlayWithText = ({ isOpen, message, progress = null }) => (
  <Dialog open={isOpen} maxWidth='xs'>
    <Stack alignItems='center' spacing={10}>
      {progress !== null ? (
        <CircularProgressBar
          value={progress}
          useGradientArch={true}
          emptyArchColor={colors.MutedTeal50}
          backgroundColor={colors.White}
        />
      ) : (
        <CircularProgress />
      )}

      <Typography variant='h2' textAlign='center'>
        {message}
      </Typography>
    </Stack>
  </Dialog>
)

export default OverlayLoading
