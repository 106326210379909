import { useEffect, useState } from 'react'
import {
  ASSESSMENT_INVITES_URL,
  ASSESSMENT_RECIPIENTS_URL
} from 'services/constants'
import { getRequest, patchRequest } from 'utils/api'
import {
  CardContent,
  Table,
  TableBody,
  TableContainer,
  Typography,
  TableCell,
  TableRow,
  CardHeader,
  Grid
} from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import { useNavigate } from 'react-router-dom'
import { ASSESSMENT_OTHERS } from 'routes/constants'
import SimpleNotificationDialog from 'components/molecules/notificationOverlay/SimpleNotificationDialog'
import AcceptRejectCell from 'components/atoms/table/AcceptRejectCell'
import { formatDate } from 'utils/formatDateTime'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import { NotificationBodyText } from 'components/atoms/Text'
import { getFullName } from 'utils/formatText'
import { useTranslation } from 'react-i18next'

const AssessmentsList = ({
  smallItemView = false,
  countCallback,
  displayCount
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [requestError, setRequestError] = useState(undefined)
  const [assemssmentInvites, setAssessmentInvites] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedAssessment, setSelectedAssessment] = useState(undefined)

  const fetchData = async () => {
    const res = await getRequest(ASSESSMENT_INVITES_URL)
    if (res.status === 200) {
      const invites = res.data.data
      await Promise.all(
        invites.map(async invite => {
          const goal = await getGoalInfoById(invite.goal_id)
          const updated = invite
          updated.goalName = `${goal.leadershipModelName} - ${goal.name}`
          return updated
        })
      )
      if (smallItemView) {
        setAssessmentInvites(invites.slice(0, displayCount))
        countCallback(invites.length)
      } else {
        setAssessmentInvites(invites)
      }
      setRequestError(false)
    } else {
      setRequestError(true)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rejectAssessment = requestId => {
    setSelectedAssessment(requestId)
    setDialogOpen(true)
  }

  const onRejectDialogClose = async keep => {
    if (!keep) {
      const res = await patchRequest(
        `${ASSESSMENT_RECIPIENTS_URL}/${selectedAssessment}`
      )
      if (res.status === 200) {
        await fetchData()
      } else {
        setRequestError(true)
      }
    }
    setDialogOpen(false)
  }

  const AvartarHeader = ({ invite, subHeader }) => (
    <CardHeader
      avatar={<ProfileAvatar user={invite} />}
      subheader={subHeader ? formatDate(invite.created_at) : undefined}
      title={getFullName(invite)}
      sx={{ padding: 0 }}
    />
  )
  return (
    <>
      {assemssmentInvites.length > 0 ? (
        smallItemView ? (
          <Grid container>
            {assemssmentInvites.map(invite => (
              <Grid item xs={6} minWidth='350px' key={invite.id}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <AvartarHeader invite={invite} subHeader={true} />
                        </TableCell>
                        <AcceptRejectCell
                          width='100px'
                          acceptClick={() =>
                            navigate(
                              `${ASSESSMENT_OTHERS}/${invite.assessmentRequestId}`,
                              {
                                state: {
                                  senderName: getFullName(invite),
                                  goalId: invite.goal_id,
                                  sender: invite.user_id
                                }
                              }
                            )
                          }
                          rejectClick={() => rejectAssessment(invite.id)}
                        />
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            <CardContent>
              <InCardStack>
                <Typography variant='subtitle2' align='center'>
                  {t('assessments.invitedToProvideRating.part1')}
                </Typography>
                <Typography variant='subtitle2' align='center'>
                  {t('assessments.invitedToProvideRating.part2')}
                </Typography>
              </InCardStack>
            </CardContent>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableBody>
                    {assemssmentInvites.map(invite => (
                      <TableRow
                        key={invite.id}
                        sx={{ borderBottomWidth: '1px' }}>
                        <TableCell>
                          <AvartarHeader invite={invite} />
                        </TableCell>
                        <TableCell>{invite.goalName}</TableCell>
                        <AcceptRejectCell
                          acceptClick={() =>
                            navigate(
                              `${ASSESSMENT_OTHERS}/${invite.assessmentRequestId}`,
                              {
                                state: {
                                  senderName: getFullName(invite),
                                  goalId: invite.goal_id,
                                  sender: invite.user_id
                                }
                              }
                            )
                          }
                          rejectClick={() => rejectAssessment(invite.id)}
                        />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </>
        )
      ) : (
        <LeftJustifyCardContent>
          <NotificationBodyText fontSize={smallItemView ? '16px' : '20px'}>
            {t('assessments.noOutstandingRating')}
          </NotificationBodyText>
        </LeftJustifyCardContent>
      )}
      {requestError === true ? (
        <CardContent>
          <RequestRetryAlert />
        </CardContent>
      ) : null}

      <SimpleNotificationDialog
        open={dialogOpen}
        onClose={onRejectDialogClose}
        titleText={t('assessments.rejectRating')}
        notificationText={t('assessments.willRemoveRequest')}
        confirmText={t('assessments.keepIt')}
        cancelText={t('assessments.reject')}
        cancelValue={1}
      />
    </>
  )
}

export default AssessmentsList
