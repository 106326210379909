import { Box } from '@mui/material'

const ImageContainer = ({
  src,
  alt,
  maxWidth = '100%',
  maxHeight = '500px',
  ...otherProps
}) => {
  return (
    <Box
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      display='flex'
      justifyContent='center'
      {...otherProps}>
      <img
        src={src}
        alt={alt}
        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
      />
    </Box>
  )
}

export default ImageContainer
