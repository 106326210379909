/**
 * Intented to move deeplink router management from backend onto web server
 *   Aims to have functions replicated onto web page and allow components to handle redirecting to app
 * Original implemented javascript on web page from monark-backend that first tries to open the deep link
 *   1. if user has app installed, then they would be redirected to open the app to specified screen
 *   2. if user doesn't have app installed, then their browser wouldn't recognize the URL scheme
 *   and app wouldn't open since it's not installed. In 1 second (1000 milliseconds) user is redirected
 *   to download app from app store.
 */

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { JOURNEY_DEMO, LOGIN, SIGNUP_DIRECT } from './constants'
import { getUpdateLocalUser } from 'utils/localStorage'
import { getDeepLinkDestination } from 'utils/deepLinkActionHelper'
import MobileNotification from 'components/organisms/user/MobileNotification'

const DeepLinkAction = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const redirectWeb = async () => {
      const dest = getDeepLinkDestination(window.location.href)
      
      const { url, matchLocalAccount, params, email } = dest
      const state = { ...params }
      if (!url.includes(SIGNUP_DIRECT)) {
        state.from = url
      }

      if ((email && matchLocalAccount) || url === JOURNEY_DEMO) {
        const user = await getUpdateLocalUser(undefined, false)
        if ((email && user?.email === email) || (!email && user)) {
          navigate(url, { state })
        } else {
          if (url === JOURNEY_DEMO) {
            navigate(LOGIN, {
              state
            })
          } else {
            navigate(`${LOGIN}/${email}`, {
              state
            })
          }
        }
      } else {
        navigate(url, { state })
      }
    }

    redirectWeb()
  })

  return (<MobileNotification />)
}

export default DeepLinkAction
