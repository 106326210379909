import { CardContent } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import LinkButton from 'components/atoms/button/LinkButton'
import AssessmentCard from 'components/organisms/assessments/AssessmentCard'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ASSESSMENTS, EDIT_PROFILE, ROOT } from 'routes/constants'
import { ASSESSMENT_RECIPIENTS_URL, ASSESSMENT_RECIPIENT_STATUSES } from 'services/constants'
import { getRequest } from 'utils/api'
import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import { isUserProfileCompleted } from 'utils/userAccountHelper'
import SinglePageMessageCard from 'components/templates/SinglePageMessageCard'
import { getFullName } from 'utils/formatText'
import { useTranslation } from 'react-i18next'

const AssessmentForOthers = () => {
  const { t } = useTranslation()
  const params = useParams()
  const location = useLocation()
  const [goalId, setGoalId] = useState(location.state?.goalId || undefined)
  const [assessmentSenderName, setAssessmentSenderName] = useState(
    location.state?.senderName || ''
  )
  const [forUserId, setforUserId] = useState(
    location.state?.sender || undefined
  )
  const [requestError, setRequestError] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState(
    t('assessmentForOthers.notFoundAssessmentError')
  )
  const [completed, setCompleted] = useState(false)
  const userProfileCompleted = isUserProfileCompleted()

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(ASSESSMENT_RECIPIENTS_URL, {
        assessmentRequestId: params.assessmentRequestId
      })
      if (res.status === 200) {
        const invite = res.data.data

        if (!invite || invite.status !== ASSESSMENT_RECIPIENT_STATUSES.PENDING) {
          setRequestError(true)
          if (invite?.status === ASSESSMENT_RECIPIENT_STATUSES.COMPLETED) {
            setErrorMessage(
              t('assessmentForOthers.youCompletedAssessmentError')
            )
          } else if (invite?.status === ASSESSMENT_RECIPIENT_STATUSES.REJECTED) {
            setErrorMessage(
              t('assessmentForOthers.previouslyRejectedThisRequestError')
            )
          } else if (invite?.status === ASSESSMENT_RECIPIENT_STATUSES.EXPIRED) {
            setErrorMessage(
              t('assessmentForOthers.assessmentExpiredError')
            )
          }
        } else {
          setGoalId(invite.goal_id)
          setAssessmentSenderName(getFullName(invite))
          setforUserId(invite.user_id)
        }
      } else {
        setRequestError(true)
      }
    }
    if (!goalId) {
      fetchData()
    }
  })

  const submittedCallback = () => {
    setCompleted(true)
  }
  return (
    <FeedbackPage>
      {requestError ? (
        <SinglePageMessageCard
          noBorder={true}
          titleText={t('assessmentForOthers.assessmentNotAvailable')}
          message={errorMessage}>
          <CardContent>
            <InCardStack>
              <LinkButton to={ASSESSMENTS}>{t('assessmentForOthers.backToAssessments')}</LinkButton>
              {userProfileCompleted === false ? (
                <LinkButton to={EDIT_PROFILE}>{t('profile.finishProfile')}</LinkButton>
              ) : null}
            </InCardStack>
          </CardContent>
        </SinglePageMessageCard>
      ) : completed ? (
        <SinglePageMessageCard
          noBorder={true}
          titleText={t('assessmentForOthers.thankYouForRatings')}
          message={`${t('assessmentForOthers.informationProvidedGreatlyHelp.part1')}${assessmentSenderName}${t('assessmentForOthers.informationProvidedGreatlyHelp.part2')}`}>
          <CardContent>
            <InCardStack>
              <LinkButton to={ROOT}>{t('common.done')}</LinkButton>
            </InCardStack>
          </CardContent>
        </SinglePageMessageCard>
      ) : (
        <AssessmentCard
          goalId={goalId}
          title={`${t('assessmentForOthers._360RatingFor')}${assessmentSenderName}`}
          introduction={
            <>
              {`${t('assessmentForOthers.askYouSomeQuestionsAbout.part1')}${assessmentSenderName}${t('assessmentForOthers.askYouSomeQuestionsAbout.part2')}` +
                `${t('assessmentForOthers.askYouSomeQuestionsAbout.part3')}${assessmentSenderName}${t('assessmentForOthers.askYouSomeQuestionsAbout.part4')}`}
              <br />
              {`${assessmentSenderName} ${t('assessmentForOthers.userReceiveAverageRating.part1')}` +
                `${t('assessmentForOthers.userReceiveAverageRating.part2')}` +
                `${t('assessmentForOthers.userReceiveAverageRating.part3')}`}
            </>
          }
          startButtonText={t('assessmentForOthers.begin360Assessment')}
          forUserId={forUserId}
          assessmentTitle={`${t('assessmentForOthers.youAreAssessing')}${assessmentSenderName}`}
          assessmentRequestId={params.assessmentRequestId}
          submittedCallback={submittedCallback}
        />
      )}
    </FeedbackPage>
  )
}

export default AssessmentForOthers
