import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import colors from 'styles/colors'
import { getSizeString } from 'utils/formatText'
import {
  getMaxima,
  prepareRadarGraphData,
  processData
} from 'utils/radarGraphHelper'
import {
  VictoryArea,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryPolarAxis,
  VictoryScatter,
  VictoryTheme
} from 'victory'

const CompactRadarGraph = ({ assessmentData, size = 250 }) => {
  const [graphData, setGraphData] = useState([])
  const [maximaData, setMaximaData] = useState([])

  useEffect(() => {
    if (assessmentData?.radarChartData) {
      const characterData = prepareRadarGraphData(assessmentData.radarChartData)
      setGraphData(processData(characterData))
      const maxima = getMaxima(characterData)
      setMaximaData(maxima)
    }
  }, [assessmentData])

  const calculateLabelDx = angle => Math.cos(angle) * -4
  const calculatyDy = angle => Math.sin(angle) * 6
  return (
    <Box
      width='100%'
      height={getSizeString(size)}
      display='flex'
      justifyContent='center'
      alignContent='center'
      position='relative'
      overflow='hidden'
      >
      <Box
        width={getSizeString(size) * 2}
        height='200%'
        position='absolute'
        top='-50%'>
        {Object.keys(maximaData).length > 0 ? (
          <VictoryChart
            polar
            theme={VictoryTheme.material}
            width={250}
            domain={{ y: [0, 1] }}>
            {/* Outer dark grey circle */}
            <VictoryPolarAxis
              tickFormat={() => ''}
              style={{
                axis: { strokeWidth: 0.5, opacity: 1 }
              }}
            />

            {/* All the labels */}
            {Object.entries(maximaData).map(([key, value], index) => {
              const angle =
                (index * 2 * Math.PI) / Object.keys(maximaData).length

              return (
                <VictoryPolarAxis
                  key={`vPA ${index}`}
                  dependentAxis
                  style={{
                    axisLabel: {
                      fontSize: 10,
                      fontWeight: '600',
                      fill: colors.BondiBlue
                    },
                    axis: { stroke: colors.Aluminium, opacity: 0.2 },
                    grid: {
                      stroke: colors.Aluminium,
                      opacity: 0.5,
                      strokeWidth: 0.1,
                      strokeDasharray: [5, 4]
                    }
                  }}
                  domain={{ y: [0, 0.8] }}
                  tickFormat={() => ''}
                  label={key}
                  labelPlacement='vertical'
                  axisValue={index + 1}
                  axisLabelComponent={
                    <VictoryLabel
                      dx={calculateLabelDx(angle)}
                      dy={calculatyDy(angle)}
                      backgroundComponent={<rect rx={2} ry={2} />}
                      backgroundStyle={{ fill: colors.White, opacity: 0.9 }}
                      backgroundPadding={{
                        left: 6,
                        right: 6,
                        top: 2,
                        bottom: 2
                      }}
                    />
                  }
                />
              )
            })}

            {/* Filled dots on vertices */}
            {graphData[1].map((value, index) => {
              return (
                <VictoryScatter
                  key={`vPA ${index}`}
                  size={1}
                  data={[{ x: value.x, y: value.y }]}
                  style={{
                    data: { fill: colors.Aluminium, opacity: 1 }
                  }}
                />
              )
            })}
            {graphData[0].map((value, index) => {
              return (
                <VictoryScatter
                  key={`vPA ${index}`}
                  size={1}
                  data={[{ x: value.x, y: value.y }]}
                  style={{
                    data: { fill: colors.BondiBlue, opacity: 1 }
                  }}
                />
              )
            })}
            {/* Polygon-fill area */}
            <VictoryGroup
              colorScale={[colors.BondiBlue, colors.Aluminium, 'transparent']}
              style={{ data: { fillOpacity: 0.15, strokeWidth: 0.1 } }}>
              {graphData.map((data, i) => {
                return <VictoryArea key={`vPA ${i}`} data={data}></VictoryArea>
              })}
            </VictoryGroup>
          </VictoryChart>
        ) : null}
      </Box>
    </Box>
  )
}

export default CompactRadarGraph
