import { useEffect, useState } from 'react'
import { Typography, TextField, MenuItem, CardContent } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import { useTranslation } from 'react-i18next'
import DrillDownChangeTable from 'components/molecules/adminDashboard/insights/DrillDownChangeTable'
import { getGoals } from 'utils/getSupportingInfo'
import ScoreChangeDisplayBar from 'components/molecules/assessments/ScoreChangeDisplayBar'
import { getDisplayScore } from 'utils/formatText'
import { SelfOtherToggle } from 'components/molecules/ChangeTable'
import ExportAssessmentButton from 'components/atoms/button/ExportAssessmentButton'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { getRequest } from 'utils/api'
import { SUPER_LEADER_SCORE } from 'services/constants'
import { NotificationBodyText } from 'components/atoms/Text'

const GrowthDetails = ({ scoreData, cohortId, organizationId }) => {
  const { t } = useTranslation()

  const [selfState, setSelfState] = useState(true)
  const [goals, setGoals] = useState([])
  const [selectedGoal, setSelectedGoal] = useState('')
  const [displayData, setDisplayData] = useState({})
  const [pathDetails, setPathDetails] = useState(null)
  const [hasOlderData, setHasOlderData] = useState(false)
  const [behaviourInfoData, setBehaviourInfoData] = useState([])
  const [loading, setLoading] = useState(false)

  const selectProps = (attr, lable) => ({
    displayEmpty: true,
    renderValue:
      attr === ''
        ? () => <div style={{ opacity: 0.4 }}>{lable}</div>
        : undefined
  })

  useEffect(() => {
    const fetchGoal = async () => {
      const resGoals = await getGoals()
      const goals = resGoals?.filter(g => scoreData.goalIds.indexOf(g.id) >= 0)
      setGoals(goals)
      setSelectedGoal(goals[0])
      fetchGrowthDetails(goals[0])
    }
    if (scoreData.goalIds) {
      fetchGoal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreData])

  const fetchGrowthDetails = async g => {
    setLoading(true)
    const res = await getRequest(SUPER_LEADER_SCORE, {
      organizationId,
      cohortId,
      goalId: g?.id
    })
    if (res.status === 200) {
      setPathDetails(res.data.data?.growthBreakdown)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (selectedGoal !== '') {
      fetchGrowthDetails(selectedGoal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoal])

  useEffect(() => {
    if (!pathDetails) return
    const olderData = pathDetails.old
    const newerData = pathDetails.current

    // Set scoreField based on selfState
    const scoreField = selfState ? 'selfScore' : 'othersScore'
    const newDisplayData = getDisplayScore(
      newerData[scoreField],
      olderData?.[scoreField]
    )
    if (olderData?.timestamp) {
      setHasOlderData(true)
      newDisplayData.oldTimeStamp = olderData.timestamp
    } else {
      setHasOlderData(false)
    }
    newDisplayData.newTimeStamp = newerData.timestamp
    setDisplayData(newDisplayData)
  }, [selfState, pathDetails])

  const getValue = (value, valueField) => {
    let nValue = valueField ? value[valueField] : value
    nValue = nValue?.toFixed(1)
    return nValue || '-'
  }

  const getBehaviourInfoData = valueField => {
    const data = pathDetails
    const hasOlderData = data.old?.timestamp ? true : false
    return [
      ...Object.entries(data.current.virtues).map(
        ([behavior, value], index) => {
          const hasOldScore = hasOlderData && data.old.virtues?.[behavior]
          const oldScore = hasOldScore
            ? getValue(data.old.virtues[behavior], valueField) || '-'
            : '-'
          const newScore = getValue(value, valueField)
          return [behavior, oldScore, newScore]
        }
      )
    ]
  }

  useEffect(() => {
    if (!pathDetails) return

    const newData = {
      self: {
        scoreField: 'selfScore',
        data: getBehaviourInfoData('avgSelfRating')
      },
      others: {
        scoreField: 'othersScore',
        data: getBehaviourInfoData('avgOthersRating')
      }
    }
    setBehaviourInfoData(newData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathDetails])

  return (
    <>
      <OverlayLoading visible={loading} />
      <RowStack sx={{ justifyContent: 'space-between' }}>
        <Typography variant='h3'>
          {t('insights.assessingTeamChange')}
        </Typography>

        <TextField
          select
          id='goal-select'
          value={selectedGoal}
          onChange={e => setSelectedGoal(e.target.value)}
          SelectProps={selectProps(
            selectedGoal,
            t('company.selectLearningPath')
          )}
          sx={{
            '& .MuiSelect-select': {
              padding: '12px'
            }
          }}>
          {goals.map(g => (
            <MenuItem value={g} key={g.id}>
              {g.name}
            </MenuItem>
          ))}
        </TextField>
      </RowStack>

      {pathDetails ? (
        <>
          <RowStack sx={{ justifyContent: 'end' }}>
            <ExportAssessmentButton
              title={t('insights.assessingTeamChange')}
              data={scoreData.growthBreakdown}
              behaviourData={behaviourInfoData}
              fileName='assessing-team-change'
            />
          </RowStack>

          <SelfOtherToggle selfState={selfState} setSelfState={setSelfState} />
          <ScoreChangeDisplayBar
            displayData={displayData}
            hasOlderData={hasOlderData}
          />

          <DrillDownChangeTable
            data={pathDetails}
            showBehaviors={true}
            valueField={selfState ? 'avgSelfRating' : 'avgOthersRating'}
          />
        </>
      ) : (
        <CardContent>
          <NotificationBodyText>
            {t('insights.noGrowDetail')}
          </NotificationBodyText>
        </CardContent>
      )}
    </>
  )
}

export default GrowthDetails
