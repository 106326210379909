import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import { PageHeader } from 'components/atoms/Text'
import AssessmentsList from 'components/organisms/assessments/AssessmentsList'
import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import SinglePageStack from 'components/templates/SinglePageStack'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Assessments = () => {
  const { t } = useTranslation()
  const location = useLocation()
  return (
    <FeedbackPage>
      <SinglePageStack>
        {location.state?.from ? <BackIconTextButton /> : null}
        <PageHeader>{t('assessments.incomingRatingRequests')}</PageHeader>
        <AssessmentsList />
      </SinglePageStack>
    </FeedbackPage>
  )
}

export default Assessments
