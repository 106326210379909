import { Avatar, Chip, Typography, Stack, alpha } from '@mui/material'
import { getNameIntials, getFullName, desctructUser } from 'utils/formatText'
import RowStack from 'components/atoms/container/RowStack'
import colors from 'styles/colors'

const AvatarStack = ({ user, width = '100%', chipSx, ...chipProps }) => {
  const { profile_image: initialProfileImage } = user
  const profileImage = initialProfileImage || user.profileImage
  const nameInitials = getNameIntials(user)
  const fullName = getFullName(user)
  const [firstName, ,] = desctructUser(user)

  return (
    <RowStack spacing={0} width={width}>
      <Chip
        {...chipProps}
        avatar={
          profileImage ? (
            <Avatar alt={nameInitials} src={profileImage} />
          ) : (
            <Avatar>{nameInitials}</Avatar>
          )
        }
        sx={[
          {
            background: 'transparent'
          },
          chipSx
        ]}
      />

      <Stack padding={0} width={'80%'}>
        <Typography variant='body2' fontWeight={700} width={'100%'} noWrap>
          {fullName}
        </Typography>

        {firstName && (
          <Typography
            variant='caption'
            color={alpha(colors.DarkGrey, 0.5)}
            width={'100%'}
            noWrap>
            {user.email}
          </Typography>
        )}
      </Stack>
    </RowStack>
  )
}

export default AvatarStack
