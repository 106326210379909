import React from 'react'
import { Box } from '@mui/material'
import StyledButton from 'components/atoms/button/StyledButton'
import HeaderWithCarousel, {
  CarouselWelcome
} from 'components/atoms/container/HeaderWithCarousel'
import InfoCard from 'components/atoms/InfoCard'
import { GrowingPlantIcon } from 'components/atoms/Icons'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'

const CheckInSurveyLanding = ({ handleBegin }) => {
  const { t } = useTranslation()
  const name = getLocalUser().firstName || t('rolePlay.upcomingLeader')

  return (
    <HeaderWithCarousel
      customWidth={270}
      welcomeElement={
        <CarouselWelcome
          headLine={`${t('rolePlay.hi')} , ${name}`}
          title={t('checkInSurvey.oneOneOneCheckinSurvey')}
          description={t('checkInSurvey.welcomeToYourPersonalizedOneOnOne')}>
          <Box sx={{ paddingTop: 6 }}>
            <StyledButton
              size='medium'
              sx={{ width: 170, textWrap: 'nowrap' }}
              onClick={handleBegin}>
              {t('checkInSurvey.beginCheckin')}
            </StyledButton>
          </Box>
        </CarouselWelcome>
      }
      cards={t('checkInSurvey.landingPageCards', { returnObjects: true }).map(
        d => (
          <InfoCard
            icon={<GrowingPlantIcon sx={{ fontSize: 64 }} />}
            title={d.title}
            content={d.content}
          />
        )
      )}
    />
  )
}

export default CheckInSurveyLanding
