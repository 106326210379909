import { Close } from '@mui/icons-material'
import {
  Dialog,
  Button,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import LowFocusButton from 'components/atoms/button/LowFocusButton'
import StyledButton from 'components/atoms/button/StyledButton'
import InCardStack from 'components/atoms/InCardStack'
import colors from 'styles/colors'

export const InputDialog = props => {
  const {
    handleCancel,
    titleText,
    open,
    confirmText = 'Ok',
    cancelText = 'Cancel',
    alignItems = 'center',
    formik,
    titleVariant = 'h3',
    width = 500,
    dialogTitleSx = {},
    buttonRowSx = {},
    buttonSx = {},
    children
  } = props

  const handleSubmit = e =>
    formik ? formik.handleSubmit(e) : e?.preventDefault()
  return (
    <Dialog onClose={handleCancel} open={open}>
      <RowStack
        sx={[{ padding: 12, justifyContent: 'space-between' }, dialogTitleSx]}
      >
        <Typography variant={titleVariant}>{titleText}</Typography>
        <IconButton
          sx={{ float: 'right', paddingTop: '2px' }}
          onClick={() => handleCancel()}>
          <Close />
        </IconButton>
      </RowStack>
      <form onSubmit={handleSubmit}>
        <Stack
          width={width}
          alignItems={alignItems}
          sx={{ paddingTop: 0 }}
          spacing={5}>
          {children}
          <Stack
            direction='row'
            spacing={10}
            display='flex'
            justifyContent='center'
            sx={buttonRowSx}
          >
            {cancelText ? (
              <LowFocusButton onClick={() => handleCancel()}>
                {cancelText}
              </LowFocusButton>
            ) : null}
            <Button
              sx={buttonSx}
              type={formik ? 'submit' : undefined}
              onClick={formik ? null : handleCancel}>
              {confirmText}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}

export const WideInputDialog = props => (
  <InputDialog
    titleVariant='h1'
    width={520}
    buttonRowSx={{ padding: 0, paddingTop: 5 }}
    buttonSx={{ width: '100%', borderRadius: 50 }}
    dialogTitleSx={{
      color: colors.LightGrey400,
      paddingBottom: 10,
    }}
    {...props}
  />
)

export const SimpleInputDialog = ({
  open,
  children,
  handleCancel,
  handleConfirm,
  confirmText,
  cancelText,
}) => {
  return (
    <Dialog open={open}>
      <InCardStack
        sx={{
          width: 456,
          padding: 12,
          paddingBottom: 16,
        }}
      >
        {children}
        <RowStack justifyContent='center' sx={{ paddingTop: 10 }}>
          <StyledButton inverseTheme onClick={handleCancel}>
            {cancelText}
          </StyledButton>
          <StyledButton onClick={handleConfirm}>
            {confirmText}
          </StyledButton>
        </RowStack>
      </InCardStack>
    </Dialog>
  )
}

export default InputDialog
