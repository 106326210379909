import {
  alpha,
  Grid,
  Icon,
  Stack,
  Typography,
  useTheme,
  Box,
  CardContent
} from '@mui/material'
import { PageHeader, Subtitle4 } from 'components/atoms/Text'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import InCardStack from 'components/atoms/InCardStack'
import ShadedScoreDisplayBar from 'components/atoms/ShadedScoreDisplayBar'
import colors from 'styles/colors'
import ScaleBar from 'components/atoms/ScaleBar'
import { capitalizeFirstLetter, getDisplayScore } from 'utils/formatText'
import ScoreChangeDisplayBar from 'components/molecules/assessments/ScoreChangeDisplayBar'
import { useTranslation } from 'react-i18next'

const AssessmentBehaviourComparisonCard = ({ data, behaviour }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const newData = data[0].behaviourInfoData.find(
    i => i.name.trim() === behaviour
  )
  const oldData = data[1].behaviourInfoData.find(
    i => i.name.trim() === behaviour
  )
  const behaviourDescription = newData.description

  const constScoreChangeBarData = scoreField => {
    const displayData = getDisplayScore(
      oldData[scoreField],
      newData[scoreField]
    )
    displayData.oldTimeStamp = data[1].ratingTimeStamp
    displayData.newTimeStamp = data[0].ratingTimeStamp
    return displayData
  }
  const displayData = [
    {
      name: 'self',
      label: t('assessmentTimeline.assessmentChange', {
        type: t('goalsArea.self')
      }),
      icon: <PersonOutlineOutlinedIcon fontSize='inherit' />,
      displayScores: constScoreChangeBarData('avgSelfRating')
    }
  ]
  const enableOthersAssessment = data[0]?.goalData?.enableOthersAssessment
  if (enableOthersAssessment) {
    displayData.push({
      name: 'others',
      label: t('assessmentTimeline.assessmentChange', {
        type: t('goalsArea.others')
      }),
      icon: <GroupsOutlinedIcon fontSize='inherit' />,
      displayScores: constScoreChangeBarData('avgOthersRating')
    })
  }
  const scaleStartPosition = 0.2
  const getScoreBarWidth = score => {
    return (score * (1 - scaleStartPosition)) / 5 + scaleStartPosition
  }
  return (
    <>
      <Stack spacing={5} padding={0}>
        <PageHeader>{behaviour}</PageHeader>
        <Typography variant='body2'>{behaviourDescription}</Typography>
      </Stack>
      <Grid
        container
        paddingTop='20px'
        width='100%'
        display='flex'
        justifyContent='center'>
        {displayData.map(s => (
          <Grid
            item
            xs={6}
            key={s.name}
            minWidth='580px'
            sx={
              s.name === 'self' && enableOthersAssessment
                ? {
                    paddingRight: '20px',
                    borderRight: '1px solid ' + colors.Solitude
                  }
                : { paddingLeft: '20px' }
            }>
            <Stack padding={0} spacing={5}>
              <InCardStack direction='row' spacing={5}>
                <Icon
                  sx={{
                    width: '35px',
                    height: '35px',
                    fontSize: '35px',
                    color: colors.Aluminium
                  }}>
                  {s.icon}
                </Icon>
                <Subtitle4>{s.label}</Subtitle4>
              </InCardStack>
              <ScoreChangeDisplayBar
                displayData={s.displayScores}
                hasOlderData={true}
                displayEmpty={true}
              />
              <Box height='80px'>
                {s.name === 'self' ? (
                  <Typography variant='h3' paddingTop='30px'>
                    Questions
                  </Typography>
                ) : s.displayScores.newScore &&
                  s.displayScores.oldScore ? null : (
                  <CardContent>
                    <Typography
                      variant='body2'
                      color='primary'
                      fontStyle='italic'
                    >
                      You need to have others assess you to understand how
                      others think you are progressing
                    </Typography>
                  </CardContent>
                )}
              </Box>
              {oldData.questions.map((value, index) => {
                const scoreField = s.name + 'Score'
                const barLabel = capitalizeFirstLetter(s.name)
                const newScore = newData.questions[index][scoreField]
                const oldScore = value[scoreField]
                const changed =
                  newScore && oldScore
                    ? ((newScore - oldScore) / oldScore) * 100
                    : null
                return (
                  <Stack padding={0} key={`${s.name}-${index}`}>
                    <Typography>
                      {index + 1}. {value.selfQuestion}
                    </Typography>
                    <Grid container flex={1} width='100%' paddingTop={5}>
                      <Grid item xs={9}>
                        <Stack padding={0} paddingBottom={5}>
                          <ShadedScoreDisplayBar
                            width={getScoreBarWidth(oldScore)}
                            height='40px'
                            backgroundColor={alpha(
                              theme.palette.primary.main,
                              0.2
                            )}
                            labelComponent={
                              <Typography variant='string'>
                                {barLabel}
                              </Typography>
                            }
                            scoreComponent={
                              <Typography fontWeight='bold'>
                                {oldScore?.toFixed(1)}
                              </Typography>
                            }
                          />
                          <ShadedScoreDisplayBar
                            width={getScoreBarWidth(newScore)}
                            height='40px'
                            backgroundColor={alpha(colors.LightSalmon, 0.3)}
                            labelComponent={<Typography>{barLabel}</Typography>}
                            scoreComponent={
                              <Typography fontWeight='bold'>
                                {newScore?.toFixed(1)}
                              </Typography>
                            }
                          />
                        </Stack>
                        <ScaleBar
                          startPosition={scaleStartPosition}
                          startNumber={0}
                          endNumber={5}
                        />
                      </Grid>
                      <Grid item xs={3} minWidth='100px'>
                        {changed ? (
                          <InCardStack
                            direction='row'
                            height='80px'
                            spacing={0}>
                            <Icon
                              sx={{
                                height: '80px',
                                width: '70px',
                                color: colors.Orangish,
                                fontSize: '80px'
                              }}>
                              {changed < 0 ? (
                                <SouthIcon
                                  fontSize='inherit'
                                  sx={{ transform: 'scaleX(0.9)' }}
                                />
                              ) : (
                                <NorthIcon
                                  fontSize='inherit'
                                  sx={{ transform: 'scaleX(0.9)' }}
                                />
                              )}
                            </Icon>
                            <InCardStack spacing={0}>
                              <Typography color={colors.Orangish}>
                                {changed > 0 ? '+' : null}
                                {changed.toFixed(0)}%
                              </Typography>
                              <Typography color={colors.Orangish}>
                                {t('assessmentTimeline.change')}
                              </Typography>
                            </InCardStack>
                          </InCardStack>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Stack>
                )
              })}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default AssessmentBehaviourComparisonCard
