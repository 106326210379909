import { Dialog, IconButton, Stack } from '@mui/material'
import { Close } from '@mui/icons-material'
import RolePlayInteractionCard from './RolePlayInteractionCard'
import { useTranslation } from 'react-i18next'

const RolePlayCompletedPopup = ({
  open,
  handleClose,
  rolePlay,
  handleReview
}) => {
  const { t } = useTranslation()
  const handleReviewClick = () => {
    handleReview()
    handleClose()
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack width={300} alignItems='end' display='flex' padding={0}>
        <IconButton
          sx={{ width: 'fit-content', paddingTop: 5 }}
          onClick={handleClose}>
          <Close />
        </IconButton>
        <RolePlayInteractionCard
          rolePlay={rolePlay}
          inPopup={true}
          onButtonClick={handleReviewClick}
          buttonText={t('rolePlay.viewYourResults')}
        />
      </Stack>
    </Dialog>
  )
}
export default RolePlayCompletedPopup
