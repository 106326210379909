import { PERMISSION_LEVEL } from 'routes/constants'
import { getLocalUser } from './localStorage'
import { USER_ROLE_TYPES } from 'services/constants'

//Function to do private page or other permission check using local storage
export const permitted = (permission, checkVerified = false) => {
  const localUser = getLocalUser()
  let result = false
  if (permission === PERMISSION_LEVEL.SIGNEDIN) {
    // For now, as long as user exist in local storage, access is granted
    if (localUser) {
      result = true
    }
  }
  if (permission === PERMISSION_LEVEL.LICENSED) {
    if (localUser?.hasLicense) {
      result = true
    }
  }
  if (permission === PERMISSION_LEVEL.ADMINISTRATOR) {
    if (
      [USER_ROLE_TYPES.SUPER_ADMIN, USER_ROLE_TYPES.ADMINISTRATOR].includes(
        localUser?.role
      )
    ) {
      result = true
    } else {
      result = false
    }
  }
  if (permission === PERMISSION_LEVEL.COACH) {
    if (
      [
        USER_ROLE_TYPES.SUPER_ADMIN,
        USER_ROLE_TYPES.COACH,
        USER_ROLE_TYPES.ADMINISTRATOR
      ].includes(localUser?.role)
    ) {
      result = true
    } else {
      result = false
    }
  }
  if (permission === PERMISSION_LEVEL.SUPER_ADMIN) {
    if (localUser?.role === USER_ROLE_TYPES.SUPER_ADMIN) {
      result = true
    } else {
      result = false
    }
  }

  if (checkVerified) {
    if (localUser.is_verified) {
      result = true
    } else {
      result = false
    }
  }

  return result
}

export const isAllowedFeature = featureKey => {
  const user = getLocalUser()

  return user.allowedFeatures?.some(feature => feature.key === featureKey)
}
