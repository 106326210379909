import { CardContent } from '@mui/material'

const LeftJustifyCardContent = props => {
  const { children, sx, ...otherProps } = props
  return (
    <CardContent {...otherProps} sx={[sx, { justifyContent: 'left' }]}>
      {children}
    </CardContent>
  )
}

export default LeftJustifyCardContent
