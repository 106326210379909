import * as yup from 'yup'
import { useState } from 'react'
import { TextField, Grid } from '@mui/material'
import AvatarChip from 'components/atoms/AvatarChip'
import { validationsRules } from 'utils/formValidations'
import { ActionText } from 'components/atoms/Text'
import { Add } from '@mui/icons-material'
import { useFormik } from 'formik'
import EmailsImportInput from './EmailsImportInput'
import { useTranslation } from 'react-i18next'

const UserInviteForm = ({
  disabled,
  emails,
  setEmails,
  isSuperAdminView = false
}) => {
  const { t } = useTranslation()
  const [invite, setInvite] = useState({
    email: '',
    firstName: '',
    lastName: '',
  })

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: yup.object({
      email: validationsRules.optionalEmail
    })
  })

  const handleInputChange = (field) => (event) => {
    setInvite({
      ...invite,
      [field]: event.target.value,
    })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      addRecipients();
    }
  }

  const addRecipients = async () => {
    try {
        await Promise.resolve(validationsRules.email.validate(invite.email))
        formik.resetForm()
        const existing = emails.find(e => e.email.toLowerCase() === invite.email.toLowerCase())
        if (!existing) {
          const inviteObj = getRecipientObject()
          let newEmails = [...emails, inviteObj];
          setEmails(newEmails)
          setInvite({
            email: '',
            firstName: '',
            lastName: '',
          })
        } else {
          formik.setFieldError('email', t('company.emailAddressAlreadyAdded'))
          formik.setTouched({ email: true }, false)
        }
      } catch(e) {
        formik.setFieldError('email', t('shareMyResults.recpEmailValidation'))
        formik.setTouched({ email: true }, false)
        return
      }
  }

  const getRecipientObject = () => {
    const inviteObj = {};
    for (const key in invite) {
      if (invite[key] !== '') {
        inviteObj[key] = invite[key];
      }
    }

    return inviteObj;
  }

  const handleDelete = (index) => () => {
    let newEmails = emails.slice()
    newEmails.splice(index, 1)
    setEmails(newEmails)
  }

  return (
    <Grid container rowSpacing={5}>
        {isSuperAdminView ? (
          <Grid container item md={12} justifyContent="flex-end" mt={-20}>
            <EmailsImportInput 
              emails={emails}
              setEmails={setEmails}
            />
          </Grid>
        ) : null}
        {emails.length > 0 ? (
          <Grid item md={12}>
              {emails.map((option, index) => (
                  <AvatarChip
                      key={option.email}
                      user={option}
                      chipNameWithEmail={true}
                      onDelete={handleDelete(index)}
                      sx={{ mr: 4, mb: 4 }}
                  />
              ))}
          </Grid>
        ) : null }
        <Grid item md={6}>
            <TextField
                placeholder={t('company.firstName')}
                fullWidth
                disabled={disabled}
                sx={{
                    '& .MuiOutlinedInput-root': { minWidth: '80%', width: '98%' }
                    }}
                value={invite.firstName}
                onChange={handleInputChange('firstName')}
            />
        </Grid>
        <Grid item md={6}>
            <TextField
                placeholder={t('company.lastName')}
                fullWidth
                disabled={disabled}
                sx={{
                    '& .MuiOutlinedInput-root': { minWidth: '80%' },
                    }}
                value={invite.lastName}
                onChange={handleInputChange('lastName')}
            />
        </Grid>
        <Grid item md={12}>
            <TextField
                error={formik.touched.email && Boolean(formik.errors.email)}
                placeholder={t('overview.email')}
                data-testid='invite-user-input'
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                disabled={disabled}
                sx={{
                    '& .MuiOutlinedInput-root': { minWidth: '100%' },
                    }}
                value={invite.email}
                onChange={handleInputChange('email')}
                onKeyDown={handleKeyDown}
            />
        </Grid>

        <Grid item md={12} >
            <ActionText
                display='flex'
                justifyContent='center'
                width='fit-content'
                onClick={() => addRecipients()}
                >
                <Add color='primary' /> {t('company.addRecipient')}
            </ActionText>
        </Grid>
    </Grid>
  )
}

export default UserInviteForm
