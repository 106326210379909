import { getLocalUser } from 'utils/localStorage'

export const initPendo = loggedIn => {
  if (
    process.env.REACT_APP_NODE_ENV !== 'staging' &&
    process.env.REACT_APP_NODE_ENV !== 'production'
  ) {
    return
  }

  if (window.pendo?.getAccountId && !window.pendo.getAccountId()) {
    try {
      if (loggedIn) {
        const localUser = getLocalUser()
        window.pendo.initialize({
          visitor: {
            id: localUser?.fullName, // Required if user is logged in, default creates anonymous ID
            email: localUser.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: localUser.fullName // Recommended if using Pendo Feedback
            // role:         // Optional
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
          account: {
            id: localUser.id.toString()
          }
        })
      } else {
        window.pendo.initialize({
          visitor: {
            id: 'Guest'
          },
          account: {
            id: '0'
          }
        })
      }
    } catch (error) {
      console.log(error.message)
    }
  } else if (!window.pendo?.getAccountId) {
    console.log('Pendo initialization failed. Ad blocker might be blocking.')
  }
}

export const clearPendoSession = () => {
  if (
    process.env.REACT_APP_NODE_ENV !== 'staging' &&
    process.env.REACT_APP_NODE_ENV !== 'production'
  ) {
    return
  }
  try {
    window.pendo?.clearSession()
  } catch (error) {
    console.log(error.message)
  }
}
