import { Alert, CardContent } from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import FormCard from 'components/templates/FormCard'
import SimplePage from 'components/templates/SimplePage'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LOGIN } from 'routes/constants'
import { VERIFY_EMAIL_URL } from 'services/constants'
import { postRequest } from 'utils/api'

const VerifyEmail = () => {
  const [searchParams] = useSearchParams()
  const [requestSuccess, setRequestSuccess] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const email = searchParams.get('e').replace(' ', '+')
  useEffect(() => {
    const postVerify = async () => {
      const res = await postRequest(
        `${VERIFY_EMAIL_URL}/${email}/${searchParams.get('t')}`
      )
      if (res.status === 204) {
        setRequestSuccess(true)
      } else {
        setRequestSuccess(false)
        if (res.status === 422) {
          setErrorMessage(`No matching record for user with email: ${email}.`)
        } else if (res.status === 409) {
          setErrorMessage(`${email} has already been verified.`)
        } else {
          setErrorMessage(`Error while verifying ${email}.`)
        }
      }
    }
    postVerify()
  }, [searchParams, email])

  return (
    <SimplePage>
      <FormCard>
        <CardContent>
          {requestSuccess === true ? (
            <Alert severity='success'>{`${email} has been verified successfully.`}</Alert>
          ) : requestSuccess === false ? (
            <Alert severity='error'>{errorMessage}</Alert>
          ) : null}
        </CardContent>
        <CardContent>
          <LinkButton to={LOGIN}>Back to Log In</LinkButton>
        </CardContent>
      </FormCard>
    </SimplePage>
  )
}

export default VerifyEmail
