import { Box, Typography } from '@mui/material'
import AssessmentCard from 'components/organisms/assessments/AssessmentCard'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import SelfAssessmentReport from 'components/molecules/assessments/SelfAssessmentReport'
import InCardStack from 'components/atoms/InCardStack'
import { TaskSuccessIcon } from 'components/atoms/Icons'
import { getLocalUser } from 'utils/localStorage'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import { getRequest } from 'utils/api'
import { ASSESSMENT_BY_GOAL_URL } from 'services/constants'
import OverlayLoading from 'components/atoms/OverlayLoading'
import ConfirmCancelDialog from 'components/molecules/notificationOverlay/ConfirmCancelDialog'
import { ADD_ASSESSMENT_RATERS, HOME } from 'routes/constants'
import { radarGraphLocationString } from 'components/molecules/assessments/RadarGraph'
import { useTranslation } from 'react-i18next'

const celebrate = require('assets/images/celebrate.png')

const AssessmentSelf = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const fromBehaviorPage =
    window.history.state?.location === radarGraphLocationString
  const goalId = location.state?.goalId
  const userId = getLocalUser().id
  const [goalData, setGoalData] = useState({})
  const [completed, setCompleted] = useState(fromBehaviorPage)
  const [resultData, setResultData] = useState(
    fromBehaviorPage ? window.history.state.graphData : {}
  )
  const [openAssessmentDialogOpen, setOpenAssessmentDialogOpen] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    ref.current?.scrollIntoView({ block: 'nearest' })
  }, [completed])

  useState(() => {
    const fetchData = async () => {
      const data = await getGoalInfoById(goalId)
      const res = await getRequest(ASSESSMENT_BY_GOAL_URL, { goal_id: goalId })
      if (res.status === 200) {
        if (res.data.data.isOpen) {
          setOpenAssessmentDialogOpen(true)
        }
      }
      setGoalData(data)
      setLoading(false)
    }
    if (goalId && !completed) {
      setLoading(true)
      fetchData()
    }
  }, [completed, goalId])

  const submittedCallback = result => {
    setResultData(result)
    setCompleted(true)
  }

  const handleOpenAssessmentRedirect = addRaters => {
    if (addRaters) {
      navigate(`${ADD_ASSESSMENT_RATERS}/${goalId}`)
    } else {
      navigate(HOME)
    }
  }
  return (
    <>
      <OverlayLoading open={loading} />
      {completed ? (
        <FeedbackPage
          headerDivider={false}
          pageHeader={
            <InCardStack
              width='100%'
              justifyContent='flex-end'
              sx={{
                backgroundImage: `url(${celebrate})`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                paddingTop: '80px',
                paddingBottom: '20px'
              }}>
              <Box ref={ref} />
              <TaskSuccessIcon />
              <Typography textAlign='center' variant='h3'>
                {t('assessmentTimeline.completingSelfAssessment.part1')} <br /> {t('assessmentTimeline.completingSelfAssessment.part2')}
              </Typography>
            </InCardStack>
          }>
          <SelfAssessmentReport resultData={resultData} />
        </FeedbackPage>
      ) : (
        <FeedbackPage>
          <AssessmentCard
            goalId={goalId}
            title={t('assessmentTimeline.capturingBaseline')}
            introduction={
              <>
                {t('assessmentTimeline.captureBaselineMeasurement')} <strong>{goalData?.leadershipPathName}</strong> .
                <br />
                <br />
                {t('assessmentTimeline.fewMinutesToAnswer')}
                <br />
              </>
            }
            startButtonText={t('assessmentTimeline.beginSelfAssessment')}
            forUserId={userId}
            assessmentTitle={t('assessmentTimeline.selfAssessment')}
            submittedCallback={submittedCallback}
            selfAssessment={true}
          />
        </FeedbackPage>
      )}
      <ConfirmCancelDialog
        open={openAssessmentDialogOpen}
        onClose={value => handleOpenAssessmentRedirect(value)}
        notificationText={`${t('assessmentTimeline.alreadyCompletedSelfassessment')} ${t('assessmentTimeline.addMore360Raters')}`}
        confirmText={t('assessmentTimeline.addRaters')}
        cancelText={t('assessmentTimeline.returnHome')}
        maxWidth='xs'
      />
    </>
  )
}

export default AssessmentSelf
