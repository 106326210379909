import { MenuItem, Typography } from '@mui/material'
import { getUserConnectionLabel } from 'utils/getSupportingInfo'
import AvatarChip from '../AvatarChip'
import { useTranslation } from 'react-i18next'

const UserConnectionMenuItem = ({
  itemProps,
  option,
  children,
  hoverPointer = true,
  shortChip = false,
  showRelationship = true,
  showEmail = false,
  showRole = false
}) => {
  const { t } = useTranslation()
  return (
    <MenuItem
      {...itemProps}
      sx={{
        width: '100%',
        '&:hover': { cursor: hoverPointer ? 'pointer' : 'default' }
      }}>
      <AvatarChip
        user={option.user ?? option}
        variant='filled'
        sx={{
          width: shortChip ? '235px' : showEmail ? undefined : '300px',
          justifyContent: 'left',
          '&:hover': { cursor: hoverPointer ? 'pointer' : 'default' }
        }}
        chipNameWithEmail={showEmail}
      />
      {showRelationship && (
        <Typography variant='body2' minWidth='120px'>
          {option.relationship
            ? getUserConnectionLabel(option.relationship, t)
            : null}
        </Typography>
      )}
      {showRole && (
        <Typography variant='body2' minWidth='120px'>
          {option.user.role
            ? t(`constants.userRoleTypes.${option.user.role}`)
            : null}
        </Typography>
      )}
      {children}
    </MenuItem>
  )
}

export default UserConnectionMenuItem
