import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import moment from 'moment'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'
import RowStack from 'components/atoms/container/RowStack'
import { Tag } from 'components/atoms/Text'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ROLE_PLAY, ROLE_PLAY_PRACTISES, ALL_SCENARIOS } from 'routes/constants'
import { useState } from 'react'
import { ROLE_PLAY_TYPES } from 'services/constants'
import StyledButton from 'components/atoms/button/StyledButton'

const RolePlayCard = ({
  data,
  setNewRolePlay = () => {}
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [activeType, setActiveType] = useState(null)

  const { title, summary, createdAt } = data

  const createdWeeksAgo = moment.utc().diff(moment.utc(createdAt), 'weeks')

  const onRolePlayPracticePath =
    location.pathname === ROLE_PLAY_PRACTISES ||
    location.pathname === ALL_SCENARIOS

  const navigateToRolePlay = () => {
    if (onRolePlayPracticePath) {
      navigate(ROLE_PLAY, {
        state: {
          rolePlayId: data.id,
          practiceType: activeType
        }
      })
    } else {
      setNewRolePlay(data.id, activeType)
    }
  }

  const OptionButton = ({ children, active, ...otherProps }) => {
    return (
      <Button
        variant={active ? 'outlined' : 'contained'}
        sx={{
          height: 40,
          paddingX: 5,
          borderRadius: '50px',
          minWidth: '45%',
          backgroundColor: active ? colors.LightBlue : colors.LightGrey100,
          color: active ? theme.palette.primary.main : colors.DarkGrey,
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
            backgroundColor: colors.LightBlue
          }
        }}
        {...otherProps}>
        <Typography
          color={active ? theme.palette.primary.main : colors.Grey400}
          fontWeight={500}
          variant='formLabel'
        >
          {children}
        </Typography>
      </Button>
    )
  }

  return (
    <BoxWithBackground
      height={362}
      width={270}
      borderRadius={3}
      boxShadow='0px 1px 15px 0px rgba(0, 0, 0, 0.10)'
      backgroundColor={theme.palette.background.paper}
      data-testid={`role-play-${data?.id}`}>
      <Box width='100%' height={16} display='flex' justifyContent='center'>
        {createdWeeksAgo <= 6 && (
          <BoxWithBackground
            sx={{
              borderBottomRightRadius: 2,
              borderBottomLeftRadius: 2,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }}
            backgroundColor={colors.LightCyan}
            paddingX={4}
            paddingY={2}
            margin={0}
          >
            <Typography variant='eyebrow' display='block' marginTop={-1.5}>
              {t('rolePlay.new')}
            </Typography>
          </BoxWithBackground>
        )}
      </Box>

      <Box
        padding={10}
        paddingTop={2}
        height='100%'
        flexDirection='column'
        justifyContent='space-between'>
        <Stack padding={0} spacing={5} height={200}>
          <RowStack
            sx={{
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Tag>{data.virtue.name}</Tag>
            <Typography variant='h6'>5-15 {t('rolePlay.mins')}</Typography>
          </RowStack>
          <Typography variant='h4'>&ldquo;{title}&rdquo;</Typography>
          <Typography variant='body1Small' sx={{ overflow: 'scroll' }}>
            {summary}
          </Typography>
        </Stack>
        <Stack padding={0} spacing={5}>
          <Typography variant='h6'>{t('rolePlay.challengeType')}</Typography>
          <RowStack justifyContent='space-around' spacing={5}>
            <OptionButton
              startIcon={<ChatOutlinedIcon />}
              onClick={() => setActiveType(ROLE_PLAY_TYPES.TEXT_CHAT)}
              active={activeType === ROLE_PLAY_TYPES.TEXT_CHAT}>
                {t('rolePlay.textChat')}
            </OptionButton>
            <OptionButton
              startIcon={<PhoneInTalkOutlinedIcon />}
              onClick={() => setActiveType(ROLE_PLAY_TYPES.LIVE_CALL)}
              active={activeType === ROLE_PLAY_TYPES.LIVE_CALL}
              fullWidth={true}>
              {t('rolePlay.liveCall')}
            </OptionButton>
          </RowStack>
          <StyledButton
            fullWidth={true}
            disabled={!activeType}
            onClick={navigateToRolePlay}>
            {t('rolePlay.startPractice')}
          </StyledButton>
        </Stack>
      </Box>
    </BoxWithBackground>
  )
}

export default RolePlayCard
