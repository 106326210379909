import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  Icon,
  Typography,
  useTheme
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import { FeedbackIcon, JourneyIcon, LearningIcon } from 'components/atoms/Icons'
import {
  JOURNEY,
  JOURNEY_NEW,
  PULSE_SURVEY_NEW,
  USER_FEEBACK
} from 'routes/constants'
import background from 'assets/images/bluePattern.svg'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import ActivityScores from './ActivityScores'
import ContactUsButton from 'components/molecules/ContactUsButton'
import { pulseSurveyName } from 'config/config'
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from 'utils/constants'

const WelcomeArea = ({ userData, hasLicense = true }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [isCurrentGoalCompleted, setIsCurrentGoalCompleted] = useState(false)
  const [showNewPath, setShowNewPath] = useState(false)

  useEffect(() => {
    if (userData) {
      const { userStates } = userData
      const currentGoalCompleted =
        userStates.completedGoals &&
        userStates.currentGoalId &&
        userStates.completedGoals.indexOf(userStates.currentGoalId) >= 0

      if (!userStates.currentGoalId || currentGoalCompleted) {
        setShowNewPath(true)
      }
      setIsCurrentGoalCompleted(currentGoalCompleted)
    }
  }, [userData, t])

  const BulletDot = () => (
    <Icon
      color='secondary'
      style={{
        paddingRight: '50px',
        fontSize: '30px'
      }}>
      circle
    </Icon>
  )
  const buttonSX = {
    height: '55px',
    fontSize: i18n.resolvedLanguage === LANGUAGES.FR ? '18px' : '20px',
    width: i18n.resolvedLanguage === LANGUAGES.FR ? '420px' : '320px',
    '&.MuiButton-root .MuiIcon-root': { fontSize: '30px' },
    '&.MuiButton-root .MuiSvgIcon-root': { fontSize: '30px' }
  }

  return (
    <>
      <Grid
        container
        height={hasLicense ? '400px' : 'fit-content'}
        marginBottom={hasLicense ? 0 : '20px'}
        borderRadius='20px'
        style={{
          backgroundImage: `url(${background})`,
          backgroundColor: colors.CaribbeanBlue,
          backgroundSize: '18%'
        }}>
        <Grid
          item
          xs={6}
          height={hasLicense ? 'fit-content' : '100%'}
          justifyContent='left'
          alignItems='center'
          display='flex'
          padding='50px'
          paddingTop={hasLicense ? '80px' : undefined}>
          <BulletDot />
          <Typography
            variant='h3'
            fontSize='30px'
            color={theme.palette.background.paper}>
            {t('welcomeArea.welcome')}
            {hasLicense ? '' : ` ${t('common.to')} Monark`},{' '}
            {userData.first_name || userData.email}!
          </Typography>
        </Grid>
        {hasLicense ? // <ActivityScores />
        null : (
          <Grid item xs={6} minHeight='250px' />
        )}
      </Grid>
      {hasLicense ? (
        <>
          <CardContent>
            <BoxWithBorder
              width='60%'
              minWidth='600px'
              borderColor={theme.palette.secondary.main}
              padding={10}
              style={{
                backgroundColor: theme.palette.background.paper,
                position: 'relative',
                top: '-200px',
                marginBottom: '-190px'
              }}>
              <InCardStack>
                <Box display='flex' alignItems='center'>
                  <BulletDot />
                  <Typography color='primary' fontSize='26px'>
                    {t('welcomeArea.heading')}
                  </Typography>
                </Box>
                {showNewPath ? (
                  <Button
                    onClick={() => navigate(JOURNEY_NEW)}
                    startIcon={<AddIcon />}
                    sx={buttonSX}>
                    {t('welcomeArea.startNewPath')}
                  </Button>
                ) : null}
                <Button
                  onClick={() => navigate(JOURNEY)}
                  startIcon={
                    <Icon>
                      <JourneyIcon />
                    </Icon>
                  }
                  sx={buttonSX}>
                  {
                    isCurrentGoalCompleted ?
                      t('welcomeArea.viewCompletedPath') :
                      t('welcomeArea.startLearning')
                  }
                </Button>
                <Button
                  onClick={() => navigate(USER_FEEBACK)}
                  startIcon={<FeedbackIcon />}
                  sx={buttonSX}>
                  {t('welcomeArea.sendFeedback')}
                </Button>
                <Button
                  onClick={() => navigate(PULSE_SURVEY_NEW)}
                  startIcon={
                    <Icon>
                      <LearningIcon />
                    </Icon>
                  }
                  sx={buttonSX}>
                  {t('common.send')} {pulseSurveyName.camelCase}
                </Button>
              </InCardStack>
            </BoxWithBorder>
          </CardContent>
          <Divider />
        </>
      ) : (
        <CardContent sx={{ justifyContent: 'right' }}>
          <BoxWithBorder
            width='45%'
            maxWidth='480px'
            padding='30px'
            position='relative'
            borderColor={theme.palette.secondary.main}
            style={{
              backgroundColor: theme.palette.background.default,
              position: 'relative',
              top: '-220px',
              marginBottom: '-230px',
              marginRight: '30px'
            }}>
            <InCardStack spacing={10}>
              <Typography variant='body2' textAlign='center' fontSize='16px'>
                {t('welcomeArea.raterOnlyAccount')} {pulseSurveyName.lowerCase}
                s.
              </Typography>
              <Typography variant='body2' textAlign='center' fontSize='16px'>
                {t('welcomeArea.learnMoreAboutMonark')}
              </Typography>
              <ContactUsButton
                buttonText={t('common.learnMore')}
                subject={t('welcomeArea.raterOnlySubject')}
              />
            </InCardStack>
          </BoxWithBorder>
        </CardContent>
      )}
    </>
  )
}

export default WelcomeArea
