import { useState, useEffect, useRef } from 'react'
import { Typography, TableRow, TableCell } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SortableHeaderCell from 'components/atoms/table/SortableHeader'
import RowStack from 'components/atoms/container/RowStack'
import InCardStack from 'components/atoms/InCardStack'
import { useTranslation } from 'react-i18next'
import { ASSESSMENT_STATUSES } from 'services/constants'
import colors from 'styles/colors'
import { getLocalUser } from 'utils/localStorage'
import AppUsageProgressBar from 'components/molecules/adminDashboard/AppUsageProgressBar'
import TableContainer from 'components/atoms/table/TableContainer'
import { requestSort } from 'utils/collectionHelper'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import moment from 'moment'
import { permitted } from 'utils/permissionCheck'
import { PERMISSION_LEVEL } from 'routes/constants'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import StyledButton from 'components/atoms/button/StyledButton'

const UserCells = ({ memeber }) => {
  const CellText = ({ children }) => {
    return (
      <Typography variant='body1' color={colors.DarkGrey}>
        {children}
      </Typography>
    )
  }
  return (
    <>
      <TableCell style={{ width: 40, padding: '0 0 0 16px' }}>
        {<ProfileAvatar user={memeber.user} />}
      </TableCell>
      <TableCell>
        <CellText>{memeber.fullName}</CellText>
      </TableCell>
      <TableCell>
        <CellText>{memeber.email}</CellText>
      </TableCell>
      <TableCell>
        <CellText>{memeber.goalName}</CellText>
      </TableCell>
    </>
  )
}

const TeamProgressTable = ({ teamData }) => {
  const { t, i18n } = useTranslation()
  const user = getLocalUser()
  moment.locale(i18n.resolvedLanguage)
  const [filters, setFilters] = useState([])
  const [displayData, setDisplayData] = useState(teamData || [])
  const [currentSort, setCurrentSort] = useState(undefined)
  const [sortOrder, setSortOrder] = useState('asc')
  const tableRef = useRef()

  useEffect(() => {
    if (teamData) {
      const uniqueGoals = [...new Set(teamData.map(team => team.goalName))]
      const newFilters = [
        ...uniqueGoals.map(goal => ({
          label: goal,
          value: goal,
          type: 'Goal' // This is used to filter the data
        })),
        {
          label: t('fullReport.inProgress'),
          value: ASSESSMENT_STATUSES.OPEN
        },
        {
          label: t('common.completed'),
          value: ASSESSMENT_STATUSES.CLOSED
        }
      ]
      setFilters(newFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData])

  const sortableColumns = [
    { label: t('company.name'), columnName: 'fullName' },
    { label: t('overview.email'), columnName: 'email' },
    {
      label: t('fullReport.assessmentType'),
      columnName: 'goalName'
    },
    {
      label: t('fullReport.progress'),
      columnName: 'status'
    }
  ]

  const handleRequestSort = (pSortBy, clickAction = true) => {
    requestSort(
      teamData,
      currentSort,
      sortOrder,
      pSortBy,
      setCurrentSort,
      setSortOrder,
      setDisplayData,
      clickAction
    )
  }

  const isSuperAdmin = permitted(PERMISSION_LEVEL.SUPER_ADMIN)
  return (
    <InCardStack marginBottom={20}>
      <TableContainer
        data={teamData}
        displayData={displayData}
        setDisplayData={setDisplayData}
        filters={filters}
        tableProps={{ ref: tableRef }}
        title={
          <RowStack justifyContent='space-between'>
            {`${user?.organization?.name} ${t('fullReport.leaders')}`}
            {isSuperAdmin && (
              <DownloadTableExcel
                filename='full_assessment_team_progress'
                sheet='sheet1'
                currentTableRef={tableRef.current}>
                <StyledButton>{t('overview.export')}</StyledButton>
              </DownloadTableExcel>
            )}
          </RowStack>
        }
        placeholder={t('fullReport.searchLeaders')}
        headers={
          <>
            <TableCell></TableCell>
            {sortableColumns.map(({ label, columnName }) => {
              return (
                <SortableHeaderCell
                  key={columnName}
                  currentSort={currentSort}
                  sortOrder={sortOrder}
                  requestSort={handleRequestSort}
                  attributeName={columnName}>
                  {label}
                </SortableHeaderCell>
              )
            })}
            {isSuperAdmin && <TableCell>Report URL</TableCell>}
          </>
        }>
        {displayData.map(member => {
          return (
            <TableRow key={member.userId}>
              <UserCells memeber={member} />

              <TableCell>
                <RowStack spacing={4}>
                  {member.status === ASSESSMENT_STATUSES.CLOSED ? (
                    <>
                      <CheckCircleIcon color='primary' />
                      <Typography variant='h5' color={colors.Teal400}>
                        {t('superLeaderUserDrillDown.complete')}
                      </Typography>
                      <Typography
                        variant='caption'
                        color={colors.Grey300}
                        fontWeight={600}>
                        {moment(member.updatedAt).format('DD/MM/YYYY')}{' '}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <AppUsageProgressBar
                        progress={Math.round(member.progress * 100)}
                        primary={colors.GoldenYellow400}
                        secondary={colors.MutedTeal100}
                        height={12}
                        borderRadius={100}
                        sx={{ width: 150 }}
                      />
                      <Typography variant='h6' color={colors.Teal400}>
                        {Math.round(member.progress * 100)}%
                      </Typography>
                    </>
                  )}
                </RowStack>
              </TableCell>
              {isSuperAdmin && (
                <TableCell>
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: 80,
                      textWrap: 'nowrap'
                    }}>
                    {member.status === ASSESSMENT_STATUSES.CLOSED &&
                      member.shareableLink}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          )
        })}
      </TableContainer>
    </InCardStack>
  )
}
export default TeamProgressTable
