import { Box, Grid } from '@mui/material'
import { useRef } from 'react'
import InCardStack from '../InCardStack'

const PanelsWithScrollBars = ({ leftList, children, listOnScroll }) => {
  const listRef = useRef()
  return (
    <Box height='inherit' overflow='hidden'>
      <Grid container height='inherit' sx={{ overflowY: 'auto' }}>
        <Grid
          item
          xs={3.9}
          height='inherit'
          gap={5}
          minWidth='350px'
          sx={{ overflowY: 'auto', paddingTop: '10px' }}
          onScroll={listOnScroll ? () => listOnScroll(listRef) : null}
          ref={listRef}>
          <InCardStack>{leftList}</InCardStack>
        </Grid>
        <Grid item xs={0.2} />
        <Grid
          item
          xs={7.9}
          height='inherit'
          minWidth='500px'
          sx={{ overflowY: 'auto' }}>
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}

export default PanelsWithScrollBars
