import { Avatar } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { EDIT_PROFILE } from 'routes/constants'
import { getNameIntials } from 'utils/formatText'
const ProfileAvatar = ({ user, size = 'normal', newImage }) => {
  const { profile_image: initialProfileImage } = user
  const profileImage = initialProfileImage || user.profileImage
  const location = useLocation()
  const sizeSetting = size === 'small' ? 28
    : size === 'tiny' ? 20
    : size === 'medium' ? 70
    : size === 'large' ? 100
    : 40
  const nameInitials = getNameIntials(user)
  const [imageLink, setImageLink] = useState(profileImage)

  useEffect(() => {
    // If user comes from edit profile, set a delay on loading image
    if (profileImage && location.state?.from === EDIT_PROFILE) {
      const refreshTimeout = setTimeout(() => {
        setImageLink(profileImage)
      }, 500)
      return () => clearTimeout(refreshTimeout)
    } else {
      setImageLink(profileImage)
    }
  }, [location.state, profileImage])

  useEffect(() => {
    if (newImage) {
      setImageLink(newImage)
    }
  }, [newImage])
  return (
    <>
      {profileImage || newImage ? (
        <Avatar
          alt={ size === 'tiny' ? nameInitials[0] : nameInitials}
          src={imageLink}
          sx={{ height: sizeSetting, width: sizeSetting }}
        />
      ) : (
        <Avatar sx={{ height: sizeSetting, width: sizeSetting }}>
          {size === 'small' || size === 'tiny' ? nameInitials[0] : nameInitials}
        </Avatar>
      )}
    </>
  )
}

export default ProfileAvatar
