import {
  Alert,
  Box,
  Button,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
  useTheme
} from '@mui/material'
import { Done } from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react'
import {
  ASSESSMENT_QUESTIONS_OTHER_URL,
  ASSESSMENT_QUESTIONS_SELF_URL,
  ASSESSMENT_SUBMIT_URL,
  CONSTANTS_OPTIONS
} from 'services/constants'
import { getRequest, postRequest } from 'utils/api'
import { getGoalNameById } from 'utils/getSupportingInfo'
import InCardStack from 'components/atoms/InCardStack'
import BackIconButton from 'components/atoms/button/BackIconButton'
import NextIconButton from 'components/atoms/button/NextIconButton'
import SingleWhitePageCard from 'components/templates/SingleWhitePageCard'
import { getConstant } from 'services/constantServices'
import { useTranslation } from 'react-i18next'
import OverlayLoading from 'components/atoms/OverlayLoading'

const AssessmentCard = ({
  title,
  introduction,
  goalId,
  startButtonText,
  assessmentTitle,
  forUserId,
  submittedCallback,
  assessmentRequestId,
  selfAssessment = false
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [options, setOptions] = useState([])
  const [assessmentStatus, setAssessmentStatus] = useState('init')
  const [error, setError] = useState(undefined)
  const [questionCount, setQuestionCount] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [questions, setQuestions] = useState([])
  const [goalName, setGoalName] = useState('')
  const [answers, setAnswers] = useState({})
  const [currentValue, setCurrentValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const questionField = selfAssessment ? 'selfQuestion' : 'othersQuestion'
  const questionRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const evaluateOptions = await getConstant(
        CONSTANTS_OPTIONS.EVALUATION_OPTIONS
      )
      if (evaluateOptions) {
        setOptions(evaluateOptions)
      }
      const goal = await getGoalNameById(goalId)
      setGoalName(goal)
      let requestUrl = ASSESSMENT_QUESTIONS_SELF_URL
      const requestBody = {}
      if (selfAssessment) {
        requestBody.goalId = goalId
      } else {
        requestUrl = ASSESSMENT_QUESTIONS_OTHER_URL
        requestBody.assessmentRequestId = assessmentRequestId
      }
      const res = await getRequest(requestUrl, requestBody)
      if (res.status === 200) {
        const resQuestions = res.data.data
        setQuestions(resQuestions)
        setQuestionCount(resQuestions.length)
        setAnswers(Array(resQuestions.length).fill(null))
      } else {
        setError(true)
      }
    }
    if (goalId) {
      fetchData()
    }
  }, [assessmentRequestId, goalId, selfAssessment])

  const handleSubmit = async () => {
    setLoading(true)
    const scores = {}
    answers.forEach((value, index) => {
      if (value) {
        const questionId = questions[index].id
        scores[questionId] = parseInt(value)
      }
    })
    const requestBody = {
      goal_id: goalId,
      for_user_id: forUserId,
      assessment_question_scores: scores,
      version: questions[0].version
    }
    if (!selfAssessment) {
      requestBody.assessmentRequestId = assessmentRequestId
    }
    const res = await postRequest(ASSESSMENT_SUBMIT_URL, requestBody)
    if (res.data?.success) {
      submittedCallback(res.data.data)
    } else {
      setError(true)
    }
    setLoading(false)
  }
  const handleAnswers = event => {
    answers[currentQuestion] = event.target.value
    setCurrentValue(event.target.value)
  }

  const handleNavigation = step => {
    const newQuestion = currentQuestion + step
    setCurrentQuestion(newQuestion)
    setCurrentValue(answers[newQuestion])
    // Make sure question is in view
    questionRef.current?.scrollIntoView({ block: 'nearest' })
  }
  const handleStartClick = () => {
    setAssessmentStatus('started')
  }
  return (
    <>
      {assessmentStatus === 'init' ? (
        <SingleWhitePageCard title={title}>
          <CardContent sx={{ marginBottom: '20px' }}>
            <Typography
              variant='body1'
              align='center'
              lineHeight='1.75'
              maxWidth='750px'>
              {introduction}
              <br />
              {`${t('assessmentTimeline.assessmentOnly.part1')} ${questionCount} ${t('assessmentTimeline.assessmentOnly.part2')}`}
            </Typography>
          </CardContent>
          <CardContent>
            <Button onClick={() => handleStartClick()}>
              {startButtonText}
            </Button>
          </CardContent>
        </SingleWhitePageCard>
      ) : assessmentStatus === 'started' ? (
        <SingleWhitePageCard title={assessmentTitle} mainBodyWidth={800}>
          <Typography
            width='100%'
            color={theme.palette.primary.dark}
            textAlign='center'>
            {goalName}
          </Typography>
          <Box ref={questionRef} />
          <InCardStack
            data-testid='question-body'
            sx={{ maxWidth: 450, alignSelf: 'center' }}>
            <Typography color={theme.palette.primary.dark}>
              {t('pulseSurvey.question')} {currentQuestion + 1}/{questionCount}
            </Typography>
            <Box width='100%'>
              <LinearProgress
                variant='determinate'
                value={((currentQuestion + 1) / questionCount) * 100}
                sx={{ height: 10, borderRadius: 5 }}
              />
            </Box>
            <Divider width='100%' />

            <Typography variant='subtitle1' alignSelf='start' paddingTop='20px'>
              {questions[currentQuestion]?.displayOrder}.{' '}
              {questions[currentQuestion]?.[questionField]}
            </Typography>
            <FormControl
              fullWidth
              sx={{
                padding: 10,
                backgroundColor: theme.palette.background.default,
                borderRadius: 5
              }}>
              <RadioGroup
                name='controlled-radio-buttons-group'
                value={currentValue}
                onChange={handleAnswers}>
                {options.map(option => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio size='small' />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </InCardStack>
          <CardContent data-testid='navigation-bar'>
            <BackIconButton
              disabled={currentQuestion === 0}
              title='Back'
              onClick={() => handleNavigation(-1)}
            />
            <Divider orientation='vertical' sx={{ marginX: '80px' }} />
            <NextIconButton
              sx={{
                display:
                  currentQuestion + 1 < questionCount ? undefined : 'none'
              }}
              title='Next'
              disabled={answers[currentQuestion] === null}
              onClick={() => handleNavigation(1)}
            />
            <Button
              sx={{
                display:
                  currentQuestion + 1 === questionCount ? undefined : 'none'
              }}
              disabled={answers[currentQuestion] === null}
              title='Finish'
              onClick={() => handleSubmit()}>
              <Done />
            </Button>
          </CardContent>
          {error ? (
            <Alert severity='error'>
              {t('assessmentTimeline.submitError')}
            </Alert>
          ) : null}
        </SingleWhitePageCard>
      ) : null}
      <OverlayLoading open={loading} />
    </>
  )
}

export default AssessmentCard
