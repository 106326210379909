import { Container, Stack, Typography } from '@mui/material'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import { PageHeader } from 'components/atoms/Text'
import AboutPage from 'components/templates/navigationPageTemplates/AboutPage'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isAllowedFeature } from 'utils/permissionCheck'
import { FEATURE_KEYS } from 'services/constants'

const About360 = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const showBackButton = location.state?.from
  const textProps = { variant: 'body2', fontSize: '16px', lineHeight: 1.75 }
  const listProps = { ...textProps, marginLeft: '30px', display: 'list-item' }
  const SectionPart = ({ children, titleText }) => (
    <Stack padding={0} spacing={4}>
      <Typography variant='h4'>
        {titleText}
      </Typography>
      <Typography variant='body1' {...textProps} component='div'>
        {children}
      </Typography>
    </Stack>
  )
  return (
    <AboutPage>
      <Container maxWidth='lg'>
        <LeftJustifyCardContent>
          <Stack spacing={15}>
            {showBackButton ? <BackIconTextButton /> : null}
            <PageHeader>
              {t('about360.heading')}
            </PageHeader>
            { isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT) && 
              <SectionPart titleText={t('about360.disclaimer')}></SectionPart>
              }
            <SectionPart titleText={t('about360.why360')}>
              {t('about360.why360Text.part1')}
              <br />
              {t('about360.why360Text.part2')}
            </SectionPart>
            <SectionPart titleText={t('about360.interpretResults')}>
              {t('about360.interpretResultsText.para')}
              <Typography {...listProps}>
                {t('about360.interpretResultsText.itmes.item1.part1')}
                <strong>{t('about360.interpretResultsText.itmes.item1.part2')}</strong>
                {t('about360.interpretResultsText.itmes.item1.part3')}
                <strong>{t('about360.interpretResultsText.itmes.item1.part4')}</strong>.
              </Typography>
              <Typography {...listProps}>
                {t('about360.interpretResultsText.itmes.item2.part1')}
                <strong>{t('about360.interpretResultsText.itmes.item2.part2')} </strong>
                {t('about360.interpretResultsText.itmes.item2.part3')}
              </Typography>
              <Typography {...listProps}>
                {t('about360.interpretResultsText.itmes.item3.part1')}
                <strong>{t('about360.interpretResultsText.itmes.item3.part2')}</strong>
                {t('about360.interpretResultsText.itmes.item3.part3')}
              </Typography>
            </SectionPart>
            <SectionPart titleText={t('about360.whatYouSee')}>
              <Typography {...listProps}>
                <strong>{t('about360.whatYouSeeItems.item1.part1')}</strong> {t('about360.whatYouSeeItems.item1.part2')}
              </Typography>
              <Typography {...listProps}>
                <strong>{t('about360.whatYouSeeItems.item2.part1')}</strong> {t('about360.whatYouSeeItems.item2.part2')}
                invited to rate you.
              </Typography>
            </SectionPart>
            <SectionPart titleText={t('about360.understandingBlindspots')}>
              <Typography {...listProps}>
                <strong>{t('about360.understandingBlindspotsItems.item1.part1')}</strong>{t('about360.understandingBlindspotsItems.item1.part2')}
              </Typography>
              <Typography {...listProps}>
              <strong>{t('about360.understandingBlindspotsItems.item2.part1')}</strong>{t('about360.understandingBlindspotsItems.item2.part2')}
              </Typography>
            </SectionPart>
          </Stack>
        </LeftJustifyCardContent>
      </Container>
    </AboutPage>
  )
}

export default About360
