import { Button, Icon } from '@mui/material'
import { microsoftAppId, microsoftRedirectUrl } from 'config/config'
import { USER_CALENDAR_EVENTS } from 'routes/constants'
import { CALENDAR_OAUTH_STORAGE_KEY } from 'services/constants'
import microsoftCalendar from 'assets/images/microsoft-calendar.svg'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'services/amplitudeTracking'

const MicrosoftCalendar = ({ signinCallback }) => {
  const { t } = useTranslation()
  const authURL =
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?'
  const redirectURI = `redirect_uri=${microsoftRedirectUrl}${USER_CALENDAR_EVENTS}`
  const client = `client_id=${microsoftAppId}`
  const params = [
    client,
    'response_type=code',
    'response_mode=query',
    redirectURI,
    'scope=openid%20email%20User.Read%20calendars.readwrite%20offline_access'
  ]
  const hrefURL = authURL + params.join('&')

  const listenToOAuth = event => {
    if (event.key === CALENDAR_OAUTH_STORAGE_KEY.MICROSOFT) {
      const oAuthRes = localStorage.getItem(
        CALENDAR_OAUTH_STORAGE_KEY.MICROSOFT
      )
      localStorage.removeItem(CALENDAR_OAUTH_STORAGE_KEY.MICROSOFT)
      window.removeEventListener('storage', listenToOAuth)
      signinCallback(oAuthRes)
      trackEvent('Connect Calendar', { type: 'Microsoft' })
    }
  }

  const onBtnClick = () => {
    window.addEventListener('storage', listenToOAuth)
  }

  return (
    <Button
      component='a'
      target='_blank'
      variant='outlined'
      sx={{ fontWeight: 'bold' }}
      href={hrefURL}
      onClick={onBtnClick}
      startIcon={
        <Icon>
          <img
            src={microsoftCalendar}
            alt='microsoft'
            style={{ maxWidth: '22px' }}
          />
        </Icon>
      }>
      {t('calenderEvents.connectMicrosoft')}
    </Button>
  )
}

export default MicrosoftCalendar
