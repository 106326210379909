import moment from 'moment'

export const getLocalConstant = key => {
  const localItem = sessionStorage.getItem(`CONSTANT_${key}`)
  if (localItem) {
    const parsed = JSON.parse(localItem)
    if (parsed.setAt && moment().diff(parsed.setAt, 'day') < 1) {
      return parsed.data
    }
  }
  return null
}

export const setLocalConstant = (key, value) => {
  const saveObj = {
    data: value,
    setAt: moment()
  }
  return sessionStorage.setItem(`CONSTANT_${key}`, JSON.stringify(saveObj))
}
