import { Close } from '@mui/icons-material'
import { Dialog, DialogTitle, IconButton, Stack } from '@mui/material'
import AssessmentChangeTable from 'components/molecules/assessments/AssessmentChangeTable'

const AssessmentChangeDialog = props => {
  const { onClose, open, changeData } = props

  const handleSelect = () => {
    onClose()
  }

  return (
    <Dialog
      onClose={handleSelect}
      open={open}
      sx={{ '& .MuiDialog-paper': { maxWidth: '100%' } }}>
      <DialogTitle>
        {`Viewing Change for UserId: ${changeData.userId}`}
        <IconButton
          sx={{ float: 'right', paddingTop: '2px' }}
          onClick={() => onClose()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Stack alignItems='center' spacing={5} sx={{ paddingTop: 0 }}>
        <AssessmentChangeTable
          data={changeData.data}
          disableBehaviorRedirect={changeData.userId === 'Total Average'}
          showExport={true}
        />
      </Stack>
    </Dialog>
  )
}

export default AssessmentChangeDialog
