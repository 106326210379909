import { Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import moment, { utc } from 'moment'
import { useTranslation } from 'react-i18next'

const DateTimeStack = ({ event, fontColor, dayMonth = false }) => {
  const { i18n } = useTranslation()
  moment.locale(i18n.resolvedLanguage)
  const date = utc(event.startTime)?.local()

  return (
    <InCardStack spacing={dayMonth ? -2 : 0} height='100%' sx={{ justifyContent: 'center' }}>
      {dayMonth ? (
        <>
          <Typography color={fontColor} variant='h2'>
            {date?.format('D')}
          </Typography>
          <Typography variant='eyebrow' lineHeight={2} noWrap>
            {date?.format('MMM')}
          </Typography>
        </>
      ) : (
        <>
          <Typography color={fontColor} variant='subtitle1'>
            {date?.format('MMM D')}
          </Typography>
          <Typography color={fontColor} variant='subtitle1' noWrap={true}>
            {date?.format('hh:mm A')}
          </Typography>
        </>
      )}
    </InCardStack>
  )
}

export default DateTimeStack
