import React, { useEffect, useState } from 'react'
import SimplePage from 'components/templates/SimplePage'
import { ROOT } from 'routes/constants'
import { CardContent, Typography } from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import { useParams } from 'react-router-dom'
import { NotificationBodyText } from 'components/atoms/Text'
import { getRequest } from 'utils/api'
import { OHEP_SURVEYS_DETAIL_URL } from 'services/constants'
import OverlayLoading from 'components/atoms/OverlayLoading'
import RequestErrorPageCard from 'components/molecules/RequestErrorPageCard'
import SurveyRespondingCard from 'components/organisms/SurveyRespondingCard'

const storeName = 'completedSurveys'
const Survey = () => {
  const params = useParams()
  const { token } = params

  // Check if user has completed this survey
  let completedBefore = false
  const localStore = localStorage.getItem(storeName)
  if (localStore) {
    const storedSurveys = JSON.parse(localStore)
    if (storedSurveys.indexOf(token) > -1) {
      completedBefore = true
    }
  }

  const [completed, setCompleted] = useState(completedBefore)
  const [loading, setLoading] = useState(!completedBefore)
  const [requestError, setRequestError] = useState(undefined)
  const [surveyConfig, setSurveyConfig] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(OHEP_SURVEYS_DETAIL_URL, { token })
      if (res.status === 200) {
        setSurveyConfig(res.data.data)
        setRequestError(false)
      } else {
        setRequestError(true)
      }
      setLoading(false)
    }
    if (!completedBefore) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const completeCallback = () => {
    let completedSurveys = []
    if (localStore) {
      completedSurveys = JSON.parse(localStore)
    }
    completedSurveys.push(token)
    localStorage.setItem(storeName, JSON.stringify(completedSurveys))
    setCompleted(true)
  }
  return (
    <SimplePage maxWidth='lg'>
      {completed ? (
        <>
          <CardContent sx={{ marginTop: 50 }}>
            <Typography variant='h5'>Finished</Typography>
          </CardContent>
          <CardContent>
            <NotificationBodyText>
              The survey is completed, your responses have been saved.
            </NotificationBodyText>
          </CardContent>
          <CardContent>
            <LinkButton to={ROOT}>Return to Monark</LinkButton>
          </CardContent>
        </>
      ) : loading ? (
        <OverlayLoading open={loading} />
      ) : requestError ? (
        <CardContent sx={{ marginTop: 50 }}>
          <RequestErrorPageCard
            title='Survey Not Found'
            text='We cannot find the survey at this moment. You may refresh the page and retry later.'
          />
        </CardContent>
      ) : (
        <SurveyRespondingCard
          surveyConfig={surveyConfig}
          completeCallback={completeCallback}
          token={token}
        />
      )}
    </SimplePage>
  )
}

export default Survey
