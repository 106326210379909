import { deleteRequest, patchRequest, postRequest } from 'utils/api'
import {
  COHORTS_URL,
  COHORT_GOAL_FACILITATION_URL,
  COHORT_SESSIONS_URL
} from './constants'

export const postCohort = async requestBody => {
  const res = await postRequest(COHORTS_URL, requestBody)
  const result = { error: true }
  if (res.status === 201) {
    result.error = false
    result.data = res.data.data
  } else if (res.status === 422) {
    result.message = 'Name has been taken, please update cohort name and retry.'
  }
  return result
}

export const postPatchSession = async (requestBody, cohortSessionId) => {
  const res = cohortSessionId
    ? await patchRequest(
        `${COHORT_SESSIONS_URL}/${cohortSessionId}`,
        requestBody
      )
    : await postRequest(COHORT_SESSIONS_URL, requestBody)
  const result = { error: true }
  if (res.status === 201 || res.status === 200) {
    result.error = false
    result.data = res.data.data
  } else if (res.status === 422) {
    result.message =
      'Session dates might be overlapping with other session in cohort. Please double check.'
  }
  return result
}

export const updateFaciliations = async (
  cohortSessionId,
  facilitations,
  facilitatorId
) => {
  const hadUser = facilitations.find(f => f.id && f.userId)
  const updatingUser =
    (facilitatorId !== '' && !hadUser) ||
    (hadUser && facilitatorId !== hadUser.userId)
  const results = await Promise.all(
    facilitations.map(async fac =>
      fac.id && ((fac.updating && fac.facilitationDate) || updatingUser)
        ? patchRequest(`${COHORT_GOAL_FACILITATION_URL}/${fac.id}`, {
            userId: facilitatorId || '',
            facilitationDate: fac.facilitationDate
          })
        : fac.id && fac.updating && !fac.facilitationDate
        ? deleteRequest(`${COHORT_GOAL_FACILITATION_URL}/${fac.id}`)
        : !fac.id
        ? postRequest(COHORT_GOAL_FACILITATION_URL, {
            cohortSessionId,
            userId: facilitatorId,
            goalTimelineId: fac.goalTimelineId,
            facilitationDate: fac.facilitationDate
          })
        : null
    )
  )
  const failedRequest = results.find(
    res =>
      res?.status && res.status !== 200 && res.status !== 201 && res.status !== 204
  )
  if (failedRequest) {
    return {
      error: true,
      message:
        'Facilitations update failed. Please check if all dates are between session start date and end date.'
    }
  }
  return { error: false }
}
