import * as yup from 'yup'

const validationsRules = {
  optionalEmail: yup
    .string('validationErrors.enterYourEmail')
    .trim()
    .email('validationErrors.enterValidEmail'),
  email: yup
    .string('validationErrors.enterYourEmail')
    .trim()
    .email('validationErrors.enterValidEmail')
    .required('validationErrors.emailRequired'),
  password: yup.string('validationErrors.enterYourPassword').required('validationErrors.passwordRequired'),
  passwordWithRules: yup
    .string('validationErrors.enterYourPassword')
    .required('validationErrors.passwordRequired')
    .min(8, 'validationErrors.passwordLeast8Characters')
    .matches(
      /(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*+\-_[\]()=])/,
      'validationErrors.passwordMustContains'
    ),
  // reference from https://stackoverflow.com/a/72975771
  confirmPassword: yup
    .string()
    .required('validationErrors.passwordsMustMatch')
    .oneOf([yup.ref('password')], 'validationErrors.passwordsMustMatch'),
  text: yup.string(),
  textRequired: yup.string().required('validationErrors.required'),
  dateRequired: yup.string().nullable().required('validationErrors.pleaseSelectDate'),
  timeRangeRequired: yup.string().required('validationErrors.invalidTimeRange'),
  endRequiredStartDate: yup.string().nullable().when('startDate', startDate => {
    if (startDate) {
      return yup
        .date()
        .nullable()
        .required('validationErrors.pleaseSelectEndDate')
        .min(startDate, 'validationErrors.endDateAfterStartDate')
    }
  })
}

const emailValidationSchema = yup.object({
  email: validationsRules.email
})

const passwordValidationSchema = yup.object({
  confirmPassword: validationsRules.confirmPassword
})

const signupValidationSchema = yup.object({
  email: validationsRules.email,
  firstName: validationsRules.textRequired,
  lastName: validationsRules.textRequired
})

const loginValidationSchema = yup.object({
  email: validationsRules.email,
  password: validationsRules.password
})

const organizationValidationSchema = yup.object({
  companyName: validationsRules.text,
  industry: validationsRules.textRequired
})

const featureValidationSchema = yup.object({
  status: validationsRules.textRequired
})

const cohortSessionValidationSchema = withName => {
  const rules = {
    reAssessmentTiming: yup
      .number()
      .required('validationErrors.pleaseSelectReassessmentTiming'),
    startDate: validationsRules.dateRequired,
    endDate: validationsRules.endRequiredStartDate,
    path: yup.string().required('validationErrors.pleaseSelectPath')
  }
  if (withName) {
    rules.cohortName = validationsRules.textRequired
  }
  return yup.object(rules)
}
export {
  validationsRules,
  emailValidationSchema,
  passwordValidationSchema,
  signupValidationSchema,
  loginValidationSchema,
  organizationValidationSchema,
  featureValidationSchema,
  cohortSessionValidationSchema
}
