import InternalPage from 'components/templates/navigationPageTemplates/InternalPage'
import { FEATURES_ADMIN } from 'routes/constants'
import InCardStack from 'components/atoms/InCardStack'
import FeatureAdminCard from 'components/organisms/FeatureAdminCard'


const FeatureAdmin = () => {
  return (
    <InternalPage activePage={FEATURES_ADMIN} pageTitle='Feature Admin'>
      <InCardStack sx={{ paddingTop: '30px' }}>
        <FeatureAdminCard />
      </InCardStack>
    </InternalPage>
  )
}

export default FeatureAdmin
