import { Box } from '@mui/material'
import { InfoCardIcon } from 'components/atoms/Icons'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InfoCard from 'components/atoms/InfoCard'
import StyledButton from 'components/atoms/button/StyledButton'
import HeaderWithCarousel, {
  CarouselWelcome
} from 'components/atoms/container/HeaderWithCarousel'
import { useTranslation } from 'react-i18next'
import timerIcon from 'assets/images/timer.svg'
import shielldTickIcon from 'assets/images/shieldTick.svg'
import cloudeAddIcon from 'assets/images/cloudAdd.svg'
import { ActionText } from 'components/atoms/Text'
import { useLocation, useNavigate } from 'react-router-dom'
import { ABOUT_CONFIDENTIALITY, ONBOARDING } from 'routes/constants'

const FullAssessmentLandingCarousel = ({ startAction, questionCount }) => {
  const location = useLocation()
  const naviqate = useNavigate()
  const { t } = useTranslation()
  const isOnboarding = location.pathname === ONBOARDING
  return (
    <Box width='100%' paddingTop={isOnboarding ? 0 : 10}>
      <HeaderWithCarousel
        cards={[
          <InfoCard
            title={t('assessments.landingQuestionTitle', {
              count: questionCount
            })}
            content={t('assessments.landingQuestion')}
            icon={<InfoCardIcon src={timerIcon} />}
          />,
          <InfoCard
            title={t('assessments.landingWhenYouCanTitle')}
            content={t('assessments.landingWhenYouCan')}
            icon={<InfoCardIcon src={cloudeAddIcon} />}
          />,
          <InfoCard
            title={t('headers.aboutConfidentiality')}
            content={t('assessments.landingConfidentiality')}
            icon={<InfoCardIcon src={shielldTickIcon} />}>
            <ActionText
              variant='body1Small'
              fontWeight='500'
              sx={{ alignItems: 'center', display: 'flex', marginTop: 10 }}
              onClick={() => naviqate(ABOUT_CONFIDENTIALITY)}>
              <InfoOutlinedIcon sx={{ marginRight: 2 }} />
              {t('assessments.landingMoreConfidentiality')}
            </ActionText>
          </InfoCard>
        ]}
        welcomeElement={
          <CarouselWelcome
            title={t('assessmentTimeline.selfAssessment')}
            description={t('assessments.assessmentLanding')}>
            <Box paddingTop={10}>
              <StyledButton onClick={startAction} sx={{ width: 'fit-content' }}>
                {t('assessmentTimeline.beginSelfAssessment')}
              </StyledButton>
            </Box>
          </CarouselWelcome>
        }></HeaderWithCarousel>
    </Box>
  )
}

export default FullAssessmentLandingCarousel
