import { Typography } from '@mui/material'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import { useTranslation } from 'react-i18next'

const OverallScoreDisplay = ({ score, categoryColor, containerStyle = {} }) => {
  const { t } = useTranslation()

  return (
    <InCardStack spacing={3} style={containerStyle}>
      <Typography variant='formText' sx={{ color: colors.Grey300 }}>
        {t('fullReport.overallScore')}
      </Typography>

      <BoxWithBorder
        borderWidth={1}
        borderColor={colors.Grey100}
        backgroundColor={colors.White}
        borderRadius={2}
        width={94}
        padding={4}>
        <Typography
          variant='h3'
          fontWeight={500}
          textAlign={'center'}
          color={categoryColor}>
          {score.toFixed(2)}
        </Typography>
      </BoxWithBorder>
    </InCardStack>
  )
}

export default OverallScoreDisplay
