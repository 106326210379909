import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import AvatarChip from 'components/atoms/AvatarChip'
import DateCell from 'components/atoms/table/DateCell'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import {
  RestoreButton,
  SnapshotButton
} from 'components/molecules/internal/DemoAccountButtons'
import InternalPage from 'components/templates/navigationPageTemplates/InternalPage'
import { useEffect, useState } from 'react'
import { DEMO_ACCOUNTS } from 'routes/constants'
import { USERS_URL } from 'services/constants'
import { getRequest } from 'utils/api'

const DemoAccounts = () => {
  const [demoUsers, setDemoUsers] = useState([])
  const fetchUsers = async () => {
    const res = await getRequest(USERS_URL, { demoOnly: true })
    if (res.status === 200) {
      setDemoUsers(res.data.data)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <InternalPage activePage={DEMO_ACCOUNTS} pageTitle='Demo Paths'>
      <Stack spacing={5}>
        <Typography variant='h3'>Demo Accounts</Typography>
        <PresetTableContainer
          maxHeight='100%'
          headers={
            <>
              <TableCell>User</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Snapshot Taken At</TableCell>
              <TableCell />
              <TableCell />
            </>
          }>
          {demoUsers.map(u => (
            <TableRow key={`${u.id}`}>
              <TableCell>
                <AvatarChip user={u} />
              </TableCell>
              <TableCell>{u.email}</TableCell>
              <DateCell value={u.snapshotLastModified} />
              <TableCell>
                {u.snapshotLastModified && <RestoreButton user={u} />}
              </TableCell>
              <TableCell>
                <SnapshotButton user={u} sucessCallBack={() => fetchUsers()} />
              </TableCell>
            </TableRow>
          ))}
        </PresetTableContainer>
      </Stack>
    </InternalPage>
  )
}

export default DemoAccounts
