import { useState } from 'react'
import {
  Autocomplete,
  createFilterOptions,
  TextField,
  MenuItem,
  Typography,
  Stack,
  alpha,
  useTheme
} from '@mui/material'
import { USER_CONNECTION_RELATIONSHIP } from 'services/constants'
import { getFullName } from 'utils/formatText'
import { validationsRules } from 'utils/formValidations'
import { getUserConnectionLabel } from 'utils/getSupportingInfo'
import { useTranslation } from 'react-i18next'
import RowStack from 'components/atoms/container/RowStack'
import colors from 'styles/colors'
import { ErrorText } from 'components/atoms/Text'
import AvatarStack from 'components/atoms/AvatarStack'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

const RenderMenuItem = ({ itemProps, user, relationship }) => {
  const { t } = useTranslation()
  return (
    <MenuItem {...itemProps} style={{ opacity: 1 }}>
      <Stack spacing={1} padding={0} width={'100%'}>
        <RowStack
          sx={{
            justifyContent: 'space-between',
            opacity: itemProps['aria-disabled'] ? 0.3 : 1
          }}>
          <AvatarStack user={user} width={'70%'} />

          {relationship && (
            <Typography variant='body2'>
              {getUserConnectionLabel(relationship, t)}
            </Typography>
          )}
        </RowStack>

        {itemProps['aria-disabled'] && (
          <ErrorText>{t('contact.canOnlySetup1O1')}</ErrorText>
        )}
      </Stack>
    </MenuItem>
  )
}

const ConnectionAutocomplete = ({
  connections,
  initialValue = null,
  formik,
  attributeName,
  placeholder,
  disabled = false,
  ...otherProps
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const filter = createFilterOptions()
  const [options, setOptions] = useState(connections)
  const [focused, setFocused] = useState(false)

  const handleOpen = () => {
    const filtered = connections.filter(
      c => c.relationship === USER_CONNECTION_RELATIONSHIP[0].value
    )
    if (initialValue?.new) {
      filtered.push(initialValue)
    }
    setOptions(filtered)
  }

  const filterOptions = (options, params) => {
    const filtered = filter(options, params)

    if (params.inputValue !== '') {
      const otherUsers = connections.filter(
        c =>
          c.relationship !== USER_CONNECTION_RELATIONSHIP[0].value &&
          c.email.toLowerCase() === params.inputValue.toLowerCase()
      )

      if (otherUsers.length > 0) {
        filtered.push(...otherUsers)
      } else {
        filtered.push({
          email: params.inputValue,
          firstName: `${t('addRaters.new')} ${t('addRaters.connection')}`,
          lastName: `"${params.inputValue}"`,
          relationship: USER_CONNECTION_RELATIONSHIP[0].value,
          new: true
        })
      }
    }

    return filtered
  }

  const handleChange = async (_, value) => {
    // Validate new email
    let newValue = value?.email ? value : null
    if (value?.new) {
      try {
        await Promise.resolve(validationsRules.email.validate(value.email))
        formik.setErrors({})
      } catch {
        formik.setFieldValue(attributeName, null)
        return
      }

      newValue = {
        email: value.email,
        relationship: value.relationship ?? '',
        firstName: t('addRaters.new'),
        lastName: t('addRaters.connection'),
        new: true
      }

      options.push(newValue)
    }

    // Set field value
    formik.setFieldValue(attributeName, newValue)
  }

  return (
    <Autocomplete
      {...otherProps}
      onOpen={() => handleOpen()}
      autoSelect
      disabled={disabled}
      options={options}
      fullWidth
      autoHighlight={true}
      defaultValue={initialValue}
      sx={{
        '& .MuiAutocomplete-popupIndicator': {
          transform: 'none',
          color: focused
            ? theme.palette.primary.main
            : alpha(colors.DarkGrey, 0.5)
        }
      }}
      filterOptions={filterOptions}
      renderOption={(props, option) => (
        <RenderMenuItem
          option={option}
          itemProps={props}
          user={option.user ?? option}
          key={option.email}
          relationship={option.relationship}
        />
      )}
      popupIcon={<PersonOutlineOutlinedIcon />}
      renderInput={params => (
        <TextField
          {...params}
          error={formik.touched[attributeName] && Boolean(formik.errors[attributeName])}
          helperText={formik.touched[attributeName] && formik.errors[attributeName]}
          value={formik[attributeName]}
          placeholder={
            placeholder ? placeholder : t('addRaters.nameOrWorkEmail')
          }
          variant={'standard'}
        />
      )}
      getOptionLabel={option =>
        option.new
          ? `${t('addRaters.new')} ${t('addRaters.connection')} (${option.email})`
          : `${getFullName(option)} (${option.email})`
      }
      getOptionDisabled={option =>
        !option.new &&
        option.relationship !== USER_CONNECTION_RELATIONSHIP[0].value
      }
      isOptionEqualToValue={(option, value) => option.email === value.email}
      onChange={handleChange}
      onBlur={_ => {
        setFocused(false)
      }}
      onFocus={() => setFocused(true)}
    />
  )
}

export default ConnectionAutocomplete
