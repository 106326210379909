import { Grid, Typography } from '@mui/material'
import StyledButton from 'components/atoms/button/StyledButton'
import { useNavigate } from 'react-router-dom'
import { FULL_ASSESSMENT } from 'routes/constants'
import { useTranslation } from 'react-i18next'
import { Card, CardStack, CardTag } from 'components/atoms/container/BoxCard'
import colors from 'styles/colors'
import { ReportImg } from 'components/atoms/Icons'
import moment from 'moment'
import RowStack from 'components/atoms/container/RowStack'
import AssessmentReportDialog from './AssessmentReportDialog'
import CircularProgressBar from 'components/atoms/CircularProgressBar'
import CopyToClipboardButton from 'components/atoms/button/CopyToClipboardButton'
import TeamProgressCard from './TeamProgressCard'

const ClosedAssementCard = ({
  assessmentData,
  closingTimeStamp,
  isAdmin = false
}) => {
  const { t, i18n } = useTranslation()
  moment.locale(i18n.resolvedLanguage)

  return (
    <>
      <CardStack spacing={12} justifyContent={'center'}>
        <CardStack spacing={2} height={'auto'}>
          <Typography variant='h1'>{t('fullReport.selfAssessment')}</Typography>
          <Typography variant='h1'>{t('fullReport.fullReport')}</Typography>

          <CardTag style={{ marginTop: 12 }}>
            {t('common.completed')}:{' '}
            {moment(closingTimeStamp).format('MMMM DD, YYYY')}
          </CardTag>
        </CardStack>

        <RowStack spacing={4}>
          <AssessmentReportDialog data={assessmentData} />

          <CopyToClipboardButton
            copyText={assessmentData?.pdfLink || assessmentData?.shareableLink}
          />
        </RowStack>
      </CardStack>

      <ReportImg
        sx={{ width: isAdmin ? 370 : 'initial', right: isAdmin ? '-10%' : 0 }}
      />
    </>
  )
}

const AssessmentHead = ({
  assessmentProgress,
  isClosed,
  assessmentData,
  closingTimeStamp,
  setShowDashboard,
  isAdmin = false,
  completionRate,
  participants,
  completedAssessments
}) => {
  const { t, i18n } = useTranslation()
  moment.locale(i18n.resolvedLanguage)
  const navigate = useNavigate()

  const HeadTags = ({ containerStyle = {} }) => {
    return (
      <CardStack spacing={4} style={containerStyle}>
        <CardTag>
          {t('fullReport.startDate')}:{' '}
          {moment(assessmentProgress?.createdAt).format('MMMM DD')}
        </CardTag>
        {assessmentProgress?.currentCategory && (
          <>
            {!isAdmin && (
              <CardTag>
                {t('fullReport.currentPillar')}:{' '}
                {assessmentProgress?.currentCategory?.modelName}
              </CardTag>
            )}

            <CardTag>
              {t('fullReport.currentCategory')}:{' '}
              {assessmentProgress?.currentCategory?.name}
            </CardTag>
          </>
        )}
      </CardStack>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={isAdmin ? 8 : 12}>
          <Card>
            {isClosed ? (
              <ClosedAssementCard
                assessmentData={assessmentData}
                closingTimeStamp={closingTimeStamp}
                isAdmin={isAdmin}
              />
            ) : (
              <>
                <RowStack justifyContent={'space-between'} spacing={24}>
                  <CardStack
                    spacing={12}
                    justifyContent={'center'}
                    width={isAdmin ? 'auto' : '33%'}>
                    <CardStack spacing={2}>
                      <Typography variant='h1'>
                        {t('fullReport.leadership')}
                      </Typography>
                      <Typography variant='h1'>
                        {t('fullReport.selfAssessment')}
                      </Typography>

                      {isAdmin ? (
                        <HeadTags />
                      ) : (
                        <Typography variant='body1' color={colors.Grey400}>
                          {t('fullReport.completeThisAssessment')}
                        </Typography>
                      )}
                    </CardStack>

                    <StyledButton onClick={() => navigate(FULL_ASSESSMENT)}>
                      {t('fullReport.continueAssessment')}
                    </StyledButton>
                  </CardStack>

                  {!isAdmin && <HeadTags />}

                  <CircularProgressBar
                    value={assessmentProgress?.progress}
                    useGradientArch={true}
                    emptyArchColor={colors.MutedTeal50}
                    innerRadius={105}
                    cornerRadius={0}
                    backgroundColor={colors.White}
                    labelSubText={t('common.completed')}
                    containerSx={{ height: '100%', width: 265 }}
                    labelStyle={[
                      {
                        fontSize: '48px',
                        fontWeight: '700'
                      },
                      {
                        fill: colors.Grey400,
                        fontSize: '16px'
                      }
                    ]}
                  />
                </RowStack>
              </>
            )}
          </Card>
        </Grid>

        {isAdmin && (
          <Grid item xs={4} paddingLeft={12}>
            <TeamProgressCard
              completionRate={completionRate}
              participants={participants}
              completedAssessments={completedAssessments}
              setShowDashboard={setShowDashboard}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
export default AssessmentHead
