import { Box } from '@mui/material'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import { PageHeader } from 'components/atoms/Text'
import PulseSurveyInvites from 'components/organisms/pulsSurveys/PulseSurveyInvites'
import PulseSurveyList from 'components/organisms/pulsSurveys/PulseSurveyList'
import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'
import SinglePageStack from 'components/templates/SinglePageStack'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const PulseSurveys = () => {
  const { t } = useTranslation()
  const location = useLocation()
  return (
    <FeedbackPage>
      <SinglePageStack maxWidth='xl'>
        {location.state?.from ? <BackIconTextButton /> : null}
        <PageHeader>{t('pulseSurvey.incomingPulseSurvey')}</PageHeader>
        <PulseSurveyInvites />
        <Box height='20px' />
        <PulseSurveyList />
      </SinglePageStack>
    </FeedbackPage>
  )
}

export default PulseSurveys
