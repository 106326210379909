import { microsoftRedirectUrl, microsoftAppId } from 'config/config'
import { LogLevel } from '@azure/msal-browser'

export const microsoftAuthConfig = {
  appId: microsoftAppId,
  canlendarScopes: ['calendars.read', 'openid', 'offline_access'],
  loginScopes: ['openid', 'profile', 'email', 'user.read']
}

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: microsoftAppId,
    authority: 'https://login.microsoftonline.com/common/',
    redirectUri: microsoftRedirectUrl
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      }
    }
  }
}

