import { TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { LANGUAGES } from 'utils/constants'
require('moment/locale/fr')

const DateCell = ({ value, ...otherProps }) => {
  const { i18n } = useTranslation()
  moment.locale(i18n.resolvedLanguage || LANGUAGES.EN)

  return (
    <TableCell align='left' {...otherProps}>
      {value ? moment.utc(value)?.local().format('MMM DD, YYYY') : null}
    </TableCell>
  )
}

export default DateCell
