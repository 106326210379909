import axios from 'axios'
import { apiVersion, serverEndpoint } from 'config/config'
import { getLocalUser } from './localStorage'

const instance = axios.create({ baseURL: `${serverEndpoint}/api/${apiVersion}` })
const header = () => {
  const localUser = getLocalUser()
  const lang = localStorage.getItem('i18nextLng')
  return {
    Authorization: localUser?.token,
    Accept: 'application/json',
    'Accept-Language': lang || 'en'
  }
}

export const getRequest = async (url, params) => {
  return instance
    .get(url, {
      headers: header(),
      params: params
    })
    .then(response => {
      return response
    })
    .catch(error => {
      return handleErrorResponse(error)
    })
}

export const patchRequest = async (url, body, config) => {
  return instance
    .patch(url, body, {
      headers: header(),
      ...config
    })
    .then(response => {
      return response
    })
    .catch(error => {
      return handleErrorResponse(error)
    })
}

export const postRequest = async (url, body, config) => {
  return instance
    .post(url, body, {
      headers: header(),
      ...config
    })
    .then(response => {
      return response
    })
    .catch(error => {
      return handleErrorResponse(error)
    })
}

export const deleteRequest = async (url, params) => {
  return instance
    .delete(url, {
      params,
      headers: header()
    })
    .then(response => {
      return response
    })
    .catch(error => {
      return handleErrorResponse(error)
    })
}

const handleErrorResponse = error => {
  if (error.message === 'Network Error') {
    window.alert('Your browser does not have an internet connection.')
  }
  return error.response
}
