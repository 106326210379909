import SinglePageCard from 'components/templates/SinglePageCard'
import { Typography } from '@mui/material'

const SinglePageMessageCard = ({ titleText, message, children, noBorder=false }) => (
  <SinglePageCard title={titleText} noBorder={noBorder}>
    <Typography variant='body1'>{message}</Typography>
    {children}
  </SinglePageCard>
)

export default SinglePageMessageCard
