import AuthorizedPage from 'components/templates/AuthorizedPage'
import ContentHeaderTabs from 'components/organisms/ContentHeaderTabs'
import { PAGE_TITLES } from 'utils/constants'
import { HOME } from 'routes/constants'
import { Box } from '@mui/material'

const HomePage = ({ children, pageTitle, ...otherProps }) => {
  return (
    <AuthorizedPage
      pageTitle={pageTitle || PAGE_TITLES.DEFAULT}
      {...otherProps}
      headerTabs={<ContentHeaderTabs activePage={HOME} />}>
      <Box width='100%'>{children}</Box>
    </AuthorizedPage>
  )
}

export default HomePage
