import { Typography } from '@mui/material'
import ReportPage from 'components/templates/ReportPage'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import { ActionText } from 'components/atoms/Text'
import { MONARK_WEBSITE, MONARK_CONTACT_EMAIL } from 'utils/constants'
import { useTranslation } from 'react-i18next'

const CopyrightPage = ({ downloadingFile, assessmentName, pageNum }) => {
  const { t } = useTranslation()
  const typoSx = {
    color: colors.MidnightTeal400,
    lineHeight: '140%'
  }
  return (
    <>
      <ReportPage
        downloadingFile={downloadingFile}
        assessmentName={assessmentName}
        pageNum={pageNum}>
        <Typography variant='body1Nano' sx={typoSx} marginTop={'75%'}>
          {t('fullReport.copyrightText')}
        </Typography>

        <Typography variant='body1Nano' sx={typoSx} marginTop={36}>
          {t('fullReport.forAdditionalInformation')}
        </Typography>

        <InCardStack alignItems={'flex-start'} spacing={5} marginTop={15.5}>
          <Typography variant='body1Nano' sx={{ ...typoSx, fontWeight: 500 }}>
            {t('fullReport.monarkInc')}
          </Typography>

          <ActionText
            variant='body1Nano'
            sx={{ ...typoSx, fontWeight: 500 }}
            href={`mailto:${MONARK_CONTACT_EMAIL}`}>
            {MONARK_CONTACT_EMAIL}
          </ActionText>

          <ActionText
            variant='body1Nano'
            sx={{ ...typoSx, fontWeight: 500 }}
            href={`https://${MONARK_WEBSITE}/`}
            target='_blank'>
            {MONARK_WEBSITE}
          </ActionText>
        </InCardStack>
      </ReportPage>
    </>
  )
}

export default CopyrightPage
