import { Box, CardContent, Grid, Stack, Typography } from '@mui/material'
import Carousel from 'components/atoms/Carousel'
import { UpwardIcon } from 'components/atoms/Icons'
import LeadershipModelItem from 'components/atoms/LeadershipModelItem'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import PathItem from 'components/molecules/PathItem'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { JOURNEY_NEW } from 'routes/constants'
import {
  LEADERSHIP_MODELS_URL,
  USER_QUIZ_RESULTS_URL
} from 'services/constants'
import { getRequest } from 'utils/api'
import { getGoalNameById } from 'utils/getSupportingInfo'
import { updatePageState } from 'utils/windowHistoryHelper'
import AssessmentTimelineCard from './assessments/AssessmentTimelineCard'
import { getStates } from 'services/userServices'
import { getLocalUser } from 'utils/localStorage'

const LeadershipPathList = () => {
  const location = useLocation()
  const [leadershipModels, setLeadershipModels] = useState([])
  const [requestError, setRequestError] = useState(undefined)
  const [activeIndex, setActiveIndex] = useState(
    window.history.state?.activeIndex || 0
  )
  const [onGoingGoalId, setOnGoingGoalId] = useState(null)
  const [onGoingPathName, setOnGoingPathName] = useState('')
  const [selectedGoal, setSelectedGoal] = useState(
    window.history.state?.selectedGoal || undefined
  )
  const [showAssessments, setShowAssessments] = useState(
    window.history.state?.showAssessments || false
  )
  useEffect(() => {
    const fetchData = async () => {
      const userStates = await getStates()
      const userLicensedGoalIds = getLocalUser()?.licensedGoalIds
      if (
        userStates.currentGoalId &&
        (!userStates.completedGoals ||
          userStates.completedGoals.indexOf(userStates.currentGoalId) < 0)
      ) {
        const pathName = await getGoalNameById(userStates.currentGoalId)
        setOnGoingGoalId(userStates.currentGoalId)
        setOnGoingPathName(pathName)
      }

      const completedGoals = userStates.completedGoals
      const resQuizResult = await getRequest(USER_QUIZ_RESULTS_URL)
      const res = await getRequest(LEADERSHIP_MODELS_URL)
      if (res.status === 200 && resQuizResult.status === 200) {
        const models = res.data.data
        const quizResults = resQuizResult.data.data
        const mappedModels = models.map(model => {
          model.goals = model.goals
            .map(g => {
              g.completed = completedGoals?.indexOf(g.id) > -1
              if (g.completed || g.id === userStates.currentGoalId) {
                const quiz = quizResults.find(q => q.goalStep.goal_id === g.id)
                g.completedQuizStepId = quiz?.goalStepId
              }
              g.licensed = !userLicensedGoalIds || userLicensedGoalIds.includes(g.id)
              return g
            })
            .sort((a, b) => (a.available && !b.available ? -1 : 1))
          model.goalsCount = model.goals.length
          model.completedGoalsCount = model.goals.filter(
            g => g.completed
          ).length
          return model
        })
        // If navigate back from behaviours page, goal should have been set with windows history
        if (!selectedGoal) {
          const ongGoingGoal =
          userStates.currentGoalId && location.pathname !== JOURNEY_NEW
          const activeGoalId = location.state?.goalId || userStates.currentGoalId
          const directToAssessment =
            location.state?.showAssessment && location.state?.goalId
          if (directToAssessment || ongGoingGoal) {
            const activeModel = models.findIndex(m =>
              m.goals.find(g => g.id === activeGoalId)
            )
            if (activeModel > -1) {
              setActiveIndex(activeModel)
              const activeGoal = models[activeModel].goals.find(
                g => g.id === activeGoalId
              )
              setSelectedGoal(activeGoal)
              if (directToAssessment) setShowAssessments(true)
            }
          }
        }

        setLeadershipModels(mappedModels)
      } else {
        setRequestError(true)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateActive = i => {
    setActiveIndex(i)
    setShowAssessments(false)
    updatePageState({ activeIndex: i, showAssessments: false })
  }

  const assessmentClickCallback = goal => {
    setSelectedGoal(goal)
    setShowAssessments(true)
    updatePageState({ selectedGoal: goal, showAssessments: true })
  }

  const leadershiItemSize = 130

  return (
    <CardContent width='100%' data-testid='leadership-paths-view'>
      {requestError === true ? (
        <RequestRetryAlert />
      ) : (
        <Grid container width='100%' gap={15}>
          <Grid item xs={4} minWidth={330}>
            <Box height={350} overflow='hidden'>
              <Carousel
                height={450}
                width={330}
                yOrigin={42}
                yRadius={48}
                itemSize={leadershiItemSize}
                activeCallBack={updateActive}
                activeIndex={activeIndex}
                data={leadershipModels.map((m, i) => (
                  <LeadershipModelItem
                    active={i === activeIndex}
                    name={m.name}
                    size={leadershiItemSize}
                    tagLabel={`${m.completedGoalsCount}/${m.goalsCount}`}
                    progress={m.completedGoalsCount / m.goalsCount}
                  />
                ))}
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            {showAssessments ? (
              <AssessmentTimelineCard
                goal={selectedGoal}
                returnCallback={() => setShowAssessments(false)}
              />
            ) : (
              <Stack spacing={5} padding={0}>
                <Typography variant='h3' alignItems='center' display='flex'>
                  <UpwardIcon sx={{ marginRight: '8px' }} />
                  {leadershipModels[activeIndex]?.name}
                </Typography>
                <Typography variant='body2'>
                  {leadershipModels[activeIndex]?.about}
                </Typography>
                {leadershipModels[activeIndex]?.goals.map(g => (
                  <PathItem
                    goalData={g}
                    key={g.id}
                    onGoingGoalId={onGoingGoalId}
                    onGoingPathName={onGoingPathName}
                    onAssessmentClick={() => assessmentClickCallback(g)}
                  />
                ))}
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
    </CardContent>
  )
}
export default LeadershipPathList
