import { useState, useEffect } from 'react'
import { TableCell, TableRow, Alert, Snackbar } from '@mui/material'
import { USER_ALLOWED_FEATURE_URL, FEATURE_STATUSES } from 'services/constants'
import { postRequest, deleteRequest } from 'utils/api'
import { isMatchingUser } from 'utils/userSearchHelper'
import colors from 'styles/colors'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import AvatarHeaderCell from 'components/atoms/table/AvartarHeaderCell'
import SettingSwitch from 'components/atoms/input/SettingSwitch'

const FeatureUserAccess = ({
  users,
  setUsers,
  selectedFeature,
  getFeatureAllowedUsers,
  userFilterText = ''
}) => {
  const [requestError, setRequestError] = useState(null)
  const [toggled, setToggled] = useState(false)

  useEffect(() => {
    let globalToggle = true
    users.forEach(user => {
      if (!user.accessAllowed) globalToggle = false
    })
    setToggled(globalToggle)
  }, [users])

  const setUsersAccess = async userIds => {
    const resp = await postRequest(USER_ALLOWED_FEATURE_URL, {
      userIds,
      featureId: selectedFeature.id
    })
    if (resp.status === 201) {
      const newUsers = [...users]
      newUsers.forEach(user => {
        if (userIds.indexOf(user.id) > -1) user.accessAllowed = true
      })
      setUsers(newUsers)
    } else {
      setRequestError(true)
      setTimeout(() => {
        setRequestError(false)
      }, 3000)
    }

    // Get updated Allowed User data
    getFeatureAllowedUsers()
  }

  const deleteUsersAccess = async userIds => {
    await Promise.all(
      userIds.map(async userId => {
        const resp = await deleteRequest(USER_ALLOWED_FEATURE_URL, {
          userId,
          featureId: selectedFeature.id
        })
        if (resp.status === 204) {
          const newUsers = [...users]
          newUsers.forEach(user => {
            if (userIds.indexOf(user.id) > -1) user.accessAllowed = false
          })
          setUsers(newUsers)
        } else {
          setRequestError(true)
          setTimeout(() => {
            setRequestError(false)
          }, 3000)
        }
      })
    )

    // Get updated Allowed User data
    getFeatureAllowedUsers()
  }

  const handleGlobalToggle = e => {
    const access = e.target.checked
    setToggled(access)
    const userIds = users.map(u => u.id)
    if (access) setUsersAccess(userIds)
    else deleteUsersAccess(userIds)
  }

  const handleIndividualToggle = async (e, user) => {
    const access = e.target.checked
    if (access) setUsersAccess([user.id])
    else deleteUsersAccess([user.id])
    if (!access) setToggled(false)
  }

  return (
    <>
      <PresetTableContainer
        shorterRow={true}
        maxHeight='450px'
        headers={
          <>
            <TableCell>User Details</TableCell>
            <TableCell>Allow Access</TableCell>
          </>
        }>
        <TableRow
          sx={{
            '& .MuiTableCell-root': {
              border: 0,
              borderBottom: '1px solid ' + colors.Solitude,
              paddingBottom: '20px',
              paddingTop: '20px'
            }
          }}>
          <TableCell style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
            Toggle All
          </TableCell>
          <TableCell>
            <SettingSwitch
              checked={toggled}
              disabled={selectedFeature.status === FEATURE_STATUSES.PRODUCTION}
              onChange={e => handleGlobalToggle(e)}
              testId='switch-access-all'
            />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell height='30px' />
        </TableRow>

        {users
          .filter(u => isMatchingUser(u, userFilterText))
          .map((u, index) => {
            return (
              <TableRow key={index}>
                <AvatarHeaderCell user={u} />
                <TableCell width='200px'>
                  <SettingSwitch
                    checked={u.accessAllowed}
                    disabled={
                      selectedFeature.status === FEATURE_STATUSES.PRODUCTION
                    }
                    onChange={e => handleIndividualToggle(e, u)}
                    testId={`switch-access-push`}
                  />
                </TableCell>
              </TableRow>
            )
          })}
      </PresetTableContainer>
      <Snackbar
        open={requestError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity='error'>Request failed! Please try again.</Alert>
      </Snackbar>
    </>
  )
}

export default FeatureUserAccess
