import { Stack, Typography } from '@mui/material'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import { PageHeader } from 'components/atoms/Text'
import LeadershipPathList from 'components/organisms/LeadershipPathList'
import JourneyPage from 'components/templates/navigationPageTemplates/JourneyPage'
import { useTranslation } from 'react-i18next'

const LeadershipModel = () => {
  const { t } = useTranslation()
  return (
    <JourneyPage>
      <LeftJustifyCardContent>
        <Stack padding={0} spacing={5}>
          <PageHeader>
            {t('leadership.monarkLeadershipModel')}
          </PageHeader>
          <Typography variant='subtitle2'>
           {t('leadership.chooseAnItem')}
          </Typography>
        </Stack>
      </LeftJustifyCardContent>
      <LeadershipPathList />
    </JourneyPage>
  )
}

export default LeadershipModel
