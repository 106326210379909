import { Box, Stack, Typography } from '@mui/material'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import RowStack from 'components/atoms/container/RowStack'
import { useTranslation } from 'react-i18next'
import { TabPanel } from './SuperLeaderTabs'

export const BreakdownDisplayPanel = ({
  children,
  activeScoreTab,
  setActiveScoreTab,
  titleKey,
  descriptionKey,
  currentTab
}) => {
  const { t } = useTranslation()
  return (
    <TabPanel tab={currentTab} activeTab={activeScoreTab}>
      <Stack spacing={10}>
        <RowStack>
          <BackIconTextButton onClick={setActiveScoreTab} />
          <Box sx={{ display: 'flex', width: '80%', justifyContent: 'center' }}>
            <Typography variant='h2'>{t(titleKey)}</Typography>
          </Box>
        </RowStack>
        <Typography variant='body2'>{t(descriptionKey)}</Typography>
        {children}
      </Stack>
    </TabPanel>
  )
}
