import { Dialog, Divider, IconButton, Stack, Typography } from '@mui/material'
import { Close, DeleteOutlined, ModeEditOutlined } from '@mui/icons-material'
import StyledButton, {
  NoBorderButton
} from 'components/atoms/button/StyledButton'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import RowStack from 'components/atoms/container/RowStack'
import { deleteRequest, patchRequest, postRequest } from 'utils/api'
import { USER_CONNECTION_NOTE_URL } from 'services/constants'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { TextBox } from 'components/atoms/input/StyledInputs'
import NotificationSnackBar from '../notificationOverlay/NotificationSnackBar'
import { formatDateTime } from 'utils/formatDateTime'
import colors from 'styles/colors'

export const ContactNotesPopup = ({
  userConnectionId,
  completeCallback,
  open,
  note
}) => {
  const { t } = useTranslation()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [editing, setEditing] = useState(!note)
  const [title, setTitle] = useState(note?.title || '')
  const [notes, setNotes] = useState(note?.notes || '')
  const [noteObj, setNoteObj] = useState(note)
  const [updated, setUpdated] = useState(false)
  const [requestError, setRequestError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!open && !note) {
      // Reset params if not editing an existing note
      setTitle('')
      setNotes('')
      setNoteObj(null)
      setEditing(true)
    }
  }, [open, note])

  const handleUpdateNote = async () => {
    setLoading(true)
    let res
    if (noteObj) {
      res = await patchRequest(`${USER_CONNECTION_NOTE_URL}/${noteObj.id}`, {
        title: title || t('contact.manualNotesEntry'),
        notes
      })
    } else {
      res = await postRequest(USER_CONNECTION_NOTE_URL, {
        userConnectionId,
        title: title || t('contact.manualNotesEntry'),
        notes
      })
    }
    if (res.status !== 200 && res.status !== 201) {
      setRequestError(true)
    } else {
      setEditing(false)
      setUpdated(true)
      setNoteObj(res.data.data)
    }
    if (!note) {
      completeCallback(true)
    }
    setLoading(false)
  }

  const handleCancel = () => {
    completeCallback(updated)
  }

  const handleDeleteNote = async confirmed => {
    if (confirmed) {
      setLoading(true)
      const res = await deleteRequest(
        `${USER_CONNECTION_NOTE_URL}/${noteObj.id}`
      )
      if (res.status !== 204) {
        setRequestError(true)
      } else {
        completeCallback(true)
      }
      setLoading(false)
    }
    setDeleteOpen(false)
  }
  return (
    <>
      <Dialog open={open} onClose={() => handleCancel()}>
        <Stack spacing={8} width={550} sx={{ minHeight: 300 }}>
          <RowStack justifyContent='space-between'>
            <RowStack>
              {editing && (
                <Typography variant='h6'>
                  {note ? t('common.editing') : t('contact.addNote')}
                </Typography>
              )}
              {noteObj && (
                <Typography variant='body1Micro'>
                  {formatDateTime(noteObj.createdAt)}
                </Typography>
              )}
            </RowStack>
            <RowStack justifyContent='flex-end' spacing={1}>
              {!editing && (
                <IconButton size='small' onClick={() => setEditing(true)}>
                  <ModeEditOutlined sx={{ color: colors.LightGrey400 }} />
                </IconButton>
              )}
              {noteObj && (
                <IconButton size='small' onClick={() => setDeleteOpen(true)}>
                  <DeleteOutlined sx={{ color: colors.LightGrey400 }} />
                </IconButton>
              )}
              {note && <Divider orientation='vertical' sx={{ height: 20 }} />}
              <IconButton size='small' onClick={() => handleCancel()}>
                <Close sx={{ color: colors.LightGrey400 }} />
              </IconButton>
            </RowStack>
          </RowStack>
          {editing ? (
            <>
              <TextBox
                value={title}
                placeholder={t('contact.noteTitle')}
                onChange={e => setTitle(e.target.value)}
                maxRows={1}
                removeBorder={false}
              />
              <TextBox
                value={notes}
                multiline
                removeBorder={false}
                onChange={e => setNotes(e.target.value)}
                rows={15}
                sx={{ fontSize: '14px' }}
              />
              <RowStack justifyContent='flex-end'>
                <NoBorderButton onClick={() => setEditing(false)}>
                  {t('common.cancel')}
                </NoBorderButton>
                <StyledButton onClick={handleUpdateNote}>
                  {t('common.save')}
                </StyledButton>
              </RowStack>
            </>
          ) : (
            <>
              <Typography variant='h4'>{title}</Typography>
              <Typography sx={{ whiteSpace: 'pre-line' }}>{notes}</Typography>
            </>
          )}
          {requestError && (
            <NotificationSnackBar
              open={requestError}
              setOpen={setRequestError}
              error={true}
            />
          )}
        </Stack>
      </Dialog>
      <ConfirmCancelDialog
        bodyWidth={400}
        open={deleteOpen}
        onClose={handleDeleteNote}
        updatedStyle={true}
        titleText={t('contact.deleteNote')}
        notificationText={t('contact.aboutToDeleteNotes.part1')}
        confirmText={t('common.delete')}
        cancelText={t('common.cancel')}>
        <Typography variant='body1Large'>
          {t('contact.aboutToDeleteNotes.part2')}
        </Typography>
      </ConfirmCancelDialog>
      <OverlayLoading open={loading} />
    </>
  )
}

export const CreateNoteButton = ({
  userConnectionId,
  refreshCallback,
  buttonProps
}) => {
  const { t } = useTranslation()
  const [newNoteOpen, setNewNoteOpen] = useState(false)

  const completeCallback = submitted => {
    if (submitted) {
      refreshCallback()
    }
    setNewNoteOpen(false)
  }
  return (
    <>
      <StyledButton onClick={() => setNewNoteOpen(true)} {...buttonProps}>
        {t('myTeam.takeANote')}
      </StyledButton>
      <ContactNotesPopup
        userConnectionId={userConnectionId}
        completeCallback={completeCallback}
        open={newNoteOpen}
      />
    </>
  )
}
