import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ASSESSMENT_BEHAVIOUR,
  ASSESSMENT_BEHAVIOUR_COMPARISON
} from 'routes/constants'
import { getDisplayScore } from 'utils/formatText'
import ScoreChangeDisplayBar from './ScoreChangeDisplayBar'
import { useTranslation } from 'react-i18next'
import { ChangeTable, ChangeTableRow, SelfOtherToggle } from '../ChangeTable'
import ExportAssessmentButton from 'components/atoms/button/ExportAssessmentButton'
import InCardStack from 'components/atoms/InCardStack'

const AssessmentChangeTable = ({
  data,
  titleText,
  disableBehaviorRedirect = false,
  showExport = false
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [selfState, setSelfState] = useState(true)
  const [displayData, setDisplayData] = useState({})
  const [hasOlderData, setHasOlderData] = useState(false)
  const [behaviourInfoData, setBehaviourInfoData] = useState([])

  useEffect(() => {
    const olderData = data[1]
    const newerData = data[0]
    const scoreField = selfState
      ? 'selfAllBehaviourAvg'
      : 'othersAllBehaviourAvg'
    const newDisplayData = getDisplayScore(
      olderData ? olderData[scoreField] : null,
      newerData[scoreField]
    )
    if (olderData) {
      setHasOlderData(true)
      newDisplayData.oldTimeStamp = data[1].ratingTimeStamp
    } else {
      setHasOlderData(false)
    }
    newDisplayData.newTimeStamp = data[0].ratingTimeStamp
    setDisplayData(newDisplayData)
  }, [data, selfState])

  const getBehaviourInfoData = (scoreField, behaviorScoreField) => {
    const hasOlderData = data[1] ? data[1][scoreField] : null

    return [
      ...data[0].behaviourInfoData.map((behavior, index) => {
        const hasOldScore =
          hasOlderData && data[1].behaviourInfoData[index][behaviorScoreField]
        const oldScore = hasOldScore
          ? data[1].behaviourInfoData[index][behaviorScoreField].toFixed(1)
          : '-'
        const newScore = behavior[behaviorScoreField]?.toFixed(1) || '-'

        return [behavior.name, oldScore, newScore]
      })
    ]
  }

  useEffect(() => {
    const newData = {
      self: {
        scoreField: 'selfAllBehaviourAvg',
        data: getBehaviourInfoData('selfAllBehaviourAvg', 'avgSelfRating')
      },
      others: {
        scoreField: 'othersAllBehaviourAvg',
        data: getBehaviourInfoData('othersAllBehaviourAvg', 'avgOthersRating')
      }
    }

    setBehaviourInfoData(newData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleRowClick = behavior => {
    if (!disableBehaviorRedirect) {
      if (!hasOlderData) {
        navigate(
          `${ASSESSMENT_BEHAVIOUR}/${data[0].goalData.id}/${data[0].id}/${behavior}`,
          {
            state: {
              assessmentData: data[0]
            }
          }
        )
      } else {
        navigate(
          `${ASSESSMENT_BEHAVIOUR_COMPARISON}/${data[0].goalData.id}/${behavior}`,
          { state: { behaviourData: data } }
        )
      }
    }
  }

  return (
    <>
      <InCardStack
        direction='row'
        width={'100%'}
        justifyContent={'space-between'}>
        <Typography variant='subtitle1'>
          {titleText ? titleText : t('assessmentTimeline.assessingYourChange')}
        </Typography>

        {showExport && (
          <ExportAssessmentButton
            title={
              titleText
                ? titleText
                : t('assessmentTimeline.assessingYourChange')
            }
            data={data}
            behaviourData={behaviourInfoData}
            fileName='viewing-assessment-change'
          />
        )}
      </InCardStack>

      {data[0].goalData?.enableOthersAssessment ? (
        <SelfOtherToggle selfState={selfState} setSelfState={setSelfState} />
      ) : null}
      <ScoreChangeDisplayBar
        displayData={displayData}
        hasOlderData={hasOlderData}
      />
      <ChangeTable
        data={data}
        timestampField='ratingTimeStamp'
        showOlderColumn={true}
        hasOlderScore={hasOlderData && displayData.oldScore > 0}>
        {behaviourInfoData[selfState ? 'self' : 'others']?.data.map(
          ([behavior, oldScore, newScore], index) => {
            return (
              <ChangeTableRow
                key={behavior}
                name={behavior}
                oldScore={oldScore}
                newScore={newScore}
                handleRowClick={() => handleRowClick(behavior)}
                index={index}
                showOlderColumn={true}
              />
            )
          }
        )}
      </ChangeTable>
      <Typography
        color='primary'
        fontStyle='italic'
        variant='caption'
        paddingBottom='30px'>
        {t('assessmentTimeline.clickVariousBehaviours')}
      </Typography>
    </>
  )
}

export default AssessmentChangeTable
