import { IconButton, Tooltip, Typography } from '@mui/material'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { deleteRequest } from 'utils/api'
import { USERS_URL } from 'services/constants'
import { useState } from 'react'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useTranslation } from 'react-i18next'

const DeactivateUserButton = ({ successCallback, user, fromLicensed }) => {
  const { t } = useTranslation()
  const [sendDialogOpen, setSendDialogOpen] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const [loading, setLoading] = useState(false)
  const extraMessage = fromLicensed
    ? t('company.free1AdditionalLicense')
    : ''

  const handleDialogSelect = async confirmed => {
    if (confirmed) {
      setLoading(true)
      const res = await deleteRequest(`${USERS_URL}/${user.id}`)
      if (res.status === 204) {
        setSendDialogOpen(false)
        await successCallback(user.id)
      } else setRequestError(true)
      setLoading(false)
    } else {
      setSendDialogOpen(false)
    }
  }
  return (
    <>
      <Tooltip title={t('company.deactivateUser')}>
        <IconButton
          onClick={() => setSendDialogOpen(true)}
          data-testid={`deactivate-user-${user.id}`}>
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </Tooltip>
      <ConfirmCancelDialog
        open={sendDialogOpen}
        titleText={t('company.deactivateUser')}
        onClose={handleDialogSelect}
        confirmText={t('company.yesDeactivateUser')}
        cancelText={t('common.cancel')}
        buttonsDirection='row'>
        <Typography>
          {t('company.willDisableUsersAccount')}
          {extraMessage}
          {t('company.actionCannotUndone')}
        </Typography>
        {requestError ? <RequestRetryAlert /> : null}
      </ConfirmCancelDialog>
      <OverlayLoading open={loading} />
    </>
  )
}

export default DeactivateUserButton
