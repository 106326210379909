import { isMobile } from 'react-device-detect'

// iOS detection logic updated with https://stackoverflow.com/a/38241481
const platform = navigator?.userAgentData?.platform || navigator.platform

const iphoneDevices = ['iPhone Simulator', 'iPod Simulator', 'iPhone', 'iPod']

export const iOS = () =>
  iphoneDevices.some(device => window.navigator.userAgent.includes(device))

export const isiPad = () =>
  !iphoneDevices.some(device => window.navigator.userAgent.includes(device)) &&
  (['iPad Simulator', 'iPad'].includes(platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document))

// Identifies on both macOS iPadOS
export const isSafari = () => window.navigator.userAgent.includes('Version')

// Tested on macOS and Linux
export const isFirefox = () => window.navigator.userAgent.includes('Firefox')

export const checkIsMobile = () => {
  // If user on mobile and not iPad
  if ((iOS() || isMobile) && !isiPad()) {
    return true
  }
  return false
}
