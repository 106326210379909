import React from 'react'
import SimplePage from 'components/templates/SimplePage'
import { ROOT } from 'routes/constants'
import { CardContent, Typography } from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import FormCard from 'components/templates/FormCard'

const NotFound = () => {
  return (
    <SimplePage>
      {/* Page Not Found! */}
      <FormCard>
        <CardContent>
          <Typography variant='h5'>Page not found!</Typography>
          {/* <Alert severity='error'>404: page not found!</Alert> */}
        </CardContent>
        <CardContent>
          <LinkButton to={ROOT}>Return to Home</LinkButton>
        </CardContent>
      </FormCard>
    </SimplePage>
  )
}

export default NotFound
