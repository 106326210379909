import { Typography, useTheme, Stack, Box } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import RadarGraphLegend from 'components/atoms/RadarGraphLegend'
import { formatDate } from 'utils/formatDateTime'
import RadarGraph from './RadarGraph'
import StyledAccordion from 'components/atoms/container/Accordion'
import RadarGraphAddMoreRaters from './RadarGraphAddMoreRaters'
import ShareResultPopupButton from './ShareResultPopupButton'
import { useTranslation } from 'react-i18next'

const AssessmentTimelineItem = ({ data, index }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  return (
    <StyledAccordion
      initialExpanded={index <= 1}
      summaryComponent={
        <Stack padding={0}>
          <Typography>{formatDate(data.ratingTimeStamp, i18n.resolvedLanguage)}</Typography>
          <InCardStack direction='row'>
            {data.goalData.enableOthersAssessment ? (
              <Typography variant='body2'>{data.othersRatingStats}</Typography>
            ) : null}
            <Typography>
              {data.isOpen ? (
                <Typography component='span' color={theme.palette.error.main}>
                  {t('assessmentTimeline.closingOn')}:
                </Typography>
              ) : (
                <Typography component='span'>{t('assessmentTimeline.closedOn')}:</Typography>
              )}{' '}
              <Typography component='span' variant='body2'>
                {formatDate(data.closingTimeStamp, i18n.resolvedLanguage)}
              </Typography>
            </Typography>
          </InCardStack>
        </Stack>
      }>
      <InCardStack>
        <Box width='100%'>
          <ShareResultPopupButton
            assessmentId={data.id}
            title={t('shareMyResults.selectRecipients')}
            buttonLabel={t('shareMyResults.heading')}
          />
        </Box>
        <RadarGraph assessmentData={data} />
        <RadarGraphLegend
          displayOthersRating={data.othersAllBehaviourAvg > 0}
        />
        {data.isOpen ? <RadarGraphAddMoreRaters assessmentData={data} /> : null}
      </InCardStack>
    </StyledAccordion>
  )
}

export default AssessmentTimelineItem
