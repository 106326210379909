import ShortButton from 'components/atoms/button/ShortButton'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useState } from 'react'
import {
  DEMO_SNAPSHOT_RESTORE_URL,
  DEMO_SNAPSHOT_URL
} from 'services/constants'
import { postRequest } from 'utils/api'
import { formatDate } from 'utils/formatDateTime'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import SuccessfulNotificationDialog from '../notificationOverlay/SuccessfulNotificationDialog'

export const SnapshotButton = ({ user, sucessCallBack }) => {
  const [loading, setLoading] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const handleConfirm = async confirmed => {
    setConfirmDialogOpen(false)
    if (confirmed) {
      setLoading(true)
      const res = await postRequest(DEMO_SNAPSHOT_URL, { userId: user.id })
      if (res.status === 201) {
        sucessCallBack()
        setSuccessDialogOpen(true)
      }
      setLoading(false)
    }
  }
  const handleClick = () => {
    if (user.snapshotLastModified) {
      setConfirmDialogOpen(true)
    } else {
      handleConfirm(true)
    }
  }
  return (
    <>
      <ShortButton onClick={handleClick}>Snapshot Now</ShortButton>
      {user.snapshotLastModified && (
        <ConfirmCancelDialog
          open={confirmDialogOpen}
          titleText='Overwriting Snapshot'
          onClose={handleConfirm}
          maxWidth='sm'
          confirmText='Yes, take a new snapshot'
          notificationText={
            <>
              You are about to overwrite the snapshot taken on{' '}
              <strong>{formatDate(user.snapshotLastModified)}</strong> for
              account <strong>{user.email}</strong>.
              <br />
              This action cannot be undone, are you sure?
            </>
          }
          cancelText='Cancel'
        />
      )}
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={`Snapshot Taken for ${user.email}`}
        buttonAction={() => setSuccessDialogOpen(false)}
        confirmButtonText='Ok'
      />
      <OverlayLoading open={loading} />
    </>
  )
}

export const RestoreButton = ({ user }) => {
  const [loading, setLoading] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)

  const handleConfirm = async confirmed => {
    setConfirmDialogOpen(false)
    if (confirmed) {
      setLoading(true)
      const res = await postRequest(DEMO_SNAPSHOT_RESTORE_URL, {
        userId: user.id
      })
      if (res.status === 200) {
        setSuccessDialogOpen(true)
      }
      setLoading(false)
    }
  }

  return (
    <>
      <ShortButton onClick={() => setConfirmDialogOpen(true)}>
        Restore
      </ShortButton>
      <ConfirmCancelDialog
        open={confirmDialogOpen}
        titleText='Restore Account'
        onClose={handleConfirm}
        maxWidth='sm'
        confirmText='Yes, restore'
        notificationText={
          <>
            You are about to restore account <strong>{user.email}</strong> to
            last snapshot date:{' '}
            <strong>{formatDate(user.snapshotLastModified)}</strong>.
            <br />
            Changes of path progress, owned assessments, pulse surveys, and
            comments made after this date will be removed.
            <br />
            This action cannot be undone, are you sure?
          </>
        }
        cancelText='Cancel'
        buttonsDirection='row'
      />
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={`Account Restored to ${formatDate(
          user.snapshotLastModified
        )} for ${user.email}`}
        buttonAction={() => setSuccessDialogOpen(false)}
        confirmButtonText='Ok'
      />
      <OverlayLoading open={loading} />
    </>
  )
}
