import {
  Box,
  CardContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import StepOne from 'components/organisms/user/onboardingSteps/StepOne'
import StepTwo from './onboardingSteps/StepTwo'
import StepThree from './onboardingSteps/StepThree'
import StepFour from './onboardingSteps/StepFour'
import { getLocalUser } from 'utils/localStorage'
import ConfirmCancelDialog from 'components/molecules/notificationOverlay/ConfirmCancelDialog'
import {
  LICENSE_TYPES,
} from 'services/constants'
import { utc } from 'moment'
import { HOME } from 'routes/constants'
import SuccessfulNotificationDialog from 'components/molecules/notificationOverlay/SuccessfulNotificationDialog'
import InCardStack from 'components/atoms/InCardStack'
import { formatDate } from 'utils/formatDateTime'
import AddRatersCard from '../assessments/AddRatersCard'
import OverlayLoading from 'components/atoms/OverlayLoading'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import { MONARK_SUPPORT_EMAIL } from 'utils/constants'
import { useTranslation } from 'react-i18next'

const OnboardingCard = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const [selectedGoal, setSelectedGoal] = useState({})
  const [loading] = useState(false)
  const userInfo = getLocalUser()

  const [licensePopupOpen] = useState(
    !userInfo.hasLicense
  )
  const [requestError] = useState(undefined)
  const [successDialogOpen] = useState(false)

  // Set initial active step
  const profileCompleted = Boolean(userInfo.department && userInfo.job)

  const forProfile = location.state?.completeProfile === true
  const initialStep = forProfile
    ? 1
    : profileCompleted
    ? userInfo.userStates.currentGoalId
      ? 3
      : 2
    : 0
  const [activeStep, setActiveStep] = useState(initialStep)

  // Callback from goal select screen
  const handleSelectGoal = () => {
    setActiveStep(3)
  }

  useEffect(() => {
    if (location.state && location.state?.selectedGoal) {
      setSelectedGoal(location.state.selectedGoal)
      handleSelectGoal()
    }
  }, [location.state])

  // If user have preset goal
  useEffect(() => {
    const fetchData = async () => {
      if (userInfo.userStates.currentGoalId) {
        const goal = await getGoalInfoById(userInfo.userStates.currentGoalId)
        setSelectedGoal(goal)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const OnboardingStepLabel = ({ n, label }) => (
    <StepLabel
      StepIconProps={{
        sx: {
          width: '40px',
          height: '40px',
          opacity: n > activeStep ? 0.7 : 1
        }
      }}>
      <Typography variant='subtitle1'>{label}</Typography>
    </StepLabel>
  )
  const connectorStyle = {
    '& .MuiStepConnector-root ': {
      top: '20px',
      '&.Mui-active .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main
      }
    }
  }
  const licenseDialogProps = {}
  if (licensePopupOpen) {
    const isTrial = userInfo.organization.licenseType === LICENSE_TYPES.TRIAL
    const licenseActive = utc(userInfo.organization.licenseExpireAt) > utc()
    licenseDialogProps.titleText = isTrial
      ? licenseActive
        ? t('onboarding.companyHasActiveTrial')
        : t('onboarding.companyTrialExpired')
      : licenseActive
      ? t('onboarding.companyAlreadyHasLicense')
      : t('onboarding.companyLicenseExpired')
    licenseDialogProps.confirmText = t('common.learnMore')
    licenseDialogProps.onClose = async value => {
      if (value) {
        const subject = t('welcomeArea.raterOnlySubject')
        const mailtoLink = `mailto:${MONARK_SUPPORT_EMAIL}?subject=${encodeURIComponent(subject)}`;
        window.location.href = mailtoLink;
      }
      navigate(HOME)
    }
    licenseDialogProps.children = (
      <InCardStack width='550px' marginY='10px'>
        {isTrial && licenseActive ? (
          <>
            <Typography variant='body2' textAlign='center'>
              {t('onboarding.companyCompletingTrial')}
            </Typography>
            <Typography variant='body2' textAlign='center'>
              {t('onboarding.trialExpireOn')}
              {formatDate(userInfo.organization.licenseExpireAt, i18n.resolvedLanguage)}.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant='body2' textAlign='center'>
              {t('onboarding.companyPreviouslyHadLicense')}
            </Typography>
            <Typography variant='body2' textAlign='center'>
              {t('onboarding.contactToLearn')}
            </Typography>
          </>
        )}
        {requestError ? <RequestRetryAlert /> : null}
      </InCardStack>
    )
  }
  return (
    <>
      <CardContent sx={{ marginTop: '20px' }}>
        <Box width='100%'>
          <Stepper activeStep={activeStep} sx={connectorStyle} alternativeLabel>
            <Step key={1}>
              <OnboardingStepLabel n={1} label={`${t('welcomeArea.welcome')}!`} />
            </Step>
            <Step key={2}>
              <OnboardingStepLabel n={2} label={t('onboarding.createProfile')} />
            </Step>
            <Step key={3}>
              <OnboardingStepLabel n={3} label={t('onboarding.selectPath')} />
            </Step>
            <Step key={4}>
              <OnboardingStepLabel n={4} label={t('onboarding.completeSelfAssessment')} />
            </Step>
            {selectedGoal?.enableOthersAssessment ? (
              <Step key={5}>
                <OnboardingStepLabel n={5} label={t('onboarding.inviteOthersToRate')} />
              </Step>
            ) : null}
          </Stepper>
        </Box>
      </CardContent>
      <CardContent sx={{ padding: 0 }}>
        {activeStep === 0 ? (
          <StepOne completeCallback={() => setActiveStep(1)} />
        ) : activeStep === 1 ? (
          <StepTwo completeCallback={() => setActiveStep(2)} />
        ) : activeStep === 2 ? (
          <StepThree
            completeCallback={handleSelectGoal}
            selectedGoal={selectedGoal}
          />
        ) : activeStep === 3 ? (
          <StepFour
            completeCallback={() => setActiveStep(4)}
            selectedGoal={selectedGoal}
          />
        ) : activeStep === 4 ? (
          <AddRatersCard fromSelfAssessment={true} goalId={selectedGoal.id} />
        ) : null}
      </CardContent>
      {licensePopupOpen ? (
        <ConfirmCancelDialog
          open={licensePopupOpen}
          {...licenseDialogProps}
          cancelText={t('onboarding.continueRater')}
          buttonsDirection='row'
          maxWidth='md'></ConfirmCancelDialog>
      ) : null}
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={t('onboarding.requestSent')}
        notificationText={t('onboarding.messageSent')}
        confirmButtonText={t('common.done')}
        buttonAction={() => navigate(HOME)}
      />
      <OverlayLoading open={loading} />
    </>
  )
}

export default OnboardingCard
