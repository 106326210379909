import Header from 'components/molecules/header/Header'
import {
  Alert,
  Box,
  Container,
  Divider,
  Fade,
  Snackbar,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { PAGE_TITLES } from 'utils/constants'
import { initPendo } from 'services/pendo'
import { firebaseApp, getFirebaseToken } from 'services/firebase'
import { getMessaging, onMessage, isSupported } from 'firebase/messaging'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getDeepLinkDestination } from 'utils/deepLinkActionHelper'
import { getSizeString } from 'utils/formatText'
import {
  logScreen,
  trackingScreenKey,
  updateViewTime
} from 'services/trackingScreenActions'

const AuthorizedPage = ({
  children,
  pageTitle = PAGE_TITLES.DEFAULT,
  headerTabs = null,
  pageHeader = null,
  whiteBackground = true,
  headerDivider = true,
  customizedBodyWidth = false
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [messageData, setMessageData] = useState({})
  const [showNotification, setShowNotification] = useState(false)
  const screenLog = useRef({})
  const headerHeight = window.innerWidth < 1122 ? 124 : 73

  // Screen monitor functions
  useEffect(() => {
    const updateParentViewTime = async () => {
      await updateViewTime(screenLog, trackingScreenKey)
    }
    const logParentScreen = async () => {
      await logScreen(screenLog, trackingScreenKey, location)
    }
    logParentScreen()
    window.addEventListener('focus', logParentScreen)
    window.addEventListener('blur', updateParentViewTime)
    return () => {
      window.removeEventListener('focus', logParentScreen)
      window.removeEventListener('blur', updateParentViewTime)
      updateParentViewTime()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  document.title = pageTitle
  initPendo(true)

  const initFirebase = async () => {
    if (process.env.NODE_ENV !== 'test') {
      const isFirebaseSupported = await isSupported()
      if (isFirebaseSupported) {
        getFirebaseToken()
        const messaging = getMessaging(firebaseApp)
        onMessage(messaging, payload => {
          setMessageData(payload.data)
          setShowNotification(true)
        })
      }
    }
  }
  initFirebase()

  const handleNotificationClick = () => {
    const { url, params } = getDeepLinkDestination(messageData.actionLink)
    if (url === location.pathname && params === location.state) {
      window.location.reload()
    } else {
      navigate(url, { state: params })
    }
    setShowNotification(false)
  }
  return (
    <Box
      width='100%'
      height='100%'
      position='fixed'
      sx={{
        backgroundColor: whiteBackground
          ? theme.palette.background.paper
          : 'default'
      }}>
      <Header>{headerTabs}</Header>
      <Box
        width='100%'
        height='100%'
        sx={{
          backgroundColor: whiteBackground
            ? theme.palette.background.paper
            : 'default',
          overflowY: 'auto',
          paddingTop: getSizeString(headerHeight)
        }}
        component='main'
        data-testid='all-page-content'>
        {pageHeader ? (
          <Box
            data-testid='dashboard-page-header'
            style={{ backgroundColor: theme.palette.background.paper }}>
            {pageHeader}
            {headerDivider ? <Divider /> : null}
          </Box>
        ) : null}
        {customizedBodyWidth ? (
          <>{children}</>
        ) : (
          <Container maxWidth='xl' sx={{ height: 'inherit' }}>
            <Stack
              height='inherit'
              spacing={10}
              width='100%'
              sx={{ paddingBottom: 0, paddingTop: 5 }}>
              {children}
            </Stack>
          </Container>
        )}
      </Box>
      {showNotification ? (
        <Snackbar
          open={showNotification}
          onClose={() => setShowNotification(false)}
          onClick={() => handleNotificationClick()}
          TransitionComponent={Fade}
          transitionDuration={1000}
          autoHideDuration={10000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert
            severity='info'
            onClose={() => setShowNotification(false)}
            variant='outlined'
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              '&:hover': { cursor: 'pointer' }
            }}>
            <Typography component='span' variant='subtitle1' color='primary'>
              {messageData.body}
            </Typography>
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default AuthorizedPage
