import { ArrowBack } from '@mui/icons-material'
import { Button } from '@mui/material'

const BackIconButton = props => {
  return (
    <Button {...props}>
      <ArrowBack />
    </Button>
  )
}

export default BackIconButton
