import { Button } from '@mui/material'
import { useState } from 'react'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import LinkCalendarDialog from './LinkCalendarDialog'
import { useTranslation } from 'react-i18next'

const LinkCalendarButton = ({ signinCallback, label, btnStyle = {} }) => {
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [connectFailed, setConnectFailed] = useState(undefined)

  const handleSigninResult = async success => {
    signinCallback(success)
    setConnectFailed(!success)
    setDialogOpen(false)
  }
  return (
    <>
      <Button style={{ ...btnStyle }} onClick={() => setDialogOpen(true)}>
        {label ? label : t('calenderEvents.linkMyCalendar')}
      </Button>
      {connectFailed ? <RequestRetryAlert /> : null}
      <LinkCalendarDialog
        handleSigninResult={handleSigninResult}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </>
  )
}

export default LinkCalendarButton
