import { Grid, Typography } from '@mui/material'
import QuestionRow from 'components/molecules/QuestionRow'

const FullAssessmentCategoryQuestions = ({
  category,
  questions,
  scores,
  setScore,
  options
}) => {
  const handleUpdate = (id, value) => {
    const updated = { ...scores, [id]: parseInt(value, 10) }
    setScore(updated)
  }
  return (
    <>
      <Grid item xs={12} paddingBottom={6}>
        <Typography variant='h3'>{category.name}</Typography>
      </Grid>
      {questions.map((q, idx) => (
        <QuestionRow
          key={`question-${q.id}`}
          questionText={q.selfQuestion}
          initialValue={scores[q.id]}
          onChange={v => handleUpdate(q.id, v)}
          order={idx + 1}
          options={options}
        />
      ))}
    </>
  )
}
export default FullAssessmentCategoryQuestions
