/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CardContent,
  MenuItem,
  MenuList,
  TableCell,
  TableRow,
  CircularProgress,
  Typography
} from '@mui/material'
import OverlayLoading from 'components/atoms/OverlayLoading'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText } from 'components/atoms/Text'
import PanelsWithScrollBars from 'components/atoms/container/PanelsWithScrollBars'
import DateCell from 'components/atoms/table/DateCell'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import RoundingCell from 'components/atoms/table/RoundingCell'
import React, { useEffect, useRef, useState } from 'react'
import {
  GOAL_STEP_QUIZ_TYPES,
  GOAL_STEP_QUIZ_URL,
  USER_QUIZ_RESULTS_URL
} from 'services/constants'
import { getRequest } from 'utils/api'
import { getGoals } from 'utils/getSupportingInfo'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import ShortButton from 'components/atoms/button/ShortButton'
import InCardStack from 'components/atoms/InCardStack'
import { updatePageState } from 'utils/windowHistoryHelper'

const SurveyQuizReportCard = ({ organizationId }) => {
  const [goals, setGoals] = useState(window.history.state?.goals || [])
  const [selectedQuiz, setSelectedQuiz] = useState(
    window.history.state?.selectedQuiz || null
  )
  const [requestError, setRequestError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [quizLoading, setQuizLoading] = useState(false)
  const [quizResultsData, setQuizResultsData] = useState(
    window.history.state?.quizResultsData || []
  )
  const [virtueHeaders, setVirtueHeaders] = useState([])
  const [initialized, setInitialized] = useState(false)
  const tableRef = useRef(null)
  const [currentTableRef, setCurrentTableRef] = useState(undefined)

  useEffect(() => {
    const fetchGoal = async () => {
      const resGoals = await getGoals()
      setGoals([...resGoals?.filter(g => g.available)])
    }
    if (!goals.length) {
      fetchGoal()
    }
    if (initialized) {
      setSelectedQuiz(null)
      setQuizResultsData([])
    }
  }, [organizationId])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(USER_QUIZ_RESULTS_URL, {
        organizationId,
        goalStepId: selectedQuiz.goalStepId
      })
      if (res.status === 200) {
        setRequestError(false)
        const resData = res.data.data

        const headers = Object.keys(resData[0].score)
        setVirtueHeaders(headers)
        setQuizResultsData(resData)
      } else if (res.status === 204) {
        setRequestError(false)
        setQuizResultsData([])
      } else {
        setRequestError(true)
        setQuizResultsData([])
      }

      if (!initialized) {
        setInitialized(true)
      }
      setLoading(false)
    }
    if (selectedQuiz) {
      setLoading(true)
      fetchData()
    }
  }, [selectedQuiz])

  useEffect(() => {
    updatePageState({
      quizResultsData,
      goals,
      selectedQuiz
    })
  }, [quizResultsData, goals, selectedQuiz])

  const handleGoalClick = async goal => {
    setQuizLoading(goal.id)
    const newGoals = [...goals]
    const g = newGoals.find(g => g.id === goal.id)
    if (!goal.quizzes) {
      const quizzesRes = await getRequest(GOAL_STEP_QUIZ_URL, {
        goalId: goal.id
      })
      if (quizzesRes.status === 200) {
        g.quizzes = quizzesRes.data.data.filter(
          q => q.type === GOAL_STEP_QUIZ_TYPES.SURVEY
        )
      } else {
        g.quizzes = []
      }
    }
    g.expanded = !goal.expanded
    setGoals(newGoals)
    setQuizLoading(false)
  }

  useEffect(() => {
    setCurrentTableRef(tableRef.current)
  }, [quizResultsData])

  return (
    <Box width='100%'>
      <PanelsWithScrollBars
        leftList={
          <MenuList>
            {goals.map(g => [
              <MenuItem
                onClick={() => handleGoalClick(g)}
                key={g.id}
                width='100%'
                style={{ whiteSpace: 'normal', paddingLeft: 0 }}>
                <Typography>{g.leadershipPathName}</Typography>
              </MenuItem>,

              g.id === quizLoading ? (
                <MenuItem>
                  <CircularProgress size={20} />
                </MenuItem>
              ) : null,
              g.expanded ? (
                g.quizzes?.length > 0 ? (
                  g.quizzes.map(q => (
                    <MenuItem
                      key={`survey-quiz-${q.id}`}
                      onClick={() => setSelectedQuiz(q)}
                      selected={q.id === selectedQuiz?.id}>
                      {q.title || q.goal_step.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <Typography variant='text'>
                      No survey quiz for this goal
                    </Typography>
                  </MenuItem>
                )
              ) : null
            ])}
          </MenuList>
        }>
        <OverlayLoading open={loading} />
        {!selectedQuiz ? (
          <CardContent>
            <NotificationBodyText>
              Expand a Path to See Available Quizzes and Select to View Data.
            </NotificationBodyText>
          </CardContent>
        ) : quizResultsData.length ? (
          <>
            <InCardStack direction='row'>
              {currentTableRef && (
                <DownloadTableExcel
                  filename={`Org-${organizationId}-${
                    selectedQuiz.title || selectedQuiz.goal_step.name
                  }-survey-quiz-report`}
                  sheet='sheet1'
                  currentTableRef={currentTableRef}>
                  <ShortButton>Export</ShortButton>
                </DownloadTableExcel>
              )}
            </InCardStack>
            <PresetTableContainer
              maxHeight='800px'
              tableProps={{ ref: tableRef }}
              headers={
                <>
                  <TableCell>User Id</TableCell>
                  <TableCell>Cohorts</TableCell>
                  <TableCell sx={{ minWidth: 120 }}>Survey Date</TableCell>
                  {virtueHeaders.map(v => (
                    <TableCell key={`header-${v}`}>{v}</TableCell>
                  ))}
                </>
              }>
              {quizResultsData.map(quizResult => (
                <TableRow
                  key={`user-quiz-result-${
                    quizResult.userId || quizResult.name
                  }`}>
                  <TableCell>{quizResult.userId || quizResult.name}</TableCell>
                  <TableCell>
                    {quizResult.cohorts?.map(c => (
                      <Typography
                        key={`user-cohort-${quizResult.userId}-${c.id}`}>
                        {c.name}{' '}
                      </Typography>
                    ))}
                  </TableCell>
                  <DateCell value={quizResult.createdAt} />
                  {virtueHeaders.map(v => (
                    <RoundingCell
                      value={quizResult.score[v]}
                      key={`quiz-result-${quizResult.userId}-${v}`}
                    />
                  ))}
                </TableRow>
              ))}
            </PresetTableContainer>
          </>
        ) : (
          <CardContent>
            <NotificationBodyText>
              No quiz result data found!
            </NotificationBodyText>
          </CardContent>
        )}
        {requestError ? (
          <CardContent>
            <RequestRetryAlert />
          </CardContent>
        ) : null}
      </PanelsWithScrollBars>
    </Box>
  )
}

export default SurveyQuizReportCard
