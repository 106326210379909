import { useNavigate } from 'react-router-dom'
import { ActionText } from 'components/atoms/Text'
import { ADD_ASSESSMENT_RATERS } from 'routes/constants'
import { Add } from '@mui/icons-material'
import { useState } from 'react'
import NoOpenAssessmentDialog from '../notificationOverlay/NoOpenAssessmentDialog'
import { useTranslation } from 'react-i18next'

const RadarGraphAddMoreRaters = ({ assessmentData }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const goalId = assessmentData.goalData.id
  const isOpen = assessmentData.isOpen
  const goalName = assessmentData.goalData.name
  const showLabel = assessmentData.goalData.enableOthersAssessment
  const [openNoOpenAssessmentNote, setOpenNoOpenAssessmentNote] =
    useState(false)
  const handleClick = () => {
    if (isOpen) {
      navigate(`${ADD_ASSESSMENT_RATERS}/${goalId}`, {
        state: { assessmentData }
      })
    } else {
      setOpenNoOpenAssessmentNote(true)
    }
  }
  const handleDiaglogClose = () => {
    setOpenNoOpenAssessmentNote(false)
  }
  return (
    <>
      {showLabel ? (
        <>
          <ActionText
            display='flex'
            justifyContent='center'
            onClick={() => handleClick()}>
            <Add />
            &nbsp;&nbsp;{t('raters.addMore360Raters')}
          </ActionText>
          <NoOpenAssessmentDialog
            open={openNoOpenAssessmentNote}
            handleDialogClose={handleDiaglogClose}
            goalId={goalId}
            goalName={goalName}
          />
        </>
      ) : null}
    </>
  )
}
export default RadarGraphAddMoreRaters
