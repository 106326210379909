import * as yup from 'yup'
import { Alert, Button, MenuItem, TableCell } from '@mui/material'
import FormInputBox from 'components/atoms/input/FormInputBox'
import FormSelectInput from 'components/atoms/input/FormSelectInput'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { ORGANIZATIONS_URL, LICENSE_TYPES } from 'services/constants'
import { patchRequest } from 'utils/api'
import { validationsRules } from 'utils/formValidations'
import moment from 'moment'

const SubscriptionRow = ({ organization, saveCallback, organizationId }) => {
  const [requestSuccess, setRequestSuccess] = useState(null)

  const formik = useFormik({
    initialValues: {
      licenseCount: organization?.licenseCount || 0,
      type: organization?.licenseType || '',
      expireAt: moment
        .utc(organization?.licenseExpireAt || undefined)
        .format('yyyy-MM-DD')
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      licenseCount: yup.number('Number only').required('Required'),
      type: validationsRules.textRequired,
      expireAt: yup.date().required()
    }),
    onSubmit: async values => {
      const requestBody = {
        licenseCount: values.licenseCount,
        licenseType: values.type,
        licenseExpireAt: values.expireAt
      }

      const res = await patchRequest(
        `${ORGANIZATIONS_URL}/${organization.id}`,
        requestBody
      )
      if (res.status === 200) {
        setRequestSuccess(true)
        saveCallback()
      } else {
        setRequestSuccess(false)
      }
    }
  })

  useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  useEffect(() => {
    setRequestSuccess(null)
  }, [organizationId])
  return (
    <>
      <TableCell>
        <FormSelectInput
          disabled={organization?.licenseType === LICENSE_TYPES.AUTOMATIED}
          type='text'
          name='type'
          formik={formik}
          InputProps={{ sx: { minWidth: '150px' } }}
          attributeName='type'>
          <MenuItem key={0} value={LICENSE_TYPES.INVOICE}>
            Invoice
          </MenuItem>
          <MenuItem key={1} value={LICENSE_TYPES.TRIAL}>
            Trial
          </MenuItem>
        </FormSelectInput>
      </TableCell>
      <TableCell>
        <FormInputBox
          type='text'
          formik={formik}
          InputProps={{ sx: { width: '100px', minWidth: 0 } }}
          attributeName='licenseCount'
        />
      </TableCell>
      <TableCell>
        <FormInputBox
          type='date'
          formik={formik}
          attributeName='expireAt'
          InputProps={{ sx: { minWidth: '200px' } }}
        />
      </TableCell>
      <TableCell>
        <Button title='Save' onClick={() => formik.handleSubmit()}>
          {organization?.id ? 'Save' : 'Add'}
        </Button>
      </TableCell>
      <TableCell width='100px'>
        {requestSuccess === false ? (
          <Alert severity='error' />
        ) : requestSuccess === true ? (
          <Alert severity='success' />
        ) : null}
      </TableCell>
    </>
  )
}

export default SubscriptionRow
