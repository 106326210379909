//Routing constants
export const DEEP_LINK_ACTION = '/assets/deepLinkAction.html'
export const ROOT = '/'
export const INTERNAL = '/internal'
export const SURVEY = '/survey'

export const HOME = '/home'
export const SIGNUP_DIRECT = '/signup-direct'
export const LOGIN = '/login'
export const POST_GOAL_QUIZ = '/post-goal-quiz'

export const USER_PROFILE = '/user-profile'
export const EDIT_PROFILE = '/edit-profile'
export const USER_SETTINGS = '/user-settings'
export const UNAUTHORIZED = '/forbidden'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
export const UNSUBSCRIBE = '/unsubscribe'
export const VERIFY_EMAIL = '/email-verification'
export const SEND_EMAIL_VERIFICATION = '/send-email-verification'
export const HELP_AND_SUPPORT = '/help-and-support'

export const NOTEBOOK = '/notebook'
export const BOOKMARKS = '/bookmarks'
export const SEARCH_RESULTS = '/search-results'
export const ASSESSMENTS = '/assessments'
export const ASSESSMENT_SELF = '/assessment-self'
export const ASSESSMENT_OTHERS = '/assessment-others'
export const ASSESSMENT_BEHAVIOUR = '/assessment-behaviour'
export const ASSESSMENT_BEHAVIOUR_COMPARISON =
  '/assessment-behaviour-comparison'
export const ADD_ASSESSMENT_RATERS = '/assessment-add-raters'
export const ASSESSMENT_REPORT_SHARE = '/assessment-report-share'
export const FULL_ASSESSMENT = '/full-assessment'

export const PULSE_SURVEYS = '/pulse-surveys'
export const PULSE_SURVEY_NEW = '/pulse-survey/new'
export const PULSE_SURVEY_DETAIL = '/pulse-survey'
export const PULSE_SURVEY_RATE = '/pulse-survey/rate'

export const USER_FEEBACK = '/feedbacks'
export const TEAM_SHOUTOUTS = '/team-shoutouts'
export const USER_CALENDAR_EVENTS = '/user-calendar-events'
export const MY_TEAM = '/user-connections'
export const MY_TEAM_IMPORT = '/user-connections/import'
export const MY_CONTACT = '/user-connection'
export const MY_MEETING = '/user-meeting'
export const CHECK_IN_SURVEY = '/check-in-survey'

export const ROLE_PLAY_PRACTISES = '/practices'
export const ROLE_PLAY = '/role-play'
export const ALL_SCENARIOS = '/all-scenarios'

export const JOURNEY = '/journey'
export const JOURNEY_DEMO = '/journey-demo'
export const JOURNEY_NEW = '/journey-new'
export const LEADERSHIP_MODEL = '/leadership-model'
export const ONBOARDING = '/onboarding'

export const ABOUT_CONFIDENTIALITY = '/about-confidentiality'
export const ABOUT_360 = '/about-360'

export const OVERVIEW = '/overview'
export const OVERVIEW_INSIGHTS = '/overview-insights'
export const COMPANY_GENERAL = '/company'
export const COMPANY_USERS = '/company-users'

export const CONTENT_EDITOR = '/content-editor'
export const SBUSCRIPTION_ADMIN = '/subscription-admin'
export const OHEP_SURVEYS = '/ohep-surveys'
export const GOALS = '/demo-goals'
export const CHECK_QUIZZES_RESULT_EXPORT = '/check-quizzes-result-export'
export const USER_PROGRESS_REPORT = '/user-progress-report'
export const FEATURES_ADMIN = '/features-admin'
export const DEMO_ACCOUNTS = '/demo-accounts'

export const MOBILE_BASE_URL = 'monarkapp://'
export const PERMISSION_LEVEL = {
  PUBLIC: 0,
  SIGNEDIN: 1,
  LICENSED: 2,
  ADMINISTRATOR: 3,
  COACH: 4,
  SUPER_ADMIN: 5
}
