import { Card, CardContent, Stack, Typography } from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import { TaskSuccessIcon } from 'components/atoms/Icons'
import InCardStack from 'components/atoms/InCardStack'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HOME, JOURNEY_DEMO, PULSE_SURVEY_NEW } from 'routes/constants'
import { formatDate } from 'utils/formatDateTime'
import { getGoalNameById } from 'utils/getSupportingInfo'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'

const CompletionCard = ({ goalId, showReAssessmentInfo, pathOpen }) => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const isDemo = location.pathname === JOURNEY_DEMO
  const [pathName, setPathName] = useState('')
  const user = getLocalUser()
  useEffect(() => {
    const fetchData = async () => {
      const goalName = await getGoalNameById(goalId)
      setPathName(goalName)
    }
    if (goalId) {
      fetchData()
    }
  }, [goalId])

  let resendingDays = user.organization?.resend360AfterDays || 60
  let reminderDate = moment.utc().add(resendingDays, 'days')
  let cohortUserShowAssessment = true
  // If cohort user use cohort info
  if (user.isCohortUser) {
    // Only active cohort session in cohorts data
    const matchedCohortWithSession = user.cohorts?.find(c => {
      const session = c.cohortSessions?.find(s => s.goalId === goalId)
      if (session) return true
      return false
    })
    if (matchedCohortWithSession) {
      const cohortSession = matchedCohortWithSession.cohortSessions[0]
      reminderDate = moment
        .utc(cohortSession.endDate)
        .add(cohortSession.resend360AfterDays, 'days')
      resendingDays = cohortSession.resend360AfterDays
    } else {
      cohortUserShowAssessment = false
    }
  }

  const textProps = {
    textAlign: 'center',
    variant: 'body1'
  }

  return (
    <Card>
      <CardContent sx={{ paddingTop: 30 }}>
        <InCardStack>
          <TaskSuccessIcon />
          <Typography variant='h3' fontSize={pathOpen ? '1.25rem !important' : 'calc(1.25rem + 2px) !important'}>
            {t('pathsAndModels.congratulations')}!
          </Typography>
        </InCardStack>
      </CardContent>
      <CardContent>
        {showReAssessmentInfo && cohortUserShowAssessment ? (
          <Stack alignItems='center' spacing={10} maxWidth='700px'>
            <Typography {...textProps}>
              {t('pathsAndModels.youJustCompleted')} {pathName}!
            </Typography>
            <Typography {...textProps}>
              {t('pathsAndModels.senseOfNewSkills.part1')}
              <strong>
                {resendingDays} {t('pathsAndModels.senseOfNewSkills.part2')}
              </strong>
              {t('pathsAndModels.senseOfNewSkills.part3')}
            </Typography>
            <Typography {...textProps}>
              {t('pathsAndModels.makeItEasy')}
            </Typography>
            <Typography>
              {t('pathsAndModels.reassessmentReminder')}
              <strong>{formatDate(reminderDate, i18n.resolvedLanguage)}</strong>
              .
            </Typography>
            <LinkButton style={{ minWidth: '20%' }} to={HOME}>
              {t('common.ok')}
            </LinkButton>
          </Stack>
        ) : (
          <Stack alignItems='center' spacing={10} maxWidth='700px'>
            <Typography {...textProps}>
              {isDemo
                ? t('pathsAndModels.completedDemoPath')
                : `${t('pathsAndModels.alreadyCompleted')} ${pathName}!`}
            </Typography>
            <Typography {...textProps} paddingBottom={10}>
              {t('pathsAndModels.reflectOnWhatYouLearned')}
            </Typography>
            <LinkButton style={{ minWidth: '50%' }} to={PULSE_SURVEY_NEW}>
              {t('pathsAndModels.sendOutSurvey')}
            </LinkButton>
          </Stack>
        )}
      </CardContent>
    </Card>
  )
}

export default CompletionCard
