import { OVERVIEW } from 'routes/constants'
import AdminPage from 'components/templates/navigationPageTemplates/AdminPage'
import { isAllowedFeature, permitted } from 'utils/permissionCheck'
import { PERMISSION_LEVEL } from 'routes/constants'
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import UsersStatsList from 'components/organisms/adminDashboard/UsersStatsList'
import Insights from 'components/organisms/adminDashboard/Insights'
import { useState } from 'react'
import theme from 'styles/theme'
import PageHeaderInfoTab from 'components/atoms/PageHeaderInfoTab'
import InCardStack from 'components/atoms/InCardStack'
import { useTranslation } from 'react-i18next'
import { FEATURE_KEYS } from 'services/constants'

const Overview = () => {
  const { t } = useTranslation()
  const [activeUserCount, setActiveUserCount] = useState(0)
  const [atRiskUserCount, setAtRiskeUserCount] = useState(0)
  const [invitedUserCount, setInvitedUserCount] = useState(0)
  const [cohortFilter, setCohortFilter] = useState('')
  const [cohortList, setCohortList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [companyFilter, setCompanyFilter] = useState('')
  const [disableCompany, setDisableCompany] = useState(false)
  const [disableCohort, setDisableCohort] = useState(false)

  const OVERVIEW_TABS = {
    USERS: {
      value: 0,
      label: 'users',
      showTab: true
    },
    INSIGHTS: {
      value: 1,
      label: 'insights',
      showTab: isAllowedFeature(FEATURE_KEYS.COMPANY_INSIGHTS)
    }
  }

  const [activeTab, setActiveTab] = useState(OVERVIEW_TABS.USERS)

  const isAdmin = permitted(PERMISSION_LEVEL.SUPER_ADMIN)
  const isManager = permitted(PERMISSION_LEVEL.ADMINISTRATOR)
  const isCoach = permitted(PERMISSION_LEVEL.COACH)

  const handleUserStatsCount = (atRisk, active) => {
    setAtRiskeUserCount(atRisk)
    setActiveUserCount(active)
  }

  const handleInvitedUserCount = invited => {
    setInvitedUserCount(invited)
  }

  const handleGroupsLists = (orgList, cList) => {
    setCompanyList(orgList)
    setCohortList(cList)
  }

  const resetFilters = () => {
    setCohortFilter('')
    setCompanyFilter('')
    setDisableCohort(false)
    setDisableCompany(false)
  }

  const filterSX = {
    '& .MuiSelect-select': {
      padding: '12px'
    },
    '& .MuiInputBase-root': { minWidth: '200px' }
  }
  const selectProps = (attr, lable) => ({
    displayEmpty: true,
    renderValue:
      attr === ''
        ? () => <div style={{ opacity: 0.4 }}>{lable}</div>
        : undefined
  })
  const getMenuList = items =>
    items.map(c => (
      <MenuItem value={c.id} key={c.id}>
        {c.name || c.domain}
      </MenuItem>
    ))

  const updateCompany = e => {
    if (isAdmin && companyFilter === '' && cohortFilter !== '') {
      setDisableCohort(true)
    }
    setCompanyFilter(e.target.value)
  }

  const updateCohort = e => {
    if (isAdmin && cohortFilter === '' && companyFilter !== '') {
      setDisableCompany(true)
    }
    setCohortFilter(e.target.value)
  }

  const handleTabChange = (event, newValue) => {
    const dest = Object.entries(OVERVIEW_TABS).find(
      ([, t]) => t.value === newValue
    )
    setActiveTab(dest[1])
  }

  return (
    <AdminPage activePage={OVERVIEW} customizedBodyWidth={true}>
      <Box
        data-testid='dashboard-page-header'
        style={{ backgroundColor: theme.palette.background.paper }}>
        <Container maxWidth='xl'>
          <Grid container alignItems='end'>
            <Grid item>
              <Stack padding={0} spacing={5}>
                {isCoach ? (
                  <>
                    <Stack
                      padding={0}
                      paddingLeft={6}
                      paddingTop='6px'
                      spacing={3}>
                      <Typography fontSize='14px' fontWeight='bold'>
                        {t('overview.filters')}
                      </Typography>
                      <InCardStack direction='row'>
                        {(isCoach && !isManager) || isAdmin ? (
                          <TextField
                            select
                            id='company-select'
                            value={companyFilter}
                            onChange={e => updateCompany(e)}
                            SelectProps={selectProps(
                              companyFilter,
                              t('headers.company')
                            )}
                            disabled={disableCompany}
                            sx={filterSX}>
                            {getMenuList(companyList)}
                          </TextField>
                        ) : null}
                        <TextField
                          select
                          id='cohort-select'
                          value={cohortFilter}
                          onChange={e => updateCohort(e)}
                          SelectProps={selectProps(
                            cohortFilter,
                            t('overview.cohort')
                          )}
                          disabled={disableCohort || cohortList.length === 0}
                          sx={filterSX}>
                          {getMenuList(cohortList)}
                        </TextField>
                        <Button
                          sx={{ paddingY: '9px' }}
                          onClick={() => resetFilters()}
                          disabled={!cohortFilter && !companyFilter}>
                          {t('overview.resetFilters')}
                        </Button>
                      </InCardStack>
                    </Stack>
                  </>
                ) : null}
                <Tabs
                  value={activeTab.value}
                  textColor='inherit'
                  onChange={handleTabChange}>
                  {Object.entries(OVERVIEW_TABS).map(([, tab]) => {
                    if (tab.showTab) {
                      return (
                        <Tab
                          sx={{ fontWeight: 600 }}
                          value={tab.value}
                          label={t(`overview.${tab.label}`)}
                          key={tab.label}
                        />
                      )
                    }
                    return null
                  })}
                </Tabs>
              </Stack>
            </Grid>
            <Grid item justifyContent='right' flex={1} alignSelf='flex-start'>
              {activeTab.value === 0 ? (
                <Stack direction='row' justifyContent='right' padding={0}>
                  <PageHeaderInfoTab
                    title={t('overview.active')}
                    value={activeUserCount}
                    dividercolor={theme.palette.primary.main}
                  />
                  <PageHeaderInfoTab
                    title={t('overview.invited')}
                    value={invitedUserCount}
                    dividercolor={theme.palette.secondary.main}
                  />
                  <PageHeaderInfoTab
                    title={t('overview.atRisk')}
                    value={atRiskUserCount}
                    dividercolor={theme.palette.error.light}
                  />
                </Stack>
              ) : null}
            </Grid>
          </Grid>
        </Container>
        <Divider />
      </Box>
      <Container
        maxWidth='xl'
        sx={{
          height: 'inherit',
          display: activeTab.value === 0 ? 'block' : 'none'
        }}>
        <UsersStatsList
          handleUserStatsCount={handleUserStatsCount}
          handleGroupsLists={handleGroupsLists}
          handleInvitedUserCount={handleInvitedUserCount}
          cohortFilter={cohortFilter}
          companyFilter={companyFilter}
        />
      </Container>
      <Container
        maxWidth='xl'
        sx={{
          height: 'inherit',
          display: activeTab.value === 1 ? 'block' : 'none'
        }}>
        <Insights
          cohortFilter={cohortFilter}
          companyFilter={companyFilter}
          isSuperAdmin={isAdmin}
        />
      </Container>
    </AdminPage>
  )
}

export default Overview
