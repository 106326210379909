import { Box, Card, Stack } from '@mui/material'
import React from 'react'
import { ReactComponent as MonarkLogoSvg } from 'assets/images/monark_logo.svg'

const FormCard = ({
  children,
  showLogo = true,
  cardMinWidth = '450px',
  ...otherProps
}) => {
  return (
    <Stack {...otherProps}>
      {showLogo &&
        <Box display='flex' justifyContent='center'>
          <MonarkLogoSvg />
        </Box>
      }

      <Box paddingTop='40px' display='flex' justifyContent='center'>
        <Card sx={{ padding: '24px 24px 58px 24px', minWidth: cardMinWidth }}>
          {children}
        </Card>
      </Box>
    </Stack>
  )
}

export default FormCard
