import { Icon, Stack, Typography } from '@mui/material'
import { TaskSuccessIcon } from 'components/atoms/Icons'
import ConfirmCancelDialog from './ConfirmCancelDialog'
import { useTranslation } from 'react-i18next'

const ConfidentialityPopUp = ({ open, onClose }) => {
  const { t } = useTranslation()
  return (
    <ConfirmCancelDialog
      titleText={t('confidentiality.heading')}
      notificationText={
        <Stack padding='12px' spacing={5}>
          <Typography variant='body2' align='left' lineHeight={2}>
              {t('confidentiality.part1')}
            </Typography>
            <Typography variant='body2' align='left' lineHeight={2}>
              {t('confidentiality.part2')}
            </Typography>
            <Typography variant='body2' align='left' lineHeight={2}>
              {t('confidentiality.part3')}
            </Typography>
            <Typography variant='body2' align='left' lineHeight={2}>
              {t('confidentiality.part4')}
            </Typography>
        </Stack>
      }
      onClose={onClose}
      open={open}
      confirmText={
        <>
          <Icon sx={{ marginRight: 8 }}>
            <TaskSuccessIcon size={25} />
          </Icon>{' '}
          {t('confidentiality.gotIt')}
        </>
      }
    />
  )
}

export default ConfidentialityPopUp
