import { Link, Popover, TableCell } from '@mui/material'
import { useState } from 'react'

const PopoverCell = ({ cellValue, PopoverContent, id, cellAlign='center' }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <TableCell>
        <Link
          component='button'
          sx={{ textDecoration: 'none' }}
          align={cellAlign}
          aria-owns={open ? id : undefined}
          aria-haspopup='true'
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
          {cellValue}
        </Link>
        <Popover
          data-testid={id}
          id={id}
          sx={{
            pointerEvents: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <PopoverContent />
        </Popover>
      </TableCell>
    </>
  )
}

export default PopoverCell
