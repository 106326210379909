/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CardContent,
  MenuItem,
  MenuList,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import OverlayLoading from 'components/atoms/OverlayLoading'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText } from 'components/atoms/Text'
import SmallButton from 'components/atoms/button/SmallButton'
import PanelsWithScrollBars from 'components/atoms/container/PanelsWithScrollBars'
import DateCell from 'components/atoms/table/DateCell'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import RoundingCell from 'components/atoms/table/RoundingCell'
import React, { useEffect, useRef, useState } from 'react'
import { ASSESSMENT_REPORT_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { getGoals } from 'utils/getSupportingInfo'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import ShortButton from 'components/atoms/button/ShortButton'
import moment from 'moment'
import InCardStack from 'components/atoms/InCardStack'
import { updatePageState } from 'utils/windowHistoryHelper'
import AssessmentChangeDialog from 'components/molecules/notificationOverlay/AssessmentChangeDialog'

const partitionOldNew = data => {
  const olderData = []
  const newerData = []

  data
    .map(o => o.assessments.toSorted(
      (a, b) => moment(b.ratingTimeStamp).diff(moment(a.ratingTimeStamp))
    ))
    .filter(x => x.length > 1
      && x[0].selfAllBehaviourAvg > 0
      && x[1].selfAllBehaviourAvg > 0
    )
    .forEach(x => {
      olderData.push(x[1])
      newerData.push(x[0])
    })

  return { olderData, newerData }
}

const getRatingTimeStamp = (objs, isLatest) => {
  return objs.length === 0
    ? undefined
    : objs
      .map(x => x.ratingTimeStamp)
      .reduce((a, x) => {
        const chooseNew = isLatest
          ? moment(x).isAfter(a) : moment(x).isBefore(a)
        return chooseNew ? x : a
      })
}

const getAverages = (behaviors, data, radarChartField) => {
  const averages = behaviors.map(b => {
    const scores = data
      .map(s => s.radarChartData?.[radarChartField]?.[b])
      .filter(s => s)
    const sum = scores.reduce((a, s) => a + s, 0)
    const count = scores.length

    return [b, count > 0 ? sum / count : 0]
  })

  return Object.fromEntries(averages)
}

const sumValues = obj => Object.values(obj).reduce((a, x) => a + x, 0)

const processData = resultData => {
  if (resultData?.[0]?.assessments?.[0]?.radarChartData?.ref) {
    const behaviors = Object.keys(
      resultData[0].assessments[0].radarChartData.ref
    )

    // Compile for company average
    const { olderData, newerData } = partitionOldNew(resultData)

    let comparisonCounts
    if (olderData.length > 0) {
      const olderAssessment = { ...olderData[0] }
      olderAssessment.ratingTimeStamp = getRatingTimeStamp(olderData, false)

      const oldOthersCount = olderData
        .map(x => Object.keys(x.radarChartData.others).length > 0 ? 1 : 0)
        .reduce((a, s) => a + s, 0)
      const oldOthersScores = getAverages(behaviors, olderData, 'others')
      const oldSelfScores = getAverages(behaviors, olderData, 'self')

      const newerAssessment = { ...newerData[0] }
      newerAssessment.ratingTimeStamp = getRatingTimeStamp(newerData, true)

      const newOthersCount = newerData
        .map(x => Object.keys(x.radarChartData.others).length > 0 ? 1 : 0)
        .reduce((a, s) => a + s, 0)
      const newOthersScores = getAverages(behaviors, newerData, 'others')
      const newSelfScores = getAverages(behaviors, newerData, 'self')

      // calculate average and compile behaviourInfoData for change table
      const selfDataLength = olderData.length
      const oldAverages = {
        self: oldSelfScores,
        others: oldOthersScores,
        ref: olderAssessment.radarChartData.ref,
        selfTotal: sumValues(oldSelfScores),
        othersTotal: sumValues(oldOthersScores)
      }
      const oldBehaviorInfo = behaviors.map(b => ({
        name: b,
        avgSelfRating: oldSelfScores[b],
        avgOthersRating: oldOthersScores[b]
      }))

      const newAverages = {
        self: newSelfScores,
        others: newOthersScores,
        ref: olderAssessment.radarChartData.ref,
        selfTotal: sumValues(newSelfScores),
        othersTotal: sumValues(newOthersScores)
      }
      const newBehaviorInfo = behaviors.map(b => ({
        name: b,
        avgSelfRating: newSelfScores[b],
        avgOthersRating: newOthersScores[b]
      }))

      olderAssessment.behaviourInfoData = oldBehaviorInfo
      olderAssessment.radarChartData = oldAverages
      olderAssessment.selfAllBehaviourAvg =
        oldAverages.selfTotal / behaviors.length
      olderAssessment.othersAllBehaviourAvg =
        oldAverages.othersTotal > 0
          ? oldAverages.othersTotal / behaviors.length
          : undefined
      newerAssessment.behaviourInfoData = newBehaviorInfo
      newerAssessment.radarChartData = newAverages
      newerAssessment.selfAllBehaviourAvg =
        newAverages.selfTotal / behaviors.length
      newerAssessment.othersAllBehaviourAvg =
        newAverages.othersTotal > 0
          ? newAverages.othersTotal / behaviors.length
          : undefined

      const companyAssessmentData = {
        userId: 'Total Average',
        assessments: [newerAssessment, olderAssessment]
      }
      resultData.push(companyAssessmentData)
      comparisonCounts = {
        self: selfDataLength,
        others: { old: oldOthersCount, new: newOthersCount }
      }
    }
    return { resultData, comparisonCounts, behaviors }
  }
  return { resultData: [] }
}

const AssessmentReportCard = ({ organizationId }) => {
  const [goals, setGoals] = useState([])
  const [selectedGoal, setSelectedGoal] = useState(
    window.history.state?.selectedGoal || null
  )
  const [assessmentsData, setAssessmentsData] = useState([])
  const [requestError, setRequestError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [changeData, setChangeData] = useState(
    window.history.state?.changeData || null
  )
  const [behaviorHeaders, setBehaviorHeaders] = useState([])
  const [companyComparisonCounts, setCompanyComparisonCounts] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const tableRef = useRef(null)
  const [currentTableRef, setCurrentTableRef] = useState(undefined)
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    const fetchGoal = async () => {
      const resGoals = await getGoals()
      setGoals(resGoals?.filter(g => g.available))
    }
    if (!goals.length) {
      fetchGoal()
    }
    if (initialized) {
      setSelectedGoal(null)
    }
    setAssessmentsData([])
  }, [organizationId])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(ASSESSMENT_REPORT_URL, {
        goalId: selectedGoal.id,
        organizationId
      })
      if (res.status === 200) {
        const { resultData, comparisonCounts, behaviors } = processData(
          res.data.data
        )
        setBehaviorHeaders(behaviors)
        setCompanyComparisonCounts(comparisonCounts)
        setAssessmentsData(resultData)
        setRequestError(false)
      } else {
        setRequestError(true)
      }
      if (!initialized) {
        setInitialized(true)
      } else {
        setChangeData(null)
      }

      setLoading(false)
    }
    if (selectedGoal) {
      setLoading(true)
      fetchData()
    }
  }, [selectedGoal])

  const handleChangeView = (userId, data) => {
    setChangeData({ userId, data })
    setDialogOpen(true)
  }

  useEffect(() => {
    updatePageState({
      changeData,
      selectedGoal
    })
  }, [changeData, selectedGoal])

  useEffect(() => {
    setCurrentTableRef(tableRef.current)
  }, [assessmentsData])

  return (
    <Box width='100%'>
      <PanelsWithScrollBars
        leftList={
          <>
            <MenuList>
              {goals.map(g => (
                <MenuItem
                  onClick={() => setSelectedGoal(g)}
                  key={g.id}
                  width='100%'
                  style={{ whiteSpace: 'normal', paddingLeft: 0 }}>
                  <Typography
                    color={g.id === selectedGoal?.id ? 'primary' : 'default'}
                    paddingBottom='10px'>
                    {g.leadershipPathName}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
            {changeData ? (
              <AssessmentChangeDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                changeData={changeData}
              />
            ) : null}
          </>
        }>
        <OverlayLoading open={loading} />
        {!selectedGoal ? (
          <CardContent>
            <NotificationBodyText>
              Select a Path to See Report.
            </NotificationBodyText>
          </CardContent>
        ) : assessmentsData.length ? (
          <>
            <InCardStack direction='row'>
              {currentTableRef && (
                <DownloadTableExcel
                  filename={`Org-${organizationId}-${selectedGoal?.name}-assessment-report`}
                  sheet='sheet1'
                  currentTableRef={currentTableRef}>
                  <ShortButton>Export</ShortButton>
                </DownloadTableExcel>
              )}

              {companyComparisonCounts ? (
                <Typography color='primary'>
                  Company total comparison counts: total{' '}
                  {companyComparisonCounts.self}, others{' '}
                  {companyComparisonCounts.others.old}/
                  {companyComparisonCounts.others.new} (old/new)
                </Typography>
              ) : null}
            </InCardStack>
            <PresetTableContainer
              maxHeight='800px'
              tableProps={{ ref: tableRef }}
              headers={
                <>
                  <TableCell>User Id</TableCell>
                  <TableCell sx={{ minWidth: 120 }} />
                  <TableCell sx={{ minWidth: 120 }}>Assessment Date</TableCell>
                  <TableCell>Self Score</TableCell>
                  {behaviorHeaders.map(b => (
                    <TableCell key={`header-self-${b}`}>{b}-Self</TableCell>
                  ))}
                  <TableCell>Others Score</TableCell>
                  {behaviorHeaders.map(b => (
                    <TableCell key={`header-others-${b}`}>{b}-Other</TableCell>
                  ))}
                </>
              }>
              {assessmentsData.map(userData =>
                userData.assessments.map((assessment, i) => (
                  <TableRow
                    key={`user-assessment-${userData.userId}-${assessment.id}`}>
                    <TableCell>{userData.userId}</TableCell>
                    <TableCell>
                      {i < userData.assessments.length - 1 ? (
                        <SmallButton
                          onClick={() =>
                            handleChangeView(userData.userId, [
                              assessment,
                              userData.assessments[i + 1]
                            ])
                          }>
                          View Change
                        </SmallButton>
                      ) : null}
                    </TableCell>
                    <DateCell value={assessment.ratingTimeStamp} />
                    <RoundingCell value={assessment.selfAllBehaviourAvg} />
                    {behaviorHeaders.map(b => (
                      <RoundingCell
                        value={assessment.radarChartData.self[b]}
                        key={`assessment-${userData.userId}-${assessment.id}-self-${b}`}
                      />
                    ))}
                    <RoundingCell value={assessment.othersAllBehaviourAvg} />
                    {behaviorHeaders.map(b => (
                      <RoundingCell
                        value={assessment.radarChartData.others[b]}
                        key={`assessment-${userData.userId}-${assessment.id}-others-${b}`}
                      />
                    ))}
                  </TableRow>
                ))
              )}
            </PresetTableContainer>
          </>
        ) : (
          <CardContent>
            <NotificationBodyText>
              No assessment data found!
            </NotificationBodyText>
          </CardContent>
        )}
        {requestError ? (
          <CardContent>
            <RequestRetryAlert />
          </CardContent>
        ) : null}
      </PanelsWithScrollBars>
    </Box>
  )
}

export default AssessmentReportCard
