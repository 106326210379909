import AuthorizedPage from 'components/templates/AuthorizedPage'
import ContentHeaderTabs from 'components/organisms/ContentHeaderTabs'
import { PAGE_TITLES } from 'utils/constants'
import { MY_TEAM } from 'routes/constants'

const MyTeamPage = ({ children, ...otherProps }) => {
  return (
    <AuthorizedPage
      pageTitle={PAGE_TITLES.TEAM}
      headerTabs={<ContentHeaderTabs activePage={MY_TEAM} />}
      {...otherProps}
    >
      {children}
    </AuthorizedPage>
  )
}

export default MyTeamPage
