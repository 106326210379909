import { Divider } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import colors from 'styles/colors'
import InCardStack from '../InCardStack'
import { ActionText } from '../Text'
const TopNavigationLink = ({ children, to, Icon }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentLocation = location.pathname
  const active = currentLocation === to
  return (
    <InCardStack direction='row'>
      <ActionText
        onClick={() => (!active ? navigate(to) : null)}
        variant='caption'
        style={{
          color: active ? 'primary' : colors.Aluminium,
          display: 'flex',
          justifyContent: 'center',
          fontSize: '12px'
        }}>
        <Icon fontSize='small' />
        {children}
      </ActionText>
      <Divider orientation='vertical' sx={{ height: '20px' }} />
    </InCardStack>
  )
}

export default TopNavigationLink
