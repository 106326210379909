import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import SettingSwitch from 'components/atoms/input/SettingSwitch'
import OverlayLoading from 'components/atoms/OverlayLoading'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { useEffect, useState } from 'react'
import { CONSTANTS_OPTIONS, NOTIFICATION_TYPES } from 'services/constants'
import { getConstant } from 'services/constantServices'
import { updateStates } from 'services/userServices'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

const NotificationSettings = ({ userStates }) => {
  const { t } = useTranslation()
  const [configurations, setConfigurations] = useState([])
  const [initialSettings, setInitialSettings] = useState({})
  const [toggled, setToggled] = useState(false)
  const [userSettings, setUserSettings] = useState({})
  const [disablePushNotifications, setDisablePushNotifications] =
    useState(false)
  const [disableEmailNotifications, setDisableEmailNotifications] =
    useState(false)
  const [updateError, setUpdateError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const initializeByConfig = config => {
    const setting = {}
    setting[NOTIFICATION_TYPES.EMAIL] = true
    if (!config.EMAIL_ONLY) {
      setting[NOTIFICATION_TYPES.PUSH] = true
    }
    return setting
  }

  useEffect(() => {
    const fetchData = async () => {
      const configConstant = await getConstant(
        CONSTANTS_OPTIONS.NOTIFICATION_CONFIG
      )
      const settingsInit = {}
      if (configConstant) {
        configConstant.forEach(config => {
          settingsInit[config.SETTING_NAME] = initializeByConfig(config)
        })
        setInitialSettings(settingsInit)
        setUserSettings(userStates.notificationSettings || settingsInit)

        if (userStates.notificationSettings) {
          const pushEnabled = configConstant.find(
            config =>
              !config.EMAIL_ONLY &&
              userStates.notificationSettings[config.SETTING_NAME] &&
              userStates.notificationSettings[config.SETTING_NAME][
                NOTIFICATION_TYPES.PUSH
              ]
          )
          setDisablePushNotifications(!pushEnabled)
        }
        setDisableEmailNotifications(!userStates.emailNotifications)
        setConfigurations(configConstant)
      }
      setLoading(false)
    }
    setLoading(true)
    if (userStates) {
      fetchData()
    }
  }, [userStates])

  useEffect(() => {
    const updateSettings = async () => {
      const requestBody = {
        emailNotifications: !disableEmailNotifications,
        notificationSettings: JSON.stringify(userSettings)
      }
      const res = await updateStates(requestBody)
      if (res.status !== 200) {
        setUpdateError(true)
      } else {
        setUpdateError(false)
      }
    }

    if (toggled) {
      updateSettings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings, disableEmailNotifications, disablePushNotifications])

  const handleIndividualChange = (e, type, settingName) => {
    setToggled(true)
    const newSettings = { ...userSettings }
    if (!newSettings[settingName])
      newSettings[settingName] = initialSettings[settingName]
    newSettings[settingName][type] = e.target.checked
    setUserSettings(newSettings)
  }

  const handleGlobalChange = (e, type) => {
    setToggled(true)
    const newValue = e.target.checked
    const newSettings = { ...userSettings }

    // For email, don't change individual items when turning disabling all
    if (type === NOTIFICATION_TYPES.EMAIL && newValue === false) {
      configurations.forEach(config => {
        const settingName = config.SETTING_NAME
        if (!newSettings[settingName])
          newSettings[settingName] = initialSettings[settingName]
        newSettings[settingName][type] = true
      })
      setUserSettings(newSettings)
    }
    if (type === NOTIFICATION_TYPES.PUSH) {
      setDisablePushNotifications(newValue)
      configurations.forEach(config => {
        const settingName = config.SETTING_NAME
        if (!newSettings[settingName])
          newSettings[settingName] = initialSettings[settingName]
        newSettings[settingName][type] = !newValue
      })
    } else {
      setDisableEmailNotifications(newValue)
    }
  }
  return (
    <>
      <Typography variant='h3'>{t('setings.notifications')}</Typography>
      <TableContainer>
        <Table
          sx={{ '& .MuiTableCell-root': { paddingY: '8px', paddingX: 0 } }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('setings.type')}</TableCell>
              <TableCell>{t('setings.inApp')}</TableCell>
              <TableCell width='50px'>{t('overview.email')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {configurations.map(config => (
              <TableRow key={config.SETTING_NAME}>
                <TableCell>{config.SETTING_LABEL}</TableCell>
                {!config.EMAIL_ONLY ? (
                  <TableCell>
                    <SettingSwitch
                      checked={
                        !disablePushNotifications &&
                        (userSettings[config.SETTING_NAME]
                          ? userSettings[config.SETTING_NAME][
                              NOTIFICATION_TYPES.PUSH
                            ]
                          : true)
                      }
                      disabled={disablePushNotifications}
                      onChange={e =>
                        handleIndividualChange(
                          e,
                          NOTIFICATION_TYPES.PUSH,
                          config.SETTING_NAME
                        )
                      }
                      testId={`switch-${config.SETTING_NAME}-push`}
                    />
                  </TableCell>
                ) : (
                  <TableCell />
                )}
                <TableCell>
                  <SettingSwitch
                    checked={Boolean(
                      !disableEmailNotifications &&
                        (userSettings[config.SETTING_NAME]
                          ? userSettings[config.SETTING_NAME][
                              NOTIFICATION_TYPES.EMAIL
                            ]
                          : true)
                    )}
                    disabled={disableEmailNotifications}
                    onChange={e =>
                      handleIndividualChange(
                        e,
                        NOTIFICATION_TYPES.EMAIL,
                        config.SETTING_NAME
                      )
                    }
                    testId={`switch-${config.SETTING_NAME}-email`}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell height='30px' />
            </TableRow>
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  border: 0,
                  borderTop: '1px solid ' + colors.Solitude,
                  paddingTop: '20px'
                }
              }}>
              <TableCell style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                {t('setings.disableAll')}
              </TableCell>
              <TableCell>
                <SettingSwitch
                  checked={disablePushNotifications}
                  onChange={e => handleGlobalChange(e, NOTIFICATION_TYPES.PUSH)}
                  testId='switch-push'
                />
              </TableCell>
              <TableCell>
                <SettingSwitch
                  checked={disableEmailNotifications}
                  onChange={e =>
                    handleGlobalChange(e, NOTIFICATION_TYPES.EMAIL)
                  }
                  testId='switch-email'
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {updateError ? <RequestRetryAlert /> : null}
      <OverlayLoading open={loading} />
    </>
  )
}

export default NotificationSettings
