import { Stack, Typography, Divider } from '@mui/material'

const PageHeaderInfoTab = props => {
  const { title, value, dividercolor, ...otherProps } = props
  return (
    <Stack {...otherProps} sx={{paddingTop: '18px'}}>
      <Typography variant='eyebrow'>
        {title}
      </Typography>
      <Typography variant='h3'>{value}</Typography>
      <Divider
        style={{
          borderColor: dividercolor,
          borderBottomWidth: 'thick'
        }}
      />
    </Stack>
  )
}

export default PageHeaderInfoTab
