import {
  AppBar,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack
} from '@mui/material'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import PublicIcon from '@mui/icons-material/Public'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  HOME,
  BOOKMARKS,
  LOGIN,
  OVERVIEW,
  PERMISSION_LEVEL,
  ROOT,
  SEND_EMAIL_VERIFICATION,
  USER_PROFILE,
  USER_SETTINGS,
  HELP_AND_SUPPORT,
  NOTEBOOK,
  SBUSCRIPTION_ADMIN,
} from 'routes/constants'
import { signout } from 'services/userServices'
import { getLocalUser } from 'utils/localStorage'
import { isAllowedFeature, permitted } from 'utils/permissionCheck'
import TopNavigationLink from 'components/atoms/button/TopNavigationLink'
import SearchHeaderTag from './SearchHeaderTag'
import MyTeamPopup from './MyTeamPopup'
import ChatBotTag from './ChatBotTag'
import { FEATURE_KEYS } from 'services/constants'
import { useTranslation } from 'react-i18next'
import TranslateIcon from '@mui/icons-material/Translate'
import { LANGUAGE_OPTIONS } from 'utils/constants'
import { multilingualSwitch } from 'config/config'
import { ReactComponent as MonarkLogoSvg } from 'assets/images/monark_logo.svg'
import colors from 'styles/colors'

const Header = ({ children, loggedIn = true }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useState({})
  const [myTeamOpen, setMyTeamOpen] = useState(false)
  const [hasLicense, setHasLicense] = useState(false)
  const [hasChatPermission, setChatPermission] = useState(false)
  const [fullAssessmentFeature, setFullAssessmentFeature] = useState(false)

  useEffect(() => {
    if (loggedIn) {
      const user = getLocalUser()
      setUserInfo(user)
      setHasLicense(permitted(PERMISSION_LEVEL.LICENSED))
      setChatPermission(
        isAllowedFeature(FEATURE_KEYS.ASK_ME_ANYTHING_LEADERSHIP)
      )
      setFullAssessmentFeature(
        isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT)
      )
    }
  }, [loggedIn])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const logOut = async () => {
    await signout()
    handleClose()
    navigate(LOGIN)
  }

  const handleNavigate = url => {
    handleClose()
    navigate(url)
  }

  const handleTeamOpen = () => {
    handleClose()
    setMyTeamOpen(true)
  }
  return (
    <AppBar
      sx={{ flexGrow: 1, backgroundColor: '#FAFAFA' }}
      width='100%'
      component='nav'>
      <Container maxWidth='xl'>
        <Stack spacing={5} width='100%' sx={{ paddingY: '12px', paddingX: 0 }}>
          <Grid container>
            <Grid item display='flex'>
              <Grid item display='flex' width='160px'>
                <IconButton
                  onClick={() => navigate(ROOT)}
                  sx={{ paddingTop: 2, paddingLeft: 7 }}>
                  <MonarkLogoSvg
                    style={{ '--dot-color': colors.GoldenYellow400 }}
                    height={26}
                    width={140}
                  />
                </IconButton>
              </Grid>
              {children ? (
                <Grid item minWidth='400px'>
                  {children}
                </Grid>
              ) : null}
            </Grid>

            {loggedIn ? (
              <Grid
                item
                flex={1}
                display='inline-flex'
                justifyContent='right'
                minWidth={550}>
                <Stack direction='row' spacing={5} padding={0}>
                  {hasLicense && !fullAssessmentFeature ? (
                    <>
                      {hasChatPermission ? <ChatBotTag /> : null}
                      <SearchHeaderTag />
                      <TopNavigationLink
                        to={BOOKMARKS}
                        Icon={BookmarkBorderIcon}>
                        {t('headers.bookmarks')}
                      </TopNavigationLink>
                      <TopNavigationLink
                        to={NOTEBOOK}
                        Icon={EventNoteOutlinedIcon}>
                        {t('headers.notebook')}
                      </TopNavigationLink>
                    </>
                  ) : null}
                  <IconButton
                    onClick={handleClick}
                    data-testid='profile-avatar'
                    sx={{
                      height: 'fit-content',
                      paddingY: 0
                    }}>
                    <ProfileAvatar user={userInfo} title='Profile' />
                  </IconButton>
                </Stack>
              </Grid>
            ) : null}
          </Grid>
        </Stack>
      </Container>
      <Divider />
      {loggedIn ? (
        <Menu
          id='profile-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          {permitted(PERMISSION_LEVEL.COACH) && !fullAssessmentFeature ? (
            <MenuItem onClick={() => handleNavigate(OVERVIEW)}>
              <AdminPanelSettingsOutlinedIcon />
              &nbsp; {t('headers.adminDashboard')}
            </MenuItem>
          ) : null}
          {permitted(PERMISSION_LEVEL.SUPER_ADMIN) ? (
            <MenuItem onClick={() => handleNavigate(SBUSCRIPTION_ADMIN)}>
              <BiotechOutlinedIcon />
              &nbsp; {t('headers.internalDashboard')}
            </MenuItem>
          ) : null}
          <MenuItem onClick={() => handleNavigate(HOME)}>
            <DashboardOutlinedIcon />
            &nbsp; {t('headers.monarkHome')}
          </MenuItem>
          <Divider />
          {userInfo.is_verified === true ? null : (
            <MenuItem onClick={() => handleNavigate(SEND_EMAIL_VERIFICATION)}>
              <DashboardOutlinedIcon />
              &nbsp; {t('headers.verifyEmail')}
            </MenuItem>
          )}
          <MenuItem onClick={() => handleNavigate(USER_PROFILE)}>
            <AccountCircleOutlinedIcon />
            &nbsp; {t('headers.profile')}
          </MenuItem>
          <Divider />
          {hasLicense && !fullAssessmentFeature
            ? [
                <MenuItem
                  onClick={() => handleTeamOpen()}
                  key='menu-organization'>
                  <PublicIcon />
                  &nbsp; {t('headers.myOrganization')}
                </MenuItem>,
                <Divider key='divider-1' />,
                <MenuItem
                  onClick={() => handleNavigate(USER_SETTINGS)}
                  key='menu-settings'>
                  <SettingsOutlinedIcon />
                  &nbsp; {t('headers.settings')}
                </MenuItem>,
                <Divider key='divider-2' />
              ]
            : null}
          {multilingualSwitch ? (
            <MenuItem>
              <TranslateIcon />
              &nbsp; {t('headers.languages')}
              <Select
                sx={{ marginLeft: 10, paddingLeft: 2 }}
                variant='standard'
                value={i18n.resolvedLanguage}
                onChange={e => {
                  i18n.changeLanguage(e.target.value)
                  sessionStorage.clear()
                }}>
                {LANGUAGE_OPTIONS.map(l => (
                  <MenuItem key={`language-option-${l.value}`} value={l.value}>
                    {t(l.label)}
                  </MenuItem>
                ))}
              </Select>
            </MenuItem>
          ) : null}
          <MenuItem onClick={() => handleNavigate(HELP_AND_SUPPORT)}>
            <HelpOutlineIcon />
            &nbsp; {t('headers.helpSupport')}
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => logOut()}>
            <LogoutOutlinedIcon />
            &nbsp; {t('headers.logout')}
          </MenuItem>
        </Menu>
      ) : null}
      {hasLicense ? (
        <MyTeamPopup open={myTeamOpen} onClose={() => setMyTeamOpen(false)} />
      ) : null}
    </AppBar>
  )
}

export default Header
