import { Box, CardContent, Stack } from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import PanelsWithScrollBars from 'components/atoms/container/PanelsWithScrollBars'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText } from 'components/atoms/Text'
import ContentListItem from 'components/molecules/ContentListItem'
import StepContent from 'components/molecules/journey/StepContent'
import StepPathObjectHeader from 'components/molecules/journey/StepPathObjectHeader'
import { useEffect, useRef, useState } from 'react'
import { JOURNEY } from 'routes/constants'
import { BOOKMARKS_URL, GOAL_STEP_CONTENT_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { useTranslation } from 'react-i18next'

const BookmarksCard = () => {
  const { t } = useTranslation()
  const [requestError, setRequestError] = useState(undefined)
  const [bookmarks, setBookmarks] = useState([])
  const [selected, setSelected] = useState(null)
  const [pagination, setPagination] = useState({})
  const topRef = useRef(null)

  const fetchData = async queryPagination => {
    const queryObject = {
      web: true,
      ...queryPagination
    }
    const res = await getRequest(BOOKMARKS_URL, queryObject)
    if (res.status === 200) {
      let newResult = [...bookmarks]
      newResult = newResult.concat(res.data.data.data)
      setBookmarks(newResult)
      setPagination(res.data.data.pagination)
      setRequestError(false)
    } else {
      setRequestError(true)
    }
  }

  const listOnScroll = ref => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current
      if (scrollTop + clientHeight === scrollHeight) {
        if (pagination.next) {
          const queryPagination = {
            page: pagination.next,
            limit: pagination.limit
          }
          fetchData(queryPagination)
        }
      }
    }
  }

  useEffect(() => {
    fetchData({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const selectedData = { ...selected }
      const res = await getRequest(
        GOAL_STEP_CONTENT_URL,
        { web: true, goalStepId: selected.goalStepId}
      )
      if (res.status === 200) {
        selectedData.contentData = res.data.data
      } else {
        selectedData.contentData = false
      }
      setSelected(selectedData)
    }
    if (selected?.goalStepId && !selected.contentData) {
      fetchData()
    }
  }, [selected])

  const handleSelected = newSelected =>{
    setSelected(newSelected)
    topRef.current?.scrollIntoView({ block: 'nearest' })
  }

  return (
    <PanelsWithScrollBars
      listOnScroll={listOnScroll}
      leftList={
        <>
          {bookmarks.map(bookmark => (
            <ContentListItem
              key={bookmark.id}
              selected={bookmark.id === selected?.id}
              contentData={bookmark}
              selectCallback={() => handleSelected(bookmark)}
              bookmarked={true}
            />
          ))}
        </>
      }>
      {bookmarks.length === 0 ? (
        <CardContent>
          <Stack spacing={10} display='flex' maxWidth='600px'>
            <NotificationBodyText>
              {t('bookmarks.noCurrentlyContentBookmarked')}
            </NotificationBodyText>
            <NotificationBodyText>
              {t('bookmarks.addContentToList')}
            </NotificationBodyText>
            <LinkButton
              to={JOURNEY}
              style={{ alignSelf: 'center', paddingTop: '20px' }}
              buttonProps={{ sx: { width: 'fit-content' } }}>
              {t('bookmarks.goToCurrentPath')}
            </LinkButton>
          </Stack>
        </CardContent>
      ) : selected ? (
        <Box width='100%'>
          <Box ref={topRef}/>
          <StepPathObjectHeader
            l1StepName={selected.parentStepName}
            goalName={selected.contentData?.goalName}
          />
          {selected.contentData ? (
            <StepContent stepData={selected.contentData} />
          ) : null}
        </Box>
      ) : (
        <CardContent>
          <NotificationBodyText>
            {t('bookmarks.selectBookmark')}
          </NotificationBodyText>
        </CardContent>
      )}
      {requestError ? (
        <CardContent>
          <RequestRetryAlert />
        </CardContent>
      ) : null}
    </PanelsWithScrollBars>
  )
}

export default BookmarksCard
