import { useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { alpha, InputAdornment, IconButton, useTheme } from '@mui/material'
import colors from 'styles/colors'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { useTranslation } from 'react-i18next'

const FormDatePicker = props => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  const {
    attributeName,
    formik,
    sx,
    onChangeCallBack,
    TextFieldProps = {},
    ...otherProps
  } = props
  moment.locale(i18n.resolvedLanguage)

  return (
    <DatePicker
      {...otherProps}
      open={open}
      id={attributeName}
      onChange={value => {
        formik.setFieldValue(attributeName, value ? moment(value) : value)
        if (onChangeCallBack) onChangeCallBack()
      }}
      onBlur={formik.handleBlur}
      value={formik.values[attributeName]}
      onClose={() => {
        setOpen(false)
      }}
      slotProps={{
        actionBar: {
          actions: ['clear']
        },
        textField: {
          error:
            formik.touched[attributeName] &&
            Boolean(formik.errors[attributeName]),
          helperText:
            formik.touched[attributeName] &&
            Boolean(formik.errors[attributeName])
              ? t(`${formik.errors[attributeName]}`)
              : '',
          disabled: true,
          InputProps: {
            endAdornment: (
              <InputAdornment position={'end'}>
                <IconButton
                  sx={{
                    color:
                      formik.touched[attributeName] &&
                      Boolean(formik.errors[attributeName])
                        ? theme.palette.error.main
                        : open
                          ? theme.palette.primary.main
                          : alpha(colors.DarkGrey, 0.5)
                  }}
                  onClick={event => {
                    setOpen(true)
                  }}>
                  <CalendarMonthOutlinedIcon />
                </IconButton>
              </InputAdornment>
            )
          },
          ...TextFieldProps
        }
      }}
      sx={[
        {
          '& .MuiInputBase-root': {
            minWidth: 0
          },
          '& .MuiInputBase-root .Mui-disabled': {
            color: 'black',
            WebkitTextFillColor: 'black'
          }
        },
        sx
      ]}
    />
  )
}

export default FormDatePicker
