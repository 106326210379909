import React, { useState } from 'react'
import { useFormik } from 'formik'
import {
  Button,
  CardContent,
  Typography,
  Alert,
  Grid,
  MenuItem,
  Select,
  FormControl
} from '@mui/material'
import FormCard from 'components/templates/FormCard'
import LinkButton from 'components/atoms/button/LinkButton'
import { LOGIN } from 'routes/constants'
import { FORGOT_PASSWORD_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import { emailValidationSchema } from 'utils/formValidations'
import FormInputBox from 'components/atoms/input/FormInputBox'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language'
import { LANGUAGE_OPTIONS } from 'utils/constants'
import { multilingualSwitch } from 'config/config'

const ForgotPasswordCard = () => {
  const { t, i18n } = useTranslation()
  const [requestSuccess, setRequestSuccess] = useState(undefined)

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: emailValidationSchema,
    onSubmit: async values => {
      const requestBody = {
        email: values.email
      }
      const res = await postRequest(FORGOT_PASSWORD_URL, requestBody)
      if (res.status === 200) {
        setRequestSuccess(true)
      } else {
        setRequestSuccess(false)
      }
    }
  })

  return (
    <FormCard>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant='h2_alu'>{t('authPages.sendPasswordReset')}</Typography>
      </CardContent>
      {requestSuccess === true ? (
        <CardContent>
          <Typography variant='body1'>
            {t('authPages.requestSubmittedSuccessfully')}
          </Typography>
        </CardContent>
      ) : (
        <React.Fragment>
          <form onSubmit={formik.handleSubmit}>
            <CardContent>
              <FormInputBox
                label={t('overview.email')}
                type='text'
                placeholder={t('authPages.workEmail')}
                attributeName='email'
                inputProps={{ style: { textTransform: 'lowercase' } }}
                formik={formik}
              />
            </CardContent>
            {requestSuccess === false ? (
              <CardContent>
                <Alert severity='error'>
                  {t('authPages.cannotFindAccount')}
                </Alert>
              </CardContent>
            ) : null}
            <CardContent>
              <Grid container spacing={10} maxWidth='250px'>
                <Grid item xs={12}>
                  <Button fullWidth type='submit'>
                    {t('authPages.sendResetLink')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </React.Fragment>
      )}
      <CardContent sx={{paddingTop:0}}>
          <Grid item width={'230px'}>
            <LinkButton to={LOGIN}>{t('authPages.backToLogin')}</LinkButton>
          </Grid>
      </CardContent>

      {multilingualSwitch ? (
        <CardContent sx={{ marginTop: 15, alignItems: 'center' }}>
          <LanguageIcon />
          <FormControl>
            <Select
              sx={{ marginLeft: 5, paddingLeft: 2, minWidth: 120, height: 30 }}
              value={i18n.resolvedLanguage}
              onChange={e => {
                i18n.changeLanguage(e.target.value)
                sessionStorage.clear()
              }}
              >
              {LANGUAGE_OPTIONS.map(l => (
                <MenuItem key={`language-option-${l.value}`} value={l.value}>
                  {t(l.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      ) : null}
    </FormCard>
  )
}
export default ForgotPasswordCard
