import { ArrowForward } from '@mui/icons-material'
import { Button } from '@mui/material'

const NextIconButton = props => {
  return (
    <Button {...props}>
      <ArrowForward />
    </Button>
  )
}

export default NextIconButton
