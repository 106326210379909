import { ChangeTable, ChangeTableRow } from 'components/molecules/ChangeTable'
import { getPercentageDisplay } from 'utils/formatText'

const breakdownInfo = {
  completionRate: {
    label: 'Completion Rate',
    tooltip:
      'This indicates what percentage of users are keeping pace with expected learning rate. This score will be high if all team members are moving forward at the planned pace, and a bit lower if some are not keeping up with their learning.'
  },
  learningCheck: {
    label: 'Learning Check Scores',
    tooltip:
      'This score is an average of how well users are doing across all of the learning checks in the Monark learning paths.'
  },
  facilitationAttendance: {
    label: 'Facilitated Session Attendance',
    tooltip:
      'This is a measure of attendance across users in attending Monark’s facilitation sessions around the learning content.'
  },
  feedbackSeeking: {
    label: 'Feedback Seeking',
    tooltip:
      'This score reflects the percentage of users who are completing self-assessments and then also requesting 360 ratings from the colleagues.'
  }
}

const DevelopmentDetails = ({ scoreData }) => {
  return (

      <ChangeTable showBehaviors={false}>
        {Object.entries(breakdownInfo).map(([key, value], index) => (
          <ChangeTableRow
            key={key}
            name={value.label}
            index={index}
            tooltip={value.tooltip}
            newScore={getPercentageDisplay(scoreData.developmentBreakdown[key])}
            showOlderColumn={false}
            boldIndex={true}
          />
        ))}
      </ChangeTable>

  )
}

export default DevelopmentDetails
