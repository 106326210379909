import React, { useEffect, useState } from 'react'
import MyTeamPage from 'components/templates/navigationPageTemplates/MyTeamPage'
import { useLocation, useParams } from 'react-router-dom'
import { getRequest } from 'utils/api'
import { USER_CONNECTION_URL } from 'services/constants'
import { Grid, Typography } from '@mui/material'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { getFullName } from 'utils/formatText'
import RowStack from 'components/atoms/container/RowStack'
import OverlayLoading from 'components/atoms/OverlayLoading'
import InCardStack from 'components/atoms/InCardStack'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import { useTranslation } from 'react-i18next'
import { getLocalUser } from 'utils/localStorage'
import { Tag } from 'components/atoms/Text'
import {
  UpcomingEventList,
  PastEventList,
  ConnectionNotesList
} from 'components/molecules/myTeam/EventList'
import colors from 'styles/colors'
import BookOneOnOne from 'components/organisms/myTeam/BookOneOnOne'

const Contact = () => {
  const { t } = useTranslation()
  const params = useParams()
  const location = useLocation()
  // const navigate = useNavigate()
  const showBackButton = location.state?.from
  const [connection, setConnection] = useState(null)
  const [loading, setLoading] = useState(false)
  // const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [isDirectReport, setIsDirectReport] = useState(false)
  const userConnectionId = params.id

  const fetchData = async () => {
    const res = await getRequest(`${USER_CONNECTION_URL}/${userConnectionId}`)
    let connection
    if (res.status === 200) {
      connection = res.data.data
      setConnection(res.data.data)
      setIsDirectReport(connection.relationship === 'direct_report')
    }
    setLoading(false)
  }
  useEffect(() => {
    if (!connection) {
      setLoading(true)
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  // const handlDeleteConnectionConfirm = async confirm => {
  //   setConfirmDialogOpen(false)
  //   if (!confirm) {
  //     return
  //   }
  //   setLoading(true)
  //   const res = await deleteRequest(`${USER_CONNECTION_URL}/${connection.id}`)
  //   if (res.status === 204) {
  //     navigate(MY_TEAM)
  //   }
  //   setLoading(false)
  // }

  const relationToText = rel => {
    if (rel === 'direct_report') {
      return t('myTeam.directReport')
    } else if (rel === 'direct_supervisor') {
      return t('myTeam.directSupervisor')
    } else if (rel === 'senior_leader') {
      return t('myTeam.seniorLeader')
    } else if (rel === 'colleague') {
      return t('myTeam.colleague')
    } else {
      return t('myTeam.colleague')
    }
  }

  return (
    <MyTeamPage>
      {showBackButton && <BackIconTextButton />}
      {connection && (
        <RowStack justifyContent='space-between'>
          <RowStack>
            <ProfileAvatar size='large' user={connection.user} />
            <InCardStack
              spacing={3}
              sx={{
                alignItems: 'flex-start'
              }}>
              <Typography variant='h2'>
                {getFullName(connection.user)}
              </Typography>
              <RowStack>
                {connection.user.job && (
                  <Typography>{connection.user.job}</Typography>
                )}
                <Tag
                  sx={{
                    backgroundColor:
                      connection.relationship === 'direct_report'
                        ? colors.LightGreen20
                        : colors.Grey
                  }}>
                  {relationToText(connection.relationship)}
                </Tag>
              </RowStack>

              <Typography>{connection.user.email}</Typography>
            </InCardStack>
          </RowStack>
          {isDirectReport && (
            <BookOneOnOne
              userConnectionId={userConnectionId}
              userConnectionEmail={connection?.user?.email}
              buttonLabel={t('myTeam.bookOneOnOneWith', {
                name: connection.user.firstName
              })}
              connectionName={connection?.user?.firstName}
            />
          )}
        </RowStack>
      )}

      <Grid container columnSpacing={10} style={{marginLeft: -20}}>
        <Grid item xs={4}>
          <UpcomingEventList
            name={getLocalUser().firstName}
            nameRel={connection?.user?.firstName}
            userConnectionId={userConnectionId}
            userConnectionEmail={connection?.user?.email}
            showBookOneOnOne={isDirectReport}
          />
        </Grid>
        <Grid item xs={4}>
          <PastEventList
            name={getLocalUser().firstName}
            nameRel={connection?.user?.firstName}
            userConnectionId={userConnectionId}
          />
        </Grid>
        <Grid item xs={4}>
          <ConnectionNotesList userConnectionId={userConnectionId} />
        </Grid>
      </Grid>

      <OverlayLoading open={loading} />
      {/* <ConfirmCancelDialog
        open={confirmDialogOpen}
        titleText={t('myTeam.deleteConnection')}
        confirmText={t('contact.yesDeleteIt')}
        cancelText={t('common.cancel')}
        notificationText={t('myTeam.areYouSureDeleteConnection')}
        buttonsDirection='row'
        onClose={handlDeleteConnectionConfirm}
      /> */}
    </MyTeamPage>
  )
}

export default Contact
