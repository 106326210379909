import {
  Stack,
  Typography,
  Autocomplete,
  TextField,
  Icon,
  createFilterOptions,
  MenuList,
  MenuItem,
  CardContent,
  TableCell,
  TableRow,
  IconButton
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import {
  FEATURES_URL,
  ORGANIZATIONS_URL,
  USERS_URL,
  FEATURE_STATUSES,
  USER_ALLOWED_FEATURE_URL
} from 'services/constants'
import { getRequest, deleteRequest } from 'utils/api'
import InCardStack from 'components/atoms/InCardStack'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import colors from 'styles/colors'
import FeatureState from 'components/molecules/internal/FeatureState'
import { NotificationBodyText } from 'components/atoms/Text'
import SearchInputBox from 'components/atoms/input/SearchInputBox'
import PanelsWithScrollBars from 'components/atoms/container/PanelsWithScrollBars'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { updatePageState } from 'utils/windowHistoryHelper'
import StyledAccordion from 'components/atoms/container/Accordion'
import FeatureUserAccess from 'components/molecules/internal/FeatureUserAccess'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import AvatarHeaderCell from 'components/atoms/table/AvartarHeaderCell'
import SortableHeaderCell from 'components/atoms/table/SortableHeader'
import { requestSort } from 'utils/collectionHelper'

const InnerCard = ({ children, ...otherProps }) => (
  <InCardStack
    sx={{
      border: 0,
      borderBottom: '1px solid ' + colors.Solitude,
      paddingTop: '20px',
      paddingBottom: '20px',
      alignItems: 'flex-start'
    }}
    {...otherProps}>
    {children}
  </InCardStack>
)

const FeatureAdminCard = () => {
  const [featureList, setFeatureList] = useState([])
  const [selectedFeature, setSelectedFeature] = useState(
    window.history.state?.selectedFeature || null
  )
  const [users, setUsers] = useState([])
  const [allowedUsers, setAllowedUsers] = useState([])
  const [requestError, setRequestError] = useState(null)
  const [userFilterText, setUserFilterText] = useState('')

  const [loading, setLoading] = useState(true)
  const [companyList, setCompanyList] = useState([])
  const [selectedOrganization, setSelectedOrganization] = useState(
    window.history.state?.selectedOrganization || null
  )
  const [filterText, setFilterText] = useState('')
  const filter = createFilterOptions()
  const [currentSort, setCurrentSort] = useState(undefined)
  const [sortOrder, setSortOrder] = useState('asc')
  const [displayData, setDisplayData] = useState([])
  const sortableColumns = [
    { label: 'User', columnName: 'firstName' },
    { label: 'Company', columnName: 'organizationName' }
  ]

  const fetchFeaturesData = async () => {
    setLoading(true)
    const oRes = await getRequest(FEATURES_URL)
    if (oRes?.status === 200) {
      setFeatureList(oRes.data.data)
    }
    setLoading(false)
  }

  const getFeatureAllowedUsers = async () => {
    if (selectedFeature) {
      setLoading(true)
      const res = await getRequest(`${FEATURES_URL}/${selectedFeature.id}`)
      if (res?.status === 200) {
        let users = res.data.data.allowedUsers ?? []
        users.forEach(user => {
          user.accessAllowed = true
          user.organizationName = user.organization?.name ?? ''
        })
        setAllowedUsers(users)
      }
      setLoading(false)
    }
  }

  const deleteAccess = async userId => {
    const resp = await deleteRequest(USER_ALLOWED_FEATURE_URL, {
      userId,
      featureId: selectedFeature.id
    })
    if (resp.status === 204) {
      const newUsers = [...users]
      newUsers.forEach(user => {
        if (userId === user.id) user.accessAllowed = false
      })
      setUsers(newUsers)

      // Get updated Allowed User data
      getFeatureAllowedUsers()
    } else {
      setRequestError(true)
      setTimeout(() => {
        setRequestError(false)
      }, 3000)
    }
  }

  const handleRequestSort = (pSortBy, clickAction = true) => {
    requestSort(
      allowedUsers,
      currentSort,
      sortOrder,
      pSortBy,
      setCurrentSort,
      setSortOrder,
      setDisplayData,
      clickAction
    )
  }

  useEffect(() => {
    if (currentSort) {
      handleRequestSort(currentSort, false)
    } else {
      setDisplayData(allowedUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedUsers])

  const fetchOrganizationsData = async () => {
    setLoading(true)
    const oRes = await getRequest(ORGANIZATIONS_URL)
    if (oRes?.status === 200) {
      setCompanyList(oRes.data.data.filter(o => o.name))
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchFeaturesData()
    fetchOrganizationsData()
  }, [])

  useEffect(() => {
    const newFeatureList = [...featureList]
    newFeatureList.forEach(feature => {
      if (feature.id === selectedFeature.id) {
        feature.status = selectedFeature.status
      }
    })
    setFeatureList(newFeatureList)
    getFeatureAllowedUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature])

  const fetchUsers = async () => {
    setLoading(true)
    setRequestError(false)

    const resOrg = await getRequest(
      `${USERS_URL}?noStats=true&organizationId=${selectedOrganization.id}`
    )

    if (resOrg.status === 200) {
      let newUsers = resOrg.data.data ?? []
      newUsers.forEach(user => {
        let access = false
        if (selectedFeature.status === FEATURE_STATUSES.BETA) {
          access = user.allowedFeatures.some(
            feature => feature.id === selectedFeature.id
          )
        } else {
          access = true // Incase Feature in Production, Allow Access to all
        }
        user.accessAllowed = access
      })
      setUsers(newUsers)
    } else {
      setRequestError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (selectedOrganization) {
      fetchUsers()
    } else {
      setUsers([])
    }
    updatePageState({ selectedFeature, selectedOrganization })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization, selectedFeature])

  const Label = ({ children, variant }) => (
    <Typography
      variant={variant}
      width='100%'
      marginBottom={3}
      display='flex'
      alignItems='center'>
      {children}
    </Typography>
  )

  return (
    <Stack spacing={0} width='100%' sx={{ paddingTop: 0 }}>
      <PanelsWithScrollBars
        leftList={
          <MenuList style={{ width: '90%' }}>
            {featureList.map(f => (
              <MenuItem
                onClick={() => setSelectedFeature(f)}
                key={f.id}
                style={{ whiteSpace: 'normal', paddingLeft: 0 }}
                selected={selectedFeature?.id === f.id}>
                <Typography>{f.name}</Typography>
              </MenuItem>
            ))}
          </MenuList>
        }>
        <OverlayLoading open={loading} />

        {!selectedFeature ? (
          <CardContent>
            <NotificationBodyText>
              Select a Feature to Set User Access
            </NotificationBodyText>
          </CardContent>
        ) : (
          <>
            <InnerCard>
              <Label variant={'h6'}>Feature Properties</Label>
              <FeatureState
                selectedFeature={selectedFeature}
                setSelectedFeature={setSelectedFeature}
              />
            </InnerCard>

            <InnerCard>
              <Label variant={'h6'}>User Access</Label>

              <StyledAccordion
                summaryComponent={
                  <Typography variant='subtitle1'>
                    Current users with access:{' '}
                    {selectedFeature.status === FEATURE_STATUSES.PRODUCTION
                      ? 'This feature is released to production. All users have access to it.'
                      : allowedUsers?.length}
                  </Typography>
                }
                disabled={
                  selectedFeature.status === FEATURE_STATUSES.PRODUCTION
                }
                initialExpanded={
                  selectedFeature.status !== FEATURE_STATUSES.PRODUCTION
                }
                detailProps={{ sx: { paddingX: 0 } }}
                style={{ marginTop: '10px' }}>
                {allowedUsers.length ? (
                  <PresetTableContainer
                    shorterRow={true}
                    maxHeight='450px'
                    headers={
                      <>
                        {sortableColumns.map(({ label, columnName }) => {
                          return (
                            <SortableHeaderCell
                              key={columnName}
                              currentSort={currentSort}
                              sortOrder={sortOrder}
                              requestSort={handleRequestSort}
                              attributeName={columnName}>
                              {label}
                            </SortableHeaderCell>
                          )
                        })}
                        <TableCell></TableCell>
                      </>
                    }>
                    <TableRow>
                      <TableCell height='30px' />
                    </TableRow>

                    {displayData.map((u, index) => {
                      return (
                        <TableRow key={index}>
                          <AvatarHeaderCell user={u} />
                          <TableCell width='300px'>
                            {u.organizationName}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ color: colors.Aluminium }}
                              onClick={() => deleteAccess(u.id)}>
                              <Close />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </PresetTableContainer>
                ) : selectedOrganization ? (
                  <CardContent>
                    <NotificationBodyText>
                      No users have access to this feature!
                    </NotificationBodyText>
                  </CardContent>
                ) : null}
              </StyledAccordion>
            </InnerCard>

            {selectedFeature.status === FEATURE_STATUSES.PRODUCTION ? null : (
              <InnerCard>
                <Label variant={'subtitle1'}>Manage access by company</Label>

                <InnerCard direction='row'>
                  <Autocomplete
                    autoSelect
                    // multiple={true}
                    value={selectedOrganization}
                    options={companyList}
                    onChange={(e, value) => setSelectedOrganization(value)}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params)
                      return filtered
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        autoFocus
                        placeholder='Search for Company'
                        sx={{
                          color: colors.Aluminium,
                          minWidth: '200px',
                          '& .MuiInput-root:before': { display: 'none' },
                          '& .MuiInput-root:after': { display: 'none' }
                        }}
                        onChange={e => setFilterText(e.target.value)}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Typography {...props} key={option.id}>
                        {option.name}
                      </Typography>
                    )}
                    clearIcon={
                      <Icon fontSize='small' sx={{ color: colors.Aluminium }}>
                        {filterText?.length ? 'clear' : null}
                      </Icon>
                    }
                    getOptionLabel={option => option?.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    data-testid='company-filter-select'
                  />

                  {users.length ? (
                    <SearchInputBox
                      onChangeHandler={e => setUserFilterText(e.target.value)}
                      placeholder='Search for user..'
                    />
                  ) : null}
                </InnerCard>

                {users.length ? (
                  <FeatureUserAccess
                    users={users}
                    setUsers={setUsers}
                    selectedFeature={selectedFeature}
                    getFeatureAllowedUsers={getFeatureAllowedUsers}
                    userFilterText={userFilterText}
                  />
                ) : selectedOrganization ? (
                  <CardContent>
                    <NotificationBodyText>
                      No users data found!
                    </NotificationBodyText>
                  </CardContent>
                ) : null}
              </InnerCard>
            )}
          </>
        )}

        {requestError ? (
          <CardContent>
            <RequestRetryAlert />
          </CardContent>
        ) : null}
      </PanelsWithScrollBars>
    </Stack>
  )
}

export default FeatureAdminCard
