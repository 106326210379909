import { Button } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import { MONARK_SUPPORT_EMAIL } from 'utils/constants'

const ContactUsButton = ({ buttonText = 'Contact Us', subject = '' }) => {
  return (
    <InCardStack spacing={5}>
      <Button href={`mailto:${MONARK_SUPPORT_EMAIL}?subject=${encodeURIComponent(subject)}`}>{buttonText}</Button>
    </InCardStack>
  )
}

export default ContactUsButton
