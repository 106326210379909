import {
  Box,
  Card,
  CardContent,
  MobileStepper,
  Typography,
  useTheme
} from '@mui/material'
import BackIconButton from 'components/atoms/button/BackIconButton'
import NextIconButton from 'components/atoms/button/NextIconButton'
import { useEffect, useRef, useState } from 'react'
import { getRequest } from 'utils/api'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import {
  GOAL_STEP_CONTENT_URL,
  GOAL_STEP_QUIZ_TYPES,
  GOAL_STEP_QUIZ_URL
} from 'services/constants'
import StepContent from './StepContent'
import StepFeedback from './StepFeedback'
import StepQuiz from './StepQuiz'
import StepPathObjectHeader from './StepPathObjectHeader'
import BookmarkButton from 'components/atoms/button/BookmarkButton'
import { checkSaved } from 'utils/contentEditorHelper'
import OverlayLoading from 'components/atoms/OverlayLoading'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import { useLocation, useNavigate } from 'react-router-dom'
import { PULSE_SURVEY_NEW } from 'routes/constants'
import { FeedbackBlueIcon } from 'components/atoms/Icons'
import InCardStack from 'components/atoms/InCardStack'
import colors from 'styles/colors'
import { getPopupConfig, setPopupConfig } from 'utils/localStorage'
import {
  TRACKING_SCREEN_MODELS,
  logScreen,
  trackingSubScreenKey,
  updateViewTime
} from 'services/trackingScreenActions'
import { useTranslation } from 'react-i18next'
import { trackPathAction } from 'services/amplitudeTracking'

const popupTypeGoalStepSurvey = 'goalStepSurvey'

// This componenet manage steps pagination
const StepContentsCard = ({
  organizedSteps,
  l2StepData,
  firstL2Step,
  handleNextStep2,
  handlePreviousStep2,
  contentStartIndex,
  editorMode = false,
  pathOpen,
  togglePathOpen
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [l1StepName, setL1StepName] = useState('')
  const [l3Steps, setL3Steps] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [goalName, setGoalName] = useState('')
  const [showStepper, setShowStepper] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pulseSurveyPromptOpen, setPulseSurveyPromptOpen] = useState(false)

  const topRef = useRef(null)
  const scrollToTop = () => topRef.current?.scrollIntoView({ block: 'nearest' })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const stepContents = [...l2StepData.childL3Steps]

      await Promise.all(
        stepContents.map(async step => {
          let requesUrl = ''
          const params = { goalStepId: step.id }
          if (step.type === 'content') {
            requesUrl = GOAL_STEP_CONTENT_URL
            if (editorMode) {
              params.editorMode = true
            } else {
              params.web = true
            }
          } else {
            requesUrl = GOAL_STEP_QUIZ_URL
          }
          const res = await getRequest(requesUrl, params)
          if (res.status === 200) {
            step.contentData = res.data.data
          } else {
            step.contentData = false
          }
        })
      )

      if (l2StepData.isLastL2Step) {
        stepContents.push({ type: 'feedback', stepId: l2StepData.id })
      }
      setL3Steps(stepContents)
      if (contentStartIndex === -1) {
        setActiveStep(stepContents.length - 1)
      } else {
        setActiveStep(contentStartIndex)
      }
      scrollToTop()
      setLoading(false)
    }
    if (l2StepData.childL3Steps) {
      fetchData()
    }
  }, [contentStartIndex, editorMode, l2StepData])

  // Screen monitor functions
  const location = useLocation()
  const screenLogRef = useRef({})
  const logSubScreen = async () => {
    const relatedModelId =
      l3Steps[activeStep]?.id ?? l3Steps[activeStep]?.stepId
    if (relatedModelId) {
      await logScreen(
        screenLogRef,
        trackingSubScreenKey,
        location,
        TRACKING_SCREEN_MODELS.GOAL_STEPS,
        relatedModelId
      )
    }
  }
  useEffect(() => {
    const updateSubScreenViewTime = async () => {
      await updateViewTime(screenLogRef, trackingSubScreenKey)
    }
    window.addEventListener('focus', logSubScreen)
    window.addEventListener('blur', updateSubScreenViewTime)
    return () => {
      window.removeEventListener('focus', logSubScreen)
      window.removeEventListener('blur', updateSubScreenViewTime)
      updateViewTime()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const goalInfo = await getGoalInfoById(l2StepData.goal_id)
      setGoalName(goalInfo.name)
    }
    if (l2StepData.goal_id) {
      fetchData()
    }
  }, [l2StepData.goal_id])

  useEffect(() => {
    if (organizedSteps.length && l2StepData.number) {
      const sortedSteps = [...organizedSteps].sort(
        (a, b) => b.number - a.number
      )
      const currentL1Step = sortedSteps.find(
        l1Step => l1Step.number < l2StepData.number
      )
      setL1StepName(currentL1Step.name)
    }
  }, [organizedSteps, l2StepData])

  useEffect(() => {
    if (
      l3Steps[activeStep]?.type === 'feedback' ||
      (l3Steps[activeStep]?.type === 'quiz' &&
        l3Steps[activeStep].contentData?.type === GOAL_STEP_QUIZ_TYPES.CHECK &&
        !l3Steps[activeStep].contentData.userQuizResults?.length)
    ) {
      setShowStepper(false)
    } else setShowStepper(true)
  }, [l3Steps, activeStep])

  const contentActionTracking = (eventName, additionalProperties) => {
    trackPathAction(l2StepData.goalId, eventName, {
      milestone: l1StepName,
      stepName: l2StepData.name,
      stepTitle: l3Steps[activeStep].contentData?.title,
      ...additionalProperties
    })
  }

  useEffect(() => {
    if (l3Steps[activeStep]) {
      logSubScreen()
      contentActionTracking('Read Content')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [l3Steps[activeStep]])

  const handleNext = (isNavigation = true, isStepUpdate = true) => {
    if (editorMode && !checkSaved()) {
      return
    }
    if (l3Steps[activeStep].pulseSurveyQuestion) {
      const poppedSurveys = getPopupConfig(popupTypeGoalStepSurvey) || {}
      if (!poppedSurveys[l3Steps[activeStep].id]) {
        setPulseSurveyPromptOpen(true)
        poppedSurveys[l3Steps[activeStep].id] = true
        setPopupConfig(popupTypeGoalStepSurvey, poppedSurveys)
        return
      }
    }
    if (activeStep < l3Steps.length - 1) {
      setActiveStep(activeStep + 1)
    } else {
      handleNextStep2(isNavigation, isStepUpdate)
    }
    scrollToTop()
  }
  const handlePrevious = () => {
    if (editorMode && !checkSaved()) {
      return
    }
    if (activeStep === 0) {
      handlePreviousStep2()
    } else {
      setActiveStep(activeStep - 1)
    }
    scrollToTop()
  }

  const contentEditorCallback = updated => {
    const updatedArray = [...l3Steps]
    updatedArray[activeStep].contentData = updated
    setL3Steps(updatedArray)
  }

  const handlePulseSurveyPropmptClose = confirmed => {
    if (confirmed) {
      navigate(PULSE_SURVEY_NEW, {
        state: {
          pulseSurveyQuestion: l3Steps[activeStep].pulseSurveyQuestion,
          goalId: l2StepData.goalId,
          goalStepId: l3Steps[activeStep].id
        }
      })
    } else handleNext()
    setPulseSurveyPromptOpen(false)
  }

  return (
    <Card
      data-testid='path-content-card'
      sx={{ height: 'inherit', boxShadow: '0px 1px 5px 2px rgb(0 0 0 / 12%)' }}>
      <StepPathObjectHeader
        goalName={goalName}
        l1StepName={l1StepName}
        bookmarkIcon={
          l3Steps[activeStep]?.type === 'content' ? (
            <BookmarkButton
              stepContentId={l3Steps[activeStep].contentData.id}
              bookmarked={l3Steps[activeStep].contentData.bookmarked}
            />
          ) : null
        }
      />
      {l3Steps.length > 0 ? (
        <CardContent
          sx={{
            height: 'inherit',
            paddingBottom: !showStepper ? '120px !important' : '200px',
            width: '100%'
          }}>
          <Box
            sx={{
              height: 'inherit',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: '100%'
            }}>
            <Box ref={topRef} />
            {l3Steps[activeStep].type === 'content' ? (
              <StepContent
                stepData={l3Steps[activeStep].contentData}
                editorMode={editorMode}
                contentEditorCallback={contentEditorCallback}
                contentActionTracking={contentActionTracking}
              />
            ) : l3Steps[activeStep].type === 'quiz' ? (
              <StepQuiz
                stepData={l3Steps[activeStep].contentData}
                scrollToTop={scrollToTop}
                setShowStepper={setShowStepper}
                pathOpen={pathOpen}
                togglePathOpen={togglePathOpen}
                contentActionTracking={contentActionTracking}
              />
            ) : (
              <StepFeedback
                organizedSteps={organizedSteps}
                l2StepData={l2StepData}
                completeAction={handleNext}
                pathOpen={pathOpen}
                togglePathOpen={togglePathOpen}
              />
            )}
          </Box>
        </CardContent>
      ) : null}
      {l3Steps.length > 0 && !showStepper ? null : (
        <CardContent
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            backgroundColor: theme.palette.background.paper
          }}>
          <MobileStepper
            variant='dots'
            sx={{ flexGrow: 1, background: 'none' }}
            position='static'
            steps={l3Steps.length}
            activeStep={activeStep}
            backButton={
              <BackIconButton
                disabled={activeStep === 0 && firstL2Step}
                onClick={() => handlePrevious()}
              />
            }
            nextButton={
              <NextIconButton onClick={() => handleNext()} />
            }></MobileStepper>
        </CardContent>
      )}
      <OverlayLoading open={loading} />
      <ConfirmCancelDialog
        open={pulseSurveyPromptOpen}
        titleText={t('pathsAndModels.getInstantFeedback')}
        confirmText={t('pathsAndModels.sendPulseSurvey')}
        cancelText={t('common.noThanks')}
        buttonsDirection='row'
        onClose={handlePulseSurveyPropmptClose}>
        <InCardStack sx={{ paddingBottom: 6 }}>
          <FeedbackBlueIcon
            iconVariant='large'
            sx={{ width: 100, height: 100 }}
          />
          <Typography color={colors.Aluminium}>
            {t('pathsAndModels.partWayThroughLearning.part1')}
            <strong>{t('pathsAndModels.partWayThroughLearning.part2')}</strong>
            {t('pathsAndModels.partWayThroughLearning.part3')}
          </Typography>
          <Typography color={colors.Aluminium}>
            {t('pathsAndModels.useThisQuestion')}
          </Typography>
          <Typography color={colors.Aluminium} fontWeight='bold'>
            {l3Steps[activeStep]?.pulseSurveyQuestion?.questionText}
          </Typography>
        </InCardStack>
      </ConfirmCancelDialog>
    </Card>
  )
}

export default StepContentsCard
