import { useEffect, useState } from 'react'
import { Alert } from '@mui/material'
import SimplePage from 'components/templates/SimplePage'
import { useSearchParams } from 'react-router-dom'
import { getRequest } from 'utils/api'
import {
  ASSESSMENT_REPORT_PUBLIC_URL,
  ASSESSMENT_BY_GOAL_URL
} from 'services/constants'
import { refreshAssessmentDataSubject } from 'services/fullAssessmentServices'
import { getLocalUser } from 'utils/localStorage'
import FullReport from 'components/organisms/assessments/fullAssessment/FullReport'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useTranslation } from 'react-i18next'

const AssessmentReport = () => {
  const { t } = useTranslation()
  const user = getLocalUser()
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const [assessmentData, setAssessmentData] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const token = searchParams.get('t')

  const fetchDataByToken = async data => {
    setLoading(true)
    const res = await getRequest(`${ASSESSMENT_REPORT_PUBLIC_URL}/${data.id}`, {
      token: data.pt
    })
    if (res.status === 200) {
      setAssessmentData(res.data.data)
      setErrorMessage('')
    } else {
      setErrorMessage(res.data.message)
    }
    setLoading(false)
  }

  const fetchDataByLoggedUser = async () => {
    setLoading(true)
    const res = await getRequest(ASSESSMENT_BY_GOAL_URL, {
      goal_id: user.userStates.currentGoalId
    })
    if (res.status === 200) {
      setAssessmentData(res.data.data)
    } else {
      setErrorMessage(res.data.message)
    }
    setLoading(false)
  }

  const getAssessmentData = async () => {
    const data = token ? JSON.parse(atob(token)) : null
    if (data?.id && data?.pt) {
      fetchDataByToken(data)
    } else if (user?.userStates?.currentGoalId) {
      fetchDataByLoggedUser()
    } else {
      setErrorMessage(t('fullReport.reportNotFound'))
    }
  }

  useEffect(() => {
    getAssessmentData()

    // Subscribe to refresh Assessment Data
    const subscription = refreshAssessmentDataSubject.subscribe(resp => {
      getAssessmentData()
    })

    // Cleanup subscription on unmount
    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SimplePage maxWidth={'100%'}>
      {assessmentData && (
        <FullReport
          assessment={assessmentData}
          user={assessmentData.user || user}
        />
      )}
      {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
      <OverlayLoading open={loading} />
    </SimplePage>
  )
}

export default AssessmentReport
