import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import {
  getLocalUser,
  getPopupConfig,
  setPopupConfig
} from 'utils/localStorage'
import { useLocation, useNavigate } from 'react-router-dom'
import MyTeamPage from 'components/templates/navigationPageTemplates/MyTeamPage'
import CheckInSurveyLanding from 'components/molecules/myTeam/CheckInSurveyLanding'
import CheckInSurvey from 'components/molecules/myTeam/CheckInSurvey'
import { getCalendarEvent } from 'services/userConnectionsHelper'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { getConstant } from 'services/constantServices'
import { CONSTANTS_OPTIONS } from 'services/constants'
import { MY_MEETING, MY_TEAM } from 'routes/constants'
import moment from 'moment'

const popupType = 'checkInSurveyIsSeen'

const CheckInSurveyPage = () => {
  const navigate = useNavigate()
  const [isLandingPage, setIsLandingPage] = useState(false)
  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState([])

  const location = useLocation()
  const { userCalendarEventId } = location?.state

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const calendarEvent = await getCalendarEvent(userCalendarEventId)
      if (calendarEvent) {
        const user = getLocalUser()
        const isOwner = user.id === calendarEvent.userId
        const notOpened =
          moment.utc(calendarEvent.startTime).subtract(1, 'day') > moment() ||
          calendarEvent.isClosed
        if (calendarEvent.surveyResults?.length || notOpened || isOwner) {
          navigate(MY_MEETING, {
            state: { userCalendarEventId, deepLink: true }
          })
        }
      } else {
        navigate(MY_TEAM)
      }
      const res = await getConstant(
        CONSTANTS_OPTIONS.ONE_ON_ONE_SURVEY_EVALUATIONS
      )
      if (res) {
        setQuestions(res)
      }
      setLoading(false)
    }

    setIsLandingPage(!getPopupConfig(popupType))
    setPopupConfig(popupType, true)
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MyTeamPage>
      <OverlayLoading open={loading} />
      <Box paddingY={2} />
      {isLandingPage ? (
        <CheckInSurveyLanding handleBegin={() => setIsLandingPage(false)} />
      ) : (
        <CheckInSurvey
          userCalendarEventId={userCalendarEventId}
          questions={questions}
        />
      )}
      <Box paddingY={2} />
    </MyTeamPage>
  )
}

export default CheckInSurveyPage
