const searchParameters = [
  ['firstName', 'lastName'],
  ['first_name', 'last_name'],
  'fullName',
  'email'
]


export const isMatchingUser = (user, query, searchParams=searchParameters) => {
  const q = query.toLowerCase()

  if (q.length === 0) { // Don't search on empty query
    return true
  }

  let isMatched = false

  for (let p of searchParams) {
    if (Array.isArray(p)) {
      for (let x of p) {
        isMatched ||= user[x]?.toLowerCase().indexOf(q) > -1
      }

      isMatched ||= p.map(x => user[x]).join(" ").toLowerCase().indexOf(q) > -1
    } else {
      isMatched ||= user[p]?.toLowerCase().indexOf(q) > -1
    }
  }

  return isMatched
}
