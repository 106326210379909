import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  Stack,
  Box
} from '@mui/material'
import { SuccessAccentIcon, TaskSuccessIcon } from 'components/atoms/Icons'
import StyledButton from 'components/atoms/button/StyledButton'
import colors from 'styles/colors'

const SuccessfulNotificationDialog = ({
  titleText,
  open,
  notificationText,
  buttonAction,
  confirmButtonText,
  updatedStyle
}) => {
  return updatedStyle ? (
    <Dialog onClose={buttonAction} open={open}>
      <Stack
        width={520}
        alignItems='center'
        spacing={10}
        sx={{paddingX: 16, paddingY: 20}}>
        <SuccessAccentIcon />
        <Typography variant='h1' textAlign='center'>
          {titleText}
        </Typography>
        <Typography textAlign='center' variant='body1' color={colors.Grey400}>
          {notificationText}
        </Typography>
        <StyledButton onClick={() => buttonAction()}>
          {confirmButtonText}
        </StyledButton>
      </Stack>
    </Dialog>
  ) : (
    <Dialog onClose={buttonAction} open={open}>
      <Stack width='430px' alignItems='center' spacing={5}>
        <TaskSuccessIcon />
        <DialogTitle variant='h3' textAlign='center'>
          {titleText}
        </DialogTitle>
        <Box width='320px'>
          <Typography align='center' textAlign='center'>
            {notificationText}
          </Typography>
        </Box>
        <Button onClick={() => buttonAction()}>{confirmButtonText}</Button>
      </Stack>
    </Dialog>
  )
}

export default SuccessfulNotificationDialog
