import { Box, TextField, Typography } from '@mui/material'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

// A simple notes card.
//
// Ensure setNotes does not trigger a re-render on this component, otherwise
// the cursor will consistently lose focus.
const RPNotes = ({ notes, setNotes }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Typography fontWeight={600}>{t('common.notes')}</Typography>
      <TextField
        type='text'
        multiline
        value={notes}
        rows={12}
        placeholder={t('rolePlay.notesPlaceholder')}
        onChange={e => setNotes(e.target.value)}
        InputProps={{
          sx: {
            borderRadius: 4,
            backgroundColor: colors.White,
            fontSize: 14,
            height:280,
            minWidth: '100%'
          }
        }}
        sx={{ width: '100%' }}
      />
    </Box>
  )
}

export default RPNotes
