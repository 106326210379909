import React, { useEffect, useState } from 'react'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { Container, Divider, Stack } from '@mui/material'
import NotificationSettings from 'components/organisms/user/NotificationSettings'
import CalendarSettings from 'components/organisms/user/CalendarSettings'
import LearningPathReminderSettings from 'components/organisms/user/LearningPathReminderSettings'
import { getStates } from 'services/userServices'

const UserSettings = () => {
  const [userStates, setUserStates] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      const resData = await getStates()
      setUserStates(resData)
    }
    fetchData()
  }, [])
  return (
    <HomePage>
      <Container maxWidth='sm'>
        <Stack padding={0} spacing={10} paddingBottom={20}>
          <LearningPathReminderSettings userStates={userStates} />
          <Divider />
          <CalendarSettings />
          <Divider />
          <NotificationSettings userStates={userStates} />
        </Stack>
      </Container>
    </HomePage>
  )
}

export default UserSettings
