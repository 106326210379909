import { Box, Typography, useTheme } from '@mui/material'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import InCardStack from 'components/atoms/InCardStack'
import colors from 'styles/colors'

const InfoCard = ({
  icon,
  title,
  content,
  children
}) => {
  const theme = useTheme()

  return (
    <BoxWithBackground
      height={280}
      width={222}
      borderRadius={3}
      boxShadow='0px 1px 15px 0px rgba(0, 0, 0, 0.10)'
      marginTop={10}
      backgroundColor={theme.palette.background.paper}
      paddingX={12}
      paddingY={16}
    >
      <InCardStack spacing={0} justifyContent='flex-start'>
        <Box paddingBottom={1}>{icon}</Box>
        <Typography
          variant='h5'
          sx={{ textAlign: 'center', textWrap: 'nowrap' }}
        >
          {title}
        </Typography>
        <Typography
          variant='body1'
          sx={{ textAlign: 'center', paddingTop: 4, color: colors.Grey400 }}
        >
          {content}
        </Typography>
        {children}
      </InCardStack>
    </BoxWithBackground>
  )
}

export default InfoCard
