import CardCarousel from 'components/molecules/CardCarousel'
import { v4 as uuidv4 } from 'uuid'
import RolePlayCard from 'components/molecules/rolePlay/RolePlayCard'

const RolePlayCarousel = ({ rolePlays, setNewRolePlay, virtueId = null }) => {
  return (
    <CardCarousel
      cards={
        rolePlays
          .filter(d => !virtueId || d.virtue.id === virtueId)
          .map(d => (
            <RolePlayCard
              key={uuidv4()}
              data={d}
              setNewRolePlay={setNewRolePlay}
            />
          ))
      }
    />
  )
}

export default RolePlayCarousel
