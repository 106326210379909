import { TableCell } from '@mui/material'

const PercentageCell = ({ value, roundDigit = 0, ...otherProps }) => {
  return (
    <TableCell {...otherProps}>
      {value ? `${(value * 100).toFixed(roundDigit)}%` : null}
    </TableCell>
  )
}

export default PercentageCell
