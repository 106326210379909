import { useEffect, useState } from 'react'
import { Container, Typography } from '@mui/material'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { permitted } from 'utils/permissionCheck'
import { ABOUT_360, ONBOARDING, PERMISSION_LEVEL } from 'routes/constants'
import InCardStack from 'components/atoms/InCardStack'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ASSESSMENT_STATUSES } from 'services/constants'
import {
  getAssessmentProgress,
  getAssessmentByGoal,
  getTeamData,
  refreshAssessmentDataSubject
} from 'services/fullAssessmentServices'
import colors from 'styles/colors'
import { getLocalUser } from 'utils/localStorage'
import { WavingHandIcon, ReportImg } from 'components/atoms/Icons'
import moment from 'moment'
import AssessmentResultSummary from 'components/molecules/assessments/fullAssessment/AssessmentResultSummary'
import TeamProgress from 'components/molecules/assessments/fullAssessment/TeamProgress'
import AssessmentHead from 'components/molecules/assessments/fullAssessment/AssessmentHead'
import { Card, CardStack } from 'components/atoms/container/BoxCard'
import StyledButton from 'components/atoms/button/StyledButton'

const FullAssessmentHome = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  moment.locale(i18n.resolvedLanguage)
  const user = getLocalUser()
  const isAdmin =
    permitted(PERMISSION_LEVEL.SUPER_ADMIN) ||
    permitted(PERMISSION_LEVEL.ADMINISTRATOR) ||
    permitted(PERMISSION_LEVEL.COACH)
  const hasLicense = permitted(PERMISSION_LEVEL.LICENSED)
  const [fullAssessment, setFullAssessment] = useState(null)
  const [assessmentData, setAssementData] = useState(null)
  const [isClosed, setIsClosed] = useState(true)
  const [closingTimeStamp, setClosingTimeStamp] = useState(null)
  const [loading, setLoading] = useState(false)
  const [greeting, setGreeting] = useState('')
  const [showDashboard, setShowDashboard] = useState(false)
  const [teamData, setTeamData] = useState(null)
  const [completionRate, setCompletionRate] = useState(0)
  const [participants, setParticipants] = useState(0)
  const [completedAssessments, setCompletedAssessments] = useState(0)

  const getAssessmentDataByGoal = async () => {
    const assessmentRes = await getAssessmentByGoal(
      user?.userStates?.currentGoalId
    )
    if (assessmentRes) {
      setAssementData(assessmentRes)
    }
  }

  const fetchAssessmentData = async () => {
    setLoading(true)
    // Fetch assessment progress
    const res = await getAssessmentProgress()
    if (res) {
      if (
        res[0]?.progress === 1 &&
        res[0]?.status === ASSESSMENT_STATUSES.OPEN
      ) {
        res[0].progress = 0.99
      }
      setFullAssessment(res[0])
    }

    // Check if assessment is closed
    const isAssessmentClosed = res?.[0]?.status === ASSESSMENT_STATUSES.CLOSED
    setIsClosed(isAssessmentClosed)
    setClosingTimeStamp(res?.[0]?.updatedAt)

    // Fetch assessment data if assessment is closed
    if (isAssessmentClosed) {
      await getAssessmentDataByGoal()
    }
    setLoading(false)
  }

  const fetchTeamStats = async () => {
    // Fetch Team Progress data
    const res = await getTeamData(user.organizationId)
    setTeamData(res?.team)
    setCompletionRate(res?.completionRate)
    setParticipants(res?.participants)
    setCompletedAssessments(res?.completedAssessments)
  }

  const setCurrentGreeting = () => {
    const currentHour = moment().hour()
    let newGreeting = ''
    if (currentHour < 12) {
      newGreeting = t('fullReport.goodMorning')
    } else if (currentHour < 18) {
      newGreeting = t('fullReport.goodAfternoon')
    } else {
      newGreeting = t('fullReport.goodEvening')
    }
    setGreeting(newGreeting)
  }

  useEffect(() => {
    if (hasLicense) {
      if (!user.onboardingAnswers) {
        navigate(ONBOARDING)
      } else {
        fetchAssessmentData()
        setCurrentGreeting()

        if (isAdmin) {
          fetchTeamStats()
        }
      }
    } else if (isAdmin) {
      fetchTeamStats()
      setShowDashboard(true)
    }

    // Subscribe to refresh Assessment Data
    const subscription = refreshAssessmentDataSubject.subscribe(() => {
      getAssessmentDataByGoal()
    })

    // Cleanup subscription on unmount
    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {showDashboard ? (
        <TeamProgress
          teamData={teamData}
          completionRate={completionRate}
          participants={participants}
          completedAssessments={completedAssessments}
          setShowDashboard={setShowDashboard}
          showBackButton={hasLicense}
        />
      ) : (
        <Container>
          <OverlayLoading open={loading} />
          <InCardStack spacing={12} marginTop={20}>
            <Typography variant='h1' color={colors.Teal400}>
              {greeting}, {user.fullName}
              <WavingHandIcon sx={{ fontSize: 32 }} />
            </Typography>
            {fullAssessment && (
              <>
                <AssessmentHead
                  assessmentProgress={fullAssessment}
                  isClosed={isClosed}
                  assessmentData={assessmentData}
                  closingTimeStamp={closingTimeStamp}
                  setShowDashboard={setShowDashboard}
                  isAdmin={isAdmin}
                  completionRate={completionRate}
                  participants={participants}
                  completedAssessments={completedAssessments}
                />
              </>
            )}

            {!isClosed && (
              <Card
                backgroundColor={colors.MutedTeal50}
                borderColor={colors.MutedTeal300}
                style={{ marginBottom: 24 }}>
                <CardStack spacing={12} justifyContent={'center'}>
                  <Typography variant='h2'>
                    {t('fullReport.reportOnWay')}
                  </Typography>

                  <Typography
                    variant='body1'
                    color={colors.Grey400}
                    style={{ marginTop: 4 }}
                    width={'40%'}>
                    {t('fullReport.getComprehensiveReport')}
                  </Typography>

                  <StyledButton
                    inverseTheme={true}
                    onClick={() => navigate(ABOUT_360)}>
                    {t('headers.about360')}
                  </StyledButton>
                </CardStack>

                <ReportImg />
              </Card>
            )}

            {assessmentData && isClosed && (
              <AssessmentResultSummary
                data={assessmentData}
                closingTimeStamp={closingTimeStamp}
              />
            )}
          </InCardStack>
        </Container>
      )}
    </>
  )
}
export default FullAssessmentHome
