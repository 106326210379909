import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { Dialog, alpha, Typography, CircularProgress } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import InCardStack from 'components/atoms/InCardStack'
import {
  USER_CONNECTION_EVENT_TYPES,
  CALENDAR_RECURRENCE_FREQ,
  USER_CONNECTION_EVENTS_URL,
} from 'services/constants'
import { getLocalUser } from 'utils/localStorage'
import { desctructUser } from 'utils/formatText'
import { useTranslation } from 'react-i18next'
import StyledButton from 'components/atoms/button/StyledButton'
import { isAllowedFeature } from 'utils/permissionCheck'
import { useFormik } from 'formik'
import FormInputBox from 'components/atoms/input/FormInputBox'
import colors from 'styles/colors'
import { validationsRules } from 'utils/formValidations'
import { getUserConnections } from 'services/userServices'
import { trackEvent } from 'services/amplitudeTracking'
import ConnectionAutocomplete from 'components/molecules/myTeam/ConnectionAutocomplete'
import FormDatePicker from 'components/atoms/input/FormDatePicker'
import moment from 'moment'
import { postRequest } from 'utils/api'
import RadioTags from 'components/atoms/RadioTags'
import StartEndTimePicker from 'components/molecules/myTeam/StartEndTimePicker'
import CalendarSetup from 'components/molecules/myTeam/CalendarSetup'
import LocationAutocomplete from 'components/molecules/myTeam/LocationAutocomplete'
import SuccessfulNotificationDialog from 'components/molecules/notificationOverlay/SuccessfulNotificationDialog'
import NotificationSnackBar from 'components/molecules/notificationOverlay/NotificationSnackBar'

const ColStack = ({ children, ...otherProps }) => (
  <InCardStack alignItems={'flex-start'} width={'100%'} {...otherProps}>
    {children}
  </InCardStack>
)

const BookOneOnOne = ({
  userConnectionId,
  connectionName,
  buttonLabel,
  fetchedUserConnections,
  userConnectionEmail,
  buttonProps = {}
}) => {
  const { t } = useTranslation()
  const [openDialog, setOpenDialog] = useState(false)
  const [userConnections, setUserConnections] = useState([])
  const [loading, setLoading] = useState(false)
  const [firstName, ,] = desctructUser(getLocalUser())
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [openErrorSnack, setOpenErrorSnack] = useState(false)

  const hideDialog = () => {
    setOpenErrorSnack(false)
    setOpenDialog(false)
    formik.resetForm()
  }

  const handleSubmit = e => {
    e.preventDefault()
    formik.submitForm()
  }

  const formik = useFormik({
    initialValues: {
      participant: null,
      title: '',
      type: USER_CONNECTION_EVENT_TYPES.ONE_TO_ONE,
      location: '',
      startTime: moment(),
      start: '',
      durationMinutes: '',
      videoConference: false,
      frequency: CALENDAR_RECURRENCE_FREQ.WEEKLY,
      'recurrence.until': ''
    },
    validateOnBlur: false,
    validationSchema: yup.object({
      participant: yup
        .object()
        .shape({
          email: validationsRules.email
        })
        .nullable()
        .test(
          'email-required',
          t('shareMyResults.recpEmailValidation'),
          function (value) {
            return userConnectionId || value?.email
          }
        ),
      title: validationsRules.textRequired,
      type: validationsRules.textRequired,
      startTime: validationsRules.dateRequired,
      durationMinutes: validationsRules.timeRangeRequired
    }),
    onSubmit: async values => {
      // Parse the time string
      const time = moment(values.start, 'hh:mma')

      // Replace the time in values.startTime with the time from values.start
      const updatedStartTime = values.startTime.set({
        hour: time.hour(),
        minute: time.minute()
      })

      const requestBody = {
        userConnectionId: userConnectionId ?? values.participant.id,
        title: values.title,
        type: values.type,
        startTime: updatedStartTime,
        durationMinutes: values.durationMinutes,
        recurrence: {
          frequency: values.frequency
        },
        location: values.location,
        videoConference: values.videoConference,
        timeZone: moment.tz.guess()
      }

      if (!requestBody.userConnectionId) {
        requestBody.email = values.participant.email
        requestBody.relationship = values.participant.relationship
      }

      setLoading(true)
      const res = await postRequest(USER_CONNECTION_EVENTS_URL, requestBody)
      if (res?.status === 201) {
        hideDialog()
        setSuccessDialogOpen(true)

        const inviteeEmail = userConnectionEmail
          ? userConnectionEmail
          : values?.participant?.email

        trackEvent('Schedule Meeting', {
          userConnectionId: userConnectionId ?? values.participant.id,
          type: values.type,
          startTime: updatedStartTime.toISOString(),
          durationMinutes: values.durationMinutes,
          recurrence: values.frequency,
          location: values.location,
          videoConference: values.videoConference,
          inviteeEmail: inviteeEmail,
        })
      } else {
        setOpenErrorSnack(true)
      }
      setLoading(false)
    }
  })

  const fetchData = async () => {
    setLoading(true)
    let res = fetchedUserConnections
    if (!res) {
      res = await getUserConnections()
    }
    if (res.length) {
      setUserConnections(res.filter(u => u.relationship))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (openDialog && !userConnectionId && !userConnections.length) {
      fetchData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog, userConnectionId])

  const setTitle = () => {
    if (connectionName) {
      formik.setFieldValue(
        'title',
        `${firstName} / ${connectionName} ${t('contact.ONE_TO_ONE')}`
      )
    } else if (formik.values.participant?.firstName) {
      formik.setFieldValue(
        'title',
        `${firstName} / ${formik.values.participant?.firstName} ${t('contact.ONE_TO_ONE')}`
      )
    }
  }

  useEffect(() => {
    setTitle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog, formik.values.participant])

  const handleTimeChange = (start, end) => {
    if (start && end) {
      // if start is less than end set error and vice versa
      if (
        moment(start, 'hh:mma').isAfter(moment(end, 'hh:mma')) ||
        moment(start, 'hh:mma').isSame(moment(end, 'hh:mma'))
      ) {
        formik.setFieldValue('durationMinutes', '')
        formik.setFieldTouched('durationMinutes', true)
        return
      }

      const duration = moment
        .duration(moment(end, 'hh:mma').diff(moment(start, 'hh:mma')))
        .asMinutes()
      formik.setFieldValue('start', start)
      formik.setFieldValue('durationMinutes', duration)
      formik.setFieldError('durationMinutes', '')
    }
  }

  const { sx: buttonSx, ...rest } = buttonProps
  return (
    <>
      <StyledButton
        onClick={() => setOpenDialog(true)}
        {...rest}
        sx={{ textWrap: 'nowrap', ...buttonSx }}>
        {buttonLabel || t('contact.bookOneOnOne')}
      </StyledButton>

      <Dialog
        onClose={hideDialog}
        open={openDialog}
        sx={{
          '& .MuiDialog-paper': {
            width: 450,
            paddingX: 12,
            paddingY: 14
          }
        }}>
        <form onSubmit={handleSubmit}>
          <ColStack>
            {!userConnectionId && (
              <ConnectionAutocomplete
                connections={userConnections}
                formik={formik}
                initialValue={formik.values.participant}
                attributeName='participant'
                placeholder={t('contact.participants')}
              />
            )}

            <ColStack spacing={5}>
              <FormInputBox
                sx={{ width: '100%' }}
                InputProps={{
                  sx: { fontSize: '24px', paddingY: 2.5 }
                }}
                formik={formik}
                attributeName={'title'}
                placeholder={t('contact.title')}
                variant='standard'
              />

              <RowStack spacing={8}>
                <RadioTags
                  tags={Object.keys(USER_CONNECTION_EVENT_TYPES).map(key => ({
                    key,
                    value: USER_CONNECTION_EVENT_TYPES[key],
                    label: t(`contact.${key}`)
                  }))}
                  selectedTag={formik.values.type}
                  setSelectedTag={tag => formik.setFieldValue('type', tag)}
                />

                <Typography
                  sx={{
                    color: alpha(colors.DarkGrey, 0.5),
                    fontSize: 13,
                    lineHeight: '16px',
                    fontStyle: 'italic'
                  }}>
                  {t('contact.additionalTypesComingSoon')}
                </Typography>
              </RowStack>
            </ColStack>

            <RowStack>
              <FormDatePicker
                formik={formik}
                attributeName='startTime'
                minDate={moment()}
                inputVariant='standard'
                sx={{
                  width: '100%',
                  '& .MuiInput-root': { paddingY: 2.5 }
                }}
                format='MMMM DD, YYYY'
                TextFieldProps={{
                  variant: 'standard',
                  placeholder: t('contact.date')
                }}
              />

              <StartEndTimePicker
                timeChange={handleTimeChange}
                error={
                  formik.touched.durationMinutes &&
                  t(formik.errors.durationMinutes)
                }
              />
            </RowStack>

            <RadioTags
              tags={Object.keys(CALENDAR_RECURRENCE_FREQ).map(key => ({
                key,
                value: key,
                label: t(`contact.${key}`)
              }))}
              selectedTag={formik.values.frequency}
              setSelectedTag={tag => formik.setFieldValue('frequency', tag)}
            />

            <LocationAutocomplete formik={formik} attributeName='location' />
            <CalendarSetup formik={formik} />

            <RowStack justifyContent='flex-end' spacing={4}>
              <StyledButton
                onClick={hideDialog}
                inverseTheme={true}
                inverseColor={colors.Grey400}
                disabled={loading}
                sx={{ borderWidth: 0 }}>
                {t('common.cancel')}
              </StyledButton>
              <StyledButton type={'submit'} disabled={loading}>
                {loading && <CircularProgress color='inherit' size={20} />}
                {t('common.save')}
              </StyledButton>
            </RowStack>

            <NotificationSnackBar
              open={openErrorSnack}
              setOpen={setOpenErrorSnack}
              error={true}
              errorText={t('contact.eventError')}
              autoHideDuration={3000}
            />
          </ColStack>
        </form>
      </Dialog>
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={t('contact.eventSuccess')}
        confirmButtonText={t('common.done')}
        buttonAction={() => setSuccessDialogOpen(false)}
      />
    </>
  )
}

export default BookOneOnOne
