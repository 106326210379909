import React from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import colors from '../../../styles/colors'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

const PasswordValidation = ({ formik }) => {
  const { t } = useTranslation()

  const checkPasswordRequirements = () => {
    const { password } = formik.values
    return [
      {
        valid: /.*[a-zA-Z].*/.test(password),
        label: t('authPages.leastOneAlphabetCharacter')
      },
      { valid: /\d/.test(password), label: t('authPages.leastOneDigit') },
      {
        valid: /(?=.*[!@#$%^&*+\-_[\]()=])/.test(password),
        label: t('authPages.leastOneSpecialCharacter')
      },
      { valid: password.length >= 8, label: t('authPages.least8Characters') }
    ]
  }
  const requirements = checkPasswordRequirements()

  return (
    ((formik.touched['password'] || formik.values['password'])
      && !requirements.reduce((acc, x) => acc && x.valid, true)
    ) ? (
      <Box
        sx={{
          position: 'absolute',
          backgroundColor: colors.White,
          borderRadius: '0 0 1rem 1rem',
          boxShadow: '0px 10px 15px 3px rgba(0, 0, 0, 0.10)',
          top: '100%',
          paddingX: 10,
          width: 408,
          zIndex: 10
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemText
              primary={t('authPages.passwordMustHave')}
              primaryTypographyProps={{
                color: colors.Aluminium,
                variant: 'subtitle2'
              }}
            />
          </ListItem>
          {requirements.map((value, index) => (
            <ListItem key={index} disablePadding>
              <ListItemIcon>
                {value.valid ? (
                  <DoneIcon style={{ color: colors.BrightGreen }} />
                ) : (
                  <CloseIcon style={{ color: colors.Terracotta }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={value.label}
                primaryTypographyProps={{
                  color: value.valid ? colors.BrightGreen : colors.Terracotta,
                  variant: 'subtitle2'
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    ) : null
  )
}

export default PasswordValidation
