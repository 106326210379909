import { Grid } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { ActionText, PageHeader } from 'components/atoms/Text'
import { useEffect, useState } from 'react'
import { getFullName } from 'utils/formatText'
import { getLocalUser } from 'utils/localStorage'
import ConnectionUserItem from './ConnectionUserItem'
import { useTranslation } from 'react-i18next'

const TeamList = ({ connections, roleList, filterText, meList = false }) => {
  const { t } = useTranslation()
  const [displayConnections, setDisplayConnections] = useState(connections)
  const [displayCount, setDisplayCount] = useState(meList ? 4 : 6)
  useEffect(() => {
    if (roleList) {
      const list = connections.filter(
        c =>
          getFullName(c.user, true).toLowerCase().indexOf(filterText) >= 0 &&
          roleList.indexOf(c.relationship) >= 0
      )
      setDisplayConnections(list)
    }
  }, [connections, filterText, roleList])

  const SidePanel = ({ startIndex = 0 }) => (
    <Grid item md={4}>
      <InCardStack width='100%' height='100%' justifyContent='center'>
        {displayConnections[startIndex] ? (
          <ConnectionUserItem c={displayConnections[startIndex]} />
        ) : null}
        {displayConnections[startIndex + 2] ? (
          <ConnectionUserItem c={displayConnections[startIndex + 2]} />
        ) : null}
      </InCardStack>
    </Grid>
  )
  return (
    <>
      <Grid container spacing={5}>
        {meList
          ? [
              <SidePanel key='melist-left-panel' />,
              <Grid
                item
                md={4}
                key='melist-center-panel'
                alignItems='center'
                justifyContent='center'
                display='flex'>
                <InCardStack spacing={2}>
                  <ProfileAvatar user={getLocalUser()} size='large' />
                  <PageHeader>{t('myTeam.me')}</PageHeader>
                </InCardStack>
              </Grid>,
              <SidePanel startIndex={1} key='melist-right-panel' />
            ]
          : null}
        {!meList || displayConnections.length > 4
          ? displayConnections.slice(meList ? 4 : 0, displayCount).map(c => (
              <Grid item md={4} key={`connection-item-${c.id}`}>
                <ConnectionUserItem c={c} />
              </Grid>
            ))
          : null}
      </Grid>
      {displayConnections.length > displayCount ? (
        <ActionText onClick={() => setDisplayCount(displayCount + 9)}>
          {t('myTeam.showMore')}
        </ActionText>
      ) : null}
    </>
  )
}

export default TeamList
