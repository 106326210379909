import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Alert, Snackbar, Button, MenuItem, Typography } from '@mui/material'
import { FEATURES_URL, FEATURE_STATUSES } from 'services/constants'
import { patchRequest } from 'utils/api'
import { featureValidationSchema } from 'utils/formValidations'
import FormSelectInput from 'components/atoms/input/FormSelectInput'
import InCardStack from 'components/atoms/InCardStack'

const FeatureState = ({ selectedFeature, setSelectedFeature }) => {
  const [requestSuccess, setRequestSuccess] = useState(undefined)

  useEffect(() => {
    if (typeof requestSuccess !== 'undefined') {
      const refreshTimeout = setTimeout(() => {
        setRequestSuccess(undefined)
      }, 2000)
      return () => clearTimeout(refreshTimeout)
    }
  }, [requestSuccess])

  const formik = useFormik({
    initialValues: {
      status: selectedFeature.status ?? ''
    },
    enableReinitialize: true,
    validationSchema: featureValidationSchema,
    onSubmit: async values => {
      const requestBody = {
        status: values.status
      }
      const res = await patchRequest(
        `${FEATURES_URL}/${selectedFeature.id}`,
        requestBody
      )
      if (res.data.success) {
        const newSelectedFeature = {...selectedFeature}
        newSelectedFeature.status = values.status
        setSelectedFeature(newSelectedFeature)
        setRequestSuccess(true)
      } else {
        setRequestSuccess(false)
      }
    }
  })

  const FieldLabel = ({ children }) => (
    <Typography
      variant='subtitle1'
      width='100%'
      marginBottom={3}
      display='flex'
      alignItems='center'>
      {children}
    </Typography>
  )

  return (
    <InCardStack direction='row'>
      <form onSubmit={formik.handleSubmit}>
        <FieldLabel>Status</FieldLabel>
        <FormSelectInput
          type='text'
          name='status'
          formik={formik}
          attributeName='status'>
          {Object.keys(FEATURE_STATUSES).map(key => (
            <MenuItem key={key} value={FEATURE_STATUSES[key]}>
                {key}
            </MenuItem>
          ))}
        </FormSelectInput>

        <Button type='submit' style={{ marginLeft: 10 }}>
          Save
        </Button>

        <Snackbar
          open={requestSuccess === true || requestSuccess === false}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          {requestSuccess === false ? (
            <Alert severity='error'>Request failed! Please try again.</Alert>
          ) : (
            <Alert severity='success'>Updated sucessfully</Alert>
          )}
        </Snackbar>
      </form>
    </InCardStack>
  )
}
export default FeatureState
