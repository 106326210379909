import { Close } from '@mui/icons-material'
import { Dialog, DialogTitle, IconButton, MenuItem, Stack } from '@mui/material'
import StyledButton from 'components/atoms/button/StyledButton'
import { StyledSelect } from 'components/atoms/input/StyledInputs'
import { useTranslation } from 'react-i18next'
import { ROLE_PLAY_TYPES } from 'services/constants'

const ScenarioSettingsDialog = ({
  handleClose,
  titleText,
  open,
  virtueList,
  selectedVirtueId,
  handleVirtueChange,
  selectedRolePlayId,
  setSelectedRolePlayId,
  rolePlays,
  practiceType,
  setPracticeType,
  onLearningPath
}) => {
  const { t } = useTranslation()
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {titleText}
        <IconButton
          sx={{ float: 'right', paddingTop: '2px' }}
          onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <Stack width='500px' sx={{ paddingTop: 0 }} spacing={5}>
        {!onLearningPath && (
          <StyledSelect value={selectedVirtueId} onChange={handleVirtueChange} name='virtue'>
            {virtueList.map(b => (
              <MenuItem value={b.id} key={`virtue-option-${b.id}`}>
                {b.name}
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        <StyledSelect
          value={selectedRolePlayId}
          onChange={e => setSelectedRolePlayId(e.target.value)} name='role-play' >
          {rolePlays
            .filter(r => r.virtueId === selectedVirtueId)
            .map(r => (
              <MenuItem value={r.id} key={`roleplay-option-${r.id}`}>
                {r.title}
              </MenuItem>
            ))}
        </StyledSelect>
        <StyledSelect
          value={practiceType}
          onChange={e => setPracticeType(e.target.value)} name='type'>
          <MenuItem value={ROLE_PLAY_TYPES.TEXT_CHAT}>
            {t('rolePlay.textChat')}
          </MenuItem>
          <MenuItem value={ROLE_PLAY_TYPES.LIVE_CALL}>
            {t('rolePlay.liveCall')}
          </MenuItem>
        </StyledSelect>
        <StyledButton onClick={handleClose}>{t('common.ok')}</StyledButton>
      </Stack>
    </Dialog>
  )
}

export default ScenarioSettingsDialog
