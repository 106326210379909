import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState, useRef } from 'react'
import { register } from 'swiper/element'
import RowStack from 'components/atoms/container/RowStack'
import RoundButton from 'components/atoms/button/RoundButton.js'
import EastIcon from '@mui/icons-material/East'
import WestIcon from '@mui/icons-material/West'

const CardCarousel = ({ cards, customWidth = 270, paddingX = 12 }) => {
  const [swiperSlides, setSwiperSlides] = useState(null)
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [isScrollable, setIsScrollable] = useState(true)
  const swiperRef = useRef(null)

  useEffect(() => {
    register()

    const params = {
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 0,
      on: {
        resize: e => {
          setIsScrollable(e.allowSlideNext && e.allowSlidePrev)
        },
        slideChange: e => {
          setIsPrevDisabled(e.isBeginning)
          setIsNextDisabled(e.isEnd)
        },
        slidesUpdated: e => {
          setIsScrollable(e.allowSlideNext && e.allowSlidePrev)
          setIsPrevDisabled(e.isBeginning)
          setIsNextDisabled(e.isEnd)
        },
        reachEnd: () => setIsNextDisabled(true)
      }
    }

    Object.assign(swiperRef.current, params)
    swiperRef.current.initialize()
  }, [swiperRef, setIsPrevDisabled, setIsNextDisabled, setIsScrollable])

  useEffect(() => {
    const newSwiperSlides = genSlides(cards)
    setSwiperSlides(newSwiperSlides)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards])

  const genSlides = cards => {
    return cards.length > 0
      ? cards.map((card, i) => (
          <swiper-slide
            style={{
              width: customWidth,
              flexShrink: 0,
              display: 'block',
              height: '100%',
              maxHeight: '100%',
              paddingLeft: paddingX + (i === 0 ? 2 : 0), // For shadow
              paddingRight: paddingX
            }}
            key={card.key ?? uuidv4()}>
            {card}
          </swiper-slide>
        ))
      : [<swiper-slide key={uuidv4()}></swiper-slide>]
  }

  return (
    <Box
      minWidth={0}
      height={400}
      marginY={0}
      display='block'
      sx={{
        userSelect: 'none'
      }}>
      <swiper-container
        style={{
          minHeight: 0,
          minWidth: 0,
          height: '100%'
        }}
        init='false'
        ref={swiperRef}>
        {swiperSlides}
      </swiper-container>
      {isScrollable && (
        <RowStack justifyContent='flex-end' spacing={0} marginTop={-10}>
          <RoundButton
            disabled={isPrevDisabled}
            onClick={() => swiperRef.current.swiper.slidePrev()}
            number={<WestIcon />}
            size={60}
            filled={true}
          />
          <RoundButton
            disabled={isNextDisabled}
            onClick={() => swiperRef.current.swiper.slideNext()}
            number={<EastIcon />}
            size={60}
            filled={true}
          />
        </RowStack>
      )}
    </Box>
  )
}

export default CardCarousel
