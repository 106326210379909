import { CardContent, Divider, Typography } from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import InCardStack from 'components/atoms/InCardStack'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import FiveNumberOptions from 'components/molecules/FiveNumberOptions'
import { useState } from 'react'
import { PULSE_SURVEYS } from 'routes/constants'
import { PULSE_SURVEY_RECIPIENTS_URL } from 'services/constants'
import { patchRequest } from 'utils/api'
import {
  AttributeLabel,
  AttributeLabelValue,
  AttributeValue,
  PageHeader
} from 'components/atoms/Text'
import { formatDate } from 'utils/formatDateTime'
import { TaskSuccessIcon } from 'components/atoms/Icons'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import SinglePageStack from 'components/templates/SinglePageStack'
import { getFullName } from 'utils/formatText'
import { useTranslation } from 'react-i18next'

const PulseSurveyRatingCard = ({ surveyData }) => {
  const { t, i18n } = useTranslation()
  const [completed, setCompleted] = useState(false)
  const [requestError, setRequestError] = useState(undefined)
  const submitRating = async rating => {
    const res = await patchRequest(
      `${PULSE_SURVEY_RECIPIENTS_URL}/${surveyData.id}`,
      { score: rating }
    )
    if (res.status === 200) {
      setCompleted(true)
    } else {
      setRequestError(true)
    }
  }
  return (
    <SinglePageStack data-testid='pulse-survey-rating-card' maxWidth='sm'>
      <PageHeader>{t('pulseSurvey.surveyRequest')}</PageHeader>

      <CardContent sx={{ justifyContent: 'left' }}>
        <AttributeLabelValue
          label={t('common.date')}
          value={formatDate(surveyData.createdAt, i18n.resolvedLanguage)}
        />
      </CardContent>
      <CardContent sx={{ justifyContent: 'left', paddingTop: 0 }}>
        <AttributeLabel alignSelf='flex-end'>{t('common.from')}:</AttributeLabel>
        <ProfileAvatar user={surveyData.createdByUser} size='small' />
        <Divider orientation='vertical' width='5px' />
        <AttributeValue alignSelf='flex-end'>
          {getFullName(surveyData.createdByUser)}
        </AttributeValue>
      </CardContent>
      <Divider />
      <CardContent>
        <Typography>{surveyData.pulseSurveyQuestion}</Typography>
      </CardContent>
      {completed === false ? (
        <>
          <FiveNumberOptions onClick={rating => submitRating(rating)} />
          {requestError === true ? (
            <CardContent>
              <RequestRetryAlert />
            </CardContent>
          ) : null}
          <CardContent>
            <LinkButton to={PULSE_SURVEYS}>{t('pulseSurvey.backtoPulseSurvey')}</LinkButton>
          </CardContent>
        </>
      ) : (
        <>
          <CardContent>
            <InCardStack>
              <TaskSuccessIcon />
              <Typography variant='h3'>
                {t('pulseSurvey.yourResponseRecorded')}
              </Typography>
            </InCardStack>
          </CardContent>
          <CardContent>
            <LinkButton to={PULSE_SURVEYS}>{t('common.done')}</LinkButton>
          </CardContent>
        </>
      )}
    </SinglePageStack>
  )
}

export default PulseSurveyRatingCard
