import { track, setGroup, setUserId } from '@amplitude/analytics-browser'
import { getGoalNameById } from 'utils/getSupportingInfo'

export const registerWithAmplitude = user => {
  setUserId(user.email)
  setGroup('orgId', user.organizationId)
  setGroup('organization', user.organization?.name)
  setGroup('hasLicense', user.hasLicense)
  setGroup('licenseType', user.organization?.licenseType)
}

export const trackEvent = (eventName, eventProperties) => {
  track(eventName, eventProperties)
}

export const trackPathAction = async (
  goalId,
  eventName,
  otherEventProperties
) => {
  const pathName = await getGoalNameById(goalId)
  trackEvent(eventName, { pathName, ...otherEventProperties })
}
