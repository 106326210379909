import { Add } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  CardContent,
  Chip,
  Stack,
  Typography
} from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import LowFocusButton from 'components/atoms/button/LowFocusButton'
import InCardStack from 'components/atoms/InCardStack'
import UserConnectionForm from 'components/atoms/input/UserConnectionForm'
import { ActionText } from 'components/atoms/Text'
import ConfirmCancelDialog from 'components/molecules/notificationOverlay/ConfirmCancelDialog'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { HOME } from 'routes/constants'
import {
  ASSESSMENT_RECIPIENTS_URL,
  ASSESSMENT_BY_GOAL_URL,
  INVITED_LIST_URL
} from 'services/constants'
import { getRequest, postRequest } from 'utils/api'
import { TaskSuccessIcon } from 'components/atoms/Icons'
import { getUserConnections } from 'services/userServices'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { getFullName } from 'utils/formatText'
import OverlayLoading from 'components/atoms/OverlayLoading'
import AddRatersDescriptions from 'components/molecules/assessments/AddRatersDescriptions'
import { useTranslation } from 'react-i18next'

const AddRatersCard = ({ fromSelfAssessment, goalId }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [assessmentData, setAssessmentData] = useState(
    location.state?.assessmentData
  )
  const [existingRaters, setExistingRaters] = useState(
    location.state?.assessmentData?.raters || []
  )
  const navigate = useNavigate()
  const [dialogOpen, setDialogOpen] = useState(fromSelfAssessment || false)
  const [recipients, setRecipients] = useState([])
  const [raters, setRaters] = useState([])
  const [requestSucess, setRequestSucess] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [displayError, setDisplayError] = useState(false)
  const [loading, setLoading] = useState(false)

  const onDialogClose = () => {
    setDialogOpen(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      let fetchedRaters = existingRaters
      let previousRaters = assessmentData?.previousRaters

      if (!assessmentData) {
        const assessmentRes = await getRequest(ASSESSMENT_BY_GOAL_URL, {
          goal_id: goalId
        })
        if (assessmentRes.status === 200) {
          setAssessmentData(assessmentRes.data.data)
          fetchedRaters = assessmentRes.data.data.raters
          setExistingRaters(fetchedRaters)
          previousRaters = assessmentRes.data.data.previousRaters
        }
      }
      let connectionList = []
      const res = await getUserConnections()
      const resInvites = await getRequest(INVITED_LIST_URL, {
        profileOnly: true
      })
      if (res.length && resInvites.status === 200) {
        const connectedEmails = res
          .filter(r => r.id && !r.userId)
          .map(u => u.user.email)
        const invited = resInvites.data.data
          .filter(i => connectedEmails.indexOf(i.email) < 0)
          .map(i => ({ user: i, ...i }))

        const completeList = res.concat(invited)
        if (fetchedRaters > 0) {
          const selectedRaters = fetchedRaters.map(r => r.email)
          connectionList = completeList.filter(
            r => selectedRaters.indexOf(r.email) < 0
          )
        } else {
          connectionList = completeList
        }
      }

      // if assessment does not have any raters and has previous raters, populate raters
      if (previousRaters?.length > 0 && fetchedRaters?.length === 0) {
        previousRaters = previousRaters.map(r => {
          const foundConnection = connectionList.find(
            l => l.email === r.email
          )
          return foundConnection || r
        })
        if (previousRaters.length < 5) {
          previousRaters.push(...Array(5 - previousRaters.length).fill(null))
        }
      }
      setRecipients(connectionList)
      if (previousRaters?.length > 0 && fetchedRaters?.length === 0) {
        setRaters(previousRaters)
      } else {
        setRaters(Array(5).fill(null))
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRaterChange = (index, rater) => {
    const newRaters = [...raters]
    newRaters[index] = rater
    setRaters(newRaters)
    checkSubmittable(newRaters)
  }

  const addRaters = () => {
    const newRaters = [...raters]
    const emptyRaters = Array(3).fill(null)
    newRaters.push(...emptyRaters)
    setRaters(newRaters)
  }

  const submitRaters = async () => {
    const validData = checkSubmittable(raters)
    if (!validData) {
      setDisplayError(true)
      return
    }
    setLoading(true)
    const invitees = raters
      .map(r => {
        if (r?.email) {
          return {
            email: r.email,
            firstName: r.firstName ?? null,
            lastName: r.lastName ?? null,
            relationship: r.relationship
          }
        } else {
          return null
        }
      })
      .filter(r => r !== null)
    const requestBody = {
      goalId,
      invitees,
      action: 'rate_assessment'
    }

    const res = await postRequest(ASSESSMENT_RECIPIENTS_URL, requestBody)
    if (res.status === 200) {
      setRequestSucess(true)
    } else {
      setDisplayError(true)
      setErrorMessage(t('requestRetryAlert.message'))
    }
    setLoading(false)
  }

  const checkSubmittable = checkRaters => {
    const filtered = checkRaters.filter(r => r !== null && !r.error)
    const errorInput = checkRaters.find(r => r?.error)
    const missingRelationship = checkRaters.find(r => r && !r.relationship)

    if (filtered.length < 3 && existingRaters.length === 0) {
      setErrorMessage(
        t('addRaters.minimum3ConnValidation')
      )
      return false
    } else if (filtered.length === 0 || missingRelationship) {
      // When there are existing raters, allow sending <3 and >0 new raters
      setErrorMessage(t('addRaters.validConnError'))
      return false
    } else if (errorInput) {
      setErrorMessage(errorInput.errorMessage)
      return false
    } else {
      setErrorMessage('')
      return true
    }
  }

  const handleSelfAssessmentFinish = () => {
    navigate(HOME)
  }
  return (
    <CardContent data-testid='add-raters-card'>
      <OverlayLoading open={loading} />
      {requestSucess ? (
        <InCardStack
          sx={{ marginTop: '100px', maxWidth: '600px' }}
          spacing={15}>
          <TaskSuccessIcon size={80} />
          <Typography variant='h3'>{t('addRaters.emailsSent')}</Typography>
          <Typography variant='body2'>
            {t('addRaters.emailSuccessfullySent')}
          </Typography>
          {fromSelfAssessment ? (
            <>
              <Typography variant='body2'>
                {t('addRaters.notWaitForResults')}
              </Typography>
              <Box height='20px' />
              <Button onClick={() => handleSelfAssessmentFinish()}>
                {t('addRaters.startYourJourney')}
              </Button>
            </>
          ) : (
            <>
              <Box height='20px' />
              <LinkButton to={HOME}>{t('common.ok')}</LinkButton>
            </>
          )}
        </InCardStack>
      ) : (
        <Stack width='60%' minWidth='500px' spacing={5}>
          <AddRatersDescriptions />
          {!existingRaters?.length ? (
            <Typography variant='body2'>
              {t('addRaters.ratersNotSelected')}
            </Typography>
          ) : (
            <>
              <Typography
                variant='caption'
                color='primary'
                fontStyle='italic'
              >
                {t('addRaters.invitedRaters.part1')} {existingRaters.length} {t('addRaters.invitedRaters.part2')}
              </Typography>
              <Stack padding={0} spacing={2} width='100%'>
                {existingRaters.map(r => {
                  const name = getFullName(r)
                  let email = null
                  if (r.firstName) {
                    email = `(${r.email})`
                  }
                  return (
                    <Chip
                      key={r.email}
                      avatar={<ProfileAvatar user={r} size='small' />}
                      label={
                        <Typography fontSize='14px'>
                          {name}{' '}
                          <Typography variant='body2' component='span'>
                            {email}
                          </Typography>
                        </Typography>
                      }
                      sx={{
                        background: 'transparent',
                        justifyContent: 'left'
                      }}
                    />
                  )
                })}
              </Stack>
            </>
          )}

          <Typography variant='subtitle1' textAlign='right'>
            {t('addRaters.relationship')}
          </Typography>
          {raters.map((r, i) => (
            <UserConnectionForm
              key={i}
              index={i}
              connections={recipients}
              selected={raters}
              submitHandler={handleRaterChange}
              existingRaters={existingRaters}
            />
          ))}
          <ActionText
            display='flex'
            justifyContent='center'
            width='fit-content'
            onClick={() => addRaters()}>
            <Add color='primary' /> {t('addRaters.addMoreRaters')}
          </ActionText>
          <Box width='100%' height='50px'>
            {displayError && errorMessage.length > 0 ? (
              <Alert severity='error'>{errorMessage}</Alert>
            ) : null}
          </Box>
          <InCardStack
            direction='row'
            spacing={30}
            justifyContent='center'
            sx={{ marginTop: '25px !important' }}>
            {fromSelfAssessment ? (
              <Button onClick={() => handleSelfAssessmentFinish()}>
                {t('addRaters.skipForNow')}
              </Button>
            ) : (
              <LowFocusButton onClick={() => navigate(-1)}>
                {t('common.cancel')}
              </LowFocusButton>
            )}
            <Button onClick={() => submitRaters()}>{t('common.send')}</Button>
          </InCardStack>
        </Stack>
      )}
      <ConfirmCancelDialog
        titleText={t('addRaters.getMostFromResults')}
        open={dialogOpen}
        onClose={onDialogClose}
        confirmText={t('addRaters.getStarted')}
        notificationText={
          <InCardStack marginY='25px'>
            <Typography>
              {t('addRaters.nextStepToGetOthersRate')}
            </Typography>
            <Typography fontWeight='bold'>
              {t('addRaters.importantToNote')}
            </Typography>
            <Typography>
              {t('addRaters.rateAndCompare')}
            </Typography>
          </InCardStack>
        }
      />
    </CardContent>
  )
}

export default AddRatersCard
