import { firebaseConfig } from 'config/config'
import { usePlacesWidget } from 'react-google-autocomplete'
import FormInputBox from 'components/atoms/input/FormInputBox'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import { useTranslation } from 'react-i18next'

const LocationAutocomplete = ({ formik, attributeName }) => {
  const { t } = useTranslation()

  const { ref } = usePlacesWidget({
    apiKey: firebaseConfig.apiKey,
    onPlaceSelected: place =>
      formik.setFieldValue(attributeName, place?.formatted_address),
    options: {
      types: ['geocode'],
      componentRestrictions: { country: 'ca' }
    }
  })

  return (
    <FormInputBox
      inputRef={ref}
      sx={{ width: '100%' }}
      InputProps={{
        sx: { fontSize: '16px', paddingY: 2.5 }
      }}
      formik={formik}
      attributeName={attributeName}
      placeholder={t('contact.meetingLocation')}
      variant='standard'
      endAdornment={<FmdGoodOutlinedIcon />}
    />
  )
}

export default LocationAutocomplete
