import { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material'
import { Icon, IconButton, Stack, Typography, useTheme } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ActionText } from 'components/atoms/Text'
import ConfidentialityPopUp from 'components/molecules/notificationOverlay/ConfidentialityPopUp'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import InCardStack from 'components/atoms/InCardStack'
import ImageContainer from 'components/atoms/container/ImageContainer'
import { getPopupConfig, setPopupConfig } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'

const displayConfigKey = 'addRatersDescriptionOpen'

const AddRatersDescriptions = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [confidentialDialogOpen, setConfidentialDialogOpen] = useState(false)
  const [moreExpended, setMoreExpended] = useState(true)
  useEffect(()=> {
    const saved = getPopupConfig(displayConfigKey)
    if (saved === false){
      setMoreExpended(false)
    }
  },[])

  const DetailContainer = ({
    icon,
    iconAlt,
    title,
    description,
    size = 55,
    showClose = false
  }) => (
    <InCardStack direction='row' width='100%'>
      <InCardStack sx={{ minWidth: 75 }}>
        <Icon sx={{ width: size, height: size }}>
          <ImageContainer src={icon} alt={iconAlt} />
        </Icon>
      </InCardStack>
      <Stack padding={0} width='100%'>
        <Stack padding={0} direction='row' justifyContent='space-between'>
          <Typography variant='subtitle1' color='primary'>
            {title}
          </Typography>
          {showClose ? (
            <IconButton
              sx={{ marginTop: -14, marginRight: -10 }}
              onClick={() => setMoreExpended(false)}>
              <Close />
            </IconButton>
          ) : null}
        </Stack>
        <Typography>{description}</Typography>
      </Stack>
    </InCardStack>
  )

  useEffect(() => {
    setPopupConfig(displayConfigKey, moreExpended)
  }, [moreExpended])

  return (
    <>
      <Typography variant='h3'>
        {t('addRaters.inviteOthersToRate')}
        <IconButton onClick={() => setConfidentialDialogOpen(true)}>
          <InfoOutlinedIcon />
        </IconButton>
      </Typography>
      {moreExpended ? (
        <BoxWithBorder
          sx={{
            backgroundColor: theme.palette.background.default,
            padding: 10
          }}>
          <InCardStack>
            <DetailContainer
              title={t('addRaters.details.item1.title')}
              description={t('addRaters.details.item1.description')}
              icon={require('assets/images/question.png')}
              iconAlt='question'
              showClose={true}
            />
            <DetailContainer
              title={t('addRaters.details.item2.title')}
              description={t('addRaters.details.item2.description')}
              icon={require('assets/images/shrug.png')}
              iconAlt='shrug'
              size={65}
            />
            <DetailContainer
              title={t('addRaters.details.item3.title')}
              description={t('addRaters.details.item3.description')}
              icon={require('assets/images/addUser.png')}
              iconAlt='add-user'
            />
            <DetailContainer
              title={t('addRaters.details.item4.title')}
              description={t('addRaters.details.item4.description')}
              icon={require('assets/images/timeLeft.png')}
              iconAlt='time-left'
            />
          </InCardStack>
        </BoxWithBorder>
      ) : (
        <ActionText onClick={() => setMoreExpended(true)}>
          {t('addRaters.tellMeMore')}
        </ActionText>
      )}
      <ConfidentialityPopUp
        open={confidentialDialogOpen}
        onClose={() => setConfidentialDialogOpen(false)}
      />
    </>
  )
}

export default AddRatersDescriptions
