import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  alpha,
  useTheme
} from '@mui/material'
import InCardStack from '../InCardStack'
import BoxWithBackground from './BoxWithBackground'
import { getLocalUser } from 'utils/localStorage'
import ProfileAvatar from '../ProfileAvatar'
import MuiMarkdown from 'mui-markdown'
import { TypeAnimation } from 'react-type-animation'

// Text-messaging conversation box
//
// messages:
//    A list of objects with `role` and `content` fields. A `sequence` field
//    can be provided instead of a content field, which will enable a typing
//    animation sequence.
// loading: Puts a message at the bottom in a "loading" state.
// messageBubbles: When true, use iMessage style bubbles. Otherwise Slack-style
const ConvoBox = ({ messages, loading, messageBubbles=true }) => {
  const theme = useTheme()

  const boxPadding = { paddingX: 10, paddingY: 7 }

  const AiAvatar = () => (
    <Avatar src={require('assets/images/alexRole.png')}>M</Avatar>
  )

  const pfpStyles = { padding: '14px 0', alignSelf: 'flex-start' }

  const messagesHtml = messages?.map((item, i) => (
    <InCardStack
      key={`convo-text-${i}`}
      direction='row'
      spacing={5}
      justifyContent={
        messageBubbles && item.role === 'user' ? 'flex-end' : 'flex-start'
      }
      sx={[{ width: '100%', paddingRight: 5 }]}
    >
      { /* Renders PFP on left. Always the case without bubbles */
        (!messageBubbles || item.role !== 'user') ?
          <Box {...pfpStyles}>
            {
              item.role !== 'user' ? <AiAvatar /> :
              <ProfileAvatar user={getLocalUser()} />
            }
          </Box>
        : null
      }
      <BoxWithBackground
        {...boxPadding}
        backgroundColor={
          !messageBubbles ? '#0000' :
          item.role === 'user' ? alpha(theme.palette.primary.light, 0.3) :
          theme.palette.background.default
        }>
        {
          item.sequence ?
            <TypeAnimation
              sequence={item.sequence}
              speed={100}
              cursor={false} /> :
            <MuiMarkdown>{item.content}</MuiMarkdown>
        }
      </BoxWithBackground>
      { /* Renders user PFP on right for bubble */
        (messageBubbles && item.role === 'user') ?
          <Box {...pfpStyles}>
            <ProfileAvatar user={getLocalUser()} />
          </Box>
        : null
      }
    </InCardStack>
  ))

  return (
    <Stack padding={0} spacing={5}>
      {messagesHtml}
      {loading ? (
        <InCardStack direction='row'>
          <AiAvatar />
          <BoxWithBackground
            height={50}
            width={80}
            backgroundColor='#0000'
            {...boxPadding}>
            <CircularProgress size={30} />
          </BoxWithBackground>
        </InCardStack>
      ) : null}
    </Stack>
  )
}

export default ConvoBox
