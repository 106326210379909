import { Stack, TextField, Typography } from '@mui/material'
import { postRequest } from 'utils/api'
import { INVITED_LIST_URL } from 'services/constants'
import { useState } from 'react'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import OverlayLoading from 'components/atoms/OverlayLoading'
import AvatarChip from 'components/atoms/AvatarChip'
import SmallButton from 'components/atoms/button/SmallButton'
import SuccessfulNotificationDialog from '../notificationOverlay/SuccessfulNotificationDialog'
import { useTranslation } from 'react-i18next'

const ResendInviteButton = ({ invite }) => {
  const { t } = useTranslation()
  const [sendDialogOpen, setSendDialogOpen] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [customText, setCustomText] = useState('')

  const handleDialogSelect = async confirmed => {
    if (confirmed) {
      setLoading(true)
      const res = await postRequest(`${INVITED_LIST_URL}/${invite.id}/resend`, {
        customText
      })
      if (res.status === 200) {
        setSendDialogOpen(false)
        setSuccessDialogOpen(true)
        setCustomText('')
      } else setRequestError(true)
      setLoading(false)
    } else {
      setSendDialogOpen(false)
    }
  }
  return (
    <>
      <SmallButton onClick={() => setSendDialogOpen(true)} data-testid={`resend-invite-${invite.id}`}>
        {t('company.resend')}
      </SmallButton>
      <ConfirmCancelDialog
        open={sendDialogOpen}
        titleText={t('company.resendInvitation')}
        onClose={handleDialogSelect}
        confirmText={t('common.send')}
        cancelText={t('common.cancel')}
        buttonsDirection='row'>
        <Stack padding={0} spacing={5}>
          <Typography variant='subtitle2'>{t('pulseSurvey.recipients')}</Typography>
          <AvatarChip user={invite} sx={{ width: 'fit-content' }} />
          <Typography variant='subtitle2'>{t('common.sendMessage')} ({t('company.optional')})</Typography>
          <TextField
            multiline
            placeholder={t('company.messageAccompanyInvitation')}
            rows={3}
            value={customText}
            onChange={e => setCustomText(e.target.value)}
            sx={{ width: '500px' }}
          />
          {requestError ? <RequestRetryAlert /> : null}
        </Stack>
      </ConfirmCancelDialog>
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={t('company.inviteSuccessfullySent')}
        confirmButtonText={t('common.done')}
        buttonAction={() => setSuccessDialogOpen(false)}
      />
      <OverlayLoading open={loading} />
    </>
  )
}

export default ResendInviteButton
