import { Button, Grid, Icon, Typography, useTheme } from '@mui/material'
const WideEndIconButton = ({
  label,
  endIcon,
  disabled = false,
  onClick,
  ...otherProps
}) => {
  const theme = useTheme()
  const fontColor = theme.palette.background.paper
  return (
    <Button
      fullWidth
      {...otherProps}
      disabled={disabled}
      onClick={onClick}
      sx={{
        paddingY: '5px',
        paddingX: '18px',
        backgroundColor: disabled
          ? theme.palette.grey[500] + ' !important'
          : undefined
      }}>
      <Typography variant='subtitle1' color={fontColor} onClick={onClick}>
        {label}
      </Typography>
      <Grid item flex={1} display='inline-flex' justifyContent='right'>
        <Icon color={fontColor} sx={{ display: 'flex', alignItems: 'center' }}>
          {endIcon}
        </Icon>
      </Grid>
    </Button>
  )
}

export default WideEndIconButton
