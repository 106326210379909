import React from 'react'
import { VictoryPie, Slice, VictoryLabel } from 'victory'
import { Box, useTheme, alpha, darken, Typography } from '@mui/material'
import colors from 'styles/colors'

const CustomSlice = props => {
  const { datum, slice, origin, activeIndex, index } = props
  const pieSlice = Slice({ ...props, slice })
  const path = pieSlice?.props?.d

  return (
    <g
      transform={`translate(${origin.x}, ${origin.y})`}
      opacity={activeIndex === null || activeIndex === index ? 1 : 0.5}>
      <path
        d={path}
        fill={alpha(datum.color, 0.6)}
        stroke='none'
        transform={activeIndex === index ? 'scale(1.05)' : 'scale(0.95)'}
      />
      <path
        d={path}
        fill={alpha(datum.color, 0.4)}
        stroke='none'
        transform={activeIndex === index ? 'scale(1.1)' : 'scale(1)'}
      />
    </g>
  )
}

const ModelPieChart = ({ title, data, activeIndex = null }) => {
  const theme = useTheme()
  const width = 450
  const height = 450
  return (
    <>
      <Box sx={{ width: width, height: height, position: 'relative' }}>
        <VictoryPie
          width={width}
          height={height}
          data={data}
          innerRadius={45}
          padAngle={2}
          labels={({ datum }) => datum.name?.split(' ')}
          labelRadius={({ innerRadius }) => innerRadius + 20}
          startAngle={-45}
          dataComponent={<CustomSlice activeIndex={activeIndex} />}
          labelComponent={<VictoryLabel lineHeight={1.5} />}
          style={{
            labels: {
              fill: ({ index }) => darken(data[index].color, 0.4),
              opacity: ({ index }) =>
                activeIndex === null || activeIndex === index ? 1 : 0.5,
              fontSize: ({ index }) => (activeIndex === index ? 12 : 10),
              fontWeight: ({ index }) => (activeIndex === index ? 600 : 500),
              fontFamily: theme.typography.fontFamily
            }
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: alpha(colors.GoldenYellow50, 0.5),
            borderRadius: '50%',
            width: '100px',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px'
          }}>
          <Typography
            sx={{ fontSize: 9, fontWeight: 600, color: colors.Grey400 }}>
            {title}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default ModelPieChart
