import { Stack } from '@mui/material'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import RadioOption from 'components/atoms/input/RadioOption'
import QuestionOptionsTemplate from 'components/templates/QuestionOptionsTemplate'
import { useEffect, useState } from 'react'
import { getConstant } from 'services/constantServices'
import { FEATURE_KEYS } from 'services/constants'
import { isAllowedFeature } from 'utils/permissionCheck'

const OnboardingQuestiionnaire = ({ updateAnswers, userAnswers }) => {
  const [requestError, setRequestError] = useState(undefined)
  const [answers, setAnswers] = useState(userAnswers)
  const [questions, setQuestions] = useState([])
  const isFullAssessment = isAllowedFeature(
    FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT
  )

  useEffect(() => {
    const fetchData = async () => {
      const questionRes = await getConstant('ONBOARDING_QUESTIONNARIE')
      if (!questionRes) {
        setRequestError(true)
      } else {
        setRequestError(false)
        setQuestions(questionRes.filter(
          q =>
            q.displayPage === 0 &&
            (isFullAssessment ? q.questionId !== 0 : true)
        ))
      }
    }
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAnswerChange = questionId => {
    return event => {
      const newAnswers = { ...answers }
      newAnswers[questionId] = parseInt(event.target.value, 10)
      updateAnswers(newAnswers)
      setAnswers(newAnswers)
    }
  }
  const getOptions = (options, questionId) => {
    return options.map(v => (
      <RadioOption
        key={`option-${questionId}-${v.value}`}
        value={v.value}
        label={v.label}
      />
    ))
  }

  return (
    <Stack width='100%' padding={0} spacing={5}>
      {questions.map((q, i) => (
        <QuestionOptionsTemplate
          key={`question-${q.questionId}`}
          question={q.questionText}
          handleOnValueChange={handleAnswerChange(q.questionId)}
          options={getOptions(q.options, i)}
          initialValue={userAnswers[q.questionId]}
          optionMarginX={0}
          padding={0}
        />
      ))}
      {requestError ? <RequestRetryAlert /> : null}
    </Stack>
  )
}

export default OnboardingQuestiionnaire
