import { Container, Stack, Typography } from '@mui/material'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import { PageHeader } from 'components/atoms/Text'
import AboutPage from 'components/templates/navigationPageTemplates/AboutPage'
import { useTranslation } from 'react-i18next'
import { FEATURE_KEYS } from 'services/constants'
import { isAllowedFeature } from 'utils/permissionCheck'

const textProps = { variant: 'body2', fontSize: '16px', lineHeight: 1.75 }
const Confidentiality = () => {
  const { t } = useTranslation()
  const getTextLabel = (key) => t(`confidentiality.${isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT) ? 'fullAssessment': 'basic'}.${key}`)
  return (
    <AboutPage>
      <Container maxWidth='md'>
        <LeftJustifyCardContent>
          <Stack spacing={10}>
            <PageHeader>{t('confidentiality.heading')}</PageHeader>
            <Typography {...textProps}>
              {getTextLabel('part1')}
            </Typography>
            <Typography {...textProps}>
              {getTextLabel('part2')}
            </Typography>
            <Typography {...textProps}>
              {getTextLabel('part3')}
            </Typography>
            <Typography {...textProps}>
              {getTextLabel('part4')}
            </Typography>
            <Typography {...textProps}>
              {isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT) && getTextLabel('part5')}
            </Typography>
          </Stack>
        </LeftJustifyCardContent>
      </Container>
    </AboutPage>
  )
}

export default Confidentiality
