import React, { useState, useEffect } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import MyTeamPage from 'components/templates/navigationPageTemplates/MyTeamPage'
import moment from 'moment'
import LiveNote from 'components/molecules/myTeam/LiveNote'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import InCardStack from 'components/atoms/InCardStack'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getLocalUser } from 'utils/localStorage'
import {
  getCalendarEvent,
  getConnectionEvent,
  patchUserCalendarEvent
} from 'services/userConnectionsHelper'
import { MY_TEAM } from 'routes/constants'
import colors from 'styles/colors'
import UserMeetingHeader from 'components/molecules/myTeam/UserMeetingHeader'
import { PastEventList } from 'components/molecules/myTeam/EventList'
import CheckInSurveyResult from 'components/organisms/myTeam/CheckInSurveyResult'

const UserMeeting = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const userConnectionEventId = location?.state?.userConnectionEventId
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [userCalendarEventId, setUserCalendarEventId] = useState(
    location?.state?.userCalendarEventId
  )
  const [event, setEvent] = useState(null)
  const [connection, setConnection] = useState(null)
  const [surveyUser, setSurveyUser] = useState(null)
  const [userSharedNotes, setUserSharedNotes] = useState('')
  const [userConnectionSharedNotes, setUserConnectionSharedNotes] = useState('')
  const [privateNotes, setPrivateNotes] = useState('')
  const [isClosed, setIsClosed] = useState(null)
  const [isOwner, setIsOwner] = useState(false)

  const handleDataRefresh = updatedEvent => {
    setIsClosed(updatedEvent.isClosed)
    if (isOwner) {
      setUserConnectionSharedNotes(updatedEvent.userConnectionSharedNotes ?? '')
    } else {
      setUserSharedNotes(updatedEvent.userSharedNotes ?? '')
      setPrivateNotes(updatedEvent.notes ?? '')
    }
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      let calendarEvent
      let connectionEvent
      if (userCalendarEventId) {
        calendarEvent = await getCalendarEvent(userCalendarEventId)
        const connectionEId = calendarEvent.userConnectionEventId
        connectionEvent = await getConnectionEvent(connectionEId)
      } else {
        connectionEvent = await getConnectionEvent(userConnectionEventId)
        const calendarEId = connectionEvent.userCalendarEvents[0].id
        calendarEvent = await getCalendarEvent(calendarEId)
        setUserCalendarEventId(calendarEvent.id)
      }

      const ownedEvent =
        getLocalUser().id === connectionEvent.userConnection.createdByUserId
      setIsOwner(ownedEvent)
      setConnection(
        ownedEvent
          ? connectionEvent.userConnection.connection
          : connectionEvent.userConnection.connectedBy
      )
      setSurveyUser(connectionEvent.userConnection.connection)
      setUserSharedNotes(calendarEvent.userSharedNotes ?? '')
      setUserConnectionSharedNotes(
        calendarEvent.userConnectionSharedNotes ?? ''
      )
      setPrivateNotes(calendarEvent.notes ?? '')
      setIsClosed(calendarEvent.isClosed)
      setEvent(calendarEvent)
      setIsLoading(false)
    }
    if (!userCalendarEventId && !userConnectionEventId) {
      navigate(MY_TEAM)
    } else {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      // Only do live update if event started and not closed
      if (event && !isClosed && moment.utc(event.startTime).isBefore()) {
        const updatedEvent = await getCalendarEvent(userCalendarEventId)
        if (updatedEvent) {
          handleDataRefresh(updatedEvent)
        }
      }
    }, 10000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, isClosed])

  const handleUpdateEvent = async (field, value) => {
    const updated = await patchUserCalendarEvent(userCalendarEventId, {
      [field]: value
    })
    handleDataRefresh(updated)
    return updated
  }
  const handleCloseMeeting = async () => {
    setIsLoading(true)
    const updated = await handleUpdateEvent('isClosed', true)
    if (updated) {
      setIsClosed(true)
    }
    setIsLoading(false)
  }
  return (
    <MyTeamPage customizedBodyWidth>
      <OverlayLoading open={isLoading} />
      {event && (
        <Container maxWidth='xl' sx={{ minHeight: '800px', height: 'inherit' }}>
          <Grid container height='100%' paddingLeft={10}>
            <Grid item height='100%' xs={isOwner ? 9 : 12} paddingTop={4}>
              <Grid
                container
                height='100%'
                alignItems='stretch'
                direction='column'>
                {!location.state?.deepLink && (
                  <Grid item width='100%'>
                    <BackIconTextButton />
                  </Grid>
                )}
                <UserMeetingHeader
                  connection={connection}
                  event={event}
                  handleCloseMeeting={handleCloseMeeting}
                  isOwner={isOwner}
                  isClosed={isClosed}
                  userConnectionId={
                    event?.userConnectionEvent?.userConnectionId
                  }
                />
                <Grid
                  item
                  width='100%'
                  container
                  columnSpacing={10}
                  sx={{ height: 'calc(100% - 140px)' }}>
                  <Grid
                    item
                    xs={5}
                    height='100%'
                    container
                    rowSpacing={10}
                    direction='column'>
                    <Grid item height='450px'>
                      <CheckInSurveyResult
                        isOwner={isOwner}
                        userCalendarEvent={event}
                        surveyUser={surveyUser}
                      />
                    </Grid>
                    <Grid item sx={{ height: 'calc(100% - 450px)' }}>
                      {event && (
                        <PastEventList
                          name={getLocalUser().firstName}
                          nameRel={connection?.firstName ?? 'Leader'}
                          userConnectionId={
                            event?.userConnectionEvent?.userConnectionId
                          }
                          isFixedHeight
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    container
                    direction='column'
                    height='100%'
                    rowSpacing={10}>
                    <Grid item height='50%' order={isOwner ? 1 : 2}>
                      {/* userSharedNote */}
                      <LiveNote
                        isClosed={isClosed}
                        connection={connection}
                        notes={userSharedNotes}
                        setNotes={setUserSharedNotes}
                        handleUpdateNotes={n =>
                          handleUpdateEvent('userSharedNotes', n)
                        }
                        isUserOwnedNote={isOwner}
                      />
                    </Grid>
                    <Grid item order={isOwner ? 2 : 1} height='50%'>
                      {/* userConnectionSharedNote */}
                      <LiveNote
                        isClosed={isClosed}
                        connection={connection}
                        notes={userConnectionSharedNotes}
                        setNotes={setUserConnectionSharedNotes}
                        handleUpdateNotes={n =>
                          handleUpdateEvent('userConnectionSharedNotes', n)
                        }
                        isUserOwnedNote={!isOwner}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {isOwner && (
              <Grid item xs={3}>
                <InCardStack
                  sx={{
                    height: '100%',
                    padding: 10,
                    backgroundColor: colors.LightYellow
                  }}>
                  <Typography variant='h4'>
                    {t('myTeam.applyWhatYouveLearned')}
                  </Typography>
                  <LiveNote
                    isClosed={isClosed}
                    connection={connection}
                    notes={privateNotes}
                    setNotes={setPrivateNotes}
                    handleUpdateNotes={n => handleUpdateEvent('notes', n)}
                    isUserOwnedNote={true}
                    isPrivateNote
                  />
                </InCardStack>
              </Grid>
            )}
          </Grid>
        </Container>
      )}
    </MyTeamPage>
  )
}

export default UserMeeting
