import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

const RequestRetryAlert = ({
  message
}) => {
  const { t } = useTranslation()
  return (<Alert severity='error'>{message ? message : t('requestRetryAlert.message')}</Alert>)
}

export default RequestRetryAlert
