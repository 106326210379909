import FullAssessmentOnboarding from 'components/organisms/assessments/fullAssessment/FullAssessmentOnboarding'
import OnboardingCard from 'components/organisms/user/OnboardingCard'
import SimplePage from 'components/templates/SimplePage'
import { FEATURE_KEYS } from 'services/constants'
import { isAllowedFeature } from 'utils/permissionCheck'

const Onboarding = () => {
  const fullAssessmentUser = isAllowedFeature(
    FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT
  )
  return (
    <SimplePage maxWidth={fullAssessmentUser ? '100%' : 'xl'}>
      {fullAssessmentUser ? <FullAssessmentOnboarding /> : <OnboardingCard />}
    </SimplePage>
  )
}

export default Onboarding
