import { IconButton, Stack } from '@mui/material'
import { getRequest } from 'utils/api'
import { useEffect, useState } from 'react'
import ConfirmCancelDialog from '../../notificationOverlay/ConfirmCancelDialog'
import { useFormik } from 'formik'
import { cohortSessionValidationSchema } from 'utils/formValidations'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import OverlayLoading from 'components/atoms/OverlayLoading'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import { COHORT_GOAL_FACILITATION_URL } from 'services/constants'
import CohortSessionFields from './CohortSessionFields'
import moment from 'moment'
import ShortButton from 'components/atoms/button/ShortButton'
import {
  postCohort,
  postPatchSession,
  updateFaciliations
} from 'services/cohortServices'
import { useTranslation } from 'react-i18next'

const EditCohortSessionButton = ({
  organizationId,
  newCohort = false,
  cohort,
  newSession = false,
  cohortSession,
  editMode = false,
  successCallback
}) => {
  const { t } = useTranslation()
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [postGoalActivityEmails, setPostGoalActivityEmails] = useState(
    editMode ? cohortSession.postGoalActivityEmails : true
  )
  const [autoAssignPathOnStart, setAutoAssignPathOnStart] = useState(
    editMode ? cohortSession.autoAssignPathOnStart : true
  )
  const [preFacilitationEmails, setPreFacilitationEmails] = useState(
    editMode ? cohortSession.setPreFacilitationEmails : true
  )
  const [addedSessionId, setAddedSessionId] = useState(null)

  const fetchFacilitations = async cohortSessionId => {
    const res = await getRequest(COHORT_GOAL_FACILITATION_URL, {
      cohortSessionId
    })
    if (res.status === 200) {
      const facData = res.data.data
      formik.setFieldValue('facilitations', facData)
      if (facData.length && facData[0].userId) {
        // Assuming all facilitation belongs to same user
        formik.setFieldValue('facilitatorId', facData[0].userId)
      }
    }
  }
  const formik = useFormik({
    initialValues: {
      cohortName: '',
      startDate: editMode ? moment(cohortSession.startDate) : null,
      endDate: editMode ? moment(cohortSession.endDate) : null,
      path: editMode ? cohortSession.goalId || '' : '',
      reAssessmentTiming: editMode ? cohortSession.resend360AfterDays : '',
      facilitations: [],
      facilitatorId: ''
    },
    enableReinitialize: true,
    validationSchema: cohortSessionValidationSchema(newCohort),
    onSubmit: async values => {
      setLoading(true)
      const sessionData = {
        goalId: values.path,
        autoAssignPathOnStart,
        startDate: values.startDate.utc(),
        endDate: values.endDate.endOf('day').utc(),
        resend360AfterDays: values.reAssessmentTiming,
        postGoalActivityEmails,
        preFacilitationEmails
      }
      let cohortSessionId
      let result
      if (newCohort && !addedSessionId) {
        const requestBody = {
          name: values.cohortName,
          organizationId,
          firstSession: sessionData
        }
        result = await postCohort(requestBody)
        if (!result.error) {
          cohortSessionId = result.data.cohortSessions[0].id
        }
      } else if (newSession && !addedSessionId) {
        result = await postPatchSession({
          ...sessionData,
          cohortId: cohort.id
        })
        if (!result.error) {
          cohortSessionId = result.data.id
        }
      } else {
        result = await postPatchSession(
          sessionData,
          cohortSession?.id || addedSessionId
        )
        if (!result.error) {
          cohortSessionId = cohortSession.id
        }
      }

      if (!result.error) {
        result = await updateFaciliations(
          cohortSessionId,
          values.facilitations,
          values.facilitatorId
        )
      }
      if (!result.error) {
        // TODO: Double check on user coach role and filtering
        setRequestError(false)
        await successCallback()
        setEditDialogOpen(false)
        resetForm()
      } else {
        if (cohortSessionId) {
          await fetchFacilitations(cohortSessionId)
          setAddedSessionId(cohortSessionId)
        }
        setRequestError(true)
        setErrorMessage(result.message)
      }
      setLoading(false)
    }
  })

  useEffect(() => {
    if (editDialogOpen && editMode) {
      fetchFacilitations(cohortSession.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDialogOpen])

  const resetForm = () => {
    setPostGoalActivityEmails(
      editMode ? cohortSession.postGoalActivityEmails : true
    )
    setAutoAssignPathOnStart(
      editMode ? cohortSession.autoAssignPathOnStart : true
    )
    setPreFacilitationEmails(
      editMode ? cohortSession.preFacilitationEmails : true
    )
    setRequestError(false)
    formik.resetForm()
  }

  const handleDialogSelect = async confirmed => {
    if (confirmed) {
      formik.handleSubmit()
    } else {
      setEditDialogOpen(false)
      resetForm()
    }
  }

  useEffect(() => {
    resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortSession])

  const dialogTitle = newCohort
    ? t('company.addCohort')
    : newSession
      ? `${t('company.addSessionToCohort')} ${cohort.name}`
      : t('company.editCohortSession')
  const handleDialogOpen = () => setEditDialogOpen(true)
  return (
    <>
      {newCohort ? (
        <ShortButton onClick={handleDialogOpen}>{t('company.addCohort')}</ShortButton>
      ) : newSession ? (
        <IconButton title={t('company.addSession')} onClick={handleDialogOpen}>
          <AddIcon fontSize='small' />
        </IconButton>
      ) : moment.utc(cohortSession.endDate) > moment.utc() ? (
        <IconButton title={t('company.editSession')} onClick={handleDialogOpen}>
          <EditIcon fontSize='small' />
        </IconButton>
      ) : null}
      <ConfirmCancelDialog
        open={editDialogOpen}
        titleText={dialogTitle}
        onClose={handleDialogSelect}
        maxWidth='lg'
        confirmText={t('common.save')}
        cancelText={t('common.cancel')}
        buttonsDirection='row'>
        <Stack padding={0} spacing={6} width={600}>
          <CohortSessionFields
            formik={formik}
            sessionFieldOnly={!newCohort}
            postGoalActivityEmails={postGoalActivityEmails}
            setPostGoalActivityEmails={setPostGoalActivityEmails}
            autoAssignPathOnStart={autoAssignPathOnStart}
            setAutoAssignPathOnStart={setAutoAssignPathOnStart}
            preFacilitationEmails={preFacilitationEmails}
            setPreFacilitationEmails={setPreFacilitationEmails}
          />
          {requestError ? <RequestRetryAlert message={errorMessage} /> : null}
        </Stack>
      </ConfirmCancelDialog>
      <OverlayLoading open={loading} />
    </>
  )
}

export default EditCohortSessionButton
