import {
  ABOUT_360,
  ABOUT_CONFIDENTIALITY,
  ASSESSMENTS,
  HOME,
  JOURNEY,
  LEADERSHIP_MODEL,
  OVERVIEW,
  PULSE_SURVEYS,
  USER_FEEBACK,
  SBUSCRIPTION_ADMIN,
  COMPANY_GENERAL,
  OHEP_SURVEYS,
  GOALS,
  CHECK_QUIZZES_RESULT_EXPORT,
  USER_PROGRESS_REPORT,
  FEATURES_ADMIN,
  DEMO_ACCOUNTS,
  ROLE_PLAY_PRACTISES,
  MY_TEAM
} from 'routes/constants'

// Labels are mapped from i18n translation files
export const managerTabs = [
  { value: OVERVIEW, label: 'overview' },
  { value: COMPANY_GENERAL, label: 'company' }
]

export const coachTabs = [
  { value: OVERVIEW, label: 'overview' },
]

export const contentTabs = [
  {
    value: HOME,
    label: 'home'
  },
  {
    value: 'journey',
    label: 'journey',
    pages: [
      {
        value: JOURNEY,
        label: 'currentPath'
      },
      {
        value: LEADERSHIP_MODEL,
        label: 'leadershipModel'
      }
    ]
  },
  {
    value: 'feedback',
    label: 'feedback',
    pages: [
      {
        value: ASSESSMENTS,
        label: 'assessments'
      },
      {
        value: PULSE_SURVEYS,
        label: 'pulseSurvey'
      },
      {
        value: USER_FEEBACK,
        label: 'userFeedback'
      }
    ]
  },
  {
    value: 'about',
    label: 'about',
    pages: [
      {
        value: ABOUT_360,
        label: 'about360'
      },
      {
        value: ABOUT_CONFIDENTIALITY,
        label: 'aboutConfidentiality'
      },
    ]
  },
]

export const myTeamOneOnOneTab = {
  value: MY_TEAM,
  label: 'myTeam',
}

export const practiceTab = {
  value: ROLE_PLAY_PRACTISES,
  label: 'practice'
}

export const raterTabs = [contentTabs[0], contentTabs[3]]

export const testingTabs = [
  { value: SBUSCRIPTION_ADMIN, label: 'subscriptions' },
  {
    value: GOALS,
    label: 'paths'
  },
  {
    value: 'reports',
    label: 'reportExport',
    pages: [
      {
        value: OHEP_SURVEYS,
        label: 'ohepSurveys'
      },
      {
        value: CHECK_QUIZZES_RESULT_EXPORT,
        label: 'learningCheckQuizzesResults'
      },
      {
        value: USER_PROGRESS_REPORT,
        label: 'userProgressReport'
      }
    ]
  },
  { value: FEATURES_ADMIN, label: 'features' },
  {
    value: DEMO_ACCOUNTS,
    label: 'demoAccounts'
  }
]
