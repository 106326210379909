import { Done } from '@mui/icons-material'
import {
  Box,
  Button,
  LinearProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import BackIconButton from 'components/atoms/button/BackIconButton'
import NextIconButton from 'components/atoms/button/NextIconButton'
import RadioOption from 'components/atoms/input/RadioOption'
import QuestionOptionsTemplate from 'components/templates/QuestionOptionsTemplate'
import { useEffect, useRef, useState } from 'react'

const SurveyQuestionPage = ({
  totalPages,
  activePage,
  pageContent,
  initialAnswers,
  submitAnswers,
  scoreOptions = [],
  isDemographic = false
}) => {
  const [answers, setAnswers] = useState({})
  const [questions, setQuestions] = useState([])
  const [disablePrev, setDisablePrev] = useState(false)
  const [showError, setShowError] = useState(false)
  const [finishNext, setFinishNext] = useState(false)

  const topRef = useRef(null)

  const scrollToTop = () =>
    topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  useEffect(() => {
    if (activePage === 0) {
      setDisablePrev(true)
    } else {
      setDisablePrev(false)
    }
    setShowError(false)
    setAnswers(initialAnswers)
    setQuestions(pageContent.questions)
    scrollToTop()
  }, [activePage, pageContent, initialAnswers])

  const handleOnValueChange = (questionId, e, finishSurveyOptionValue) => {
    const newAnswer = { ...answers }
    newAnswer[questionId] = e.target.value
    if (finishSurveyOptionValue !== undefined) {
      setFinishNext(
        newAnswer[questionId] === finishSurveyOptionValue.toString()
      )
    }
    setAnswers(newAnswer)
    if (showError) {
      checkSubmittable(newAnswer)
    }
  }

  const checkSubmittable = checkAnswers => {
    const requiredQuestions = questions.filter(q => q.optional !== true)
    let submittable = true
    requiredQuestions.forEach(q => {
      if (!checkAnswers[q.id]) {
        submittable = false
      }
    })
    if (submittable) {
      setShowError(false)
    }
    return submittable
  }
  const handleNavigate = direction => {
    if (direction > 0) {
      const submittable = checkSubmittable(answers)
      if (!submittable) {
        setShowError(true)
        return
      }
      if (finishNext) {
        submitAnswers(answers, totalPages)
        return
      }
    }
    submitAnswers(answers, activePage + direction)
  }

  const getQuestionText = q => {
    let t = q.questionText
    if (q.optional !== true) {
      t = '*' + t
    }
    return t
  }

  return (
    <InCardStack sx={{ padding: 20, width: '100%' }}>
      <Typography variant='h5' ref={topRef}>
        {isDemographic ? 'Demographic Information' : pageContent.title}
      </Typography>
      <Typography textAlign='center' variant='subtitle2'>
        {pageContent.summary}
      </Typography>
      {questions?.map(q => {
        let questionOptions = scoreOptions
        if (q.type === 'multiple_choice') {
          questionOptions = q.options
        }
        return (
          <Stack padding={0} width='80%' key={q.id}>
            {showError && !q.optional && !answers[q.id] ? (
              <Typography color='error' variant='caption'>
                *Required
              </Typography>
            ) : null}
            {q.type === 'text' ? (
              <Stack width='100%' spacing={5} padding={0}>
                <Typography alignSelf='flex-start'>
                  {getQuestionText(q)}
                </Typography>
                <TextField
                  key={`input-${q.id}`}
                  multiline
                  minRows={3}
                  value={answers[q.id]}
                  onChange={e => handleOnValueChange(q.id, e)}
                  inputProps={{ 'data-testid': `input-${q.id}` }}
                />
              </Stack>
            ) : (
              <QuestionOptionsTemplate
                question={getQuestionText(q)}
                width='100%'
                padding={0}
                initialValue={answers[q.id]}
                options={questionOptions?.map(o => (
                  <RadioOption
                    value={o.value}
                    label={o.label}
                    key={`option-${q.id}-${o.value}`}
                  />
                ))}
                handleOnValueChange={e =>
                  handleOnValueChange(q.id, e, q.finishSurveyOptionValue)
                }
              />
            )}
          </Stack>
        )
      })}
      <InCardStack width='60%' direction='row' sx={{ paddingTop: 5 }}>
        <Box width='100%'>
          <LinearProgress
            variant='determinate'
            value={((activePage + 1) / totalPages) * 100}
            sx={{ height: 10, borderRadius: 5 }}
          />
        </Box>
        <Typography color='primary'>
          {(((activePage + 1) / totalPages) * 100).toFixed(0)}%
        </Typography>
      </InCardStack>
      {showError ? (
        <Typography color='error'>
          Please answer all reqired questions!
        </Typography>
      ) : null}
      <InCardStack direction='row' spacing={100}>
        <BackIconButton
          disabled={disablePrev}
          onClick={() => handleNavigate(-1)}
        />
        <NextIconButton
          sx={{
            display: activePage + 1 < totalPages ? undefined : 'none'
          }}
          onClick={() => handleNavigate(1)}
        />
        <Button
          sx={{
            display: activePage + 1 === totalPages ? undefined : 'none'
          }}
          title='Finish'
          onClick={() => handleNavigate(1)}>
          <Done />
        </Button>
      </InCardStack>
    </InCardStack>
  )
}

export default SurveyQuestionPage
