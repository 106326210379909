import * as yup from 'yup'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { ActionText } from 'components/atoms/Text'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { useFormik } from 'formik'
import { useState } from 'react'
import { USER_CALENDAR_EVENTS_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import { validationsRules } from 'utils/formValidations'
import InputDialog from '../notificationOverlay/InputDialog'
import FormInputBox from 'components/atoms/input/FormInputBox'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

const EventContent = ({
  event,
  fontColor,
  home,
  withString,
  isFuture = true
}) => {
  const messageTo = `mailto:${event.emailToString}?subject=Note about ${event.subject}`
  const { t } = useTranslation()
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)
  const [noteRequestSuccess, setNoteRequestSuccess] = useState(undefined)

  const formik = useFormik({
    initialValues: {
      note: selectedNote?.userNote
    },
    validationSchema: yup.object({
      note: validationsRules.textRequired
    }),
    onSubmit: async values => {
      const requestBody = {
        notes: values.note
      }
      const res = await postRequest(
        `${USER_CALENDAR_EVENTS_URL}/${selectedNote.id}`,
        requestBody
      )
      if (res.status === 201) {
        selectedNote.userNote = values.note
        setNoteRequestSuccess(true)
        setNoteDialogOpen(false)
      } else {
        setNoteRequestSuccess(false)
      }
    }
  })

  const handleEventClick = event => {
    setNoteRequestSuccess(undefined)
    setSelectedNote(event)
    formik.resetForm()
    formik.setFieldValue('note', event.userNote)
    setNoteDialogOpen(true)
  }
  return (
    <Stack padding={0} spacing={home ? 0 : 2}>
      <Typography variant='h3' color={fontColor}>
        {event.subject}
      </Typography>
      {event.attendeeNameString?.length > 0 || withString ? (
        <>
          <Typography variant='body2' color={fontColor}>
            with{' '}
            {withString
              ? withString
              : event.attendeeNameString.length > 33 && home
              ? event.attendeeNameString.slice(0, 33) + '....'
              : event.attendeeNameString}
            {isFuture ? (
              <Tooltip title={t('common.sendMessage')}>
                <IconButton
                  href={messageTo}
                  sx={{
                    transform: 'rotate(-25deg)',
                    top: -3,
                    color: home ? fontColor : undefined
                  }}>
                  <SendOutlinedIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            ) : null}
          </Typography>
        </>
      ) : null}
      <Box width='100%' display='flex' alignItems='center' paddingBottom={5}>
        {home ? null : (
          <EventNoteOutlinedIcon sx={{ marginRight: 4, color: 'grey' }} />
        )}
        {isFuture ? (
          event.userNote ? (
            <>
              <Typography variant={home ? 'body2' : 'body'}>
                {event.userNote.length > 40 && home
                  ? event.userNote.slice(0, 40) + '....'
                  : event.userNote}
              </Typography>
              <Tooltip title={t('calenderEvents.editNotes')}>
                <IconButton
                  onClick={() => handleEventClick(event)}
                  sx={{ color: home ? fontColor : undefined }}>
                  <EditOutlinedIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <ActionText
              onClick={() => handleEventClick(event)}
              variant={home ? 'body2' : undefined}
              sx={{ color: home ? colors.Aluminium : undefined }}>
              {t('calenderEvents.addNotes')}
              {home ? (
                <Tooltip title={t('calenderEvents.editNotes')}>
                  <IconButton
                    onClick={() => handleEventClick(event)}
                    sx={{ color: fontColor }}>
                    <EditOutlinedIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              ) : null}
            </ActionText>
          )
        ) : null}
      </Box>
      <InputDialog
        formik={formik}
        titleText={t('calenderEvents.eventNotes')}
        open={noteDialogOpen}
        handleCancel={() => setNoteDialogOpen(false)}
        confirmText={t('common.save')}
        cancelText={t('common.cancel')}>
        <FormInputBox
          sx={{ width: '100%' }}
          type='text'
          formik={formik}
          multiline
          rows={3}
          attributeName='note'
          placeholder={t('calenderEvents.yourNotePlaceholder')}
        />
        {noteRequestSuccess === false ? <RequestRetryAlert /> : null}
      </InputDialog>
    </Stack>
  )
}

export default EventContent
