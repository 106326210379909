import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import UploadIcon from '@mui/icons-material/Upload'
import InCardStack from 'components/atoms/InCardStack'
import CheckButton from 'components/atoms/input/CheckButton'
import { NotificationBodyText } from 'components/atoms/Text'
import SubscriptionManagement from 'components/molecules/internal/SubscriptionManagement'
import { useEffect, useState, useRef } from 'react'
import { ORGANIZATIONS_URL } from 'services/constants'
import { MY_TEAM_IMPORT } from 'routes/constants'
import { getRequest } from 'utils/api'
import AddOrganizationDialog from './AddOrganizationDialog'
import OverlayLoading from 'components/atoms/OverlayLoading'
import CompanyInfoCard from './adminDashboard/CompanyInfoCard'
import AssessmentReportCard from './adminDashboard/AssessmentReportCard'
import { updatePageState } from 'utils/windowHistoryHelper'
import SurveyQuizReportCard from './adminDashboard/SurveyQuizReportCard'
import SearchOrganizations from 'components/molecules/internal/SearchOrganizations'
import LowFocusButton from 'components/atoms/button/LowFocusButton'
import { postRequest } from 'utils/api'
import moment from 'moment'

const COMPANY_TABS = {
  SUBSCRITIONS: {
    value: 0,
    label: 'Subscriptions'
  },
  GENERAL: {
    value: 1,
    label: 'General'
  },
  ASSESSMENT_REPORT: {
    value: 2,
    label: 'Assessment Report'
  },
  SURVEY_QUIZ_REPORT: {
    value: 3,
    label: 'Survey Quiz Report'
  }
}

const SubscriptionAdminCard = () => {
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState(COMPANY_TABS.SUBSCRITIONS)
  const [companyList, setCompanyList] = useState([])
  const [isActiveLicenseFilter, setIsActiveLicenseFilter] = useState(true)
  const [selectedCompany, setSelectedCompany] = useState(
    window.history.state?.selectedCompany || null
  )
  const [newOrganizationDialogOpen, setNewOrganizationDialogOpen] =
    useState(false)
  const [uploadMyTeamOpen, setUploadMyTeamOpen] = useState(false)
  const [uploadMyTeamText, setUploadMyTeamText] = useState('Upload A CSV!')
  const fileInputRef = useRef(null)

  const fetchData = async () => {
    const oRes = await getRequest(ORGANIZATIONS_URL)
    if (oRes.status === 200) {
      setCompanyList(oRes.data.data.filter(o => o.name))
    }
    setLoading(false)
  }
  useEffect(() => {
    fetchData()
    const returnTab = window.history.state?.activeTabId
    if (returnTab) {
      handleTabChange(null, returnTab)
    }
  }, [])

  const createCompanyCallback = async newOrg => {
    setLoading(true)
    setNewOrganizationDialogOpen(false)
    await fetchData()
    setSelectedCompany(newOrg)
    setLoading(false)
  }

  const handleTabChange = (event, newValue) => {
    const dest = Object.entries(COMPANY_TABS).find(
      ([, t]) => t.value === newValue
    )
    setActiveTab(dest[1])
  }

  const handleUploadClick = () => {
    fileInputRef.current && fileInputRef.current.click()
  }

  const closeCsvPopup = () => {
    setUploadMyTeamText('Upload a CSV!')
    setUploadMyTeamOpen(false)
  }

  const uploadCsv = async e => {
    const data = new FormData()
    data.append('data', e.target.files[0])

    setUploadMyTeamText('Uploading, please wait...')
    const res = await postRequest(MY_TEAM_IMPORT, data)

    if (res.status === 200) {
      setUploadMyTeamText('Upload Succeeded! This popup will automatically close in 5s.')
      setTimeout(closeCsvPopup, 5000)
    } else {
      setUploadMyTeamText('Upload failed. Please ensure the CSV is correctly formatted.')
      console.error(res)
    }
  }

  useEffect(() => {
    updatePageState({ selectedCompany, activeTabId: activeTab.value })
  }, [selectedCompany, activeTab])
  return (
    <>
      <OverlayLoading open={loading} />
      <Grid container>
        <Grid item xs={6}>
          <InCardStack direction='row' spacing={3}>
            <SearchOrganizations
              organizations={companyList}
              selectedOrg={selectedCompany}
              setSelectedOrg={setSelectedCompany}
              isActiveLicenseFilter={isActiveLicenseFilter}
            />
            <CheckButton
              label='Active License'
              isChecked={isActiveLicenseFilter}
              setIsChecked={() => {
                const wasFiltered = !isActiveLicenseFilter
                const s = selectedCompany
                const hasLicense = s?.licenseExpireAt && moment(s.licenseExpireAt).isAfter()

                // Clears a selected company when filter turns on and company
                // isn't part of the filter
                if (wasFiltered && !hasLicense) {
                  setSelectedCompany(null)
                }
                setIsActiveLicenseFilter(a => !a)
              }}
            />
            <IconButton
              title='Add new company'
              onClick={() => setNewOrganizationDialogOpen(true)}>
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              title='Upload MyTeam Spreadsheet'
              onClick={() => setUploadMyTeamOpen(true)}
            >
              <UploadIcon />
            </IconButton>
          </InCardStack>
        </Grid>
        <Grid item xs={6}>
          {selectedCompany ? (
            <Typography variant='h2_alu'>
              {selectedCompany.name
                ? `${selectedCompany.name} (${selectedCompany.domain})`
                : selectedCompany.domain}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Divider />
      {selectedCompany ? (
        <InCardStack>
          <Box width='100%'>
            <Tabs
              value={activeTab.value}
              textColor='inherit'
              onChange={handleTabChange}>
              {Object.entries(COMPANY_TABS).map(([, t]) => (
                <Tab value={t.value} label={t.label} key={t.label} />
              ))}
            </Tabs>
            <Divider />
          </Box>
          {activeTab === COMPANY_TABS.SUBSCRITIONS ? (
            <SubscriptionManagement organizationId={selectedCompany.id} />
          ) : activeTab === COMPANY_TABS.GENERAL ? (
            <CompanyInfoCard organizationId={selectedCompany.id} />
          ) : activeTab === COMPANY_TABS.ASSESSMENT_REPORT ? (
            <AssessmentReportCard organizationId={selectedCompany.id} />
          ) : activeTab === COMPANY_TABS.SURVEY_QUIZ_REPORT ? (
            <SurveyQuizReportCard organizationId={selectedCompany.id} />
          ) : null}
        </InCardStack>
      ) : (
        <CardContent height='inherit'>
          <Stack maxWidth='600px'>
            <NotificationBodyText>
              Select a company to manage subscriptions and user licenses
            </NotificationBodyText>
          </Stack>
        </CardContent>
      )}
      <AddOrganizationDialog
        open={newOrganizationDialogOpen}
        onCancel={() => setNewOrganizationDialogOpen(false)}
        createdCallback={createCompanyCallback}
      />
      {uploadMyTeamOpen &&
        <Dialog open={uploadMyTeamOpen}>
          <DialogTitle>
            Import MyTeam Structure
          </DialogTitle>
          <Box sx={{ textAlign: 'center', padding: 10 }}>
            {uploadMyTeamText}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              padding: 10
          }}>
            <LowFocusButton onClick={closeCsvPopup}>
              Cancel
            </LowFocusButton>
            <Button onClick={() => handleUploadClick()}>
              Upload
            </Button>
          </Box>

          <input
            ref={fileInputRef}
            hidden
            accept="text/csv, .csv"
            type='file'
            onChange={uploadCsv}
          />
        </Dialog>
      }
    </>
  )
}

export default SubscriptionAdminCard
