import { useState, useEffect } from 'react'
import AppUsageBarChart from 'components/molecules/adminDashboard/AppUsageBarChart'
import AppUsageProgressBar from 'components/molecules/adminDashboard/AppUsageProgressBar'
import {
  Box,
  Stack,
  Typography,
  Select,
  MenuItem,
  CardHeader
} from '@mui/material'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InCardStack from 'components/atoms/InCardStack'
import { APP_USAGE } from 'services/constants'
import { getRequest } from 'utils/api'
import moment from 'moment'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { getFullName } from 'utils/formatText'

const AppUsage = ({ organizationId, cohortId }) => {
  const { t, i18n } = useTranslation()
  const [appUsage, setAppUsage] = useState([])
  const [usageDays, setUsageDays] = useState('30')

  const handleUpdateUsageDays = async event => {
    const newValue = event.target.value
    setUsageDays(newValue)
  }

  const fetchAppUsage = async () => {
    const sRes = await getRequest(APP_USAGE, {
      organizationId,
      cohortId,
      days: usageDays
    })
    if (sRes?.status === 200) {
      setAppUsage(sRes.data.data)
    }
  }

  useEffect(() => {
    if (organizationId || cohortId) {
      fetchAppUsage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, cohortId, usageDays])

  const InStack = ({ children }) => (
    <Stack
      padding={0}
      spacing={5}
      sx={{
        borderBottom: '1px solid ' + colors.Solitude,
        paddingBottom: 10
      }}>
      {children}
    </Stack>
  )

  const RowStack = ({ children }) => (
    <InCardStack direction='row' sx={{ justifyContent: 'space-between' }}>
      {children}
    </InCardStack>
  )

  const RightLabel = ({ value, text }) => (
    <InCardStack direction='row' sx={{ justifyContent: 'space-around' }}>
      <Typography variant='h4' color='primary'>
        {value}
      </Typography>
      <Typography variant='body1' style={{ marginLeft: 5 }}>
        {text}
      </Typography>
    </InCardStack>
  )

  const UserAvatar = ({ user }) => {
    const fullName = getFullName(user)
    const email = fullName === user.email ? null : user.email
    return (
      <CardHeader
        avatar={<ProfileAvatar user={user} />}
        title={fullName}
        subheader={email}
        sx={{
          width: '60%',
          padding: 0,
          paddingTop: 6,
          '& .MuiTypography-root': {
            width: '60%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        }}
      />
    )
  }

  return (
    <Stack spacing={10}>
      <Stack padding={0}>
        <Typography variant='h3'>{t('insights.appUsage')}</Typography>
        <Box>
          <Select
            value={usageDays}
            variant='standard'
            disableUnderline
            inputProps={{
              IconComponent: () => (
                <ExpandMoreIcon
                  color={colors.Aluminium}
                  fontSize='small'
                  sx={{
                    position: 'absolute',
                    right: 0,
                    pointerEvents: 'none'
                  }}
                />
              )
            }}
            sx={{ width: '100%', fontSize: '13px' }}
            onChange={handleUpdateUsageDays}>
            <MenuItem key={0} value={'30'}>
              {t('insights.last')} {t('company._30Days')}
            </MenuItem>
            <MenuItem key={1} value={'60'}>
              {t('insights.last')} {t('company._60Days')}
            </MenuItem>
            <MenuItem key={2} value={'90'}>
              {t('insights.last')} {t('company._90Days')}
            </MenuItem>
            <MenuItem key={3} value={'120'}>
              {t('insights.last')} {t('company._120Days')}
            </MenuItem>
          </Select>
        </Box>
      </Stack>

      <InStack>
        <RowStack>
          <Typography variant='body1'>{t('insights.avgOnlineTime')}</Typography>
          <RightLabel
            value={appUsage.avgOnlineTime}
            text={t('insights.mins')}
          />
        </RowStack>

        <RowStack>
          <Typography variant='body1'>
            {t('insights.avgDaysLoggedIn')}
          </Typography>
          <RightLabel
            value={appUsage.avgLoggedInDays}
            text={t('company.days')}
          />
        </RowStack>
      </InStack>

      <InStack>
        <Typography variant='body1'>{t('insights.monthlyUsage')}</Typography>
        <AppUsageBarChart
          barData={appUsage?.monthlyUsage
            ?.map(m => {
              moment.locale(i18n.resolvedLanguage)
              if (parseInt(m.usage) > 0) {
                return {
                  x: moment.utc(m.date).format('MMM D'),
                  y: parseInt(m.usage)
                }
              }
              return null
            })
            .filter(value => value !== null)}
        />
      </InStack>

      <InStack>
        <Typography variant='body1'>{t('insights.topUsers')}</Typography>

        {appUsage?.topUsers?.map((u, index) => (
          <RowStack key={index}>
            <UserAvatar user={u?.user} />
            <RightLabel value={u?.usage} text={t('insights.mins')} />
          </RowStack>
        ))}
      </InStack>

      <Stack padding={0} spacing={3}>
        <Typography variant='body1'>{t('insights.topActivities')}</Typography>

        <Typography variant='body2'>{t('insights.learning')}</Typography>
        <AppUsageProgressBar progress={90} />

        <Typography variant='body2'>{t('insights._360sSent')}</Typography>
        <AppUsageProgressBar progress={70} />

        <Typography variant='body2'>
          {t('insights.goalsAndActivities')}
        </Typography>
        <AppUsageProgressBar progress={10} />
      </Stack>
    </Stack>
  )
}

export default AppUsage
