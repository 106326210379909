import {
  Typography,
  Autocomplete,
  TextField,
  Icon,
  createFilterOptions
} from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import colors from 'styles/colors'

const SearchOrganizations = ({
  organizations,
  selectedOrg,
  setSelectedOrg,
  isActiveLicenseFilter
}) => {
  const [filterText, setFilterText] = useState('')
  const filter = createFilterOptions()

  return (
    <>
      <Autocomplete
        autoSelect
        size='small'
        value={selectedOrg}
        options={organizations}
        onChange={(e, value) => setSelectedOrg(value)}
        filterOptions={(options, params) => {
          let filtered = filter(options, params)
          if (isActiveLicenseFilter) {
            filtered = filtered
              .filter(x => (
                x?.licenseExpireAt && moment(x.licenseExpireAt).isAfter()
              ))
          }

          return filtered
        }}
        renderInput={params => (
          <TextField
            {...params}
            autoFocus
            placeholder='Search for Organization'
            sx={{
              color: colors.Aluminium,
              minWidth: '200px',
              '& .MuiInput-root:before': { display: 'none' },
              '& .MuiInput-root:after': { display: 'none' }
            }}
            onChange={e => setFilterText(e.target.value)}
          />
        )}
        renderOption={(props, option) => (
          <Typography {...props} key={option.id}>
            {option.name}
          </Typography>
        )}
        clearIcon={
          <Icon fontSize='small' sx={{ color: colors.Aluminium }}>
            {filterText?.length ? 'clear' : null}
          </Icon>
        }
        getOptionLabel={option => option?.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        data-testid='company-filter-select'
      />
    </>
  )
}

export default SearchOrganizations
