import colors from 'styles/colors'
import NumberIconButton from './NumberIconButton'
import CheckIcon from '@mui/icons-material/Check'
import { getSizeString } from 'utils/formatText'
import { Box, Tooltip, Typography } from '@mui/material'

const tickGrey = require('assets/images/tickGrey.png')
const tickGreen = require('assets/images/tickGreen.png')
const person = require('assets/images/user.png')

const buttonColorOptions = [
  colors.MonarkYellow,
  colors.Cinnabar,
  colors.LightSalmon
]

export const getColorByIndex = i => {
  const colorIndex = i % 3
  return buttonColorOptions[colorIndex]
}
export const Level1IconButton = ({ completed, active, number }) => {
  const inactive = !(completed || active)
  if (completed) {
    return (
      <NumberIconButton
        number={
          <CheckIcon sx={{ color: '#21B182', fontSize: getSizeString(30) }} />
        }
        size={56}
        color='#E9F8F3'
      />
    )
  }
  return (
    <NumberIconButton
      number={number}
      size={56}
      fontSize={20}
      disabled={inactive}
    />
  )
}

export const Level2IconButton = ({
  completed = false,
  number,
  stepIndex,
  onClick,
  hasThoughtLeader,
  currentUserCount,
  active = false
}) => {
  const color = getColorByIndex(stepIndex)
  const mainIconSize = 28
  const fontSize = 12
  const ThoughtLeaderIcon = () => {
    const overlay = completed ? tickGreen : tickGrey
    return (
      <Box
        position='relative'
        left={getSizeString(-44)}
        top={getSizeString(21)}>
        <img width='20px' src={overlay} alt='Thought Leader Content' />
      </Box>
    )
  }
  const OtherUserIcon = () => {
    const positionProps = hasThoughtLeader
      ? { left: getSizeString(-83), top: getSizeString(19) }
      : { left: getSizeString(-55), top: getSizeString(13) }
    return (
      <Box position='relative' {...positionProps}>
        <Tooltip
          title={`${currentUserCount} other users in your group are currently on this step.`}>
          <Box
            display='flex'
            width={getSizeString(20)}
            alignItems='baseline'
            justifyContent='center'>
            <img src={person} width={getSizeString(20)} alt='Other Person' />
            <Typography
              color='primary'
              fontSize={11}
              position='absolute'
              textAlign='center'
              top={getSizeString(10)}>
              {currentUserCount}
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    )
  }

  return (
    <Box display='flex' width='44px'>
      <NumberIconButton
        number={number}
        size={mainIconSize}
        fontSize={fontSize}
        color={color}
        hoverColor={color}
        onClick={onClick}
        disabled={!completed}
        active={active}
      />
      {hasThoughtLeader ? <ThoughtLeaderIcon /> : null}
      {currentUserCount ? <OtherUserIcon /> : null}
    </Box>
  )
}
