import { useState } from 'react'
import { Dialog } from '@mui/material'
import { useTranslation } from 'react-i18next'
import StyledButton from 'components/atoms/button/StyledButton'
import { getLocalUser } from 'utils/localStorage'
import FullReport from 'components/organisms/assessments/fullAssessment/FullReport'

const AssessmentReportDialog = ({ data }) => {
  const { t } = useTranslation()
  const user = getLocalUser()
  const [dialogDownloading, setDialogDownloading] = useState(false)
  const [open, setOpen] = useState(false)

  const dialogSx = {
    '& .MuiDialog-paper': {
      paddingX: 12,
      paddingY: 0,
      width: '100%',
      height: !dialogDownloading ? '90vh' : 0,
      borderRadius: 2
    }
  }

  return (
    <>
      <StyledButton onClick={() => setOpen(true)}>
        {t('fullReport.viewFullReport')}
      </StyledButton>

      <Dialog sx={dialogSx} open={open} onClose={() => setOpen(false)}>
        <FullReport
          assessment={data}
          user={user}
          isInDialog={true}
          setDialogDownloading={setDialogDownloading}
        />
      </Dialog>
    </>
  )
}

export default AssessmentReportDialog
