import { Dialog, Stack, Typography } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import StyledButton, {
  NoBorderButton
} from 'components/atoms/button/StyledButton'

const UnavoidableConfirmCancel = ({
  open,
  title,
  subtitle = '',
  lowFocusText,
  highFocusText,
  lowFocusAction,
  highFocusAction,
  buttonAlignment = 'center',
  dialogWidth = 'initial'
}) => {
  const dialogStyles = {
    '& .MuiDialog-paper': {
      width: dialogWidth
    }
  }

  return (
    <Dialog open={open} sx={dialogStyles}>
      <Stack spacing={12}>
        <Stack spacing={4} padding={0}>
          <Typography variant='h4'>{title}</Typography>
          <Typography variant='body1Large'>{subtitle}</Typography>
        </Stack>
        <RowStack spacing={4} justifyContent={buttonAlignment}>
          <NoBorderButton onClick={lowFocusAction} >
            {lowFocusText}
          </NoBorderButton>
          <StyledButton onClick={highFocusAction} >
            {highFocusText}
          </StyledButton>
        </RowStack>
      </Stack>
    </Dialog>
  )
}

export default UnavoidableConfirmCancel
