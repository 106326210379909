import {
  CardContent,
  CircularProgress,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import PanelsWithScrollBars from 'components/atoms/container/PanelsWithScrollBars'
import InCardStack from 'components/atoms/InCardStack'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText } from 'components/atoms/Text'
import NotebookItem from 'components/molecules/NotebookItem'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { JOURNEY } from 'routes/constants'
import { USER_NOTES_TYPES, USER_NOTES_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import { useTranslation } from 'react-i18next'

const NotebookCard = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [requestError, setRequestError] = useState(undefined)
  const [notes, setNotes] = useState([])
  const [selected, setSelected] = useState(null)
  const [goals, setGoals] = useState([])
  const [activeType, setActiveType] = useState(USER_NOTES_TYPES.GOAL)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(USER_NOTES_URL)
      if (res.status === 200) {
        const noteData = res.data.data
        setNotes(noteData)
        const goalIds = [...new Set(noteData.map(n => n.goalStep.goal_id))]

        const goalInfo = await Promise.all(
          goalIds.map(async id => getGoalInfoById(id))
        )
        goalInfo.sort((a, b) => (a.name > b.name ? 1 : -1))
        setGoals(goalInfo)
        setRequestError(false)
        if (goalInfo.length > 0) {
          if (location.state?.noteData) {
            const selectedGoalId = location.state.noteData.goalStep.goal_id
            const targetGoal = goalInfo.find(g => g.id === selectedGoalId)
            if (targetGoal) {
              setSelected(targetGoal)
            } else setSelected(goalInfo[0])
          } else setSelected(goalInfo[0])
        } else {
          setLoading(false)
        }
      } else {
        setRequestError(true)
      }

      setLoading(false)
    }
    setLoading(true)
    fetchData()
  }, [location])

  const filterNotes = () => {
    return notes.filter(
      n =>
        n.goalStep.goal_id === selected?.id &&
        (activeType === USER_NOTES_TYPES.NOTE
          ? n.type !== USER_NOTES_TYPES.GOAL
          : n.type === activeType)
    )
  }
  return (
    <PanelsWithScrollBars
      leftList={
        <MenuList sx={{ width: '100%' }}>
          {goals.map(g => (
            <MenuItem
              onClick={() => setSelected(g)}
              key={g?.id}
              width='100%'
              style={{ whiteSpace: 'normal', paddingLeft: 0 }}>
              <Typography
                color={g?.id === selected?.id ? 'primary' : 'default'}
                paddingBottom='10px'>
                {g?.name}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      }>
      {loading ? (
        <CardContent>
          <CircularProgress />
        </CardContent>
      ) : selected ? (
        <Stack padding={0}>
          <Typography variant='h3'>{selected.leadershipPathName}</Typography>
          <Tabs
            value={activeType}
            onChange={(e, v) => setActiveType(v)}
            variant='fullWidth'>
            <Tab label={t('notebook.goals')} value={USER_NOTES_TYPES.GOAL} />
            <Tab label={t('notebook.notesActivities')} value={USER_NOTES_TYPES.NOTE} />
          </Tabs>

          <CardContent>
            {filterNotes().length === 0 ? (
              <Stack spacing={10} display='flex' maxWidth='600px'>
                <NotificationBodyText>
                  {activeType === USER_NOTES_TYPES.GOAL
                    ? t('notebook.doNotCurrentlyHaveAnyGoals')
                    : t('notebook.doNotCurrentlyHaveAnyNotes')}
                </NotificationBodyText>
                <LinkButton
                  to={`${JOURNEY}/${selected?.id}`}
                  style={{ alignSelf: 'center', paddingTop: '20px' }}
                  buttonProps={{ sx: { width: 'fit-content' } }}>
                  {t('notebook.visitLearningPath')}
                </LinkButton>
              </Stack>
            ) : (
              <InCardStack width='100%'>
                {filterNotes().map(n => (
                  <NotebookItem key={n.id} data={n} />
                ))}
              </InCardStack>
            )}
          </CardContent>
        </Stack>
      ) : (
        <CardContent>
          <Stack spacing={10} display='flex' maxWidth='600px'>
            <NotificationBodyText>
              {t('notebook.notWorkedOnLearningPaths')}
            </NotificationBodyText>
            <LinkButton
              to={JOURNEY}
              style={{ alignSelf: 'center', paddingTop: '20px' }}
              buttonProps={{ sx: { width: 'fit-content' } }}>
              {t('notebook.startMyLearningJourney')}
            </LinkButton>
          </Stack>
        </CardContent>
      )}
      {requestError ? (
        <CardContent>
          <RequestRetryAlert />
        </CardContent>
      ) : null}
    </PanelsWithScrollBars>
  )
}

export default NotebookCard
