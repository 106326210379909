import { useEffect, useState, useRef } from 'react'
import { Stack, Box } from '@mui/material'
import { OverlayWithText } from 'components/atoms/OverlayLoading'
import { ASSESSMENT_REPORT_SHARE } from 'routes/constants'
import BucketPage from 'components/molecules/assessments/report/BucketPage'
import ShareResultPopupButton from 'components/molecules/assessments/ShareResultPopupButton'
import TitlePage from 'components/molecules/assessments/report/TitlePage'
import CopyrightPage from 'components/molecules/assessments/report/CopyrightPage'
import CategorySummaryPage from 'components/molecules/assessments/report/CategorySummaryPage'
import CategoryTablePage from 'components/molecules/assessments/report/CategoryTablePage'
import ReflectionPage from 'components/molecules/assessments/report/ReflectionPage'
import BehaviourPage from 'components/molecules/assessments/report/BehaviourPage'
import WrapupPage from 'components/molecules/assessments/report/WrapupPage'
import AboutPage from 'components/molecules/assessments/report/AboutPage'
import html2pdf from 'html3pdf'
import { jsPDF } from 'jspdf'
import StyledButton from 'components/atoms/button/StyledButton'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { checkIsMobile } from 'utils/deviceCheck'
import { getStringAbbreviation } from 'utils/formatText'
import { triggerAssessmentRefresh } from 'services/fullAssessmentServices'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const CategoryPagesWrpper = ({ children }) => {
  return <>{children}</>
}

const FullReport = ({
  assessment,
  user,
  isInDialog = false,
  setDialogDownloading
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [models, setModels] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [hideDom, setHideDom] = useState(false)
  const [refreshAssessment, setRefreshAssessment] = useState(false)
  const isMobile = checkIsMobile()
  const reportRef = useRef()

  const getFileName = () => {
    return `Monark${getStringAbbreviation(assessment?.goalData?.name)}Report.pdf`
  }

  const handleDownloadPdf = async () => {
    const element = reportRef.current
    const pages = Array.from(element.children)
    setHideDom(true)
    if (isInDialog) setDialogDownloading(true)
    await exportHTMLToPDF(pages)
    setDownloading(false)
    setHideDom(false)
    if (isInDialog) setDialogDownloading(false)
  }

  const exportHTMLToPDF = async pages => {
    const opt = {
      margin: [0, 0],
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'mm', format: [205, 275], orientation: 'portrait' }
    }
    const doc = new jsPDF(opt.jsPDF)
    const pageSize = jsPDF.getPageSize(opt.jsPDF)
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i]
      const pageImage = await html2pdf().from(page).set(opt).outputImg()
      if (i !== 0) {
        doc.addPage()
      }
      doc.addImage(
        pageImage.src,
        'jpeg',
        opt.margin[0],
        opt.margin[1],
        pageSize.width,
        pageSize.height
      )

      // Update progress
      const progress = (((i + 1) / pages.length) * 100) / 100
      setDownloadProgress(progress)
    }

    const fileName = getFileName()
    doc.save(fileName)
    return true
  }

  useEffect(() => {
    if (downloading) {
      handleDownloadPdf()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloading])

  const initDownload = async () => {
    if (assessment.pdfLink) {
      // Open pdf link in new tab
      window.open(assessment.pdfLink, '_blank')
      setRefreshAssessment(false)
    } else if (!refreshAssessment) {
      // Trigger refresh assessment
      triggerAssessmentRefresh()
      setRefreshAssessment(true)
    } else {
      // Trigger html to pdf download
      setDownloading(true)
      setRefreshAssessment(false)
    }
  }

  useEffect(() => {
    if (refreshAssessment) {
      initDownload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment])

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        initDownload()
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  useEffect(() => {
    const newModels = [
      ...new Map(
        assessment?.assessmentCategories?.map(c => [
          c?.modelName,
          { name: c?.modelName, color: c?.color }
        ])
      ).values()
    ]
    setModels(newModels)
  }, [assessment])

  let pageNum = 0
  let activeModelIndex = null

  return (
    <>
      <Box display='flex' justifyContent='center' padding={10} gap={6}>
        <StyledButton
          size={'small'}
          onClick={initDownload}
          disabled={downloading}
          sx={{ gap: 2.5 }}>
          <FileDownloadOutlinedIcon />
          {t('fullReport.downloadAsPDF')}
        </StyledButton>
        {location?.pathname !== ASSESSMENT_REPORT_SHARE && (
          <ShareResultPopupButton
            inverseTheme
            copyUrl={assessment?.pdfLink || assessment?.shareableLink}
            assessmentId={assessment?.id}
            title={t('fullReport.shareYourReport')}
            buttonLabel={t('fullReport.shareReport')}
          />
        )}
      </Box>
      {!hideDom && (
        <Stack
          ref={reportRef}
          sx={{
            padding: 0,
            gap: downloading ? 0 : 10,
            zoom: downloading ? 1 : isMobile ? 0.4 : isInDialog ? 0.7 : 1
          }}>
          {user && (
            <>
              <TitlePage
                userData={user}
                downloadingFile={downloading}
                assessmentName={assessment?.goalData?.name}
              />

              <CopyrightPage
                downloadingFile={downloading}
                assessmentName={assessment?.goalData?.name}
                pageNum={++pageNum}
              />

              <BucketPage
                downloadingFile={downloading}
                goalData={assessment?.goalData}
                models={models}
                pageNum={++pageNum}
              />

              {assessment?.assessmentCategories.map((category, index) => {
                const newModelIndex = models.findIndex(
                  m => m.name === category.modelName
                )
                let showBucketPage = false
                if (activeModelIndex !== newModelIndex) {
                  activeModelIndex = newModelIndex
                  showBucketPage = true
                }
                return (
                  <CategoryPagesWrpper key={`category${index}`}>
                    {showBucketPage && (
                      <BucketPage
                        downloadingFile={downloading}
                        goalData={assessment.goalData}
                        models={models}
                        activeIndex={activeModelIndex}
                        pageNum={++pageNum}
                      />
                    )}

                    <CategorySummaryPage
                      downloadingFile={downloading}
                      goalData={assessment.goalData}
                      categoryData={category}
                      pageNum={++pageNum}
                    />

                    <CategoryTablePage
                      downloadingFile={downloading}
                      categoryData={category}
                      assessmentName={assessment.goalData?.name}
                      pageNum={++pageNum}
                    />

                    {category?.data?.behaviourInfoData.map(
                      (behavior, behaviorIndex) => (
                        <BehaviourPage
                          key={`behaviour${index}${behaviorIndex}`}
                          downloadingFile={downloading}
                          categoryData={category}
                          behaviorData={behavior}
                          behaviorIndex={behaviorIndex}
                          assessmentName={assessment.goalData?.name}
                          pageNum={++pageNum}
                        />
                      )
                    )}
                  </CategoryPagesWrpper>
                )
              })}

              {assessment.comments && (
                <ReflectionPage
                  downloadingFile={downloading}
                  assessmentName={assessment?.goalData?.name}
                  comments={assessment.comments}
                  pageNum={++pageNum}
                />
              )}

              <WrapupPage
                downloadingFile={downloading}
                assessmentName={assessment?.goalData?.name}
                pageNum={++pageNum}
              />

              <AboutPage
                downloadingFile={downloading}
                assessmentName={assessment?.goalData?.name}
                pageNum={++pageNum}
              />
            </>
          )}
        </Stack>
      )}
      <OverlayWithText
        isOpen={downloading}
        message={t('fullReport.pleaseWait')}
        progress={downloadProgress}
      />
    </>
  )
}

export default FullReport
