import {
  Autocomplete,
  Divider,
  Icon,
  IconButton,
  Paper,
  TextField,
  Typography,
  createFilterOptions
} from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import InCardStack from 'components/atoms/InCardStack'
import { ActionText } from 'components/atoms/Text'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SEARCH_RESULTS } from 'routes/constants'
import colors from 'styles/colors'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'

const SearchHeaderTag = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const onSearchPage = location.pathname.indexOf(SEARCH_RESULTS) === 0
  const params = useParams()
  const user = getLocalUser()
  const navigate = useNavigate()
  const [inputActivated, setInputActivated] = useState(onSearchPage || false)
  const [searchText, setSearchText] = useState(params.searchText || '')
  const [searchHistory, setSearchHistory] = useState([])
  const filter = createFilterOptions()

  const historyKey = `searchHistory-${user.id}`
  useEffect(() => {
    const previousHistory = localStorage.getItem(historyKey)
    if (previousHistory) {
      setSearchHistory(JSON.parse(previousHistory))
    }
  }, [historyKey])

  const addToSearchHistory = () => {
    if (searchHistory.indexOf(searchText.toLowerCase()) < 0) {
      searchHistory.push(searchText.toLowerCase())
      localStorage.setItem(
        historyKey,
        JSON.stringify(searchHistory.sort((a, b) => (a > b ? 1 : -1)))
      )
    }
  }

  const handleSubmit = event => {
    if (searchText.length > 0) {
      addToSearchHistory()
      navigate(`${SEARCH_RESULTS}/${searchText}`)
    } else {
      event?.preventDefault()
    }
  }

  const deactivateSearch = () => {
    if (!onSearchPage && !searchText?.length) {
      setInputActivated(false)
    }
  }

  return (
    <InCardStack direction='row'>
      {inputActivated ? (
        <Paper
          component='form'
          onSubmit={handleSubmit}
          onBlur={() => deactivateSearch()}
          sx={{ borderRadius: 5, paddingX: 8 }}>
          <InCardStack direction='row' spacing={5}>
            <Icon fontSize='small' color='disabled'>
              search
            </Icon>
            <Autocomplete
              freeSolo
              autoSelect
              size='small'
              value={searchText}
              noOptionsText={t('searchArea.noPreviousSearch')}
              options={searchHistory}
              onChange={(e, value) => setSearchText(value)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                return filtered.slice(0, 5)
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  autoFocus
                  placeholder={t('searchArea.search')}
                  size='small'
                  variant='standard'
                  sx={{
                    color: colors.Aluminium,
                    minWidth: '200px',
                    '& .MuiInput-root:before': { display: 'none' },
                    '& .MuiInput-root:after': { display: 'none' }
                  }}
                  onChange={e => setSearchText(e.target.value)}
                />
              )}
              renderOption={(props, option) => (
                <Typography {...props} key={option}>
                  <Icon
                    fontSize='small'
                    sx={{ color: colors.Aluminium, marginRight: 5 }}>
                    history
                  </Icon>
                  {option}
                </Typography>
              )}
              clearIcon={
                <Icon fontSize='small' sx={{ color: colors.Aluminium }}>
                  {searchText?.length ? 'clear' : null}
                </Icon>
              }
            />
            <IconButton
              onClick={() => handleSubmit()}
              sx={{
                padding: 0,
                color: 'black',
                height: '20px',
                width: '20px'
              }}
              disableRipple>
              <ArrowRightAltIcon
                fontSize='large'
                sx={{ transform: 'scale(0.9)' }}
              />
            </IconButton>
          </InCardStack>
        </Paper>
      ) : (
        <ActionText
          variant='caption'
          onClick={() => setInputActivated(true)}
          style={{
            color: colors.Aluminium,
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Icon fontSize='small'>search</Icon>
          {t('searchArea.contentSearch')}
        </ActionText>
      )}

      <Divider orientation='vertical' sx={{ height: '20px' }} />
    </InCardStack>
  )
}

export default SearchHeaderTag
