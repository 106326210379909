/**
 * LinkRoute is just any name for all the Link tags you will use throughout the app.
 * It is recommended to keep the styling and other stuff consistant.
 * All Link tags that are pointing to routes within this app should be improted from react-router-dom.
 */
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@mui/material'

const LinkRoute = props => {
  return (
    <RouterLink to={props.to} state={props.state}>
      <Link component='button' style={{textDecoration:'none'}}>{props.children}</Link>
    </RouterLink>
  )
}

export default LinkRoute
