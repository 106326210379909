import { getRequest } from 'utils/api'
import {
  ASSESSMENT_PROGRESS_URL,
  ASSESSMENT_BY_GOAL_URL,
  FEATURE_KEYS,
  ASSESSMENT_STATUSES
} from './constants'
import { getFullName, capitalizeFirstLetter } from 'utils/formatText'
import { getGoalInfoById } from 'utils/getSupportingInfo'
import { Subject } from 'rxjs'

export const getAssessmentProgress = async organizationId => {
  const res = await getRequest(ASSESSMENT_PROGRESS_URL, {
    featureKey: FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT,
    organizationId
  })
  if (res.status === 200) {
    return res.data.data
  }
  return null
}

export const getAssessmentByGoal = async goalId => {
  const res = await getRequest(ASSESSMENT_BY_GOAL_URL, {
    goal_id: goalId
  })
  if (res.status === 200) {
    return res.data.data
  }
  return null
}

// Function to emit refresh events Assessment data
export const refreshAssessmentDataSubject = new Subject()
export const triggerAssessmentRefresh = () => {
  refreshAssessmentDataSubject.next()
}

export const getTeamData = async organizationId => {
  const res = await getAssessmentProgress(organizationId)
  const result = {}
  if (res) {
    result.team = await Promise.all(
      res.map(async team => {
        const fullName = capitalizeFirstLetter(getFullName(team.user))
        const email = fullName === team.user.email ? null : team.user.email
        const goalInfo = await getGoalInfoById(team.goalId)
        return {
          ...team,
          fullName,
          email,
          goalName: goalInfo?.name || ''
        }
      })
    )
    result.completionRate = Math.round(
      res
        ?.filter(team => team?.status === ASSESSMENT_STATUSES.CLOSED)
        .reduce((total, team) => total + 100, 0) / res?.length || 0
    )
    result.participants = res.length
    result.completedAssessments =
      res.filter(team => team?.status === ASSESSMENT_STATUSES.CLOSED).length ||
      0
  }
  return result
}
