import { Box, Typography, useTheme } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import CircleIcon from '@mui/icons-material/Circle'
import { TaskErrorIcon } from 'components/atoms/Icons'
import 'styles/fonts/fonts.css'
import colors from 'styles/colors'
import { useNavigate } from 'react-router-dom'

export const AttributeLabel = props => {
  const { children } = props
  return (
    <Typography {...props} variant='body1' component='span'>
      {children}&nbsp;&nbsp;
    </Typography>
  )
}

export const AttributeValue = props => {
  const { children } = props
  return (
    <Typography {...props} variant='subtitle2' component='span'>
      {children}
    </Typography>
  )
}

export const AttributeLabelValue = ({ label, value }) => (
  <Typography>
    <AttributeLabel>
      {label}
      {':'}
    </AttributeLabel>
    <AttributeValue>{value}</AttributeValue>
  </Typography>
)

export const ActionText = props => {
  const { children, sx, ...otherProps } = props
  return (
    <Typography
      {...otherProps}
      color='primary'
      component='a'
      sx={[
        sx,
        {
          textDecoration: 'none',
          background: 'none',
          '&:hover': { cursor: 'pointer' }
        }
      ]}>
      {children}
    </Typography>
  )
}

export const SeeAllLinkText = props => {
  const { from, to, text = 'See All', ...otherProps } = props
  const navigate = useNavigate()
  return (
    <Typography
      {...otherProps}
      onClick={() => navigate(to, { state: { from } })}
      color={colors.Aluminium}
      component='a'
      sx={{
        textDecoration: 'none',
        background: 'none',
        paddingX: '15px',
        '&:hover': { cursor: 'pointer' }
      }}>
      {text}
    </Typography>
  )
}

export const TextSeperationDot = props => {
  const { size = '10px', sx } = props
  return <FiberManualRecordIcon sx={[{ width: size, height: size }, sx]} />
}

export const CircleNumber = props => {
  const theme = useTheme()
  const {
    value,
    color = theme.palette.background.paper,
    textColor = 'primary',
    size = '50px',
    variant = 'h3',
    ...otherProps
  } = props
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='relative'
      component='span'
      {...otherProps}>
      <CircleIcon sx={{ color, width: size, height: size }} fontSize='large' />
      <Typography
        position='absolute'
        color={textColor}
        variant={variant}
        component='span'>
        {value}
      </Typography>
    </Box>
  )
}

export const Subtitle3 = props => {
  const { children, ...otherProps } = props
  return (
    <Typography
      fontWeight={500}
      fontSize='16px'
      {...otherProps}
    >
      {children}
    </Typography>
  )
}

export const Subtitle4 = props => {
  const { children, ...otherProps } = props
  return (
    <Typography {...otherProps} fontWeight='bold' color={colors.Aluminium}>
      {children}
    </Typography>
  )
}

export const PageHeader = props => {
  const { children, ...otherProps } = props
  return (
    <Typography {...otherProps} variant='h3' fontSize='24px'>
      {children}
    </Typography>
  )
}

export const NotificationBodyText = props => {
  const { children, fontSize = '20px', ...otherProps } = props
  return (
    <Typography
      {...otherProps}
      fontSize={fontSize}
      color={colors.Aluminium}
      fontWeight={500}>
      {children}
    </Typography>
  )
}

export const Tag = ({ children, sx, ...otherProps }) => {
  return (
    <Typography
      sx={[{
        background: colors.Grey,
        paddingX: 4,
        paddingY: 3,
        fontSize: 13,
        borderRadius: 1,
        fontWeight: 500,
      }, sx]}
      {...otherProps}>
      {children}
    </Typography>
  )
}

export const ErrorText = ({ children, ...otherProps }) => {
  return (
    <Box
      display='flex'
      component='span'
      gap={2}
      alignItems={'baseline'}
      {...otherProps}>
      <TaskErrorIcon size={13} color={'error'} />

      <Typography
        variant='body1Micro'
        fontStyle='italic'
        color={colors.ErrorRed}
        whiteSpace={'normal'}
        {...otherProps}
      >
        {children}
      </Typography>
    </Box>
  )
}
