import { CardContent } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { USER_FEEDBACK_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import FeedbackItem from 'components/molecules/FeedBackItem'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText } from 'components/atoms/Text'
import { useTranslation } from 'react-i18next'

const UserFeedbackCard = ({
  isHomePage = false,
  feedbackCountCallback,
  displayCount,
  hasLicense
}) => {
  const { t } = useTranslation()
  const [feedbackData, setFeedbackData] = useState([])
  const [dataRequestError, setDataRequestError] = useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(USER_FEEDBACK_URL)
      if (res.status === 200) {
        const data = res.data.data

        if (isHomePage) {
          feedbackCountCallback(data.length)
          setFeedbackData(data.slice(0, displayCount))
        } else {
          setFeedbackData(data)
        }
      } else {
        setDataRequestError(true)
      }
    }
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box width='100%' minWidth='500px' data-testid='user-feedback-list'>
        {feedbackData.length > 0 ? (
          feedbackData.map(feedback => (
            <FeedbackItem key={feedback.id} feedback={feedback} showReply={hasLicense}/>
          ))
        ) : dataRequestError === true ? (
          <RequestRetryAlert />
        ) : (
          <CardContent>
            <NotificationBodyText>
              {t('feedbackArea.noFeedbackFromTeam')}
            </NotificationBodyText>
          </CardContent>
        )}
      </Box>
    </>
  )
}

export default UserFeedbackCard
