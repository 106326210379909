import React from 'react'
import { Box, Typography } from '@mui/material'
import { lightGreen, yellow, red, grey } from '@mui/material/colors'
import { getPercentageDisplay } from 'utils/formatText'
import { VictoryLabel, VictoryPie } from 'victory'
import colors from 'styles/colors'

export const radarGraphLocationString = 'RadarGraph'

// Custom Label Component using MUI Typography
const RenderLabel = props => {
  const { x, y, children } = props
  return (
    <Typography x={x} y={y} component='text'>
      {children.map((child, index) => {
        const childProps = child?.props || {}
        return (
          <Typography
            key={index}
            textAnchor={childProps.textAnchor || 'middle'}
            x={childProps?.x || x}
            dy={index === 0 ? (children.length === 1 ? 0 : '0.4em') : '2em'}
            style={{
              lineHeight: '150%',
              ...childProps?.style,
              fontFamily: 'inherit'
            }}
            component='tspan'>
            {childProps?.children}
          </Typography>
        )
      })}
    </Typography>
  )
}

const CircularProgressBar = ({
  value = 0,
  zeroValueLabel,
  containerSx = {},
  barColor = '',
  useGradientArch = false,
  emptyArchColor = 'transparent',
  innerRadius = 120,
  cornerRadius = 25,
  backgroundColor = '',
  labelSubText = '',
  labelStyle = { fontSize: 60, fontWeight: 400 }
}) => {
  const percentage = value * 100
  const primaryColor = barColor
    ? barColor
    : percentage > 70
      ? lightGreen[600]
      : percentage > 10
        ? yellow[700]
        : percentage > 0
          ? red[700]
          : grey[200]
  const background = backgroundColor
    ? backgroundColor
    : percentage > 70
      ? lightGreen[100]
      : percentage > 10
        ? yellow[100]
        : percentage > 0
          ? red[100]
          : grey[200]

  const getData = () => {
    return [
      { x: 1, y: percentage },
      { x: 2, y: 100 - percentage }
    ]
  }

  const getText = () => {
    return percentage > 0
      ? labelSubText
        ? [getPercentageDisplay(value), labelSubText]
        : getPercentageDisplay(value)
      : zeroValueLabel ?? getPercentageDisplay(0)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: 100,
        height: 100,
        ...containerSx
      }}>
      <svg viewBox='0 0 400 400'>
        <circle cx={200} cy={200} r={150} fill={background} />
        {useGradientArch && (
          <defs>
            <linearGradient id='gradient' gradientTransform='rotate(90)'>
              <stop offset='0%' stopColor={colors.Teal400} />
              <stop offset='50%' stopColor={colors.GoldenYellow400} />
              <stop offset='100%' stopColor={colors.Watermelon400} />
            </linearGradient>
          </defs>
        )}
        <VictoryPie
          standalone={false}
          width={400}
          height={400}
          data={getData(percentage)}
          innerRadius={innerRadius}
          cornerRadius={cornerRadius}
          labels={() => null}
          style={{
            data: {
              fill: ({ datum }) => {
                return datum.x === 1
                  ? !useGradientArch
                    ? primaryColor
                    : 'url(#gradient)'
                  : emptyArchColor
              }
            }
          }}
        />
        <VictoryLabel
          textAnchor='middle'
          verticalAnchor='middle'
          x={200}
          y={200}
          text={getText}
          textComponent={<RenderLabel />}
          style={labelStyle}
        />
      </svg>
    </Box>
  )
}

export default CircularProgressBar
