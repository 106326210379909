import { Typography, useTheme } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import { getPercentageDisplay } from 'utils/formatText'
import { Tag } from 'components/atoms/Text'
import colors from 'styles/colors'
import CheckIcon from '@mui/icons-material/Check'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import LinearProgressBar from 'components/atoms/LinearProgressBar'

const FullAssessmentHeader = ({
  models,
  saved,
  scores,
  activeModelName,
  questionsLength
}) => {
  const theme = useTheme()
  const AssessmentModelProgressBar = ({ model, active }) => {
    const progress = () => {
      const questions = model.categories.map(c => c.questions).flat()
      const answeredQuestions = questions.filter(
        q => scores[q.id] !== undefined
      )
      return (answeredQuestions.length / questions.length) * 100
    }
    return (
      <LinearProgressBar
        value={progress()}
        label={model.name}
        width={120}
        labelSx={{
          color: progress() === 0 ? undefined : theme.palette.primary.main,
          fontWeight: active ? 'bold' : 'normal'
        }}
      />
    )
  }
  const completePercentage = () => {
    let questionsCompleted = Object.keys(scores).length / questionsLength
    if (questionsCompleted === 1) {
      questionsCompleted = 0.99
    }
    return getPercentageDisplay(questionsCompleted)
  }
  const iconProps = {
    fontSize: 'small',
    width: 10,
    height: 10,
    sx: { marginLeft: 1 }
  }
  return (
    <>
      <RowStack width='fit-content' spacing={5}>
        <Typography>{completePercentage()} complete</Typography>
        <Tag
          sx={{
            paddingY: 2,
            backgroundColor: saved ? colors.LightBlue : colors.Grey100,
            display: 'flex',
            alignItems: 'center'
          }}>
          {saved ? 'Saved' : 'Saving'}
          {saved ? (
            <CheckIcon {...iconProps} />
          ) : (
            <AutorenewIcon {...iconProps} />
          )}
        </Tag>
      </RowStack>
      <RowStack width='fit-content'>
        {models.map(m => (
          <AssessmentModelProgressBar
            model={m}
            active={m.name === activeModelName}
            key={`model-progress-${m.name}`}
          />
        ))}
      </RowStack>
    </>
  )
}

export default FullAssessmentHeader
