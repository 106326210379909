import { alpha, Box, Grid, useTheme } from '@mui/material'
import { Subtitle3 } from 'components/atoms/Text'
import { useTranslation } from 'react-i18next'

const StepPathObjectHeader = ({ l1StepName, goalName, bookmarkIcon }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Box
      margin='12px'
      padding='12px'
      borderRadius='8px'
      sx={{
        border: 1,
        borderColor: theme.palette.secondary.light,
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
        borderWidth: '1px'
      }}>
      <Grid container>
        <Grid item xs={11}>
          <Subtitle3>{t('pathsAndModels.path')}: {goalName}</Subtitle3>
          <Subtitle3>{t('pathsAndModels.milestone')}: {l1StepName}</Subtitle3>
        </Grid>
        <Grid item xs={1} display='flex' justifyContent='flex-end'>
          {bookmarkIcon}
        </Grid>
      </Grid>
    </Box>
  )
}
export default StepPathObjectHeader
