import * as yup from 'yup'
import { useFormik } from 'formik'
import {
  Alert,
  Button,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  Typography
} from '@mui/material'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import {
  ActionText,
  AttributeLabel,
  PageHeader,
  TextSeperationDot
} from 'components/atoms/Text'
import FormSelectInput from 'components/atoms/input/FormSelectInput'
import { useState } from 'react'
import FormInputBox from 'components/atoms/input/FormInputBox'
import { validationsRules } from 'utils/formValidations'
import { JOURNEY, PULSE_SURVEYS } from 'routes/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import UserConnectionSelectMultiple from 'components/atoms/input/UserConnectionSelectMultiple'
import Close from '@mui/icons-material/Close'
import { getRequest, postRequest } from 'utils/api'
import {
  PULSE_SURVEYS_QUESTIONS_URL,
  PULSE_SURVEYS_URL
} from 'services/constants'
import moment from 'moment'
import SuccessfulNotificationDialog from 'components/molecules/notificationOverlay/SuccessfulNotificationDialog'
import ThemeListItemButton from 'components/atoms/button/ThemeListItemButton'
import LowFocusButton from 'components/atoms/button/LowFocusButton'
import SinglePageStack from 'components/templates/SinglePageStack'
import { useTranslation } from 'react-i18next'

const PulseSurveySendCard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(
    location.state?.pulseSurveyQuestion
  )
  const [questionList, setQuestionList] = useState([])
  const [questionRequestSuccess, setQuestionRequestSuccess] =
    useState(undefined)
  const [createSuccess, setCreateSuccess] = useState(undefined)
  const showSelectQuestion = Boolean(!location.state?.pulseSurveyQuestion)

  const formik = useFormik({
    initialValues: {
      questionText: selectedQuestion?.questionText || '',
      sendIn: 0,
      recipients: []
    },
    validationSchema: yup.object({
      questionText: yup
        .string()
        .required(t('pulseSurvey.pleaseSelectQuestion')),
      sendIn: validationsRules.textRequired,
      recipients: yup.array().min(3, t('pulseSurvey.pleaseSelectMinRecipients'))
    }),
    onSubmit: async values => {
      const sendAt = moment().add(values.sendIn, 'days')
      const requestBody = {
        pulseSurveyQuestionText:
          values.questionText === selectedQuestion?.questionText
            ? null
            : values.questionText,
        pulseSurveyQuestionId:
          values.questionText === selectedQuestion?.questionText
            ? selectedQuestion.id
            : null,
        recipients: values.recipients.map(r => r.user?.id),
        sendAt
      }
      const res = await postRequest(PULSE_SURVEYS_URL, requestBody)
      if (res.status === 201) {
        setCreateSuccess(true)
      } else {
        setCreateSuccess(false)
      }
    }
  })

  const fetchQuestionData = async () => {
    const res = await getRequest(PULSE_SURVEYS_QUESTIONS_URL)
    if (res.status === 200) {
      const data = res.data.data
      setQuestionList(data)
      setQuestionRequestSuccess(true)
    } else {
      setQuestionRequestSuccess(false)
    }
  }
  const handleQestionSelect = value => {
    if (value) {
      formik.setFieldValue('questionText', value.questionText)
      setSelectedQuestion(value)
    }
    setQuestionDialogOpen(false)
  }

  const openQuestionDialog = () => {
    if (questionList.length === 0) {
      fetchQuestionData()
    }
    setQuestionDialogOpen(true)
  }

  const handleDoneNavigation = () => {
    if (location.state?.goalStepId) {
      navigate(`${JOURNEY}/${location.state?.goalId}`, {
        state: { goalStepId: location.state.goalStepId }
      })
    } else {
      navigate(PULSE_SURVEYS)
    }
  }

  const cardStackStyle = { width: '100%', alignItems: 'flex-start', padding: 0 }
  return (
    <SinglePageStack data-testid='pulse-survey-send-card'>
      <PageHeader>{t('pulseSurvey.sendPulseSurvey')}</PageHeader>
      <CardContent>
        <Typography variant='text' textAlign='center'>
          {t('pulseSurvey.recipientsWillAnswer.part1')} <br /> <br />
          {t('pulseSurvey.recipientsWillAnswer.part2')}
        </Typography>
      </CardContent>
      <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
        <LeftJustifyCardContent sx={{ width: '100%' }}>
          <Stack spacing={5} sx={cardStackStyle}>
            <Stack sx={cardStackStyle}>
              <Typography>
                <AttributeLabel>{t('pulseSurvey.question')}</AttributeLabel>
                {showSelectQuestion ? (
                  <>
                    <TextSeperationDot />
                    <ActionText
                      onClick={() => {
                        openQuestionDialog()
                      }}>
                      &nbsp;&nbsp;{t('pulseSurvey.selectQuestion')}
                    </ActionText>
                  </>
                ) : null}
              </Typography>
              <FormInputBox
                fullWidth
                multiline
                rows={4}
                type='text'
                placeholder={t('pulseSurvey.questionPlaceholder')}
                formik={formik}
                attributeName='questionText'
              />
            </Stack>
            <Stack sx={cardStackStyle}>
              <AttributeLabel>{t('pulseSurvey.recipients')}</AttributeLabel>
              <UserConnectionSelectMultiple formik={formik} />
            </Stack>
            <Stack sx={cardStackStyle}>
              <AttributeLabel>{t('pulseSurvey.selectTime')}</AttributeLabel>
              <FormSelectInput
                type='text'
                name='sendIn'
                formik={formik}
                attributeName='sendIn'>
                <MenuItem key={0} value={'0'}>
                  {t('pulseSurvey.now')}
                </MenuItem>
                <MenuItem key={1} value={'1'}>
                  {t('pulseSurvey.after1Day')}
                </MenuItem>
                <MenuItem key={2} value={'3'}>
                  {t('pulseSurvey.after3Days')}
                </MenuItem>
                <MenuItem key={3} value={'7'}>
                  {t('pulseSurvey.after1Week')}
                </MenuItem>
              </FormSelectInput>
            </Stack>
          </Stack>
        </LeftJustifyCardContent>
        <CardContent>
          <LowFocusButton onClick={handleDoneNavigation}>
            {t('common.cancel')}
          </LowFocusButton>
          <Button sx={{ marginLeft: '30px' }} type='submit'>
            {t('common.send')}
          </Button>
        </CardContent>
        {createSuccess === false ? (
          <CardContent sx={{ paddingTop: 0 }}>
            <Alert severity='error'>
              {t('pulseSurvey.createFailed')}
            </Alert>
          </CardContent>
        ) : null}
      </form>
      <Dialog
        open={questionDialogOpen}
        onClose={() => handleQestionSelect(undefined)}>
        <DialogTitle>
          {t('pulseSurvey.pulseSurveyQuestion')}
          <IconButton
            sx={{ float: 'right', paddingTop: '2px' }}
            onClick={() => handleQestionSelect(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <Stack width='600px' alignItems='flex-start' sx={{ paddingTop: 0 }}>
          {questionRequestSuccess ? (
            questionList.map(question => (
              <ThemeListItemButton
                key={question.id}
                onClick={() => handleQestionSelect(question)}>
                {question.questionText}
              </ThemeListItemButton>
            ))
          ) : questionRequestSuccess === undefined ? (
            <Typography variant='subtitle2'>{t('pulseSurvey.fetchingQuestions')}</Typography>
          ) : (
            <Alert severity='error'>
              {t('pulseSurvey.notAbleToRetrieve')}
            </Alert>
          )}
        </Stack>
      </Dialog>
      <SuccessfulNotificationDialog
        open={Boolean(createSuccess)}
        titleText={t('pulseSurvey.createSuccess')}
        notificationText={t('pulseSurvey.allowAtLeast3Response')}
        confirmButtonText={t('common.done')}
        buttonAction={handleDoneNavigation}
      />
    </SinglePageStack>
  )
}

export default PulseSurveySendCard
