import colors from 'styles/colors'
import InCardStack from './InCardStack'

const { LinearProgress, Typography } = require('@mui/material')

const LinearProgressBar = ({ value, label, labelSx, width = 150 }) => {
  return (
    <InCardStack spacing={4}>
      <LinearProgress
        variant='determinate'
        value={value}
        sx={{
          width,
          height: 8,
          borderRadius: 4,
          backgroundColor: colors.Grey200
        }}
      />
      {label && (
        <Typography
          maxWidth={width}
          textAlign='center'
          variant='body1Small'
          color={colors.Grey300}
          sx={labelSx}
          >
          {label}
        </Typography>
      )}
    </InCardStack>
  )
}

export default LinearProgressBar
