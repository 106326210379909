import { Button } from '@mui/material'
import ShareReportDialog from 'components/molecules/assessments/ShareReportDialog'
import StyledButton from 'components/atoms/button/StyledButton'
import { useState } from 'react'

const ShareResultPopupButton = ({
  inverseTheme = false,
  title,
  buttonLabel,
  assessmentId,
  copyUrl = null, // Optional, copy button won't show up without it
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      { inverseTheme ?
          <StyledButton
            size={'small'}
            onClick={() => setIsOpen(true)}
            inverseTheme
          >
            {buttonLabel}
          </StyledButton>
        :
          <Button {...otherProps} onClick={() => setIsOpen(true)}>
            {buttonLabel}
          </Button>
      }

      <ShareReportDialog
        title={title}
        isOpen={isOpen}
        copyUrl={copyUrl}
        assessmentId={assessmentId}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

export default ShareResultPopupButton
