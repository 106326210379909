import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

const LinkButton = props => {
  const {style={}, buttonProps={}, ...otherProps} = props
  return (
    <Link {...otherProps} style={{textDecoration:'none', ...style}}>
      <Button fullWidth {...buttonProps}>{props.children}</Button>
    </Link>
  )
}

export default LinkButton
