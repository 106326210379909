import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import { postRequest } from 'utils/api'
import { VERIFY_EMAIL_URL } from 'services/constants'
import InCardStack from 'components/atoms/InCardStack'
import { TaskSuccessIcon } from 'components/atoms/Icons'
import LinkButton from 'components/atoms/button/LinkButton'
import { HOME } from 'routes/constants'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import SingleWhitePageCard from 'components/templates/SingleWhitePageCard'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'

const SendEmailVerification = () => {
  const { t } = useTranslation()
  const [userInfo] = useState(getLocalUser())
  const [requestSuccess, setRequestSuccess] = useState(undefined)

  const resendEmail = async () => {
    const res = await postRequest(VERIFY_EMAIL_URL)
    if (res.status === 200) {
      setRequestSuccess(true)
    } else {
      setRequestSuccess(false)
    }
  }
  return (
    <HomePage>
      <SingleWhitePageCard title={t('authPages.verifyEmailAddress')} mainBodyWidth='850px'>
        {/* <ProfileCard submitButtonText='Save Changes' /> */}
        <InCardStack align='center'>
          <Typography variant='text'>
            {t('authPages.mustVerifyEmailAddress')}
          </Typography>

          <Typography variant='text'>
              {t('authPages.signedUpForMonark')}

              <Typography variant='subtitle1'>
                  {userInfo.email}
              </Typography>
          </Typography>

          <Typography variant='text'>
            {t('authPages.verificationEmailWillBeSent')}
          </Typography>
          
          {requestSuccess === true ? (
            <>
              <TaskSuccessIcon />
              <Typography variant='text'>
                  {t('authPages.verificationEmailSent')}
                  <br /> {t('authPages.checkYourInbox')}
              </Typography>
              <LinkButton to={HOME}>{t('assessmentTimeline.returnHome')}</LinkButton>
            </>
          ) : (
            <>
              <Button onClick={() => resendEmail()} sx={{ marginTop: '40px !important' }}>
                {t('authPages.sendVerificationEmail')}
              </Button>
            </>
          )}
          {requestSuccess === false ? (
            <RequestRetryAlert />
          ) : null}
        </InCardStack>
      </SingleWhitePageCard>
    </HomePage>
  )
}

export default SendEmailVerification
