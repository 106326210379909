import {
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PULSE_SURVEY_RATE } from 'routes/constants'
import { PULSE_SURVEY_RECIPIENTS_URL } from 'services/constants'
import { getRequest, patchRequest } from 'utils/api'
import SimpleNotificationDialog from 'components/molecules/notificationOverlay/SimpleNotificationDialog'
import AcceptRejectCell from 'components/atoms/table/AcceptRejectCell'
import moment from 'moment'
import { formatDate } from 'utils/formatDateTime'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText } from 'components/atoms/Text'
import { getFullName } from 'utils/formatText'
import { useTranslation } from 'react-i18next'

const PulseSurveyInvites = ({
  smallItemView = false,
  countCallback,
  displayCount
}) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [requestError, setRequestError] = useState(undefined)
  const [surveyInvites, setSurveyInvites] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selected, setSelected] = useState(undefined)

  const fetchData = async () => {
    const res = await getRequest(PULSE_SURVEY_RECIPIENTS_URL)
    if (res.status === 200) {
      const data = res.data.data
      if (smallItemView) {
        setSurveyInvites(data.slice(0, displayCount))
        countCallback(data.length)
      } else {
        setSurveyInvites(data)
      }

      setRequestError(false)
    } else {
      setRequestError(true)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rejectSurvey = inviteId => {
    setSelected(inviteId)
    setDialogOpen(true)
  }

  const onRejectDialogClose = async keep => {
    if (!keep) {
      const res = await patchRequest(
        `${PULSE_SURVEY_RECIPIENTS_URL}/${selected}`,
        {
          status: 'rejected'
        }
      )
      if (res.status === 200) {
        await fetchData()
      } else {
        setRequestError(true)
      }
    }
    setDialogOpen(false)
  }

  const AvartarHeader = ({ invite, subHeader }) => (
    <CardHeader
      avatar={<ProfileAvatar user={invite.createdByUser} />}
      subheader={subHeader ? formatDate(invite.createdAt, i18n.resolvedLanguage) : undefined}
      title={getFullName(invite.createdByUser)}
      sx={{ padding: 0 }}
    />
  )
  return (
    <>
      {surveyInvites.length > 0 ? (
        smallItemView ? (
          <Grid container>
            {surveyInvites.map(invite => (
              <Grid item xs={6} minWidth='350px' key={invite.pulseSurveyId}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <AvartarHeader invite={invite} subHeader={true} />
                        </TableCell>
                        <AcceptRejectCell
                          width='100px'
                          acceptClick={() =>
                            navigate(
                              `${PULSE_SURVEY_RATE}/${invite.pulseSurveyId}`,
                              {
                                state: {
                                  pulseSurvey: invite
                                }
                              }
                            )
                          }
                          rejectClick={() => rejectSurvey(invite.id)}
                        />
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ))}
          </Grid>
        ) : (
          <CardContent data-testid='pulse-survey-invites'>
            <TableContainer>
              <Table>
                <TableBody>
                  {surveyInvites.map(invite => (
                    <TableRow
                      key={invite.pulseSurveyId}
                      sx={{ borderBottomWidth: '1px' }}>
                      <TableCell>
                        <AvartarHeader invite={invite} />
                      </TableCell>
                      <TableCell>
                        {t('pulseSurvey.receivedOn')} {formatDate(invite.createdAt, i18n.resolvedLanguage)}
                        <br />
                        {t('pulseSurvey.openUntil')}
                        {formatDate(
                          new Date(moment.utc(invite.createdAt).add(7, 'days')), 
                          i18n.resolvedLanguage
                        )}
                      </TableCell>
                      <AcceptRejectCell
                        acceptClick={() =>
                          navigate(
                            `${PULSE_SURVEY_RATE}/${invite.pulseSurveyId}`,
                            {
                              state: {
                                pulseSurvey: invite
                              }
                            }
                          )
                        }
                        rejectClick={() => rejectSurvey(invite.id)}
                      />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        )
      ) : (
        <CardContent>
          <NotificationBodyText fontSize={smallItemView ? '16px' : '20px'}>
            {t('pulseSurvey.noOutstandingRequests')}
          </NotificationBodyText>
        </CardContent>
      )}
      {requestError === true ? (
        <CardContent>
          <RequestRetryAlert />
        </CardContent>
      ) : null}

      <SimpleNotificationDialog
        open={dialogOpen}
        onClose={onRejectDialogClose}
        titleText={t('pulseSurvey.rejectPulseSurvey')}
        notificationText={t('pulseSurvey.removeRequest')}
        confirmText={t('assessments.keepIt')}
        cancelText={t('assessments.reject')}
        cancelValue={1}
      />
    </>
  )
}

export default PulseSurveyInvites
