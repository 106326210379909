import { CardContent, TextField, InputAdornment, Icon } from '@mui/material'
import colors from 'styles/colors'

const SearchInputBox = ({
  onChangeHandler,
  placeholder = 'Search for user..',
  sx,
  updatedStyle = false,
  inputSx = {},
  ...otherProps
}) => {
  return (
    <CardContent
      sx={[{ justifyContent: 'left', paddingY: 0 }, sx]}
      {...otherProps}>
      { updatedStyle ?
        <TextField
          placeholder={placeholder}
          onChange={onChangeHandler}
          InputProps={{
            sx: inputSx,
            startAdornment: (
              <InputAdornment position='start'>
                <Icon sx={{ color: colors.Grey400 }}>search</Icon>
              </InputAdornment>
            )
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 50,
              backgroundColor: colors.Transparent,
              '& fieldset': {
                borderColor: colors.BondiBlue
              },
              '& .MuiInputBase-input': {
                paddingY: 6
              }
            },
            '& .MuiInputBase-input::placeholder': {
              color: colors.Grey400,
              opacity: 1,
            },
          }}
        />
        :
        <TextField
          placeholder={placeholder}
          onChange={onChangeHandler}
          InputProps={{
            sx: inputSx,
            startAdornment: (
              <InputAdornment position='start'>
                <Icon>search</Icon>
              </InputAdornment>
            )
          }}
        />
      }
    </CardContent>
  )
}

export default SearchInputBox
