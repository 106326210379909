import { useEffect, useState } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import RolePlayResultCard from 'components/molecules/rolePlay/RolePlayResultCard'
import { getRequest } from 'utils/api'
import { useTranslation } from 'react-i18next'
import { USER_ROLE_PLAYS_URL } from 'services/constants'

const RolePlayResultList = ({
  rolePlayId = undefined,
  virtueId = undefined,
  type = undefined,
  cardSizeMD = 3,
  setActiveUserRolePlayId = () => {},
  activeUserRolePlayId = null
}) => {
  const { t } = useTranslation()
  const [userRolePlays, setUserRolePlays] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(USER_ROLE_PLAYS_URL, {
        rolePlayId,
        virtueId
      })
      if (res.status === 200) {
        const userRolePlaysData = res.data.data?.data
        setUserRolePlays(userRolePlaysData)
      }
    }
    fetchData()
  }, [rolePlayId, virtueId])

  return userRolePlays.length ? (
    <Stack padding={0} spacing={6} paddingBottom={12}>
      <Typography variant='h3'>{t('rolePlay.yourPreviousPractice')}</Typography>
      <Box marginLeft={-6} width='100%'>
        <Grid container rowGap={6} columnSpacing={6}>
          {userRolePlays
            .filter(r => (type ? r.type === type : true))
            .map(ur => (
              <Grid
                item
                md={cardSizeMD}
                style={{
                  minWidth: cardSizeMD === 3 ? 360 : 0
                }}
                key={`user-role-play-${ur.id}`}
                data-testid={`user-role-play-${ur.id}`}>
                <RolePlayResultCard
                  userRolePlay={ur}
                  setActiveUserRolePlayId={setActiveUserRolePlayId}
                  active={activeUserRolePlayId === ur.id}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Stack>
  ) : null
}

export default RolePlayResultList
