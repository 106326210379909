import CompanyInfoCard from 'components/organisms/adminDashboard/CompanyInfoCard'
import CompanySettingsTemplate, {
  COMPANY_TABS
} from 'components/templates/CompanySettingsTemplate'
import { getUserOrganizationId } from 'utils/localStorage'

const Company = () => {
  return (
    <CompanySettingsTemplate activeTab={COMPANY_TABS.GENERAL}>
      <CompanyInfoCard organizationId={getUserOrganizationId()}/>
    </CompanySettingsTemplate>
  )
}

export default Company
