import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { translationEn } from 'utils/languages.js/en'
import { translationFr } from 'utils/languages.js/fr'
import { multilingualSwitch } from 'config/config'

if (multilingualSwitch) {
  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector) // Disabled for now for not impacting current users before releas
}
i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translationEn
      },
      fr: {
        translation: translationFr
      }
    },
    returnObjects: true
  })

export default i18n
