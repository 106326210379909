import {
  Box,
  Divider,
  StepLabel,
  useTheme,
  Container,
  Typography,
  Stepper,
  Step
} from '@mui/material'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

const StepperPageTemplate = ({ steps, activeStep, stepperMaxWidth, children }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const connectorStyle = {
    zIndex: 1,
    '& .MuiStepConnector-root ': {
      top: '20px',
      '& .MuiStepConnector-line': {
        borderWidth: 4,
        Opacity: 1,
        borderColor: colors.Grey200
      },
      '&.Mui-completed .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main
      }
    }
  }
  const OnboardingStepLabel = ({ n, label }) => (
    <StepLabel
      StepIconProps={{
        sx: {
          width: '40px',
          height: '40px',
          backgroundColor: theme.palette.background.paper
        }
      }}
      StepIconComponent={
        n >= activeStep
          ? () => (
              <CircleOutlinedIcon
                sx={{
                  fontSize: 42,
                  backgroundColor: theme.palette.background.paper,
                  color:
                    n === activeStep
                      ? theme.palette.primary.main
                      : colors.Grey200
                }}
              />
            )
          : undefined
      }>
      <Typography
        marginTop={-5}
        variant='h5'
        color={n <= activeStep ? 'primary' : colors.Grey300}>
        {label}
      </Typography>
    </StepLabel>
  )
  return (
    <Box width='100%'>
      <Box position='sticky' width='100%' paddingTop={18} display='flex'>
        <Box width='50%'>
          <Divider
            sx={{ borderWidth: 2, borderColor: theme.palette.primary.main }}
          />
        </Box>
        <Box width='50%'>
          <Divider sx={{ borderWidth: 2, borderColor: colors.Grey200 }} />
        </Box>
      </Box>
      <Container
        maxWidth={stepperMaxWidth}
        sx={{ position: 'relative', top: -40 }}>
        <Box width='100%' paddingTop={8}>
          <Stepper activeStep={activeStep} sx={connectorStyle} alternativeLabel>
            {steps.map((s, i) => (
              <Step key={i + 1}>
                <OnboardingStepLabel n={i + 1} label={`${t(s)}`} />
              </Step>
            ))}
          </Stepper>
        </Box>
      </Container>
      {children}
    </Box>
  )
}

export default StepperPageTemplate
