import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import RowStack from 'components/atoms/container/RowStack'
import CardCarousel from 'components/molecules/CardCarousel'
import { Stack, Typography } from '@mui/material'

export const CarouselWelcome = ({ headLine, title, description, children }) => {
  return (
    <Stack
      spacing={6}
      padding={0}
      sx={{
        width: 420,
        paddingRight: 26
      }}>
      {headLine && <Typography variant='h4'>{headLine}</Typography>}
      {title && <Typography variant='h2'>{title}</Typography>}
      {description && <Typography variant='body1'>{description}</Typography>}
      {children}
    </Stack>
  )
}

const HeaderWithCarousel = ({
  cards,
  welcomeElement = null,
  carousel = null,
  ...carouselProps
}) => {
  return (
    <BoxWithBackground
      paddingY={32}
      paddingX={20}
      width='100%'
      minHeight={550}
      overflow='hidden'>
      <RowStack sx={{ userSelect: 'none' }}>
        {welcomeElement}
        {carousel === null ? (
          <CardCarousel cards={cards} {...carouselProps} />
        ) : (
          carousel
        )}
      </RowStack>
    </BoxWithBackground>
  )
}

export default HeaderWithCarousel
