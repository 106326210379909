import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  Stack,
  Box
} from '@mui/material'
import { TaskErrorIcon } from 'components/atoms/Icons'

const ErrorNotificationDialog = props => {
  const {
    titleText,
    open,
    notificationText,
    buttonAction,
    confirmButtonText,
    ButtonIcon = undefined
  } = props

  return (
    <Dialog onClose={buttonAction} open={open}>
      <Stack maxWidth='430px' alignItems='center' spacing={5}>
        <TaskErrorIcon />
        <DialogTitle textAlign='center'>{titleText}</DialogTitle>
        <Box maxWidth='320px'>
          <Typography align='center' textAlign='center'>
            {notificationText}
          </Typography>
        </Box>
        <Button onClick={() => buttonAction()} startIcon={ButtonIcon}>
          {confirmButtonText}
        </Button>
      </Stack>
    </Dialog>
  )
}

export default ErrorNotificationDialog
