import SignUpCard from 'components/organisms/user/SignUpCard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ASSESSMENT_OTHERS,
  HOME,
  LOGIN,
  ONBOARDING,
  OVERVIEW
} from 'routes/constants'
import { useEffect } from 'react'
import { getRequest } from 'utils/api'
import { CHECK_USER_EXIST_URL, USER_ROLE_TYPES } from 'services/constants'
import SplashPage from 'components/templates/SplashPage'
import { getLocalUser } from 'utils/localStorage'
import { signout } from 'services/userServices'
import { isAllowedFeature } from 'utils/permissionCheck'
import { FEATURE_KEYS } from 'services/constants'
import { useTranslation } from 'react-i18next'

const SignUp = () => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  // Redirect to login if user exists
  useEffect(() => {
    const fetchData = async () => {
      const currentUser = getLocalUser()
      if (currentUser?.email && currentUser.email !== params.email) {
        signout()
      }
      const res = await getRequest(CHECK_USER_EXIST_URL, {
        email: params.email
      })
      if (res.data.success && res.data.data.userExist) {
        const state = location.state
        if (params.assessmentRequestId) {
          state.from = `${ASSESSMENT_OTHERS}/${params.assessmentRequestId}`
        }
        navigate(`${LOGIN}/${params.email}`, { state })
      }
    }
    if (params.email) {
      fetchData()
    } else {
      // If email isn't set then navigate to login
      navigate(LOGIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSignupSuccessful = userData => {
    if (params.assessmentRequestId) {
      navigate(`${ASSESSMENT_OTHERS}/${params.assessmentRequestId}`)
    } else if (
      userData.role !== USER_ROLE_TYPES.RESTRICTED &&
      !userData.hasLicense &&
      !isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT)
    ) {
      navigate(OVERVIEW)
    } else if (userData.isNewUser) {
      navigate(ONBOARDING)
    } else {
      navigate(HOME)
    }
  }
  return (
    <SplashPage title={t('authPages.createAccount')}>
      <SignUpCard
        firstName={location.state?.firstName}
        lastName={location.state?.lastName}
        email={params.email}
        handleSignupSuccessful={handleSignupSuccessful}
        allowEmailChange={location.state?.allowEmailChange}
      />
    </SplashPage>
  )
}

export default SignUp
