import InternalPage from './InternalPage'

const InternalReportPage = ({ children, ...otherProps }) => {
  return (
    <InternalPage {...otherProps} activePage='reports'>
      {children}
    </InternalPage>
  )
}

export default InternalReportPage
