import { Close } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material'

const SimpleNotificationDialog = props => {
  const {
    onClose,
    titleText,
    open,
    notificationText,
    confirmText,
    cancelText,
    cancelValue
  } = props

  const handleSelect = value => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleSelect} open={open}>
      <DialogTitle>
        {titleText}
        <IconButton
          sx={{ float: 'right', paddingTop: '2px' }}
          onClick={() => onClose(cancelValue)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Stack width='400px' alignItems='center' sx={{ paddingTop: 0 }}>
        <Typography align='center'>{notificationText}</Typography>
        <Stack direction='row' spacing={10}>
          <Button onClick={() => handleSelect(1)}>{confirmText}</Button>
          <Button color='error' onClick={() => handleSelect(0)}>
            {cancelText}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default SimpleNotificationDialog
