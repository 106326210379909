import { Box, CardContent, Stack, Typography } from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import PathItem from 'components/molecules/PathItem'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { JOURNEY_NEW, LEADERSHIP_MODEL, USER_PROFILE } from 'routes/constants'
import { USER_QUIZ_RESULTS_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { getGoalInfoById } from 'utils/getSupportingInfo'

const UserLearningPaths = ({ userStates }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [goalList, setGoalList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      let list = []
      if (userStates.currentGoalId) {
        const onGoingGoal = await getGoalInfoById(userStates.currentGoalId)
        list.push(onGoingGoal)
      }
      if (userStates.completedGoals) {
        const completedOnly = userStates.completedGoals.filter(
          g => g !== userStates.currentGoalId
        )
        const dataList = await Promise.all(
          completedOnly.map(async g => {
            const goalData = await getGoalInfoById(g)
            goalData.completed = true
            return goalData
          })
        )
        list = list.concat(dataList)
      }
      const res = await getRequest(USER_QUIZ_RESULTS_URL)
      if (list.length > 0 && res.status === 200) {
        const quizResults = res.data.data
        list = list.map(g => {
          const quiz = quizResults.find(q => q.goalStep.goal_id === g.id)
          g.completedQuizStepId = quiz?.goalStepId
          return g
        })
      }
      setGoalList(list)
    }
    fetchData()
  }, [userStates])

  const assessmentClickCallback = goal => {
    navigate(LEADERSHIP_MODEL, {
      state: { from: USER_PROFILE, goalId: goal.id, showAssessment: true }
    })
  }
  return (
    <>
      <Box height='20px' />
      <CardContent>
        <Stack padding={0} width='100%'>
          <Typography variant='h4'>
            {t('pathsAndModels.learningPaths')}
          </Typography>
          <Typography variant='body1Small'>
            {goalList.length > 0
              ? t('profile.pathKeepTrack')
              : t('profile.noPathYet')}
          </Typography>
        </Stack>
      </CardContent>
      {goalList.length > 0 ? (
        goalList.map(g => (
          <CardContent key={g.id} sx={{ paddingTop: 0 }}>
            <PathItem
              goalData={g}
              onGoingGoalId={userStates.currentGoalId}
              onAssessmentClick={() => assessmentClickCallback(g)}
            />
          </CardContent>
        ))
      ) : (
        <LeftJustifyCardContent>
          <Box>
            <LinkButton to={JOURNEY_NEW}>
              {t('profile.startNewPath')}
            </LinkButton>
          </Box>
        </LeftJustifyCardContent>
      )}
    </>
  )
}

export default UserLearningPaths
