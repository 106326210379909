import SimplePage from 'components/templates/SimplePage'
import ForgotPasswordCard from 'components/organisms/user/ForgotPasswordCard'

const ForgotPassword = () => {
  return (
    <SimplePage>
      <ForgotPasswordCard />
    </SimplePage>
  )
}

export default ForgotPassword
