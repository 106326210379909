import FullAssessmentCard from 'components/organisms/assessments/fullAssessment/FullAssessmentCard'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { Box, Container } from '@mui/material'

const FullAssessment = () => {
  return (
    <HomePage>
      <Container maxWidth='xl'>
        <Box height={12} width='100%' /> {/* This is a spacer */}
        <FullAssessmentCard />
      </Container>
    </HomePage>
  )
}

export default FullAssessment
