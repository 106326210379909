import { Close } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  Alert
} from '@mui/material'
import LowFocusButton from 'components/atoms/button/LowFocusButton'
import StyledButton, {
  NoBorderButton
} from 'components/atoms/button/StyledButton'
import RowStack from 'components/atoms/container/RowStack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'

const ConfirmCancelDialog = props => {
  const { t } = useTranslation()
  const {
    onClose,
    titleText,
    open,
    notificationText,
    confirmText,
    cancelText,
    cancelValue,
    maxWidth,
    bodyWidth,
    buttonsDirection = 'column', // Deprecated
    showDisableToggle = false,
    errorAlertMessage = '',
    children,
    updatedStyle = false
  } = props

  const [checkDisable, setCheckDisabled] = useState(false)
  const handleSelect = value => {
    onClose(value, checkDisable)
  }

  return updatedStyle ? (
    <Dialog onClose={handleSelect} open={open} maxWidth={maxWidth}>
      <Stack padding={8} spacing={8} sx={{ width: bodyWidth }}>
        <RowStack justifyContent='space-between'>
          <Typography variant='h4' component='span'>
            {titleText}
          </Typography>
          <IconButton size='small' onClick={() => onClose(cancelValue)}>
            <Close sx={{ color: colors.LightGrey400 }} />
          </IconButton>
        </RowStack>
        <Typography variant='body1Large'>{notificationText}</Typography>
        {children}
        {confirmText || cancelText ? (
          <RowStack justifyContent='flex-end' paddingY={6}>
            {cancelText ? (
              <NoBorderButton onClick={() => handleSelect(0)}>
                {cancelText}
              </NoBorderButton>
            ) : null}
            {confirmText ? (
              <StyledButton onClick={() => handleSelect(1)}>
                {confirmText}
              </StyledButton>
            ) : null}
          </RowStack>
        ) : null}
        {errorAlertMessage ? (
          <Alert severity='error'>{errorAlertMessage}</Alert>
        ) : null}
        {showDisableToggle ? (
          <FormControlLabel
            label={t('stepFeedback.dontAskMeAgain')}
            control={
              <Checkbox
                checked={checkDisable}
                onChange={e => setCheckDisabled(e.target.checked)}
                color='primary'
              />
            }
          />
        ) : null}
      </Stack>
    </Dialog>
  ) : (
    <Dialog onClose={handleSelect} open={open} maxWidth={maxWidth}>
      <DialogTitle sx={{ paddingTop: 10 }}>
        <Typography variant='h3' component='span'>
          {titleText}
        </Typography>
        <IconButton
          sx={{ float: 'right', paddingTop: '2px' }}
          onClick={() => onClose(cancelValue)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Stack alignItems='center' sx={{ paddingTop: 0, width: bodyWidth }}>
        <Typography align='center' component='div'>
          {notificationText}
        </Typography>
        {children}
        {confirmText || cancelText ? (
          <Stack spacing={10} direction={buttonsDirection}>
            {confirmText ? (
              <Button onClick={() => handleSelect(1)}>{confirmText}</Button>
            ) : null}
            {cancelText ? (
              <LowFocusButton onClick={() => handleSelect(0)}>
                {cancelText}
              </LowFocusButton>
            ) : null}
          </Stack>
        ) : null}
        {errorAlertMessage ? (
          <Alert severity='error'>{errorAlertMessage}</Alert>
        ) : null}
        {showDisableToggle ? (
          <FormControlLabel
            label={t('stepFeedback.dontAskMeAgain')}
            control={
              <Checkbox
                checked={checkDisable}
                onChange={e => setCheckDisabled(e.target.checked)}
                color='primary'
              />
            }
          />
        ) : null}
      </Stack>
    </Dialog>
  )
}

export default ConfirmCancelDialog
