import { Star } from '@mui/icons-material'
import { Box, CardContent, Divider, Stack, Typography } from '@mui/material'
import AvatarChip from 'components/atoms/AvatarChip'
import LinkButton from 'components/atoms/button/LinkButton'
import InCardStack from 'components/atoms/InCardStack'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import {
  ActionText,
  AttributeLabelValue,
  PageHeader
} from 'components/atoms/Text'
import SinglePageStack from 'components/templates/SinglePageStack'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { JOURNEY, PULSE_SURVEYS } from 'routes/constants'
import { PULSE_SURVEY_STATUSES } from 'services/constants'
import { formatDate } from 'utils/formatDateTime'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'

const PulseSurveyDetailCard = ({ surveyData }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const hasScore = surveyData.avgRating !== null
  const user = getLocalUser()
  const addExtraText =
    user.email.indexOf('monarktech.ca') > -1 ||
    user.email.indexOf('leadwithmonark.com') > -1
  const goToStep = () => {
    navigate(`${JOURNEY}/2`, { state: { goalStepId: 204 } })
  }
  return (
    <SinglePageStack data-testid='pulse-survey-detail' spacing={5}>
      <PageHeader>{t('pulseSurvey.psDetails')}</PageHeader>
      <LeftJustifyCardContent>
        <Typography variant='subtitle1'>
          {surveyData.pulseSurveyQuestion?.statementText ||
            surveyData.pulseSurveyQuestion?.questionText ||
            surveyData.pulseSurveyQuestionText}
        </Typography>
      </LeftJustifyCardContent>
      <LeftJustifyCardContent sx={{ paddingTop: 0 }}>
        <Stack padding={0}>
          <AttributeLabelValue
            label={t('pulseSurvey.issuedOn')}
            value={formatDate(surveyData.createdAt, i18n.resolvedLanguage)}
          />
          {surveyData.status !== PULSE_SURVEY_STATUSES.OPEN ? (
            <AttributeLabelValue
              label={t('assessmentTimeline.closedOn')}
              value={formatDate(surveyData.updatedAt, i18n.resolvedLanguage)}
            />
          ) : (
            <AttributeLabelValue
              label={t('assessmentTimeline.closingOn')}
              value={formatDate(
                new Date(moment.utc(surveyData.sendAt).add(7, 'days')),
                i18n.resolvedLanguage
              )}
            />
          )}
        </Stack>
      </LeftJustifyCardContent>
      <Divider />
      <LeftJustifyCardContent>
        <InCardStack spacing={5}>
          <Typography alignSelf='flex-start' variant='subtitle1'>
            {t('pulseSurvey.teamMembersSentTo')}
          </Typography>
          <Box>
            {surveyData.recipients.map(user => (
              <AvatarChip
                key={user.id}
                user={user}
                size='medium'
                variant='filled'
              />
            ))}
          </Box>
          <Typography color='primary' alignSelf='flex-start'>
            {surveyData.responseCount}/{surveyData.recipients.length} {t('pulseSurvey.responsesReceived')}
          </Typography>
        </InCardStack>
      </LeftJustifyCardContent>
      <Divider />
      <LeftJustifyCardContent>
        {hasScore ? (
          <InCardStack spacing={5}>
            <InCardStack
              direction='row'
              alignItems='center'
              spacing={2}
              alignSelf='flex-start'>
              <Star color='secondary' />
              <Typography variant='subtitle1'>{t('pulseSurvey.averageRating')}</Typography>
              <Typography variant='h3' color='primary'>
                {surveyData.avgRating.toFixed(1)}/5
              </Typography>
            </InCardStack>
            <Typography alignSelf='flex-start'>
              {surveyData.ratingExplanation}
            </Typography>
            {addExtraText ? (
              <>
                <Typography variant='body2'>
                  {t('pulseSurvey.makingGreatProgress')}
                </Typography>
                <Typography variant='body2'>
                  {t('pulseSurvey.whyStopAtGood')}
                  <ActionText variant='body2' onClick={() => goToStep()}>
                    {t('pulseSurvey.learnAboutRecognition')}
                  </ActionText>
                </Typography>
              </>
            ) : null}
          </InCardStack>
        ) : (
          <Stack padding={0} spacing={5}>
            <Typography variant='subtitle1'>{t('pulseSurvey.noRating')}</Typography>
            <Typography>
              {t('pulseSurvey.atLeast3People')}
            </Typography>
          </Stack>
        )}
      </LeftJustifyCardContent>
      <CardContent sx={{ paddingTop: '30px' }}>
        <LinkButton to={PULSE_SURVEYS}>{t('pulseSurvey.goBack')}</LinkButton>
      </CardContent>
    </SinglePageStack>
  )
}

export default PulseSurveyDetailCard
