import React from 'react'
import { Grid, Typography } from '@mui/material'
// import MoreVertIcon from '@mui/icons-material/MoreVert'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { LowConstrastButton } from 'components/atoms/button/StyledButton'
import RowStack from 'components/atoms/container/RowStack'
import InCardStack from 'components/atoms/InCardStack'
import CheckButton from 'components/atoms/input/CheckButton'
import { formatTime, formatDayMonthDay } from 'utils/formatDateTime'
import { MY_CONTACT, MY_MEETING } from 'routes/constants'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const UserMeetingHeader = ({
  connection,
  event,
  handleCloseMeeting,
  userConnectionId,
  isOwner,
  isClosed
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Grid item paddingRight={10}>
      {connection && event && (
        <RowStack sx={{ padding: 2 }}>
          <ProfileAvatar size='medium' user={connection} />
          <InCardStack
            spacing={0}
            sx={{
              height: 100,
              justifyContent: 'center',
              alignItems: 'flex-start'
            }}>
            <RowStack>
              <Typography variant='h1' fontWeight={400}>
                {t('myTeam.oneOnOneWithName', {
                  name: connection.firstName
                })}
              </Typography>
              {isOwner && (
                <LowConstrastButton
                  onClick={() =>
                    navigate(`${MY_CONTACT}/${userConnectionId}`, {
                      state: { from: MY_MEETING }
                    })
                  }>
                  {t('myTeam.namePage', {
                    name: `${connection.firstName}'s`
                  })}
                </LowConstrastButton>
              )}
            </RowStack>
            <Typography variant='body1Small'>
              {formatDayMonthDay(event.startTime)}
              {' at '}
              {formatTime(event.startTime)}
              {' - '}
              {formatTime(event.endTime)}
            </Typography>
          </InCardStack>

          {(isOwner || isClosed) && (
            <RowStack flex={1} justifyContent='flex-end' spacing={0}>
              <Typography
                variant='body1Small'
                sx={{ color: colors.LightGrey400, textAlign: 'right' }}>
                {isClosed ? (
                  t('myTeam.thisMeetingIsOver')
                ) : (
                  <CheckButton
                    checkProps={{ sx: { color: colors.LightGrey400 } }}
                    label={t('myTeam.thisMeetingHasFinished')}
                    isChecked={false}
                    setIsChecked={handleCloseMeeting}
                  />
                )}
              </Typography>
              {/* {isOwner && (
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              )} */}
            </RowStack>
          )}
        </RowStack>
      )}
    </Grid>
  )
}

export default UserMeetingHeader
