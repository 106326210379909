import { useEffect, useState } from 'react'
import SuperLeaderScore from '../../molecules/adminDashboard/insights/SuperLeaderScore'
import DevelopmentDetails from '../../molecules/adminDashboard/insights/DevelopmentDetails'
import GrowthDetails from '../../molecules/adminDashboard/insights/GrowthDetails'
import AppUsage from './AppUsage'
import { Grid, Stack } from '@mui/material'
import UsersStatsList from './UsersStatsList'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { permitted } from 'utils/permissionCheck'
import { PERMISSION_LEVEL } from 'routes/constants'
import { getLocalUser } from 'utils/localStorage'
import { getRequest } from 'utils/api'
import { SUPER_LEADER_SCORE } from 'services/constants'
import {
  ScrollPanel,
  TabPanel
} from '../../molecules/adminDashboard/insights/SuperLeaderTabs'
import { BreakdownDisplayPanel } from 'components/molecules/adminDashboard/insights/SuperLeaderScorePanel'
import DrillDownChangeTable from 'components/molecules/adminDashboard/insights/DrillDownChangeTable'
import DrillDownUserDetails from 'components/molecules/adminDashboard/insights/DrillDownUserDetails'
import CompletionPace from './CompletionPace'

const SCORE_TABS = {
  MAIN: 0,
  DEVELOPMENT: 1,
  COMMITMENT: 2,
  GROWTH: 3,
  TEAM: 4
}

const USERS_TABS = {
  MAIN: 0,
  USER_DETAILS: 1
}

const Insights = ({ companyFilter, cohortFilter, isSuperAdmin }) => {
  const [activeScore, setActiveScore] = useState(SCORE_TABS.MAIN)
  const [activeUserTab, setActiveUserTab] = useState(USERS_TABS.MAIN)
  const [scoreData, setScoreData] = useState(null)
  const [userDetails, setUserDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [organizationId, setOrganizationId] = useState(null)

  const setActiveScoreTab = newValue => {
    const dest = Object.entries(SCORE_TABS).find(([, t]) => t === newValue)
    if (dest) {
      setActiveScore(dest[1])
    } else {
      setActiveScore(SCORE_TABS.MAIN)
    }
  }

  const openMainUserTab = () => {
    setActiveUserTab(USERS_TABS.MAIN)
    setUserDetails({})
  }

  const openUserDetailsTab = user => {
    setActiveUserTab(USERS_TABS.USER_DETAILS)
    setUserDetails(user)
  }

  const fetchData = async (organizationId, cohortId) => {
    setLoading(true)
    const res = await getRequest(SUPER_LEADER_SCORE, {
      organizationId,
      cohortId
    })
    if (res.status === 200) {
      const data = res.data.data
      setScoreData(data)
    }
    setLoading(false)
  }

  useEffect(() => {
    setActiveScoreTab(SCORE_TABS.MAIN)
    const orgId = permitted(PERMISSION_LEVEL.SUPER_ADMIN)
      ? companyFilter
      : getLocalUser().organizationId
    if (orgId) {
      fetchData(orgId, cohortFilter)
      setOrganizationId(orgId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyFilter, cohortFilter])

  return (
    <Stack padding={0} height='inherit' width='100%'>
      <OverlayLoading open={loading} />
      {(companyFilter || !isSuperAdmin) && (
        <Grid container spacing={10} height='inherit' width='100%'>
          <ScrollPanel md={3} gridSxProps={{ zIndex: 1 }}>
            <AppUsage
              organizationId={organizationId}
              cohortId={cohortFilter}
            />
          </ScrollPanel>

          <Grid item md={9} width='100%'>
            {scoreData && (
              <>
                <TabPanel tab={SCORE_TABS.MAIN} activeTab={activeScore}>
                  <SuperLeaderScore
                    scoreData={scoreData}
                    SCORE_TABS={SCORE_TABS}
                    setActiveScoreTab={setActiveScoreTab}
                  />
                </TabPanel>
                <BreakdownDisplayPanel
                  currentTab={SCORE_TABS.DEVELOPMENT}
                  activeScoreTab={activeScore}
                  setActiveScoreTab={setActiveScoreTab}
                  titleKey='insights.developmentScoreDetails'
                  descriptionKey='insights.focusesOnLeadersProactiveEfforts'>
                  {scoreData.developmentBreakdown && (
                    <DevelopmentDetails scoreData={scoreData} />
                  )}
                </BreakdownDisplayPanel>
                <BreakdownDisplayPanel
                  currentTab={SCORE_TABS.COMMITMENT}
                  activeScoreTab={activeScore}
                  setActiveScoreTab={setActiveScoreTab}
                  titleKey='insights.commitmentDetails'
                  descriptionKey='insights.assessesLeadersDedication'>
                  {scoreData.commitmentBreakdown?.current && (
                    <DrillDownChangeTable
                      data={scoreData.commitmentBreakdown}
                      isPercentage={true}
                    />
                  )}
                </BreakdownDisplayPanel>
                <BreakdownDisplayPanel
                  currentTab={SCORE_TABS.GROWTH}
                  activeScoreTab={activeScore}
                  setActiveScoreTab={setActiveScoreTab}
                  titleKey='insights.growthScoreDetails'
                  descriptionKey='insights.seeDetailsOfGrowth'>
                  {scoreData.growthBreakdown?.current && (
                    <GrowthDetails
                      scoreData={scoreData}
                      organizationId={organizationId}
                      cohortId={cohortFilter}
                    />
                  )}
                </BreakdownDisplayPanel>
                <BreakdownDisplayPanel
                  currentTab={SCORE_TABS.TEAM}
                  activeScoreTab={activeScore}
                  setActiveScoreTab={setActiveScoreTab}
                  titleKey='insights.teamStrengthDetails'
                  descriptionKey='insights.evaluatesLeadersTeam'>
                  {scoreData.teamStrengthBreakdown?.current && (
                    <DrillDownChangeTable
                      data={scoreData.teamStrengthBreakdown}
                      isPercentage={true}
                    />
                  )}
                </BreakdownDisplayPanel>
              </>
            )}

            <ScrollPanel>
              <CompletionPace
                companyFilter={companyFilter}
                cohortFilter={cohortFilter}
              />
            </ScrollPanel>
          </Grid>

          <TabPanel
            tab={USERS_TABS.MAIN}
            activeTab={activeUserTab}
            isBottomElement={true}
            animationEasyOut={false}>
            <UsersStatsList
              cohortFilter={cohortFilter}
              companyFilter={companyFilter}
              handleGroupsLists={() => {}}
              handleInvitedUserCount={() => {}}
              handleUserStatsCount={() => {}}
              showUsersTableonly={true}
              height='500px'
              openUserDetailsTab={openUserDetailsTab}
            />
          </TabPanel>

          <TabPanel
            tab={USERS_TABS.USER_DETAILS}
            activeTab={activeUserTab}
            isBottomElement={true}
            animationEasyOut={false}>
            <DrillDownUserDetails
              openMainUserTab={openMainUserTab}
              userDetails={userDetails}
            />
          </TabPanel>
        </Grid>
      )}
    </Stack>
  )
}

export default Insights
