import * as yup from 'yup'
import React, { useState } from 'react'
import {
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useFormik } from 'formik'
import EmailsInputBox from 'components/atoms/input/EmailsInputBox'
import StyledButton from 'components/atoms/button/StyledButton'
import RowStack from 'components/atoms/container/RowStack'
import StatusMessage from 'components/atoms/StatusMessage'
import CopyToClipboardButton from 'components/atoms/button/CopyToClipboardButton'
import { useTranslation } from 'react-i18next'
import { postRequest } from 'utils/api'
import { ASSESSMENT_SHARE_RESULT_URL } from 'services/constants'
import OverlayLoading from 'components/atoms/OverlayLoading'
import colors from 'styles/colors'

const SendState = Object.freeze({
  NotRequested: 0,
  Pending: 1,
  Success: 2,
  Failed: 3,
})

export const ShareReportDialog = ({
  title = null,
  isOpen,
  onClose,
  assessmentId,
  copyUrl = null
}) => {
  const [sendState, setSendState] = useState(SendState.NotRequested)
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      recipients: []
    },
    validationSchema: yup.object({
      recipients: yup.array().min(1, t('shareMyResults.recpMinValidation'))
    }),
    onSubmit: async values => {
      setSendState(SendState.Pending)

      const res = await postRequest(
        ASSESSMENT_SHARE_RESULT_URL.replace('{id}', assessmentId),
        {
          emails: values.recipients.map(user => user.user?.email.trim())
        }
      )

      const isSuccess = res.status === 200
      setSendState(isSuccess ? SendState.Success : SendState.Failed)

      if (isSuccess) {
        formik.resetForm()
      }
      return isSuccess
    }
  })

  const handleClose = () => {
    formik.resetForm()
    setSendState(SendState.NotRequested)
    onClose()
  }

  return (
    sendState === SendState.Pending ?
      <OverlayLoading open={sendState === SendState.Pending} />
      :
      <Dialog open={isOpen}
        sx={{ '& .MuiDialog-paper': { width: 500, padding: 8, } }}
        onClose={handleClose}
      >
        <Stack alignItems='center' spacing={16} sx={{ padding: 0 }}>
          <RowStack justifyContent='space-between'>
            <Typography variant='h4' sx={{ textWrap: 'nowrap' }}>
              {title ?? t('fullReport.shareYourReport')}
            </Typography>
            <RowStack justifyContent='flex-end' spacing={6}>
              { copyUrl && <CopyToClipboardButton copyText={copyUrl} /> }
              <IconButton aria-label='close-share-dialog' onClick={handleClose}>
                <Close data-testid='share-dialog-close' />
              </IconButton>
            </RowStack>
          </RowStack>

          <Divider
            sx={{
              position: 'absolute',
              backgroundColor: colors.Grey300,
              top: 38,
              left: 0,
              width: '100%',
            }}
          />

          { sendState === SendState.Success ?
              <StatusMessage
                isSuccess
                onClose={() => setSendState(SendState.NotRequested)}
                message={t('fullReport.yourEmailHasBeenSent')}

              />
            : sendState === SendState.Failed ?
              <StatusMessage
                onClose={() => setSendState(SendState.NotRequested)}
                message={t('setings.submitError')}
              />
            :
              <RowStack>
                <EmailsInputBox
                  disabled={false}
                  formik={formik}
                />
                <StyledButton
                  size='small'
                  disabled={formik.values.recipients?.length === 0
                    || sendState !== SendState.NotRequested
                  }
                  onClick={() => formik.handleSubmit()}
                  sx={{
                    '&:disabled': {
                      borderColor: colors.White,
                      backgroundColor: colors.Grey50,
                    }
                  }}
                >
                  <Typography
                    variant='eyebrow'
                    sx={{
                      textTransform: 'none',
                      color: formik.values.recipients?.length === 0
                        ? colors.Grey300 : colors.White,
                      textWrap: 'nowrap',
                    }}
                  >
                    {t('fullReport.sendEmail')}
                  </Typography>
                </StyledButton>
              </RowStack>
          }
        </Stack>
      </Dialog>
  )
}

export default ShareReportDialog
