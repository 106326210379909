import {
  TableCell,
  TableRow,
  Typography,
  MenuList,
  MenuItem,
  CardContent
} from '@mui/material'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { NotificationBodyText } from 'components/atoms/Text'
import ShortButton from 'components/atoms/button/ShortButton'
import PanelsWithScrollBars from 'components/atoms/container/PanelsWithScrollBars'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import { useEffect, useRef, useState } from 'react'
import {
  GOAL_STEP_QUIZ_TYPES,
  GOAL_STEP_QUIZ_URL,
  USER_QUIZ_RESULTS_CHECK_REPORT_URL,
  ORGANIZATIONS_URL
} from 'services/constants'
import { getRequest } from 'utils/api'
import { getGoals } from 'utils/getSupportingInfo'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import PercentageCell from 'components/atoms/table/PercentageCell'
import InternalReportPage from 'components/templates/navigationPageTemplates/InternalReportPage'
import InCardStack from 'components/atoms/InCardStack'
import SearchOrganizations from 'components/molecules/internal/SearchOrganizations'

const LearningCheckResults = () => {
  const [goals, setGoals] = useState([])
  const [selectedGoal, setSelectedGoal] = useState(null)
  const [reportData, setReportData] = useState([])
  const [quizzes, setQuizzes] = useState([])
  const [requestError, setRequestError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const tableRef = useRef(null)
  const [currentTableRef, setCurrentTableRef] = useState(undefined)
  const [organizationsList, setOrganizationsList] = useState([])
  const [selectedOrg, setSelectedOrg] = useState(null)

  const fetchGoals = async _goalId => {
    const res = await getGoals()
    setGoals(res.filter(g => g.available))
  }

  const fetchOrgData = async () => {
    const oRes = await getRequest(ORGANIZATIONS_URL)
    if (oRes.status === 200) {
      setOrganizationsList(oRes.data.data.filter(o => o.name))
    }
  }

  useEffect(() => {
    fetchGoals()
    fetchOrgData()
  }, [])

  useEffect(() => {
    setCurrentTableRef(tableRef.current)
  }, [reportData])

  useEffect(() => {
    if (selectedGoal) handleGoalClick(selectedGoal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg])

  const handleGoalClick = async g => {
    setLoading(true)
    setSelectedGoal(g)
    const res = await getRequest(USER_QUIZ_RESULTS_CHECK_REPORT_URL, {
      goalId: g.id,
      organizationId: selectedOrg?.id
    })
    const quizRes = await getRequest(GOAL_STEP_QUIZ_URL, {
      goalId: g.id
    })
    if (res.status === 200 && quizRes.status === 200) {
      setRequestError(false)
      setReportData(res.data.data)
      setQuizzes(
        quizRes.data.data.filter(q => q.type === GOAL_STEP_QUIZ_TYPES.CHECK)
      )
    } else {
      if (res.status === 204 || quizRes.status === 204) {
        setRequestError(false)
      } else {
        setRequestError(true)
      }
      setReportData([])
      setQuizzes([])
    }
    setLoading(false)
  }
  return (
    <InternalReportPage>
      <PanelsWithScrollBars
        leftList={
          <MenuList>
            {goals.map(g => (
              <MenuItem
                onClick={() => handleGoalClick(g)}
                key={g.id}
                width='100%'
                style={{ whiteSpace: 'normal', paddingLeft: 0 }}
                selected={selectedGoal?.id === g.id}>
                <Typography>{g.leadershipPathName}</Typography>
              </MenuItem>
            ))}
          </MenuList>
        }>
        <OverlayLoading open={loading} />
        {!selectedGoal ? (
          <CardContent>
            <NotificationBodyText>
              Select a Path to See Available Quizzes Results.
            </NotificationBodyText>
          </CardContent>
        ) : reportData.length || selectedOrg ? (
          <>
            {currentTableRef && (
              <InCardStack direction='row' justifyContent='space-between'>
                <DownloadTableExcel
                  filename={`${selectedGoal.name}-check-quiz-report`}
                  sheet='sheet1'
                  currentTableRef={currentTableRef}>
                  <ShortButton>Export</ShortButton>
                </DownloadTableExcel>

                <SearchOrganizations
                  organizations={organizationsList}
                  selectedOrg={selectedOrg}
                  setSelectedOrg={setSelectedOrg}
                />
              </InCardStack>
            )}

            <PresetTableContainer
              maxHeight='unset'
              tableProps={{ ref: tableRef }}
              headers={
                <>
                  <TableCell>User Id</TableCell>
                  <TableCell>Organization</TableCell>
                  {quizzes.map(q => (
                    <TableCell key={`header-${q.id}`}>{q.title}</TableCell>
                  ))}
                </>
              }>
              {reportData.map(quizResult => (
                <TableRow
                  key={`user-quiz-result-${
                    quizResult.userId || quizResult.name
                  }`}>
                  <TableCell>{quizResult.userId || quizResult.name}</TableCell>
                  <TableCell>{quizResult.organization}</TableCell>
                  {quizzes.map(q => (
                    <PercentageCell
                      value={quizResult[q.title]}
                      key={`quiz-result-${quizResult.userId}-${q.id}`}
                    />
                  ))}
                </TableRow>
              ))}
            </PresetTableContainer>
          </>
        ) : (
          <CardContent>
            <NotificationBodyText>
              No quiz result data found!
            </NotificationBodyText>
          </CardContent>
        )}
        {requestError ? (
          <CardContent>
            <RequestRetryAlert />
          </CardContent>
        ) : null}
      </PanelsWithScrollBars>
    </InternalReportPage>
  )
}

export default LearningCheckResults
