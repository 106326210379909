import * as yup from 'yup'
import { Alert, Box, Divider, Stack, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { ActionText, TextSeperationDot } from 'components/atoms/Text'
import { useFormik } from 'formik'
import { useState } from 'react'
import colors from 'styles/colors'
import { validationsRules } from 'utils/formValidations'
import { USER_FEEDBACK_TYPES, USER_FEEDBACK_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import SuccessfulNotificationDialog from './notificationOverlay/SuccessfulNotificationDialog'
import InputDialog from './notificationOverlay/InputDialog'
import FormInputBox from 'components/atoms/input/FormInputBox'
import { formatDate } from 'utils/formatDateTime'
import { getFullName } from 'utils/formatText'
import { useTranslation } from 'react-i18next'

const FeedbackItem = ({ feedback, showReply }) => {
  const { t, i18n } = useTranslation()
  const [replyDialogOpen, setReplyDialogOpen] = useState(false)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [feedbackCreatedSuccessful, setFeedbackCreatedSuccessful] =
    useState(undefined)

  const formik = useFormik({
    initialValues: {
      feedbackText: ''
    },
    validationSchema: yup.object({
      feedbackText: validationsRules.textRequired
    }),
    onSubmit: async values => {
      const requestBody = {
        createdForUserId: feedback.created_by_user?.id,
        feedbackText: values.feedbackText,
        feedbackType: USER_FEEDBACK_TYPES.COMMENT
      }
      const res = await postRequest(USER_FEEDBACK_URL, requestBody)
      if (res.data?.success) {
        setFeedbackCreatedSuccessful(true)
        setReplyDialogOpen(false)
        setSuccessDialogOpen(true)
      } else {
        setFeedbackCreatedSuccessful(false)
      }
    }
  })

  const handleOpenReply = () => {
    formik.resetForm()
    setFeedbackCreatedSuccessful(undefined)
    setReplyDialogOpen(true)
  }

  return (
    <>
      <Stack key={feedback.id} padding='10px' spacing={5}>
        <InCardStack direction='row'>
          <Box alignSelf='flex-start'>
            <ProfileAvatar user={feedback.created_by_user} />
          </Box>
          <Stack padding={0} alignItems='flex-start'>
            <Typography variant='subtitile1'>
              {getFullName(feedback.created_by_user)}
            </Typography>
            <Typography variant='body2'>{feedback.feedbackText}</Typography>
            <Typography marginTop='5px'>
              <Typography
                component='span'
                variant='caption'
                color={colors.Aluminium}>
                {formatDate(feedback.createdAt, i18n.resolvedLanguage)}
              </Typography>{' '}
              {showReply ? (
                <>
                  <TextSeperationDot
                    size='8px'
                    sx={{ color: colors.Aluminium }}
                  />{' '}
                  <ActionText
                    variant='caption'
                    onClick={() => handleOpenReply()}>
                    {t('feedbackArea.reply')}
                  </ActionText>
                </>
              ) : null}
            </Typography>
          </Stack>
        </InCardStack>
        <Divider width='100%' />
      </Stack>
      <InputDialog
        open={replyDialogOpen}
        titleText={`${t('feedbackArea.reply')} ${t('common.to')} ${feedback.created_by_user?.first_name}`}
        handleCancel={() => setReplyDialogOpen(false)}
        formik={formik}
        confirmText={t('common.send')}
        cancelText={t('common.cancel')}>
        <FormInputBox
          sx={{ width: '100%' }}
          type='text'
          formik={formik}
          multiline
          rows={3}
          attributeName='feedbackText'
          placeholder={t('feedbackArea.yourReply')}
        />
        {feedbackCreatedSuccessful === false ? (
          <Alert severity='error'>
            {t('feedbackArea.feedbackError')}
          </Alert>
        ) : null}
      </InputDialog>
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={t('feedbackArea.feedbackSuccess')}
        confirmButtonText={t('common.done')}
        buttonAction={() => setSuccessDialogOpen(false)}
      />
    </>
  )
}

export default FeedbackItem
