import { blue, yellow, red } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'

const AppUsageProgressBar = ({
  progress = 0,
  primary = null,
  secondary = null,
  height = 8,
  borderRadius = 5,
  ...otherProps
}) => {
  const primaryColor = primary
    ? primary
    : progress > 70
      ? blue[600]
      : progress > 10
        ? yellow[700]
        : red[700]
  const secondaryColor = secondary
    ? secondary
    : progress > 70
      ? blue[100]
      : progress > 10
        ? yellow[100]
        : red[100]

  // Ensure progress is between 0 and 100
  const normalizedProgress = Math.min(100, Math.max(0, progress))

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height,
    borderRadius,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: secondaryColor
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius,
      backgroundColor: primaryColor
    }
  }))

  return (
    <BorderLinearProgress
      variant='determinate'
      value={normalizedProgress}
      {...otherProps}
    />
  )
}

export default AppUsageProgressBar
