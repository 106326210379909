import * as yup from 'yup'
import {
  Box,
  Button,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { useEffect, useState } from 'react'
import { TEAM_SHOUTOUTS_URL } from 'services/constants'
import { getRequest, postRequest } from 'utils/api'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import InCardStack from 'components/atoms/InCardStack'
import FormInputBox from 'components/atoms/input/FormInputBox'
import { useFormik } from 'formik'
import { validationsRules } from 'utils/formValidations'
import colors from 'styles/colors'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText, Subtitle4 } from 'components/atoms/Text'
import { getFullName } from 'utils/formatText'
import { permitted } from 'utils/permissionCheck'
import { PERMISSION_LEVEL } from 'routes/constants'
import { useTranslation } from 'react-i18next'

const TeamShoutoutsCard = ({ countCallback, displayCount, isHomePage }) => {
  const { t } = useTranslation()
  const [requestError, setRequestError] = useState(undefined)
  const [shoutouts, setShoutouts] = useState([])
  const [postSuccess, setPostSuccess] = useState(undefined)
  const hasLicense = permitted(PERMISSION_LEVEL.LICENSED)

  const fetchData = async () => {
    const res = await getRequest(TEAM_SHOUTOUTS_URL)
    if (res.status === 200) {
      const data = res.data.data
      if (isHomePage) {
        setShoutouts(data.slice(0, displayCount))
        countCallback(data.length)
      } else {
        setShoutouts(data)
      }

      setRequestError(false)
    } else {
      setRequestError(true)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formik = useFormik({
    initialValues: {
      comment: ''
    },
    validationSchema: yup.object({
      comment: validationsRules.textRequired.max(
        1024,
        t('teamShoutOut.commentValidation')
      )
    }),
    onSubmit: async values => {
      const requestBody = {
        body: values.comment
      }
      const res = await postRequest(TEAM_SHOUTOUTS_URL, requestBody)
      if (res.data.success) {
        setPostSuccess(true)
        fetchData()
        formik.resetForm()
      } else {
        setPostSuccess(false)
      }
    }
  })

  const toggleLike = async commentId => {
    const res = await postRequest(`${TEAM_SHOUTOUTS_URL}/${commentId}/like`)
    if (res.status !== 200) {
      setPostSuccess(false)
    }
    fetchData()
  }
  return (
    <>
      <Stack padding={0} width='100%'>
        {shoutouts.length > 0 ? (
          shoutouts.map(s => (
            <LeftJustifyCardContent key={s.id} sx={{ paddingTop: 0 }}>
              <InCardStack spacing={0} width='100%' sx={{ alignItems: 'left' }}>
                <InCardStack direction='row'>
                  <ProfileAvatar user={s.user} />
                  <Typography variant='body2'>{s.body}</Typography>
                </InCardStack>
                <Box
                  width='100%'
                  paddingLeft='60px'
                  display='flex'
                  alignItems='center'>
                  <Typography variant='caption' fontWeight='bold'>
                    {t('teamShoutOut.postedBy')}: {getFullName(s.user)}
                  </Typography>
                  <Typography
                    variant='caption'
                    fontWeight='bold'
                    sx={{ color: colors.Cinnabar, marginLeft: '20px' }}>
                    {s.likesCount}
                  </Typography>
                  <IconButton
                    sx={{ color: colors.Cinnabar }}
                    onClick={() => toggleLike(s.id)}>
                    {s.liked ? (
                      <FavoriteIcon fontSize='small' />
                    ) : (
                      <FavoriteBorderOutlinedIcon fontSize='small' />
                    )}
                  </IconButton>
                </Box>
                {isHomePage !== true ? (
                  <Divider width='100%' sx={{ height: '20px' }} />
                ) : null}
              </InCardStack>
            </LeftJustifyCardContent>
          ))
        ) : hasLicense ? (
          <LeftJustifyCardContent>
            <Subtitle4>
              {t('teamShoutOut.shoutOutToTeam')}
            </Subtitle4>
          </LeftJustifyCardContent>
        ) : (
          <LeftJustifyCardContent sx={{ paddingTop: 0 }}>
            <NotificationBodyText fontSize='16px'>
              {t('teamShoutOut.noTeamShouts')}
            </NotificationBodyText>
          </LeftJustifyCardContent>
        )}
        {hasLicense ? (
          <LeftJustifyCardContent>
            <InCardStack width='100%' sx={{ alignItems: 'left' }}>
              <Typography variant='h3'>{t('teamShoutOut.post')}</Typography>
              <InCardStack
                direction='row'
                width='100%'
                sx={{ alignItems: 'flex-start' }}>
                <FormInputBox
                  fullWidth
                  type='text'
                  placeholder={t('teamShoutOut.comments')}
                  formik={formik}
                  attributeName='comment'
                />
                <Button
                  onClick={() => formik.submitForm()}
                  style={{ width: '240px', marginTop: '4px' }}>
                  {t('teamShoutOut.postToWall')}
                </Button>
              </InCardStack>
            </InCardStack>
          </LeftJustifyCardContent>
        ) : null}

        {requestError === true || postSuccess === false ? (
          <CardContent>
            <RequestRetryAlert />
          </CardContent>
        ) : null}
      </Stack>
    </>
  )
}

export default TeamShoutoutsCard
