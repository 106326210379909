import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'

const RememberMeToggle = ({ isRememberMe, setIsRememberMe }) => {
  const { t } = useTranslation()
  return (
    <FormControlLabel
      sx={{ userSelect: 'none' }}
      control={
        <Checkbox
          checked={isRememberMe}
          onChange={() => setIsRememberMe(r => !r)}
          sx={{paddingY: 0}}
        />
      }
      label={
        <Typography variant='body1' color={colors.Grey400}>
          {t('authPages.rememberMe')}
        </Typography>
      }
    />
  )
}

export default RememberMeToggle
