import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import InCardStack from 'components/atoms/InCardStack'
import OverlayLoading from 'components/atoms/OverlayLoading'
import StyledButton, {
  BackIconButton,
  NextIconButton
} from 'components/atoms/button/StyledButton'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import RowStack from 'components/atoms/container/RowStack'
import FullAssessmentCategoryQuestions from 'components/molecules/assessments/fullAssessment/FullAssessmentCateogryQuestions'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HOME } from 'routes/constants'
import { getConstant } from 'services/constantServices'
import {
  ASSESSMENT_CATEGORIES_URL,
  ASSESSMENT_REQUESTS_URL,
  ASSESSMENT_SUBMIT_URL,
  CONSTANTS_OPTIONS
} from 'services/constants'
import { getAssessmentProgress } from 'services/fullAssessmentServices'
import { getRequest, patchRequest, postRequest } from 'utils/api'
import {
  getLocalUser,
  getPopupConfig,
  setPopupConfig
} from 'utils/localStorage'
import FullAssessmentHeader from 'components/molecules/assessments/fullAssessment/FullAssessmentHeader'
import { useTranslation } from 'react-i18next'
import SuccessfulNotificationDialog from 'components/molecules/notificationOverlay/SuccessfulNotificationDialog'
import FullAssessmentLandingCarousel from 'components/molecules/assessments/fullAssessment/FullAssessmentLandingCarousel'
import { TextBox } from 'components/atoms/input/StyledInputs'

const compileQuestions = categories => {
  const questionModals = [
    ...new Set(categories.map(c => c?.category?.modelName))
  ].sort((a, b) => a - b)
  const quests = []
  const models = questionModals.map(m => {
    const modelCategories = categories.filter(c => c?.category?.modelName === m)
    modelCategories.forEach(c => {
      quests.push(...c.questions)
    })
    return { name: m, categories: modelCategories }
  })
  return [models, quests]
}

const displayConfigKey = 'fullAssessmentLandingScreen'

const FullAssessmentCard = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()
  const [displayLandingScreen, setDisplayLandingScreen] = useState(true)
  const [assessment, setAssessment] = useState(null)
  const [scores, setScores] = useState({})
  const [comments, setComments] = useState(null)
  const [activePage, setActivePage] = useState(0)
  const [questions, setQuestions] = useState([])
  const [assessmentModels, setAssessmentModels] = useState([])
  const [categories, setCategories] = useState([])
  const [options, setOptions] = useState([])
  const [saved, setSaved] = useState(true)
  const [completed, setCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const topRef = useRef(null)

  useEffect(() => {
    const saved = getPopupConfig(displayConfigKey)
    if (!saved) {
      setDisplayLandingScreen(true)
      setPopupConfig(displayConfigKey, true)
    } else {
      setDisplayLandingScreen(false)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await getAssessmentProgress()
      const assessmentOptions = await getConstant(
        CONSTANTS_OPTIONS.EVALUATION_OPTIONS
      )
      if (assessmentOptions) {
        const postiveOptions = assessmentOptions.filter(o => o.value > 0)
        const rearrangedOptions = [
          ...postiveOptions,
          ...assessmentOptions.filter(o => o.value === 0)
        ]
        setOptions(rearrangedOptions)
      }
      if (res) {
        const catsRes = await getRequest(ASSESSMENT_CATEGORIES_URL, {
          goalId: res[0].goalId
        })
        const userAssessment = res[0]
        setAssessment(userAssessment)
        if (catsRes.status === 200) {
          const cats = catsRes.data.data
          const [models, quests] = compileQuestions(cats)
          setAssessmentModels(models)
          setCategories(cats)
          setQuestions(quests)
          setScores(userAssessment?.selfScore ?? {})
          setComments(userAssessment?.comments ?? '')
          if (userAssessment.currentCategory) {
            // Paginate to not completed page
            const activeIndex = cats.findIndex(
              c => c.category.id === userAssessment.currentCategory.id
            )
            setActivePage(activeIndex ?? cats.length)
          } else if (userAssessment.progress === 1) {
            setActivePage(cats.length)
          }
        }
      } else {
        navigate(HOME)
      }

      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const upsertScore = async () => {
    if (!assessment?.id && Object.keys(scores).length > 0) {
      const user = getLocalUser()
      const res = await postRequest(ASSESSMENT_SUBMIT_URL, {
        for_user_id: user.id,
        goal_id: assessment.goalId,
        assessment_question_scores: scores,
        version: questions[0].version
      })
      if (res.status === 200) {
        setAssessment(res.data.data)
        setSaved(true)
      }
    } else if (assessment?.id) {
      const res = await patchRequest(
        `${ASSESSMENT_REQUESTS_URL}/${assessment.id}`,
        { selfScore: scores, comments }
      )
      if (res.status === 200) {
        setSaved(true)
      }
    }
  }
  useEffect(() => {
    if (Object.keys(scores).length > 0) {
      setSaved(false)
    }

    const updateTimeout = setTimeout(async () => {
      await upsertScore()
    }, 2000)
    return () => clearTimeout(updateTimeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scores, comments])

  const handleNextPage = () => {
    setActivePage(activePage + 1)
    if (!saved) {
      upsertScore()
    }
    topRef.current?.scrollIntoView({ block: 'center' })
  }

  const handleComplete = async () => {
    const res = await patchRequest(
      `${ASSESSMENT_REQUESTS_URL}/${assessment.id}`,
      { selfScore: scores, comments, isCompleted: true }
    )
    if (res.status === 200) {
      setSaved(true)
      setCompleted(true)
    }
  }

  const nextDisabled = () => {
    const answered = categories[activePage].questions.map(q => {
      if (scores[q.id] === undefined) {
        return false
      }
      return true
    })
    return answered.some(a => a === false)
  }

  const handleExit = async () => {
    if (!saved) {
      setLoading(true)
      await upsertScore()
      setLoading(false)
    }
    navigate(HOME)
  }
  return (
    <InCardStack width='100%'>
      {displayLandingScreen ? (
        <FullAssessmentLandingCarousel
          startAction={() => setDisplayLandingScreen(false)}
          questionCount={questions?.length || 50}
        />
      ) : (
        <>
          <Box width='100%' position='relative'>
            <Box ref={topRef} sx={{ position: 'absolute', top: -80 }} />
            <StyledButton
              startIcon={<KeyboardBackspaceIcon />}
              onClick={handleExit}
              sx={{ position: 'absolute' }}>
              {t('assessments.saveNExit')}
            </StyledButton>
            <Typography variant='h1' color='primary' textAlign='center'>
              Self Assessment
            </Typography>
          </Box>
          {questions?.length && (
            <>
              <FullAssessmentHeader
                models={assessmentModels}
                scores={scores}
                saved={saved}
                activeModelName={categories[activePage]?.category?.modelName}
                questionsLength={questions.length}
              />
              <BoxWithBackground
                padding={20}
                style={{ marginBottom: 20 }}
                backgroundColor={
                  activePage < categories.length
                    ? undefined
                    : theme.palette.background.paper
                }>
                <Grid container rowSpacing={10}>
                  {activePage < categories.length ? (
                    <FullAssessmentCategoryQuestions
                      category={categories[activePage].category}
                      questions={categories[activePage].questions}
                      scores={scores}
                      setScore={setScores}
                      options={options}
                    />
                  ) : (
                    <Grid item xs={12} width='100%'>
                      <Container maxWidth='md'>
                        <InCardStack>
                          <Typography variant='body1Large'>
                            {t('assessments.reflectionQuestion')}
                          </Typography>
                          <TextBox
                            placeholder={t('assessments.reflectionPlaceholder')}
                            removeBorder={false}
                            value={comments}
                            onChange={e => setComments(e.target.value)}
                            minRows={8}
                          />
                        </InCardStack>
                      </Container>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent='flex-end'
                    columnSpacing={activePage < categories.length ? 56 : 0}
                    paddingTop={6}>
                    <Grid
                      item
                      xs={12}
                      lg={activePage < categories.length ? 6 : 12}
                      paddingX={12}
                      paddingY={6}>
                      <RowStack
                        justifyContent='space-between'
                        data-testid='navigation-bar'>
                        <BackIconButton
                          disabled={activePage === 0}
                          onClick={() => setActivePage(activePage - 1)}
                        />
                        {activePage < categories.length ? (
                          <NextIconButton
                            disabled={nextDisabled()}
                            onClick={handleNextPage}
                          />
                        ) : (
                          <StyledButton onClick={handleComplete}>
                            {t('assessments.submitAssessment')}
                          </StyledButton>
                        )}
                      </RowStack>
                    </Grid>
                  </Grid>
                </Grid>
              </BoxWithBackground>
            </>
          )}
        </>
      )}
      <SuccessfulNotificationDialog
        open={completed}
        titleText={t('assessments.assessmentComplete')}
        confirmButtonText={t('rolePlay.viewYourResults')}
        notificationText={t('assessments.greatJobFullAssessment')}
        buttonAction={() => navigate(HOME)}
        updatedStyle={true}
      />
      <OverlayLoading open={loading} />
    </InCardStack>
  )
}

export default FullAssessmentCard
