import { useEffect, useState } from 'react'
import { Box, CardHeader, Stack, TableCell, TableRow } from '@mui/material'
import BackIconButton from 'components/atoms/button/BackIconButton'
import RowStack from 'components/atoms/container/RowStack'
import { useTranslation } from 'react-i18next'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { getFullName, getPercentageDisplay } from 'utils/formatText'
import { getRequest } from 'utils/api'
import { USERS_LEARNING_PATH_STATS_URL } from 'services/constants'
import OverlayLoading from 'components/atoms/OverlayLoading'
import SortableHeaderCell from 'components/atoms/table/SortableHeader'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import { getGoals } from 'utils/getSupportingInfo'
import { requestSort } from 'utils/collectionHelper'

const DrillDownUserDetails = ({ openMainUserTab, userDetails }) => {
  const { t } = useTranslation()
  const fullName = getFullName(userDetails)
  const email = fullName === userDetails.email ? null : userDetails.email
  const [data, setData] = useState([])
  const [displayData, setDisplayData] = useState([])
  const [currentSort, setCurrentSort] = useState(undefined)
  const [sortOrder, setSortOrder] = useState('asc')
  const [loading, setLoading] = useState(false)

  const sortableColumns = [
    {
      label: t('overview.learningPath'),
      columnName: 'goalName'
    },
    {
      label: t('superLeaderUserDrillDown.complete'),
      columnName: 'percentageCompleteCurrentGoal'
    },
    {
      label: t('insights._360sSent'),
      columnName: 'currentGoal360Sent'
    },
    {
      label: t('superLeaderUserDrillDown._360sResponse'),
      columnName: 'currentGoal360Completed'
    },
    {
      label: t('superLeaderUserDrillDown.minutesOnPath'),
      columnName: 'totalViewTime'
    }
  ]

  const mapGoals = async goalStats => {
    const goals = await getGoals()
    return goalStats.map(goalStat => {
      const goal = goals.find(goal => goal.id === goalStat.goalId)
      goalStat.goalName = goal.name
      goalStat.percentageCompleteCurrentGoal = getPercentageDisplay(
        goalStat.percentageCompleteCurrentGoal
      )
      // convert millisecond to minute
      goalStat.totalViewTime = Math.floor(goalStat.totalViewTime / 60000)
      return goalStat
    })
  }

  const handleRequestSort = (pSortBy, clickAction = true) => {
    requestSort(
      data,
      currentSort,
      sortOrder,
      pSortBy,
      setCurrentSort,
      setSortOrder,
      setDisplayData,
      clickAction
    )
  }

  useEffect(() => {
    const fetchData = async userId => {
      setLoading(true)
      const res = await getRequest(USERS_LEARNING_PATH_STATS_URL, { userId })
      if (res.status === 200) {
        const data = await mapGoals(res.data.data)
        setData(data)
      }
      setLoading(false)
    }

    if (userDetails?.id) {
      fetchData(userDetails.id)
    }
  }, [userDetails])

  useEffect(() => {
    if (currentSort) {
      handleRequestSort(currentSort, false)
    } else {
      setDisplayData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Stack spacing={10}>
      <OverlayLoading open={loading} />
      <RowStack>
        <BackIconButton onClick={openMainUserTab} />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center'
          }}>
          <CardHeader
            avatar={<ProfileAvatar user={userDetails} />}
            title={fullName}
            subheader={email}
          />
        </Box>
      </RowStack>
      <PresetTableContainer
        headers={
          <>
            {sortableColumns.map(({ label, columnName }) => {
              return (
                <SortableHeaderCell
                  key={columnName}
                  currentSort={currentSort}
                  sortOrder={sortOrder}
                  requestSort={handleRequestSort}
                  attributeName={columnName}>
                  {label}
                </SortableHeaderCell>
              )
            })}
          </>
        }>
        {displayData.map(goal => {
          return (
            <TableRow key={goal.goalId}>
              <TableCell>{goal.goalName}</TableCell>
              <TableCell>{goal.percentageCompleteCurrentGoal}</TableCell>
              <TableCell>{goal.currentGoal360Sent}</TableCell>
              <TableCell>{goal.currentGoal360Completed}</TableCell>
              <TableCell>{goal.totalViewTime}</TableCell>
            </TableRow>
          )
        })}
      </PresetTableContainer>
    </Stack>
  )
}

export default DrillDownUserDetails
