const colors = {
  BondiBlue: '#0396A8',
  MonarkYellow: '#FCBE1F',
  MainLightBackground: '#FAFAFA',
  GhostWhite: '#F7F8FB',
  Aluminium: '#808284',
  SpunPearl: '#AEABBD',
  Black: '#333333',
  White: '#FFFFFF',
  Whisper: '#E5E5E5',
  Solitude: '#E1E5EF',

  Gainsboro: '#E0E0E0',
  Cinnabar: '#EC7161',
  Nobel: '#979797',

  BigStone: '#334148',
  BigStone25: '#33414840',
  LightCyan: '#e6f5f6',
  WhiteSmoke: '#FDF1F0',
  Seashell: '#FEF6F2',
  LightSalmon: '#FFA37A',
  Azure: '#EDF7F9',
  CaribbeanBlue: '#006F7C',

  SelectiveYellow: '#fcebb5',
  Terracotta: '#EC7161',

  WhiteLilac: '#F8F7FB',
  JetGrey: '#616A80',
  DeepPeach: '#FFCCB5',
  DarkGreen: '#2E4F59',
  TiffanyBlue: '#8fced8',
  White25: '#FFFFFF40',
  SilverSand: '#C4C4C4',
  StealTeal: '#607D8B',

  BrightGreen: '#32D74B',
  Orangish: '#FBBB00',

  LightBlue: '#F2FAFA',
  DarkBlue: '#25355F',
  DarkBlueishGreenish: '#2F4F59',
  Transparent: '#00000000',
  LightGrey100: '#F8F7FB',
  LightGrey200: '#D9D9D9',
  LightGrey400: '#8D8D8D',
  DarkGrey: '#49494A',
  Grey: '#EEEEEE',
  LightGreen: '#3ABF37',
  LightGreen20: '#3ABF3733',
  MetalGrey: '#F7F9FA',
  Grey50: '#F4F4F4',
  Grey300: '#A4A4A4',
  LightYellow: '#FEF9EC',

  /* New colors 2024 */
  /* Primary */
  Teal400: '#0396A8',
  GoldenYellow400: '#FCBE1F',
  //White: '#FFFFFF',

  /* Secondary */
  //Black: '#333333',
  MidnightTeal400: '#395966',
  Watermelon400: '#EC7161',
  Peach400: '#FFA37A',

  /* Blues */
  Teal500: '#007F8F',
  MutedTeal400: '#81BBC2',
  MutedTeal300: '#B3D6DA',
  MutedTeal200: '#D1E6E9',
  MutedTeal100: '#E1EFEF',
  MutedTeal50: '#F1F7F7',
  OceanTeal400: '#A0CBCB',

  /* Greys */
  Grey400: '#49494A',
  //Grey300: '#A4A4A4',
  Grey200: '#D1D1D1',
  Grey100: '#E8E8E8',
  //Grey50: '#F4F4F4',

  /* Golden Yellows */
  GoldenYellow300: '#FFD25E',
  GoldenYellow200: '#FFDD9C',
  GoldenYellow100: '#FFEBC4',
  GoldenYellow50: '#FFF9EE',

  /* Scale Colors */
  Salomie: '#FFE38A',
  Deco: '#D8DA87',
  PineGlade: '#B0D184',
  DeYork: '#87C882',
  Fern: '#5DBF7F',

  /* System Message colors */
  SuccessGreen: '#2E992C',
  ErrorRed: '#D94A38',
  WarnOrange: '#F8931D',
  WarnYellow: '#F6C61C',
}
export default colors
