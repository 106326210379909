import { Divider, Stack, TableCell, TableRow, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import DateCell from 'components/atoms/table/DateCell'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import UsersIconPopupList from 'components/molecules/UsersIconPopupList'
import EditCohortSessionButton from 'components/molecules/adminDashboard/cohorts/EditCohortSessionButton'
import { useEffect, useState } from 'react'
import { COHORTS_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { getGoalNameById } from 'utils/getSupportingInfo'
import { useTranslation } from 'react-i18next'

const CohortManagement = ({ organizationId }) => {
  const { t } = useTranslation()
  const [cohortSessions, setCohortSessions] = useState([])
  const [requestSuccess, setRequestSuccess] = useState(undefined)
  const fetchCohorts = async () => {
    const res = await getRequest(COHORTS_URL, { organizationId })
    if (res.status === 200) {
      const cohortRes = res.data.data
      const sessionRes = await Promise.all(
        cohortRes.map(async r => {
          const sessions = await Promise.all(
            r.cohortSessions.map(async (s, i) => {
              const goalName = await getGoalNameById(s.goalId)
              return {
                ...s,
                index: i,
                members: i === 0 ? r.cohortMembers : [],
                cohortName: i === 0 ? r.name : undefined,
                goalName
              }
            })
          )
          return sessions
        })
      )
      const sessionsData = [].concat(...sessionRes)
      setCohortSessions(sessionsData)
      setRequestSuccess(true)
    } else {
      setRequestSuccess(false)
    }
  }
  useEffect(() => {
    fetchCohorts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  return (
    <Stack spacing={5}>
      <Divider sx={{ marginTop: 10 }} />
      <InCardStack direction='row'>
        <Typography variant='h3'>{t('company.cohorts')}</Typography>
        <EditCohortSessionButton
          newCohort={true}
          organizationId={organizationId}
          successCallback={fetchCohorts}
        />
      </InCardStack>
      <PresetTableContainer
        maxHeight='800px'
        headers={
          <>
            <TableCell />
            <TableCell>{t('company.name')}</TableCell>
            <TableCell>{t('company.members')}</TableCell>
            <TableCell>{t('company.startDate')}</TableCell>
            <TableCell>{t('company.endDate')}</TableCell>
            <TableCell>{t('company.path')}</TableCell>
            <TableCell>{t('company.autoAssihnPath')}</TableCell>
            <TableCell>{t('company._360Reassessment')}</TableCell>
            <TableCell>{t('company.postGoalEmail')}</TableCell>
            <TableCell>{t('company.preFacilitationEmail')}</TableCell>
            <TableCell />
          </>
        }>
        {cohortSessions.map(c => (
          <TableRow key={`cohort-session-${c.id}`}>
            <TableCell>
              {c.index === 0 ? (
                <EditCohortSessionButton
                  newSession={true}
                  cohort={{ id: c.cohortId, name: c.cohortName }}
                  successCallback={fetchCohorts}
                  organizationId={organizationId}
                />
              ) : null}
            </TableCell>
            <TableCell>{c.cohortName}</TableCell>
            <TableCell>
              <UsersIconPopupList
                parentKey={c.id}
                users={c.members}
                popupTitle={t('company.allMembers')}
              />
            </TableCell>
            <DateCell value={c.startDate} />
            <DateCell value={c.endDate} />
            <TableCell>{c.goalName}</TableCell>
            <TableCell>{c.autoAssignPathOnStart ? t('company.on') : t('company.off')}</TableCell>
            <TableCell>
              {c.resend360AfterDays ? `${c.resend360AfterDays} ${t('company.days')}` : t('company.off')}
            </TableCell>
            <TableCell>{c.postGoalActivityEmails ? t('company.on') : t('company.off')}</TableCell>
            <TableCell>{c.preFacilitationEmails ? t('company.on') : t('company.off')}</TableCell>
            <TableCell>
              <EditCohortSessionButton
                cohortSession={c}
                successCallback={fetchCohorts}
                editMode={true}
                organizationId={organizationId}
              />
            </TableCell>
          </TableRow>
        ))}
      </PresetTableContainer>
      {requestSuccess === false ? <RequestRetryAlert /> : null}
    </Stack>
  )
}

export default CohortManagement
