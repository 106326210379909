import PracticePage from 'components/templates/navigationPageTemplates/PracticePage'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROLE_PLAY_PRACTISES } from 'routes/constants'
import RolePlayPageCard from 'components/organisms/rolePlay/RolePlayPageCard'

const RolePlay = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!location?.state?.rolePlayId) {
      navigate(ROLE_PLAY_PRACTISES)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PracticePage>
      <RolePlayPageCard
        initialRolePlayId={location?.state?.rolePlayId}
        initialUserRolePlayId={
          window.history?.state?.activeUserRolePlayId ||
          location?.state?.activeUserRolePlayId
        }
      />
    </PracticePage>
  )
}

export default RolePlay
