//Constants for api url, requests

export const SIGNUP_URL = '/signup'
export const LOGIN_URL = '/login'
export const LOGOUT_URL = '/logout'
export const FORGOT_PASSWORD_URL = '/forgot-password'
export const RESET_PASSWORD_URL = '/reset-password'
export const USER_PROFILE_URL = '/user-profile'
export const USER_STATES_URL = '/user-states'
export const CHECK_USER_EXIST_URL = '/user-exists'
export const UNSUBSCRIBE_URL = '/unsubscribe'
export const VERIFY_EMAIL_URL = '/email-verification'
export const ACTIVITY_SCORES_URL = '/user-activity-scores'
export const ADD_DEVICE_TOKEN_URL = '/add-device-token'

export const GOAL_TIMELINES_URL = '/goal-timelines'
export const GOAL_STEP_URL = '/goal-steps'
export const GOAL_STEP_CONTENT_URL = '/goal-step-contents'
export const GOAL_STEP_QUIZ_URL = '/goal-step-quizzes'
export const GOAL_STEP_USER_FEEDBACK_URL =
  '/goal-steps/{goal_step_id}/user-feedback'
export const USER_NOTES_URL = '/user-notes'
export const USER_QUIZ_RESULTS_URL = '/user-quiz-results'
export const USER_QUIZ_RESULTS_CHECK_REPORT_URL =
  '/user-quiz-results/learning-check-report'
export const AI_ROLE_PLAY_V2_URL = '/ai-role-play-v2'
export const AI_STT_URL = '/ai-stt'
export const AI_TTS_URL = '/ai-tts'
export const AI_CONTENT_SEARCH_URL = '/ai-content-search'

export const VIRTUES_URL = '/virtues'
export const LEADERSHIP_MODELS_URL = '/leadership-models'

export const GOALS_URL = '/goals'
export const ASSESSMENT_INVITES_URL = '/assessments/invites'
export const ASSESSMENT_REQUESTS_URL = '/assessment-requests'
export const ASSESSMENT_RECIPIENTS_URL = '/assessment-recipients'
export const ASSESSMENT_QUESTIONS_SELF_URL = '/assessment-questions/self'
export const ASSESSMENT_QUESTIONS_OTHER_URL = '/assessment-questions/others'
export const ASSESSMENT_SUBMIT_URL = '/assessments'
export const ASSESSMENT_RATINGS_TIMELINE_URL = '/assessments/ratings-timeline'
export const ASSESSMENT_BY_GOAL_URL = '/assessments'
export const ASSESSMENT_SHARE_RESULT_URL = '/assessments/{id}/share-result'
export const ASSESSMENT_REPORT_URL = '/assessments/report'
export const ASSESSMENT_REPORT_PUBLIC_URL = '/public/assessments'
export const ASSESSMENT_PROGRESS_URL = '/assessments/progress'
export const ASSESSMENT_CATEGORIES_URL = '/assessment-categories'

export const PULSE_SURVEYS_URL = '/pulse-surveys'
export const PULSE_SURVEY_RECIPIENTS_URL = 'pulse-survey-recipients'
export const PULSE_SURVEYS_QUESTIONS_URL = '/pulse-survey-questions'

export const USER_FEEDBACK_URL = '/user-feedbacks'
export const TEAM_SHOUTOUTS_URL = '/leadership-shout-outs'
export const USER_CALENDAR_EVENTS_URL = '/user-calendar-events'
export const CALENDER_OAUTH_TOKEN_URL = '/user-oauth-tokens'

export const CONSTANTS_URL = '/constants'

export const ORGANIZATIONS_URL = '/organizations'
export const COHORTS_URL = '/cohorts'
export const COHORT_SESSIONS_URL = '/cohort-sessions'
export const COHORT_GOAL_FACILITATION_URL = 'cohort-goal-facilitations'
export const USER_LICENSES_URL = '/user-licenses'
export const USER_COMPLETED_STEPS_URL = '/user-completed-steps'

export const USER_URL = '/user'
export const USERS_URL = '/users'
export const USERS_LEARNING_PATH_STATS_URL = '/users/learning-path-stats'
export const INVITED_LIST_URL = '/user-invites'
export const SEND_INVITE_EMAIL_URL = '/user-invites/signup'

export const USER_CONNECTION_URL = '/user-connections'
export const USER_CONNECTION_NOTE_URL = '/user-connection-notes'
export const USER_CONNECTION_EVENTS_URL = '/user-connection-events'

export const BOOKMARKS_URL = '/content-bookmarks'
export const CONTENT_SEARCH_URL = '/content-search'

export const SUPPORT_REQUEST_URL = '/support-request'

export const EMAIL_CLICK_TRACKING_URL = '/tracking-notification-click'
export const POST_GOAL_QUIZ_LOGGING_URL =
  '/tracking-notification-click/log-quiz-answer'
export const POST_GOAL_ACTIVITY_URL = '/post-goal-weekly-emails'
export const SCREEN_TRACKING_URL = '/tracking-screens'
export const SCREEN_CLICK_TRACKING_URL = '/tracking-screen-clicks'

export const OHEP_SURVEYS_URL = '/ohep-surveys'
export const OHEP_SURVEYS_DETAIL_URL = '/ohep-surveys/details'
export const OHEP_RESPONSES_URL = '/ohep-responses'

export const FEATURES_URL = '/features'
export const USER_ALLOWED_FEATURE_URL = '/user-allowed-features'
export const FEATURES_FEEDBACK_URL = '/feature-feedbacks'

export const DEMO_SNAPSHOT_URL = '/demo-snapshots'
export const DEMO_SNAPSHOT_RESTORE_URL = '/demo-snapshots/restore'

export const SUPER_LEADER_SCORE = '/super-leader-scores'
export const APP_USAGE = '/app-usage'

export const ROLE_PLAYS_URL = '/role-plays'
export const USER_ROLE_PLAYS_URL = 'user-role-plays'
export const LIVE_ROLE_PLAY_URL = '/live-role-play'
export const SURVEY_HIGH_LOW_CUTOFF = 3

export const LOGIN_TYPES = {
  PASSWORD: '1',
  GOOGLE: '2',
  FACEBOOK: '3',
  TWITTER: '4',
  APPLE: '5',
  MICROSOFT: '6'
}
export const PULSE_SURVEY_STATUSES = {
  OPEN: 'open',
  CLOSED: 'closed',
  INCOMPLETE: 'incomplete'
}
export const USER_FEEDBACK_TYPES = {
  COMMENT: 'comment'
}
export const ASSESSMENT_STATUSES = {
  OPEN: 'open',
  CLOSED: 'closed',
  INCOMPLETE: 'incomplete'
}
export const ASSESSMENT_RECIPIENT_STATUSES = {
  CREATED: 'created',
  PENDING: 'pending',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  EXPIRED: 'expired'
}

export const USER_CONNECTION_RELATIONSHIP = [
  // Labels can be found in translation files
  { value: 'direct_report' },
  { value: 'direct_supervisor' },
  { value: 'senior_leader' },
  { value: 'colleague' },
  { value: 'other' }
]

export const NOTIFICATION_TYPES = { PUSH: 'push', EMAIL: 'email' }

export const INTRO_VIDEO_URI =
  'https://monark-public-assets.s3.ca-central-1.amazonaws.com/Monark-Welcome-480p.mp4'

export const USER_NOTES_TYPES = {
  GOAL: 'goal',
  NOTE: 'note',
  ACTIVITY: 'activity'
}

export const VIRTUE_TYPES = {
  BEHAVIOUR: 'behaviour',
  WORK_VALUE: 'work_value'
}

export const USER_ROLE_TYPES = {
  SUPER_ADMIN: 'admin',
  RESTRICTED: 'restricted',
  ADMINISTRATOR: 'manager',
  COACH: 'coach'
}

export const LICENSE_TYPES = {
  INVOICE: 'invoice',
  TRIAL: 'trial',
  AUTOMATIED: 'automatic'
}

export const CALENDAR_OAUTH_TOKEN_PROVIDERS = {
  MICROSOFT: 'microsoft',
  GOOGLE: 'google'
}

export const CALENDAR_OAUTH_STORAGE_KEY = {
  MICROSOFT: 'microsoftOAuth'
}

export const GOAL_STEP_QUIZ_TYPES = {
  SURVEY: 'survey_multiple_choice',
  CHECK: 'check_multiple_choice',
  ROLE_PLAY: 'role_play'
}

export const CONSTANTS_OPTIONS = {
  INDUSTRY_OPTIONS: 'INDUSTRY_OPTION_LIST',
  JOB_OPTIONS: 'JOB_OPTION_LIST',
  DEPARTMENT_OPTIONS: 'DEPARTMENT_OPTION_LIST',
  ONBOARDING_QUESTIONNARIE: 'ONBOARDING_QUESTIONNARIE',
  NOTIFICATION_CONFIG: 'NOTIFICATION_CONFIG',
  JOURNEY_WORK_VALUE_SURVEY_OPTIONS: 'JOURNEY_WORK_VALUE_SURVEY_OPTIONS',
  EVALUATION_OPTIONS: 'EVALUATION_OPTIONS',
  QUIZ_RESULT_MESSAGES: 'QUIZ_RESULT_MESSAGES',
  ONE_ON_ONE_SURVEY_EVALUATIONS: 'ONE_ON_ONE_SURVEY_EVALUATIONS'
}

export const FEATURE_STATUSES = {
  BETA: 'beta',
  PRODUCTION: 'production'
}

export const FEATURE_KEYS = {
  ASK_ME_ANYTHING_LEADERSHIP: 'ask_me_anything_leadership',
  AUDIO_ROLEPLAY: 'audio_roleplay',
  COMPANY_INSIGHTS: 'company_insights',
  ROLE_PLAY_STANDALONE: 'roleplay_standalone',
  MY_TEAM_ONE_ON_ONE: 'my_team_one_on_one',
  FULL_LEADERSHIP_ASSESSMENT: 'full_leadership_assessment'
}

export const ROLE_PLAY_TYPES = Object.freeze({
  LIVE_CALL: 'live-call',
  TEXT_CHAT: 'text-chat'
})

export const USER_CONNECTION_EVENT_TYPES = {
  ONE_TO_ONE: 'one-to-one'
  // PERFORMANCE_REVIEW: 'performance-review',
  // CHECK_IN: 'check-in'
}

export const CALENDAR_RECURRENCE_FREQ = {
  WEEKLY: 'WEEKLY',
  BI_WEEKLY: 'BI_WEEKLY',
  MONTHLY: 'MONTHLY',
  DO_NOT_REPEAT: 'DO_NOT_REPEAT'
}
