import moment from 'moment'
require('moment/locale/fr')

export const formatDate = (dateTimeString, locale = 'en-US') =>
  dateTimeString
    ? Intl.DateTimeFormat(locale, {
        month: 'long',
        day: '2-digit',
        year: 'numeric'
      }).format(Date.parse(dateTimeString))
    : ''

export const formatDateShort = (dateTimeString, locale = 'en-US') =>
  dateTimeString
    ? Intl.DateTimeFormat(locale, {
        month: 'short',
        day: '2-digit'
      }).format(Date.parse(dateTimeString))
    : ''

export const formatDateTime = (dateTimeString, locale = 'en') => {
  moment.locale(locale)
  return moment(dateTimeString).format('MMMM Do, YYYY h:mm A')
}

export const formatTime = (dateTimeString, locale = 'en') => {
  moment.locale(locale)
  return moment(dateTimeString).format('h:mm A')
}

export const formatDayMonthDay = (dateTimeString, locale = 'en') => {
  moment.locale(locale)
  return moment(dateTimeString).format('dddd, MMMM DD')
}

export const convertSecsToMins = seconds => {
  const mins = Math.floor(seconds / 60)
  const secs = seconds % 60
  let result = ''
  if (mins > 0) {
    result += `${mins}m`
  }
  if (secs > 0) {
    result += ` ${secs}s`
  }
  return result
}

export const formatDifference = (start, end) => {
  const diff = moment.duration(moment(end).diff(moment(start)))
  return `${diff.hours()}h ${diff.minutes()}m`
}
