import { Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import { useState } from 'react'
import ProfileCard from '../ProfileCard'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { updateProfile } from 'services/userServices'
import OnboardingQuestiionnaire from '../OnboardingQuestionnaire'
import { getLocalUser } from 'utils/localStorage'
import { useTranslation } from 'react-i18next'
import LinearProgressBar from 'components/atoms/LinearProgressBar'
import colors from 'styles/colors'
import StyledButton from 'components/atoms/button/StyledButton'

const StepTwo = ({ completeCallback }) => {
  const { t } = useTranslation()
  const userInfo = getLocalUser()
  const [activePage, setActivePage] = useState(0)
  const [answers, setAnswers] = useState(userInfo.onboardingAnswers || {})
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    if (Object.keys(answers).length !== 0) {
      setLoading(true)
      const data = new FormData()
      data.append('onboardingAnswers', JSON.stringify(answers))
      await updateProfile(data)
    }
    completeCallback()
  }

  const answerHasKey = key => answers.hasOwnProperty(key)
  const progress = () =>
    activePage * 50 + (answerHasKey(1) ? 25 : 0) + (answerHasKey(3) ? 25 : 0)
  const canContinue = answerHasKey(1)
  return (
    <>
      <Typography variant='h1' color='primary'>
        {t('onboarding.createYourProfile')}
      </Typography>
      <LinearProgressBar
        value={progress()}
        label={`${progress()}% ${t('common.complete')}`}
      />

      {activePage === 0 ? (
        <ProfileCard
          submitButtonText={t('common.continue')}
          continueCallback={() => setActivePage(1)}
          fromSignup={true}
          updatedStyle={true}
          showOptions={false}
        />
      ) : activePage === 1 ? (
        <>
          <Typography textAlign='center' color={colors.Grey400}>
            {t('onboarding.aFewQuestion')}
          </Typography>
          <InCardStack sx={{ paddingTop: 10 }}>
            <OnboardingQuestiionnaire
              updateAnswers={newAnswers => setAnswers(newAnswers)}
              userAnswers={answers}
            />
            <StyledButton
              disabled={!canContinue}
              onClick={() => handleSubmit()}>
              {t('common.continue')}
            </StyledButton>
          </InCardStack>
        </>
      ) : null}
      <OverlayLoading open={loading} />
    </>
  )
}

export default StepTwo
