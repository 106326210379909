import { useTranslation } from 'react-i18next'
import { getDisplayScore } from 'utils/formatText'
import { downloadExcel } from 'react-export-table-to-excel'
import ShortButton from 'components/atoms/button/ShortButton'
import { formatDate } from 'utils/formatDateTime'

const ExportAssessmentButton = ({ title, data, behaviourData, fileName }) => {
  const { t, i18n } = useTranslation()

  const getScoreData = scoreField => {
    const olderData = data[1] || data.old
    const newerData = data[0] || data.current

    const scoreDisplayData = getDisplayScore(
      olderData ? olderData[scoreField] : null,
      newerData[scoreField]
    )
    if (olderData) {
      scoreDisplayData.hasOlderData = true
      scoreDisplayData.oldTimeStamp = olderData.ratingTimeStamp
        ? olderData.ratingTimeStamp
        : olderData.timestamp
    } else {
      scoreDisplayData.hasOlderData = false
    }
    scoreDisplayData.newTimeStamp = newerData.ratingTimeStamp
      ? newerData.ratingTimeStamp
      : newerData.timestamp
    return scoreDisplayData
  }

  const getExcelBody = (heading, scoreField, behaviourDataArr = []) => {
    const averageRatingData = getScoreData(scoreField)
    return [
      [heading],
      [
        t('assessmentTimeline.overallAverageRating'),
        formatDate(averageRatingData.oldTimeStamp, i18n.resolvedLanguage),
        formatDate(averageRatingData.newTimeStamp, i18n.resolvedLanguage),
        averageRatingData.changePercent &&
          `${t('assessmentTimeline.change')} ${averageRatingData.changePercent > 0 ? '+' : ''}${
            averageRatingData.changePercent
          }%`
      ],
      [
        '',
        averageRatingData.oldScore > 0 ? averageRatingData.oldScore : '-',
        averageRatingData.newScore > 0 ? averageRatingData.newScore : '-',
        averageRatingData.changeScore
      ],
      [t('assessmentTimeline.behaviours')],
      ...behaviourDataArr
    ]
  }

  const handleDownloadExcel = () => {
    const body = [
      ...getExcelBody(
        t('goalsArea.self'),
        behaviourData.self.scoreField,
        behaviourData.self.data
      ),
      [''],
      ...getExcelBody(
        t('goalsArea.others'),
        behaviourData.others.scoreField,
        behaviourData.others.data
      )
    ]

    downloadExcel({
      fileName,
      sheet: 'sheet1',
      tablePayload: {
        header: [title],
        body
      }
    })
  }

  return (
    <ShortButton onClick={handleDownloadExcel}>
      {t('overview.export')}
    </ShortButton>
  )
}

export default ExportAssessmentButton
