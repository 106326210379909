import { Grid, useTheme } from '@mui/material'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'

export const ScrollPanel = ({
  md,
  children,
  height = 'inherit',
  gridSxProps = {},
  isBottomElement
}) => {
  const theme = useTheme()
  return (
    <Grid
      item
      md={md}
      width='100%'
      sx={gridSxProps}
      paddingBottom={isBottomElement ? 15 : null}>
      <BoxWithBorder
        height={height}
        width='100%'
        paddingY={5}
        borderColor={theme.palette.text.disabled}
        borderWidth='1px'
        sx={{
          marginTop: 5,
          background: theme.palette.background.paper,
          overflowY: 'auto'
        }}>
        {children}
      </BoxWithBorder>
    </Grid>
  )
}

export const TabPanel = ({
  children,
  tab,
  activeTab,
  isBottomElement,
  animation = true,
  animationEasyOut = true
}) => {
  const getTabAnimationStyle = active => {
    return {
      position: active ? 'initial' : 'absolute',
      transform: active ? 'translateX(0)' : 'translateX(120%)',
      opacity: active ? 1 : 0,
      transition: animation
        ? active
          ? 'transform 0.6s ease-in, opacity 0.6s ease-in'
          : animationEasyOut
            ? 'transform 0.6s ease-out, opacity 0.6s ease-out'
            : ''
        : ''
    }
  }

  return (
    <ScrollPanel
      gridSxProps={getTabAnimationStyle(activeTab === tab)}
      isBottomElement={isBottomElement}>
      {children}
    </ScrollPanel>
  )
}
