import {
  Box,
  Button,
  CardContent,
  Stack,
  Typography,
  Alert
} from '@mui/material'
import RadioOption from 'components/atoms/input/RadioOption'
import QuestionOptionsTemplate from 'components/templates/QuestionOptionsTemplate'
import StepQuizMatching from './StepQuizMatching'
import StepQuizSurveyResult from './StepQuizSurveyResult'
import {
  FEATURE_KEYS,
  CONSTANTS_OPTIONS,
  GOAL_STEP_QUIZ_TYPES,
  USER_QUIZ_RESULTS_URL,
  SURVEY_HIGH_LOW_CUTOFF
} from 'services/constants'
import { useEffect, useState } from 'react'
import { postRequest } from 'utils/api'
import { getVirtueById } from 'utils/getSupportingInfo'
import { getConstant } from 'services/constantServices'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import ImageContainer from 'components/atoms/container/ImageContainer'
import InCardStack from 'components/atoms/InCardStack'
import {
  deleteUserBasedStorageItem,
  getUserBasedStorageItem,
  setUserBasedStorageItem
} from 'utils/localStorage'
import StepQuizRolePlay from './StepQuizRolePlay'
import RolePlayPageCard from 'components/organisms/rolePlay/RolePlayPageCard'
import { isAllowedFeature } from 'utils/permissionCheck'
import { useTranslation } from 'react-i18next'

const quizAnswerBaseKey = 'quizAnswer'

const StepQuiz = ({
  stepData,
  scrollToTop,
  setShowStepper,
  pathOpen,
  togglePathOpen,
  contentActionTracking
}) => {
  const { t } = useTranslation()
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [options, setOptions] = useState([])
  const [quizResults, setQuizResults] = useState([])
  const [correctCheckCount, setCorrectCheckCount] = useState(0)
  const [quizResultMessages, setQuizResultMessages] = useState([])
  const [quizResultMessageDisplay, setQuizResultMessageDisplay] = useState(null)
  const [badgePopupOpen, setBadgePopupOpen] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)

  const hasStandAloneRolePlay = isAllowedFeature(
    FEATURE_KEYS.ROLE_PLAY_STANDALONE
  )

  const calculateSurveyScores = async (quest, ans) => {
    const scores = {}
    quest.questions.forEach((question, index) => {
      const attributeKey = question.virtue_id
      if (!scores[attributeKey]) {
        scores[attributeKey] = { sum: 0, count: 0 }
      }
      scores[attributeKey].sum += parseInt(ans[index])
      scores[attributeKey].count += 1
    })
    const resultArray = await Promise.all(
      Object.entries(scores).map(async ([key, value]) => {
        const intKey = parseInt(key)
        const virtue = await getVirtueById(intKey)

        const virtueMessages = quest.virtue_messages.find(
          x => x.virtue_id === intKey
        )

        const low = virtueMessages?.low || null
        const high = virtueMessages?.high || null

        const score = value.sum / value.count

        let highLowMsg
        if (score > SURVEY_HIGH_LOW_CUTOFF && high) {
          highLowMsg = { type: 'high', message: high }
        } else if (score <= SURVEY_HIGH_LOW_CUTOFF && low) {
          highLowMsg = { type: 'low', message: low }
        } else {
          highLowMsg = null
        }

        return {
          name: virtue.name,
          score: score,
          description: virtue.description,
          highLowMsg
        }
      })
    )
    resultArray.sort((a, b) => b.score - a.score)
    setQuizResults(resultArray)
    setSubmitted(true)
  }

  const calculateCheckScores = (quest, ans, messages) => {
    const resultsMatch = quest.filter((q, i) => {
      if (q.correctIndex.toString() === ans[i].toString()) {
        return true
      } else {
        return false
      }
    })
    setCorrectCheckCount(resultsMatch.length)
    const correctPercent = resultsMatch.length / quest.length
    const quizResultGroup = messages.find(q => q.minScore <= correctPercent)
    if (quizResultGroup) {
      quizResultGroup.displayMessage =
        quizResultGroup.messages[
          Math.floor(Math.random() * quizResultGroup.messages.length)
        ]
      setQuizResultMessageDisplay(quizResultGroup)
    }

    setSubmitted(true)
  }
  useEffect(() => {
    const processData = async () => {
      let quest
      try {
        quest = JSON.parse(stepData.questions)
      } catch {
        quest = stepData.questions
      }

      if (!stepData.userQuizResults?.length) {
        setSubmitted(false)
      }
      if (stepData.type === GOAL_STEP_QUIZ_TYPES.SURVEY) {
        if (Array.isArray(quest)) {
          // Required for backward compatiblity with old questions format
          quest = { questions: quest }
        }

        if (!quest.hasOwnProperty('virtue_messages')) {
          // Also for backward compatiblity
          quest.virtue_messages = []
        }

        const fetchedOptions = await getConstant(
          CONSTANTS_OPTIONS.JOURNEY_WORK_VALUE_SURVEY_OPTIONS
        )
        if (fetchedOptions) {
          setOptions(fetchedOptions)
        }

        if (stepData.userQuizResults?.length > 0) {
          await calculateSurveyScores(
            quest,
            stepData.userQuizResults[0].resultData
          )
        }
      } else if (stepData.type === GOAL_STEP_QUIZ_TYPES.CHECK) {
        const res = await getConstant(CONSTANTS_OPTIONS.QUIZ_RESULT_MESSAGES)
        if (res) {
          setQuizResultMessages(res)
        }
        if (stepData.userQuizResults?.length > 0) {
          setAnswers(stepData.userQuizResults[0].resultData)
          calculateCheckScores(
            quest,
            stepData.userQuizResults[0].resultData,
            res
          )
        } else {
          const localQuizAnswers = getUserBasedStorageItem(
            quizAnswerBaseKey,
            stepData.goalStepId
          )
          if (localQuizAnswers) {
            setAnswers(localQuizAnswers)
          }
        }
      }
      setQuestions(quest)
    }
    processData()

    // Close sidebar if it's open on roleplay page
    if (stepData.type === GOAL_STEP_QUIZ_TYPES.ROLE_PLAY && pathOpen) {
      togglePathOpen()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData])

  const getHasUnAnswerQuestion = newAnswers => {
    return newAnswers.includes(undefined) || newAnswers.includes(null)
  }

  const handleAnswerChange = index => {
    return event => {
      const newAnswers = answers.length > 0 ? answers : Array(questions.length)
      newAnswers[index] = event.target.value
      let hasUnAnswerQuestion = getHasUnAnswerQuestion(newAnswers)
      setAnswers(newAnswers)
      setUserBasedStorageItem(
        quizAnswerBaseKey,
        stepData.goalStepId,
        newAnswers
      )

      if (!hasUnAnswerQuestion) {
        setSubmitError(false)
        setErrorMessage(null)
      }
    }
  }

  const handleSubmit = async () => {
    scrollToTop()
    const resultData = answers.map(a => parseInt(a))
    const requestBody = { resultData, goalStepId: stepData.goalStepId }
    const res = await postRequest(USER_QUIZ_RESULTS_URL, requestBody)
    if (res.status === 201) {
      deleteUserBasedStorageItem(quizAnswerBaseKey, stepData.goalStepId)
    }
  }

  const onSubmitSurvey = async () => {
    await calculateSurveyScores(questions, answers)
    await handleSubmit()
  }

  const onSubmitCheck = async () => {
    calculateCheckScores(questions, answers, quizResultMessages)
    setShowStepper(true)
    await handleSubmit()
    setBadgePopupOpen(true)
    contentActionTracking('Complete Learning Check')
  }

  const onSubmitLearningCheck = () => {
    const newAnswers = answers.length > 0 ? answers : Array(questions.length)
    let hasUnAnswerQuestion = getHasUnAnswerQuestion(newAnswers)
    if (!hasUnAnswerQuestion) {
      if (stepData.type === GOAL_STEP_QUIZ_TYPES.SURVEY) onSubmitSurvey()
      else onSubmitCheck()
    } else {
      setSubmitError(true)
      setErrorMessage(t('stepQuiz.answerAllQuestionsValidation'))
    }
  }

  const SummaryText = ({ children }) => (
    <Typography
      variant='subtitle2'
      color='primary'
      paddingY={10}
      maxWidth='90%'
      alignSelf='center'>
      {children}
    </Typography>
  )
  const getOptions = (optionType, questionId) => {
    const selectedOptions = optionType ? options[optionType] : options[0]
    return selectedOptions?.map(v => (
      <RadioOption
        key={`option-${questionId}-${v.value}`}
        value={v.value}
        label={v.label_text}
      />
    ))
  }
  const Badge = ({ size }) => (
    <Box width={size}>
      <ImageContainer src={quizResultMessageDisplay?.badge} alt='score-badge' />
    </Box>
  )
  return (
    <>
      {stepData.type === GOAL_STEP_QUIZ_TYPES.ROLE_PLAY ? (
        <>
          {hasStandAloneRolePlay ? (
            <RolePlayPageCard
              initialRolePlayId={stepData?.questions?.rolePlayId}
              onLearningPath={true}
              pathOpen={pathOpen}
            />
          ) : (
            <StepQuizRolePlay
              quizData={stepData}
              settings={stepData?.questions}
            />
          )}
        </>
      ) : (
        <Stack paddingTop={0} width='100%'>
          {stepData.type === GOAL_STEP_QUIZ_TYPES.SURVEY ||
          stepData.type === GOAL_STEP_QUIZ_TYPES.CHECK ? (
            <>
              <CardContent>
                <Typography variant='subtitle1'>
                  {stepData.type === GOAL_STEP_QUIZ_TYPES.SURVEY
                    ? `${stepData.title || t('stepQuiz.workValuesSurvey')}${
                        submitted
                          ? ` ${quizResults.length === 1 ? t('stepQuiz.score') : t('stepQuiz.result')}`
                          : ''
                      }`
                    : stepData.title}
                </Typography>
              </CardContent>

              {submitted && stepData.summaryEnd ? (
                <SummaryText>{stepData.summaryEnd}</SummaryText>
              ) : !submitted && stepData.summary ? (
                <SummaryText>{stepData.summary}</SummaryText>
              ) : null}
              {stepData.type === GOAL_STEP_QUIZ_TYPES.SURVEY ? (
                questions.questions?.length ? (
                  submitted ? (
                    <StepQuizSurveyResult
                      resultData={quizResults}
                      quizData={stepData}
                    />
                  ) : (
                    questions.questions?.map((q, i) => (
                      <QuestionOptionsTemplate
                        key={`survey-question-${i}`}
                        question={q.question}
                        handleOnValueChange={handleAnswerChange(i)}
                        submitError={submitError}
                        options={getOptions(q.option_type, i)}
                      />
                    ))
                  )
                ) : null
              ) : questions.length ? (
                <>
                  {submitted ? (
                    <InCardStack>
                      {quizResultMessageDisplay ? <Badge size='100px' /> : null}
                      <Typography alignSelf='center'>
                        {t('stepQuiz.youScored')}
                        <Typography component='span' fontWeight='bolder'>
                          {correctCheckCount}/{questions.length}
                        </Typography>
                      </Typography>
                    </InCardStack>
                  ) : null}
                  {questions.map((q, i) => (
                    <QuestionOptionsTemplate
                      key={`question-${i}`}
                      question={`${i + 1}. ${q.question}`}
                      initialValue={answers[i]?.toString()}
                      correctAnswer={q.correctIndex?.toString()}
                      correctMessage={q.correctSummary || t('stepQuiz.correct')}
                      wrongMessage={
                        q.wrongSummary ||
                        `${t('stepQuiz.incorrectAnswer')} ${
                          q.options?.[q.correctIndex]
                        }`
                      }
                      handleOnValueChange={handleAnswerChange(i)}
                      showSummary={submitted}
                      submitError={submitError}
                      options={q.options?.map((a, j) => (
                        <RadioOption
                          value={j}
                          label={a}
                          disabled={submitted}
                          key={`option-${i}-${j}`}
                        />
                      ))}
                    />
                  ))}
                </>
              ) : null}
              {!submitted ? (
                <Button
                  onClick={onSubmitLearningCheck}
                  sx={{ width: 'fit-content', alignSelf: 'center' }}>
                  {t('common.submit')}
                </Button>
              ) : null}
              {submitError ? (
                <Alert severity='error' sx={{ marginTop: '10px' }}>
                  {errorMessage}
                </Alert>
              ) : null}
            </>
          ) : null}

          {/* Old quiz types, to be removed after data cleanup complete */}
          {stepData.type !== GOAL_STEP_QUIZ_TYPES.SURVEY &&
          stepData.type !== GOAL_STEP_QUIZ_TYPES.CHECK &&
          stepData.type !== GOAL_STEP_QUIZ_TYPES.ROLE_PLAY ? (
            <>
              <Typography variant='subtitle1' alignSelf='center'>
                {stepData.summary}
              </Typography>
              {stepData.type === 'true_false' ? (
                <>
                  <Typography variant='subtitle2'>
                    {t('stepQuiz.true')} {t('stepQuiz.or')}{' '}
                    {t('stepQuiz.false')}
                  </Typography>
                  {stepData.questions.map((q, i) => (
                    <QuestionOptionsTemplate
                      key={`question-${i}`}
                      question={q.question}
                      correctAnswer={q.answer.toString()}
                      correctMessage={q.answerSummary}
                      options={
                        <>
                          <RadioOption
                            value={'true'}
                            label={t('stepQuiz.true')}
                          />
                          <RadioOption
                            value={'false'}
                            label={t('stepQuiz.false')}
                          />
                        </>
                      }
                    />
                  ))}
                </>
              ) : null}
              {stepData.type === 'multiple_choice' ? (
                <>
                  <Typography variant='subtitle2'>
                    {t('stepQuiz.multipleChoice')}
                  </Typography>
                  {stepData.questions.map((q, i) => (
                    <QuestionOptionsTemplate
                      key={`question-${i}`}
                      question={q.question}
                      correctAnswer={q.correctAnswerPosition.toString()}
                      correctMessage={t('stepQuiz.correct')}
                      options={q.answers.map((a, j) => (
                        <RadioOption
                          value={j}
                          label={a}
                          key={`option-${i}-${j}`}
                        />
                      ))}
                    />
                  ))}
                </>
              ) : null}
              {stepData.type === 'matching' ? (
                <StepQuizMatching questionData={stepData.questions} />
              ) : null}
            </>
          ) : null}

          <ConfirmCancelDialog
            open={badgePopupOpen}
            maxWidth='sm'
            confirmText={t('stepQuiz.reviewMyResults')}
            onClose={() => setBadgePopupOpen(false)}>
            <InCardStack sx={{ width: 300 }}>
              <Badge size='200px' />
              <Typography color='primary' fontWeight='bold'>
                {t('stepQuiz.youScored')} {correctCheckCount}/{questions.length}
              </Typography>
              <Typography variant='subtitle1' textAlign='center'>
                {quizResultMessageDisplay?.displayMessage?.title}
              </Typography>
              <Typography textAlign='center'>
                {quizResultMessageDisplay?.displayMessage?.body}
              </Typography>
              <Typography textAlign='center'>
                {quizResultMessageDisplay?.displayMessage?.closing}
              </Typography>
            </InCardStack>
          </ConfirmCancelDialog>
        </Stack>
      )}
    </>
  )
}

export default StepQuiz
