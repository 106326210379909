import {
  CardContent,
  Icon,
  IconButton,
  MenuList,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import InputDialog from '../notificationOverlay/InputDialog'
import { getUserConnections } from 'services/userServices'
import UserConnectionMenuItem from 'components/atoms/input/UserConnectionMenuItem'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText } from 'components/atoms/Text'
import { FeedbackBlueIcon } from 'components/atoms/Icons'
import { useNavigate } from 'react-router-dom'
import { USER_FEEBACK } from 'routes/constants'
import { useTranslation } from 'react-i18next'

const MyTeamPopup = ({ open, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [recipients, setRecipients] = useState([])
  const [filterText, setFilterText] = useState('')
  const [connectionRequestSuccess, setConnectionRequestSuccess] =
    useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      const res = await getUserConnections(true)
      if (res.length) {
        setRecipients(res)
        setConnectionRequestSuccess(true)
      } else {
        setConnectionRequestSuccess(false)
      }
    }
    if (open && recipients.length === 0) {
      fetchData()
    }
  }, [open, recipients.length])

  const userFilter = user => {
    const searchString = `${user.firstName}  ${user.lastName} ${user.email}`
    return searchString.toLowerCase().indexOf(filterText.toLowerCase()) > -1
  }

  const handleFeedbackClick = selected => {
    onClose()
    navigate(`${USER_FEEBACK}?selectedUser=${selected.id}`, {
      state: { selected }
    })
  }
  return (
    <InputDialog
      open={open}
      handleCancel={onClose}
      confirmText={t('common.done')}
      titleText={t('headers.myOrganization')}
      cancelText={null}
      alignItems='left'>
      <Typography variant='subtitle2' paddingLeft='16px'>
        {t('organization.searchOrganizationMembers')}
      </Typography>
      <CardContent sx={{ paddingY: 0 }}>
        <TextField
          key='connection-filter'
          type='text'
          value={filterText}
          onChange={e => setFilterText(e.target.value)}
          sx={{ width: '100%' }}
          placeholder={t('organization.enterNameOrEmail')}
        />
      </CardContent>
      <MenuList sx={{ height: '400px', overflowY: 'auto' }}>
        {recipients.length > 0 ? (
          recipients
            .filter(user => userFilter(user.user))
            .map(r => (
              <UserConnectionMenuItem
                key={r.user?.id}
                itemProps={{ key: r.user?.id }}
                option={r.user}
                hoverPointer={false}
                shortChip={true}>
                <Tooltip title={t('welcomeArea.sendFeedback')}>
                  <IconButton
                    sx={{ marginLeft: '10px' }}
                    disableRipple
                    data-testid={`${r.user?.id}-send-feedback`}
                    onClick={() => handleFeedbackClick(r.user)}>
                    <Icon>
                      <FeedbackBlueIcon />
                    </Icon>
                  </IconButton>
                </Tooltip>
              </UserConnectionMenuItem>
            ))
        ) : connectionRequestSuccess === false ? (
          <RequestRetryAlert />
        ) : (
          <NotificationBodyText>{t('organization.noConnectionFound')}</NotificationBodyText>
        )}
      </MenuList>
    </InputDialog>
  )
}

export default MyTeamPopup
