import RolePlayResultList from 'components/organisms/rolePlay/RolePlayResultList'
import PracticePage from 'components/templates/navigationPageTemplates/PracticePage'
import RolePlayCarousel from 'components/molecules/rolePlay/RolePlayCarousel'
import { useEffect, useState } from 'react'
import RolePlayWelcome from 'components/molecules/rolePlay/RolePlayWelcome'
import HeaderWithCarousel, {
  CarouselWelcome
} from 'components/atoms/container/HeaderWithCarousel'
import SelectVirtue from 'components/molecules/rolePlay/SelectVirtue'
import { ActionText } from 'components/atoms/Text'
import { useTranslation } from 'react-i18next'
import { getRequest } from 'utils/api'
import { ALL_SCENARIOS } from 'routes/constants'
import { ROLE_PLAYS_URL } from 'services/constants'
import { Box } from '@mui/material'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useNavigate } from 'react-router-dom'

const Practice = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [rolePlays, setRolePlays] = useState([])
  const [selectedVirtueId, setSelectedVirtueId] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getRolePlays = async () => {
      const res = await getRequest(ROLE_PLAYS_URL)
      if (res.status === 200) {
        setRolePlays(res.data.data)
      }
      setLoading(false)
    }
    getRolePlays()
  }, [])

  return (
    <PracticePage>
      <OverlayLoading open={loading} />
      <Box height={8} />
      <HeaderWithCarousel
        welcomeElement={
          <CarouselWelcome>
            <RolePlayWelcome />
            <SelectVirtue
              virtueId={selectedVirtueId}
              setVirtueId={setSelectedVirtueId}
              rolePlays={rolePlays}
            />
            <ActionText variant='h5' onClick={() => navigate(ALL_SCENARIOS)}>
              {t('rolePlay.seeAllScenarios')}
            </ActionText>
          </CarouselWelcome>
        }
        carousel={
          <RolePlayCarousel
            rolePlays={rolePlays}
            selectedVirtueId={selectedVirtueId}
            setSelectedVirtueId={setSelectedVirtueId}
          />
        }
      />
      <RolePlayResultList virtueId={selectedVirtueId} />
    </PracticePage>
  )
}

export default Practice
