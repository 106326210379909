import * as yup from 'yup'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import {
  Button,
  CardContent,
  Alert,
  Grid,
  MenuItem,
  IconButton
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import FormInputBox from 'components/atoms/input/FormInputBox'
import { validationsRules } from 'utils/formValidations'
import FormSelectInput from 'components/atoms/input/FormSelectInput'
import { getLocalUser } from 'utils/localStorage'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import InCardStack from 'components/atoms/InCardStack'
import { ActionText } from 'components/atoms/Text'
import colors from 'styles/colors'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import { updateProfile } from 'services/userServices'
import { getConstant } from 'services/constantServices'
import { CONSTANTS_OPTIONS } from 'services/constants'
import OnboardingQuestiionnaire from './OnboardingQuestionnaire'
import OverlayLoading from 'components/atoms/OverlayLoading'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { useTranslation } from 'react-i18next'
import StyledButton from 'components/atoms/button/StyledButton'

const ProfileCard = ({
  submitButtonText,
  continueCallback,
  fromSignup = true,
  updatedStyle = false,
  showOptions = true
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const imageInputRef = useRef(null)
  const [requestSuccess, setRequestSuccess] = useState(undefined)
  const userInfo = getLocalUser()
  const [profileImage, setProfileImage] = useState(userInfo.profile_image)
  const [industryOptions, setIndustryOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [jobOptions, setJobOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [optionLoaded, setOptionLoaded] = useState(false)

  const showCompanyNameInput =
    userInfo.organization?.id && !userInfo.organization.name
  const enableSave =
    !showOptions ||
    (fromSignup && showOptions && userInfo.department && userInfo.job)

  useEffect(() => {
    if (requestSuccess && !fromSignup) {
      const refreshTimeout = setTimeout(() => {
        setRequestSuccess(undefined)
      }, 2000)
      return () => clearTimeout(refreshTimeout)
    }
  }, [requestSuccess, fromSignup])

  const formik = useFormik({
    initialValues: {
      companyName: userInfo.organization?.name || '',
      industry: userInfo.organization?.industry || '',
      firstName: userInfo.first_name || '',
      lastName: userInfo.last_name || '',
      department: userInfo.department || '',
      job: userInfo.job || '',
      profileImage: null,
      questionnaireAnswers: userInfo.onboardingAnswers || {}
    },
    validationSchema: yup.object({
      companyName: showCompanyNameInput
        ? validationsRules.textRequired
        : validationsRules.text,
      industry: showCompanyNameInput
        ? validationsRules.textRequired
        : validationsRules.text,
      firstName: fromSignup ? undefined : validationsRules.textRequired,
      lastName: fromSignup ? undefined : validationsRules.textRequired,
      department: showOptions
        ? validationsRules.textRequired
        : validationsRules.text,
      job: showOptions ? validationsRules.textRequired : validationsRules.text
    }),
    onSubmit: async values => {
      setLoading(true)
      const data = new FormData()
      if (values.profileImage) {
        data.append('profile_image', values.profileImage)
      }

      data.append('first_name', values.firstName)
      data.append('last_name', values.lastName)
      data.append('department', values.department)
      data.append('job', values.job)
      data.append(
        'onboardingAnswers',
        JSON.stringify(values.questionnaireAnswers)
      )
      if (showCompanyNameInput) {
        data.append('organization_name', values.companyName)
        data.append('industry', values.industry)
      }
      const res = await updateProfile(data)
      if (res.status === 200) {
        if (fromSignup) {
          continueCallback()
        } else {
          setTimeout(() => {
            setRequestSuccess(true)
            if (window.history.state.idx > 0) {
              navigate(-1)
            }
            setLoading(false)
          }, 800)
        }
      } else {
        setRequestSuccess(false)
        setLoading(false)
      }
    }
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      if (showCompanyNameInput) {
        const industry = await getConstant(CONSTANTS_OPTIONS.INDUSTRY_OPTIONS)
        setIndustryOptions(industry || [])
      }
      const job = await getConstant(CONSTANTS_OPTIONS.JOB_OPTIONS)
      const department = await getConstant(CONSTANTS_OPTIONS.DEPARTMENT_OPTIONS)
      setJobOptions(job || [])
      setDepartmentOptions(department || [])
      if (!job?.find(j => j.value === userInfo.job)) {
        formik.setFieldValue('job', '')
        formik.setFieldTouched({})
      }
      setOptionLoaded(true)
      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    if (formik.dirty) {
      formik.submitForm()
    } else if (fromSignup && enableSave) {
      continueCallback()
    }
  }
  const uploadImage = e => {
    const file = e.target.files[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setProfileImage(url)
      formik.setFieldValue('profileImage', file)
    }
  }
  const handleUploadClick = () => {
    imageInputRef.current && imageInputRef.current.click()
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <CardContent>
        <Grid container spacing={8} maxWidth='350px'>
          <input
            ref={imageInputRef}
            accept='image/*'
            hidden
            type='file'
            onChange={uploadImage}
          />
          <Grid item justifyContent='center' display='flex' width='100%'>
            {profileImage ? (
              <InCardStack spacing={0}>
                <ProfileAvatar
                  user={userInfo}
                  size='large'
                  newImage={profileImage}
                />
                <IconButton
                  color='primary'
                  title='edit'
                  onClick={() => handleUploadClick()}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </InCardStack>
            ) : (
              <InCardStack spacing={0}>
                <IconButton disableRipple onClick={() => handleUploadClick()}>
                  <BoxWithBackground
                    padding='20px'
                    backgroundColor={colors.White}
                    sx={{
                      border: '1px solid',
                      borderColor: colors.Solitude,
                      paddingBottom: '15px',
                      borderRadius: 12.5
                    }}>
                    <AddIcon
                      sx={{
                        color: colors.Aluminium,
                        width: '30px',
                        height: '30px'
                      }}
                    />
                  </BoxWithBackground>
                </IconButton>
                <ActionText onClick={() => handleUploadClick()}>
                  {t('profile.uploadPhoto')}
                </ActionText>
              </InCardStack>
            )}
          </Grid>
          {showCompanyNameInput ? (
            <>
              <Grid item>
                <FormInputBox
                  label={t('company.companyName')}
                  type='text'
                  placeholder={t('company.enterYourCompanyName')}
                  formik={formik}
                  attributeName='companyName'
                />
              </Grid>
              <Grid item>
                <FormSelectInput
                  required
                  name='industry'
                  label={t('company.industry')}
                  type='text'
                  formik={formik}
                  placeholder={t('company.yourIndustry')}
                  attributeName='industry'>
                  {industryOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </FormSelectInput>
              </Grid>
            </>
          ) : null}
          {!fromSignup ? (
            <>
              <Grid item>
                <FormInputBox
                  required
                  label={t('company.firstName')}
                  type='text'
                  placeholder={t('profile.enterYourFirstName')}
                  formik={formik}
                  attributeName='firstName'
                />
              </Grid>
              <Grid item>
                <FormInputBox
                  required
                  label={t('company.lastName')}
                  type='text'
                  placeholder={t('profile.enterYourLastName')}
                  formik={formik}
                  attributeName='lastName'
                />
              </Grid>
            </>
          ) : null}
          {optionLoaded && showOptions ? (
            <>
              <Grid item>
                <FormSelectInput
                  required
                  name='department'
                  label={t('profile.department')}
                  type='text'
                  formik={formik}
                  placeholder={t('profile.yourDepartment')}
                  updatedStyle={updatedStyle}
                  attributeName='department'>
                  {departmentOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </FormSelectInput>
              </Grid>
              <Grid item>
                <FormSelectInput
                  required
                  name='job'
                  label={t('profile.role')}
                  type='text'
                  placeholder={t('profile.yourRole')}
                  updatedStyle={updatedStyle}
                  formik={formik}
                  attributeName='job'>
                  {jobOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </FormSelectInput>
              </Grid>
            </>
          ) : null}
          {!fromSignup ? (
            <Grid item>
              <OnboardingQuestiionnaire
                updateAnswers={newAnswers =>
                  formik.setFieldValue('questionnaireAnswers', newAnswers)
                }
                userAnswers={userInfo.onboardingAnswers || {}}
                padding={0}
              />
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      {requestSuccess === false ? (
        <CardContent sx={{ padding: 0 }}>
          <RequestRetryAlert />
        </CardContent>
      ) : null}
      <CardContent>
        {updatedStyle ? (
          <StyledButton
            onClick={() => handleSubmit()}
            disabled={!(formik.dirty || enableSave)}>
            {submitButtonText}
          </StyledButton>
        ) : (
          <Button
            onClick={() => handleSubmit()}
            disabled={!(formik.dirty || enableSave)}>
            {submitButtonText}
          </Button>
        )}
      </CardContent>
      {requestSuccess ? (
        <CardContent sx={{ padding: 0 }}>
          <Alert
            severity='success'
            sx={{
              opacity: requestSuccess === true ? 1 : 0,
              transition: 'opacity 1s'
            }}>
            {t('profile.changesSaved')}
          </Alert>
        </CardContent>
      ) : null}
      <OverlayLoading open={loading} />
    </form>
  )
}
export default ProfileCard
