export const prepareRadarGraphData = data => {
  const responseData = {}
  const dataKeys = Object.keys(data)
  if (dataKeys.some(key => key === 'ref')) {
    responseData.ref = Object.assign({}, data.ref)
  }

  if (dataKeys.some(key => key === 'self')) {
    responseData.self = Object.assign({}, data.self)
    Object.keys(data.ref).forEach(key => {
      const setKey = responseData.self[key] ?? 0
      responseData.self[key] = setKey
    })
  }

  if (dataKeys.some(key => key === 'others')) {
    responseData.others = Object.assign({}, data.others)
    Object.keys(data.ref).forEach(key => {
      const setKey = responseData.others[key] ?? 0
      responseData.others[key] = setKey
    })
  }

  const characterData = [
    { ...responseData.self },
    { ...responseData.others },
    { ...responseData.ref }
  ]
  return characterData
}

/* Each row is a rating entry from one person, with each key an attribute */
export const getMaxima = data =>
  Object.keys(data[0]).reduce((memo, key) => {
    memo[key] = Math.max(...data.map(d => d[key]))
    return memo
  }, {})

  export const processData = data => {
    const maxByGroup = getMaxima(data)
    const makeDataArray = d => {
      return Object.keys(d).map(key => {
        const y = !isNaN(d[key] / maxByGroup[key])
          ? d[key] / maxByGroup[key]
          : 0
        return { x: key, y }
      })
    }
    return data.map(datum => makeDataArray(datum))
  }
