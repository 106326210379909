import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { HOME } from './constants'
import { isAllowedFeature } from 'utils/permissionCheck'

const FeatureRoute = ({ children, featureKey, redirectPath = HOME }) => {
  if (!isAllowedFeature(featureKey)) {
    return <Navigate to={redirectPath} />
  }

  return children ? children : <Outlet />
}

export default FeatureRoute
