import moment from 'moment'
import { getRequest, patchRequest } from 'utils/api'
import {
  USER_CONNECTION_URL,
  USER_CONNECTION_EVENTS_URL,
  CALENDAR_RECURRENCE_FREQ
} from 'services/constants'
import { USER_CALENDAR_EVENTS } from 'routes/constants'

export const getConnection = async userConnectionId => {
  const res = await getRequest(`${USER_CONNECTION_URL}/${userConnectionId}`)
  return res.status === 200 ? res.data.data : null
}

export const getCalendarEvent = async userCalendarEventId => {
  const res = await getRequest(`${USER_CALENDAR_EVENTS}/${userCalendarEventId}`)
  return res.status === 200 ? res.data.data : null
}

export const getConnectionEvent = async userConnectionEventId => {
  const res = await getRequest(
    `${USER_CONNECTION_EVENTS_URL}/${userConnectionEventId}`
  )
  return res.status === 200 ? res.data.data : null
}

export const getUpcomingEvents = async (userConnectionId, limit = 50) => {
  const res = await getRequest(USER_CONNECTION_EVENTS_URL, { userConnectionId })

  if (res.status === 200) {
    const connectionEvents = res.data.data
    let upcoming = []
    connectionEvents
      .filter(
        x =>
          !x.recurrence ||
          !x.recurrence.until ||
          moment(x.recurrence.until).add(1, 'month').isSameOrAfter() // buffer for calendar event updated
      )
      .forEach(x => {
        const start = moment(x.startTime)
        const cease = !x.recurrence
          ? moment(x.startTime)
          : x.recurrence?.until
            ? moment(x.recurrence?.until)
            : moment(x.startTime).add(1, 'years')
        let time = start.clone()
        const currentCaledarEvents = x.userCalendarEvents.filter(c =>
          moment.utc(c.startTime).isSameOrAfter()
        )
        const maxCalendarTime =
          currentCaledarEvents[currentCaledarEvents.length - 1]?.endTime
        const events = [...currentCaledarEvents]
        while (time.isSameOrBefore(cease) && events.length < limit) {
          const thisTime = time.clone()
          const startTime = thisTime.toISOString()

          const eventObj = {
            startTime,
            endTime: thisTime
              .clone()
              .add(x.durationMinutes, 'minutes')
              .toISOString(),
            userConnectionEventId: x.id
          }
          if (thisTime.isSameOrAfter() && thisTime.isAfter(maxCalendarTime)) {
            events.push(eventObj)
          }

          if (x.recurrence?.frequency === CALENDAR_RECURRENCE_FREQ.WEEKLY) {
            time.add('1', 'weeks')
          } else if (
            x.recurrence?.frequency === CALENDAR_RECURRENCE_FREQ.BI_WEEKLY
          ) {
            time.add('2', 'weeks')
          } else if (
            x.recurrence?.frequency === CALENDAR_RECURRENCE_FREQ.MONTHLY
          ) {
            time.add('1', 'months')
          } else {
            break // Not recurring
          }
        }
        upcoming = upcoming.concat(events)
      })

    return upcoming
      .sort((a, b) => moment(a.startTime).diff(moment(b.startTime)))
      .splice(0, limit)
  } else {
    return []
  }
}

export const patchUserCalendarEvent = async (userCalendarEventId, body) => {
  const res = await patchRequest(
    `${USER_CALENDAR_EVENTS}/${userCalendarEventId}`,
    body
  )

  return res.status === 200 ? res.data.data : null
}
