import React from 'react'
import { Grid, Typography } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import LikertScale from 'components/molecules/LikertScale'

const QuestionRow = ({ questionText, order, initialValue, onChange, options }) => {
  return (
    <Grid item container columnSpacing={28}>
      <Grid item xs={12} lg={6} sx={{ display: 'flex', alignItems: 'center' }}>
        <RowStack
          spacing={4}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <Typography
            sx={{ alignSelf: 'flex-start' }}
            variant='bodyLarge'
            fontWeight='bold'>
            {order}.
          </Typography>
          <Typography variant='bodyLarge'>{questionText}</Typography>
        </RowStack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <LikertScale
          id={order}
          onChange={onChange}
          initialValue={initialValue}
          options={options}
        />
      </Grid>
    </Grid>
  )
}

export default QuestionRow
