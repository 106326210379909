import OHEPSurveysExportCard from 'components/organisms/OHEPSurveysExportCard'
import InternalReportPage from 'components/templates/navigationPageTemplates/InternalReportPage'

const OHEPSurveys = () => {
  return (
    <InternalReportPage>
      <OHEPSurveysExportCard />
    </InternalReportPage>
  )
}

export default OHEPSurveys
