import { Box, useTheme } from '@mui/material'
const BoxWithBackground = ({
  children,
  backgroundColor,
  borderRadius = 5,
  sx,
  updatedStyle = false,
  ...otherProps
}) => {
  const theme = useTheme()
  return (
    <Box
      {...otherProps}
      borderRadius={borderRadius}
      sx={[
        {
          backgroundColor: backgroundColor || theme.palette.background.default
        },
        updatedStyle && {
          padding: 6,
          borderRadius: 4
        },
        sx
      ]}>
      {children}
    </Box>
  )
}

export default BoxWithBackground
