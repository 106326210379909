import AuthorizedPage from 'components/templates/AuthorizedPage'
import { PAGE_TITLES } from 'utils/constants'
import ContentHeaderTabs from 'components/organisms/ContentHeaderTabs'
import { coachTabs, managerTabs } from 'utils/headerTabs'
import { permitted } from 'utils/permissionCheck'
import { PERMISSION_LEVEL } from 'routes/constants'

const AdminPage = ({ children, activePage, ...otherProps }) => {
  const isManager = permitted(PERMISSION_LEVEL.ADMINISTRATOR)
  return (
    <AuthorizedPage
      pageTitle={PAGE_TITLES.ADMIN}
      whiteBackground={false}
      headerTabs={
        <ContentHeaderTabs
          activePage={activePage}
          tabs={isManager ? managerTabs : coachTabs}
        />
      }
      {...otherProps}>
      {children}
    </AuthorizedPage>
  )
}

export default AdminPage
