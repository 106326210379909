import { Box, Typography } from '@mui/material'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import { BloomingFlowerIcon } from 'components/atoms/Icons'
import { ReactComponent as MonarkLogoSvg } from 'assets/images/monark_logo.svg'
import PolicyLinks from 'components/molecules/PolicyLinks'

const SplashPage = ({ title, children, showPrivacyPolicy = true }) => {
  const halfPage = (isLeft, color) => (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: color,
        width: '50%',
        height: 'max(100%, 860px)',  // Prevents clipping from image overflow
        top: 0,
        zIndex: -10,
        ...(isLeft ? { left: 0 } : { right: 0 })
      }}
    />
  )

  return (
    <Box
      width='100%'
      height='100%'
      overflow='auto'
      display='flex'
      flexDirection='column'
    >
      {halfPage(true, colors.White)}
      {halfPage(false, colors.MidnightTeal400)}

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: -5
        }}>
        <BloomingFlowerIcon sx={{ width: 998, height: 974 }} />
      </Box>

      <Box sx={{ position: 'absolute', top: 32, left: 32, zIndex: -1 }}>
        <MonarkLogoSvg />
      </Box>

      <InCardStack
        spacing={20}
        justifyContent='center'
        minHeight='100vh'
        width='50%'
        sx={{ paddingTop: 50 }}
      >
          <Typography variant='h1' color={colors.MidnightTeal400}>
            {title}
          </Typography>
        <InCardStack spacing={8}>{children}</InCardStack>
        {showPrivacyPolicy && <PolicyLinks fontSize={14} gap={22} />}
      </InCardStack>
    </Box>
  )
}

export default SplashPage
