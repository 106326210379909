import AuthorizedPage from 'components/templates/AuthorizedPage'
import ContentHeaderTabs from 'components/organisms/ContentHeaderTabs'
import { PAGE_TITLES } from 'utils/constants'
import { Box } from '@mui/material'

const AboutPage = ({ children, ...otherProps }) => {
  return (
    <AuthorizedPage
      pageTitle={PAGE_TITLES.ABOUT}
      {...otherProps}
      headerTabs={<ContentHeaderTabs activePage='about' />}>
      <Box width='100%'>{children}</Box>
    </AuthorizedPage>
  )
}

export default AboutPage
