import {
  CALENDAR_OAUTH_TOKEN_PROVIDERS,
  CALENDER_OAUTH_TOKEN_URL
} from 'services/constants'
import { Button, Icon } from '@mui/material'
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'
import { googleClientId } from 'config/config'
import { postRequest } from 'utils/api'
import ImageContainer from 'components/atoms/container/ImageContainer'
import googleCalendar from 'assets/images/google-calendar.svg'
import { useTranslation } from 'react-i18next'
import { trackEvent } from 'services/amplitudeTracking'

const LoginButton = ({ signinCallback }) => {
  const { t } = useTranslation()

  const handleClick = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async response => {
      const requestBody = {
        provider: CALENDAR_OAUTH_TOKEN_PROVIDERS.GOOGLE,
        code: response.code
      }
      const res = await postRequest(CALENDER_OAUTH_TOKEN_URL, requestBody)
      signinCallback(res.status === 201)
      trackEvent('Connect Calendar', { type: 'Google' })
    },
    scope: 'https://www.googleapis.com/auth/calendar'
  })
  return (
    <Button
      onClick={() => handleClick()}
      variant='outlined'
      sx={{ fontWeight: 'bold' }}
      startIcon={
        <Icon>
          <ImageContainer
            src={googleCalendar}
            alt='calendar'
          />
        </Icon>
      }>
      {t('calenderEvents.connectGoogle')}
    </Button>
  )
}
const GoogleCalendarLogin = ({ signinCallback }) => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <LoginButton signinCallback={signinCallback} />
    </GoogleOAuthProvider>
  )
}

export default GoogleCalendarLogin
