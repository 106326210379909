import { COMPANY_GENERAL, COMPANY_USERS } from 'routes/constants'
import AdminPage from './navigationPageTemplates/AdminPage'
import { PageHeader } from 'components/atoms/Text'
import { Box, Divider, Tab, Tabs } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const COMPANY_TABS = {
  GENERAL: {
    value: 0,
    label: 'general',
    url: COMPANY_GENERAL
  },
  USERS: {
    value: 1,
    label: 'users',
    url: COMPANY_USERS
  }
}

const CompanySettingsTemplate = ({ children, activeTab }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleTabChange = (event, newValue) => {
    const dest = Object.entries(COMPANY_TABS).find(
      ([, t]) => t.value === newValue
    )
    navigate(dest[1].url)
  }
  return (
    <AdminPage activePage={COMPANY_GENERAL}>
      <PageHeader paddingY='12px'>{t('company.companySettings')}</PageHeader>
      <Box>
        <Tabs
          value={activeTab.value}
          textColor='inherit'
          onChange={handleTabChange}>
          {Object.entries(COMPANY_TABS).map(([, tab]) => (
            <Tab value={tab.value} label={t(`company.${tab.label}`)} key={tab.label} />
          ))}
        </Tabs>
        <Divider />
      </Box>
      {children}
    </AdminPage>
  )
}

export default CompanySettingsTemplate
