export const translationEn = {
  english: 'English',
  french: 'Français',
  constants: {
    userConnRelationship: {
      direct_report: 'Direct Report',
      direct_supervisor: 'Direct Supervisor',
      senior_leader: 'Senior Leader',
      colleague: 'Colleague',
      other: 'Other'
    },
    userRoleTypes: {
      admin: 'Admin',
      restricted: 'Restricted',
      manager: 'Manager',
      coach: 'Coach'
    },
    likertScaleSimple: ['Very Bad', 'Bad', 'Neutral', 'Good', 'Very Good']
  },
  headers: {
    home: 'Home',
    journey: 'My Journey',
    feedback: 'Feedback',
    about: 'About',
    overview: 'Overview',
    company: 'Company',
    bookmarks: 'Bookmarks',
    notebook: 'Notebook',
    adminDashboard: 'Admin Dashboard',
    internalDashboard: 'Internal Dashboard',
    monarkHome: 'Monark Home',
    verifyEmail: 'Verify Email',
    profile: 'Profile',
    myTeam: 'My Team',
    myOrganization: 'My Organization',
    settings: 'Settings',
    languages: 'Languages',
    helpSupport: 'Help & Support',
    logout: 'Log Out',

    // sub headers
    currentPath: 'Current Path',
    leadershipModel: 'Leadership Model',
    assessments: 'Assessments',
    pulseSurvey: `Instant Surveys`,
    userFeedback: 'User Feedback',
    about360: 'Learn About 360 Results',
    aboutConfidentiality: 'Confidentiality',
    ohepSurveys: 'OHEP Surveys',
    learningCheckQuizzesResults: 'Learning Check Quizzes Results',
    userProgressReport: 'User Progress Report',
    practice: 'Practice',

    // Internal pages, not translation needed
    contentEditor: 'Content Editor',
    subscriptions: 'Subscriptions',
    ohep: 'OHEP Surveys',
    demoGoals: 'Demo Goals',
    paths: 'Paths',
    reportExport: 'Report & Export',
    features: 'Features',
    demoAccounts: 'Demo Accounts'
  },
  common: {
    send: 'Send',
    to: 'To',
    back: 'Back',
    seeAll: 'See All',
    learnMore: 'Learn More',
    noThanks: 'No Thanks',
    sendMessage: 'Send Message',
    save: 'Save',
    cancel: 'Cancel',
    finish: 'Finish',
    done: 'Done',
    notFound: 'Not Found',
    ok: 'Ok',
    saved: 'Saved',
    submit: 'SUBMIT',
    continue: 'Continue',
    skip: 'Skip',
    date: 'Date',
    from: 'From',
    more: 'more',
    notes: 'Notes',
    add: 'Add',
    complete: 'complete',
    completed: 'Completed',
    view: 'View',
    loadMore: 'Load More',
    history: 'History',
    your: 'Your',
    you: 'you',
    or: 'or',
    editing: 'Editing',
    delete: 'Delete'
  },
  welcomeArea: {
    welcome: 'Welcome',
    heading: 'What do you want to do today?',
    startNewPath: 'Start a New Path',
    continueLearning: 'Continue Learning',
    viewCompletedPath: 'View Completed Path',
    startLearning: 'Start Learning',
    sendFeedback: 'Send Feedback',
    raterOnlyAccount:
      'You have a rater-only account, which means you can only rate other users who invite you to answer their 360 rating requests and',
    learnMoreAboutMonark:
      "If you'd like to learn more about Monark's complete content and features, including how you can send out feedback requests to your peers, contact us.",
    raterOnlySubject: "I am a Rater Only user and I'd like to know more"
  },
  home: {
    newLearningPathAssignment: 'New Learning Path Assignment',
    startMyPath: 'Start My Path',
    companyAssignedYouNewLearningPath:
      'Your company has assigned you to a new learning path, ',
    automaticallyTakenToSelfAssessment:
      'You will automatically be taken to the Self Assessment to begin your new path. Any progress you have made on your current path will be saved, and you may return at any time.',
    clickStartToBegin: 'Click Start My Path to begin.'
  },
  goalsArea: {
    goalsToComplete: 'Goals To Complete',
    dueDate: 'Due Date',
    notSet: 'Not Set',
    gotoMyGoals: 'Go to My Goals',
    currentGoalRatings: 'Current Goal Ratings',
    learnMoreAbout360Results: 'Learn more about 360 Results',
    overallScore: 'OVERALL SCORE',
    self: 'Self',
    others: 'Others',
    clickBehaviours: 'Click behaviors above to see more scoring analysis.',
    selfRating: 'Self Rating',
    othersRating: 'Others Rating'
  },
  goalStepPaths: {
    encouragementPopupTitle: 'Learning Opportunity',
    encouragementPopupButton: 'Continue to {{x}}',
    selfAssessmentScores: 'Self-assessed scores',
    howOthersHaveRatedYou: 'How others have rated you',
  },
  calenderEvents: {
    calendar: 'Calendar',
    connectedCalendar: 'Connected Calendar',
    upcomingReminders: 'Upcoming Reminders',
    unableToConnect: {
      part1: 'We were unable to connect your calendar. Please go to the ',
      part2:
        ' page and try again. If you continue to have issues, please contact your IT Administrator to enable access for Monark to connect to your calendar.'
    },
    connectionExpired:
      'Your calendar connection has expired. Please reconnect to your calendar provider.',
    noUpcomingReminders:
      'There are no upcoming reminders to display because your calendar is not currently linked to Monark. By linking your calendar, you will see upcoming leadership events highlighted here.',
    linkMyCalendar: 'Link My Calendar',
    linkYourCalendar: 'Link Your Calendar',
    instantlyConnectCalendar:
      'Did you know you can instantly connect your calendar to Monark so you can view and manage upcoming leadership events without leaving the app?',
    giveItTry: 'Give it a Try',
    connectMicrosoft: 'Connect with Microsoft',
    connectGoogle: 'Connect with Google Calendar',
    editNotes: 'Edit notes',
    addNotes: 'Add notes',
    eventNotes: 'Event Notes',
    yourNotePlaceholder: 'Your note for this event',
    unableToConnectCalendarTryAgain:
      'We were unable to connect your calendar. Please try again.  If you continue to have issues, please contact your IT Administrator to enable access for Monark to connect to your calendar.',
    linkingYourCalendar:
      'Linking your calendar lets you view important leadership-related events right on your home screen.',
    IiDisconnectCalendar:
      'If you disconnect your calendar, you will no longer see upcoming leadership-related events displayed in the app.',
    disconnectCalendar: 'Disconnect Calendar',
    requestDone: 'Request Done',
    youDisconnectedCalendar: 'You have disconnected your calendar.'
  },
  requestRetryAlert: {
    message: 'Request failed! Please try again.'
  },
  feedbackArea: {
    openFeedbackRequests: 'Open Feedback Requests',
    _360Requests: '360 Requests',
    recentFeedback: 'Recent Feedback',
    noFeedbackFromTeam:
      'You currently have no comment feedback from your teammates.',
    noFeedbackFromColleagues:
      "You don't have any feedback from your colleagues yet. Ask them to let you know how you are doing!",
    gotoFeedback: 'Go to Feedback',
    reply: 'Reply',
    yourReply: 'Your Reply',
    feedbackError: 'Failed to submit feedback. Please try again later.',
    feedbackSuccess: 'Feedback Successfully Sent!',
    selectRecipient: 'Select a recipient',
    noMatchUserFound: 'No matched user found',
    failedLoadUserConnections: 'Failed to load user connections',
    loadingConnections: 'Loading connections',
    yourMessage: 'Your Message'
  },
  teamShoutOut: {
    heading: 'Team Shout-Outs',
    commentValidation: 'Comment must be less than 1024 characters.',
    postedBy: 'Posted by',
    shoutOutToTeam:
      'Give a shout out to your teammates for great work! Posts made here will be visible to your entire team.',
    noTeamShouts: 'No team shout-outs have been posted yet.',
    post: 'Post',
    postToWall: 'Post to Wall',
    comments: 'Comments'
  },
  shareMyResults: {
    heading: 'Share My Results',
    recpMinValidation: 'Please select or add at least one recipients.',
    recpEmailValidation: 'Pleae enter a valid email address',
    resultsSentSuccess: 'Your Results Have Been Sent Successfully!',
    selectRecipients: 'Select Recipients',
    recpPlaceholder: 'Select a connection or input email',
    sendNow: 'Send Now'
  },
  raters: {
    addMore360Raters: 'Add more 360 raters'
  },
  requestErrorPageCard: {
    title: 'Oops, something went wrong!',
    text: 'Sorry, we cannot retrieve requested information at the moment. Please try again later!'
  },
  assessmentBehaviour: {
    avgRating: 'Avg. Rating',
    questions: 'Questions'
  },
  profile: {
    editProfile: 'Edit Profile',
    finishProfile: 'Finish Profile',
    role: 'Role',
    industry: 'Industry',
    department: 'Department',
    currentLevelOfSeniority: 'CURRENT LEVEL OF SENIORITY',
    workExperience: 'WORK EXPERIENCE',
    pathKeepTrack: `Keep track of how much you've done, and look back at what you've learned.`,
    noPathYet: `You haven't completed any learning paths yet. Check out our leadership models and pick a learning path to get started.`,
    startNewPath: 'Start a new learning path',
    uploadPhoto: 'Upload photo',
    enterYourFirstName: 'Enter Your First Name',
    enterYourLastName: 'Enter Your Last Name',
    yourDepartment: 'Your Department...',
    yourRole: 'Your Role...',
    changesSaved: 'Changes saved',
    changePassword: 'Change Password',
    requestSubmitted: 'Request submitted successfully!',
    checkYourEmail:
      'Please check your email inbox and follow the instructions to update your password.',
    deleteMyAccount: 'Delete my account',
    areYouSure: 'Are you sure?',
    sorryToSeeYouGo:
      'We’re sorry to see you go, come back anytime and pick up where you left off.',
    keepMyAccount: 'No, keep my account open',
    closeMyAccount: 'Yes, close my account',
    accountClosed: 'Account Closed'
  },
  pathsAndModels: {
    currentPath: 'Current Path',
    path: 'Path',
    milestone: 'Milestone',
    learningPaths: 'Learning Paths',
    viewPath: 'View Path',
    beginPath: 'Begin Path',
    locked: 'Locked',
    unlock: 'Unlock',
    assessments: 'Assessments',
    surveys: 'Surveys',
    pathInProgress: 'Learning Path In Progress',
    startNewPathNotificationText: {
      part1: 'You are currently working on the ',
      part2:
        ' learning path, and have not yet completed it. If you switch to a new path, your progress will be saved, and you can pick up where you left off when you return. Do you want to start a new path now?'
    },
    oneRangePurchaseText: {
      title: 'OneRange Purchase',
      part1: "Your OneRange license doesn't include this learning path. ",
      part2: 'Visit OneRange to purchase access to this learning path.',
      visit: 'Visit OneRange'
    },
    confirmStartPath: 'Yes, Start A New Path',
    doNotHaveResource: 'It looks like we do not have resource for this goal.',
    notAbleLoadResource:
      'Sorry, we were not able to load the resource. Please try again later',
    congratulations: 'Congratulations',
    youJustCompleted: 'You just completed',
    senseOfNewSkills: {
      part1:
        'To get a sense of how these new skills are helping you as a leader in the coming weeks and months, we recommend re-doing a self-assessment ',
      part2: ' days ',
      part3: 'after completing this learning path.'
    },
    makeItEasy:
      'To make it easy, we have scheduled a reminder for you. You will receive a notification to take the assessment on this date.',
    reassessmentReminder: 'A re-assessment reminder will be sent on ',
    completedDemoPath: 'You completed the demo path!',
    alreadyCompleted: 'You have already completed',
    reflectOnWhatYouLearned:
      'As you reflect on what you learned and continue practicing your new skills, now is a great time to get feedback on your progress by sending out a quick, one-question survey to your peers.',
    sendOutSurvey: 'Send out survey now',
    requestMeeting: 'Request a Meeting',
    clickBookmarkContent: 'Click to bookmark content',
    clickRemoveBookmark: 'Click to remove bookmark',
    getInstantFeedback: 'Get some instant feedback!',
    sendPulseSurvey: `Send Instant Survey`,
    partWayThroughLearning: {
      part1:
        'Now that you’re part way through your learning path, it’s a great time to send an ',
      part2: `Instant Survey `,
      part3: 'to get some feedback on your progress!'
    },
    useThisQuestion:
      'Use this question as is, or modify it to make it your own. Then, select your recipients, hit Send, and watch the responses come in!',
    scheduleTimeLearn: 'Schedule Time to Learn',
    customize: 'Customize',
    spendingTimeLearning:
      'Spending a little time learning and practicing behaviors each day makes you more likely to retain information and reach your goals.',
    useTheSettings:
      'Use the settings to set customized learning reminders. You can change these settings at any time.',
    coveredLotContent: "You've covered a lot of content!",
    movingThroughContentQickly:
      "We noticed that you are moving through the content very quickly. While it's great to see your enthusiasm, to get the most out of Monark, we recommend taking time to review and apply the concepts you have learned before proceeding to the next topic.",
    startYourLearningJourney: 'Start Your Learning Journey',
    chooseAnItem:
      'Choose an item to see your progress and other available learning paths.'
  },
  assessmentTimeline: {
    takeNewSelfassessment: 'Take a new self-assessment',
    inviteOthersAssess: 'Invite others to assess you',
    historicalRatings: 'Historical Ratings',
    closingOn: 'Closing on',
    closedOn: 'Closed on',
    assessingYourChange: 'Assessing Your Change',
    overallAverageRating: 'Overall Average Rating',
    completeMoreAssessments:
      'Complete more assessments to build comparison data',
    change: 'Change',
    assessmentChange: '{{type}} Assessment Change',
    behaviours: 'Behaviors',
    rating: 'Rating',
    noComparisonData: 'no comparison data available',
    clickVariousBehaviours:
      'Click on the various behaviors to see a detailed breakdown of your change in assessment scores',
    noAssessmentData:
      'You have no assessment data yet, do some assessments to get some data about yourself!',
    alreadyCompletedSelfassessment:
      'You have already completed a self-assessment for this learning path within the last 2 weeks. Please wait at least 2 weeks before doing another self-assessment.',
    notCompletedSelfassessment:
      'You have not completed a self-assessment for this learning path in the last 2 weeks. In order to invite others to assess you, please do a self-assessment first and the invite others to assess you.',
    doSelfAssessmentNow: 'Do Self-Assessment Now',
    notRightNow: 'Not Right Now',
    addMore360Raters:
      'You can add more 360 raters to your open assessment now.',
    addRaters: 'Add Raters',
    returnHome: 'Return Home',
    completingSelfAssessment: {
      part1: 'Great Job On Completing',
      part2: 'Your Self-Assessment!'
    },
    selfAssessmentReport: 'Self-Assessment Report',
    currentlySelfAssessment: 'You are currently completing a Self Assessment',
    howYouScored:
      'Check out how you scored on each of the behaviors measured in your self-assessment below (just a reminder, you rated yourself on a scale of 1 to 5, where 1 = strongly disagree, and 5 = strongly agree).',
    nextStep: 'Next Step',
    capturingBaseline: 'Capturing Your Baseline',
    captureBaselineMeasurement:
      "We're going to capture a baseline measurement of where you're at today on",
    fewMinutesToAnswer:
      "Take just a few minutes to answer some questions about how you typically behave. Don't worry, no one but you will see these ratings. Your organization can only see the progress you make once on your learning journey!",
    beginSelfAssessment: 'Begin Self-Assessment',
    selfAssessment: 'Self-Assessment',
    assessmentOnly: {
      part1: 'This assessment is only',
      part2: 'questions.'
    },
    submitError: 'Something went wrong, please try again.'
  },
  leadership: {
    monarkLeadershipModel: 'Monark Leadership Model',
    chooseAnItem:
      'Choose an item to see your progress and other available learning paths.'
  },
  addRaters: {
    inviteOthersToRate: 'Invite others to rate you',
    details: {
      item1: {
        title: 'Why should I send 360 requests?',
        description:
          'Gain balanced insight into your leadership strengths and potential blind spots.'
      },
      item2: {
        title: 'Who should I send the requests to?',
        description:
          'Choose colleagues, supervisors, direct reports, or others familiar with your work behavior.'
      },
      item3: {
        title: 'How can I add more raters?',
        description:
          'Simply type their email address and relationship; they don’t need to be app users and they can be from outside your organization.'
      },
      item4: {
        title: 'When will I see the results?',
        description:
          'To protect anonymity, you will only see results once you receive ratings from at least three people.'
      }
    },
    tellMeMore: 'Tell me more about how to complete 360 assessments',
    minimum3ConnValidation:
      'Please select or provide a minimum of 3 valid connections.',
    validConnError: 'Please select or provide valid connections.',
    required: 'Required',
    relationshipTypeValidation:
      'Please select a relationship type for each rater.',
    emailAlreadyInInvite: 'This email is already added to your invite list',
    emailAlreadyInRaters: 'This email is already in your raters list',
    new: 'New',
    connection: 'Connection',
    nameOrWorkEmail: 'Name Or Work Email',
    select: 'Select',
    emailsSent: 'Emails Sent',
    emailSuccessfullySent:
      'Your emails were successfully sent. If at any time, you’d like to go back and add more raters, visit your profile page.',
    notWaitForResults:
      'Remember: you don’t need to wait for your results to get started! You can begin your journey now and you will be notified as your 360 results become available.',
    startYourJourney: 'Start Your Journey',
    ratersNotSelected:
      'You haven’t yet selected any 360 raters so you have no data to display yet.',
    invitedRaters: {
      part1: 'You have invited',
      part2: 'raters for this assessment.'
    },
    relationship: 'Relationship',
    addMoreRaters: 'Add more raters',
    skipForNow: 'Skip For Now',
    getMostFromResults: 'Get the Most from Your Results',
    getStarted: 'Get Started',
    nextStepToGetOthersRate:
      'The next step is to get others to rate you as part of a 360-degree assessment. This will give you a better understanding of how you “show up” in your role at work.',
    importantToNote:
      'It’s important to note that a 360-degree assessment is designed to measure your strengths and weaknesses, not your performance.',
    rateAndCompare:
      'When you rate yourself and compare these ratings to those provided by others, you gain better self-awareness, which is a key ingredient to behavior change!'
  },
  stepNote: {
    activitiesAndReflection: 'Activities and Reflection',
    recordResponse: 'Record a response',
    setGoal: 'Set A Goal',
    setReminder: 'Set Reminder',
    addDateToCalendar: 'Add due date to my calendar',
    greatJobCompletedGoal: 'GREAT JOB! You completed this goal!',
    IDidIt: 'I DID IT!',
    takeNotes: 'Take Notes',
    takeSomeNotes: 'Take some notes',
    sources: 'Sources',
    errorRetrievingContent: 'Error retrieving content, please retry later'
  },
  stepQuiz: {
    answerAllQuestionsValidation:
      'Please answer all the questions before submitting',
    workValuesSurvey: 'Work Values Survey',
    result: 'Result',
    score: 'Score',
    youScored: 'You scored ',
    on: 'on',
    correct: 'Correct!',
    incorrectAnswer: 'Incorrect. The correct answer is',
    true: 'True',
    or: 'Or',
    false: 'False',
    multipleChoice: 'Multiple Choice',
    pleaseTryAgain: 'Please try again!',
    matchedCorrectly: {
      part1: 'Congrats! You have matched the items correctly.',
      part2: 'Please review all of the answers below.'
    },
    correctAnswers: 'Correct Answers',
    reviewMyResults: 'Review My Results'
  },
  stepFeedback: {
    checkIn: 'Check In',
    EnjoyedLearningAbout: 'I enjoyed learning about',
    thankYouForFeedback: 'Thank you for your feedback!',
    completeMilestone: 'Complete Milestone',
    beforeYouMoveOn: 'Before You Move On...',
    takeMomentToRate:
      'Would you like to take a moment to rate this milestone? Your feedback will help us improve the user experience. ',
    rateNow: 'Rate Now',
    stronglyDisagree: 'Strongly Disagree',
    neutral: 'Neutral',
    stronglyAgree: 'Strongly Agree',
    dontAskMeAgain: "Don't ask me again"
  },
  about360: {
    heading: 'Interpreting your 360 results',
    why360: 'Why a 360?',
    why360Text: {
      part1:
        '360-degree feedback assessments are often used in pricey leadership development programs - but what is the point of them? Well, a 360-degree assessment gives you a comprehensive understanding of how others experience your behavior in a number of key areas. By incorporating multiple perspectives (hence 360), you get a more accurate depiction of how you show up at work.',
      part2:
        'Not to mention, when you rate yourself and then compare your self-ratings with those provided by others, you gain better self-awareness. Self-awareness (particularly external self-awareness) - i.e., the extent to which you understand how others see you - is the starting point of behavior change.'
    },
    interpretResults: 'Interpreting Your Results',
    interpretResultsText: {
      para: "This assessment serves as the baseline for your leadership development journey. It's designed to give you an idea of your starting point on a number of key areas relevant to your goal. There are a few things you should keep in mind as you go through your results:",
      itmes: {
        item1: {
          part1: 'This assessment focuses on behaviors that can be ',
          part2: 'learned ',
          part3: 'and ',
          part4: 'developed.'
        },
        item2: {
          part1: 'Your results ',
          part2: "don't ",
          part3:
            'eflect who you are as a person; they merely describe your engagement in certain behaviors up until this point.'
        },
        item3: {
          part1: 'You should not see this as a ',
          part2: 'performance assessment',
          part3: ', it is intended solely to be used as a development tool.'
        }
      }
    },
    whatYouSee: "What You'll See in Your 360 Results",
    whatYouSeeItems: {
      item1: {
        part1: 'Self-rating: ',
        part2:
          'This is the rating you gave yourself when completing the self-assessment.'
      },
      item2: {
        part1: 'Others’ rating: ',
        part2:
          'This is an average taken of the ratings that were provided for you by everyone else who was invited to rate you.'
      }
    },
    understandingBlindspots: 'Understanding Blindspots and Hidden Strengths',
    understandingBlindspotsItems: {
      item1: {
        part1: 'Blindspots ',
        part2:
          'are areas where you’ve rated yourself higher relative to how others have rated you, and therefore your beliefs about your performance in a particular area exceed those of the people around you. This sometimes results in a disconnect between your intentions and your behaviors as experienced by others.'
      },
      item2: {
        part1: 'Hidden strengths ',
        part2:
          'are areas where you have rated yourself lower on a given behavior relative to the ratings provided by others, resulting in an underestimation of your own performance. These are potential skills you may not have fully realized you possess. They’re already within you, just waiting to be further enhanced!'
      }
    },
    disclaimer: 'If you have completed a 360-degree assessment, meaning you assessed yourself and invited others to rate you as well, the following information is pertinent to you.'
  },
  confidentiality: {
    heading: 'Confidentiality',
    basic: {
      part1:
        'We take a number of steps to maintain the anonymity and confidentiality of those who provide ratings and feedback on Monark.',
      part2:
        'For the 360 assessment, we do not disclose the names of those who provide ratings. We also only report an average score across raters for each behavior/question, and only do so once at least three raters have provided ratings.',
      part3: `This is to protect the anonymity of the individual giving the ratings, and to ensure the most honest and accurate feedback for the individual receiving the ratings. This same process is applied to the instant surveys.`,
      part4:
        "However, please note that for the qualitative feedback option (open-ended comments sent and received by users), all comments provided are attached to a user's name. A simple way to differentiate between these different methods is that quantitative data will always be provided as an average and anonymously, whereas qualitative feedback is not anonymous.",
    },
      gotIt: 'Got it',
    fullAssessment: {
      part1: 'At Monark, we prioritize the confidentiality of all users who provide ratings and feedback.',
      part2: 'Here’s how we ensure your privacy:',
      part3: 'Individual Privacy: We never share your individual results without your explicit consent. You have the control to share your results through the Monark platform, but we do not share them on your behalf under any circumstances.',
      part4: 'Aggregate Reporting: Any reports or analyses we conduct are done at the aggregate level only. This means we only report overall averages and trends derived from groups of three or more people, ensuring that individual responses cannot be identified.',
      part5: 'Your trust is paramount to us, and we are committed to maintaining the highest standards of privacy and confidentiality.'
    }
  },
  pulseSurvey: {
    sendPulseSurvey: `Send out a instant survey`,
    surveyRequest: `Instant Survey Request`,
    backtoPulseSurvey: `Back to Instant Surveys`,
    yourResponseRecorded: 'Your Response Has Been Recorded',
    pleaseSelectQuestion: 'Please select or type in a question.',
    pleaseSelectMinRecipients: 'Please select a minimum of 3 recipients.',
    recipientsWillAnswer: {
      part1:
        'Recipients will answer your survey question on a scale of 1 (Strongly Disagree) to 5 (Strongly Agree).',
      part2:
        'Your survey will remain open for one week, or until all of your raters have responded.'
    },
    pulseSurveyQuestion: `Instant Survey Question`,
    fetchingQuestions: 'Fetching questions',
    notAbleToRetrieve:
      'Sorry, we are not able to retrieve the questions at the moment. Please try again later.',
    question: 'Question',
    selectQuestion: 'Select a question',
    questionPlaceholder: 'Enter a question or select from a list',
    recipients: 'Recipients',
    selectTime: 'Select a time to send',
    now: 'Now',
    after1Day: 'After 1 Day',
    after3Days: 'After 3 Days',
    after1Week: 'After 1 Week',
    createFailed: `Failed to create the instant survey. Please try again later.`,
    createSuccess: `Instant Survey Successfully Sent!`,
    allowAtLeast3Response:
      'Please allow for at least 3 responses to come in before you can start to see your results.',
    incomingPulseSurvey: `Incoming Instant Survey Requests`,
    receivedOn: 'Received on',
    openUntil: 'Open until ',
    noOutstandingRequests: `You have no outstanding instant survey requests from your team.`,
    rejectPulseSurvey: `Reject Instant Survey Request`,
    removeRequest: `This will remove the request from your list and you will not be able to recover the instant survey later.`,
    myPulseSurveys: `My Instant Surveys`,
    sendOutPS: `Send out a Instant Survey`,
    status: 'Status',
    sentSendingOn: 'Sent/Sending On',
    closed: 'Closed',
    open: 'Open',
    closedInsufficientRaters: 'Closed - Insufficient Raters',
    averageRating: 'Average Rating',
    raters: 'Raters',
    sendSingleQuestion: `You can send out single-question instant surveys to your teammates to get rapid feedback.`,
    allRecipients: 'All Recipients',
    viewResults: 'View Results',
    psDetails: `Instant Survey Details`,
    issuedOn: 'Issued On',
    teamMembersSentTo: 'Team Members Sent To',
    responsesReceived: 'responses received',
    makingGreatProgress:
      "Congratulations, you're making great progress towards becoming a master at recognition! This is an essential skill for any leader, as it helps to inspire motivation and create a psychologically safe workplace for your employees.",
    whyStopAtGood:
      "But why stop at good when you can be great? To take your recognition skills to the next level, refresh yourself on these research-backed best practices for giving recognition in the workplace. With these tips, you'll be able to create an even more positive and uplifting work environment for everyone on your team! ",
    learnAboutRecognition: 'Learn more about Recognition',
    noRating: 'No Rating',
    atLeast3People:
      'At least 3 people must respond to the survey for you to see your results. Try and send the survey again to receive more responses.',
    goBack: 'Go Back'
  },
  userConnectionSelect: {
    recipientName: 'Recipient Name'
  },
  assessments: {
    incomingRatingRequests: 'Incoming 360 Rating Requests',
    invitedToProvideRating: {
      part1:
        'You’ve been invited to provide a 360 rating for the users below. ',
      part2:
        'It will take only a couple of minutes to complete, and your feedback provides valuable insight to help them on their leadership journey.'
    },
    noOutstandingRating:
      'You currently have no outstanding 360 rating requests.',
    rejectRating: 'Reject 360 Rating Request',
    willRemoveRequest:
      'This will remove the request from your list and you will not be able to recover the rating request later.',
    keepIt: 'Keep It',
    reject: 'Reject',
    assessmentLanding:
      "Our mission is to provide the insights you need to excel in leadership. Through a comprehensive self-assessment, you'll gain clarity on key leadership behaviors, reflect on your strengths, and identify growth opportunities. These insights will help you develop a strategic plan to enhance your skills and achieve long-term success.",
    landingQuestionTitle: '{{count}} Questions',
    landingQuestion:
      'For planning purposes, the assessment should take you around 20-30 minutes to complete. Trust your instincts and aim to answer each question within 30 seconds.',
    landingWhenYouCanTitle: 'Your Progress Will Save',
    landingWhenYouCan:
      'Your progress will be saved automatically as you complete the assessment. If you are interrupted, you can easily return and finish at your convenience.',
    landingConfidentiality:
      'We will never share your individual results without your permission.',
    landingMoreConfidentiality: 'More on confidentiality',
    saveNExit: 'Save & Exit',
    reflectionQuestion:
      'Would you like include any additional reflections about your leadership?',
    reflectionPlaceholder: 'Add additional reflections...',
    submitAssessment: 'Submit Assessment',
    assessmentComplete: 'Assessment complete!',
    greatJobFullAssessment:
      'Great job on completing your self-assessment! You can access your results and share the full report from your homepage.'
  },
  onboarding: {
    companyHasActiveTrial: 'Your Company Has An Active Trial With Monark',
    companyTrialExpired: "Your Company's Trial Has Expired",
    companyAlreadyHasLicense: 'Your Company Already Has A License With Monark',
    companyLicenseExpired: "Your Company's License Has Expired",
    companyCompletingTrial:
      'Your company is currently completing a trial with Monark. You could be added to the existing trial, and will have full access to Monark’s content and features.',
    trialExpireOn: 'The trial will expire on ',
    companyPreviouslyHadLicense:
      'Your company previously had a license with Monark, but it has now expired. You may still use Monark as a Rater to complete 360 assessment and feedback requests from your peers.',
    contactToLearn:
      "Contact us to learn more about how to access Monark's complete content and features.",
    createProfile: 'Create Profile',
    selectPath: 'Select Your Path',
    selfAssessment: 'Self Assessment',
    completeSelfAssessment: 'Complete Self Assessment',
    inviteOthersToRate: 'Invite Others to Rate You',
    continueRater: 'Continue as Rater',
    requestSent: 'Request Sent',
    messageSent:
      'A message has been sent to Monark. Someone will follow up with you shortly.',
    embarkInteractiveJourney:
      'You are about to embark on an interactive leadership journey that puts you in control of when and where you learn.',
    heresWhatExpect: 'Here’s what you can expect…',
    fullAssesmentWelcome:
      "Our mission is to provide the insights you need to excel in leadership. Through a comprehensive self-assessment, you'll gain clarity on key leadership behaviors, reflect on your strengths, and identify growth opportunities.",
    getYourBaseline: 'Get Your Baseline',
    assessmentOfCurrentBehaviors:
      'Complete an assessment of your current behaviors and invite others to rate you so you know exactly where you’re starting.',
    learnAtYourPace: 'Learn At Your Pace',
    easilyAccessContent:
      'Easily access content when and how it fits into your schedule with 5 minute micro-lessons.',
    setGoalsPractice: 'Set Goals and Practice',
    trackYourProgress:
      'Keep track of your progress and set goals and reminders for yourself to practice new skills.',
    getFeedbackImprove: 'Get Feedback and Improve',
    useSurveysFeedbackTools:
      'Use surveys and feedback tools to get real-time insight from your peers about how you’re improving.',
    createYourProfile: 'Create Your Profile',
    thanksForSigningUp: 'Thanks for signing up!',
    helpUsOptimize:
      'To help us optimize your path to success, please answer a few questions about yourself and your experience.',
    aFewQuestion:
      "Answer just a few quick questions about yourself, and you'll be on your way!",
    getStartedOnLeadershipJourney:
      'Time to get started on your leadership journey!',
    hasBeenPreSelected: {
      part1: 'The',
      part2:
        'learning path has been pre-selected for you. Next, you will get started by completing a Self Assessment.'
    },
    seeDetailsLearningPaths:
      'Next, you will see details for all available learning paths within the Monark Leadership Model. Select the one you want to learn about to get started.'
  },
  assessmentForOthers: {
    notFoundAssessmentError:
      'Sorry, we cannot find the assessment at the moment. Please try again later.',
    youCompletedAssessmentError:
      'It appears you have already completed this assessment request. Please return to your request list to check for any other outstanding requests.',
    previouslyRejectedThisRequestError:
      'It appears you have previously rejected this request, so you will no longer be able to complete it. Please return to your request list to check for any other outstanding requests.',
    assessmentExpiredError:
      'The assessment you are trying to access has expired, and is no longer available. Please return to your request list to check for any other outstanding requests.',
    assessmentNotAvailable: 'Assessment Not Available',
    backToAssessments: 'Back to Assessments',
    thankYouForRatings: 'Thank you for your ratings!',
    informationProvidedGreatlyHelp: {
      part1: 'The information you provided will greatly help ',
      part2: ' on their learning path!'
    },
    _360RatingFor: '360 Rating for ',
    askYouSomeQuestionsAbout: {
      part1: 'We’re going to ask you to answer some questions about ',
      part2: ', in order to help them with their learning path. ',
      part3: 'Take just a few minutes to answer some questions about how ',
      part4: ' typically behaves at work. '
    },
    userReceiveAverageRating: {
      part1:
        'will only receive an average rating calculated across all raters, ',
      part2:
        'and will only have access to their ratings if they receive at least three separate responses, ',
      part3: 'to maintain rater confidentiality.'
    },
    begin360Assessment: 'Begin 360 Assessment',
    youAreAssessing: 'You are currently assessing '
  },
  overview: {
    filters: 'FILTERS',
    cohort: 'Cohort',
    resetFilters: 'Reset Filters',
    users: 'Users',
    insights: 'Insights',
    active: 'ACTIVE',
    invited: 'INVITED',
    atRisk: 'AT RISK',
    detailList: {
      pulseSurveySent: `Instant Survey Sent`,
      pulseSurveyCompleted: `Instant Survey Completed`,
      qualitativeFeedback: 'Qualitative Feedback',
      communityPostsLeadershipShouts: 'Community Posts & Leadership Shouts',
      completedSelfAssessments: 'Completed Self Assessments',
      completed360Assessments: 'Completed 360 Assessments',
      _360Sent: '360 Sent',
      milestonesCompleted: 'Milestones completed',
      learningJourneysCompleted: 'Learning Journeys Completed'
    },
    atRiskUsers: 'At Risk Users',
    denotesAtRiskUsers: 'Denotes At Risk Users',
    usersNotLogged30days: 'Users who have not logged in for 30+ days',
    activeUsers: 'Active Users',
    usersWhoStartedLearningPath:
      'Users who have started a learning path by completing a self assessment',
    raterOnlyUsers: 'Rater-Only Users',
    raterOnlyUsersCanRateOthers: `Rater-only users can rate others on instant surveys & 360 assessments, but cannot view Monark content or do self-assessments. These users can be upgraded with a license`,
    searchForUser: 'Search for user..',
    export: 'Export',
    user: 'USER',
    completedPaths: 'COMPLETED PATHS',
    currentPath: 'CURRENT PATH',
    currentStep: 'CURRENT STEP',
    percentCompleted: '% COMPLETED',
    _360Responses: '360 RESPONSES',
    lastSeen: 'LAST SEEN',
    leaderHealthScore: 'LEADER HEALTH SCORE',
    licensedBy: 'LICENSED BY',
    invitedUsers: 'Invited Users',
    usersWhoBeenInvitedButNotLoggedIn:
      'Users who have been invited to use the app, but have not yet logged in',
    email: 'Email',
    learningPath: 'Learning Path',
    firstInvitedAt: 'First Invited At',
    noUserInvited: 'No user invited'
  },
  insights: {
    superLeaderScores: 'Super Leader Scores',
    development: 'Development',
    commitment: 'Commitment',
    growth: 'Growth',
    teamStength: 'Team Strength',
    showDescriptions: 'Show Descriptions',
    hideDescriptions: 'Hide Descriptions',
    focusesOnLeadersProactiveEfforts:
      "Focuses on the leader's proactive efforts to improve their skills and knowledge. It's based on the principle that effective leadership is a continuous learning process. In this context, this is largely evidenced by their commitment to and engagement in the Monark learning and development process.",
    assessesLeadersDedication:
      "Assesses the leader's dedication to their organization, gauging their level of engagement, loyalty, and willingness to contribute to the organization's success. As well as their willingness to recommend or speak positively about the organization to others.",
    measuresLeadersDevelopment:
      "Measures the leader's personal and professional development since engaging with the Monark application, emphasizing actual progress and advancement.",
    evaluatesLeadersTeam:
      "Evaluates the health and performance of the team led by the individual. It's predicated on the belief that one key aspect of leadership is the ability to create and maintain a high-performing and satisfied team.",
    includedMetrics: 'Included Metrics',
    directReports: '(Direct Reports Only)',
    learningPathCompletion: 'Learning Path Completion',
    learningCheckScores: 'Learning Check Scores',
    facilitatedSessionAttendance: 'Facilitated Session Attendance',
    feedbackSeeking: 'Feedback Seeking (360s sent)',
    intentionToStay: 'Intention to Stay',
    engagement: 'Engagement',
    workSatisfaction: 'Work Satisfaction',
    burnout: 'Burnout',
    changesInAssessmentScores:
      'Changes in 360 self and others assessment scores',
    appUsage: 'App Usage',
    last: 'Last',
    avgOnlineTime: 'Avg. Online Time',
    mins: 'mins',
    avgDaysLoggedIn: 'Avg. days logged in',
    monthlyUsage: 'Monthly Usage',
    topUsers: 'Top Users',
    topActivities: 'Top Activities',
    learning: 'Learning',
    _360sSent: '360s Sent',
    goalsAndActivities: 'Goals and Activities',
    whyNoScore: 'Why is there no score?',
    orderToShowGrowthScore:
      'In order to show a growth score, members of your organization must complete both an initial 360 assessment as well as a followup 360 assessment to show the change in scores.',
    commitmentDetails: 'Commitment Details',
    teamStrengthDetails: 'Team Strength Details',
    developmentScoreDetails: 'Development Score Details',
    survey: 'Survey',
    growthScoreDetails: 'Growth Score Details',
    seeDetailsOfGrowth:
      'See the details of growth in behavior development across the organization.',
    assessingTeamChange: 'Assessing Team Change',
    combined: 'Combined',
    completionPace: 'Completion Pace',
    facilitatedOn: 'Facilitated On',
    selectCohortAndLearningPath:
      'Please select a cohort and learning path to view the progress map',
    noGrowDetail: 'No growth details available for selected path.',
    user: 'User',
    completedStep: 'Completed Step',
    percentageCompleted: 'Percentage Completed',
    noData: 'No Data'
  },
  superLeaderUserDrillDown: {
    complete: 'Complete',
    _360sResponse: '360s Responses',
    minutesOnPath: 'Minutes On Path'
  },
  company: {
    companySettings: 'Company Settings',
    general: 'General',
    users: 'Users',
    companyInformation: 'Company Information',
    companyName: 'Company Name',
    enterYourCompanyName: 'Enter Your Company Name',
    industry: 'Industry',
    yourIndustry: 'Your Industry...',
    scheduleAutomatic360RemindersAfter:
      'Schedule automatic 360 reminders after',
    whatDoesThisMean: 'What does this mean?',
    membersOrganizationToSee:
      'In order for the members of your organization to see the progress they have made, Monark strongly encourages follow-up 360 assessments after a period of time to assess change.',
    recommend60DaysDefault:
      'We recommend 60 days by default, however you may choose to have your user reassess sooner, or after a longer wait.',
    _30Days: '30 Days',
    _60Days: '60 Days',
    _90Days: '90 Days',
    _120Days: '120 Days',
    saveChanges: 'Save Changes',
    updatedSucessfully: 'Updated sucessfully',
    cohorts: 'Cohorts',
    name: 'NAME',
    members: 'MEMBERS',
    startDate: 'START DATE',
    endDate: 'END DATE',
    path: 'PATH',
    autoAssihnPath: 'AUTO ASSIGN PATH',
    _360Reassessment: '360 REASSESSMENT',
    postGoalEmail: 'POST GOAL EMAIL',
    preFacilitationEmail: 'PRE-FACILITATION EMAIL',
    allMembers: 'All Members',
    on: 'On',
    off: 'Off',
    days: 'Days',
    addCohort: 'Add Cohort',
    addSessionToCohort: 'Add Session to Cohort',
    editCohortSession: 'Edit Cohort Session',
    addSession: 'Add Session',
    editSession: 'Edit Session',
    cohortName: 'Cohort Name',
    enterNameOfCohort: 'Enter The Name Of The Cohort',
    firstLearningPathDetails: 'First Learning Path Details',
    selectLearningPath: 'Select Learning Path',
    facilitator: 'Facilitator',
    selectFacilitator: 'Select Facilitator',
    selectPathLoadPresetPathTimelines:
      'Please select a path to load preset path timelines.',
    _360ReassessmentTiming: '360 Reassessment Timing',
    selectTiming: 'Select Timing',
    disable: 'Disable',
    autoAssignPathOnSessionStart: 'Auto-Assign Path On Session Start',
    postGoalActivityEmail: 'Post Goal Activity Email',
    preFacilitationReminderEmails: 'Pre-Facilitation Reminder Emails',
    licensedUsers: 'Licensed Users',
    inviteMoreUsersMonark: 'Invite more users to Monark.',
    licensesInUse: 'licenses in use',
    licensesHaveExpired:
      'Your licenses have expired. All users now only have Rater access.',
    wantToReactivateSubscription: 'Want to reactivate your subscription? ',
    needMoreLicenses: 'Need more licenses? ',
    contactUs: 'Contact us: ',
    getMoreMonarkLicenses: 'Get more Monark licenses',
    youDoNotHaveActiveSubscription:
      'You do not have an active subscription at the moment.',
    userDetails: 'User Details',
    userRole: 'User Role',
    enterValidEmailValidation: 'Please enter valid email to send invitations',
    inviteLicensedUsers: 'Invite Licensed Users',
    inviteAdministrativeUsers: 'Invite Administrative Users',
    licensesRemaining: 'Licenses Remaining',
    administrativeUsersAccessAdminDashboard:
      'Administrative Users have access to the Admin Dashboard, and Rater Only access to the app. If you want to invite these users to have full access to the app, you must add them using the Invite Licensed Users button above.',
    optional: 'Optional',
    licensedGoals: 'Licensed Paths',
    toBeUsedOneRange: 'To be used for OneRange subscriptions',
    licensedGoalsDescription:
      'For OneRange license use only. Licensed paths are paths that are available to the inviting users with a license. Leave empty if all paths are available to the user.',
    ifYouIncludeLearningPath:
      'If you include a Learning Path, the user will automatically be assigned to this path, instead of selecting one on their own.',
    byDefaultAssignedTheRoleOfMember:
      'By default, any user with a license will be assigned the role of Member. You may also add Administrator access for the user to view the Admin Dashboard.',
    cohortGroupOfUsersWhoParticipateInApp:
      'A cohort is a group of users who will participate in the app together and be assigned to the same learning path.',
    selectAcohort: 'Select a cohort',
    emailSubject: 'Email Subject',
    customizedSubject: 'Customized Subject',
    defaultSujectIs: `Default suject is "Monark - You are Invited to Sign up for Monark App". Do not file in if not intended to change`,
    enterCustomizedEmailSubject: 'Enter customized email subject',
    emailBody: 'Email Body',
    customizedEmail: 'Customized Email',
    defaultEmailBody: `Default email body has been set based on goals and roles being invited. Do not use before confirming with Product/Dev`,
    default: 'Default',
    customized: 'Customized',
    messageAccompanyInvitation: 'This message will accompany the invitation',
    inviteSuccessfullySent: 'Invite Successfully Sent!',
    emailAddressAlreadyAdded: 'Email address already added',
    firstName: 'First name',
    lastName: 'Last name',
    addRecipient: 'Add recipient',
    outOfOrganizationUser: 'Out of organization user',
    member: 'Member',
    administrator: 'Administrator',
    systemAdministrator: 'System Administrator',
    changeSaved: 'Change saved!',
    failedToSave: 'Failed to save change. Please retry later.',
    downgradeUserRaterOnly: 'Downgrade user to rater-only',
    downgradeUser: 'Downgrade User',
    yesDowngradeUser: 'Yes, downgrade this user',
    accountTypeToRaterOnly:
      'This will move the user’s account type to rater-only, meaning they will not be able to send feedback requests or consume the Monark learning content. It will free up 1 additional license for your subscription.',
    deactivateUser: 'Deactivate user',
    yesDeactivateUser: 'Yes, deactivate this user',
    willDisableUsersAccount:
      'This will disable the user’s account and prevent them from logging in. ',
    free1AdditionalLicense:
      ' It will free up 1 additional license for your subscription.',
    actionCannotUndone: 'This action cannot be undone, are you sure?',
    doNotHaveLicensedUsers:
      'You do not have licensed users yet. Add users to your subscription from list below!',
    unlicensedUsers: 'Unlicensed Users',
    usersHaveAdministrator:
      'Users who have Administrator and/or Rater Only access.',
    adminDoNotRequireUserLicense:
      'Administrative users do not require a User License',
    noLicenseAvailable: 'No license available',
    upgradeToFullAccess: 'Upgrade user to full access',
    upgradeUser: 'Upgrade User',
    yesUpgradeUser: 'Yes, upgrade this user',
    willUse1License:
      'This will use up 1 additional license and provide this user with full access to the Monark platform.',
    allUsersAccessToMonark: 'All Users Have Full Access to Monark.',
    pendingInvites: 'Pending Invites',
    listOfUsersYouInvited:
      'A list of users who you have invited to join Monark.',
    invitedOn: 'Invited On',
    license: 'License',
    noPendingInvites: 'No Pending Invites.',
    resend: 'Resend',
    resendInvitation: 'Resend Invitation',
    revoke: 'Revoke',
    revokeUserInvite: 'Revoke User Invite',
    yesRevokeInvite: 'Yes, revoke this invite',
    actionRevokeUserInvitation:
      "This action will revoke the user's invitation. If this invitation is for a licensed user, it will free up 1 additional license for your subscription. Do you want to continue?",
    deactivatedUsers: 'Deactivated Users',
    usersHaveBeenDeactivated:
      'Users who were previously active with your organization but have now been deactivated. You may reactivate them at any time.',
    deactivatedOn: 'Deactivated On',
    noDeactivatedUsers: 'No Deactivated Users.'
  },
  chatBot: {
    chat: 'Chat',
    askMeAnythingLeadership: 'Ask me anything leadership',
    notSureWhatToAsk: 'Not sure what to ask? Here are some suggestions',
    howToProvideFeedback: 'How do I provide feedback',
    whatIs360: 'What is 360?',
    howWasYourExperience: 'How was your experience with “Ask Me Anything?"',
    feedbackAboutExperience: 'Give some feedback about your experience'
  },
  rolePlay: {
    okay: 'Okay',
    dictCancelled: 'Dictation Cancelled',
    dictCannotExceed: 'Dictation cannot exceed 1 minute.',
    practiceSettings: 'Practice Settings',
    scenario: 'Scenario',
    textChat: 'Chat',
    aiChat: 'AI Chat',
    call: 'Call',
    liveCall: 'Live Call',
    liveAICall: 'Live AI Call',
    new: 'NEW',
    mins: 'mins',
    challengeType: 'Choose your challenge type',
    startPractice: 'Start Practice',
    hi: 'Hi',
    welcomeToPractice: 'Welcome to AI Practice',
    chooseAScenario:
      'Choose a scenario and select your challenge type to begin the role-play.',
    seeAllScenarios: 'See All Scenarios',
    chooseASkill: 'Choose a skill',
    filterBySkill: 'Filter by skill',
    yourPreviousPractice: 'Your Previous Practice Sessions',
    scenarioSettings: 'Scenario Settings',
    notesPlaceholder:
      'Here you can draft a few talking points to facilitate your conversation.',
    holdTight: 'Hold tight, we are gathering your results!',
    cancelThisChat: 'Cancel this chat?',
    thisSessionNotSaved: 'This session will not be saved',
    dontCancel: "Don't Cancel",
    cancelChat: 'Leave Chat',
    cancelCall: 'Cancel Call',
    finishSession: 'Are you sure you want to finish this session?',
    sendMessageToContinue: 'Send a message to continue the conversation.',
    endChatGetFeedback: 'End Chat, Get Feedback',
    viewYourResults: 'View Your Results',
    powerByAI: '{{botName}} is not real. {{botName}} is powered by AI.',
    startChat: 'Start Chat',
    startLiveCall: 'Start Live Call',
    previousPracticeSessions: 'Your Previous Practice Sessions',
    results: 'Results',
    transcript: 'Transcript',
    yourNotes: 'Your Notes',
    overallSummary: 'Overall Summary',
    whatYouDidWell: 'What you did well',
    whatYouCouldImproveOn: 'What you could improve on',
    morePracticeScenarios: 'More Practice Scenarios',
    length: 'Length',
    tryAgain: 'Try again',
    wouldYouTryExerciseAgain:
      'Would you like to try this exercise again and compare your results?',
    yesTryAgain: 'Yes, try again',
    resume: 'Resume',
    endCall: 'End Call',
    sureYouWantCancelCall: 'Are you sure you want to cancel this call?',
    sessionWillNotBeSavedAndNoFeedback:
      'The session will not be saved and you will not receive any feedback.',
    sureYouWantToEndCall: 'Are you sure you want to end this call?',
    callHasNotMetRequirements:
      'This call has not met the time requirements to be saved or provide you with feedback.',
    sureYouEndCallReceiveFeedback:
      'Are you sure you want to end this call and receive feedback?',
    pleaseEnableTheMic: 'Please enable the microphone to start the role play'
  },
  searchArea: {
    search: 'Search',
    noPreviousSearch: 'No previous search',
    contentSearch: 'Content Search',
    searchResults: 'Search Results',
    searchWithNoResults:
      'Your search did not return any results, Please revise your query and try again',
    selectSearchResult:
      'Select a search result to see the detailed content here'
  },
  bookmarks: {
    myBookmarks: 'My Bookmarks',
    noCurrentlyContentBookmarked:
      'You do not currently have any content bookmarked.',
    addContentToList:
      'To add content to your list, visit your learning path and click the Bookmark icon in the header of any step.',
    goToCurrentPath: 'Go to my Current Path',
    selectBookmark: 'Select a bookmark to see the detailed content here.'
  },
  notebook: {
    myNotebook: 'My Notebook',
    goals: 'GOALS',
    notesActivities: 'NOTES & ACTIVITIES',
    doNotCurrentlyHaveAnyGoals:
      'You do not currently have any goals saved. Visit the learning path for suggested goals associated to each milestone',
    doNotCurrentlyHaveAnyNotes:
      'You do not currently have any notes saved. Visit the learning path to add notes to any milestone',
    visitLearningPath: 'Visit Learning Path',
    notWorkedOnLearningPaths:
      'You have not worked on any learning paths yet, get started on your learning journey!',
    startMyLearningJourney: 'Start My Learning Journey',
    createdDate: 'CREATED DATE: ',
    dueDate: 'DUE DATE: ',
    completedDate: 'COMPLETED DATE: '
  },
  organization: {
    searchOrganizationMembers: 'Search Organization Members',
    enterNameOrEmail: 'Enter Name Or Email Address',
    noConnectionFound: 'No connection found'
  },
  myTeam: {
    searchConnection: 'Search for a connection',
    myManagersSeniorLeaders: 'My Managers & Senior Leaders',
    addConnection: 'Add Connection',
    addNewConnection: 'Add New Connection',
    noMatchedUserFound: 'No matched user found',
    noRelatedUsers: 'No related users',
    selectUserOrInputRmail: 'Select a user or input email',
    selectRelationship: 'Select Relationship',
    failedToCreateContact: 'Failed to create contact. Please try again later.',
    connectionAddedSuccessfully: 'Connection Added Successfully',
    otherConnections: 'Other Connections',
    lastUpdate: 'Last Update',
    me: 'Me',
    showMore: 'Show More',
    nobodyHereYet: 'Nobody here yet.',
    myColleaguesMe: 'My Colleagues & Me',
    myReports: 'My Reports',
    deleteConnection: 'Delete Connection',
    areYouSureDeleteConnection:
      'Are you sure you want to remove this connection?',
    bookOneOnOne: 'Book 1:1',
    bookOneOnOneWith: 'Book 1:1 with {{name}}',
    numeratorOfDenomiator: '{{numerator}} of {{denomiator}}',
    directReport: 'Direct Report',
    directSupervisor: 'Direct Supervisor',
    seniorLeader: 'Senior Leader',
    colleague: 'Colleague',
    takeANote: 'Take a Note',
    nameSharedNotes: '{{name}} shared notes',
    nameSharedNotesWillAppear: '{{name}} shared notes will appear here',
    namePage: '{{name}} Page',
    noUpcomingMeetings: 'There are no upcoming meetings',
    noPastMeetings: 'There are no past meetings',
    noNotes: 'There are no notes',
    privateNotes: 'Private Notes',
    notesHereWillBeVisible:
      'Notes here will be visible to both {{name1}} and {{name2}}',
    applyWhatYouveLearned:
      "Apply what you've learned in your learning path in this one-on-one.",
    anythingYouTypeHere:
      'Anything you type here will only be visible to you...',
    pastOneOnOne: 'Past 1:1',
    oneOnOneWithName: '1:1 with {{name}}',
    thisMeetingIsOver: 'This meeting is over.',
    thisMeetingHasFinished: 'This meeting has finished'
  },
  checkInSurvey: {
    landingPageCards: [
      {
        title: 'Highlight Challenges',
        content:
          'Identify and discuss critical challenges. This is your space to bring up issues that need attention, ensuring nothing important slips through the cracks.'
      },
      {
        title: 'Spark Deeper Conversations',
        content:
          'Go beyond the routine with deeper discussions about career aspirations and personal growth. Engage in meaningful dialogue that enriches your professional relationship.'
      },
      {
        title: 'Setup for Success',
        content:
          'Outline actionable steps and set clear objectives. Together, let’s build a roadmap for ongoing development and success in your role.'
      }
    ],
    answersSubmitted: 'Answers Submitted',
    doYouWantToPrepareSomeNotes:
      'Do you want to take some notes in preparation for your upcoming 1:1?',
    viewMeeting: 'View Meeting',
    oneOneOneCheckinSurvey: '1:1 Check-in Survey',
    welcomeToYourPersonalizedOneOnOne:
      "Welcome to your personalized 1:1 check-in. Here, every conversation is an opportunity to foster growth and address key issues proactively. Let's get started and make your one-on-one meetings more effective than ever.",
    beginCheckin: 'Begin Check-in',
    checkInSurvey: 'Check-in Survey',
    nameHowHaveYouFelt:
      '{{name}}, how have you felt about the following areas since your last check-in?',
    submitAnswers: 'Submit Answers',
    surveyResult: 'Check-In Scores',
    viewAnswers: 'View Answers',
    noSurveyData: 'There is no survey data available',
    currentLabel: 'Current: {{score}}',
    previousLabel: 'Past ({{count}}): {{score}}', 
    historicalLabel: 'Historical: {{score}}',
    lastNSurveysLabel: '(Last {{count}} surveys)',
    scoreRange: 'Scores range from 1 (very bad) to 5 (very good). Get insights into how current perceptions compare to past trends.',
    thisWeekNotComplete: 'This week’s survey has not been completed.',
    nextSurveyOpens: 'Your next check-in survey opens at',
    surveyDueIn: 'Check-in survey is due in',
    completeSurveyNow: 'Complete Survey Now'
  },
  contact: {
    contactUpdates: 'Contact Updates',
    addNote: 'Add Note',
    doNotHaveNotes:
      'You do not have any notes yet. Click the Add Note button to add notes for future reference.',
    editNote: 'Edit Note',
    noteIsPrivate: 'This note is private and only visible to you.',
    manualNotesEntry: 'Manual Notes Entry',
    deleteNote: 'Delete Note',
    yesDeleteIt: 'Yes, delete it.',
    aboutToDeleteNotes: {
      part1: 'Are you sure you want to delete this note?',
      part2: 'Once deleted, you will not be able to recover it.'
    },
    doNotHaveUpcomingMeetings:
      'You do not have any upcoming meetings scheduled with',
    bookMeetingWillList:
      'Once you book a meeting, you will see it listed here.',
    upcomingMeetings: 'Upcoming Meetings',
    pastMeetings: 'Past Meetings',
    noteTitle: 'Note Title',
    bookOneOnOne: 'Book a One-on-One',
    participants: 'Participants',
    title: 'Title',
    ONE_TO_ONE: 'One-on-One',
    PERFORMANCE_REVIEW: 'Performance Review',
    CHECK_IN: 'Check In',
    additionalTypesComingSoon: 'Additional meeting types coming soon',
    date: 'Date',
    start: 'Start',
    end: 'End',
    WEEKLY: 'Weekly',
    BI_WEEKLY: 'Bi-weekly',
    MONTHLY: 'Monthly',
    DO_NOT_REPEAT: 'Do not repeat',
    meetingLocation: 'Meeting location (optional)',
    addVideoConferencing: 'Add video conferencing?',
    googleMeet: 'Google Meet',
    whyNotLinkCalendar: 'Why not link your calendar?',
    canOnlySetup1O1: 'You can only book 1:1 meetings with your direct reports.',
    eventSuccess: 'Event Successfully Created!',
    eventError: 'Failed to create event. Please try again later.'
  },
  setings: {
    learningReminders: 'Learning Reminders',
    repeat: 'Repeat',
    time: 'Time',
    failedToSave: 'Failed to save. Please try again later.',
    notifications: 'Notifications',
    type: 'Type',
    inApp: 'In-App',
    disableAll: 'Disable All',
    all: 'All',
    unsubscribe: 'Unsubscribe',
    preferenceSaved: 'Preference Saved',
    aboutToUnsubscribe: 'You are about to unsubscribe from emails from Monark.',
    unsubscribeFrom: 'Unsubscribe from',
    submitError: 'Something went wrong, please try again.',
    confirm: 'Confirm'
  },
  authPages: {
    logIn: 'Log In',
    login: 'Log in',
    workEmail: 'Work email',
    password: 'Password',
    invalidLoginCredentials: 'Invalid login credentials',
    forgotPassword: 'Forgot password?',
    forgotYourPassword: 'Forgot your password?',
    rememberMe: 'Remember me',
    loginWith: 'Log in with',
    dontHaveAccount: "Don't have an account? ",
    contactUs: 'Contact Us',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    leastOneAlphabetCharacter: 'At least one alphabet character',
    leastOneDigit: 'At least one digit',
    leastOneSpecialCharacter: 'At least one special character',
    least8Characters: 'At least 8 characters',
    signUp: 'Sign Up',
    signUpWith: 'Sign up with',
    createAccount: 'Create account',
    acceptInvite: 'Accept Invite',
    passwordMustHave: 'Password must have',
    confirmPassword: 'Confirm Password',
    orSignUpWith: 'Or Sign Up With:',
    bySigningYouAgree: 'By signing up, you agree to the ',
    alreadyHaveAccount: 'Already have an account? ',
    here: 'here',
    and: 'and',
    verifyEmailAddress: 'Verify Email Address',
    mustVerifyEmailAddress:
      'You must verify your email address before you can begin the learning journey.',
    signedUpForMonark: 'You signed up for Monark using the address',
    verificationEmailWillBeSent:
      'Your verification email will be sent to this address.',
    verificationEmailSent: 'Verification Email Sent!',
    checkYourInbox:
      'Please check your inbox and follow the instruction to verify your email address',
    sendVerificationEmail: 'Send Verification Email',
    sendPasswordReset: 'Send Password Reset',
    requestSubmittedSuccessfully:
      'Request submitted successfully! Please check your inbox and follow the instructions to reset password!',
    cannotFindAccount: 'We cannot find your account. Please try again.',
    sendResetLink: 'Send Reset Link',
    backToLogin: 'Back to Login',
    resetPassword: 'Reset Password',
    reestPasswordSucessfully:
      'Reest password sucessfully! You can login with the new password now.',
    newPassword: 'new password',
    somethingWentWrong: 'Something went wrong.',
    reset: 'Reset',
    invitationEmailMissmatchTitle: 'Invitation Email Does Not Match',
    invitationEmailMissmatch: {
      part1: 'The invitation you received was for ',
      part2: ', you must use this email address to sign up.'
    },
    appNotAvailable: 'App Not Available on Mobile Browser',
    installTheApp: 'Please install the app or open it on your mobile device.',
    goToStore: 'Go to Store',
    monarkWorksBestInLandscape:
      'Monark works best in Landscape mode, please rotate your device for the best experience'
  },
  validationErrors: {
    enterYourEmail: 'Enter your email',
    enterValidEmail: 'Enter a valid email',
    emailRequired: 'Email is required',
    enterYourPassword: 'Enter your password',
    passwordRequired: 'Password is required',
    passwordLeast8Characters: 'Password must be at least 8 characters long',
    passwordMustContains:
      'Password must contains at least One Character, One Number, and One Special Character',
    confirmYourPassword: 'Confirm your password',
    passwordsMustMatch: 'Passwords must match',
    required: 'Required',
    pleaseSelectDate: 'Please select date',
    pleaseSelectEndDate: 'Please select end date',
    endDateAfterStartDate: 'End Date must be after Start Date',
    pleaseSelectReassessmentTiming: 'Please select reassessment timing',
    pleaseSelectPath: 'Please select path',
    pastDue: 'Past due!',
    invalidTimeRange: 'Invalid time range.'
  },
  support: {
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    haveQuestion:
      'Have a question, concern or feedback you want to share with us?',
    supportRequest: 'Support Request',
    howCanWeHelpYou: 'How can we help you?',
    yourRequestReceived: 'Your Request Has Been Received',
    FAQ_LIST: [
      {
        id: 0,
        question: `Who sees my 360-degree assessment results, my instant survey results or the qualitative feedback that I receive?`,
        answer: `All the ratings and feedback you receive are private to you! When another user provides you with either quantitative or qualitative feedback, that information is only available for you to see.`
      },

      {
        id: 1,
        question: 'What happens to the information I provide for others?',
        answer: `When you provide a numerical rating (i.e., in the 360-degree assessment or a instant survey), your score becomes part of an overall average for that user, therefore your rating is kept anonymous. Quantitative scores are only shared with a user after they have received at least three or more ratings. However, any qualitative feedback you provide for other users (i.e., open-ended comments) will be associated with your user name and will be visible to the user you provided the feedback for.`
      },

      {
        id: 2,
        question: `When can I see my 360-degree ratings from others?`,
        answer: `Your 360-degree ratings will become available to you once three or more raters have provided responses. We provide an overall average of the scores to ensure you receive as honest and accurate a rating as possible, while still maintaining the anonymity of users. If you can’t yet see any ratings from others, we suggest inviting more colleagues or people in your organization that can assess your behavior at work - this can be accessed under your Profile.`
      },
      {
        id: 3,
        question: `How can I request feedback from other users?`,
        answer: `There are a few ways to receive feedback from other users. The first opportunity for feedback from others is with your initial 360-degree baseline measurement (presented immediately after selecting your goal). If you skipped this step, or simply want to add more raters to this assessment, you can do so by going to your Profile page and selecting “Add more 360 raters.” The individuals whose email addresses you inputted will then receive an invitation to rate you using this assessment.

          You also have the option to send out a instant survey, which allows you to select a specific question to be rated on by others. We recommend using this function when you have been working on a particular area relating to your goal (or just your goal in general), and would like to get some quick feedback on how you are progressing. In order to send a instant survey, select “Feedback” from the Main Menu. Then under “Request Feedback” select “Send out a Instant Survey.” On this screen you may select your question, the users you would like to rate you, and when you would like the invitation to be sent.

          A last option is to directly ask some of your colleagues in person or over email. Remember, feedback goes two-ways, and often others won’t directly offer insight unless you ask!`
      },

      {
        id: 4,
        question: 'Who should I select to rate me?',
        answer: `In order to receive the most accurate ratings, it is best to select individuals that have a strong understanding of how you behave in the workplace. Selecting individuals that have sufficient exposure to your work behaviors will give you the best opportunity to pinpoint your strengths and weaknesses from an outside perspective.`
      }
    ]
  },
  fullReport: {
    selfLeadershipAssessment: 'SELF-LEADERSHIP ASSESSMENT',
    preparedFor: 'Prepared for:',
    copyrightText:
      'The following material as it relates to the competency model, individual scale items, and scoring and interpretation, is the exclusive intellectual property of Monark Inc. and as such, outside of personal use, this report is not to be shared or distributed externally, nor reproduced in any medium whatsoever, in full or in part, unless first consented to in writing by an authorized representative of Monark Inc.',
    forAdditionalInformation: 'For additional information, contact',
    monarkInc: 'Monark Inc.',
    downloadAsPDF: 'Download as PDF',
    shareReport: 'Share Report',
    shareYourReport: 'Share your report',
    sendEmail: 'Send Email',
    reportNotFound: 'Report Not Found',
    pleaseWait: 'Please wait. Your report is being generated.',
    summary: 'Summary',
    comprisedOfAreas: 'is comprised of the following areas: ',
    areaAssessed: 'Area Assessed',
    ratingsByQuestions: 'Ratings by individual questions',
    ratings: 'Ratings',
    selfView: 'Self-view',
    others: 'Others',
    goodMorning: 'Good morning',
    goodAfternoon: 'Good afternoon',
    goodEvening: 'Good evening',
    selfAssessment: 'Self-Assessment',
    leadership: 'Leadership',
    fullReport: 'Full Report',
    continueAssessment: 'Continue Assessment',
    viewFullReport: 'View Full Report',
    copyLink: 'Copy Link',
    linkCopied: 'Link copied!',
    assessmentResultsSummary: 'Assessment Results Summary',
    overall: 'Overall',
    teamProgress: 'Team progress',
    assessmentCompletionRate: 'Assessment completion rate',
    completedAssessments: 'Completed Assessments',
    leaders: 'Leaders',
    searchLeaders: 'Search leaders',
    assessmentType: 'Assessment Type',
    progress: 'Progress',
    showing: 'Showing',
    to: 'to',
    of: 'of',
    inProgress: 'In Progress',
    filter: 'Filter',
    startDate: 'Start date',
    completeThisAssessment:
      'Complete this assessment to clarify key leadership behaviors, reflect on your strengths, and identify growth opportunities.',
    currentPillar: 'Current pillar',
    currentCategory: 'Current category',
    reportOnWay: 'Your report is on the way...',
    getComprehensiveReport:
      'Get a comprehensive report by completing the assessment.',
    teamsProgress: 'Team’s progress',
    assessmentInsights: 'Assessment insights',
    goToDashboard: 'Go to Dashboard',
    competencyModel: 'Competency Model',
    overallScore: 'Overall Score',
    youNotAlone: 'You’re not alone',
    everyone: 'Everyone',
    roomToImprove: 'has room to improve.',
    informationAsNegative:
      'Even if you interpret any information as negative, try not to focus on it too much.',
    keepInMind:
      'Keep in mind that most reviews contain positive and constructive information. Even the most seasoned managers and leaders have room to improve.',
    aboutMonark:
      'At Monark we are a team of experts in behavioral science and the psychology of work, with specialized proficiency in organizational and leadership science. Monark places an emphasis on the application of evidence-based practices in our work with clients, grounding everything we do in the psychological principles of the way people work. Alongside specialization in traditional leadership assessment, Monark also has an on-demand leadership development platform, that combines psychology-based learning with a community of practice, empowering leaders to own their growth anywhere, anytime.',
    competencyModelDescription: {
      part1:
        'To be an effective leader, you must be able to both influence and align others who look to you for guidance, as well as exhibit excellence as an individual. Our model represents total leader performance, organized into four main competency areas: Interpersonal Acuity, Leadership Excellence, Personal Achievement, and Strategic Capability.',
      part2:
        'To ensure the results from this assessment can be successfully integrated into leadership development efforts, we measure leader behaviors that are malleable and trainable, empowering leaders in growth that will have lasting results. The key difference between leaders who rely on inherent traits and those who embrace continuous personal growth, believing they have "never arrived" at leadership success, is what distinguishes good leaders from great ones.'
    },
    reflectionComments: 'Reflection Comments',
    yourEmailHasBeenSent: 'Your email has been sent.',
  }
}
