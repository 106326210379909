import { IconButton, Typography, } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { Close } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import RowStack from 'components/atoms/container/RowStack'
import colors from 'styles/colors'

const StatusMessage = ({ message, isSuccess, onClose }) => {
  const color = isSuccess ? colors.SuccessGreen : colors.ErrorRed
  const buttonColor = isSuccess ? 'success' : 'error'

  return (
    <RowStack
      sx={{
        backgroundColor: alpha(color, 0.03),
        borderWidth: 1,
        borderColor: color,
        borderStyle: 'solid',
        borderRadius: 1,
        paddingX: 6,
        paddingY: 2
      }}
    >
      <RowStack spacing={4}>
        { isSuccess ?
          <CheckIcon color={buttonColor} />
          : <WarningAmberIcon color={buttonColor} />
        }
        <Typography variant='body1Small' color={color}>{ message }</Typography>
      </RowStack>

      <IconButton size='small' color={buttonColor} onClick={onClose}>
        <Close />
      </IconButton>
    </RowStack>
  )
}

export default StatusMessage
