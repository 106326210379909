export const apiVersion = process.env.REACT_APP_API_VERSION
export const serverEndpoint = process.env.REACT_APP_SERVER_ENDPOINT
export const microsoftAppId = process.env.REACT_APP_MICROSOFT_APP_ID
export const hostUrl = process.env.REACT_APP_HOST_URL
export const microsoftRedirectUrl = process.env.REACT_APP_HOST_URL
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
export const firebaseVapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY
export const pulseSurveyName = {
  camelCase: process.env.REACT_APP_PULSE_SURVEY_NAME || 'Pulse Survey',
  lowerCase:
    process.env.REACT_APP_PULSE_SURVEY_NAME?.toLowerCase() || 'pulse survey'
}
export const chatAPIKey= process.env.REACT_APP_CHAT_KEY
export const multilingualSwitch = process.env.REACT_APP_MULTILINGUAL_SWITCH === 'true'
export const oneRangeCoursePage = process.env.REACT_APP_ONE_RANGE_COURSE_PAGE
export const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY
