import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import { Close } from '@mui/icons-material'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import InCardStack from 'components/atoms/InCardStack'
import { ActionText } from 'components/atoms/Text'
import { useEffect, useRef, useState } from 'react'
import colors from 'styles/colors'
import { useFormik } from 'formik'
import { deleteRequest, postRequest } from 'utils/api'
import { AI_CONTENT_SEARCH_URL, FEATURE_KEYS } from 'services/constants'
import FormInputBox from 'components/atoms/input/FormInputBox'
import ConvoBox from 'components/atoms/container/ConvoBox'
import SmallButton from 'components/atoms/button/SmallButton'
import FeedbackDialog from '../notificationOverlay/FeedbackDialog'
import { useTranslation } from 'react-i18next'

const suggestions = ['howToProvideFeedback', 'whatIs360'] // Suggestions are mapped from the i18n translation file

const ChatBotTag = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [activated, setActiviated] = useState(false)
  const [chatOpen, setChatOpen] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [displayMessages, setDisplayMessages] = useState([])
  const [feedbackContent, setFeedbackContent] = useState([])
  const [serviceName, setServiceName] = useState(undefined)
  const bottomRef = useRef(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const clearChat = async () => {
    setActiviated(false)
    setDisplayMessages([])
    setServiceName(undefined)
  }

  useEffect(() => {
    if (serviceName) {
      return () => {
        deleteRequest(AI_CONTENT_SEARCH_URL, { serviceName })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName])

  useEffect(() => {
    bottomRef.current?.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth'
    })
  }, [loading, displayMessages])

  const formik = useFormik({
    initialValues: {
      newQuestion: ''
    },
    onSubmit: async values => {
      if (values.newQuestion.length === 0 || loading) {
        return
      }
      setLoading(true)
      const newDisplayMessage = [
        ...displayMessages,
        { role: 'user', content: values.newQuestion }
      ]
      setDisplayMessages(newDisplayMessage)
      formik.resetForm()
      const res = await postRequest(AI_CONTENT_SEARCH_URL, {
        userInput: values.newQuestion,
        serviceName
      })
      if (res.status === 200) {
        const resData = res.data.data
        if (!serviceName) {
          setServiceName(resData.serviceName)
        }
        setDisplayMessages([
          ...newDisplayMessage,
          { role: 'ai', content: resData.reply }
        ])
      }
      setLoading(false)
    }
  })

  useEffect(() => {
    if (chatOpen === false && displayMessages.length > 0) {
      setFeedbackContent([...displayMessages])
      clearChat()
      setDialogOpen(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatOpen])

  return (
    <>
      <InCardStack direction='row'>
        <ActionText
          variant='caption'
          onClick={() => {
            setActiviated(true)
            setChatOpen(true)
          }}
          style={{
            color: colors.Aluminium,
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Icon fontSize='small' sx={{ width: 30 }}>
            <ChatOutlinedIcon />
          </Icon>
          {t('chatBot.chat')}
        </ActionText>
        <Divider orientation='vertical' sx={{ height: '20px' }} />
      </InCardStack>
      {activated ? (
        <>
          <IconButton
            sx={{
              position: 'fixed',
              bottom: 48,
              right: 20,
              backgroundColor: theme.palette.background.paper,
              zIndex: 999,
              padding: 0,
              '&:hover': { zIndex: 999, backgroundColor: colors.SilverSand }
            }}
            onClick={() => clearChat()}
            size='small'>
            <CancelOutlinedIcon fontSize='small' />
          </IconButton>
          <IconButton
            sx={{
              position: 'fixed',
              bottom: 15,
              right: 30,
              backgroundColor: theme.palette.primary.main,
              zIndex: 1,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
                zIndex: 10
              }
            }}
            onClick={() => setChatOpen(!chatOpen)}>
            <TextsmsOutlinedIcon
              fontSize='large'
              sx={{ color: theme.palette.background.paper, paddingTop: 1 }}
            />
          </IconButton>
        </>
      ) : null}
      {chatOpen ? (
        <Dialog
          open={chatOpen}
          maxWidth='xl'
          onClose={() => setChatOpen(false)}>
          <InCardStack direction='row' sx={{ justifyContent: 'space-between' }}>
            <DialogTitle>{t('chatBot.askMeAnythingLeadership')}</DialogTitle>
            <IconButton
              sx={{ float: 'right', paddingTop: '2px' }}
              onClick={() => setChatOpen(false)}>
              <Close />
            </IconButton>
          </InCardStack>
          <Divider />
          <Stack spacing={5} width={800}>
            {displayMessages.length === 0 ? (
              <Stack
                alignItems='end'
                justifySelf='end'
                spacing={5}
                height={500}
                paddingRight={0}
                justifyContent='end'>
                <Typography variant='caption' color={colors.Aluminium}>
                  {t('chatBot.notSureWhatToAsk')}
                </Typography>
                {suggestions.map((s, i) => (
                  <SmallButton
                    variant='outlined'
                    key={`chat-suggestions-${i}`}
                    onClick={() => formik.setFieldValue('newQuestion', t(`chatBot.${s}`))}>
                    {t(`chatBot.${s}`)}
                  </SmallButton>
                ))}
              </Stack>
            ) : (
              <Stack overflow='auto' padding={0} spacing={5} height={500}>
                <ConvoBox messages={displayMessages} loading={loading} />
                <Box height='1px' ref={bottomRef} />
              </Stack>
            )}

            <InCardStack
              direction='row'
              width='100%'
              spacing={5}
              sx={{
                justifyContent: 'center'
              }}>
              <FormInputBox
                placeholder={t('chatBot.askMeAnythingLeadership')}
                sx={{ width: '100%' }}
                type='text'
                formik={formik}
                multiline
                rows={2}
                disabled={loading}
                attributeName='newQuestion'
                onKeyDown={e => {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    e.preventDefault()
                    formik.handleSubmit()
                  }
                }}
              />
              <Button
                onClick={() => formik.handleSubmit()}
                disabled={formik.values.newQuestion.length === 0 || loading}>
                {t('common.send')}
              </Button>
            </InCardStack>
          </Stack>
        </Dialog>
      ) : null}

      <FeedbackDialog
        title={t('chatBot.howWasYourExperience')}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        featureKey={FEATURE_KEYS.ASK_ME_ANYTHING_LEADERSHIP}
        featureContent={feedbackContent}
      />
    </>
  )
}

export default ChatBotTag
