// All user related database operations can be defined here.

import {
  LOGIN_URL,
  LOGOUT_URL,
  USER_PROFILE_URL,
  USER_STATES_URL,
  CALENDAR_OAUTH_TOKEN_PROVIDERS,
  CALENDAR_OAUTH_STORAGE_KEY,
  CALENDER_OAUTH_TOKEN_URL,
  USER_CALENDAR_EVENTS_URL,
  USER_CONNECTION_URL
} from 'services/constants'
import { postRequest, deleteRequest, getRequest, patchRequest } from 'utils/api'
import { getUpdateLocalUser, setLocalUser } from 'utils/localStorage'
import { clearPendoSession } from './pendo'
import { loggedTokenKey } from './firebase'
import { cleanupTrackingScreen } from './trackingScreenActions'
import * as amplitude from '@amplitude/analytics-browser'
import { registerWithAmplitude } from './amplitudeTracking'

export const signin = async requestBody => {
  const res = await postRequest(LOGIN_URL, requestBody)
  if (res.data.success) {
    const userData = res.data.data
    setLocalUser(userData)
    registerWithAmplitude(res.data.data)
  }
  return res
}

const signoutCleanup = () => {
  localStorage.removeItem('user')
  localStorage.removeItem(loggedTokenKey)
  amplitude.reset()
  amplitude.flush()
  clearPendoSession()
}
export const signout = async () => {
  await cleanupTrackingScreen()
  const res = await deleteRequest(LOGOUT_URL)
  signoutCleanup()
  return res
}

export const refreshProfile = async (reloadOnUnAuthorized = true) => {
  const res = await getRequest(USER_PROFILE_URL)
  if (res.status === 200) {
    registerWithAmplitude(res.data.data)
    return res.data.data
  } else if (res.status === 401) {
    signoutCleanup()
    if (reloadOnUnAuthorized) {
      window.location.reload()
    }
  }
  return false
}

export const updateProfile = async requestBody => {
  const res = await patchRequest(USER_PROFILE_URL, requestBody)
  if (res.status === 200) {
    setLocalUser(res.data.data)
  }
  return res
}

export const getStates = async (reloadOnUnAuthorized = true) => {
  const res = await getRequest(USER_STATES_URL)
  if (res.status === 200) {
    return res.data.data
  } else if (res.status === 401) {
    signoutCleanup()
    if (reloadOnUnAuthorized) {
      window.location.reload()
    }
  }
  return false
}

export const updateStates = async (requestBody, refreshLocalUser = false) => {
  const res = await patchRequest(USER_STATES_URL, requestBody)
  if (res.status === 200 && refreshLocalUser) {
    await getUpdateLocalUser()
  }
  return res
}

export const getUserConnections = async (userOnly = false) => {
  const res = await getRequest(USER_CONNECTION_URL)
  if (res.status === 200) {
    const recipientData = res.data.data
      .sort((a, b) => (a.user?.firstName > b.user?.firstName ? 1 : -1))
      .map(c => ({ ...c.user, ...c }))
    if (userOnly) {
      return recipientData.filter(u => u.user.id)
    }
    return recipientData
  } else {
    return { res }
  }
}

export const getUserSigninMessage = res => {
  const msg = { success: true, text: '' }
  if (res.status === 201) {
    setLocalUser(res.data.data)
  } else if (res.status === 422 && res.data.message.includes('taken')) {
    msg.success = false
    msg.text = 'This email has been taken'
  } else if (res.status === 409) {
    msg.success = false
    msg.text =
      'It looks like you previously had an account with Monark. You can recover this account by resetting your password, please check your email for password reset instructions'
  } else {
    msg.success = false
    msg.text = 'Something went wrong'
  }
  return msg
}

export const getCalendarEvents = async (
  code = undefined,
  query = undefined
) => {
  if (code) {
    const requestBody = {
      provider: CALENDAR_OAUTH_TOKEN_PROVIDERS.MICROSOFT,
      code
    }
    const res = await postRequest(CALENDER_OAUTH_TOKEN_URL, requestBody)
    localStorage?.setItem(
      CALENDAR_OAUTH_STORAGE_KEY.MICROSOFT,
      res.status === 201
    )
    window.close()
  }
  const res = await getRequest(USER_CALENDAR_EVENTS_URL, query)
  if (res?.status === 200) {
    res.linked = true
  } else if (res?.status === 204) {
    res.linked = false
  } else if (res?.status === 422 && res?.data.data?.tokenInvalid) {
    res.linked = false
    if (res?.data.data.unauthorized) {
      res.unauthorized = true
    } else {
      res.unauthorized = false
    }
    res.tokenExpired = true
  }
  return res
}

export const getThirdPartyOauth = async () => {
  const res = await getRequest(CALENDER_OAUTH_TOKEN_URL)
  return res
}
