// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rhap_container {
  background-color: unset !important;
  box-shadow: none !important;
}

.journeyStepContentInnerHTML p a {
  text-decoration-line: none;
}

.disableTextSelection{
  -ms-user-select:none;
  -moz-user-select:none;
  -webkit-user-select:none;
  -webkit-touch-callout: none;
  user-select:none;
}

.react-player {
  border-radius: 20px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/styles/override.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,wBAAwB;EACxB,2BAA2B;EAE1B,gBAAgB;AACnB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".rhap_container {\n  background-color: unset !important;\n  box-shadow: none !important;\n}\n\n.journeyStepContentInnerHTML p a {\n  text-decoration-line: none;\n}\n\n.disableTextSelection{\n  -ms-user-select:none;\n  -moz-user-select:none;\n  -webkit-user-select:none;\n  -webkit-touch-callout: none;\n  -khtml-user-select: none;\n   user-select:none;\n}\n\n.react-player {\n  border-radius: 20px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
