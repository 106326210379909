import { useState } from 'react'
import StyledButton from 'components/atoms/button/StyledButton'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import { useTranslation } from 'react-i18next'

const CopyToClipboardButton = ({
  copyText,
  copiedMessage = null,
  defaultMessage
}) => {
  const { t } = useTranslation()
  const [linkCopied, setLinkCopied] = useState(false)

  const copyLink = () => {
    navigator.clipboard.writeText(copyText)
    setLinkCopied(true)
    setTimeout(() => {
      setLinkCopied(false)
    }, 3000)
  }

  return (
    <>
      {copyText && (
        <StyledButton
          inverseTheme={true}
          size={'small'}
          sx={{ gap: 1 }}
          onClick={copyLink}>
          {linkCopied ? (
            copiedMessage ?? t('fullReport.linkCopied')
          ) : (
            <>
              <LinkOutlinedIcon sx={{ fontSize: 20 }} />
              {defaultMessage ?? t('fullReport.copyLink')}
            </>
          )}
        </StyledButton>
      )}
    </>
  )
}

export default CopyToClipboardButton
