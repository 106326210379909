import { useState, useEffect } from 'react'
import {
  Stack,
  useTheme
} from '@mui/material'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import SearchInputBox from 'components/atoms/input/SearchInputBox'

const TableContainerWithSearch = ({ 
  headers,
  users,
  setFilteredUsers,
  children,
  maxHeight = '600px',
  minHeight = '200px',
  otherSX,
  shorterRow = false,
  tableProps = {},
  placeholder = 'Search'
}) => {
  const theme = useTheme()
  const contentContainerProps = {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: 5,
    spacing: 3
  }
  const [inviteFilterText, setInviteFilterText] = useState('')
  useEffect(() => {
    const newFilteredUsers = users.filter(user =>
      `${(user.firstName ?? '')} ${(user.lastName ?? '')}`.toLowerCase().includes(inviteFilterText.toLowerCase()) ||
      (user.email ?? '').toLowerCase().includes(inviteFilterText.toLowerCase())
    );
    setFilteredUsers(newFilteredUsers)
    // eslint-disable-next-line
  }, [inviteFilterText, users])

  return (
    <Stack {...contentContainerProps}>
        <SearchInputBox
            onChangeHandler={e => setInviteFilterText(e.target.value)}
            placeholder={placeholder}
        />

        <PresetTableContainer
            maxHeight={maxHeight}
            minHeight={minHeight}
            otherSX={otherSX}
            tableProps={tableProps}
            shorterRow={shorterRow}
            headers={headers}
        >
            {children}
        </PresetTableContainer>
    </Stack>
  )
}

export default TableContainerWithSearch
