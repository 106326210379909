import { Tooltip, Typography, useTheme } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import CharacterAvatar from './CharacterAvatar'
import colors from 'styles/colors'
import RowStack from 'components/atoms/container/RowStack'
import { useTranslation } from 'react-i18next'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import StyledButton from 'components/atoms/button/StyledButton'
import { ROLE_PLAY_TYPES } from 'services/constants'
import { convertSecsToMins } from 'utils/formatDateTime'

const RolePlayInteractionCard = ({
  rolePlay,
  buttonText,
  onButtonClick,
  children,
  showAudioIcon,
  type,
  completeTime,
  height = 350,
  inPopup = false
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const botName = () => rolePlay?.context?.characterName || 'Alex Smith'

  return (
    <BoxWithBorder
      sx={{
        border: 1,
        borderStyle: inPopup ? 'none' : undefined,
        borderColor: theme.palette.primary.main,
        background: inPopup
          ? colors.Transparent
          : `linear-gradient(to top, ${theme.palette.primary.main}, ${colors.DarkBlue})`,
        borderWidth: 1,
        display: 'flex',
        alignItems: 'center',
        height: height,
        width: '100%',
        color: inPopup ? 'unset' : theme.palette.background.default
      }}>
      <InCardStack width='100%'>
        <CharacterAvatar
          rolePlay={rolePlay}
          name={botName()}
          size={120}
          sx={{
            borderWidth: 3,
            borderStyle: 'solid',
            borderColor: inPopup ? colors.Black : colors.White
          }}
          showAudioIcon={showAudioIcon}
          showCompleteIcon={inPopup}
        />
        <RowStack width='fit-content' spacing={2}>
          {inPopup ? (
            <Typography variant='h3' paddingX={10} textAlign='center'>
              {rolePlay.title} {t('superLeaderUserDrillDown.complete')}
            </Typography>
          ) : (
            <Typography variant='h1' color={colors.White}>
              {botName()}
            </Typography>
          )}
          {!inPopup && (
            <Tooltip
              title={t('rolePlay.powerByAI', {
                botName: botName().split(' ')[0]
              })}>
              <InfoOutlinedIcon fontSize='small' />
            </Tooltip>
          )}
        </RowStack>
        {completeTime && (
          <Typography variant='h3' color={colors.White}>
            {`${t(type === ROLE_PLAY_TYPES.LIVE_CALL ? 'rolePlay.call' : 'rolePlay.textChat')} ${t('common.completed')} `}
            {type === ROLE_PLAY_TYPES.LIVE_CALL &&
              `(${convertSecsToMins(completeTime).trim()})`}
          </Typography>
        )}
        {buttonText && (
          <StyledButton onClick={onButtonClick} inverseTheme={!inPopup}>
            {buttonText}
          </StyledButton>
        )}
        {children}
      </InCardStack>
    </BoxWithBorder>
  )
}

export default RolePlayInteractionCard
