import PulseSurveySendCard from 'components/organisms/pulsSurveys/PulseSurveySendCard'
import FeedbackPage from 'components/templates/navigationPageTemplates/FeedbackPage'

const PulseSurveyNew = () => {
  return (
    <FeedbackPage>
      <PulseSurveySendCard />
    </FeedbackPage>
  )
}

export default PulseSurveyNew
