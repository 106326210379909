import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { Subtitle4 } from 'components/atoms/Text'
import { USER_NOTES_TYPES, USER_NOTES_URL } from 'services/constants'
import { formatDate } from 'utils/formatDateTime'
import NextIconButton from 'components/atoms/button/NextIconButton'
import { useState } from 'react'
import { patchRequest } from 'utils/api'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { useNavigate } from 'react-router-dom'
import { JOURNEY } from 'routes/constants'
import moment from 'moment/moment'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'
import { trackPathAction } from 'services/amplitudeTracking'

const NotebookItem = ({ data, ...otherProps }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [requestSuccess, setRequestSuccess] = useState(undefined)
  const [completed, setCompleted] = useState(data.completed)
  const type = data.type
  const goalStep = data.goalStep
  const goalStepContent = goalStep.goal_step_contents[0]

  const handleFinishGoal = async () => {
    const res = await patchRequest(`${USER_NOTES_URL}/${data.id}`, {
      completed: true
    })
    if (res.status === 200) {
      data.updatedAt = moment()
      data.completed = true
      setCompleted(true)
      setRequestSuccess(true)
      trackPathAction(goalStep.goalId, 'Complete Goal')
    } else {
      setRequestSuccess(false)
    }
  }

  const handleGoToContent = () => {
    navigate(`${JOURNEY}/${goalStep.goal_id}`, {
      state: { goalStepId: goalStep.id }
    })
  }
  return (
    <Box width='100%' {...otherProps}>
      <Stack padding={0} spacing={3}>
        <Box width='100%' display='flex'>
          <Typography variant='subtitle1'>{goalStepContent.title}</Typography>
          <Grid item flex={1} display='inline-flex' justifyContent='right'>
            <NextIconButton
              style={{ padding: '3px', minWidth: '50px' }}
              onClick={() => handleGoToContent()}
            />
          </Grid>
        </Box>
        <Typography variant='body2'>
        {t('notebook.createdDate')}{formatDate(data.createdAt, i18n.resolvedLanguage)}
        </Typography>
        {type === USER_NOTES_TYPES.GOAL ? (
          <>
            <Typography variant='body2'>
              {t('notebook.dueDate')}
              {data.dueDate ? (
                <Typography
                  variant='body2'
                  component='span'
                  color={
                    !completed && moment(data.dueDate) < moment()
                      ? colors.Cinnabar
                      : undefined
                  }>
                  {formatDate(data.dueDate, i18n.resolvedLanguage)}
                </Typography>
              ) : (
                t('goalsArea.notSet')
              )}
            </Typography>
            <Typography variant='body2'>
              {t('notebook.completedDate')}{completed ? formatDate(data.updatedAt, i18n.resolvedLanguage) : null}
            </Typography>
            <Subtitle4>{goalStepContent.goalQuestion}</Subtitle4>
          </>
        ) : null}
        {type === USER_NOTES_TYPES.ACTIVITY ? (
          <Subtitle4 style={{ whiteSpace: 'pre-line' }}>
            {goalStepContent.activityQuestion}
          </Subtitle4>
        ) : null}
        <Typography variant='body2' style={{ whiteSpace: 'pre-line' }}>
          {data.notes}
        </Typography>
        {type === USER_NOTES_TYPES.GOAL ? (
          <CardContent>
            {completed ? (
              <Typography color='primary'>
                {t('stepNote.greatJobCompletedGoal')}
              </Typography>
            ) : (
              <Button
                sx={{ width: '150px', paddingY: '5px' }}
                onClick={() => handleFinishGoal()}>
                {t('stepNote.IDidIt')}
              </Button>
            )}
          </CardContent>
        ) : (
          <Box height='10px' />
        )}
        {requestSuccess === false ? <RequestRetryAlert /> : null}
        <Divider />
      </Stack>
    </Box>
  )
}

export default NotebookItem
