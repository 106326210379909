import { Typography } from '@mui/material'
import { deleteRequest } from 'utils/api'
import { INVITED_LIST_URL } from 'services/constants'
import { useState } from 'react'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import SmallButton from 'components/atoms/button/SmallButton'
import { useTranslation } from 'react-i18next'

const RevokeInviteButton = ({ successCallback, invite }) => {
  const { t } = useTranslation()
  const [sendDialogOpen, setSendDialogOpen] = useState(false)
  const [requestError, setRequestError] = useState(null)

  const handleDialogSelect = async confirmed => {
    if (confirmed) {
      const res = await deleteRequest(`${INVITED_LIST_URL}/${invite.id}`)
      if (res.status === 204) {
        setSendDialogOpen(false)
        successCallback(invite.id)
      } else setRequestError(true)
    } else {
      setSendDialogOpen(false)
    }
  }
  return (
    <>
      <SmallButton
        color='error'
        variant='outlined'
        onClick={() => setSendDialogOpen(true)}
        data-testid={`revoke-invite-${invite.id}`}>
        {t('company.revoke')}
      </SmallButton>
      <ConfirmCancelDialog
        open={sendDialogOpen}
        titleText={t('company.revokeUserInvite')}
        onClose={handleDialogSelect}
        confirmText={t('company.yesRevokeInvite')}
        cancelText={t('common.cancel')}
        buttonsDirection='row'>
        <Typography>
          {t('company.actionRevokeUserInvitation')}
        </Typography>
        {requestError ? <RequestRetryAlert /> : null}
      </ConfirmCancelDialog>
    </>
  )
}

export default RevokeInviteButton
