import { IconButton, Tooltip } from '@mui/material'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { useEffect, useState } from 'react'
import { BOOKMARKS_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import { useTranslation } from 'react-i18next'

const BookmarkButton = ({ bookmarked = false, stepContentId }) => {
  const { t } = useTranslation()
  const [marked, setMarked] = useState(bookmarked)
  const [contentId, setContentId] = useState(stepContentId)
  useEffect(() => {
    if (stepContentId !== contentId) {
      setMarked(bookmarked)
      setContentId(stepContentId)
    }
  }, [stepContentId, contentId, bookmarked])
  const toggleBookMark = async () => {
    const res = await postRequest(BOOKMARKS_URL, {
      goalStepContentId: stepContentId,
      bookmark: !marked
    })
    if (res.status === 200) {
      setMarked(!marked)
    }
  }
  return (
    <IconButton
      onClick={() => toggleBookMark()}
      sx={{ height: '48px', width: '48px' }}>
      {marked ? (
        <Tooltip title={t('pathsAndModels.clickRemoveBookmark')}>
          <BookmarkIcon fontSize='large' />
        </Tooltip>
      ) : (
        <Tooltip title={t('pathsAndModels.clickBookmarkContent')}>
          <BookmarkBorderIcon fontSize='large' />
        </Tooltip>
      )}
    </IconButton>
  )
}

export default BookmarkButton
