import { Box, CardContent, Stack, Typography } from '@mui/material'
import NumberIconButton from 'components/atoms/button/NumberIconButton'
import { useTranslation } from 'react-i18next'

const FiveNumberOptions = ({ onClick }) => {
  const { t } = useTranslation()
  const textProps = {
    variant: 'caption',
    textAlign: 'center',
    component: 'p',
    lineHeight: 1
  }
  const placeHolderProps = { width: '56px', height: '40px' }
  const horizontalStackProps = {
    direction: 'row',
    padding: 0,
    spacing: 20,
    alignItems: 'center'
  }
  return (
    <CardContent sx={{ paddingTop: 0, paddingBottom: 30 }}>
      <Stack spacing={0} padding={0}>
        <Stack {...horizontalStackProps}>
          {Array.from(Array(5).keys()).map(i => {
            const value = i + 1
            return (
              <NumberIconButton
                key={value}
                number={value}
                onClick={() => onClick(value)}
              />
            )
          })}
        </Stack>
        <Stack {...horizontalStackProps}>
          <Box {...placeHolderProps}>
            <Typography {...textProps}>{t('stepFeedback.stronglyDisagree')}</Typography>
          </Box>
          <Box {...placeHolderProps} />
          <Box {...placeHolderProps}>
            <Typography {...textProps}>{t('stepFeedback.neutral')}</Typography>
          </Box>
          <Box {...placeHolderProps} />
          <Box {...placeHolderProps}>
            <Typography {...textProps}>{t('stepFeedback.stronglyAgree')}</Typography>
          </Box>
        </Stack>
        <Box
          width='100%'
          height='7px'
          sx={{
            background: 'linear-gradient(to right, #FBBB00, #28B446)',
            borderRadius: 1
          }}
        />
      </Stack>
    </CardContent>
  )
}

export default FiveNumberOptions
