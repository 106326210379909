import { alpha, createTheme } from '@mui/material/styles'
import colors from './colors'
import './fonts/fonts.css'
import './override.css'

const theme = createTheme({
  spacing: 2,
  // justifyContent: 'center',
  palette: {
    primary: { main: colors.BondiBlue },
    secondary: { main: colors.MonarkYellow },
    background: {
      paper: colors.White,
      default: colors.WhiteLilac
    },
    action: {
      selected: colors.Teal400,
      active: colors.Teal400,
      disabled: colors.JetGrey
    },
    success: {
      main: colors.SuccessGreen
    },
    error: {
      main: colors.ErrorRed
    }
  },
  typography: {
    fontFamily: 'Inter',
    allVariants: {
      color: colors.Black,
      fontWeight: 400,
    },
    body1: {
      fontSize: 16,
      lineHeight: '150%',
    },
    body1Large: {
      fontSize: 18,
      lineHeight: '150%',
      color: colors.Grey400,
    },
    body1Small: {
      fontSize: 14,
      lineHeight: '150%',
      color: colors.Grey400,
    },
    body1Micro: {
      fontSize: 12,
      lineHeight: '150%',
      color: colors.Grey400,
    },
    body1Nano: {
      fontSize: 10,
      lineHeight: '150%',
      color: colors.Grey400,
    },
    button: {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '120%',
    },
    buttonSmall: {
      textTransform: 'none',
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '120%',
    },
    formLabel: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '150%',
    },
    formText: {
      fontSize: 14,
      lineHeight: '140%',
      color: colors.Grey400,
    },
    formTagText: {
      fontSize: 13,
      lineHeight: '140%',
    },
    formTagSelected: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '140%',
    },
    h1: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: '140%',
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '140%',
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '140%',
    },
    h4: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '140%',
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '140%',
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '140%',
    },
    eyebrow: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '100%',
      textTransform: 'uppercase',
    },
    eyebrow2: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '140%',
      textTransform: 'uppercase',
      color: colors.Aluminium,
    },
    reportH1: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '140%'
    },
    /* Old styles (pre-2024 redesign) */
    h2_alu: {
      fontSize: 24,
      fontWeight: 800,
      color: colors.Aluminium,
    },
    body1SmallWhite: {
      fontSize: 14,
      lineHeight: '150%',
      color: colors.White,
    },
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      color: colors.Aluminium,
      fontWeight: 'bold'
    },
    body2: {
      color: colors.Aluminium
    },
    text: {
      color: colors.Aluminium
    },

  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          borderRadius: '16px',
          padding: '10px 30px',
          fontSize: 16,
          fontWeight: 500,
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: 15,
          color: colors.Grey400,
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: colors.Grey400,
        }
      }
    },
    MuiStack: {
      defaultProps: {
        padding: '24px'
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: { justifyContent: 'center', display: 'flex' }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: colors.WhiteLilac,
          minWidth: '300px',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.BondiBlue,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.BondiBlue,
          },
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          maxWidth: '270px'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          verticalAlign: 'top'
        },
        root: {
          backgroundColor: colors.White,
          paddingLeft: 8,
          paddingRight: 8
        }
      }
    },
    MuiTableRow: {
      defaultProps: {
        sx: { 'td, th': { border: 0 } }
      }
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 20,
          fontWeight: 600,
          lineHeight: '140%',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          sx: { borderRadius: '16px' }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: { tooltip: { fontSize: '14px' } }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '16px'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid'
          },
          '&:hover:not(.Mui-disabled):not(.Mui-error):before': {
            borderBottomWidth: '1px'
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        // scroll bar styling. Adapted from https://stackoverflow.com/a/68983427
        body: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: alpha(colors.Aluminium, 0.01),
            borderRadius: 10,
            width: 8
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 20,
            backgroundColor: alpha(colors.Aluminium, 0.3)
          },
          '.pac-container': {
            zIndex: 1500, // Adjust z-index to ensure it's above the dialog
          },
          // '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
          //   backgroundColor: 'green'
          // }
        }
      }
    }
  }
})

export default theme
