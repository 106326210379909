import { TableCell, TableRow, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import OverlayLoading from 'components/atoms/OverlayLoading'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import SmallButton from 'components/atoms/button/SmallButton'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import { useEffect, useRef, useState } from 'react'
import { OHEP_RESPONSES_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import moment from 'moment'
import { LANGUAGES } from 'utils/constants'

const OHEPSurveyResult = ({ surveyId }) => {
  moment.locale(LANGUAGES.EN)
  const [loading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const [resultData, setResultData] = useState([])
  const [surveyHeaders, setSurveyHeaders] = useState([])
  const tableRef = useRef(null)
  const dateFields = ['startedAt', 'endAt']

  useEffect(() => {
    const fetchSurvey = async () => {
      const rRes = await getRequest(OHEP_RESPONSES_URL, {
        surveyId
      })
      if (rRes.status === 200) {
        const data = rRes.data.data
        setRequestError(false)
        setSurveyHeaders(
          data.columns.map(h => ({
            ...h,
            displayName: h.questionText,
            id: h.field || h.id
          }))
        )
        setResultData(data.data)
      } else {
        setRequestError(true)
      }
      setLoading(false)
    }
    fetchSurvey()
    setLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId])

  return (
    <>
      <OverlayLoading open={loading} />
      <InCardStack
        direction='row'
        sx={{ justifyContent: 'space-between', width: '100%', padding: 6 }}>
        <Typography variant='h3' color='primary'>
          Response received: {resultData.length}
        </Typography>
        <DownloadTableExcel
          filename='ohep'
          sheet='sheet1'
          currentTableRef={tableRef.current}>
          <SmallButton>Export</SmallButton>
        </DownloadTableExcel>
      </InCardStack>
      <PresetTableContainer
        tableProps={{ ref: tableRef }}
        shorterRow={true}
        maxHeight='1200px'
        headers={surveyHeaders.map((h, i) => (
          <TableCell key={`${i}-${h.displayName}`}>{h.displayName}</TableCell>
        ))}>
        {resultData.map((r, i) => (
          <TableRow key={`row-${i}`}>
            {surveyHeaders.map((h, j) => (
              <TableCell key={`value-${i}-${j}`}>
                {dateFields.includes(h.id)
                  ? moment.utc(r[h.id]).format('MMMM Do, YYYY h:mm A')
                  : r[h.id]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </PresetTableContainer>
      {requestError ? <RequestRetryAlert /> : null}
      <Typography variant='h3'>Columns</Typography>
      <PresetTableContainer
        shorterRow={true}
        headers={
          <>
            <TableCell />
            <TableCell>Column Name</TableCell>
            <TableCell>Question Type</TableCell>
            <TableCell>Options</TableCell>
          </>
        }>
        {surveyHeaders.map((r, i) => (
          <TableRow key={`row-${i}`}>
            <TableCell>{i + 1}</TableCell>
            <TableCell>{r.questionText}</TableCell>
            <TableCell>{r.type}</TableCell>
            <TableCell>
              {r.options?.map(o => (
                <Typography
                  key={`option-${i}-${o.value}`}>{`${o.value}: ${o.label}`}</Typography>
              ))}
            </TableCell>
          </TableRow>
        ))}
      </PresetTableContainer>
    </>
  )
}

export default OHEPSurveyResult
