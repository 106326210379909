import { FormControlLabel, Radio } from '@mui/material'

const RadioOption = props => {
  const { value, label, ...otherProps } = props
  return (
    <FormControlLabel
      value={value}
      control={<Radio size='small' />}
      label={label}
      {...otherProps}
    />
  )
}

export default RadioOption
