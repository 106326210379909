import { useState } from 'react'
import {
  Box,
  CardContent,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import TableContainerWithSearch from 'components/atoms/table/TableContainerWithSearch'
import UserManagementContainer from 'components/molecules/adminDashboard/UserManagementContainer'
import DateCell from 'components/atoms/table/DateCell'
import ResendInviteButton from 'components/molecules/adminDashboard/ResendInviteButton'
import RevokeInviteButton from 'components/molecules/adminDashboard/RevokeInviteButton'
import { NotificationBodyText } from 'components/atoms/Text'
import colors from 'styles/colors'
import PopoverCell from 'components/atoms/table/PopoverCell'
import AvatarHeaderCell from 'components/atoms/table/AvartarHeaderCell'
import { useTranslation } from 'react-i18next'

const CompanyInvitedUserList = ({
  users,
  requestError,
  removeCallback,
  licenseActive
}) => {
  const { t } = useTranslation()
  const [filteredUsers, setFilteredUsers] = useState(users)

  return (
    <UserManagementContainer
      titleText={t('company.pendingInvites')}
      description={t('company.listOfUsersYouInvited')}
      requestError={requestError}>
      {users.length > 0 ? (
        <TableContainerWithSearch
          setFilteredUsers={setFilteredUsers}
          users={users}
          placeholder={t('overview.searchForUser')}
          maxHeight='300px'
          shorterRow={true}
          headers={
            <>
              <TableCell>{t('company.userDetails')}</TableCell>
              <TableCell>{t('company.invitedOn')}</TableCell>
              <TableCell>{t('company.license')}</TableCell>
              <TableCell>{t('profile.role')}</TableCell>
              <TableCell>{t('overview.learningPath')}</TableCell>
              <TableCell />
            </>
          }>
            {filteredUsers.map(u => (
              <TableRow key={u.id}>
                <AvatarHeaderCell user={u} />
                <DateCell sx={{ minWidth: 130 }} value={u.createdAt} />
                <TableCell align='center'>
                  <Box height='100%' justifyContent='center' display='grid'>
                    {u.subscriptionOrganizationId ? (
                      <CheckCircleIcon color='primary' />
                    ) : (
                      <CancelIcon sx={{ color: colors.Cinnabar }} />
                    )}
                  </Box>
                </TableCell>
                <TableCell>{u.roleName}</TableCell>
                {u.goalName ? (
                  <PopoverCell
                    id={`invite-${u.id}-goal`}
                    cellAlign='left'
                    PopoverContent={() =><Typography>{u.goalName}</Typography>}
                    cellValue={`${u.goalName.slice(0, 20)}...`}
                  />
                ) : (
                  <TableCell />
                )}

                <TableCell>
                  {licenseActive ? (
                    <Stack padding={0} direction='row' spacing={5} minWidth={130}>
                      <ResendInviteButton invite={u} />
                      <RevokeInviteButton
                        invite={u}
                        successCallback={removeCallback}
                      />
                    </Stack>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
        </TableContainerWithSearch>
      ) : (
        <CardContent>
          <NotificationBodyText>{t('company.noPendingInvites')}</NotificationBodyText>
        </CardContent>
      )}
    </UserManagementContainer>
  )
}

export default CompanyInvitedUserList
