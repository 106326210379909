import { CardContent, Divider, TableCell, TableRow } from '@mui/material'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import UserManagementContainer from 'components/molecules/adminDashboard/UserManagementContainer'
import AvatarHeaderCell from 'components/atoms/table/AvartarHeaderCell'
import DateCell from 'components/atoms/table/DateCell'
import { NotificationBodyText } from 'components/atoms/Text'
import { useTranslation } from 'react-i18next'

const DeactivatedUserList = ({ users, requestError }) => {
  const { t } = useTranslation()
  return (
    <>
      <Divider />
      <UserManagementContainer
        requestError={requestError}
        titleText={t('company.deactivatedUsers')}
        description={t('company.usersHaveBeenDeactivated')}>
        {users.length > 0 ? (
          <PresetTableContainer
            maxHeight='250px'
            shorterRow={true}
            headers={
              <>
                <TableCell>{t('overview.email')}</TableCell>
                <TableCell>{t('company.deactivatedOn')}</TableCell>
              </>
            }>
            {users.map(u => (
              <TableRow key={u.id} data-testid={`deactivated-user-${u.id}`}>
                <AvatarHeaderCell user={u} />
                <DateCell value={u.deletedAt} />
              </TableRow>
            ))}
          </PresetTableContainer>
        ) : (
          <CardContent>
            <NotificationBodyText>
              {t('company.noDeactivatedUsers')}
            </NotificationBodyText>
          </CardContent>
        )}
      </UserManagementContainer>
    </>
  )
}

export default DeactivatedUserList
