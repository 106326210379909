import {
  TableRow,
  TableCell,
  CardContent,
  Typography,
  Button
} from '@mui/material'
import { isMatchingUser } from 'utils/userSearchHelper'
import SearchInputBox from 'components/atoms/input/SearchInputBox'
import DateCell from 'components/atoms/table/DateCell'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import PageComponent from 'components/templates/PageComponent'
import { useRef, useState } from 'react'
import InCardStack from 'components/atoms/InCardStack'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import { useTranslation } from 'react-i18next'

const InvitedUsersList = ({ invitedUsers }) => {
  const { t } = useTranslation()
  const [filterText, setFilterText] = useState('')
  const tableRef = useRef()
  return (
    <PageComponent
      titleText={t('overview.invitedUsers')}
      tooltip={t('overview.usersWhoBeenInvitedButNotLoggedIn')}
      data-testid='dashboard-component-users-invited'>
      {invitedUsers.length > 0 ? (
        <>
          <InCardStack
            direction='row'
            sx={{ width: '100%', justifyContent: 'space-between' }}>
            <SearchInputBox
              onChangeHandler={e => setFilterText(e.target.value)}
              placeholder={t('overview.searchForUser')}
            />
            <DownloadTableExcel
              filename='user_invites'
              sheet='sheet1'
              currentTableRef={tableRef.current}>
              <Button>{t('overview.export')}</Button>
            </DownloadTableExcel>
          </InCardStack>

          <PresetTableContainer
            tableProps={{ ref: tableRef }}
            headers={
              <>
                <TableCell>{t('overview.email')}</TableCell>
                <TableCell>{t('overview.learningPath')}</TableCell>
                <TableCell>{t('overview.cohort')}</TableCell>
                <TableCell>{t('profile.role')}</TableCell>
                <TableCell>{t('overview.firstInvitedAt')}</TableCell>
              </>
            }>
            {invitedUsers
              .filter(user => isMatchingUser(user, filterText))
              .map(user => {
                return (
                  <TableRow key={user.email}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.goalName}</TableCell>
                    <TableCell>{user.cohort?.name}</TableCell>
                    <TableCell>{user.roleName}</TableCell>
                    <DateCell value={user.firstInvitedAt} />
                  </TableRow>
                )
              })}
          </PresetTableContainer>
        </>
      ) : (
        <CardContent>
          <Typography variant='h5'>{t('overview.noUserInvited')}</Typography>
        </CardContent>
      )}
    </PageComponent>
  )
}

export default InvitedUsersList
