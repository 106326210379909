// Centerize routing control
// Reference from https://www.robinwieruch.de/react-router-private-routes/
//  and https://gitlab.com/saurabhshah231/reactjs-myapp

import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  ROOT,
  OVERVIEW,
  LOGIN,
  FORGOT_PASSWORD,
  PERMISSION_LEVEL,
  COMPANY_GENERAL,
  RESET_PASSWORD,
  DEEP_LINK_ACTION,
  UNAUTHORIZED,
  USER_PROFILE,
  ASSESSMENT_OTHERS,
  UNSUBSCRIBE,
  VERIFY_EMAIL,
  PULSE_SURVEYS,
  PULSE_SURVEY_NEW,
  PULSE_SURVEY_DETAIL,
  ASSESSMENTS,
  PULSE_SURVEY_RATE,
  USER_FEEBACK,
  HOME,
  JOURNEY,
  JOURNEY_NEW,
  ASSESSMENT_BEHAVIOUR,
  SEND_EMAIL_VERIFICATION,
  LEADERSHIP_MODEL,
  ASSESSMENT_SELF,
  ADD_ASSESSMENT_RATERS,
  CONTENT_EDITOR,
  TEAM_SHOUTOUTS,
  BOOKMARKS,
  ABOUT_CONFIDENTIALITY,
  ABOUT_360,
  EDIT_PROFILE,
  USER_SETTINGS,
  SEARCH_RESULTS,
  ASSESSMENT_BEHAVIOUR_COMPARISON,
  HELP_AND_SUPPORT,
  NOTEBOOK,
  ONBOARDING,
  SBUSCRIPTION_ADMIN,
  COMPANY_USERS,
  SIGNUP_DIRECT,
  USER_CALENDAR_EVENTS,
  SURVEY,
  OHEP_SURVEYS,
  POST_GOAL_QUIZ,
  GOALS,
  JOURNEY_DEMO,
  CHECK_QUIZZES_RESULT_EXPORT,
  USER_PROGRESS_REPORT,
  MY_TEAM,
  MY_CONTACT,
  MY_MEETING,
  CHECK_IN_SURVEY,
  FEATURES_ADMIN,
  DEMO_ACCOUNTS,
  ROLE_PLAY_PRACTISES,
  ROLE_PLAY,
  ALL_SCENARIOS,
  ASSESSMENT_REPORT_SHARE,
  FULL_ASSESSMENT
} from 'routes/constants'
import PrivateRoute from './PrivateRoute'
import NotFound from '../components/pages/public/NotFound'
import Overview from 'components/pages/adminDashboard/Overview'
import Login from 'components/pages/public/Login'
import ForgotPassword from 'components/pages/public/ForgotPassword'
import Company from 'components/pages/adminDashboard/Company'
import ResetPassword from 'components/pages/public/ResetPassword'
import DeepLinkAction from 'routes/DeepLinkAction'
import Unauthorized from 'components/pages/Unauthorized'
import SignUp from 'components/pages/public/SignUp'
import AssessmentForOthers from 'components/pages/feeback/AssessmentForOthers'
import Unsubscribe from 'components/pages/public/Unsubscribe'
import VerifyEmail from 'components/pages/public/VerifyEmail'
import PulseSurveys from 'components/pages/feeback/PulseSurveys'
import PulseSurveyNew from 'components/pages/feeback/PulseSurveyNew'
import PulseSurveyDetail from 'components/pages/feeback/PulseSurveyDetail'
import Assessments from 'components/pages/feeback/Assessments'
import PulseSurveyRate from 'components/pages/feeback/PulseSurveyRate'
import UserFeedback from 'components/pages/feeback/UserFeedBack'
import Home from 'components/pages/Home'
import AssessmentBehaviourDetail from 'components/pages/feeback/AssessmentBehaviourDetail'
import Journey from 'components/pages/journey/Journey'
import JourneyNew from 'components/pages/journey/JourneyNew'
import SendEmailVerification from 'components/pages/user/SendEmailVerification'
import LeadershipModel from 'components/pages/journey/LeadershipModel'
import ContentEditor from 'components/pages/internal/ContentEditor'
import AssessmentSelf from 'components/pages/feeback/AssessmentSelf'
import AssessmentAddRaters from 'components/pages/feeback/AssessmentAddRaters'
import LeadershipShoutOuts from 'components/pages/feeback/TeamshipShoutOuts'
import Confidentiality from 'components/pages/Confidentiality'
import About360 from 'components/pages/About360'
import Bookmarks from 'components/pages/Bookmarks'
import EditProfile from 'components/pages/user/EditProfile'
import UserProfile from 'components/pages/user/UserProfile'
import UserSettings from 'components/pages/user/UserSettings'
import AssessmentBehaviourComparison from 'components/pages/feeback/AssessmentBehaviourComparison'
import Search from 'components/pages/SearchResults'
import HelpAndSupport from 'components/pages/user/HelpAndSupport'
import Onboarding from 'components/pages/user/Onboarding'
import Notebook from 'components/pages/Notebook'
import SubscriptionAdmin from 'components/pages/internal/SubscriptionAdmin'
import CompanyUsers from 'components/pages/adminDashboard/CompanyUsers'
import UserCalendarEvents from 'components/pages/user/UserCalendarEvents'
import Survey from 'components/pages/public/Survey'
import OHEPSurveys from 'components/pages/internal/OHEPSurveys'
import PostGoalQuiz from 'components/pages/public/PostGoalQuiz'
import DemoGoals from 'components/pages/internal/DemoGoals'
import JourneyDemo from 'components/pages/journey/JourneyDemo'
import LearningCheckResults from 'components/pages/internal/LearningCheckResults'
import UserProgressReport from 'components/pages/internal/UserProgressReport'
import UserConnections from 'components/pages/myTeam/UserConnections'
import Contact from 'components/pages/myTeam/Contact'
import UserMeeting from 'components/pages/myTeam/UserMeeting'
import CheckInSurveyPage from 'components/pages/myTeam/CheckInSurveyPage'
import FeatureAdmin from 'components/pages/internal/FeatureAdmin'
import DemoAccounts from 'components/pages/internal/DemoAccounts'
import FeatureRoute from './FeatureRoute'
import { FEATURE_KEYS } from 'services/constants'
import Practice from 'components/pages/practice/Practice'
import RolePlay from 'components/pages/practice/RolePlay'
import AllScenarios from 'components/pages/practice/AllScenarios'
import AssessmentReport from 'components/pages/public/AssessmentReport'
import FullAssessment from 'components/pages/feeback/FullAssessment'

export const RouterConfig = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Routes>
        {/* List all public routes here */}
        <Route exact path={LOGIN} element={<Login />}>
          <Route exact path=':email' element={<Login />} />
        </Route>
        <Route exact path={SIGNUP_DIRECT} element={<SignUp />}>
          <Route exact path=':email' element={<SignUp />}>
            <Route exact path=':assessmentRequestId' element={<SignUp />} />
          </Route>
        </Route>
        <Route exact path={FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route
          exact
          path={`${RESET_PASSWORD}/:token`}
          element={<ResetPassword />}
        />
        <Route exact path={DEEP_LINK_ACTION} element={<DeepLinkAction />} />
        <Route exact path={`${UNSUBSCRIBE}/:data`} element={<Unsubscribe />} />
        <Route exact path={VERIFY_EMAIL} element={<VerifyEmail />} />
        <Route exact path={`${SURVEY}/:token`} element={<Survey />} />
        <Route
          exact
          path={`${POST_GOAL_QUIZ}/:quizId`}
          element={<PostGoalQuiz />}>
          <Route exact path=':optionIndex' element={<PostGoalQuiz />}>
            <Route exact path=':userEmail' element={<PostGoalQuiz />} />
          </Route>
        </Route>
        <Route exact path={ASSESSMENT_REPORT_SHARE} element={<AssessmentReport />} />

        {/* List all private/auth routes here */}
        <Route
          element={
            <PrivateRoute
              permission={PERMISSION_LEVEL.SIGNEDIN}
              redirectPath={LOGIN}
            />
          }>
          <Route exact path={ROOT} element={<Home />} />
          <Route exact path={USER_PROFILE} element={<UserProfile />} />
          <Route exact path={EDIT_PROFILE} element={<EditProfile />} />
          <Route
            exact
            path={SEND_EMAIL_VERIFICATION}
            element={<SendEmailVerification />}
          />
          <Route
            element={
              <FeatureRoute featureKey={FEATURE_KEYS.MY_TEAM_ONE_ON_ONE} />
            }>
            <Route exact path={MY_TEAM} element={<UserConnections />} />
            <Route exact path={`${MY_CONTACT}/:id`} element={<Contact />} />
            <Route exact path={MY_MEETING} element={<UserMeeting />} />
            <Route
              exact
              path={CHECK_IN_SURVEY}
              element={<CheckInSurveyPage />}
            />
          </Route>
          <Route exact path={JOURNEY_DEMO} element={<JourneyDemo />} />
          <Route exact path={HELP_AND_SUPPORT} element={<HelpAndSupport />} />
          <Route
            exact
            path={ABOUT_CONFIDENTIALITY}
            element={<Confidentiality />}
          />
          <Route exact path={ABOUT_360} element={<About360 />} />
          <Route
            exact
            path={`${PULSE_SURVEY_RATE}/:id`}
            element={<PulseSurveyRate />}
          />
          <Route
            exact
            path={`${ASSESSMENT_OTHERS}/:assessmentRequestId`}
            element={<AssessmentForOthers />}
          />
          <Route exact path={USER_FEEBACK} element={<UserFeedback />} />
          <Route
            exact
            path={TEAM_SHOUTOUTS}
            element={<LeadershipShoutOuts />}
          />
          <Route exact path={ONBOARDING} element={<Onboarding />} />
          <Route
            element={
              <PrivateRoute
                permission={PERMISSION_LEVEL.LICENSED}
                redirectPath={ROOT}
              />
            }>
            <Route exact path={HOME} element={<Home />} />
            <Route
              exact
              path={USER_CALENDAR_EVENTS}
              element={<UserCalendarEvents />}
            />
            <Route exact path={JOURNEY} element={<Journey />}>
              <Route exact path=':goalId' element={<Journey />} />
            </Route>
            <Route exact path={JOURNEY_NEW} element={<JourneyNew />} />
            <Route
              exact
              path={LEADERSHIP_MODEL}
              element={<LeadershipModel />}
            />
            <Route exact path={ASSESSMENTS} element={<Assessments />} />
            <Route exact path={ASSESSMENT_SELF} element={<AssessmentSelf />} />
            <Route
              exact
              path={`${ASSESSMENT_BEHAVIOUR}/:goalId/:id/:behaviour`}
              element={<AssessmentBehaviourDetail />}
            />
            <Route
              exact
              path={`${ASSESSMENT_BEHAVIOUR_COMPARISON}/:goalId/:behaviour`}
              element={<AssessmentBehaviourComparison />}
            />
            <Route
              exact
              path={`${ADD_ASSESSMENT_RATERS}/:goalId`}
              element={<AssessmentAddRaters />}
            />
            <Route
              element={
                <FeatureRoute
                  featureKey={FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT}
                />
              }>
              <Route
                exact
                path={FULL_ASSESSMENT}
                element={<FullAssessment />}
              />
            </Route>
            <Route exact path={USER_SETTINGS} element={<UserSettings />} />
            <Route exact path={UNAUTHORIZED} element={<Unauthorized />} />

            <Route exact path={PULSE_SURVEYS} element={<PulseSurveys />} />
            <Route exact path={PULSE_SURVEY_NEW} element={<PulseSurveyNew />} />
            <Route
              exact
              path={`${PULSE_SURVEY_DETAIL}/:id`}
              element={<PulseSurveyDetail />}
            />
            <Route exact path={NOTEBOOK} element={<Notebook />} />
            <Route exact path={BOOKMARKS} element={<Bookmarks />} />
            <Route
              exact
              path={`${SEARCH_RESULTS}/:searchText`}
              element={<Search />}
            />
            <Route
              element={
                <FeatureRoute featureKey={FEATURE_KEYS.ROLE_PLAY_STANDALONE} />
              }>
              <Route exact path={ROLE_PLAY_PRACTISES} element={<Practice />} />
              <Route exact path={ROLE_PLAY} element={<RolePlay />} />
              <Route exact path={ALL_SCENARIOS} element={<AllScenarios />} />
            </Route>
          </Route>
        </Route>

        {/* To use ADMINISTRATOR/COACH role here. Redirect to root page */}
        <Route
          element={
            <PrivateRoute
              permission={PERMISSION_LEVEL.COACH}
              redirectPath={ROOT}
            />
          }>
          <Route exact path={OVERVIEW} element={<Overview />} />

          <Route
            element={
              <PrivateRoute
                permission={PERMISSION_LEVEL.ADMINISTRATOR}
                redirectPath={OVERVIEW}
              />
            }>
            <Route exact path={COMPANY_GENERAL} element={<Company />} />
            <Route exact path={COMPANY_USERS} element={<CompanyUsers />} />
            <Route
              element={
                <PrivateRoute
                  permission={PERMISSION_LEVEL.SUPER_ADMIN}
                  redirectPath={ROOT}
                />
              }>
              {/* To use SUPER_ADMIN role here. Redirect to root page */}
              <Route exact path={CONTENT_EDITOR} element={<ContentEditor />}>
                <Route exact path=':goalId' element={<ContentEditor />} />
              </Route>
              <Route
                exact
                path={SBUSCRIPTION_ADMIN}
                element={<SubscriptionAdmin />}
              />
              <Route exact path={OHEP_SURVEYS} element={<OHEPSurveys />} />
              <Route exact path={GOALS} element={<DemoGoals />} />
              <Route
                exact
                path={CHECK_QUIZZES_RESULT_EXPORT}
                element={<LearningCheckResults />}
              />
              <Route
                exact
                path={USER_PROGRESS_REPORT}
                element={<UserProgressReport />}
              />
              <Route
                exact
                path={CHECK_QUIZZES_RESULT_EXPORT}
                element={<LearningCheckResults />}
              />
              <Route exact path={DEMO_ACCOUNTS} element={<DemoAccounts />} />
            </Route>
          </Route>
          <Route exact path={FEATURES_ADMIN} element={<FeatureAdmin />} />
        </Route>
        {/* List a generic 404-Not Found route here */}
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
    </LocalizationProvider>
  )
}
