import { Box, Grid, Stack, Typography } from '@mui/material'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { Tag } from 'components/atoms/Text'
import RowStack from 'components/atoms/container/RowStack'
import RolePlayCall from 'components/organisms/rolePlay/RolePlayCall'
import RolePlayChat from 'components/organisms/rolePlay/RolePlayChat'
import RolePlayResultList from 'components/organisms/rolePlay/RolePlayResultList'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  ROLE_PLAYS_URL,
  ROLE_PLAY_TYPES,
  VIRTUE_TYPES
} from 'services/constants'
import { ROLE_PLAY_PRACTISES } from 'routes/constants'
import { getRequest } from 'utils/api'
import { getVirtueById } from 'utils/getSupportingInfo'
import ScenarioSettingsDialog from 'components/molecules/rolePlay/ScenarioSettingsDialog'
import RolePlayResults from 'components/organisms/rolePlay/RolePlayResults'
import { updatePageState } from 'utils/windowHistoryHelper'
import RPNotes from 'components/molecules/rolePlay/RPNotes'
import StyledButton from 'components/atoms/button/StyledButton'
import { ColumnItem } from 'components/atoms/container/ColumnItem'
import InCardStack from 'components/atoms/InCardStack'
import { trackEvent } from 'services/amplitudeTracking'

const RolePlayPageCard = ({
  initialRolePlayId,
  initialUserRolePlayId,
  onLearningPath = false,
  pathOpen = false
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [rolePlays, setRolePlays] = useState([])
  const [virtueList, setVirtueList] = useState([])
  const [selectedRolePlay, setSelectedRolePlay] = useState(null)
  const [selectedRolePlayId, setSelectedRolePlayId] = useState(initialRolePlayId)
  const [selectedVirtueId, setSelectedVirtueId] = useState(null)
  const [isStartRP, setIsStartRP] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [displayScenario, setDisplayScenario] = useState(true)
  const [practiceType, setPracticeType] = useState(
    location.state?.practiceType || ROLE_PLAY_TYPES.TEXT_CHAT
  )
  const [loading, setLoading] = useState(true)
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false)
  const [activeUserRolePlayId, setActiveUserRolePlayId] = useState(initialUserRolePlayId)
  const [activeUserRolePlay, setActiveUserRolePlay] = useState(null)
  const [notes, setNotes] = useState('')
  const [isShowResults, setIsShowResults] = useState(false)

  const fetchRolePlay = async id => {
    const rolePlayRes = await getRequest(`${ROLE_PLAYS_URL}/${id}`)
    if (rolePlayRes.status === 200) {
      const rolePlay = rolePlayRes.data.data
      const virtue = await getVirtueById(
        rolePlay.virtueId,
        VIRTUE_TYPES.BEHAVIOUR
      )
      rolePlay.virtue = virtue
      setSelectedRolePlay(rolePlay)
      return rolePlay.virtueId
    } else {
      return null
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await getRequest(ROLE_PLAYS_URL)
      if (res.status === 200) {
        const rolePlaysData = res.data.data
        const behaviorIds = [...new Set(rolePlaysData.map(r => r.virtueId))]
        const behaviorList = await Promise.all(
          behaviorIds.map(async id => {
            const v = await getVirtueById(id, VIRTUE_TYPES.BEHAVIOUR)
            return v
          })
        )
        setRolePlays(rolePlaysData)
        setVirtueList(behaviorList)
      }
      const virtueId = await fetchRolePlay(selectedRolePlayId)
      setSelectedVirtueId(virtueId)
      setLoading(false)
    }

    if (selectedRolePlayId) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRolePlayId])

  const setNewRolePlay = (
    newRolePlayId,
    newPracticeType = ROLE_PLAY_TYPES.TEXT_CHAT
  ) => {
    setSelectedRolePlayId(newRolePlayId)
    setPracticeType(newPracticeType)
    setActiveUserRolePlayId(null)
    setDisplayScenario(true)
    setActiveUserRolePlay(null)
    setIsCompleted(false)
  }

  useEffect(() => {
    if (selectedRolePlay && activeUserRolePlayId) {
      const userRolePlay = selectedRolePlay.userRolePlays.find(
        ur => ur.id === activeUserRolePlayId
      )
      if (userRolePlay) {
        setDisplayScenario(false)
        setActiveUserRolePlay(userRolePlay)
        setPracticeType(userRolePlay.type ?? ROLE_PLAY_TYPES.TEXT_CHAT)
        updatePageState({
          activeUserRolePlayId
        })
      }
    }
  }, [activeUserRolePlayId, selectedRolePlay])

  useEffect(() => {
    if (isStartRP) {
      setDisplayScenario(true)
      setActiveUserRolePlayId(null)
      setActiveUserRolePlay(null)
      trackEvent('Start Role Play', { title: selectedRolePlay.title })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStartRP])

  useEffect(() => {
    if (isCompleted) {
      fetchRolePlay(selectedRolePlayId)
      trackEvent('Complete Role Play', { title: selectedRolePlay.title })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted])

  useEffect(() => {
    if (location.state) {
      setSelectedRolePlayId(location.state.rolePlayId)
      setPracticeType(location.state.practiceType ?? ROLE_PLAY_TYPES.TEXT_CHAT)
      setDisplayScenario(true)
    }
  }, [location.state])

  useEffect(() => {
    setIsShowResults(!displayScenario && activeUserRolePlay)
  }, [displayScenario, activeUserRolePlay])

  const handleVirtueChange = e => {
    const rolePlay = rolePlays.find(r => r.virtueId === e.target.value)
    setSelectedVirtueId(e.target.value)
    setSelectedRolePlayId(rolePlay.id)
  }

  const widthRightPanel =
    isStartRP && practiceType !== ROLE_PLAY_TYPES.LIVE_CALL
      ? 6
      : onLearningPath && pathOpen
        ? 4
        : 3

  return (
    <>
      <OverlayLoading open={loading} />
      <Grid container height='inherit' columnSpacing={10} paddingBottom={5}>
        <ColumnItem md={12 - widthRightPanel}>
          { !onLearningPath &&
            <BackIconTextButton
              onClick={() => navigate(ROLE_PLAY_PRACTISES)}
            />
          }
          {displayScenario && selectedRolePlay && (
            <>
              <Stack padding={0} gap={5}>
                <Typography variant='eyebrow2' sx={{ marginBottom: '-10px' }}>
                  {t('rolePlay.scenario')}
                </Typography>
                <RowStack justifyContent={'space-between'}>
                  <InCardStack
                    spacing={3}
                    alignItems={'start'}
                    maxWidth={'55%'}>
                    <Typography variant='h1' paddingRight={2}>
                      {selectedRolePlay.title}
                    </Typography>
                    <RowStack>
                      <Tag>{selectedRolePlay.virtue?.name}</Tag>
                      <Tag>
                        {t(
                          practiceType === ROLE_PLAY_TYPES.LIVE_CALL
                            ? 'rolePlay.liveAICall'
                            : 'rolePlay.aiChat'
                        )}
                      </Tag>
                    </RowStack>
                  </InCardStack>

                  {!isStartRP && (
                    <StyledButton onClick={() => setSettingsDialogOpen(true)}>
                      {t('rolePlay.scenarioSettings')}
                    </StyledButton>
                  )}
                </RowStack>
              </Stack>

              <Box
                dangerouslySetInnerHTML={{
                  __html: selectedRolePlay.context?.html
                }}
              />
            </>
          )}

          {isShowResults && (
            <RolePlayResults
              selectedRolePlay={selectedRolePlay}
              selectedUserRolePlay={activeUserRolePlay}
              rolePlays={rolePlays}
              onLearningPath={onLearningPath}
              setNewRolePlay={setNewRolePlay}
            />
          )}
        </ColumnItem>
        {!loading && (
          <ColumnItem md={widthRightPanel}>
            {practiceType === ROLE_PLAY_TYPES.LIVE_CALL ? (
              <RolePlayCall
                rolePlay={selectedRolePlay}
                notes={notes}
                isStarted={isStartRP}
                setIsStarted={setIsStartRP}
                isCompleted={isCompleted}
                setIsCompleted={setIsCompleted}
                setActiveUserRolePlayId={setActiveUserRolePlayId}
              />
            ) : (
              <RolePlayChat
                rolePlay={selectedRolePlay}
                isStarted={isStartRP}
                setIsStarted={setIsStartRP}
                isCompleted={isCompleted}
                setIsCompleted={setIsCompleted}
                setActiveUserRolePlayId={setActiveUserRolePlayId}
                notes={notes}
              />
            )}
            {!isCompleted && !isShowResults &&
              <RPNotes notes={notes} setNotes={setNotes} />
            }
            {!isStartRP && (
              <RolePlayResultList
                cardSizeMD={12}
                rolePlayId={selectedRolePlayId}
                virtueId={selectedVirtueId}
                setActiveUserRolePlayId={setActiveUserRolePlayId}
                activeUserRolePlayId={activeUserRolePlayId}
              />
            )}
          </ColumnItem>
        )}
      </Grid>

      <ScenarioSettingsDialog
        open={settingsDialogOpen}
        titleText={t('rolePlay.practiceSettings')}
        handleClose={() => setSettingsDialogOpen(false)}
        virtueList={virtueList}
        selectedVirtueId={selectedVirtueId}
        handleVirtueChange={handleVirtueChange}
        selectedRolePlayId={selectedRolePlayId}
        setSelectedRolePlayId={setSelectedRolePlayId}
        rolePlays={rolePlays}
        practiceType={practiceType}
        setPracticeType={setPracticeType}
        onLearningPath={onLearningPath}
      />
    </>
  )
}

export default RolePlayPageCard
