import { TableCell, Button } from '@mui/material'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import OverlayLoading from 'components/atoms/OverlayLoading'
import PresetTableContainer from 'components/atoms/table/PresetTableContainer'
import CohortUserProgressChart from 'components/molecules/adminDashboard/CohortUserProgreessChart'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import {
  COHORT_GOAL_FACILITATION_URL,
  GOAL_STEP_URL,
  USER_COMPLETED_STEPS_URL
} from 'services/constants'
import { getRequest } from 'utils/api'
import TimelinePaceRows from 'components/molecules/adminDashboard/insights/TimelinePaceRows'
import { useTranslation } from 'react-i18next'

const CohortUserProgress = ({ cohortSession, showExport = true }) => {
  const { t } = useTranslation()
  const [userCompletedSteps, setUserCompletedSteps] = useState([])
  const [goalSteps, setGoalSteps] = useState([])
  const [goalTimeline, setGoalTimeline] = useState([])
  const [activeStepNumber, setactiveStepNumber] = useState(null)
  const [loading, setLoading] = useState(false)
  const tableRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const [stepRes, goalRes, facRes] = await Promise.all([
        getRequest(USER_COMPLETED_STEPS_URL, {
          cohortSessionId: cohortSession.id
        }),
        getRequest(GOAL_STEP_URL, {
          goalId: cohortSession.goalId
        }),
        getRequest(COHORT_GOAL_FACILITATION_URL, {
          cohortSessionId: cohortSession.id
        })
      ])
      if (
        stepRes.status === 200 &&
        goalRes.status === 200 &&
        facRes.status === 200
      ) {
        const stepData = [
          ...goalRes.data.data.filter(s => s.milestone_level !== 3)
        ]
        const l2Steps = stepData.filter(s => s.milestone_level === 2)
        const stepsLength = l2Steps.length
        l2Steps.forEach((s, i) => {
          s.percentage = ((i + 1) * 100) / stepsLength
        })
        stepData.forEach((s, i) => {
          s.stepIndex = i
          if (s.milestone_level === 1) {
            if (i === 0) {
              s.percentage = 0
            } else {
              s.percentage = stepData[i - 1].percentage
            }
          }
        })
        const timelineData = facRes.data.data
        const mileStoneSteps = []
        if (timelineData.length > 0) {
          timelineData
            .filter(t => moment.utc(t.facilitationDate) <= moment.utc())
            .forEach(t => {
              const step = stepData.find(
                s => s.id === t.goalTimeline.endGoalStepId
              )
              step.facilitationDate = t.facilitationDate
              step.facilitator = t.user
              step.sessionLabel = t.goalTimeline.label
              step.timelineOrder = t.goalTimeline.number
              mileStoneSteps.push(step)
            })
        }
        // Find where users are on facilitation date
        const completedData = stepRes.data.data.sort(
          (a, b) => moment.utc(b.createdAt) - moment.utc(a.createdAt)
        )
        const userIds = [...new Set(completedData.map(c => c.userId))]
        const completedSteps = []
        userIds.forEach(userId => {
          mileStoneSteps.forEach(s => {
            const completedBeforeFacilitation = completedData.find(
              c =>
                c.userId === userId &&
                moment.utc(c.createdAt) <= moment.utc(s.facilitationDate)
            )
            if (completedBeforeFacilitation) {
              const userCompleteData = {
                ...completedBeforeFacilitation,
                milestoneStep: s
              }
              const completedStep = stepData.find(
                cs => cs.id === completedBeforeFacilitation.goalStepId
              )
              if (completedStep) {
                if (completedStep.stepIndex < s.stepIndex - 1) {
                  userCompleteData.status = 'Behind'
                  userCompleteData.goalStepNumber = completedStep.number
                  userCompleteData.completedStepName = completedStep.name
                  userCompleteData.completedPercentage =
                    completedStep.percentage
                } else {
                  userCompleteData.status = 'On Time'
                  userCompleteData.goalStepNumber = s.number
                  userCompleteData.completedStepName = s.name
                  userCompleteData.completedPercentage = s.percentage
                }
                completedSteps.push(userCompleteData)
              }
            }
          })
        })
        stepData.forEach(s => {
          s.completedUsers = completedSteps.filter(
            c => c.goalStepNumber === s.number
          )
        })
        setGoalSteps(stepData)
        setUserCompletedSteps(
          completedSteps.sort(
            (a, b) => a.completedPercentage - b.completedPercentage
          )
        )
        setGoalTimeline(
          mileStoneSteps.sort((a, b) => a.timelineOrder - b.timelineOrder)
        )
      } else {
        setUserCompletedSteps([])
      }
      setLoading(false)
    }
    if (cohortSession) {
      setLoading(true)
      fetchData()
    }
  }, [cohortSession])

  return (
    <>
      {showExport && (
        <DownloadTableExcel
          filename={`Goal-${cohortSession?.goalId}-user-progress-report`}
          sheet='sheet1'
          currentTableRef={tableRef.current}>
          <Button>Export</Button>
        </DownloadTableExcel>
      )}

      {userCompletedSteps.length > 0 ? (
        <CohortUserProgressChart
          goalSteps={goalSteps}
          progressData={userCompletedSteps}
          selectedCallback={selectedOrder =>
            setactiveStepNumber(selectedOrder)
          }
        />
      ) : null}

      <PresetTableContainer
        maxHeight='400px'
        tableProps={{ ref: tableRef }}
        shorterRow={true}
        headers={
          <>
            <TableCell>{t('insights.user')}</TableCell>
            <TableCell>{t('pulseSurvey.status')}</TableCell>
            <TableCell>{t('insights.completedStep')}</TableCell>
            <TableCell>{t('insights.percentageCompleted')}</TableCell>
          </>
        }>
        {goalTimeline.map(t => (
          <TimelinePaceRows
            key={`timeline-${t.id}`}
            timeline={t}
            userCompletedSteps={userCompletedSteps.filter(
              u => u.milestoneStep.timelineOrder === t.timelineOrder
            )}
            activeStepNumber={activeStepNumber}
          />
        ))}
      </PresetTableContainer>
      <OverlayLoading open={loading} />
    </>
  )
}

export default CohortUserProgress
