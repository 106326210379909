import { Box, Grid, useTheme } from '@mui/material'

const ShadedScoreDisplayBar = props => {
  const theme = useTheme()
  const {
    backgroundColor = theme.palette.background.default,
    width,
    height,
    borderRadius = '10px',
    labelComponent,
    scoreComponent,
    paddingX = '10px',
    paddingY = '5px'
  } = props
  return (
    <Box
      display='flex'
      alignItems='center'
      width={width}
      height={height}
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        paddingY,
        paddingX
      }}>
      {labelComponent}
      <Grid item flex={1} display='inline-flex' justifyContent='right'>
        {scoreComponent}
      </Grid>
    </Box>
  )
}

export default ShadedScoreDisplayBar
