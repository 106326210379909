import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useEffect, useState } from 'react'
import colors from 'styles/colors'
import { logScreenAction, trackingSubScreenKey } from 'services/trackingScreenActions'

const StyledAccordion = ({
  children,
  initialExpanded,
  summaryComponent,
  detailProps,
  trackingEvent,
  trackingScreenKey = trackingSubScreenKey,
  summaryProps,
  ...otherProps
}) => {
  const [expanded, setExpanded] = useState(initialExpanded)
  useEffect(() => {
    setExpanded(initialExpanded)
  }, [initialExpanded])

  const handleExpand = () => {
    if (!expanded && trackingEvent){
      logScreenAction(`${trackingEvent}_expand`, null, trackingScreenKey)
    }
    setExpanded(!expanded)
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      sx={{
        width: '100%',
        boxShadow: 'none'
      }}
      {...otherProps}>
      <AccordionSummary
        {...summaryProps}
        expandIcon={<ExpandMoreIcon sx={{ color: colors.Aluminium }} />}
        aria-controls='panel1a-content'>
        {summaryComponent}
      </AccordionSummary>
      <AccordionDetails {...detailProps}>{children}</AccordionDetails>
    </Accordion>
  )
}

export default StyledAccordion
