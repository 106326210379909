import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import { postRequest } from 'utils/api'
import { USER_LICENSES_URL } from 'services/constants'
import { useState } from 'react'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useTranslation } from 'react-i18next'

const UpgradeUserButton = ({
  successCallback,
  user,
  organizationId,
  disabled
}) => {
  const { t } = useTranslation()
  const [sendDialogOpen, setSendDialogOpen] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleDialogSelect = async confirmed => {
    if (confirmed) {
      setLoading(true)
      const res = await postRequest(USER_LICENSES_URL, {
        organizationId,
        userIds: [user.id]
      })
      if (res.status === 201) {
        setSendDialogOpen(false)
        await successCallback(user.id)
      } else setRequestError(true)
      setLoading(false)
    } else {
      setSendDialogOpen(false)
    }
  }
  return (
    <>
      <Tooltip title={disabled ? t('company.noLicenseAvailable') : t('company.upgradeToFullAccess')}>
        <Box>
          <IconButton
            disabled={disabled}
            onClick={() => setSendDialogOpen(true)}
            data-testid={`upgrade-user-${user.id}`}>
            <ArrowCircleUpOutlinedIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <ConfirmCancelDialog
        open={sendDialogOpen}
        titleText={t('company.upgradeUser')}
        onClose={handleDialogSelect}
        confirmText={t('company.yesUpgradeUser')}
        cancelText={t('common.cancel')}
        buttonsDirection='row'>
        <Typography>
          {t('company.willUse1License')}
        </Typography>
        {requestError ? <RequestRetryAlert /> : null}
      </ConfirmCancelDialog>
      <OverlayLoading open={loading} />
    </>
  )
}

export default UpgradeUserButton
