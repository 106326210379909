import SubscriptionAdminCard from 'components/organisms/SubscriptionAdminCard'
import InternalPage from 'components/templates/navigationPageTemplates/InternalPage'
import { SBUSCRIPTION_ADMIN } from 'routes/constants'

const SubscriptionAdmin = () => {
  return (
    <InternalPage
      activePage={SBUSCRIPTION_ADMIN}
      pageTitle='Subscription Admin'
      >
        <SubscriptionAdminCard />
      </InternalPage>
  )
}

export default SubscriptionAdmin
