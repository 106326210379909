import { IconButton, Typography } from '@mui/material'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import RowStack from 'components/atoms/container/RowStack'
import InCardStack from 'components/atoms/InCardStack'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'utils/formatDateTime'
import { getFullName } from 'utils/formatText'
import { getUserConnectionLabel } from 'utils/getSupportingInfo'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import { MY_CONTACT, MY_TEAM } from 'routes/constants'
import { useTranslation } from 'react-i18next'

const ConnectionUserItem = ({ c }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  return (
    <BoxWithBorder
      borderWidth={1}
      paddingY={3}
      paddingX={8}
      width='100%'
      minWidth={225}>
      <RowStack justifyContent='space-between'>
        <RowStack width='calc(100% - 60px)'>
          <ProfileAvatar user={c.user} />
          <InCardStack alignItems='left' spacing={0} sx={{ overflow: 'hidden'}}>
            <Typography
              sx={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {getFullName(c.user)}
            </Typography>
            <Typography variant='body2'>
              {getUserConnectionLabel(c.relationship, t)}
            </Typography>
            <Typography variant='body2'>
              {t('myTeam.lastUpdate')}: {formatDate(c.updatedAt, i18n.resolvedLanguage)}
            </Typography>
          </InCardStack>
        </RowStack>
        <IconButton
          size='small'
          sx={{ border: '1px solid' }}
          data-testid={`contact-button-${c.id}`}
          onClick={() => navigate(`${MY_CONTACT}/${c.id}`, {state: {from: MY_TEAM}})}>
          <KeyboardArrowRightOutlinedIcon fontSize='large' />
        </IconButton>
      </RowStack>
    </BoxWithBorder>
  )
}

export default ConnectionUserItem
