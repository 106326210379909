import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import {
  Alert,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import LinkRoute from 'components/atoms/LinkRoute'
import {
  FORGOT_PASSWORD,
  PERMISSION_LEVEL,
  OVERVIEW,
  HOME,
  ONBOARDING
} from 'routes/constants'
import { LOGIN_TYPES, USER_ROLE_TYPES } from 'services/constants'
import { MONARK_SUPPORT_EMAIL } from 'utils/constants'
import { getUserSigninMessage, signin, signout } from 'services/userServices'
import { permitted, isAllowedFeature } from 'utils/permissionCheck'
import { loginValidationSchema } from 'utils/formValidations'
import {
  EmailInputBox,
  PasswordInputBox
} from 'components/atoms/input/FormInputBox'
import {
  getGlobalStorageItem,
  setGlobalStorageItem,
  delGlobalStorageItem
} from 'utils/localStorage'
import { getLocalUser } from 'utils/localStorage'
import RowStack from 'components/atoms/container/RowStack'
import FloatingCard from 'components/atoms/container/FloatingCard'
import OverlayLoading from 'components/atoms/OverlayLoading'
import ThirdPartyLoginStack from 'components/molecules/oAuth/ThirdPartyLoginStack'
import { ActionText } from 'components/atoms/Text'
import { useTranslation } from 'react-i18next'
import { LANGUAGE_OPTIONS, LANGUAGES } from 'utils/constants'
import { multilingualSwitch } from 'config/config'
import LanguageIcon from '@mui/icons-material/Language'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import StyledButton from 'components/atoms/button/StyledButton'
import RememberMeToggle from 'components/molecules/RememberMeToggle'
import { FEATURE_KEYS } from 'services/constants'

const EMAIL_STORAGE_KEY = 'login-email'

const LoginCard = props => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [error, setError] = useState(false)
  const [isRememberMe, setIsRememberMe] = useState(false)
  const [errorMessage, setErrorMessage] = useState(
    t('authPages.invalidLoginCredentials')
  )
  const [loading, setLoading] = useState(false)

  const from = location.state?.from
  const signinCallback = res => {
    const msg = getUserSigninMessage(res)
    if (msg.success) {
      // SET USER LANGUAGE
      i18n.changeLanguage(
        res.data.data?.userStates?.preferLanguage ?? LANGUAGES.EN
      )
      sessionStorage.clear()

      if (from) {
        navigate(from, { state: location.state })
      } else if (
        res.data.data.role !== USER_ROLE_TYPES.RESTRICTED &&
        !res.data.data.hasLicense &&
        !isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT)
      ) {
        navigate(OVERVIEW)
      } else if (res.data.data.isNewUser) {
        navigate(ONBOARDING)
      } else {
        navigate(HOME)
      }
    } else {
      setError(true)
      if (res.status !== 422) {
        setErrorMessage(msg.text)
      }
    }
    setLoading(false)
  }

  //Go home if logged in  with the account already
  useEffect(() => {
    if (permitted(PERMISSION_LEVEL.SIGNEDIN)) {
      const userInfo = getLocalUser()
      if (!props.email || props.email === userInfo.email) {
        if (from) {
          navigate(from, { state: location.state })
        } else if (isAllowedFeature(FEATURE_KEYS.FULL_LEADERSHIP_ASSESSMENT)) {
          navigate(HOME)
        } else {
          navigate(OVERVIEW)
        }
      } else {
        // If not same account, clear session and ask to login
        signout()
      }
    }

    if (getGlobalStorageItem(EMAIL_STORAGE_KEY)) {
      setIsRememberMe(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formik = useFormik({
    initialValues: {
      email: props.email || getGlobalStorageItem(EMAIL_STORAGE_KEY) || '',
      password: ''
    },
    validationSchema: loginValidationSchema,
    onSubmit: async values => {
      setLoading(true)
      const email = values.email.trim()
      const requestBody = {
        email,
        password: values.password,
        login_type: LOGIN_TYPES.PASSWORD
      }
      const res = await signin(requestBody)
      signinCallback(res)
      if (isRememberMe) {
        setGlobalStorageItem(EMAIL_STORAGE_KEY, email)
      } else {
        delGlobalStorageItem(EMAIL_STORAGE_KEY)
      }
    }
  })

  return (
    <>
      <FloatingCard>
        <form onSubmit={formik.handleSubmit} id='login-form'>
          <InCardStack spacing={20}>
            <Stack padding={0} spacing={10} width='100%'>
              <EmailInputBox formik={formik} />
              <PasswordInputBox formik={formik} />
              <RowStack spacing={0} justifyContent='space-between'>
                <RememberMeToggle
                  isRememberMe={isRememberMe}
                  setIsRememberMe={setIsRememberMe}
                />
                <LinkRoute to={FORGOT_PASSWORD}>
                  <Typography variant='body1Small' color={colors.Teal400}>
                    {t('authPages.forgotPassword')}
                  </Typography>
                </LinkRoute>
              </RowStack>

              {error === true ? (
                <CardContent sx={{ paddingY: 0 }}>
                  <Alert severity='error'>{errorMessage}</Alert>
                </CardContent>
              ) : null}
            </Stack>
            <StyledButton
              sx={{ width: '100%' }}
              type='submit'
              form='login-form'>
              {t('authPages.logIn')}
            </StyledButton>
            <ThirdPartyLoginStack
              labelPrefix={t('authPages.loginWith')}
              signinCallback={signinCallback}
            />
          </InCardStack>
        </form>
      </FloatingCard>
      <Typography variant='body1Small' color={colors.LightGrey400}>
        {t('authPages.dontHaveAccount')}
        <ActionText fontSize={13} href={`mailto:${MONARK_SUPPORT_EMAIL}`}>
          {t('authPages.contactUs')}
        </ActionText>
      </Typography>

      {multilingualSwitch ? (
        <CardContent sx={{ marginTop: 15, alignItems: 'center' }}>
          <LanguageIcon />
          <FormControl>
            <Select
              sx={{ marginLeft: 5, paddingLeft: 2, minWidth: 120, height: 30 }}
              value={i18n.resolvedLanguage}
              onChange={e => {
                i18n.changeLanguage(e.target.value)
                sessionStorage.clear()
              }}>
              {LANGUAGE_OPTIONS.map(l => (
                <MenuItem key={`language-option-${l.value}`} value={l.value}>
                  {t(l.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      ) : null}
      <OverlayLoading open={loading} />
    </>
  )
}
export default LoginCard
