export const requestSort = (
  data,
  currentSort,
  sortOrder,
  pSortBy,
  setCurrentSort,
  setSortOrder,
  setDisplayData,
  clickAction = true
) => {
  let newSortOrder = 'asc'
  if (pSortBy === currentSort && sortOrder === 'asc') {
    newSortOrder = 'desc'
  }
  if (!clickAction) {
    newSortOrder = sortOrder
  } else {
    setCurrentSort(pSortBy)
  }
  setSortOrder(newSortOrder)
  const sorted = data.sort((a, b) => {
    if (newSortOrder === 'asc') {
      return a[pSortBy] === null ||
        a[pSortBy] === undefined ||
        a[pSortBy] > b[pSortBy]
        ? 1
        : -1
    } else {
      return a[pSortBy] === null ||
        a[pSortBy] === undefined ||
        a[pSortBy] < b[pSortBy]
        ? 1
        : -1
    }
  })
  setDisplayData(sorted)
}
