import React, { useState } from 'react'
import { ButtonBase, Stack, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import { MY_MEETING } from 'routes/constants'
import StyledButton from 'components/atoms/button/StyledButton'
import RowStack from 'components/atoms/container/RowStack'
import BlankRect from 'components/atoms/BlankRect'
import InCardStack from 'components/atoms/InCardStack'
import { SurveyIcon } from 'components/atoms/Icons'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'
import moment from 'moment'
import { ContactNotesPopup } from './ContactNotes'

const EventRowCard = ({
  name,
  nameRel,
  isNotes,
  isPast,
  userConnectionId,
  noteRefreshCallback,
  isBlank = false,
  event
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [notesPopupOpen, setNotesPopupOpen] = useState(false)

  const hasNotes =
    event?.notes ||
    event?.userConnectionSharedNotes ||
    event?.userSharedNotes
  const userCalendarEventId = event?.id

  const startTime = moment(event?.startTime)
  const hour = startTime?.format('HH:mm')
  const day = startTime?.format('DD')
  const month = startTime?.format('MMM').toUpperCase()
  const strStartTime = startTime?.format('h:mm a')
  const strCeaseTime = event?.endTime
    ? moment(event.endTime)?.format('h:mm a')
    : null

  return (
    <BoxWithBackground
      borderRadius={2}
      sx={{ width: '100%', padding: 6 }}
      backgroundColor={theme.palette.background.default}>
      <RowStack spacing={0} justifyContent='space-between'>
        <ButtonBase
          sx={{
            width: '100%'
          }}
          disabled={!isPast && !isNotes}
          onClick={() =>
            isNotes
              ? setNotesPopupOpen(true)
              : navigate(MY_MEETING, {
                  state: {
                    userConnectionId,
                    userCalendarEventId
                  }
                })
          }>
          <RowStack spacing={8}>
            {isBlank ? (
              <BlankRect w={40} h={40} />
            ) : (
              <InCardStack spacing={0}>
                <Typography variant='h3'>{day}</Typography>
                <Typography variant='eyebrow'>{month}</Typography>
                {isNotes && (
                  <Typography
                    variant='eyebrow2'
                    fontWeight={400}
                    paddingTop={4}>
                    {hour}
                  </Typography>
                )}
              </InCardStack>
            )}

            <Stack
              padding={0}
              spacing={isBlank ? 2 : 0}
              sx={{ overflow: 'hidden' }}>
              {isBlank ? (
                <>
                  <BlankRect w={150} h={20} />
                  <BlankRect w={130} h={15} />
                </>
              ) : (
                <>
                  <Typography
                    variant='h4'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textWrap: 'nowrap',
                      textAlign: 'left'
                    }}>
                    {isNotes ? event.title : `${nameRel} / ${name}`}
                  </Typography>
                  <Typography
                    variant='body1Small'
                    sx={{
                      color: isNotes ? colors.LightGrey400 : colors.DarkGrey,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre-line',
                      textAlign: 'left',
                      maxHeight: 40
                    }}>
                    {isNotes
                      ? event.notes
                      : `${strStartTime} - ${strCeaseTime}`}
                  </Typography>
                </>
              )}
            </Stack>
          </RowStack>
        </ButtonBase>

        {isBlank || isNotes ? null : isPast ? (
          <>
            {hasNotes && (
              <StickyNote2OutlinedIcon
                sx={{
                  fontSize: 24,
                  color: colors.LightGrey400
                }}
              />
            )}
            {event.surveyResults && <SurveyIcon />}
          </>
        ) : userCalendarEventId ? (
          <StyledButton
            onClick={() =>
              navigate(MY_MEETING, {
                state: {
                  userConnectionId,
                  userCalendarEventId
                }
              })
            }
            size='small'>
            {t('common.view')}
          </StyledButton>
        ) : null}
      </RowStack>
      <ContactNotesPopup
        note={event}
        open={notesPopupOpen}
        completeCallback={updated => {
          if (updated) {
            noteRefreshCallback()
          }
          setNotesPopupOpen(false)
        }}
      />
    </BoxWithBackground>
  )
}

export default EventRowCard
