import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { getRequest, postRequest } from 'utils/api'
import {
  COHORTS_URL,
  INVITED_LIST_URL,
  LEADERSHIP_MODELS_URL,
  USER_ROLE_TYPES
} from 'services/constants'
import { useEffect, useState } from 'react'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import OverlayLoading from 'components/atoms/OverlayLoading'
import SuccessfulNotificationDialog from '../notificationOverlay/SuccessfulNotificationDialog'
import { getGoals, userRoleMenuItems } from 'utils/getSupportingInfo'
import ToolTipCustomized from 'components/atoms/container/ToolTipCustomized'
import RichTextContentEditor from '../journey/RichTextEditor'
import moment from 'moment'
import UserInviteForm from 'components/atoms/input/UserInviteForm'
import { useTranslation } from 'react-i18next'
import { permitted } from 'utils/permissionCheck'
import { PERMISSION_LEVEL } from 'routes/constants'
import colors from 'styles/colors'

const InviteMoreButton = ({
  organizationId,
  licensesAvailable,
  successCallback,
  licenseInvite = true,
  isSuperAdminView = false
}) => {
  const { t } = useTranslation()
  const [emails, setEmails] = useState([])
  const [sendDialogOpen, setSendDialogOpen] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [customText, setCustomText] = useState('')
  const [selectedPath, setSelectedPath] = useState('')
  const [goalData, setGoalData] = useState([])
  const [userRole, setUserRole] = useState(
    licenseInvite ? USER_ROLE_TYPES.RESTRICTED : USER_ROLE_TYPES.ADMINISTRATOR
  )
  const [cohorts, setCohorts] = useState([])
  const [selectedCohort, setSelectedCohort] = useState('')
  const [customTitle, setCustomTitle] = useState('')
  const [useEditor, setUseEditor] = useState('0')
  const [licensedPaths, setLicensePaths] = useState([])
  const [inviteError, setInviteError] = useState(false)

  useEffect(() => {
    const fetchGoals = async () => {
      // Need to use leadership goals for permission filtering here
      let queryParams = {}
      if (permitted(PERMISSION_LEVEL.SUPER_ADMIN)) {
        queryParams = { includeFeatures: true }
      }
      const res = await getRequest(LEADERSHIP_MODELS_URL, queryParams)
      const goalIds = []
      if (res.status === 200) {
        res.data.data.forEach(l => goalIds.push(...l.goals.map(g => g.id)))
      }
      const goals = await getGoals()
      setGoalData(goals.filter(g => g.available && goalIds.indexOf(g.id) > -1))
    }
    const fetchCohorts = async () => {
      const res = await getRequest(COHORTS_URL, { organizationId })
      if (res.status === 200) {
        const cohorrtRes = res.data.data
        const currentTime = moment.utc()
        const processedCohort = cohorrtRes.map(c => {
          const r = { ...c }
          c.cohortSessions.forEach(s => {
            if (
              moment.utc(s.startDate) < currentTime &&
              moment.utc(s.endDate) > currentTime
            ) {
              r.activeSessionGoal = s.goalId
            }
          })
          if (!r.activeSessionGoal) {
            r.activeSessionGoal = c.cohortSessions[0]?.goalId
          }
          return r
        })
        setCohorts(processedCohort)
      }
    }
    if (sendDialogOpen && goalData.length === 0 && licenseInvite) {
      fetchGoals()
    }
    if (isSuperAdminView && cohorts.length === 0) {
      fetchCohorts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendDialogOpen])

  const resetInviteForm = () => {
    setCustomTitle('')
    setCustomText('')
    setEmails([])
    setRequestError(null)
    setSelectedPath('')
    setUserRole(
      licenseInvite ? USER_ROLE_TYPES.RESTRICTED : USER_ROLE_TYPES.ADMINISTRATOR
    )
    setSelectedCohort('')
    setLicensePaths([])
    setUseEditor('0')
    setInviteError('')
  }

  const invitingAdmin = !licenseInvite
  const handleDialogSelect = async confirmed => {
    if (confirmed) {
      if (emails.length === 0) {
        setInviteError(t('company.enterValidEmailValidation'))
      } else {
        setLoading(true)
        setSendDialogOpen(false)
        setInviteError('')
        const requestBody = {
          customText: customText.length > 0 ? customText : undefined,
          invitees: emails,
          goalId:
            selectedCohort === '' && selectedPath ? selectedPath : undefined,
          subscriptionOrganizationId: licenseInvite
            ? organizationId
            : undefined,
          organizationId:
            invitingAdmin || userRole === USER_ROLE_TYPES.ADMINISTRATOR
              ? organizationId
              : undefined,
          userRole:
            userRole === USER_ROLE_TYPES.ADMINISTRATOR ? userRole : undefined,
          customSubject: customTitle.length > 0 ? customTitle : undefined,
          licensedGoalIds: licensedPaths.length > 0 ? licensedPaths : undefined
        }
        if (isSuperAdminView && selectedCohort !== '') {
          requestBody.cohortId = selectedCohort
        }
        const res = await postRequest(INVITED_LIST_URL, requestBody)
        if (res.status === 201) {
          await successCallback()
          setSuccessDialogOpen(true)
          resetInviteForm()
        } else {
          setRequestError(true)
          setSendDialogOpen(true)
        }
        setLoading(false)
      }
    } else {
      setSendDialogOpen(false)
      resetInviteForm()
    }
  }

  const FieldTitle = ({ titleText, tooltipTitle, description }) => (
    <Typography
      paddingTop={5}
      variant='h5'
      color={colors.Aluminium}
      alignContent='baseline'
      display='flex'>
      {titleText}
      {tooltipTitle ? (
        <ToolTipCustomized
          toolTip={
            <Stack spacing={2} padding={5}>
              <Typography color={colors.White} variant='h5'>
                {tooltipTitle}
              </Typography>
              <Typography color={colors.White} variant='body1'>
                {description}
              </Typography>
            </Stack>
          }
        />
      ) : null}
    </Typography>
  )
  return (
    <>
      <Button
        sx={{ width: 'fit-content' }}
        disabled={licensesAvailable <= 0}
        onClick={() => setSendDialogOpen(true)}>
        {licenseInvite
          ? t('company.inviteLicensedUsers')
          : t('company.inviteAdministrativeUsers')}
      </Button>
      <ConfirmCancelDialog
        open={sendDialogOpen}
        titleText={
          licenseInvite
            ? `${t('company.inviteLicensedUsers')} (${licensesAvailable} ${t('company.licensesRemaining')})`
            : t('company.inviteAdministrativeUsers')
        }
        onClose={handleDialogSelect}
        maxWidth='xl'
        confirmText={t('common.send')}
        cancelText={t('common.cancel')}
        buttonsDirection='row'
        errorAlertMessage={inviteError}>
        <Stack padding={0} spacing={4} width={isSuperAdminView ? 800 : 500}>
          {!licenseInvite ? (
            <Typography paddingBottom={5}>
              {t('company.administrativeUsersAccessAdminDashboard')}
            </Typography>
          ) : null}
          <FieldTitle titleText={`${t('pulseSurvey.recipients')} *`} />
          <UserInviteForm
            emails={emails}
            setEmails={setEmails}
            disabled={licenseInvite && emails.length === licensesAvailable}
            isSuperAdminView={isSuperAdminView}
          />
          {licenseInvite ? (
            <>
              <FieldTitle
                titleText={`${t('overview.learningPath')} (${t('company.optional')})`}
                tooltipTitle={t('overview.learningPath')}
                description={t('company.ifYouIncludeLearningPath')}
              />
              <TextField
                name='path'
                type='text'
                value={selectedPath}
                select
                disabled={Boolean(selectedCohort)}
                onChange={e => setSelectedPath(e.target.value)}
                data-testid='path-select'
                inputProps={{ 'data-testid': 'path-select-input' }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue:
                    selectedPath === ''
                      ? () => (
                          <div style={{ opacity: 0.4 }}>
                            {t('company.selectLearningPath')}
                          </div>
                        )
                      : undefined
                }}>
                {goalData.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.leadershipPathName}
                  </MenuItem>
                ))}
              </TextField>
              <FieldTitle
                titleText={`${t('profile.role')} (${t('company.optional')}) `}
                tooltipTitle={t('profile.role')}
                description={t('company.byDefaultAssignedTheRoleOfMember')}
              />
              <TextField
                name='role'
                type='text'
                value={userRole}
                select
                onChange={e => setUserRole(e.target.value)}
                data-testid='role-select'
                sx={{ width: '150px' }}
                inputProps={{ 'data-testid': 'role-select-input' }}>
                {userRoleMenuItems(false).map(r => (
                  <MenuItem key={r.key} value={r.value}>
                    {t(`company.${r.label}`)}
                  </MenuItem>
                ))}
              </TextField>
              {isSuperAdminView ? (
                <>
                  <FieldTitle
                    titleText={`${t('company.licensedGoals')} (${t('company.toBeUsedOneRange')}) `}
                    tooltipTitle={t('company.licensedGoals')}
                    description={t('company.licensedGoalsDescription')}
                  />
                  <Select
                    multiple
                    value={licensedPaths}
                    onChange={e => setLicensePaths(e.target.value)}>
                    {goalData.map(option => (
                      <MenuItem
                        key={`licensed-path-option-${option.id}`}
                        value={option.id}>
                        <Checkbox
                          checked={licensedPaths.indexOf(option.id) > -1}
                        />
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FieldTitle
                    titleText={`${t('overview.cohort')} (${t('company.optional')}) `}
                    tooltipTitle={t('overview.cohort')}
                    description={t(
                      'company.cohortGroupOfUsersWhoParticipateInApp'
                    )}
                  />
                  <TextField
                    name='cohort'
                    type='text'
                    value={selectedCohort}
                    select
                    onChange={e => {
                      setSelectedCohort(e.target.value)
                      const goalId = cohorts.find(
                        c => c.id === parseInt(e.target.value, 10)
                      )?.activeSessionGoal
                      if (goalId) setSelectedPath(goalId)
                    }}
                    data-testid='cohort-select'
                    sx={{ width: '150px' }}
                    inputProps={{ 'data-testid': 'cohort-select-input' }}
                    SelectProps={{
                      displayEmpty: true,
                      endAdornment: selectedCohort ? (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => setSelectedCohort('')}>
                            <Icon>close</Icon>
                          </IconButton>
                        </InputAdornment>
                      ) : undefined,
                      renderValue:
                        selectedCohort === ''
                          ? () => (
                              <div style={{ opacity: 0.4 }}>
                                {t('company.selectAcohort')}
                              </div>
                            )
                          : undefined
                    }}>
                    {cohorts.map(c => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FieldTitle
                    titleText={`${t('company.emailSubject')} (${t('company.optional')})`}
                    tooltipTitle={t('company.customizedSubject')}
                    description={t('company.defaultSujectIs')}
                  />
                  <TextField
                    name='emailSubject'
                    type='text'
                    value={customTitle}
                    onChange={e => setCustomTitle(e.target.value)}
                    data-testid='subject-edit'
                    inputProps={{ 'data-testid': 'subject-select-input' }}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue:
                        selectedCohort === ''
                          ? () => (
                              <div style={{ opacity: 0.4 }}>
                                {t('company.enterCustomizedEmailSubject')}
                              </div>
                            )
                          : undefined
                    }}
                  />
                </>
              ) : null}
            </>
          ) : null}
          {isSuperAdminView ? (
            <>
              <FieldTitle
                titleText={`${t('company.emailBody')} (${t('company.optional')})`}
                tooltipTitle={t('company.customizedEmail')}
                description={t('company.defaultEmailBody')}
              />
              <RadioGroup
                row
                value={useEditor}
                onChange={e => setUseEditor(e.target.value)}>
                <FormControlLabel
                  value='0'
                  control={<Radio />}
                  label={t('company.default')}
                />
                <FormControlLabel
                  value='1'
                  control={<Radio />}
                  label={t('company.customized')}
                />
              </RadioGroup>
              {useEditor === '1' ? (
                <RichTextContentEditor
                  data={customText}
                  changeCallback={updated => setCustomText(updated)}
                  emailEditor={true}
                />
              ) : null}
            </>
          ) : (
            <>
              <FieldTitle
                titleText={`${t('common.sendMessage')} (${t('company.optional')})`}
              />
              <TextField
                multiline
                placeholder={t('company.messageAccompanyInvitation')}
                rows={3}
                value={customText}
                onChange={e => setCustomText(e.target.value)}
                sx={{ width: '500px' }}
              />
            </>
          )}
          {requestError ? <RequestRetryAlert /> : null}
        </Stack>
      </ConfirmCancelDialog>
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={t('company.inviteSuccessfullySent')}
        confirmButtonText={t('common.done')}
        buttonAction={() => setSuccessDialogOpen(false)}
      />
      <OverlayLoading open={loading} />
    </>
  )
}

export default InviteMoreButton
