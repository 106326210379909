import { CardContent, Divider, Stack, Typography } from '@mui/material'
import LinkButton from 'components/atoms/button/LinkButton'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import { SeeAllLinkText, Subtitle4 } from 'components/atoms/Text'
import { useState } from 'react'
import {
  ASSESSMENTS,
  HOME,
  PULSE_SURVEYS,
  TEAM_SHOUTOUTS,
  USER_FEEBACK
} from 'routes/constants'
import AssessmentsList from '../assessments/AssessmentsList'
import PulseSurveyInvites from '../pulsSurveys/PulseSurveyInvites'
import TeamShoutoutsCard from '../TeamShoutOutsCard'
import UserFeedbackCard from '../UserFeedbackCard'
import { pulseSurveyName } from 'config/config'
import { useTranslation } from 'react-i18next'

const FeedbackArea = ({ hasLicense = true }) => {
  const { t } = useTranslation()
  const [hasFeedback, setHasFeedback] = useState(true)
  const [showFeedbackSeeAll, setShowFeedbackSeeAll] = useState(false)
  const [showPulseSurvey, setShowPulseSurvey] = useState(true)
  const [showPulseSurveySeeAll, setShowPulseSurveySeeAll] = useState(false)
  const [show360, setShow360] = useState(true)
  const [show360SeeAll, setShow360SeeAll] = useState(false)
  const [showShoutSeeAll, setShowShoutSeeAll] = useState(false)

  const displayFeedbackCount = 2
  const feedbackCountCallback = count => {
    if (count > displayFeedbackCount) {
      setShowFeedbackSeeAll(true)
    } else if (count === 0) {
      setHasFeedback(false)
    }
  }
  const displayRatingCount = 4
  const pulseSurveyCountCallback = count => {
    if (count > displayRatingCount && hasLicense) {
      setShowPulseSurveySeeAll(true)
    } else if (hasLicense && count === 0) {
      setShowPulseSurvey(false)
    }
  }
  const assessment360CountCallback = count => {
    if (count > displayRatingCount && hasLicense) {
      setShow360SeeAll(true)
    } else if (hasLicense && count === 0) {
      setShow360(false)
    }
  }

  const displayShoutOutCount = 3
  const shoutoutCountCallback = count => {
    if (count > displayShoutOutCount) {
      setShowShoutSeeAll(true)
    }
  }

  const AreaTitle = ({
    children,
    to,
    seelAll,
    bottomPadding = false
  }) => (
    <LeftJustifyCardContent
      sx={{
        alignItems: 'baseline',
        paddingBottom: bottomPadding ? '16px' : '5px'
      }}>
      <Typography variant='h3'>
        {children}
      </Typography>
      {seelAll ? <SeeAllLinkText text={t('common.seeAll')} to={to} from={HOME} /> : null}
    </LeftJustifyCardContent>
  )

  return (
    <>
      <Stack padding={0}>
        {show360 || showPulseSurvey ? (
          <>
            <AreaTitle seelAll={false}>{t('feedbackArea.openFeedbackRequests')}</AreaTitle>
            {show360 ? (
              <>
                <AreaTitle seelAll={show360SeeAll} to={ASSESSMENTS}>
                  {t('feedbackArea._360Requests')}
                </AreaTitle>
                <CardContent sx={{ paddingTop: 0, marginTop: '-10px' }}>
                  <AssessmentsList
                    smallItemView={true}
                    countCallback={assessment360CountCallback}
                    displayCount={displayRatingCount}
                  />
                </CardContent>
              </>
            ) : null}
            {showPulseSurvey ? (
              <>
                <AreaTitle seelAll={showPulseSurveySeeAll} to={PULSE_SURVEYS}>
                  {pulseSurveyName.camelCase}s
                </AreaTitle>
                <CardContent sx={{ paddingTop: 0, marginTop: '-10px' }}>
                  <PulseSurveyInvites
                    smallItemView={true}
                    countCallback={pulseSurveyCountCallback}
                    displayCount={displayRatingCount}
                  />
                </CardContent>
              </>
            ) : null}
            <Divider />
          </>
        ) : null}
        {hasLicense || hasFeedback ? (
          <>
            <AreaTitle seelAll={showFeedbackSeeAll} to={USER_FEEBACK}>
              {t('feedbackArea.recentFeedback')}
            </AreaTitle>
            {hasFeedback ? (
              <CardContent sx={{ paddingTop: 0 }}>
                <UserFeedbackCard
                  isHomePage={true}
                  feedbackCountCallback={feedbackCountCallback}
                  displayCount={displayFeedbackCount}
                  hasLicense={hasLicense}
                />
              </CardContent>
            ) : (
              <>
                <LeftJustifyCardContent>
                  <Subtitle4>
                    {t('feedbackArea.noFeedbackFromColleagues')}
                  </Subtitle4>
                </LeftJustifyCardContent>
                <CardContent>
                  <LinkButton to={USER_FEEBACK}>{t('feedbackArea.gotoFeedback')}</LinkButton>
                </CardContent>
                <Divider />
              </>
            )}
          </>
        ) : null}
        <AreaTitle
          seelAll={showShoutSeeAll}
          to={TEAM_SHOUTOUTS}
          bottomPadding={true}>
          {t('teamShoutOut.heading')}
        </AreaTitle>
        <CardContent sx={{ paddingTop: hasLicense ? undefined : 0 }}>
          <TeamShoutoutsCard
            isHomePage={true}
            countCallback={shoutoutCountCallback}
            displayCount={displayShoutOutCount}
          />
        </CardContent>
      </Stack>
      {hasLicense ? <Divider /> : null}
    </>
  )
}

export default FeedbackArea
