import { TableRow, TableCell, Typography, useTheme, alpha } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/formatDateTime'
import { getFullName } from 'utils/formatText'

const TimelinePaceRows = ({
  timeline,
  userCompletedSteps,
  activeStepNumber
}) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const rowRef = useRef()

  useEffect(() => {
    if (activeStepNumber === timeline.number) {
      const ele = rowRef.current.nextElementSibling || rowRef.current
      ele.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepNumber])
  
  return (
    <>
      <TableRow ref={rowRef}>
        <TableCell
          colSpan={4}
          sx={{ backgroundColor: alpha(theme.palette.primary.light, 0.4) }}>
          <Typography
            textAlign='center'
            fontWeight='bold'>{`${timeline.sessionLabel} - ${t('insights.facilitatedOn')} ${formatDate(timeline.facilitationDate, i18n.resolvedLanguage)}`}</Typography>
        </TableCell>
      </TableRow>
      {userCompletedSteps.map(u => (
        <TableRow key={`user-completed-step-${u.id}-${u.milestoneStep.id}`}>
          <TableCell>{getFullName(u.user)}</TableCell>
          <TableCell>{u.status}</TableCell>
          <TableCell>{u.completedStepName}</TableCell>
          <TableCell>{u.completedPercentage?.toFixed(0)}%</TableCell>
        </TableRow>
      ))}
    </>
  )
}

export default TimelinePaceRows
