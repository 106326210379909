import { Stack, Typography } from '@mui/material'
import StyledButton from 'components/atoms/button/StyledButton'
import { Card, CardStack, CardTag } from 'components/atoms/container/BoxCard'
import RowStack from 'components/atoms/container/RowStack'
import { CompletionRateProgress } from './TeamProgress'
import colors from 'styles/colors'
import EastIcon from '@mui/icons-material/East'
import { useTranslation } from 'react-i18next'

export const TeamProgressCard = ({
  completionRate,
  participants,
  completedAssessments,
  setShowDashboard
}) => {
  const { t } = useTranslation()
  return (
    <Card
      padding={16}
      backgroundColor={colors.MutedTeal50}
      borderColor={colors.MutedTeal300}>
      <CardStack spacing={0} justifyContent={'space-between'}>
        <Stack padding={0}>
          <Typography variant='h1'>{t('fullReport.teamsProgress')}</Typography>

          <Typography variant='body1'>
            {t('fullReport.assessmentInsights')}
          </Typography>
        </Stack>

        <Stack padding={0} spacing={8} width={'100%'}>
          <CompletionRateProgress completionRate={completionRate} />

          <RowStack spacing={4}>
            <CardTag sx={{ background: colors.White }}>
              {participants} {t('contact.participants')}
            </CardTag>
            <CardTag sx={{ background: colors.White }}>
              {completedAssessments} {t('common.complete')}
            </CardTag>
          </RowStack>
        </Stack>

        <StyledButton onClick={() => setShowDashboard(true)} sx={{ gap: 4 }}>
          <EastIcon />
          {t('fullReport.goToDashboard')}
        </StyledButton>
      </CardStack>
    </Card>
  )
}

export default TeamProgressCard
