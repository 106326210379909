import { Box, CardContent, CircularProgress } from '@mui/material'
import PanelsWithScrollBars from 'components/atoms/container/PanelsWithScrollBars'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { NotificationBodyText } from 'components/atoms/Text'
import ContentListItem from 'components/molecules/ContentListItem'
import StepContent from 'components/molecules/journey/StepContent'
import StepPathObjectHeader from 'components/molecules/journey/StepPathObjectHeader'
import { useEffect, useRef, useState } from 'react'
import { CONTENT_SEARCH_URL } from 'services/constants'
import { getRequest } from 'utils/api'
import { useTranslation } from 'react-i18next'

const SearchResultCard = ({ searchText, resultCallback }) => {
  const { t } = useTranslation()
  const [requestError, setRequestError] = useState(undefined)
  const [results, setResults] = useState([])
  const [selected, setSelected] = useState(null)
  const [pagination, setPagination] = useState({})
  const topRef = useRef(null)

  const fetchData = async (queryPagination, firstFetch = false) => {
    const queryObject = {
      web: true,
      searchQuery: searchText,
      ...queryPagination
    }
    const res = await getRequest(CONTENT_SEARCH_URL, queryObject)
    if (res.status === 200) {
      let newResult = []
      if (!firstFetch) {
        newResult = [...results]
      }
      newResult = newResult.concat(res.data.data.data)
      setResults(newResult)
      setPagination(res.data.data.pagination)
      setRequestError(false)
      return res.data.data.pagination.total
    } else {
      setRequestError(true)
    }
  }

  useEffect(() => {
    const firstFetch = async () => {
      const total = await fetchData({}, true)
      resultCallback(total)
    }
    setResults([])
    setSelected(null)
    firstFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const listOnScroll = ref => {
    if (ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current
      if (scrollTop + clientHeight === scrollHeight) {
        if (pagination.next) {
          const queryPagination = {
            page: pagination.next,
            limit: pagination.limit
          }
          fetchData(queryPagination)
        }
      }
    }
  }

  const handleSelected = newSelected => {
    setSelected(newSelected)
    topRef.current?.scrollIntoView({ block: 'nearest' })
  }

  return (
    <PanelsWithScrollBars
      listOnScroll={listOnScroll}
      leftList={
        <>
          {results.map(result => (
            <ContentListItem
              key={result.id}
              selected={result.id === selected?.id}
              contentData={result}
              selectCallback={() => handleSelected(result)}
              bookmarked={result.bookmarked}
            />
          ))}
        </>
      }>
      {results.length === 0 && requestError === false ? (
        <CardContent>
          <NotificationBodyText maxWidth='600px'>
            {t('searchArea.searchWithNoResults')}
          </NotificationBodyText>
        </CardContent>
      ) : selected ? (
        <Box width='100%'>
          <Box ref={topRef}/>
          <StepPathObjectHeader
            l1StepName={selected.parentStepName}
            goalName={selected.goalName}
          />
          <StepContent stepData={selected} />
        </Box>
      ) : (
        <CardContent>
          {requestError === false ? (
            <NotificationBodyText>
              {t('searchArea.selectSearchResult')}
            </NotificationBodyText>
          ) : (
            <CircularProgress />
          )}
        </CardContent>
      )}
      {requestError ? (
        <CardContent>
          <RequestRetryAlert />
        </CardContent>
      ) : null}
    </PanelsWithScrollBars>
  )
}

export default SearchResultCard
