import { Typography, darken } from '@mui/material'
import ReportPage from 'components/templates/ReportPage'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import ModelPieChart from 'components/molecules/assessments/report/ModelPieChart'
import { getStringAbbreviation } from 'utils/formatText'
import { useTranslation } from 'react-i18next'

const BucketPage = ({
  downloadingFile,
  goalData,
  models,
  pageNum,
  activeIndex = null
}) => {
  const { t } = useTranslation()
  const goalName = `${getStringAbbreviation(goalData?.name)} ${t('fullReport.competencyModel')}`

  return (
    <>
      <ReportPage
        assessmentName={activeIndex === null ? goalData?.name : null}
        pageNum={pageNum}
        downloadingFile={downloadingFile}
        childrenWrapperSx={{ paddingLeft: 21, paddingRight: 21 }}
        >
        <InCardStack spacing={activeIndex === null ? 0 : 100}>
          {activeIndex === null ? (
            <Typography
              variant='reportH1'
              sx={{ color: colors.MidnightTeal400 }}>
              {goalName}
            </Typography>
          ) : (
            <Typography
              variant='reportH1'
              style={{
                color: models[activeIndex]?.color
                  ? darken(models[activeIndex]?.color, 0.2)
                  : 'inherit',
                marginTop: 40
              }}>
              {models[activeIndex]?.name}
            </Typography>
          )}

          <ModelPieChart
            title={goalName}
            data={models.map(model => ({
              ...model,
              y: Math.round(100 / models.length)
            }))}
            activeIndex={activeIndex}
          />

          {activeIndex === null && (
            <Typography
              variant='caption'
              component={'p'}
              color={colors.MidnightTeal400}>
              {goalData.summary}{' '}
              {t('fullReport.competencyModelDescription.part1')}
              <br />
              <br />
              {t('fullReport.competencyModelDescription.part2')}
            </Typography>
          )}
        </InCardStack>
      </ReportPage>
    </>
  )
}

export default BucketPage
