import { Card, Grid, Typography, useTheme, CardContent } from '@mui/material'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { NotificationBodyText } from 'components/atoms/Text'
import CompletionCard from 'components/molecules/journey/CompletionCard'
import JourneyPath from 'components/molecules/journey/JourneyPath'
import StepContentsCard from 'components/molecules/journey/StepContentsCard'
import ConfirmCancelDialog from 'components/molecules/notificationOverlay/ConfirmCancelDialog'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { USER_SETTINGS } from 'routes/constants'
import { updateStates } from 'services/userServices'
import { checkSaved } from 'utils/contentEditorHelper'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { NARROW_BROWSER_WIDTH } from 'utils/constants'
import { MilestoneEncouragementPopup } from 'components/organisms/JourneyPopups'
import { trackPathAction } from 'services/amplitudeTracking'

const JourneyContents = ({
  steps,
  goalId,
  stepId,
  userStates,
  editorMode = false,
  demoMode = false
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [scheduleReminderOpen, setScheduleReminderOpen] = useState(false)
  const [slowdownReminderOpen, setSlowDownReminderOpen] = useState(false)
  const [organizedSteps, setOrganizedSteps] = useState([])
  const [processedSteps, setProcessedSteps] = useState([])
  const [currentStep2, setCurrentStep2] = useState({})
  const [currentL1StepNumber, setCurrentL1StepNumber] = useState(0)
  const [currentStepNumber, setCurrentStepNumber] = useState(0)
  const [contentStartIndex, setContentStartIndex] = useState(0)
  const [showCompletionCard, setShowCompletionCard] = useState(false)
  const [completeGoalNow, setCompleteGoalNow] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pathOpen, setPathOpen] = useState(
    window.innerWidth > NARROW_BROWSER_WIDTH
  )
  const [encouragementMessage, setEncouragementMessage] = useState()

  useEffect(() => {
    if (steps && userStates && !initialized) {
      const l1Steps = steps.filter(s => s.milestone_level === 1)
      const l2Steps = steps.filter(s => s.milestone_level === 2)
      const l3Steps = steps.filter(s => s.milestone_level === 3)
      const getSubSteps = (sourceArray, sourcIndex, targetArray) => {
        const lowerBound = sourceArray[sourcIndex].number
        const noHigherBound =
          sourcIndex === sourceArray.length - 1 ? true : false
        return targetArray.filter(
          t =>
            t.number > lowerBound &&
            (noHigherBound || t.number < sourceArray[sourcIndex + 1].number)
        )
      }
      const resultSteps = l1Steps.map((l1Step, index) => {
        const childL2Steps = getSubSteps(l1Steps, index, l2Steps)
        if (childL2Steps.length) {
          childL2Steps[childL2Steps.length - 1].isLastL2Step = true
        }
        const processedl1 = l1Step
        processedl1.childL2Steps = childL2Steps.map((l2Step, l2Index) => {
          const processedl2 = l2Step
          const arrayIndex = l2Steps.indexOf(l2Step)
          const childL3Steps = getSubSteps(l2Steps, arrayIndex, l3Steps)
          processedl2.childL3Steps = childL3Steps
          processedl2.l1StepNumber = l1Step.number
          if (l2Index === 0) {
            processedl2.isFristL2ForL1 = true
            if (index === 0) {
              processedl2.isFirstL2ForGoal = true
            }
          }
          return processedl2
        })

        if (childL2Steps.length > 0) {
          childL2Steps[0].encouragementMessage =
            processedl1.encouragementMessage
        }

        return processedl1
      })
      setOrganizedSteps(resultSteps)
      const maxStepNumber = steps[steps.length - 1].number

      // Check user stats for journey process
      let completedGoalStepNumber
      if (userStates.currentGoalId === parseInt(goalId)) {
        // If user at current goal, started or not
        const targetStep = steps.find(
          s => s.id === userStates.currentGoalStepId
        )
        if (targetStep?.number) {
          const activeStep = l2Steps.find(s => s.number > targetStep.number)
          completedGoalStepNumber = activeStep?.number || targetStep.number
          setCurrentL1StepNumber(
            activeStep?.l1StepNumber || targetStep.l1StepNumber
          )
        } else {
          completedGoalStepNumber = resultSteps[0].childL2Steps[0].number
          setCurrentL1StepNumber(resultSteps[0].childL2Steps[0].l1StepNumber)
        }
      }
      if (
        userStates.completedGoals?.indexOf(parseInt(goalId)) > -1 ||
        editorMode ||
        demoMode
      ) {
        // if user already completed the goal
        completedGoalStepNumber = maxStepNumber
      }
      setCurrentStepNumber(completedGoalStepNumber)

      // if provide stepId in page state, set current content
      if (stepId) {
        const targetStep = steps.find(s => s.id === parseInt(stepId))
        if (targetStep) {
          if (targetStep.milestone_level === 2) setCurrentStep2(targetStep)
          else if (targetStep.milestone_level === 1) {
            const selectedL2Step = l2Steps.find(
              s => s.number > targetStep.number
            )
            setCurrentStep2(selectedL2Step)
          } else if (targetStep.milestone_level === 3) {
            const selectedL2Step = l2Steps
              .sort((a, b) => b.number - a.number)
              .find(s => s.number < targetStep.number)
            setCurrentStep2(selectedL2Step)
            const childStep = selectedL2Step.childL3Steps.find(
              c => c.id === stepId
            )
            const childIndex = selectedL2Step.childL3Steps.indexOf(childStep)
            setContentStartIndex(childIndex)
          }
        }
      } else if (completedGoalStepNumber === maxStepNumber) {
        // If finished goal, goes to first step.
        setCurrentStep2(l2Steps[0])
      } else {
        // Set current content as completedGoalStepNumber
        const targetStep = l2Steps.find(
          s => s.number === completedGoalStepNumber
        )
        if (targetStep) setCurrentStep2(targetStep)
      }
      setProcessedSteps(steps)
      setInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps])

  const pathClickHandler = (l1Index, l2Index) => {
    if (editorMode && !checkSaved()) {
      return
    }
    setCurrentStep2(organizedSteps[l1Index].childL2Steps[l2Index])
    setContentStartIndex(0)
    setShowCompletionCard(false)
  }
  const [width, setWidth] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width)
    }
  }, [])

  const handlePreviousStep2 = () => {
    const previousL2Step = processedSteps
      .sort((a, b) => b.number - a.number)
      .find(s => s.number < currentStep2.number && s.milestone_level === 2)
    if (previousL2Step) {
      setCurrentStep2(previousL2Step)
      setContentStartIndex(-1)
    }
  }

  const updateUserCurrentStepId = async newStepId => {
    const requestBody = {
      currentGoalStepId: newStepId
    }
    const res = await updateStates(requestBody, false)
    if (res.data?.data?.firstGoalStepCompleted) {
      setScheduleReminderOpen(true)
    }
    if (res.data?.data?.displayLearningSlowdown) {
      setSlowDownReminderOpen(true)
    }
    userStates.currentGoalStepId = newStepId
  }
  const updateUserGoalCompletion = async completed => {
    const completedGoals = completed || []
    if (completedGoals.indexOf(goalId) < 0) {
      completedGoals.push(goalId)
      const requestBody = {
        completedGoals
      }
      await updateStates(requestBody)
      setCompleteGoalNow(true)
    }
    setLoading(false)
  }

  const showEncouragementPopupIfEligible = currentStep => {
    const nextMilestoneName = organizedSteps
      .find(x => x.number === currentStep.l1StepNumber)
      ?.name
    const isActivePath = currentStep.l1StepNumber >= currentL1StepNumber
    if (currentStep.encouragementMessage && isActivePath) {
      setEncouragementMessage({
        description: currentStep.encouragementMessage.description,
        scores: currentStep.encouragementMessage.scores,
        nextMilestoneName,
      })
    }
  }

  // handle user profile update on currentGoalStepId
  const handleNextStep2 = (isNavigation = true, isStepUpdate = true) => {
    // Only move steps if current goal not completed and not editor mode
    const isCurrentGoalMatch = userStates.currentGoalId === parseInt(goalId)
    const notCompleteGoal = !userStates.completedGoals
      || userStates.completedGoals.indexOf(parseInt(goalId)) === -1

    if (isCurrentGoalMatch && notCompleteGoal && !editorMode && isStepUpdate) {
      const currentUserStep = processedSteps.find(
        s => s.id === userStates.currentGoalStepId
      )
      if (
        !userStates.currentGoalStepId ||
        currentUserStep.number < currentStep2.number
      ) {
        updateUserCurrentStepId(currentStep2.id)
        const currentL1Step = organizedSteps.find(
          s => s.number === currentL1StepNumber
        )
        if (currentStep2.isFristL2ForL1) {
          trackPathAction(goalId, 'Milestone Started', {
            milestone: currentL1Step.name
          })
          if (currentStep2.isFirstL2ForGoal) {
            trackPathAction(goalId, 'Path Started')
          }
        }
        if (currentStep2.isLastL2Step) {
          trackPathAction(goalId, 'Milestone Completed', {
            milestone: currentL1Step.name
          })
        }
      }
    }
    const nextL2Step = processedSteps
      .sort((a, b) => a.number - b.number)
      .find(s => s.number > currentStep2.number && s.milestone_level === 2)
    if (nextL2Step && isNavigation) {
      setCurrentStep2(nextL2Step)
      showEncouragementPopupIfEligible(nextL2Step)
      setContentStartIndex(0)
      if (nextL2Step.number > currentStepNumber) {
        setCurrentStepNumber(nextL2Step.number)
        setCurrentL1StepNumber(nextL2Step.l1StepNumber)
      }
    } else if (!nextL2Step) {
      if (isNavigation) {
        setLoading(true)
        setShowCompletionCard(true)
        setCurrentStepNumber(organizedSteps[organizedSteps.length - 1].number)
        setCurrentL1StepNumber(
          organizedSteps[organizedSteps.length - 1].l1StepNumber
        )
        setCurrentStep2({})
      }
      updateUserGoalCompletion(userStates.completedGoals)
      trackPathAction(goalId, 'Path Completed')
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (pathOpen && window.innerWidth < NARROW_BROWSER_WIDTH) {
        togglePathOpen()
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const togglePathOpen = () => {
    setPathOpen(!pathOpen)
  }

  return (
    <Grid
      container
      height='inherit'
      width='100%'
      sx={{ paddingBottom: 5, flexWrap: 'nowrap' }}>
      <OverlayLoading open={loading} />
      {encouragementMessage ? (
        <MilestoneEncouragementPopup
          open={!!encouragementMessage}
          message={encouragementMessage.description}
          virtueScores={encouragementMessage.scores}
          nextMilestoneName={encouragementMessage.nextMilestoneName}
          onClose={() => setEncouragementMessage(null)}
        />
      ) : null}
      {pathOpen ? (
        <Grid
          item
          xs={3}
          minWidth='400px'
          height='inherit'
          data-testid='journey-path-view'>
          <Card
            ref={measuredRef}
            sx={{
              height: 'inherit',
              overflowY: 'scroll',
              overflowX: 'hidden',
              boxShadow: '0px 1px 5px 2px rgb(0 0 0 / 12%)'
            }}>
            <CardContent
              sx={{
                position: 'absolute',
                backgroundColor: theme.palette.background.paper,
                width,
                zIndex: 1000,
                justifyContent: 'space-between'
              }}>
              <Typography variant='h3'>
                {t('pathsAndModels.currentPath')}
              </Typography>
              <IconButton onClick={togglePathOpen}>
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
            </CardContent>

            <JourneyPath
              organizedSteps={organizedSteps}
              containerWidth={width}
              currentStepNumber={currentStepNumber}
              clickHandler={pathClickHandler}
              activeStepNumber={currentStep2.number}
              goalId={goalId}
            />
          </Card>
        </Grid>
      ) : (
        <>
          <CardContent
            sx={{
              position: 'absolute',
              marginLeft: '-30px'
            }}>
            <IconButton onClick={togglePathOpen}>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </CardContent>
        </>
      )}

      <Grid
        item
        xs={pathOpen ? 9 : 12}
        minWidth='400px'
        height='inherit'
        paddingLeft={10}
        sx={
          pathOpen
            ? {}
            : {
                '& p, & span, & em, & sub': {
                  fontSize: 'calc(1rem + 2px)'
                }
              }
        }>
        {showCompletionCard ? (
          <CompletionCard
            goalId={goalId}
            showReAssessmentInfo={completeGoalNow}
            pathOpen={pathOpen}
          />
        ) : (
          <StepContentsCard
            organizedSteps={organizedSteps}
            l2StepData={currentStep2}
            handleNextStep2={handleNextStep2}
            handlePreviousStep2={handlePreviousStep2}
            firstL2Step={
              !Boolean(
                processedSteps?.find(
                  s => currentStep2.number > s.number && s.milestone_level === 2
                )
              )
            }
            contentStartIndex={contentStartIndex}
            editorMode={editorMode}
            pathOpen={pathOpen}
            togglePathOpen={togglePathOpen}
          />
        )}
      </Grid>
      <ConfirmCancelDialog
        titleText={t('pathsAndModels.scheduleTimeLearn')}
        buttonsDirection='row'
        open={scheduleReminderOpen}
        onClose={confirmed => {
          setScheduleReminderOpen(false)
          if (confirmed) {
            navigate(USER_SETTINGS, { state: { fromJourney: true } })
          }
        }}
        confirmText={t('pathsAndModels.customize')}
        cancelText={t('common.noThanks')}>
        <NotificationBodyText textAlign='center'>
          {t('pathsAndModels.spendingTimeLearning')}
        </NotificationBodyText>
        <NotificationBodyText textAlign='center'>
          {t('pathsAndModels.useTheSettings')}
        </NotificationBodyText>
      </ConfirmCancelDialog>
      <ConfirmCancelDialog
        open={slowdownReminderOpen}
        onClose={() => setSlowDownReminderOpen(false)}
        titleText={t('pathsAndModels.coveredLotContent')}
        maxWidth='xs'
        confirmText={t('common.ok')}>
        <NotificationBodyText textAlign='center'>
          {t('pathsAndModels.movingThroughContentQickly')}
        </NotificationBodyText>
      </ConfirmCancelDialog>
    </Grid>
  )
}

export default JourneyContents
