import { MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { StyledSelect } from 'components/atoms/input/StyledInputs'
import { useTranslation } from 'react-i18next'

const SelectVirtue = ({ virtueId, setVirtueId, rolePlays }) => {
  const { t } = useTranslation()
  const [rpVirtues, setRPVirtues] = useState([])

  useEffect(() => {
    const virtueIds = [...new Set(rolePlays.map(r => r.virtue.id))]
    const preVirtues = rolePlays.map(r => ({
      virtueName: r.virtue.name,
      virtueId: r.virtue.id
    }))

    const virtues = virtueIds.map(id => {
      const v = rolePlays.find(r => r.virtueId === id)
      return {
        id,
        name: v.virtue.name,
        count: preVirtues.filter(x => x.virtueId === id).length
      }
    })

    setRPVirtues(virtues)
  }, [rolePlays])

  return (
    <StyledSelect
      sx={{ minWidth: 300 }}
      displayEmpty
      value={virtueId}
      onChange={e => setVirtueId(e.target.value)}>
      <MenuItem value='' sx={{ display: 'none' }}>
        {t('rolePlay.filterBySkill')}
      </MenuItem>
      {rpVirtues.map(v => (
        <MenuItem
          sx={{fontWeight: 500}}
          value={v.id}
          key={`virtue-option-${v.id}`}
        >
          {v.name} ({v.count})
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

export default SelectVirtue
