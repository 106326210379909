import { TextField, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

const FormSelectInput = props => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    attributeName,
    formik,
    placeholder,
    children,
    onChange,
    sx,
    updatedStyle = false,
    variant = 'outlined',
    ...otherProps
  } = props
  return (
    <TextField
      select
      id={attributeName}
      name={attributeName}
      data-testid={`${attributeName}-select`}
      onChange={onChange || formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values[attributeName]}
      error={
        formik.touched[attributeName] && Boolean(formik.errors[attributeName])
      }
      helperText={
        formik.touched[attributeName] &&
        (formik.errors[attributeName]
          ? t(`${formik.errors[attributeName]}`)
          : false)
      }
      InputLabelProps={{
        shrink: true
      }}
      SelectProps={{
        displayEmpty: true,
        renderValue:
          formik.values[attributeName] === ''
            ? () => <div style={{ opacity: 0.4 }}>{placeholder}</div>
            : undefined
      }}
      variant={variant}
      inputProps={
        updatedStyle && variant === 'standard'
          && { sx: { paddingLeft: 2, paddingY: 5 } }
      }
      InputProps={
        updatedStyle && variant === 'outlined' ? {
          sx: {
            borderRadius: 20,
            backgroundColor: theme.palette.background.paper,
            '& .MuiSvgIcon-root': { marginRight: 5 },
            '& .MuiSelect-select': { paddingY: 5, paddingX: 10}
          }
        } : {}
      }
      {...otherProps}>
      {children}
    </TextField>
  )
}

export default FormSelectInput
