import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  Stack,
  Typography
} from '@mui/material'
import { useFormik } from 'formik'
import { deleteRequest, patchRequest, postRequest } from 'utils/api'
import { USER_NOTES_TYPES, USER_NOTES_URL } from 'services/constants'
import { useEffect, useRef, useState } from 'react'
import FormInputBox from 'components/atoms/input/FormInputBox'
import InCardStack from 'components/atoms/InCardStack'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { GoalInvertIcon, LearningInvertIcon } from 'components/atoms/Icons'
import FormDatePicker from 'components/atoms/input/FormDatePicker'
import moment from 'moment'
import { ISO_DATETIME_FORMAT } from 'utils/constants'
import { useLocation } from 'react-router-dom'
import SettingSwitch from 'components/atoms/input/SettingSwitch'
import LinkCalendarDialog from 'components/molecules/oAuth/LinkCalendarDialog'
import { USER_SETTINGS } from 'routes/constants'
import { getCalendarEvents } from 'services/userServices'
import { useNavigate } from 'react-router-dom'
import { ActionText, Subtitle4 } from 'components/atoms/Text'
import { useTranslation } from 'react-i18next'

const StepNote = ({ question, stepId, noteData, type, title, placeholder, contentActionTracking }) => {
  const { t } = useTranslation()
  const [requestSuccess, setRequestSuccess] = useState(undefined)
  const [noteCreated, setNoteCreated] = useState(null)
  const ref = useRef()
  const location = useLocation()
  const scrollTo =
    location.state?.to === USER_NOTES_TYPES.GOAL &&
    type === USER_NOTES_TYPES.GOAL
  const [linked, setLinked] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [connectFailed, setConnectFailed] = useState(undefined)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const existingNote = noteData?.find(n => n.type === type)
    if (existingNote) {
      setNoteCreated(existingNote)
    } else {
      setNoteCreated(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteData, type, stepId])

  useEffect(() => {
    if (scrollTo && ref.current) {
      ref.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      })
    }
  }, [scrollTo])

  useEffect(() => {
    const notificationTimeout = setTimeout(() => {
      setRequestSuccess(undefined)
    }, 2000)
    return () => clearTimeout(notificationTimeout)
  }, [requestSuccess])

  const formik = useFormik({
    initialValues: {
      feedbackText: noteCreated?.notes || '',
      dueDate: noteCreated?.dueDate ? moment.utc(noteCreated.dueDate) : null,
      reminderDate: noteCreated?.reminderDate
        ? moment.utc(noteCreated.reminderDate)
        : null,
      addCalendarEvent: noteCreated?.calendarEventId && linked ? true : false
    },
    enableReinitialize: true,
    onSubmit: async values => {
      const requestBody = {
        notes: values.feedbackText,
        type,
        goalStepId: stepId
      }

      if (type === USER_NOTES_TYPES.GOAL) {
        requestBody.dueDate =
          values.dueDate
            ?.utc()
            .set({ hour: 9, minute: 0, second: 0 })
            .format(ISO_DATETIME_FORMAT) || null
        requestBody.reminderDate =
          values.reminderDate
            ?.utc()
            .set({ hour: 9, minute: 0, second: 0 })
            .format(ISO_DATETIME_FORMAT) || null
        requestBody.timeZone = moment.tz.guess()
        requestBody.addCalendarEvent = values.addCalendarEvent
      }
      let res
      if (noteCreated) {
        if (values.feedbackText.length > 0) {
          res = await patchRequest(
            `${USER_NOTES_URL}/${noteCreated.id}`,
            requestBody
          )
          contentActionTracking('Note Updated', { noteType: type })
        } else {
          res = await deleteRequest(`${USER_NOTES_URL}/${noteCreated.id}`)
        }
      } else if (values.feedbackText.length > 0) {
        res = await postRequest(USER_NOTES_URL, requestBody)
        contentActionTracking('Note Created', { noteType: type })
      } else {
        return
      }
      if (res.status >= 200 && res.status < 205) {
        let newNoteCreated = null
        if (res.status === 201) {
          noteData.push(res.data.data)
          newNoteCreated = res.data.data
        } else if (res.status === 200) {
          const existingNote = noteData?.find(n => n.type === type)
          existingNote.notes = values.feedbackText
          newNoteCreated = res.data.data
        } else {
          const noteIndex = noteData.indexOf(noteCreated)
          noteData.splice(noteIndex, 1)
        }
        setRequestSuccess(true)
        setNoteCreated(newNoteCreated)
      } else {
        setRequestSuccess(false)
      }
    }
  })

  useEffect(() => {
    formik.resetForm()
    formik.setErrors([])
    if (
      type === USER_NOTES_TYPES.GOAL &&
      noteCreated?.id &&
      !noteCreated.completed &&
      noteCreated.dueDate
    ) {
      if (moment(noteCreated.dueDate) < moment()) {
        formik.setFieldTouched('dueDate', true)
        formik.setFieldError('dueDate', 'validationErrors.pastDue') // keys are translated in the component
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteCreated])

  const handleFinishGoal = async () => {
    const res = await patchRequest(`${USER_NOTES_URL}/${noteCreated.id}`, {
      completed: true
    })
    if (res.status === 200) {
      setNoteCreated(res.data.data)
      setRequestSuccess(true)
      contentActionTracking('Complete Goal')
    } else {
      setRequestSuccess(false)
    }
  }

  const checkCalenderLinked = async () => {
    setLoading(true)
    const res = await getCalendarEvents()
    setLinked(res?.linked ?? false)
    setLoading(false)
  }

  useEffect(() => {
    if (type === USER_NOTES_TYPES.GOAL) {
      checkCalenderLinked()
    }
  }, [type])

  const setAddToCalender = value => {
    if (value && !linked) setDialogOpen(true)
    else {
      formik.setFieldValue('addCalendarEvent', value)
      formik.submitForm()
    }
  }

  const signinCallback = async success => {
    if (success) {
      setLinked(true)
      formik.setFieldValue('addCalendarEvent', true)
      setTimeout(() => {
        formik.submitForm()
      }, 1000)
    }
    setConnectFailed(!success)
    setDialogOpen(false)
  }

  const DateGrid = ({ label, children, ...otherProps }) => (
    <Grid item xs={6} alignItems='center' display='flex' {...otherProps}>
      <Typography variant='subtitle1' marginRight={10}>
        {label}:
      </Typography>
      {children}
    </Grid>
  )
  return (
    <InCardStack
      width='100%'
      sx={{ paddingY: type !== USER_NOTES_TYPES.NOTE ? 8 : undefined }}>
      <Divider sx={{ width: '100%' }} />
      <Stack spacing={10} padding={0} width='100%'>
        <Stack direction='row' spacing={10} padding={0}>
          {type !== USER_NOTES_TYPES.NOTE ? (
            <Icon sx={{ width: '48px', height: '48px', marginTop: '5px' }}>
              {type === USER_NOTES_TYPES.GOAL ? (
                <GoalInvertIcon />
              ) : (
                <LearningInvertIcon />
              )}
            </Icon>
          ) : null}
          <Stack padding={0}>
            {type !== USER_NOTES_TYPES.NOTE ? (
              <Typography variant='subtitle1'>{title}</Typography>
            ) : null}
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {question}
            </Typography>
          </Stack>
        </Stack>
        <form onSubmit={formik.handleSubmit} width='100%'>
          <InCardStack>
            <FormInputBox
              sx={{ width: '100%' }}
              type='text'
              formik={formik}
              multiline
              rows={3}
              attributeName='feedbackText'
              placeholder={placeholder}
              disabled={noteCreated?.completed}
            />
            {requestSuccess === false ? <RequestRetryAlert /> : null}
            {type === USER_NOTES_TYPES.GOAL &&
            (!noteCreated?.completed ||
              (noteCreated?.completed && requestSuccess)) ? (
              <Grid container data-testid='goal-dates-grid'>
                <DateGrid label={t('goalsArea.dueDate')}>
                  <FormDatePicker
                    formik={formik}
                    attributeName='dueDate'
                    minDate={moment()}
                    onChangeCallBack={() => formik.submitForm()}
                  />
                </DateGrid>
                <DateGrid label={t('stepNote.setReminder')}>
                  <FormDatePicker
                    formik={formik}
                    attributeName='reminderDate'
                    minDate={moment()}
                    onChangeCallBack={() => formik.submitForm()}
                  />
                </DateGrid>
                <>
                  <DateGrid label={t('stepNote.addDateToCalendar')} mt={15}>
                    <SettingSwitch
                      disabled={loading || !formik.values['dueDate']}
                      checked={formik.values['addCalendarEvent']}
                      onChange={e => setAddToCalender(e.target.checked)}
                    />
                    <LinkCalendarDialog
                      handleSigninResult={signinCallback}
                      dialogOpen={dialogOpen}
                      setDialogOpen={setDialogOpen}
                    />
                  </DateGrid>
                  <Subtitle4 mt={10}>
                    {connectFailed ? (
                      <>
                        {t('calenderEvents.unableToConnect.part1')}
                        <ActionText onClick={() => navigate(USER_SETTINGS)}>
                          {t('headers.settings')}
                        </ActionText>{' '}
                        {t('calenderEvents.unableToConnect.part2')}
                      </>
                    ) : null}
                  </Subtitle4>
                </>
              </Grid>
            ) : null}
            <Box display='flex' paddingLeft='170px'>
              {noteCreated?.completed && requestSuccess === undefined ? (
                <Typography color='primary'>
                  {t('stepNote.greatJobCompletedGoal')}
                </Typography>
              ) : (
                <InCardStack direction='row'>
                  <Button type='submit' sx={{ textTransform: 'uppercase' }}>
                    {t('common.save')} {type.toUpperCase()}
                    {type === USER_NOTES_TYPES.NOTE ? 'S' : null}
                  </Button>
                  {noteCreated?.id && type === USER_NOTES_TYPES.GOAL ? (
                    <Button onClick={() => handleFinishGoal()}>
                      {t('stepNote.IDidIt')}
                    </Button>
                  ) : null}
                </InCardStack>
              )}
              <Alert
                sx={{
                  marginLeft: '20px',
                  opacity: requestSuccess === true ? 1 : 0,
                  transition: 'opacity 1s'
                }}>
                {t('common.saved')}!
              </Alert>
            </Box>
          </InCardStack>
        </form>
        <Box position='relative'>
          {/* scrolling anchor, give component buffer at the end */}
          <Box ref={ref} position='absolute' top='100px' />
        </Box>
      </Stack>
    </InCardStack>
  )
}

export default StepNote
