/* eslint-disable react-hooks/exhaustive-deps */
import { useStopwatch } from 'react-timer-hook'
import { Alert, Snackbar, Typography, useTheme } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import RolePlayInteractionCard from 'components/molecules/rolePlay/RolePlayInteractionCard'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import StopIcon from '@mui/icons-material/Stop'
import StyledButton, {
  InverseIconButton
} from 'components/atoms/button/StyledButton'
import { OverlayWithText } from 'components/atoms/OverlayLoading'
import {
  AI_ROLE_PLAY_V2_URL,
  LIVE_ROLE_PLAY_URL,
  ROLE_PLAY_TYPES
} from 'services/constants'
import { rolePlaysocket as socket } from 'utils/socket'
import { postRequest } from 'utils/api'
import UnavoidableConfirmCancel from 'components/molecules/notificationOverlay/UnavoidableConfirmCancel'
import { padNumber } from 'utils/formatText'
import RolePlayCompletedPopup from 'components/molecules/rolePlay/RolePlayCompletedPopup'
import colors from 'styles/colors'

const RolePlayCall = ({
  rolePlay,
  notes,
  isStarted,
  setIsStarted,
  isCompleted,
  setIsCompleted,
  setActiveUserRolePlayId
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [paused, setPaused] = useState(false)
  const [stopWithPaused, setStopWithPaused] = useState(false)
  const [completeLoading, setCompleteLoading] = useState(false)
  const [isConnected, setIsConnected] = useState(socket.connected)
  const [isInitialized, setIsInitialized] = useState(false)
  const [endCallDialogOpen, setEndCallDialogOpen] = useState(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [stopClicked, setStopClicked] = useState(false)
  const [messages, setMessages] = useState([])
  const [newReply, setNewReply] = useState([])
  const [completedRolePlay, setCompletedRolePlay] = useState(null)
  const [completeDialogOpen, setCompleteDialogOpen] = useState(false)
  const [isMicAlert, setIsMicAlert] = useState(false)
  const mediaRecorder = useRef(null)
  const audioPlayer = useRef(null)
  const timer = useStopwatch({
    autoStart: false
  })

  const onConnect = () => {
    setIsConnected(true)
  }

  const onDisconnect = () => {
    setIsConnected(false)
  }

  const setUpStream = () => {
    socket.on('connect', onConnect)
    socket.on('init_success', initData => {
      setIsInitialized(true)
      if (initData.firstMessage) {
        setNewReply([initData.firstMessage])
      }
    })
    socket.on('reply', data => {
      stopRecording()
      const { transcript, response } = data
      setNewReply([{ role: 'user', content: transcript }, response])
    })
    socket.on('disconnect', onDisconnect)
    socket.connect(LIVE_ROLE_PLAY_URL)
  }

  const stopStream = () => {
    socket.removeAllListeners('reply')
    socket.removeAllListeners('init_success')
    socket.off('connect', onConnect)
    socket.off('connect', onDisconnect)
    socket.disconnect()

    // Clean up
    stopRecording()
    audioPlayer.current?.pause()
    setIsStarted(false)
    setMessages([])
    setIsInitialized(false)
    setPaused(false)
    timer.reset()
  }
  useEffect(() => {
    return stopStream
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initStream = () => {
    socket.emit('init', {
      rolePlayId: rolePlay.id,
      messages: messages.map(m => ({ role: m.role, content: m.content }))
    })
    setMessages([])
    setIsInitialized(false)
  }

  const attemptStart = async () => {
    const isMic = await isMicrophoneAvailable()

    if (!isMic) {
      setIsMicAlert(true)
    } else {
      setIsStarted(true)
    }
  }

  useEffect(() => {
    // Reset stream if role play changes
    if (isConnected && rolePlay) {
      initStream()
    }
  }, [isConnected, rolePlay])

  useEffect(() => {
    // When newReply changes, add to messages
    if (newReply.length > 0) {
      setMessages([...messages, ...newReply])
    }
  }, [newReply])

  useEffect(() => {
    // When messages changes, play reply if available
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1]
      if (isStarted && !paused && lastMessage.tts) {
        audioPlayer.current = new Audio(
          'data:audio/wav;base64,' + lastMessage.tts.audio
        )
        audioPlayer.current.addEventListener('ended', () => {
          startRecording()
          audioPlayer.current = null
        })
        audioPlayer.current.play()
      }
    }
  }, [isStarted, messages])

  useEffect(() => {
    if (isStarted) {
      setUpStream()
      timer.reset()
      timer.start()
    }
  }, [isStarted])

  const audioListener = event => {
    if (event.data.size > 0 && isConnected && isInitialized) {
      socket.emit('audio', event.data)
    }
  }
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        sampleRate: 16000
      })

      mediaRecorder.current = new MediaRecorder(stream)
      mediaRecorder.current.addEventListener('dataavailable', audioListener)
      mediaRecorder.current.start(250)
    } catch (err) {
      console.error(err)
    }
  }

  const stopRecording = () => {
    mediaRecorder.current?.stop()
    mediaRecorder.current?.stream.getTracks().forEach(track => track.stop())
    mediaRecorder.current?.removeEventListener('dataavailable', audioListener)
  }

  const handlePause = () => {
    timer.pause()
    stopRecording()
    audioPlayer.current?.pause()
    setPaused(true)
  }

  const handlePauseClick = () => {
    handlePause()
    setStopWithPaused(true)
  }

  const handleResume = () => {
    timer.start()
    setPaused(false)
    if (audioPlayer.current) {
      audioPlayer.current.play()
    } else {
      startRecording()
    }
    setStopWithPaused(false)
  }

  const handleStopClick = () => {
    handlePause()
    if (messages.length >= 4) {
      setEndCallDialogOpen(true)
    } else {
      setCancelDialogOpen(true)
      setStopClicked(true)
    }
  }

  const handleCompletion = async confirmed => {
    setEndCallDialogOpen(false)
    if (!confirmed) {
      if (!stopWithPaused) {
        handleResume()
      }
      return
    }
    setCompleteLoading(true)
    const res = await postRequest(AI_ROLE_PLAY_V2_URL, {
      rolePlayId: rolePlay.id,
      rolePlayMessages: messages.map(m => ({
        role: m.role,
        content: m.content
      })),
      isWithAudio: false,
      notes: notes.length ? notes : null,
      complete: true,
      type: ROLE_PLAY_TYPES.LIVE_CALL,
      conversationSeconds: timer.totalSeconds
    })
    if (res.status === 200) {
      setCompletedRolePlay(res.data.data?.userRolePlay)
      setIsCompleted(true)
      stopStream()
      setCompleteDialogOpen(true)
    }
    setCompleteLoading(false)
  }

  const handleCancelClose = confirmed => {
    if (confirmed) {
      stopStream()
    } else if (!stopWithPaused) {
      handleResume()
    }
    setCancelDialogOpen(false)
  }

  const isMicrophoneAvailable = async () => {
    try {
        await navigator.mediaDevices.getUserMedia({ audio: true })
        return true
    } catch(err) {
        return false;
    }
  }

  return (
    <>
      <RolePlayInteractionCard
        rolePlay={rolePlay}
        buttonText={
          isCompleted
            ? t('rolePlay.viewYourResults')
            : isStarted
              ? undefined
              : t('rolePlay.startLiveCall')
        }
        onButtonClick={() =>
          !isCompleted
            ? attemptStart()
            : setActiveUserRolePlayId(completedRolePlay?.id)
        } // TODO: navigate over to result page
        showAudioIcon={isStarted}
        type={ROLE_PLAY_TYPES.LIVE_CALL}
        completeTime={
          isCompleted ? completedRolePlay?.conversationSeconds : undefined
        }
        height={isStarted ? 400 : undefined}>
        {isStarted && !isCompleted && (
          <>
            <Typography
              variant='body1'
              color='Background'>{`${padNumber(timer.minutes)}:${padNumber(timer.seconds)}`}</Typography>
            <RowStack width='fit-content'>
              {paused ? (
                <InverseIconButton
                  onClick={handleResume}
                  icon={<PlayIcon fontSize='large' />}
                />
              ) : (
                <InverseIconButton
                  onClick={handlePauseClick}
                  icon={<PauseIcon fontSize='large' />}
                />
              )}
              <InverseIconButton
                onClick={handleStopClick}
                icon={<StopIcon fontSize='large' />}
              />
            </RowStack>
            <StyledButton
              onClick={() => {
                handlePause()
                setStopClicked(false)
                setCancelDialogOpen(true)
              }}
              sx={{
                paddingY: 0,
                paddingX: 5,
                fontSize: 'small',
                backgroundColor: theme.palette.primary.light,
                boxShadow: 'none'
              }}>
              <Typography variant='body1Small' color={colors.White}>
                {t('rolePlay.cancelCall')}
              </Typography>
            </StyledButton>
          </>
        )}
      </RolePlayInteractionCard>
      <OverlayWithText
        isOpen={completeLoading}
        message={t('rolePlay.holdTight')}
      />
      <UnavoidableConfirmCancel
        open={cancelDialogOpen}
        stopClicked
        title={t(
          `rolePlay.${stopClicked ? 'sureYouWantToEndCall' : 'sureYouWantCancelCall'}`
        )}
        subtitle={t(
          `rolePlay.${stopClicked ? 'callHasNotMetRequirements' : 'sessionWillNotBeSavedAndNoFeedback'}`
        )}
        lowFocusText={t('rolePlay.resume')}
        lowFocusAction={() => handleCancelClose(false)}
        highFocusText={t(`rolePlay.${stopClicked ? 'endCall' : 'cancelCall'}`)}
        highFocusAction={() => handleCancelClose(true)}
        dialogWidth={440}
      />
      <UnavoidableConfirmCancel
        open={endCallDialogOpen}
        title={t('rolePlay.sureYouEndCallReceiveFeedback')}
        lowFocusText={t('rolePlay.resume')}
        lowFocusAction={() => handleCompletion(false)}
        highFocusText={t('rolePlay.endCall')}
        highFocusAction={() => handleCompletion(true)}
        dialogWidth={440}
      />
      <RolePlayCompletedPopup
        open={completeDialogOpen}
        handleClose={() => setCompleteDialogOpen(false)}
        handleReview={() => setActiveUserRolePlayId(completedRolePlay?.id)}
        rolePlay={rolePlay}
      />
      <Snackbar
        open={isMicAlert}
        sx={{ paddingTop: 10 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClick={() => setIsMicAlert(false)}
        onClose={() => setIsMicAlert(false)}
      >
        <Alert
          sx={{
            userSelect: 'none',
            textWrap: 'nowrap',
            borderRadius: 3
          }}
          onClose={() => setIsMicAlert(false)}
          severity='error'
        >
          {t('rolePlay.pleaseEnableTheMic')}
        </Alert>
      </Snackbar>
    </>
  )
}

export default RolePlayCall
