import React, { useState } from 'react'
import { useFormik } from 'formik'
import { CardContent, Typography, Alert, Grid, Stack } from '@mui/material'
import { LOGIN } from 'routes/constants'
import { LOGIN_TYPES, SIGNUP_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import { signupValidationSchema } from 'utils/formValidations'
import {
  EmailInputBox,
  PasswordInputBox,
  TextInputBox
} from 'components/atoms/input/FormInputBox'
import LinkRoute from 'components/atoms/LinkRoute'
import FloatingCard from 'components/atoms/container/FloatingCard'
import { getUserSigninMessage } from 'services/userServices'
import { useTranslation } from 'react-i18next'
import ThirdPartyLoginStack from 'components/molecules/oAuth/ThirdPartyLoginStack'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import StyledButton from 'components/atoms/button/StyledButton'
import RememberMeToggle from 'components/molecules/RememberMeToggle'

const SignUpCard = ({
  handleSignupSuccessful,
  firstName = '',
  lastName = '',
  email = '',
  allowEmailChange
}) => {
  const { t, i18n } = useTranslation()
  const [requestSuccess, setRequestSuccess] = useState(undefined)
  const [message, setMessage] = useState(t('company.updatedSucessfully'))
  const [isRememberMe, setIsRememberMe] = useState(false)
  const disableEmailEdit = email.length > 0 && !allowEmailChange

  const signupCallback = res => {
    const msg = getUserSigninMessage(res)
    if (msg.success) {
      setRequestSuccess(true)
      handleSignupSuccessful(res.data.data)
    } else {
      setRequestSuccess(false)
      setMessage(msg.text)
    }
  }

  const formik = useFormik({
    initialValues: {
      email,
      password: '',
      firstName,
      lastName
    },
    validationSchema: signupValidationSchema,
    onSubmit: async values => {
      const requestBody = {
        password: values.password,
        email: values.email.trim(),
        login_type: LOGIN_TYPES.PASSWORD,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        preferLanguage: i18n.resolvedLanguage
      }
      const res = await postRequest(SIGNUP_URL, requestBody)
      signupCallback(res)
    }
  })

  return (
    <>
      <FloatingCard>
        <form onSubmit={formik.handleSubmit}>
          {requestSuccess === true ? (
            <CardContent>
              <Alert severity='info'>{message}</Alert>
            </CardContent>
          ) : (
            <InCardStack spacing={20}>
              <Stack padding={0} gap={10}>
                <Grid container spacing={10} justifyContent='center'>
                  <Grid item xs={6}>
                    <TextInputBox
                      id='firstname'
                      placeholder={t('company.firstName')}
                      formik={formik}
                      attributeName='firstName'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInputBox
                      id='lastname'
                      placeholder={t('company.lastName')}
                      formik={formik}
                      attributeName='lastName'
                    />
                  </Grid>
                </Grid>
                <EmailInputBox formik={formik} isDisabled={disableEmailEdit} />
                <PasswordInputBox isValidate formik={formik} />
                <RememberMeToggle
                  isRememberMe={isRememberMe}
                  setIsRememberMe={setIsRememberMe}
                />
              </Stack>
              <StyledButton
                sx={{
                  width: '100%',
                  fontWeight: 600
                }}
                type='submit'>
                {t('authPages.acceptInvite')}
              </StyledButton>
              <ThirdPartyLoginStack
                labelPrefix={t('authPages.signUpWith')}
                signinCallback={signupCallback}
                email={email}
              />
              {requestSuccess === false ? (
                <CardContent>
                  <Alert severity='error'>{message}</Alert>
                </CardContent>
              ) : null}
            </InCardStack>
          )}
        </form>
      </FloatingCard>

      <LinkRoute type='button' to={LOGIN}>
        <Typography variant='body1Small' color={colors.LightGrey400}>
          {t('authPages.alreadyHaveAccount')}
          <Typography variant='body1Small' color={colors.Teal400}>
            {t('authPages.logIn')}
          </Typography>
        </Typography>
      </LinkRoute>
    </>
  )
}
export default SignUpCard
