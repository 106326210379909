import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { LowConstrastButton } from 'components/atoms/button/StyledButton'
import RowStack from 'components/atoms/container/RowStack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CompactRadarGraph from './CompactRadarGraph'
import { formatDate } from 'utils/formatDateTime'
import { getFullName } from 'utils/formatText'
import BoxWithBackground from 'components/atoms/container/BoxWithBackground'
import colors from 'styles/colors'
import { getConstant } from 'services/constantServices'
import { CONSTANTS_OPTIONS } from 'services/constants'
import InCardStack from 'components/atoms/InCardStack'
import RoundContainer from 'components/atoms/container/RoundContainer'

const ScoreArray = ({ score, idx }) => {
  const theme = useTheme()
  const options = [1, 2, 3, 4, 5]
  return (
    <RowStack spacing={8} width='auto'>
      {options.map(opt => (
        <RoundContainer
          key={`${idx}-option-${opt}`}
          number={opt}
          size={28}
          backgroundColor={
            score === opt ? theme.palette.primary.main : colors.LightGrey200
          }>
          <Typography color={theme.palette.background.paper}>{opt}</Typography>
        </RoundContainer>
      ))}
    </RowStack>
  )
}

const CheckInAnswersButton = ({ userCalendarEvent, surveyUser }) => {
  const { t } = useTranslation()
  const [showAnswers, setShowAnswers] = useState(false)
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getConstant(
        CONSTANTS_OPTIONS.ONE_ON_ONE_SURVEY_EVALUATIONS
      )
      if (res) {
        setQuestions(res)
      }
    }
    fetchData()
  }, [])
  return (
    <>
      <LowConstrastButton onClick={() => setShowAnswers(true)} size='small'>
        {t('checkInSurvey.viewAnswers')}
      </LowConstrastButton>
      <Dialog
        onClose={() => setShowAnswers(false)}
        open={showAnswers}
        maxWidth='md'>
        <Stack spacing={10} width={700}>
          <RowStack justifyContent='space-between'>
            <Typography variant='h3'>{`${getFullName(surveyUser)} - ${formatDate(userCalendarEvent.startTime)}`}</Typography>
            <IconButton onClick={() => setShowAnswers(false)}>
              <Close />
            </IconButton>
          </RowStack>
          <RowStack spacing={16}>
            <Box width={350}>
              <CompactRadarGraph
                assessmentData={userCalendarEvent.surveyDisplayData}
              />
            </Box>
            <Stack padding={0} spacing={8} width={270}>
              <BoxWithBackground
                backgroundColor={colors.LightBlue}
                updatedStyle={true}>
                <Typography variant='button' color='primary'>
                  {t('checkInSurvey.currentLabel', {
                    score: userCalendarEvent.surveyDisplayData.currentAverage
                  })}
                </Typography>
              </BoxWithBackground>
              <BoxWithBackground
                backgroundColor={colors.LightGrey100}
                updatedStyle={true}>
                <Stack padding={0}>
                  <Typography variant='button' color={colors.Grey400}>
                    {t('checkInSurvey.historicalLabel', {
                      score: userCalendarEvent.surveyDisplayData.previousAverage
                        ? userCalendarEvent.surveyDisplayData.previousAverage
                        : 'N/A'
                    })}
                  </Typography>
                  <Typography variant='body1Micro' color={colors.Grey400}>
                    {t('checkInSurvey.lastNSurveysLabel', {
                      count: userCalendarEvent.surveyDisplayData.previousCount
                    })}
                  </Typography>
                </Stack>
              </BoxWithBackground>
              <Typography variant='body1Micro' color={colors.Grey400}>
                {t('checkInSurvey.scoreRange')}
              </Typography>
            </Stack>
          </RowStack>
          <InCardStack spacing={6}>
            {questions.map((q, idx) => (
              <BoxWithBackground
                key={idx}
                backgroundColor={colors.LightGrey100}
                updatedStyle={true}>
                <RowStack spacing={8}>
                  <RowStack alignItems='flex-start' spacing={4}>
                    <Typography variant='h5'>{idx + 1}.</Typography>
                    <Typography variant='body1Small'>
                      <Typography fontWeight={600} component='span'>
                        {q.virtue}:{' '}
                      </Typography>
                      {q.description}
                    </Typography>
                  </RowStack>
                  <ScoreArray
                    score={userCalendarEvent.surveyResults[idx]}
                    idx={idx}
                  />
                </RowStack>
              </BoxWithBackground>
            ))}
          </InCardStack>
        </Stack>
      </Dialog>
    </>
  )
}

export default CheckInAnswersButton
