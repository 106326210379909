import { Divider, Stack, Typography, Box } from '@mui/material'
/*
 * This component place labels under a line for each integer in range
 * Start position should be a decimal number between 0 and 1
 */
const ScaleBar = props => {
  const { startPosition, startNumber, endNumber } = props
  const arrLength = endNumber - startNumber
  const width = (1 - startPosition) / arrLength
  const numbArr = Array.from(
    { length: endNumber - startNumber },
    (_, k) => startNumber + k + 1
  )
  const variant = 'caption'
  return (
    <Box width='100%'>
      <Divider />
      <Stack padding={0} direction='row' justifyContent='right' width='100%'>
        <Typography variant={variant} width={startPosition} textAlign='right'>
          {startNumber}
        </Typography>
        {numbArr.map(value => (
          <Typography
            width={width}
            variant={variant}
            textAlign='right'
            key={`scale-label-${value}`}>
            {value}
          </Typography>
        ))}
      </Stack>
    </Box>
  )
}

export default ScaleBar
