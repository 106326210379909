import { Stack, Box, Typography, Divider } from '@mui/material'
import ReportPage from 'components/templates/ReportPage'
import colors from 'styles/colors'
import { BloomingFlowerIcon } from 'components/atoms/Icons'
import { useTranslation } from 'react-i18next'

const InCardStack = ({ children, ...props }) => (
  <Stack padding={0} spacing={10} {...props}>
    {children}
  </Stack>
)

const WrapupPage = ({ assessmentName, downloadingFile, pageNum }) => {
  const { t } = useTranslation()

  const typoSx = {
    lineHeight: '140%',
    fontWeight: 500,
    color: colors.MidnightTeal400
  }

  return (
    <>
      <ReportPage
        downloadingFile={downloadingFile}
        assessmentName={assessmentName}
        pageNum={pageNum}
        childrenWrapperSx={{ justifyContent: 'center' }}>
        <InCardStack spacing={7} width={300}>
          <Typography variant='body1Micro' fontStyle={'italic'} sx={typoSx}>
            {t('fullReport.youNotAlone')}
          </Typography>

          <InCardStack>
            <Typography variant='formText' sx={typoSx}>
              <strong>{t('fullReport.everyone')}</strong>{' '}
              {t('fullReport.roomToImprove')}
            </Typography>

            <Divider
              sx={{
                borderColor: colors.MidnightTeal400,
                width: 210
              }}
            />

            <Typography variant='body1Micro' sx={typoSx}>
              <strong>{t('fullReport.informationAsNegative')}</strong>
            </Typography>
          </InCardStack>

          <Typography variant='body1Micro' sx={typoSx}>
            {t('fullReport.keepInMind')}
          </Typography>
        </InCardStack>

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-10%, -44%)'
          }}>
          <BloomingFlowerIcon sx={{ width: 998, height: 974 }} />
        </Box>
      </ReportPage>
    </>
  )
}

export default WrapupPage
