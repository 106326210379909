import { Switch, useTheme } from '@mui/material'

// Styling from MUI demo https://codesandbox.io/s/mnh8jn?file=/demo.tsx:3701-4891
const SettingSwitch = ({testId, ...otherProps}) => {
  const theme = useTheme()
  return (
    <Switch
      inputProps={{'data-testid': testId}}
      {...otherProps}
      sx={{
        width: 44,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor:
                theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
              opacity: 1,
              border: 0
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5
            }
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff'
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600]
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.5 : 0.3
          }
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
          marginTop: -1,
          marginLeft: -1
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.grey[600]
              : '#39393D',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500
          })
        }
      }}
    />
  )
}

export default SettingSwitch
