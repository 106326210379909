import { Typography } from '@mui/material'
import RowStack from 'components/atoms/container/RowStack'
import InCardStack from 'components/atoms/InCardStack'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import AppUsageProgressBar from 'components/molecules/adminDashboard/AppUsageProgressBar'
import TeamProgressTable from './TeamProgressTable'
import BackIconTextButton from 'components/atoms/button/BackIconTextButton'

export const CompletionRateProgress = ({
  completionRate,
  primary = colors.GoldenYellow400,
  secondary = colors.White,
  typoSx = {}
}) => {
  const { t } = useTranslation()
  const sx = { color: colors.Black, ...typoSx }
  return (
    <InCardStack spacing={4} width={'100%'}>
      <RowStack justifyContent={'space-between'}>
        <Typography variant='body2' sx={sx}>
          {t('fullReport.assessmentCompletionRate')}
        </Typography>
        <Typography variant='body2' sx={sx}>
          {completionRate}%
        </Typography>
      </RowStack>

      <AppUsageProgressBar
        progress={completionRate}
        primary={primary}
        secondary={secondary}
        height={16}
        borderRadius={100}
        sx={{ width: '100%' }}
      />
    </InCardStack>
  )
}

const TeamProgress = ({
  teamData,
  completionRate,
  participants,
  completedAssessments,
  setShowDashboard,
  showBackButton = true
}) => {
  const { t } = useTranslation()

  const typoSx = {
    fontSize: '14px',
    fontWeight: 600
  }

  const StatsBox = ({ stat, name }) => {
    const BoxSx = {
      backgroundColor: colors.MutedTeal50,
      padding: 6,
      borderWidth: 0,
      borderRadius: 2,
      height: 70,
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      '& .MuiTypography-root': {
        color: colors.Grey400
      }
    }

    return (
      <BoxWithBorder sx={BoxSx}>
        <Typography sx={{ ...typoSx, fontSize: 28 }}>{stat}</Typography>
        <Typography
          sx={{
            ...typoSx,
            lineHeight: '120%',
            width: '100px'
          }}>
          {name}
        </Typography>
      </BoxWithBorder>
    )
  }

  const CompletionRate = () => {
    return (
      <RowStack width={'auto'} spacing={4}>
        <BoxWithBorder
          borderWidth={1}
          borderColor={colors.Grey}
          borderRadius={2}
          padding={'12px 16px'}
          width='380px'
          height='70px'>
          <CompletionRateProgress
            completionRate={completionRate}
            secondary={colors.MutedTeal100}
            typoSx={typoSx}
          />
        </BoxWithBorder>

        <StatsBox stat={participants} name={t('contact.participants')} />

        <StatsBox
          stat={completedAssessments}
          name={t('fullReport.completedAssessments')}
        />
      </RowStack>
    )
  }

  return (
    <>
     {showBackButton && <BackIconTextButton onClick={()=>setShowDashboard(false)} />}
      <RowStack
        margin={'24px 0'}
        width={'100%'}
        justifyContent={'space-between'}>
        <Typography variant='h1' color={colors.Teal400}>
          {t('fullReport.teamProgress')}
        </Typography>

        <CompletionRate />
      </RowStack>

      {teamData && <TeamProgressTable teamData={teamData} />}
    </>
  )
}
export default TeamProgress
