import { Button, CardContent, Stack, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import { useEffect, useState } from 'react'
import { TaskAlt } from '@mui/icons-material'
import LeftJustifyCardContent from 'components/atoms/LeftJustifyCardContent'
import PulseSurveyOptions from '../FiveNumberOptions'
import { postRequest } from 'utils/api'
import { GOAL_STEP_USER_FEEDBACK_URL } from 'services/constants'
import { TaskSuccessIcon } from 'components/atoms/Icons'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { getPopupConfig, setPopupConfig } from 'utils/localStorage'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import { useTranslation } from 'react-i18next'
import { NARROW_BROWSER_WIDTH } from 'utils/constants'

const popupTypeRate = 'rateMileStoneDisable'
const StepFeedback = ({
  l2StepData,
  organizedSteps,
  completeAction,
  pathOpen,
  togglePathOpen
}) => {
  const { t } = useTranslation()
  const [l2StepNames, setL2StepNames] = useState([])
  const [l1StepName, setL1StepName] = useState('')
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const [requestError, setRequestError] = useState(undefined)
  const [showRateReminder, setShowRateReminder] = useState(false)
  const [raterReminderShown, setRaterReminderShown] = useState(false)

  const findL1Step = () => {
    const sortedSteps = [...organizedSteps].sort((a, b) => b.number - a.number)
    const currentL1Step = sortedSteps.find(
      l1Step => l1Step.number < l2StepData.number
    )
    return currentL1Step
  }

  useEffect(() => {
    completeAction(false, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (organizedSteps) {
      const currentL1Step = findL1Step()
      const newL2StepNames = currentL1Step.childL2Steps.map(
        l2Step => l2Step.name
      )
      setL2StepNames(newL2StepNames)
      setL1StepName(currentL1Step.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizedSteps, l2StepData])

  const submitRating = async rating => {
    const requestBody = {
      checkInScore: rating
    }
    const l1Step = findL1Step()
    const res = await postRequest(
      GOAL_STEP_USER_FEEDBACK_URL.replace('{goal_step_id}', l1Step.id),
      requestBody
    )
    if (res.status === 200) {
      setFeedbackSubmitted(true)
    } else {
      setRequestError(true)
    }
  }

  const handleCompleteClick = () => {
    const popupDisabled = getPopupConfig(popupTypeRate)
    if (!popupDisabled && !feedbackSubmitted && !raterReminderShown) {
      setShowRateReminder(true)
      setRaterReminderShown(true)
    } else {
      completeAction(true, false)

      // Open sidebar if it's closed on milestone completion
      if (!pathOpen && window.innerWidth > NARROW_BROWSER_WIDTH) {
        togglePathOpen()
      }
    }
  }

  const handleRateDialogSelect = (confirmed, disableRateDialog) => {
    if (disableRateDialog) {
      setPopupConfig(popupTypeRate, true)
    }
    setShowRateReminder(false)

    if (!confirmed) {
      handleCompleteClick()
    }
  }

  return (
    <InCardStack spacing={0} sx={{ alignItems: 'left' }}>
      <LeftJustifyCardContent>
        <Stack padding={5} spacing={5}>
          {l2StepNames.map(name => (
            <Typography
              key={name}
              display='flex'
              alignItems='center'
              variant='subtitle1'>
              <TaskAlt
                fontSize='large'
                color='primary'
                sx={{ marginRight: 5 }}
              />
              {name}
            </Typography>
          ))}
        </Stack>
      </LeftJustifyCardContent>
      <LeftJustifyCardContent>
        <Typography variant='h3'>{t('stepFeedback.checkIn')}</Typography>
      </LeftJustifyCardContent>
      <CardContent>
        <Typography>
          {t('stepFeedback.EnjoyedLearningAbout')} {l1StepName}
        </Typography>
      </CardContent>
      {feedbackSubmitted === false ? (
        <>
          <PulseSurveyOptions onClick={rating => submitRating(rating)} />
          {requestError === true ? (
            <CardContent>
              <RequestRetryAlert />
            </CardContent>
          ) : null}
        </>
      ) : (
        <CardContent>
          <InCardStack>
            <TaskSuccessIcon />
            <Typography variant='h3'>
              {t('stepFeedback.thankYouForFeedback')}
            </Typography>
          </InCardStack>
        </CardContent>
      )}
      <CardContent>
        <Button onClick={() => handleCompleteClick()}>
          {t('stepFeedback.completeMilestone')}
        </Button>
      </CardContent>
      <ConfirmCancelDialog
        open={showRateReminder}
        titleText={t('stepFeedback.beforeYouMoveOn')}
        maxWidth='sm'
        onClose={handleRateDialogSelect}
        notificationText={t('stepFeedback.takeMomentToRate')}
        confirmText={t('stepFeedback.rateNow')}
        cancelText={t('common.noThanks')}
        cancelValue={3}
        buttonsDirection='row'
        bodyWidth={400}
        showDisableToggle={true}
      />
    </InCardStack>
  )
}

export default StepFeedback
