import {
  Box,
  Button,
  CardContent,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import { useNavigate } from 'react-router-dom'
import { ADD_ASSESSMENT_RATERS, HOME } from 'routes/constants'
import RadarGraph from './RadarGraph'
import ToolTipCustomized from 'components/atoms/container/ToolTipCustomized'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'

const SelfAssessmentReport = ({
  resultData,
  nextStepCallback,
  disableBehaviorClick
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const enableOthersAssessment = resultData.goalData?.enableOthersAssessment

  const handleNextStep = () => {
    if (nextStepCallback) {
      nextStepCallback()
    } else if (enableOthersAssessment) {
      navigate(`${ADD_ASSESSMENT_RATERS}/${resultData.goalData.id}`, {
        state: { fromSelfAssessment: true, assessmentData: resultData }
      })
    } else {
      navigate(HOME)
    }
  }
  return (
    <CardContent data-testid='self-assessment-report'>
      <InCardStack spacing={10} width='60%' minWidth='550px'>
        <InCardStack spacing={0}>
          <Typography variant='h2' display='flex' alignItems='center'>
            {t('assessmentTimeline.selfAssessmentReport')}
            <ToolTipCustomized
              toolTip={
                <Stack padding={5}>
                  <Typography color={colors.White}>
                    {t('assessmentTimeline.howYouScored')}
                  </Typography>
                </Stack>
              }
            />
          </Typography>
          <Typography variant='subtitle1'>
            {resultData.goalData.leadershipModel.name}:{' '}
            {resultData.goalData.name}
          </Typography>
        </InCardStack>
        <RadarGraph
          assessmentData={resultData}
          size={500}
          disableBehaviorClick={disableBehaviorClick}
        />
        {disableBehaviorClick ? null : (
          <Typography color='primary' fontStyle='italic'>
            {t('goalsArea.clickBehaviours')}
          </Typography>
        )}
        <Box
          paddingX='70px'
          paddingY='15px'
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: 5
          }}>
          <Typography variant='subtitle1' component='span'>
            {t('goalsArea.overallScore')}
          </Typography>
          <Typography
            variant='subtitle1'
            color='primary'
            component='span'
            marginLeft='40px'>
            {t('goalsArea.self')}: {resultData.selfAllBehaviourAvg.toFixed(2)}
          </Typography>
        </Box>
        <Button onClick={() => handleNextStep()} sx={{ paddingX: 50 }}>
          {enableOthersAssessment ? t('assessmentTimeline.nextStep') : t('common.done')}
        </Button>
      </InCardStack>
    </CardContent>
  )
}

export default SelfAssessmentReport
