import AuthorizedPage from 'components/templates/AuthorizedPage'
import ContentHeaderTabs from 'components/organisms/ContentHeaderTabs'
import { PAGE_TITLES } from 'utils/constants'
import { Box } from '@mui/material'

const FeedbackPage = ({ children, ...otherProps }) => {
  return (
    <AuthorizedPage
      pageTitle={PAGE_TITLES.FEEDBACK}
      {...otherProps}
      headerTabs={<ContentHeaderTabs activePage='feedback' />}>
      <Box width='100%' height='inherit'>{children}</Box>
    </AuthorizedPage>
  )
}

export default FeedbackPage
