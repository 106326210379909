import { Typography, darken, lighten } from '@mui/material'
import ReportPage from 'components/templates/ReportPage'
import colors from 'styles/colors'
import InCardStack from 'components/atoms/InCardStack'
import OverallScoreDisplay from './OverallScoreDisplay'
import { BehaviourTable, BehaviourTableRow } from './ReportTable'
import { useTranslation } from 'react-i18next'

const CategoryTablePage = ({
  downloadingFile,
  categoryData,
  assessmentName,
  pageNum
}) => {
  const { t } = useTranslation()
  const categoryColor = categoryData?.color || colors.Black
  const darkCategoryColor = darken(categoryColor, 0.2) || colors.Black
  const lightCategoryColor = lighten(categoryColor, 0.85) || colors.Black

  return (
    <>
      <ReportPage
        downloadingFile={downloadingFile}
        assessmentName={assessmentName}
        pageNum={pageNum}>
        <InCardStack spacing={9}>
          <Typography variant='h6' color={categoryColor}>
            {categoryData?.modelName}
          </Typography>

          <Typography variant='reportH1' sx={{ color: darkCategoryColor }}>
            {categoryData?.name}
          </Typography>

          <OverallScoreDisplay
            score={categoryData?.data?.selfAllBehaviourAvg}
            categoryColor={darkCategoryColor}
            containerStyle={{ marginTop: 26 }}
          />

          <Typography
            variant='formTagText'
            color={darkCategoryColor}
            style={{ marginTop: 44, marginBottom: 14 }}>
            {categoryData?.name} {t('fullReport.comprisedOfAreas')}
          </Typography>

          <BehaviourTable>
            {categoryData?.data?.behaviourInfoData.map((value, index) => (
              <BehaviourTableRow
                key={`behaviour-${index}`}
                name={value.name}
                score={value.avgSelfRating?.toFixed(2)}
                index={index}
                fontColor={darkCategoryColor}
                backColor={lightCategoryColor}
              />
            ))}
          </BehaviourTable>
        </InCardStack>
      </ReportPage>
    </>
  )
}

export default CategoryTablePage
