import * as yup from 'yup'
import React, { useState } from 'react'
import HomePage from 'components/templates/navigationPageTemplates/HomePage'
import { Box, Button, CardContent, Divider, Typography } from '@mui/material'
import { PageHeader } from 'components/atoms/Text'
import SinglePageStack from 'components/templates/SinglePageStack'
import { Subtitle3 } from 'components/atoms/Text'
import StyledAccordion from 'components/atoms/container/Accordion'
import InputDialog from 'components/molecules/notificationOverlay/InputDialog'
import { useFormik } from 'formik'
import { SUPPORT_REQUEST_URL } from 'services/constants'
import { postRequest } from 'utils/api'
import { validationsRules } from 'utils/formValidations'
import SuccessfulNotificationDialog from 'components/molecules/notificationOverlay/SuccessfulNotificationDialog'
import FormInputBox from 'components/atoms/input/FormInputBox'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import { useTranslation } from 'react-i18next'

const HelpAndSupport = () => {
  const { t } = useTranslation()
  const [supportDialogOpen, setSupportDialogOpen] = useState(false)
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [requestSentSuccessful, setRequestSentSuccessful] = useState(undefined)
  const formik = useFormik({
    initialValues: {
      requestText: ''
    },
    validationSchema: yup.object({
      requestText: validationsRules.textRequired
    }),
    onSubmit: async values => {
      const res = await postRequest(SUPPORT_REQUEST_URL, {
        comment: values.requestText
      })
      if (res.status === 200) {
        setRequestSentSuccessful(true)
        setSupportDialogOpen(false)
        setSuccessDialogOpen(true)
      } else {
        setRequestSentSuccessful(false)
      }
    }
  })

  const handleSupportOpen = () => {
    formik.resetForm()
    setRequestSentSuccessful(undefined)
    setSupportDialogOpen(true)
  }
  return (
    <HomePage>
      <SinglePageStack spacing={10}>
        <PageHeader>{t('headers.helpSupport')}</PageHeader>
        <Typography variant='subtitle1'>{t('support.frequentlyAskedQuestions')}</Typography>
        {Array.isArray(t('support.FAQ_LIST')) ? (
          t('support.FAQ_LIST').map((item, index) => (
            <StyledAccordion
              initialExpanded={index === 0}
              key={index}
              summaryComponent={<>{item.question}</>}>
              <Typography variant='body2'>{item.answer}</Typography>
            </StyledAccordion>
          ))
        ) : null}
        <Divider />
        <PageHeader>{t('company.contactUs')}</PageHeader>
        <Typography variant='subtitle1' style={{ marginTop: 5 }}>
          {t('support.haveQuestion')}
        </Typography>
        <CardContent>
          <Button onClick={() => handleSupportOpen()}>{t('common.sendMessage')}</Button>
        </CardContent>
      </SinglePageStack>
      <InputDialog
        open={supportDialogOpen}
        titleText={t('support.supportRequest')}
        handleCancel={() => setSupportDialogOpen(false)}
        formik={formik}
        confirmText={t('common.send')}
        cancelText={t('common.cancel')}>
        <Box width='100%'>
          <Subtitle3>{t('support.howCanWeHelpYou')}</Subtitle3>
        </Box>
        <FormInputBox
          sx={{ width: '100%' }}
          type='text'
          formik={formik}
          multiline
          rows={3}
          attributeName='requestText'
          placeholder={t('teamShoutOut.comments')}
        />
        {requestSentSuccessful === false ? <RequestRetryAlert /> : null}
      </InputDialog>
      <SuccessfulNotificationDialog
        open={successDialogOpen}
        titleText={t('support.yourRequestReceived')}
        confirmButtonText={t('common.done')}
        buttonAction={() => setSuccessDialogOpen(false)}
      />
    </HomePage>
  )
}

export default HelpAndSupport
