import { IconButton, Tooltip, Typography } from '@mui/material'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { deleteRequest } from 'utils/api'
import { USER_LICENSES_URL } from 'services/constants'
import { useState } from 'react'
import ConfirmCancelDialog from '../notificationOverlay/ConfirmCancelDialog'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import OverlayLoading from 'components/atoms/OverlayLoading'
import { useTranslation } from 'react-i18next'

const DowngradeUserButton = ({ successCallback, user }) => {
  const { t } = useTranslation()
  const [sendDialogOpen, setSendDialogOpen] = useState(false)
  const [requestError, setRequestError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleDialogSelect = async confirmed => {
    if (confirmed) {
      setLoading(true)
      const res = await deleteRequest(
        `${USER_LICENSES_URL}/${user.userLicense.id}`
      )
      if (res.status === 204) {
        setSendDialogOpen(false)
        successCallback(user.id)
      } else setRequestError(true)
      setLoading(false)
    } else {
      setSendDialogOpen(false)
    }
  }
  return (
    <>
      <Tooltip title={t('company.downgradeUserRaterOnly')}>
        <IconButton
          onClick={() => setSendDialogOpen(true)}
          data-testid={`downgrade-user-${user.userLicense.id}`}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Tooltip>
      <ConfirmCancelDialog
        open={sendDialogOpen}
        titleText={t('company.downgradeUser')}
        onClose={handleDialogSelect}
        confirmText={t('company.yesDowngradeUser')}
        cancelText={t('common.cancel')}
        buttonsDirection='row'>
        <Typography>
          {t('company.accountTypeToRaterOnly')}
        </Typography>
        {requestError ? <RequestRetryAlert /> : null}
      </ConfirmCancelDialog>
      <OverlayLoading open={loading} />
    </>
  )
}

export default DowngradeUserButton
