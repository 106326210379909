import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Typography, Alert, Grid, Button, MenuItem, Stack } from '@mui/material'
import { ORGANIZATIONS_URL, CONSTANTS_OPTIONS } from 'services/constants'
import { getRequest, patchRequest } from 'utils/api'
import { organizationValidationSchema } from 'utils/formValidations'
import PageComponent from 'components/templates/PageComponent'
import FormInputBox from 'components/atoms/input/FormInputBox'
import RequestRetryAlert from 'components/atoms/RequestRetryAlert'
import FormSelectInput from 'components/atoms/input/FormSelectInput'
import ToolTipCustomized from 'components/atoms/container/ToolTipCustomized'
import InCardStack from 'components/atoms/InCardStack'
import { useLocation } from 'react-router-dom'
import { SBUSCRIPTION_ADMIN } from 'routes/constants'
import CohortManagement from './CohortManagement'
import { getConstant } from 'services/constantServices'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'

const CompanyInfoCard = ({ organizationId }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isSuperAdminView = location.pathname === SBUSCRIPTION_ADMIN
  const [requestSuccess, setRequestSuccess] = useState(undefined)
  const [organization, setOrganization] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [resend360AfterDays, setResend360AfterDays] = useState('')
  const [industryOptions, setIndustryOptions] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getRequest(`${ORGANIZATIONS_URL}/${organizationId}`)
      if (res.data.success) {
        setOrganization(res.data.data)
        setOrganizationName(res.data.data.name)
        setResend360AfterDays(res.data.data.resend360AfterDays?.toString())
      } else {
        setRequestSuccess(false)
      }
      const industry = await getConstant(CONSTANTS_OPTIONS.INDUSTRY_OPTIONS)
      setIndustryOptions(industry || [])
    }
    fetchData()
  }, [organizationId])

  useEffect(() => {
    if (requestSuccess) {
      const refreshTimeout = setTimeout(() => {
        setRequestSuccess(undefined)
      }, 2000)
      return () => clearTimeout(refreshTimeout)
    }
  }, [requestSuccess])

  const formik = useFormik({
    initialValues: {
      companyName: organizationName,
      resend360AfterDays: resend360AfterDays,
      industry: organization.industry || ''
    },
    enableReinitialize: true,
    validationSchema: organizationValidationSchema,
    onSubmit: async values => {
      const requestBody = {
        name: values.companyName,
        resend360AfterDays: parseInt(values.resend360AfterDays, 10),
        industry: values.industry
      }
      const res = await patchRequest(
        `${ORGANIZATIONS_URL}/${organization.id}`,
        requestBody
      )
      if (res.data.success) {
        setRequestSuccess(true)
      } else {
        setRequestSuccess(false)
      }
    }
  })

  const FieldLabel = ({ children }) => (
    <Typography
      variant='h4'
      width='100%'
      marginBottom={3}
      display='flex'
      alignItems='center'>
      {children}
    </Typography>
  )
  return (
    <PageComponent
      titleText={`${t('company.companyInformation')} - ${organization.domain}`}
      width='100%'>
      <form onSubmit={formik.handleSubmit}>
        <Grid paddingLeft={10} container>
          <Grid item xs={4}>
            <FieldLabel>{t('company.companyName')}</FieldLabel>
            <FormInputBox
              type='text'
              formik={formik}
              attributeName='companyName'
              sx={{
                input: {
                  '&::placeholder': {
                    opacity: 1
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FieldLabel>{t('company.industry')}</FieldLabel>
            <FormSelectInput
              name='industry'
              type='text'
              formik={formik}
              placeholder={t('company.yourIndustry')}
              attributeName='industry'>
              {industryOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </FormSelectInput>
          </Grid>
          <Grid item xs={4}>
            <FieldLabel>
              {t('company.scheduleAutomatic360RemindersAfter')}
              <ToolTipCustomized
                toolTip={
                  <Stack padding={5} spacing={2}>
                    <Typography variant='h5' color={colors.White}>
                      {t('company.whatDoesThisMean')}
                    </Typography>
                    <Typography variant='body1SmallWhite'>
                      {t('company.membersOrganizationToSee')}
                    </Typography>
                    <Typography paddingTop='10px' variant='body1SmallWhite'>
                      {t('company.recommend60DaysDefault')}
                    </Typography>
                  </Stack>
                }
              />
            </FieldLabel>
            <FormSelectInput
              type='text'
              name='resend360AfterDays'
              formik={formik}
              attributeName='resend360AfterDays'>
              <MenuItem key={0} value={'30'}>
                 {t('company._30Days')}
              </MenuItem>
              <MenuItem key={1} value={'60'}>
                {t('company._60Days')}
              </MenuItem>
              <MenuItem key={2} value={'90'}>
                {t('company._90Days')}
              </MenuItem>
              <MenuItem key={3} value={'120'}>
                {t('company._120Days')}
              </MenuItem>
            </FormSelectInput>
          </Grid>
        </Grid>

        <InCardStack direction='row' sx={{ paddingTop: 15, paddingLeft: 10 }}>
          <Button type='submit'>{t('company.saveChanges')}</Button>
          {requestSuccess === false ? <RequestRetryAlert /> : null}
          <Alert
            severity='success'
            sx={{
              opacity: requestSuccess === true ? 1 : 0,
              transition: 'opacity 1s'
            }}>
            {t('company.updatedSucessfully')}
          </Alert>
        </InCardStack>
      </form>
      {isSuperAdminView ? (
        <CohortManagement organizationId={organizationId} />
      ) : null}
    </PageComponent>
  )
}
export default CompanyInfoCard
