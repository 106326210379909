import { Typography } from '@mui/material'
import ReportPage from 'components/templates/ReportPage'
import colors from 'styles/colors'
import { useTranslation } from 'react-i18next'

const ReflectionPage = ({ downloadingFile, assessmentName, comments, pageNum }) => {
  const { t } = useTranslation()

  return (
    <>
      <ReportPage
        downloadingFile={downloadingFile}
        assessmentName={assessmentName}
        pageNum={pageNum}
        childrenWrapperSx={{ alignItems: 'center', gap: 12 }}>
        <Typography variant='formTagText' color={colors.Teal400} marginTop={11}>
          {t('fullReport.reflectionComments')}
        </Typography>

        <Typography
          variant='formText'
          color={colors.MidnightTeal400}
          width={380}>
          {comments}
        </Typography>
      </ReportPage>
    </>
  )
}

export default ReflectionPage
