import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import InCardStack from 'components/atoms/InCardStack'
import { Tag } from 'components/atoms/Text'
import colors from 'styles/colors'

export const Card = props => {
  const {
    children,
    width = '100%',
    padding = '40px 48px',
    borderColor = colors.LightGrey200,
    ...otherProps
  } = props
  return (
    <BoxWithBorder
      width={width}
      padding={padding}
      height={350}
      borderRadius={4}
      borderWidth={1}
      borderColor={borderColor}
      position={'relative'}
      overflow={'hidden'}
      {...otherProps}>
      {children}
    </BoxWithBorder>
  )
}

export const CardStack = props => {
  const { children, ...otherProps } = props
  return (
    <InCardStack
      alignItems={'flex-start'}
      justifyContent={'center'}
      height={'100%'}
      {...otherProps}>
      {children}
    </InCardStack>
  )
}

export const CardTag = ({ children, ...otherProps }) => {
  return (
    <Tag sx={{ padding: 2 }} {...otherProps}>
      {children}
    </Tag>
  )
}
