import { yellow } from '@mui/material/colors'
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory'

const AppUsageBarChart = ({ barData }) => {
  return (
    <VictoryChart>
      <VictoryBar
        cornerRadius={{ top: 10, bottom: 10 }}
        barWidth={20}
        data={barData}
        style={{
          data: { fill: yellow[800] }
        }}
      />
      <VictoryAxis
        tickValues={barData?.map(data => data.x)}
        tickFormat={barData?.map((data, index) => {
          if (index === 0 || index === barData.length - 1) return data.x
          return ''
        })}
        style={{
          axis: { stroke: 'transparent' },
          tickLabels: {fontSize: 20, fontWeight: 400}
        }}
      />
    </VictoryChart>
  )
}

export default AppUsageBarChart
