import { Stack } from '@mui/material'

const RowStack = props => {
  const { children, spacing = 10, alignItems = 'center', ...otherProps } = props
  return (
    <Stack
      direction='row'
      padding={0}
      spacing={spacing}
      alignItems={alignItems}
      width='100%'
      {...otherProps}>
      {children}
    </Stack>
  )
}

export default RowStack
