import moment from 'moment'
import PageComponent from 'components/templates/PageComponent'
import { useEffect, useState } from 'react'
import {
  COHORTS_URL,
  INVITED_LIST_URL,
  ORGANIZATIONS_URL,
  USERS_URL,
  USER_ROLE_TYPES
} from 'services/constants'
import { getRequest } from 'utils/api'
import UserStatsTable from 'components/molecules/adminDashboard/UserStatsTable'
import {
  getGoalNameById,
  getGoals,
  userRoleMenuItems
} from 'utils/getSupportingInfo'
import { getLocalUser } from 'utils/localStorage'
import { permitted } from 'utils/permissionCheck'
import { PERMISSION_LEVEL } from 'routes/constants'
import InvitedUsersList from 'components/molecules/adminDashboard/InvitedUsersList'
import { Stack } from '@mui/material'
import { getPercentageDisplay } from 'utils/formatText'
import { useTranslation } from 'react-i18next'

const UsersStatsList = ({
  handleUserStatsCount,
  handleInvitedUserCount,
  cohortFilter,
  companyFilter,
  handleGroupsLists,
  showUsersTableonly = false,
  height = 'inherit',
  openUserDetailsTab
}) => {
  const { t } = useTranslation()
  const [allUserStats, setAllUserStats] = useState([])
  const [licensedUserStats, setLicensedUserStats] = useState([])
  const [allInvitedUsers, setAllInvitedUsers] = useState([])
  const [invitedUsers, setInvitedUsers] = useState([])
  const [activeUserStats, setActiveUserStats] = useState([])
  const [atRiskUserStats, seAtRiskUserStats] = useState([])
  const [raterStats, setRaterStats] = useState([])
  const [fullCohortList, setFullCohortList] = useState([])
  const [fullCompanyList, setFullCompanyList] = useState([])
  const [showLicensedBy, setShowLicensedBy] = useState(false)
  const isAdmin = permitted(PERMISSION_LEVEL.SUPER_ADMIN)
  const twoWeeksAgo = moment().subtract(15, 'days')

  const filterUsers = (usersList, invitedList) => {
    if (usersList.length > 0 || invitedList.length > 0) {
      let filteredList = usersList
      let filteredInvited = invitedList
      if (cohortFilter !== '') {
        filteredList = filteredList.filter(u =>
          Boolean(u.cohorts?.find(c => c.id === cohortFilter))
        )
        filteredInvited = filteredInvited.filter(
          i => i.cohortId === cohortFilter
        )
      }
      if (companyFilter !== '') {
        filteredList = filteredList.filter(
          u => u.organizationId === companyFilter
        )
        filteredInvited = filteredInvited.filter(
          i =>
            i.organizationId === companyFilter ||
            i.subscriptionOrganizationId === companyFilter
        )
      }

      setInvitedUsers(filteredInvited)

      setRaterStats(filteredList.filter(u => u.hasLicense === false))
      const licensedUserList = filteredList.filter(u => u.hasLicense === true)

      setLicensedUserStats(licensedUserList)

      setActiveUserStats(
        licensedUserList.filter(u => Date.parse(u.lastSeen) >= twoWeeksAgo)
      )
      seAtRiskUserStats(
        licensedUserList.filter(u => Date.parse(u.lastSeen) < twoWeeksAgo)
      )
      if (licensedUserList.length > 0) {
        const licensedOrgs = [
          ...new Set(licensedUserList.map(u => u.licensedOrganizations[0]))
        ]
        if (licensedOrgs.length > 1) {
          setShowLicensedBy(true)
        } else setShowLicensedBy(false)
      }
    } else {
      setRaterStats([])
      setActiveUserStats([])
      seAtRiskUserStats([])
      setInvitedUsers([])
    }
  }

  const fetchData = async (queryParams = {}) => {
    let userList = []
    const res = await getRequest(USERS_URL, queryParams)
    if (res.status === 200) {
      userList = res.data.data
    }
    let invitedList = []
    const inviteRes = await getRequest(INVITED_LIST_URL, queryParams)
    if (res.status === 200) {
      invitedList = inviteRes.data.data
    }

    // Calculate scores and configure popup
    const goals = await getGoals()
    userList = userList.map(user => {
      if (user.currentGoalId) {
        const goal = goals.find(g => g.id === user.currentGoalId)
        if (goal) {
          user.goalName = `${goal.leadershipModelName}: ${goal.name}`
        }
      }
      if (user.completedGoals) {
        user.completedGoalsCount = user.completedGoals.length
        user.completedGoalsNames = goals
          .filter(g => user.completedGoals.includes(g.id))
          .map(g => `${g.leadershipModelName}: ${g.name}`)
      }

      if (user.activityScoreTotal) {
        const detailList = []
        if (user.pulseSurveySent) {
          detailList.push({
            name: t('overview.detailList.pulseSurveySent'),
            score: user.pulseSurveySent
          })
        }
        if (user.pulseSurveyCompleted) {
          detailList.push({
            name: t('overview.detailList.pulseSurveyCompleted'),
            score: user.pulseSurveyCompleted
          })
        }
        if (user.userFeedbacksSent) {
          detailList.push({
            name: t('overview.detailList.qualitativeFeedback'),
            score: user.userFeedbacksSent
          })
        }
        if (user.commentCreated) {
          detailList.push({
            name: t('overview.detailList.communityPostsLeadershipShouts'),
            score: user.commentCreated
          })
        }
        if (user.completedSelfAssessment) {
          detailList.push({
            name: t('overview.detailList.completedSelfAssessments'),
            score: user.completedSelfAssessment
          })
        }
        if (user.completedOthersAssessment) {
          detailList.push({
            name: t('overview.detailList.completed360Assessments'),
            score: user.completedOthersAssessment
          })
        }
        if (user.sent360Invites) {
          detailList.push({
            name: t('overview.detailList._360Sent'),
            score: user.sent360Invites
          })
        }
        if (user.mileStoneCompleted) {
          detailList.push({
            name: t('overview.detailList.milestonesCompleted'),
            score: user.mileStoneCompleted
          })
        }
        if (user.completedGoals?.length) {
          detailList.push({
            name: t('overview.detailList.learningJourneysCompleted'),
            score: user.completedGoals.length * 5
          })
        }

        user.engagementDetail = detailList.sort((a, b) => b.score - a.score)
      }

      if (user.percentageCompleteCurrentGoal > 0) {
        user.currentGoalComplete = getPercentageDisplay(
          user.percentageCompleteCurrentGoal,
          0
        )
      }

      user.atRisk = Date.parse(user.lastSeen) < twoWeeksAgo

      return user
    })

    const roleList = userRoleMenuItems()
    invitedList = await Promise.all(
      invitedList.map(async invited => {
        if (invited.goalId) {
          invited.goalName = await getGoalNameById(invited.goalId)
        }
        if (invited.userRole) {
          const roleItem = roleList.find(r => r.value === invited.userRole)
          invited.roleName = t(`company.${roleItem?.label}`)
        }
        return invited
      })
    )
    // Handle filters
    if (isAdmin) {
      // if first filtered by cohort id, filter for organization list
      if (queryParams.cohortId) {
        const companyIds = userList.map(u => u.organizationId)
        const invitedCompanyIds = invitedList.map(
          i => i.subscriptionOrganizationId
        )
        const allIds = companyIds.concat(invitedCompanyIds)
        const filteredCompany = fullCompanyList.filter(
          c => allIds.indexOf(c.id) > -1
        )
        handleGroupsLists(filteredCompany, fullCohortList)
      }
      if (queryParams.organizationId) {
        const cohortIds = []
        userList.forEach(u =>
          u.cohorts?.forEach(c => {
            if (cohortIds.indexOf(c.id) < 0) {
              cohortIds.push(c.id)
            }
          })
        )
        invitedList.forEach(i => {
          if (i.cohortId) {
            if (cohortIds.indexOf(i.cohortId) < 0) {
              cohortIds.push(i.cohortId)
            }
          }
        })
        const filteredCohort = fullCohortList.filter(
          c => cohortIds.indexOf(c.id) > -1
        )
        handleGroupsLists(fullCompanyList, filteredCohort)
      }
    } else {
      const userOrganization = getLocalUser().organization
      const companies = userList.map(u => ({
        id: u.organizationId,
        name:
          u.organizationId === userOrganization.id
            ? userOrganization.name
            : u.organizationName
      }))
      invitedList.forEach(i => {
        if (i.subscriptionOrganization) {
          companies.push({
            id: i.subscriptionOrganization.id,
            name: i.subscriptionOrganization.name
          })
        }
        if (i.organization) {
          companies.push({
            id: i.organization.id,
            name: i.organization.name
          })
        }
      })
      companies.push({ id: userOrganization.id, name: userOrganization.name })
      const uniqueComanies = [
        ...new Map(companies.map(i => [i.id, i])).values()
      ]

      const localUser = getLocalUser()
      // Coaches use only authorized cohorts list
      if (localUser.role === USER_ROLE_TYPES.COACH) {
        handleGroupsLists(uniqueComanies, getLocalUser().cohorts)
      } else if (permitted(PERMISSION_LEVEL.ADMINISTRATOR)) {
        const userCohorts = [].concat(...userList.map(c => c.cohorts))
        const uniqueCohorts = [
          ...new Map(userCohorts.map(i => [i.id, i])).values()
        ]
        handleGroupsLists(uniqueComanies, uniqueCohorts)
      }
    }
    setAllUserStats(userList)
    setAllInvitedUsers(invitedList)
    filterUsers(userList, invitedList)
  }
  const fetchGroups = async () => {
    let orgList = []
    let cohortList = []
    const cRes = await getRequest(COHORTS_URL)
    if (cRes.data.success) {
      cohortList = cRes.data.data
      setFullCohortList(cohortList)
    }
    const oRes = await getRequest(ORGANIZATIONS_URL)
    if (oRes.data.success) {
      orgList = oRes.data.data?.filter(o => o.name)
      setFullCompanyList(orgList)
    }
    handleGroupsLists(orgList, cohortList)
  }
  useEffect(() => {
    if (isAdmin) {
      fetchGroups()
    } else {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isAdmin) {
      // Handle reset filters
      if (cohortFilter === '' && companyFilter === '') {
        handleGroupsLists(fullCompanyList, fullCohortList)
        setAllUserStats([])
        setAllInvitedUsers([])
        filterUsers([], [])
      } else if (cohortFilter !== '' && companyFilter !== '') {
        // When one filtered had been set and a second filter applied
        filterUsers(allUserStats, allInvitedUsers)
      } else {
        // When first filter applied
        fetchData({
          cohortId: cohortFilter === '' ? undefined : cohortFilter,
          organizationId: companyFilter === '' ? undefined : companyFilter
        })
      }
    } else {
      filterUsers(allUserStats, allInvitedUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortFilter, companyFilter])

  // Send counts back to page for header counts
  useEffect(() => {
    handleUserStatsCount(atRiskUserStats.length, activeUserStats.length)
  }, [activeUserStats, atRiskUserStats, handleUserStatsCount])

  useEffect(() => {
    handleInvitedUserCount(invitedUsers.length)
  }, [handleInvitedUserCount, invitedUsers])

  return (
    <Stack height={height} spacing={10} padding={6} paddingTop={10}>
      {showUsersTableonly ? (
        <UserStatsTable
          data={licensedUserStats}
          showUsersAtRisk={true}
          openUserDetailsTab={openUserDetailsTab}
          title={t('overview.users')}
        />
      ) : (
        <>
          {licensedUserStats.length > 0 ? (
            <PageComponent
              titleText={t('overview.users')}
              tooltip={t('overview.usersWhoStartedLearningPath')}
              data-testid='dashboard-component-users-active'>
              <UserStatsTable
                data={licensedUserStats}
                showUsersAtRisk={true}
                showLicensedBy={showLicensedBy}
              />
            </PageComponent>
          ) : null}
          {raterStats.length > 0 ? (
            <PageComponent
              titleText={t('overview.raterOnlyUsers')}
              tooltip={t('overview.raterOnlyUsersCanRateOthers')}
              data-testid='dashboard-component-users-raters'>
              <UserStatsTable
                data={raterStats}
              />
            </PageComponent>
          ) : null}
          {invitedUsers.length > 0 ? (
            <InvitedUsersList
              invitedUsers={invitedUsers}
            />
          ) : null}
        </>
      )}
    </Stack>
  )
}

export default UsersStatsList
