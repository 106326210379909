import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import BoxWithBorder from 'components/atoms/container/BoxWithBorder'
import RowStack from 'components/atoms/container/RowStack'
// import StyledButton from 'components/atoms/button/StyledButton'
import ProfileAvatar from 'components/atoms/ProfileAvatar'
import { useTranslation } from 'react-i18next'
import colors from 'styles/colors'
import { getLocalUser } from 'utils/localStorage'
import { TextBox } from 'components/atoms/input/StyledInputs'

const LiveNote = ({
  connection,
  notes,
  setNotes,
  handleUpdateNotes,
  isClosed,
  isUserOwnedNote,
  isPrivateNote = false
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const updateNotes = setTimeout(() => {
      if (isUserOwnedNote && !isClosed) {
        handleUpdateNotes(notes)
      }
    }, 3000)
    return () => clearTimeout(updateNotes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes])

  return (
    <BoxWithBorder
      borderColor={colors.LightGrey200}
      borderWidth={1}
      borderRadius={3}
      sx={{ height: '100%', width: isPrivateNote ? '100%' : undefined }}>
      <Box
        sx={{ overflow: 'hidden', height: '64px' }}
        backgroundColor={colors.Transparent}
        paddingBottom={4}>
        <RowStack
          paddingX={10}
          paddingY={4}
          borderRadius={0}
          sx={{
            boxShadow: `0px 2px 4px ${colors.LightGrey200}`,
            borderRadius: 0,
            borderTopRightRadius: 2,
            borderTopLeftRadius: 2
          }}>
          <RowStack spacing={4}>
            {isPrivateNote ? (
              <VisibilityOffIcon />
            ) : connection ? (
              <Box
                sx={{
                  backgroundColor: isUserOwnedNote
                    ? colors.Cinnabar
                    : colors.LightGreen,
                  borderRadius: 100,
                  padding: 1
                }}>
                <ProfileAvatar
                  size='medium-small'
                  user={isUserOwnedNote ? getLocalUser() : connection}
                />
              </Box>
            ) : null}
            <Typography variant='h5'>
              {isPrivateNote
                ? t('myTeam.privateNotes')
                : t('myTeam.nameSharedNotes', {
                    name: isUserOwnedNote
                      ? t('common.your')
                      : `${connection?.firstName}'s`
                  })}
            </Typography>
          </RowStack>
          {/* <StyledButton
            onClick={() => {}}
            variant='outlined'
            size='small'
            sx={{
              textWrap: 'nowrap',
              color: colors.Aluminium,
              backgroundColor: colors.Transparent,
              borderColor: colors.LightGrey200,
              borderWidth: 1
            }}>
            {t('common.history')}
          </StyledButton> */}
        </RowStack>
      </Box>
      <Box sx={{ overflowY: 'scroll', height: 'calc(100% - 64px)' }}>
        <TextBox
          disabled={!isUserOwnedNote || isClosed}
          placeholder={
            isPrivateNote
              ? t('myTeam.anythingYouTypeHere')
              : isUserOwnedNote
                ? t('myTeam.notesHereWillBeVisible', {
                    name1: t('common.you'),
                    name2: connection?.firstName ?? 'Leader'
                  })
                : t('myTeam.nameSharedNotesWillAppear', {
                    name: `${connection?.firstName ?? 'Leader'}'s`
                  })
          }
          value={notes}
          onChange={e => setNotes(e.target.value)}
        />
      </Box>
    </BoxWithBorder>
  )
}

export default LiveNote
