import { TableCell } from '@mui/material'

const RoundingCell = ({ value, roundDigit=1, ...otherProps }) => {
  return (
    <TableCell {...otherProps}>
      {value
        ? value.toFixed(roundDigit)
        : null}
    </TableCell>
  )
}

export default RoundingCell
