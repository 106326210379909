import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ErrorNotificationDialog from 'components/molecules/notificationOverlay/ErrorNotificationDialog'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { iOS, checkIsMobile } from 'utils/deviceCheck'
import { ASSESSMENT_REPORT_SHARE } from 'routes/constants'

const MobileNotification = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const iosUrl = 'https://apps.apple.com/ca/app/monark/1589783847'
  const playUrl = 'https://play.google.com/store/apps/details?id=com.monark'

  const mobileFriendlyRoutes = [ASSESSMENT_REPORT_SHARE]

  const getDeepLink = () => {
    // Check if the Link contains 'deepLinkAction.html'
    const link = location.state?.link
    const index = link && link.indexOf('deepLinkAction.html')
    if (index && index !== -1) {
      return 'monark://' + link.substring(index)
    } else {
      return null
    }
  }

  const openAppStore = async () => {
    const deepLink = getDeepLink()
    if (deepLink) {
      // Copy the deeplink to clipboard
      await window.navigator.clipboard.writeText(deepLink)
    }

    if (iOS()) {
      setTimeout(() => {
        window.location.replace(iosUrl)
      }, 1000)
    } else if (isMobile) {
      setTimeout(() => {
        window.location.replace(playUrl)
      }, 1000)
    }
  }

  useEffect(() => {
    if (!mobileFriendlyRoutes.includes(location.pathname)) {
      if (checkIsMobile()) {
        // openAppStore()
        setSuccessDialogOpen(true)
      }
    } else {
      setSuccessDialogOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <ErrorNotificationDialog
      open={successDialogOpen}
      titleText={t('authPages.appNotAvailable')}
      notificationText={t('authPages.installTheApp')}
      confirmButtonText={t('authPages.goToStore')}
      buttonAction={() => openAppStore()}
      ButtonIcon={<ContentCopyIcon />}
    />
  )
}

export default MobileNotification
