import { Button, Dialog, Typography } from '@mui/material'
import InCardStack from 'components/atoms/InCardStack'
import { useState } from 'react'
import { getLocalUser } from 'utils/localStorage'
import AssessmentCard from 'components/organisms/assessments/AssessmentCard'
import SelfAssessmentReport from 'components/molecules/assessments/SelfAssessmentReport'
import { TaskSuccessIcon } from 'components/atoms/Icons'
import { radarGraphLocationString } from 'components/molecules/assessments/RadarGraph'
import { useTranslation } from 'react-i18next'

const celebrate = require('assets/images/celebrateSmall.png')

const StepFour = ({ selectedGoal, completeCallback }) => {
  const { t } = useTranslation()
  const [activePage, setActivePage] = useState(
    window.history.state?.location === radarGraphLocationString ? 1 : 0
  )
  const [assessmentResult, setAssessmentResult] = useState(
    window.history.state?.graphData || {}
  )
  const [resultDialogOpen, setResultDialogOpen] = useState(false)

  const userInfo = getLocalUser()

  const handleSubmit = resultData => {
    setAssessmentResult(resultData)
    setActivePage(1)
    setResultDialogOpen(true)
  }

  return (
    <InCardStack width='100%' spacing={10}>
      {activePage === 0 ? (
        <>
          <AssessmentCard
            goalId={selectedGoal.id}
            title={t('assessmentTimeline.capturingBaseline')}
            introduction={
              <>
                {t('assessmentTimeline.captureBaselineMeasurement')} <strong>{selectedGoal.leadershipPathName}</strong> .
                <br />
                <br />
                {t('assessmentTimeline.fewMinutesToAnswer')}
                <br />
              </>
            }
            startButtonText={t('assessmentTimeline.beginSelfAssessment')}
            forUserId={userInfo.id}
            assessmentTitle={t('assessmentTimeline.currentlySelfAssessment')}
            submittedCallback={handleSubmit}
            selfAssessment={true}
          />
        </>
      ) : activePage === 1 ? (
        <>
          <Dialog
            open={resultDialogOpen}
            onClose={() => setResultDialogOpen(false)}>
            <InCardStack
              width='400px'
              justifyContent='flex-end'
              sx={{
                background: `url(${celebrate}) no-repeat`,
                backgroundSize: 'cover',
                backgroundPositionY: -50,
                // backgroundPosition: 'bottom',
                paddingTop: '170px',
                paddingBottom: '40px'
              }}>
              <TaskSuccessIcon />
              <Typography textAlign='center' variant='h3'>
                {t('assessmentTimeline.completingSelfAssessment.part1')} <br /> {t('assessmentTimeline.completingSelfAssessment.part2')}
              </Typography>
              <Button onClick={() => setResultDialogOpen(false)}>
                {t('stepQuiz.reviewMyResults')}
              </Button>
            </InCardStack>
          </Dialog>
          <SelfAssessmentReport
            resultData={assessmentResult}
            nextStepCallback={
              selectedGoal.enableOthersAssessment ? completeCallback : undefined
            }
            disableBehaviorClick={true}
          />
        </>
      ) : null}
    </InCardStack>
  )
}

export default StepFour
