import { alpha, Box, useTheme } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useEffect, useState } from 'react'
import InCardStack from './InCardStack'
import { getSizeString } from 'utils/formatText'

// Component converted from and inspired by https://github.com/xiaxiangfeng/react-carousel3d
const Carousel = props => {
  const theme = useTheme()
  const { height, width, data, itemSize, activeIndex, activeCallBack } = props
  const [activeI, setActiveIndex] = useState(activeIndex || 0)
  const rotation = Math.PI / 2
  const xOrigin = width * 0.5
  const yOrigin = height * 0.1
  const xRadius = width / 2.3
  const yRadius = height / 4.5
  const spacing = (2 * Math.PI) / data.length
  const farScale = 0.4

  useEffect(() => {
    if (activeI !== activeIndex) {
      setActiveIndex(activeIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  const getItemStyle = index => {
    const radius = rotation + spacing * (index - activeI)
    const sin = Math.sin(radius)
    const scale =
      farScale +
      (1 - farScale) * (sin + 1) * 0.5 +
      (index === activeI ? 0.1 : 0)
    const x = xOrigin + scale * (Math.cos(radius) * xRadius - itemSize * 0.5)
    const y = yOrigin + scale * sin * yRadius
    return {
      display: 'inline-block',
      position: 'absolute',
      transformOrigin: '0 0',
      zIndex: `${(scale * 100) | 1}`,
      transform: 'translate(' + x + 'px, ' + y + 'px) scale(' + scale + ')',
      transition: 'transform 2s',
      backgroundColor: theme.palette.background.paper,
      borderRadius: itemSize
    }
  }

  const getConnectorStyle = () => {
    const ringWidth = xRadius * rotation
    const ringHeight = height * farScale
    return {
      border: 'dashed 2px',
      borderColor: alpha(theme.palette.primary.light, 0.8),
      borderRadius: `${ringWidth / 2}px / ${ringHeight / 2}px`,
      width: ringWidth,
      height: ringHeight,
      zIndex: 0,
      marginTop: itemSize * farScale - yOrigin * farScale
    }
  }
  const updateByDelta = diff => {
    let newIndex = activeI + diff
    if (newIndex >= data.length) {
      newIndex = 0
    } else if (newIndex === -1) {
      newIndex = data.length - 1
    }
    updateActive(newIndex)
  }
  const updateActive = newIndex => {
    setActiveIndex(newIndex)
    activeCallBack(newIndex)
  }
  const iconSX = {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark
    }
  }
  return (
    <div style={{ position: 'relative', height, width, display: 'flex' }}>
      {data.map((value, i) => (
        <Box
          key={i}
          width={itemSize}
          height={itemSize}
          style={getItemStyle(i)}
          onClick={() => updateActive(i)}>
          {value}
        </Box>
      ))}
      <Box
        position='relative'
        width='100%'
        justifyContent='center'
        display='flex'>
        <InCardStack spacing={getSizeString(itemSize * farScale)}>
          <Box style={getConnectorStyle()}></Box>
          <InCardStack direction='row' spacing={getSizeString(itemSize + 10)}>
            <ArrowBackIosIcon sx={iconSX} onClick={() => updateByDelta(1)} />
            <ArrowForwardIosIcon
              sx={iconSX}
              onClick={() => updateByDelta(-1)}
            />
          </InCardStack>
        </InCardStack>
      </Box>
    </div>
  )
}

export default Carousel
