import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { amplitudeApiKey } from 'config/config'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser'

if (amplitudeApiKey) {
  amplitude.init(amplitudeApiKey, { defaultTracking: true })
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1
  })
  amplitude.add(sessionReplayTracking)
  amplitude.add(autocapturePlugin())
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
